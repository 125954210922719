import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Select from "react-select";
import $ from "jquery";
import { ContextualHelp } from "@adobe/react-spectrum";
import {
  Edit,
  StopOutline,
  Download,
  TrashCan,
  AddAlt,
} from "@carbon/icons-react";
import Constant from "../../../App/Constant";
import axios from "axios";

import {
  CaretDown,
  Checkmark,
  Add,
  OverflowMenuVertical,
  Close,
} from "@carbon/icons-react";

class Segments extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      checkAll: false,
    };
  }

  componentDidMount() {
    this.callApi();

    var maxField = 10; //Input fields increment limitation
    var addButton = $(".add_button"); //Add button selector
    var wrapper = $(".field_wrapper"); //Input field wrapper
    var fieldHTML =
      '<div><input type="text" name="field_name[]" value=""/><a href="javascript:void(0);" class="remove_button"><img src="remove-icon.png"/></a></div>'; //New input field html
    var x = 1; //Initial field counter is 1

    //Once add button is clicked
    $(addButton).click(function () {
      //Check maximum number of input fields
      if (x < maxField) {
        x++; //Increment field counter
        $(wrapper).append(fieldHTML); //Add field html
      }
    });

    //Once remove button is clicked
    $(wrapper).on("click", ".remove_button", function (e) {
      e.preventDefault();
      $(this).parent("div").remove(); //Remove field html
      x--; //Decrement field counter
    });
  }

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Data Found",
            list: result.data.data,
          },
        });
      }
    });
  };

  createSegment = () => {
    $("#savesegment").text("Processing...");
    const filterName = [];
    const filterCondition = [];
    const filterValue = [];

    var filterNameInput = document.getElementsByName("filter_name[]");
    for (var i = 0; i < filterNameInput.length; i++) {
      filterName.push(filterNameInput[i].value);
    }
    var filterConditionInput = document.getElementsByName("filter_condition[]");
    for (var i = 0; i < filterConditionInput.length; i++) {
      filterCondition.push(filterConditionInput[i].value);
    }
    var filterValueInput = document.getElementsByName("filter_value[]");
    for (var i = 0; i < filterValueInput.length; i++) {
      filterValue.push(filterValueInput[i].value);
    }

    // console.log(filterName, "filterName");
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "add-segment",
      data: {
        company_id: localStorage.getItem("company_id"),
        name: $("#segmentname").val(),
        description: $("#description").val(),
        filter_name: filterName,
        filter_condition: filterCondition,
        filter_value: filterValue,
        status: 1,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        $("#savesegment").text("Save");
        window.location.reload();
      } else {
        $("#savesegment").text("Save");
      }
    });
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  deleteSegment = (id) => {
    var id = $("#addconsent").val();
    $("#segment_" + id).hide();
    let classRef = this;
    var segment_ids = [id];
    var company_id = localStorage.getItem("company_id");

    axios({
      method: "POST",
      url: Constant.url + "delete-segment",
      data: { company_id: company_id, segment_ids: segment_ids },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#deletesuccessAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );
        $("#AddResolved").removeClass("uk-flex uk-open");
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);
      } else {
        $("#deleteerrorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        // window.location.href = "/contacts/segments";
      }
    });
  };

  download = (id) => {
    let classRef = this;
    var segment_ids = id;
    var company_id = localStorage.getItem("company_id");
    window.open(
      Constant.url +
        "download-segment?company_id=" +
        company_id +
        "&segment_id=" +
        segment_ids,
      "_blank"
    );
  };

  editSegment = (id) => {};

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr id={"segment_" + response.id}>
        <td>
          <label>
            <input
              className="uk-checkbox autoSelect"
              name="multi_check[]"
              value={response.id}
              type="checkbox"
            />
          </label>
        </td>
        <td>
          <span>{response.name}</span>
        </td>
        <td>
          <p>{response.descriptio ? response.name : "--"}</p>
          {/* <p>A peak season for the retail sector during Winters Sale</p> */}
        </td>
        <td>{response.counter}</td>
        <td>{response.created_at}</td>
        <td><button className="iconcircleBtn success" uk-tooltip="title: Download Data" onClick={(e) => this.download(response.id)}><Download /></button></td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.editUserSegment( response.id, response.name, response.description )}><Edit /></button></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addconsent(response.id)}><TrashCan /></button></td>
      </tr>
    ));
    return result;
  };

  addconsent = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  addNewSegment = () => {
    $("#NewSegment").addClass("uk-flex uk-open");
  };

  closeNewSegment = () => {
    $("#NewSegment").removeClass("uk-flex uk-open");
  };

  addSearchModal = () => {
    $("#AdvanceSearch").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#AdvanceSearch").removeClass("uk-flex uk-open");
  };
  addSaveUserSegment = () => {
    $("#SaveUserSegment").addClass("uk-flex uk-open");
  };

  closeSaveUserSegment = () => {
    $("#SaveUserSegment").removeClass("uk-flex uk-open");
  };

  editUserSegment = (id, name, description) => {
    $("#editsegmentname").val(name);
    $("#editdescription").val(description);
    $("#EditUserSegment").addClass("uk-flex uk-open");
  };

  closeEditUserSegment = () => {
    $("#EditUserSegment").removeClass("uk-flex uk-open");
  };

  addAdditionalSegment = () => {
    var fieldHTML =
      '<div class="uk-width-1-1"><p>Condition</p></div><div class="uk-width-auto"><select name="filter_name[]" class="uk-select" id="filter_name[]"><option value="tag">Tag</option><option value = "number">Number</option></select></div><div class="uk-width-auto"><select name="filter_condition[]" class="uk-select" id="filter_condition[]"><option>is not</option><option>is</option></select></div><div class="uk-width-auto"><div class="uk-form-controls"><input class="uk-input" id="filter_value[]" name="filter_value[]" type="text" placeholder="user_registered" /></div></div><div class="uk-width-auto"><button class="deleteBtn"><svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true"><path d="M12 12H14V24H12zM18 12H20V24H18z"></path><path d="M4 6V8H6V28a2 2 0 002 2H24a2 2 0 002-2V8h2V6zM8 28V8H24V28zM12 2H20V4H12z"></path></svg></button></div>';
    $("#main_segment").append(fieldHTML);
  };

  // addNewSegment = () =>{
  //   let classRef = this;
  //   var name = $("#filterName").val();

  // }

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    // console.log(searchword);

    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  deleteMultiSegment = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#segment_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "delete-segment",
      data: {
        company_id: localStorage.getItem('company_id'),
        segment_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        //  window.location.reload();
      }
    });
  };

  selectCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  advanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.status) {
      var key = key + " Status: " + $("#status :selected").text();
    }

    classRef.setState({ advancesearch: key });

    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  render() {
    const options = [
      { value: "Urgent", label: "Urgent" },
      { value: "Resolved", label: "Resolved" },
    ];
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Segments</h3>
              </div>
              <div className="uk-width-1-3">
                <div className="search_field">
                  <form action="javascript:;">
                    <input
                      className="uk-input"
                      type="text"
                      onChange={this.searchrecord}
                      name="searchword"
                      id="searchword"
                      placeholder="Search Segments through name"
                    />
                  </form>
                  <div class="CampaignReports Filtermain">
                    <div className="uk-width-auto">
                      <button onClick={this.addSearchModal}>
                        <img src="/images/newfilter.svg" uk-svg />
                        {/* <Search onClick = {this.addSearchModal}/> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-width-expand">
                <button
                  className="newFlowBtn uk-float-right"
                  onClick={this.addNewSegment}
                >
                  <Add /> <span>New Segment</span>
                </button>
              </div>
            </div>
          </div>
          <div className="tableSec">
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>
                    <label>
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        id="select_all"
                        onClick={this.selectCheckbox}
                      />
                    </label>
                    <div className="uk-inline drpIconBox">
                      <button className="iconTble" type="button">
                        <CaretDown />
                      </button>
                      <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li>
                            <a onClick={this.deleteMultiSegment}>
                              Delete Selected Rows
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </th>
                  <th>NAME</th>
                  <th>DESCRIPTION</th>
                  <th>CONTACTS</th>
                  <th>CREATED TIME</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {/* {(this.state.dataList.isLoaded) ? this.handleTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>} */}

                {this.state.dataList.isLoaded ? (
                  this.handleTable()
                ) : this.state.dataList.msg == "not" ? (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        Data not Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        <div className="mdl">
                          <div className="mdl_inner">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {/* <tr>
                  <td>
                    <label>
                      <input className="uk-checkbox" type="checkbox" />
                    </label>
                  </td>
                  <td>
                  <span>Winters Shoppers</span>
                  </td>
                  <td>
                    <p>A peak season for the retail sector during Winters Sale</p>
                  </td>
                  <td>
                  1231
                  </td>
                  <td>19:14 (UTC+05)</td>
                  <td>
                    <div className="uk-inline SegmentAction">
                        <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                        <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                                <li><a href="#"><Edit />Edit</a></li>
                                <li><a href="#"><StopOutline />Unsubscribe</a></li>
                                <li><a href="#"><Download />Download Data</a></li>
                                <hr/>
                                <li class="hintAlert"><a href="#"><TrashCan />Delete</a></li>
                            </ul>
                        </div>
                    </div>
                </td>
                  
                </tr>

                <tr>
                  <td>
                    <label>
                      <input className="uk-checkbox" type="checkbox" />
                    </label>
                  </td>
                  <td>
                  <span>Eid Shoppers</span>
                  </td>
                  <td>
                    <p>A peak season for the retail sector on Eid Sale</p>
                  </td>
                  <td>
                  3,432
                  </td>
                  <td>19:13 (UTC+05)</td>
                  <td>
                    <div className="uk-inline SegmentAction">
                        <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                        <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                                <li><a href="#"><Edit />Edit</a></li>
                                <li><a href="#"><StopOutline />Unsubscribe</a></li>
                                <li><a href="#"><Download />Download Data</a></li>
                                <hr/>
                                <li class="hintAlert"><a href="#"><TrashCan />Delete</a></li>
                            </ul>
                        </div>
                    </div>
                </td>
                  
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        {/* popup one start */}
        <div
          id="NewSegment"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Create New Segment</h3>

            <h5>Contacts match any of the following conditions:</h5>
            <form
              className="uk-form-stacked uk-grid-small"
              action="javascript:void(0);"
            >
              <div className="uk-grid-small" id="main_segment" uk-grid="">
                <div className="uk-width-1-1">
                  <p>Condition</p>
                </div>

                <div className="uk-width-auto">
                  <select
                    name="filter_name[]"
                    className="uk-select"
                    id="filter_name[]"
                  >
                    <option value="tag">Tag</option>
                    <option value="number">Number</option>
                  </select>
                </div>

                <div className="uk-width-auto">
                  <select
                    name="filter_condition[]"
                    className="uk-select"
                    id="filter_condition[]"
                  >
                    <option>is not</option>
                    <option>is</option>
                  </select>
                </div>

                <div className="uk-width-auto">
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="filter_value[]"
                      name="filter_value[]"
                      type="text"
                      placeholder="user_registered"
                    />
                  </div>
                </div>

                {/* <div className="uk-width-auto">
                                <button className="deleteBtn"><TrashCan /></button>
                            </div> */}
              </div>
              <div className="uk-margin uk-width-1-1">
                <button
                  className="simpleAddBtn"
                  onClick={this.addAdditionalSegment}
                >
                  <AddAlt /> Add Button
                </button>
              </div>

              <div className="uk-margin uk-width-1-1">
                <div className="ModalStyleBtns ">
                  <button className="CnclBtn" onClick={this.closeNewSegment}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    onClick={this.addSaveUserSegment}
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* popup one end */}

        {/* popup user field start */}

        <div
          id="SaveUserSegment"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            {/* <h4><b>Save New Segment - 1,243,453 contacts match your condition</b></h4> */}
            <h4>
              <b>Save New Segment </b>
            </h4>
            <h5>
              <b>What would you like to name your segment?</b>
            </h5>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="SegmentName">
                  Segment Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h5>
                        <b>Segment Name</b>
                      </h5>
                      <p>How this Segment appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="segmentname"
                    name="segmentname"
                    type="text"
                    placeholder="Enter Segment Name"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Description
                  <ContextualHelp variant="info">
                    <Content>
                      <h5>
                        <b>Description</b>
                      </h5>
                      <p>
                        A brief note about this Segment for you and other
                        admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="description"
                    name="description"
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <h5>
                  <b>What would you like to name your segment?</b>
                </h5>
                <p>Contacts that match any of the following conditions:</p>
                <ol>
                  <li>
                    Tags contact is tagged <b>user_registered</b>
                  </li>
                  <li>
                    Tags contact is not tagged <b>preferred_phone</b>
                  </li>
                </ol>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button
                    className="CnclBtn"
                    onClick={this.closeSaveUserSegment}
                  >
                    Close
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="savesegment"
                    onClick={this.createSegment}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* popup user field end */}

        {/*----MODAL---*/}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Segment?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsent"
                    onClick={this.deleteSegment}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Edit */}
        <div
          id="EditUserSegment"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            {/* <h4><b>Save New Segment - 1,243,453 contacts match your condition</b></h4> */}
            <h4>
              <b>Edit Segment </b>
            </h4>
            <h5>
              <b>What would you like to name your segment?</b>
            </h5>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="SegmentName">
                  Segment Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h5>
                        <b>Segment Name</b>
                      </h5>
                      <p>How this Segment appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="editsegmentname"
                    name="segmentname"
                    type="text"
                    placeholder="Enter Segment Name"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Description
                  <ContextualHelp variant="info">
                    <Content>
                      <h5>
                        <b>Description</b>
                      </h5>
                      <p>
                        A brief note about this Segment for you and other
                        admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="editdescription"
                    name="description"
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <h5>
                  <b>What would you like to name your segment?</b>
                </h5>
                <p>Contacts that match any of the following conditions:</p>
                <ol>
                  <li>
                    Tags contact is tagged <b>user_registered</b>
                  </li>
                  <li>
                    Tags contact is not tagged <b>preferred_phone</b>
                  </li>
                </ol>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button
                    className="CnclBtn"
                    onClick={this.closeSaveUserSegment}
                  >
                    Close
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="editsegment"
                    onClick={this.editSegment}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form
              className="uk-form-stacked"
              action="javascript:void(0);"
              id="segmentsFilter"
            >
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="to_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="from_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* advance search */}

        <div
          id="AdvanceSearch"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="to_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="from_date"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">keyword</label>
                            <div className="uk-form-controls">
                            <select className="uk-select" id="keyword">
                            <option value="" disabled selected >Select keyword</option>
                            {(this.state.keyList.isLoaded) ? this.handleKeywords() : ""}
                            </select>
                            </div>
                        </div> */}

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* advance search end */}
      </Content>
    );
  }
}
export default Segments;
