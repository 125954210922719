import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Select from "react-select";
import axios from "axios";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Constant from "../../../App/Constant";
import {
  CaretDown,
  Checkmark,
  Download,
  MessageQueue,
  InProgress,
  Information,
  ViewFilled,
  ReplyAll,
  Send,
  NotSent,
  Chat,
  Search,
  TrashCan,
  Add,
  AddAlt,
  Edit,
  ArrowRight,
  OverflowMenuVertical,
  Close,
} from "@carbon/icons-react";

class Company extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTokenChange = this.handleTokenChange.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },

      company_id: "",
      companyname: "",
      company_industry: "",
      firstname: "",
      lastname: "",
      code: "",
      whatsapp_number: "",

      number_of_requests: "",
      pending: "",
      approved: "",
      rejected: "",
      number_of_companies: "",
      number_of_packages_active_packages: "",
      rowsCountCompanies: 0,
    };
  }

  handleNameChange(event) {
    this.setState({ companyname: event.target.companyname });
  }

  handleTokenChange(event) {
    this.setState({ verification_token: event.target.verification_token });
  }

  handleIndustryChange(event) {
    this.setState({ company_industry: event.target.company_industry });
  }

  componentDidMount() {
    var pageCount = 1;
    $("#field_wrapper").on("click", ".deleteBtn", function () {
      $(this).parent().parent().parent().remove();
    });

    this.callApi(pageCount);
    this.companyStats();

    if(localStorage.getItem('loginStatus')== 1){
      let screens = localStorage.getItem('rolesceens').split(',');
      var checking = screens.includes("14");
       if(checking == true){

          }else{
              window.location.href = "/"; 
          }
      }
      else{
          window.location.href = "/"; 
      }

    $("#cross").hide();
  }

  companyStats = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "account-request-overview",
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          number_of_requests: stats.number_of_requests,
          pending: stats.pending,
          approved: stats.approved,
          rejected: stats.rejected,
          number_of_companies: stats.number_of_companies,
          number_of_packages_active_packages:
            stats.number_of_packages_active_packages,
        });
      }
    });
  };

  EditWebhooks = () => {
    $("#editwebhook").text("Processing");
    $("#editwebhook").prop("disabled", true);

    let classRef = this;

    var data = {};
    data = {
      company_name: $("#companyname").val(),
      type: $("#company_industry").val(),
      company_id: $("#company_id").val(),
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-company",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.reload();
        } else {
          $("#editerrorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#editwebhook").text("Edit Tag");
          $("#editwebhook").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  EditWebhookModal = (id) => {
    $("#EditTagsModal").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findhook(id);
  };

  findhook = (id) => {
    let classRef = this;
    let company_id = id;
    axios({
      method: "POST",
      url: Constant.url + "company",
      data: { company_id: company_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var company = result.data.data;

        // console.log(company, "yeahhhhh");

        classRef.setState({
          company_id: company.id,
          companyname: company.company_name,
          company_industry: company.industry,
          firstname: company.company_name,
          lastname: company.company_name,
          code: company.code,
          whatsapp_number: company.whatsapp_number,
        });
      }
    });
  };

  closeEditWebhookModal = () => {
    $("#EditTagsModal").removeClass("uk-flex uk-open");
  };

  addWebhookModal = () => {
    $("#AddTagsModal").addClass("uk-flex uk-open");
  };

  closeWebhookModal = () => {
    $("#AddTagsModal").removeClass("uk-flex uk-open");
  };

  QuickSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#QuickSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "companies",
      data: { page: pageCount, page: pageCount, limit: sortingValue },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  handleQuickClick = (data) => {
    let currentPage = data.selected + 1;
    this.QuickSorting(currentPage);
  };

  callApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "companies",
      data: { page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountCompanies: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountCompanies: result.data.rowscount,
        });
      }
    });
  };

  addconsent = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  deleteWebhook = () => {
    var id = $("#addconsent").val();
    let classRef = this;
    var company_id = id;
    var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "delete-company",
      data: { company_id: company_id, delete: is_deleted },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/company";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/company";
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr>
        {/* <td>
            <label>
            <input className="uk-checkbox autoSelect"  name="multi_check[]" value={response.id} type="checkbox" />
            </label>
          </td> */}
        <td>
          <span>{response.company_name}</span>
        </td>
        <td>
          <span>{response.industry}</span>
        </td>
        <td>
          <span>{response.whatsapp_number}</span>
        </td>
        <td>
          <span>{response.how_did_u_here}</span>
        </td>
        <td>
          <span>{response.most_exited}</span>
        </td>
        {/* <td>
            <span>{response.whatsapp_link}</span>
          </td> */}

        <td>
          <button
            className={
              response.status == "1" ? "ctmBtn succesBtn" : "ctmBtn dangreBtn"
            }
          >
            {response.status == "1" ? "Active" : "Inactive"}
          </button>
        </td>

        <td>{response.created_date}</td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.EditWebhookModal(response.id)}><Edit /></button></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addconsent(response.id)}><TrashCan /></button></td>
      </tr>
    ));

    return result;
  };

  createWebhook = () => {};

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    this.setState({ advancesearch: searchword });

    if (searchword !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "companies",
      data: { search: searchword },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  deleteMultiSegment = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#web_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "bulk-delete-webhook",
      data: {
        company_id: localStorage.getItem("company_id"),
        webhook_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        window.location.reload();
      }
    });
  };

  selectCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  advanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      status: $("#status").val(),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.status) {
      var key = key + " Status: " + $("#status :selected").text();
    }

    classRef.setState({ advancesearch: key });

    if (key !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "companies",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callApi();
    $("#cross").hide();
  };

  clearFilters = () => {
    $("#from_date").val("");
    $("#to_date").val("");
    $("#status").val("");
  };

  downloadCompanies = () => {
    let classRef = this;

    window.open(Constant.url + "companies-download", "_blank");
  };

  render() {
    const options = [
      { value: "Urgent", label: "Urgent" },
      { value: "Resolved", label: "Resolved" },
    ];
    return (
      <Content>
        <div
          className="contactContainer BroadcastingContainer "
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Company</h3>
              </div>
              <div className="uk-width-1-3">
                <div className="search_field">
                  <form>
                    <input
                      className="uk-input"
                      type="text"
                      onChange={this.searchrecord}
                      name="searchword"
                      id="searchword"
                      value={this.state.advancesearch}
                      placeholder="Search company through name"
                    />
                  </form>
                  <div class="CampaignReports Filtermain">
                    <div className="uk-width-auto">
                      <button
                        id="cross"
                        style={{ right: "30px" }}
                        onClick={this.advSearchClear}
                      >
                        <Close />
                      </button>
                      <button onClick={this.addSearchModal}>
                        <img src="/images/newfilter.svg" uk-svg />
                        {/* <Search onClick = {this.addSearchModal}/> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="uk-width-expand">
                        <button className="newFlowBtn uk-float-right" onClick={this.addWebhookModal} ><Add /> <span>Add Webhook</span></button>
                    </div> */}
              <div className="uk-width-expand@m">
                <button
                  class="newFlowBtn uk-float-left"
                  onClick={this.downloadCompanies}
                >
                  <Add /> <span>Export Companies</span>
                </button>
              </div>
            </div>
          </div>

          <div className="numberSorting uk-float-right">
            <div class="uk-inline">
              <label>
                Show
                <select
                  onChange={(e) => this.QuickSorting()}
                  id="QuickSortingBtn"
                >
                  {/* <option disabled selected>Sorting</option> */}
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>500</option>
                </select>
                entries
              </label>
            </div>
          </div>
          <div className="tableSec">
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Industry</th>
                  <th>Whatsapp Number</th>
                  <th>Source</th>
                  <th>Most Excited</th>
                  {/* <th>Whatsapp Link</th> */}
                  {/* <th>EVENT TYPES</th> */}
                  <th>STATUS</th>
                  <th>CREATED</th>

                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.state.dataList.isLoaded ? (
                  this.handleTable()
                ) : this.state.dataList.msg == "not" ? (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        Data not Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        <div className="mdl">
                          <div className="mdl_inner">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {this.state.rowsCountCompanies > 1 ? (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"...."}
                pageCount={this.state.rowsCountCompanies}
                onPageChange={this.handleQuickClick}
                containerClassName={"uk-pagination uk-float-right"}
                activeClassName={"uk-active"}
                marginPagesDisplayed={3}
                pageRangeDisplayed={6}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          id="EditTagsModal"
          className=" ModalStyle uk-flex-top"
          uk-modal="esc-close: false; bg-close: false"
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit Company</h3>
            <div id="editerrorAlert"></div>
            <div id="editsuccessAlert"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Company name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="company_id"
                    name="company_id"
                    value={this.state.company_id}
                    type="hidden"
                  />
                  <input
                    type="text"
                    id="companyname"
                    name="companyname"
                    value={this.state.companyname}
                    onChange={this.handleNameChange}
                    className="uk-input"
                    placeholder="Your company name (required)"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Company Industry
                </label>
                <div className="uk-form-controls">
                  <input
                    type="text"
                    id="company_industry"
                    name="company_industry"
                    className="uk-input"
                    value={this.state.company_industry}
                    onChange={this.handleIndustryChange}
                    placeholder="Your First Name (required)"
                  />
                </div>
              </div>

              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-4">
                  <label className="uk-form-label " htmlFor="">
                    Phone Number
                  </label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      id="code"
                      name="code"
                      value={this.state.code}
                      readonly
                    >
                      <option>+92</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-expand">
                  <label
                    className="uk-form-label uk-margin-top"
                    htmlFor=""
                  ></label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="whatsapp_number"
                      name="whatsapp_number"
                      value={this.state.whatsapp_number}
                      type="number"
                      placeholder="Enter phone number"
                      readonly
                    />
                  </div>
                </div>
              </div>

              {/* <div className="uk-margin">
                       <label className="uk-form-label" htmlFor="">Email</label>
                       <div className="uk-form-controls">
                         <input type="text" id="email" name="email"  className="uk-input" placeholder="enter email" />
                       </div>
                     </div>

                     <div className="uk-margin">
                       <label className="uk-form-label" htmlFor="">Password</label>
                       <div className="uk-form-controls">
                         <input type="text" id="password" name="password"  className="uk-input" placeholder="enter email" />
                       </div>
                     </div> */}

              <div className="uk-margin">
                <div className="ModalStyleBtns uk-margin-top">
                  <div className="uk-float-right">
                    <button
                      className="CnclBtn"
                      onClick={this.closeEditWebhookModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="AddTeamBtn"
                      id="editwebhook"
                      onClick={this.EditWebhooks}
                    >
                      Save <ArrowRight />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Company?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsent"
                    onClick={this.deleteWebhook}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form
              id="advsearchform"
              className="uk-form-stacked"
              action="javascript:void(0);"
            >
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Status
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="status">
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="1">Active</option>
                    <option value="0">In Active</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn ">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}
export default Company;
