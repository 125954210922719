import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="main_footer">
        <ul>
          <li><a href="#">Blog</a></li>
          <li><a href="#">Pricing</a></li>
          <li><a href="#">Careers</a></li>
          <li><a href="#">Terms</a></li>
          <li><a href="#">Privacy</a></li>
          <li><a href="#">Status</a></li>
          <li><a href="#">API Docs</a></li>
          <li><a href="#">Tutorials</a></li>
          <li><a href="#">Support</a></li>
          <li><a href="#">Solution Partner Program</a></li>
        </ul>
      </footer>
    )
  }
}
export default Footer;