import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { ContextualHelp } from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import PageActionBar from "../../Sections/PageActionBar";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ReactPaginate from "react-paginate";
import {
  Search,
  Filter,
  CaretDown,
  Information,
  Add,
  Edit,
  TrashCan,
  Download,
  OverflowMenuVertical,
  Close,
  AddAlt,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  CloudUpload,
  DocumentImport,
  Time,
} from "@carbon/icons-react";
import { Multiselect } from "multiselect-react-dropdown";

class QueryForm extends React.Component {
  constructor() {
    super();
    this.callTicketApi = this.callTicketApi.bind(this);
    this.callComplaintApi = this.callComplaintApi.bind(this);
    this.getTags = this.getTags.bind(this);
    this.onSelectTags = this.onSelectTags.bind(this);
    this.onRemoveTags = this.onRemoveTags.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.handleComplaintClick = this.handleComplaintClick.bind(this);
    this.handleTicketClick = this.handleTicketClick.bind(this);

    this.state = {
      uploadfiles: "",
      ContactList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      SegmentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      country: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      city: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      checkAlls: false,
      rowsCountBroadcast: 0,

      filterType: "is",
      popUpName: "",
      popUpNumber: "",
      popUpGender: "",
      popUpTag: "",
      popUpSunscribe: "",
      popUpContactTime: "",
      popAvatar: "",
      tagList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      tags: [],
      old_tags: [],
      old_contact_tags: [],
      rowsCountTicket: 0,
      rowCountComplain: 0,
      phoneintlnumber: "",
      phoneintlnumberedit: "",
    };
  }

  addContactProfile = (
    contact_name,
    phone,
    gender,
    tags,
    subscribed,
    contact_time,
    id,
    avatar,
    tag2
  ) => {
    this.setState({
      popUpName: contact_name,
      popUpNumber: phone,
      popUpGender: gender,
      popUpTag: tags,
      popUpSunscribe: subscribed,
      popUpContactTime: contact_time,
      popUpId: id,
      popAvatar: avatar,
      checkAll: false,
      old_contact_tags: tag2,
    });
    this.contactTags(tags, id);
    $("#ContactProfile").addClass("uk-flex uk-open");
  };

  componentDidMount() {
    var pageCount = 1;
    this.callTicketApi(pageCount);
    this.callComplaintApi(pageCount);
    this.getTags();
    this.getCountry();
    this.getCity();
    this.getAgents();
    var maxField = 10; //Input fields increment limitation
    var addButton = $(".add_button"); //Add button selector
    var wrapper = $(".field_wrapper"); //Input field wrapper
    var fieldHTML =
      '<div><input type="text" name="field_name[]" value=""/><a href="javascript:void(0);" class="remove_button"><img src="remove-icon.png"/></a></div>'; //New input field html
    var x = 1; //Initial field counter is 1

    //Once add button is clicked
    $(addButton).click(function () {
      //Check maximum number of input fields
      if (x < maxField) {
        x++; //Increment field counter
        $(wrapper).append(fieldHTML); //Add field html
      }
    });

    //Once remove button is clicked
    $(wrapper).on("click", ".remove_button", function (e) {
      e.preventDefault();
      $(this).parent("div").remove(); //Remove field html
      x--; //Decrement field counter
    });

    if (localStorage.getItem("loginStatus") == 1) {
      let screens = localStorage.getItem("rolesceens").split(",");
      var checking = screens.includes("4");
      if (checking == true) {
      } else {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }

    $("#cross").hide();
    $("#across").hide();
    $("#acrossforcomplaint").hide();
  }

  getAgents = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "get-agents",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          agentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  advAutoSearchClear = () => {
    let classRef = this;
    classRef.setState({ advanceautosearch: "" });
    $("#to_date").val("");
    $("#from_date").val("");
    $("#across").hide();
    this.callTicketApi(1);
  };
  advAutoSearchClearForComplaint = () => {
    let classRef = this;
    classRef.setState({ advanceautosearchforcomplaint: "" });
    $("#to_date_for_complaint").val("");
    $("#from_date_for_Complaint").val("");
    $("#acrossforcomplaint").hide();
    this.callComplaintApi(1);
  };

  getCountry = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "countries",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          country: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCountry = () => {
    let result = "";
    let index = 0;
    result = this.state.country.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  handleComplaintClick = (data) => {
    let currentPage = data.selected + 1;
    this.ComplaintSorting(currentPage);
  };

  handleTicketClick = (data) => {
    let currentPage = data.selected + 1;
    this.TicketSorting(currentPage);
  };

  ComplaintSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#complaintSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "show-complaints",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
        to: $("#to_date_for_Complaint").val(),
        from: $("#from_date_for_Complaint").val(),
      },
    }).then(function (result) {
      // console.log(result)
      if (result.data.status === true) {
        classRef.setState({
          SegmentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          // stats: {
          //   sent: result.data.data.stats.sent,
          //   delivered: result.data.data.stats.delivered,
          //   read: result.data.data.stats.read,
          //   replied: result.data.data.stats.replied,
          //   sending: result.data.data.stats.sending,
          //   Failed: result.data.data.stats.Failed,
          // },
          rowCountComplain: result.data.rowscount,
        });
      } else {
        classRef.setState({
          SegmentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowCountComplain: result.data.rowscount,
        });
      }
    });
  };

  ExportTicket = () => {
    let dateto = $("#to_date").val();
    let datefrom = $("#from_date").val();
    if (datefrom == "" && dateto == "") {
      window.open(
        Constant.url +
          "export-tickets?company_id=" +
          localStorage.getItem("company_id"),
        "_blank"
      );
    } else {
      window.open(
        Constant.url +
          "export-tickets?from=" +
          $("#from_date").val() +
          "&to=" +
          $("#to_date").val() +
          "&company_id=" +
          localStorage.getItem("company_id"),
        "_blank"
      );
    }
  };
  ExportComplaint = () => {
    let dateto = $("#to_date_for_Complaint").val();
    let datefrom = $("#from_date_for_Complaint").val();
    if (datefrom == "" && dateto == "") {
      window.open(
        Constant.url +
          "export-complaints?company_id=" +
          localStorage.getItem("company_id"),
        "_blank"
      );
    } else {
      window.open(
        Constant.url +
          "export-complaints?from=" +
          $("#from_date_for_Complaint").val() +
          "&to=" +
          $("#to_date_for_Complaint").val() +
          "&company_id=" +
          localStorage.getItem("company_id"),
        "_blank"
      );
    }
  };

  AutoAdvanceSearchForComplaint = () => {
    console.log("call");
    let classRef = this;
    var data = {};
    data = {
      to: $("#to_date_for_Complaint").val(),
      from: $("#from_date_for_Complaint").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.to) {
      var key = "Date To: " + $("#to_date_for_Complaint").val();
    }
    if (data.from) {
      var key = key + " Date From: " + $("#from_date_for_Complaint").val();
    }

    if (key !== "") {
      $("#acrossforcomplaint").show();
    }
    classRef.setState({ advanceautosearchforcomplaint: key });
    axios({
      method: "POST",
      url: Constant.url + "show-complaints",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SegmentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowCountComplain: result.data.rowscount,
        });
        $("#SearchAutoForComplaint").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          SegmentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowCountComplain: result.data.rowscount,
        });
        $("#SearchAutoForComplaint").removeClass("uk-flex uk-open");
      }
    });
  };
  AutoadvanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.keyword) {
      var key = key + " Status: " + $("#keyword :selected").text();
    }

    if (key !== "") {
      $("#across").show();
    }

    classRef.setState({ advanceautosearch: key });
    axios({
      method: "POST",
      url: Constant.url + "show-tickets",
      data: data,
    }).then(function (result) {
      console.log("result", result);
      if (result.data.status === true) {
        classRef.setState({
          ContactList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTicket: result.data.rowscount,
        });
        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          ContactList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTicket: result.data.rowscount,
        });
        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  TicketSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#ticketSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "show-tickets",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
        dateto: $("#to_date").val(),
        datefrom: $("#from_date").val(),
      },
    }).then(function (result) {
      // console.log(result)
      if (result.data.status === true) {
        classRef.setState({
          ContactList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },

          rowsCountTicket: result.data.rowscount,
        });
      } else {
        classRef.setState({
          ContactList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTicket: result.data.rowscount,
        });
      }
    });
  };

  countryChange = () => {
    this.getCity();
  };

  getCity = () => {
    let classRef = this;
    let country = $("#country").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCity = () => {
    let result = "";
    let index = 0;
    result = this.state.city.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  getTags = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "tags",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          tagList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  onSelectTags(selectedList, selectedItem) {
    this.setState({ tags: selectedList, old_contact_tags: selectedList });
  }
  onRemoveTags(selectedList, removedItem) {
    this.setState({ tags: selectedList, old_contact_tags: selectedList });
  }

  callTicketApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "show-tickets",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          ContactList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTicket: result.data.rowscount,
        });
      } else {
        classRef.setState({
          ContactList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTicket: result.data.rowscount,
        });
      }
    });
  };

  // ContactSorting = (pageCount) => {
  //   let classRef = this;
  //   let sortingValue = $("#ContactSortingBtn").val();
  //   sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
  //   axios({
  //     method: "POST",
  //     url: Constant.url + "contacts",
  //     data: {
  //       company_id: localStorage.getItem("company_id"),
  //       page: pageCount,
  //       limit: sortingValue,
  //       dateto: $("#to_date").val(),
  //       datefrom: $("#from_date").val(),
  //       country: $("#filtercountry").val(),
  //       city: $("#filtercity").val(),
  //       gender: $("#filtergender").val(),
  //       agent: $("#filteragents").val(),
  //       searchword : $("#ssearchword").val(),
  //     },
  //   }).then(function (result) {
  //     if (result.data.status === true) {
  //       classRef.setState({
  //         ContactList: {
  //           isLoaded: true,
  //           msg: "Loading...",
  //           list: result.data.data,
  //         },
  //         rowsCountTicket: result.data.rowscount,
  //       });
  //     } else {
  //       classRef.setState({
  //         ContactList: {
  //           isLoaded: false,
  //           msg: "not",
  //           list: [],
  //         },
  //         rowsCountTicket: result.data.rowscount,
  //       });
  //     }
  //   });
  // };

  closeAutoSearchModal = () => {
    $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
  };

  addAutoSearchModal = () => {
    $("#SearchAutoTeamModal").addClass("uk-flex uk-open");
  };
  closeAutoSearchModalForComplaint = () => {
    $("#SearchAutoForComplaint").removeClass("uk-flex uk-open");
  };

  addAutoSearchModalForComplaint = () => {
    $("#SearchAutoForComplaint").addClass("uk-flex uk-open");
  };

  clearFilters = () => {
    console.log("call");
    $("#to_date").val("");
    $("#from_date").val("");
  };
  clearFiltersForComplaint = () => {
    $("#to_date_for_Complaint").val("");
    $("#from_date_for_Complaint").val("");
  };

  handleContactClick = (data) => {
    let currentPage = data.selected + 1;
    this.ContactSorting(currentPage);
  };

  handleSegmentsClick = (data) => {
    let currentPage = data.selected + 1;
    this.SegmentsSorting(currentPage);
  };

  handleContactTable = () => {
    let result = "";
    let index = 0;
    let tagCount = "";
    let tags = "";
    // console.log(this.state.ContactList);
    result = this.state.ContactList.list.map((response, index) => (
      // (tagCount = response.tags.split(",")),
      // (tagCount = tagCount.length - 2),
      // (tags =
      //   response.tags.split(",")[0] + "," + response.tags.split(",")[1]),
      <tr id={"contact_" + response.id}>
        <td>{response.conversation_id}</td>
        <td>{response.order_id}</td>
        <td>{response.title}</td>
        <td>{response.company}</td>
        <td>{response.customer}</td>

        <td>{response.agent}</td>
        <td>{response.customer_name}</td>
        <td>{response.status}</td>
        <td>{response.description}</td>
        <td>{response.email}</td>
        <td>{response.phone}</td>
        <td>
          <a href={response.media} target="_blank">
            view
          </a>
        </td>
        <td>{response.contact_time.slice(0, 10)}</td>
        <td>{response.contact_time.slice(11, 16)}</td>
      </tr>
    ));
    return result;
  };

  handleComplainTable = () => {
    let result = "";
    let index = 0;
    let tagCount = "";
    let tags = "";
    result = this.state.SegmentList.list.map((response, index) => (
      // (tagCount = response.tags.split(",")),
      // (tagCount = tagCount.length - 2),
      // (tags =
      //   response.tags.split(",")[0] + "," + response.tags.split(",")[1]),
      <tr id={"contact_" + response.id}>
        <td>{response.id}</td>
        <td>{response.order_id}</td>
        <td>{response.order_status}</td>
        <td>{response.owner}</td>
        <td>{response.duplicate_conv}</td>
        <td>{response.title}</td>
        <td>{response.company}</td>
        <td>{response.customer}</td>
        <td>{response.conversation_id}</td>
        <td>{response.agent}</td>
        <td>{response.customer_name}</td>
        <td>{response.description}</td>
        <td>{response.contact_time.slice(0, 10)}</td>
      </tr>
    ));
    return result;
  };

  callComplaintApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "show-complaints",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SegmentList: {
            isLoaded: true,
            msg: "Data Found",
            list: result.data.data,
          },
          rowCountComplain: result.data.rowscount,
        });
      } else {
        classRef.setState({
          SegmentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowCountComplain: result.data.rowscount,
        });
      }
    });
  };

  render() {
    return (
      <Content>
        <div
          className="AutomationContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          {/* Automation Tab Start */}
          <div className="TabsHeadBox">
            <ul className="tabsBtns desktop" uk-switcher="connect: .multi_div">
              <li id="tagtab">
                <a href="#">Ticket </a>
              </li>
              <li id="tagtemp">
                <a href="#">Complaints</a>
              </li>
            </ul>
            <div class="mobTabsBtn">
              <p class="AnimFade uk-scrollspy-inview uk-animation-fade">
                Contacts
              </p>
              <ul className="tabsBtns" uk-switcher="connect: .multi_div">
                <li id="tagtab">
                  <a href="#">Contacts</a>
                </li>
                <li id="tagtemp">
                  <a href="#">Segments</a>
                </li>
              </ul>
            </div>

            <ul className="uk-switcher multi_div" id="tabsBtns">
              <li>
                <div className="searchnFlowBtn mobile">
                  <form className="uk-search" action="javascript:void(0)">
                    <div className="uk-width-auto">
                      <input
                        className="uk-search-input"
                        type="search"
                        disabled
                        value={this.state.advanceautosearch}
                        name="searchword"
                        id="searchword"
                      />
                    </div>
                    <div class="Filtermain automaationFiltermain">
                      <div className="icon">
                        <div className="uk-width-auto">
                          <button
                            id="across"
                            style={{ right: "50px" }}
                            onClick={this.advAutoSearchClear}
                          >
                            <Close />
                          </button>
                          <button
                            className="popadvanceFilter"
                            onClick={this.addAutoSearchModal}
                          >
                            <img src="/images/newfilter.svg" uk-svg="" />
                            {/* <Search onClick = {this.addSearchModal}/> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <button
                    className="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.ExportTicket}
                  >
                    <Add /> <span>Export Ticket</span>
                  </button>
                </div>
              </li>
              <li>
                <div className="searchnFlowBtn mobile">
                  <form className="uk-search" action="javascript:void(0)">
                    <div className="uk-width-auto">
                      <input
                        className="uk-search-input"
                        type="search"
                        disabled
                        // onChange={this.searchrecord}
                        value={this.state.advanceautosearchforcomplaint}
                        name="searchword"
                        id="searchword"
                      />
                    </div>
                    <div class="Filtermain automaationFiltermain">
                      <div className="icon">
                        <div className="uk-width-auto">
                          <button
                            id="acrossforcomplaint"
                            style={{ right: "50px" }}
                            onClick={this.advAutoSearchClearForComplaint}
                          >
                            <Close />
                          </button>
                          <button
                            className="popadvanceFilter"
                            onClick={this.addAutoSearchModalForComplaint}
                          >
                            <img src="/images/newfilter.svg" uk-svg="" />
                            {/* <Search onClick = {this.addSearchModal}/> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <button
                    className="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.ExportComplaint}
                  >
                    <Add /> <span>Export Complaint</span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div id="errorAlertmain"></div>
          <ul className="uk-switcher TabSec multi_div">
            <li id="tagtablist">
              <div className="numberSorting uk-float-right">
                <div class="uk-inline">
                  <label>
                    show
                    <select
                      onChange={(e) => this.TicketSorting()}
                      id="ticketSortingBtn"
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                    </select>
                    enteries
                  </label>
                </div>
              </div>
              <div className="tableSec">
                <table className="uk-table uk-table-small uk-table-divider CustomTableWidth">
                  <thead>
                    <tr>
                      <th>Conversation Id</th>

                      <th>Order Id</th>
                      <th>Title</th>
                      <th>Company</th>
                      <th>Customer</th>
                      <th>Agent</th>
                      <th>Customer Name</th>
                      <th>Status</th>
                      <th>Description</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Media</th>
                      <th>Date </th>
                      <th>Time </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {(this.state.ContactList.isLoaded) ? this.handleContactTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>} */}

                    {this.state.ContactList.isLoaded ? (
                      this.handleContactTable()
                    ) : this.state.ContactList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountTicket > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountTicket}
                    onPageChange={this.handleTicketClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li>

            <li id="tagtemplist">
              <div className="numberSorting uk-float-right">
                <div class="uk-inline">
                  <label>
                    show
                    <select
                      onChange={(e) => this.ComplaintSorting()}
                      id="complaintSortingBtn"
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                    </select>
                    enteries
                  </label>
                </div>
              </div>
              <div className="tableSec">
                <table className="uk-table uk-table-small uk-table-divider">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Order Id</th>
                      <th>Order Status</th>
                      <th>Owner</th>
                      <th>Duplicate Conv</th>
                      <th>Title</th>
                      <th>Company</th>
                      <th>Customer</th>
                      <th>Conversation Id</th>
                      <th>Agent</th>
                      <th>Customer Name</th>
                      <th>Reason</th>
                      <th>Date </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.SegmentList.isLoaded ? (
                      this.handleComplainTable()
                    ) : this.state.SegmentList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowCountComplain > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowCountComplain}
                    onPageChange={this.handleComplaintClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li>
          </ul>
        </div>

        <div
          id="SearchAutoTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeAutoSearchModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.AutoadvanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          id="SearchAutoForComplaint"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date_for_Complaint"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_date_for_Complaint"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn">
                  <button
                    className="CnclBtn"
                    onClick={this.clearFiltersForComplaint}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeAutoSearchModalForComplaint}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.AutoAdvanceSearchForComplaint}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}
export default QueryForm;
