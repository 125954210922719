import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { AgentStatusChart } from "../../Sections/AgentStatusChart";
import { AssignLiveChat } from "../../Sections/AssignLiveChat";
import { DateRange } from "../../Common/DateRange";
import { LiveChatOverviewResolutionTIme } from "../../Sections/LiveChatOverviewResolutionTIme";
import { WhatsappBusinessMessStatus } from "../../Sections/WhatsappBusinessMessStatus";
import { WhatsappFrequencyChart } from "../../Sections/WhatsappFrequencyChart";
import { WhatsappSmsOverview } from "../../Sections/WhatsappSmsOverview";
import { WhatsappdeliveryTime } from "../../Sections/WhatsappdeliveryTime";
import { WhatsappMonthlyActiveChart } from "../../Sections/WhatsappMonthlyActiveChart";
import { WhatsappMonthlyActiveUserChart } from "../../Sections/WhatsappMonthlyActiveUserChart";
import { SmsOverviewChart } from "../../Sections/SmsOverviewChart";
import { SmsBusinessMessStatus } from "../../Sections/SmsBusinessMessStatus";
import { SmsDeliveryNetwork } from "../../Sections/SmsDeliveryNetwork";
import { SmsDeliveryTimeChart } from "../../Sections/SmsDeliveryTimeChart";
import { SmsInboundNetwork } from "../../Sections/SmsInboundNetwork";
import { SmsInboundCountry } from "../../Sections/SmsInboundCountry";
import { ChatBotMessageChart } from "../../Sections/ChatBotMessageChart";
import { SmsTotalMess } from "../../Sections/SmsTotalMess";
import { EmailOverviewChart } from "../../Sections/EmailOverviewChart";
import { CallOutboundChart } from "../../Sections/CallOutboundChart";


  
class CallAnalytics extends React.Component {
    MultiSelectFilter =()=>{
        $(".UltabsBtns").toggleClass("showMenu");
        $(".UltabsBtns > li label").click(function(){
            $(".MultiSelectFilter > p span").text($(this).text());
        });
    }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="" uk-height-match="target: > div > .uk-card">
                <div className="uk-width-1-2">
                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>Call</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">

                    
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>
                
                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head"><h5>Call Overview</h5></div>
                            {/* Chart */}
                            <div className="ChannelsOverviewGraph">
                            <EmailOverviewChart/>
                            </div>
                    </div>
                </div>
                

                 {/* Calls Traffic Performance */}
                 <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Calls Traffic Performance
                            </h5>
                        </div>
                        <div uk-grid="">
                        <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>647</h3>
                                <p><span uk-icon="icon: check" ></span>Emails sent</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>33.33%</h3>
                                <p><span uk-icon="icon: check" ></span> Delivered</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>33.33%</h3>
                                <p><span uk-icon="icon: check" ></span>Answered</p>
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>783</h3>
                                <p><span><Hourglass /></span>Duration in seconds</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>$23</h3>
                                <p><span uk-icon="icon: check" ></span>Cost</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* Calls Outbound and Inbound Performance */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Calls Outbound and Inbound Performance
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Timer className="iconBara" />Outbound
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>203,820</h3>
                                <p>Traffic
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>First response time</h5>
                                        <p>How long are customers waiting for agent's first response.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>186,457</h3>
                                <p>Messages
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>First response time</h5>
                                        <p>How long are customers waiting for agent's first response.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>17,363</h3>
                                <p>Cost</p>
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>17,363</h3>
                                <p>Delivery Rate
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>First response time</h5>
                                        <p>How long are customers waiting for agent's first response.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Timer className="iconBara" />Inbound
                                            
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3> 17,623</h3>
                                <p>Traffic
                                <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>All response time</h5>
                                        <p>The metric that displays agent's responsiveness.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>11,261</h3>
                                <p>Messages
                                <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>All response time</h5>
                                        <p>The metric that displays agent's responsiveness.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>18,628</h3>
                                <p>Cost</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3></h3>
                                <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                

               

                 {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <div className="head customheader">
                            <h5 className="uk-heading-divider">Outbound Performance By Country</h5>
                        </div>  
                        {/* <div className="head"><h5>All Channels Overview</h5></div> */}
                            {/* Chart */}
                            <div className="ChannelsOverviewGraph">
                            <CallOutboundChart/>
                            </div>
                    </div>
                </div>
            </div>
         </>
    );
}
}
export default CallAnalytics;