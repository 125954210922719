import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, Checkbox } from "@adobe/react-spectrum";

import {
  Checkmark,
  FaceSatisfied,
  Image,
  Attachment,
  Location,
  DecisionTree,
  Edit,
  StopOutline,
  TrashCan,
  Download,
  MobileCheck, CheckmarkOutline, GenderFemale, Time, Credentials,SendFilled, Add, InformationFilled, Close
} from "@carbon/icons-react";
import PageActionBar from "../../Sections/PageActionBar";
import Constant from "../../../App/Constant";
import axios from 'axios';
import $ from 'jquery';
class Builder extends React.Component {

  constructor() {
    super();    
    this.state = {
      isPreview : "no",
      currentTime : "00:00"
    }
  }

  componentDidMount() {    
    var d = new Date("2011-04-20T09:30:51.01");    
    this.setState({currentTime : d.getHours()+':'+d.getMinutes()})
  }



  addNewBuilder = () => {
    $("#NewBuilder").addClass('uk-flex uk-open');
    // $(".action-button-save").click();
    // $("#previewButton").text("Loading...")    
    // $(".action-button-save-preview").click()
        //     axios({
        //     method: 'POST',
        //     url: Constant.url + "packages",
        //     data: { company_id: company_id }
        // }).then(function (result) {
        //     if (result.data.status === true) {            
        //       $("#NewBuilder").addClass('uk-flex uk-open'); 
        //       document.querySelector(".conversation-container").scrollBy(0, 1000);
        //     }
        // })
        
        $("#NewBuilder").addClass('uk-flex uk-open'); 
        $("#previewButton").text("Preview")    
  }

  closeNewBuilder = () => {
    this.setState({isPreview : "no"})
    $("#NewBuilder").removeClass('uk-flex uk-open');
  }

  sendMessage = () =>{    
    document.querySelector(".conversation-container").scrollBy(0, 1000);
    var message = $("#messageinput").val();
    $("#ChatConversion").append('<div class="message sent"><span>'+message+'</span></div>');
    let classRef = this;
    let company_id = localStorage.getItem('company_id');
    axios({
        method: 'GET',
        url: "https://dashboard.convexinteractive.com/waba_bot_whatsapp/preview_service.php?msg="+message+"&mobile=923413143123&telco=telenor&msg_id=3256423456464&shortcode=923433333992",        
    }).then(function (result) {
      // console.log(result.data.data.msg,'result')
      var msg = result.data.data.msg;
      if(result.data.data.file_type == "image"){
        $("#ChatConversion").append('<div class="message received"><div class=""><div class=""><img src="'+msg+'"  /></div></div></div>');      
      }else if(result.data.data.file_type == "video"){
        $("#ChatConversion").append('<div class="message received"><div class=""><video width="320" height="240" controls><source src="'+msg+'" type="video/mp4" /></video></div></div>');      
      }else if(result.data.data.file_type == "file"){
        $("#ChatConversion").append('<div class="message received"><div class=" customPdfBtn"><a href="'+msg+'" download="" target="_blank"><img src="images/box.svg" uk-svg=""  />"'+result.data.data.filename+'"</a></div></div>');      
      }else{
      $("#ChatConversion").append('<div class="message received"><span>'+msg+'</span></div>');      
      
      }
      document.querySelector(".conversation-container").scrollBy(0, 1000);
    })
    $("#messageinput").val("");
    document.querySelector(".conversation-container").scrollBy(0, 1000);
  }
  

 
  render() {
    return (
      <Content>
        <iframe className="IframeEmbd" name="iframe" id="iframe" src={"https://yolo.convexinteractive.com:10085/flowbuilder_convex/visual_flow_builder/load_builder/1/1/fb?msisdn="+localStorage.getItem('company_msisdn')+"&company_id="+localStorage.getItem('company_id')} width={"1700px"} height={'1000px'} title="description"></iframe>
       {/* <iframe className="IframeEmbd" name="iframe" id="iframe" src={"https://yolo.convexinteractive.com:10085/flowbuilder/visual_flow_builder/load_builder/1/1/fb?msisdn="+localStorage.getItem('company_msisdn')+"&company_id="+localStorage.getItem('company_id')} width={"1700px"} height={'1000px'} title="description"></iframe> */}
      
        <div className="WhatsappBuilderBtn">
          <button class="newFlowBtn uk-float-right" onClick={this.addNewBuilder}><Add/> <span id="previewButton">Preview</span></button>
        </div>
       
      
      {/* popup one start */}
      <div id="NewBuilder" className="AddTeamModal NewBuilder ModalStyle uk-flex-top" uk-modal="">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button"> <Close /></button>
                    <div class="page">
                        <div class="marvel-device nexus5">
                          <div class="top-bar"></div>
                          <div class="sleep"></div>
                          <div class="volume"></div>
                          <div class="camera"></div>
                          <div class="screen">
                            <div class="screen-container">
                              <div class="status-bar">
                               <img src="/images/whatsappTopImg.svg"/>
                              </div>
                              <div class="DateTimer">
                               <span>{this.state.currentTime}</span>
                              </div>
                              <div class="chat">
                                <div class="chat-container">
                                  <div class="user-bar">
                                    <div class="back">
                                      <img src="/images/leftChevron.svg" uk-svg="" alt="Avatar" />
                                    </div>
                                    <div class="avatar">
                                      <img src="/images/profileImg.png" alt="Avatar" />
                                    </div>
                                    <div class="name">
                                      <span>{localStorage.getItem('company_name')}</span>
                                      <span class="status">tap here for contact info</span>
                                    </div>
                                  </div>
                                  <div class="conversation">
                                    <div class="conversation-container">

                                      <div className="ChatConversion" id="ChatConversion">
                                          {/* day status */}
                                      <div className="sendStatusday">
                                        <p className="uk-text-center">Today</p>
                                      </div>

                                      {/* day status end */}


                                      {/* warning start */}
                                      <div className="warninginfo">
                                        <span><InformationFilled /></span>
                                        <p className="uk-text-left"> This business works with other companies to manage this chat. Click to learn more.</p>
                                      </div>

                                      {/* warning end */}

                                      {/* <div class="message sent">
                                        <span>Hi, Proton</span>
                                        <span class="metadata">
                                            <span class="time"></span>
                                            <span class="tick">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>
                                        </span>
                                      </div> */}
                                      {/* <div class="message sent">
                                        <span>Hi, Proton</span>                                        
                                      </div>
                                      <div class="message received">
                                        <span>Hi, Welcome to Proton Pakistan. Enter number to choose 👇 <br/> 1. Product Range <br/> 2. Find a Dealership <br/> 3. Book a test drive <br/> 4. Talk to Agent</span>
                                        <span class="metadata">
                                            <span class="time"></span>
                                            <span class="tick">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>
                                        </span>
                                      </div>                                     
                                      
                                      <div class="message received">
                                        <div className="">
                                          <div className="">
                                              <img src="images/avatar.jpg"  />
                                          </div>
                                        </div>
                                        <span class="metadata">
                                            <span class="time"></span>
                                            <span class="tick">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076"><path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7"/></svg></span>
                                        </span>
                                      </div> */}

                                        {/* <div className="message received">
                                          
                                          <div className="">
                                            <video width="320" height="240" controls>
                                              <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4" type="video/mp4" />
                                              
                                              <span>Your browser does not support the video tag.</span>

                                            </video>
                                          </div>
                                        </div> */}

                                          {/* pdf start */}
                                        {/* <div className="message received">
                                          <div className=" customPdfBtn">
                                              <a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" download=""><img src="images/box.svg" uk-svg=""  /> Document.pdf </a>
                                          </div>
                                        </div> */}

                                        {/* pdf end */}
                                      </div>
                                      
                                    </div>
                                    <form class="conversation-compose" action="javascript:void(0)">
                                      {/* <div class="emoji">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="smiley" x="3147" y="3209"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z" fill="#7d8489"/></svg>
                                      </div> */}
                                      <input class="input-msg" id="messageinput" name="input" placeholder="Type a mes.." autocomplete="off" autofocus="" /> 
                                      <div class="photo js-upload uk-form-custom">
                                        
                                        {/* <img src="/images/whatsappattach.svg" alt="" width="25" height="25" />
                                        <input type="file" /> */}
                                      </div>
                                      <button type="submit" class="send" onClick={this.sendMessage}>
                                          <div class="circle">
                                          <SendFilled />
                                          </div>
                                        </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                        {/* <button className="uk-modal-close-default" type="button"> <Close /></button> */}
                       
                        {/* type box start */}

                        {/* <div className="whatsApptopHeader">
                          <div className="chat_tab uk-grid uk-grid-small" uk-grid="">
                              <div className="uk-width-auto uk-flex-first uk-first-column">
                                <div className="chat_select">
                                  <img src="images/leftChevron.svg" uk-svg=""/>
                                </div>
                              </div>
                              <a className="user_list uk-width-expand" id="convesation_iteation_86">
                                <div className="uk-grid-small uk-grid" uk-grid="">
                                  <div className="uk-width-auto uk-flex-first uk-first-column">
                                    <div className="pp">
                                      <img src="https://yolo.convexinteractive.com:10085/waba/uploads/user.png"/>
                                    </div>
                                  </div>
                                  <div className="uk-width-expand">
                                    <p id="customer_86">Company Name</p>
                                    <p id="msg_86">tap here for contact info</p>
                                  </div>
                                  <div className="uk-width-auto uk-flex-last">
                                    <div className="timingBox">
                                      <div className="assignedunassigned">Assigned</div>
                                      <div className="time timeLeft">
                                        <div className="countdown uk-countdown" uk-countdown="date: 2022-09-15T17:48:55+05:00">
                                          <div className=" uk-countdown-hours">
                                            <span>0</span><span>5</span>
                                          </div>
                                        </div>
                                        <p className="blue">h<span className="left">left</span></p>
                                        <p className="red uk-hidden"><span className="left">expired</span></p>
                                      </div>
                                      <div className="time newTime">
                                        <p>2022-09-14 17:48</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                          </div>
                        </div>
                        <div className="messages_box builderWhatsApp">
                          <ul className="message_box_list msg_box_id_86" id="message_box_list">
                            <li className="customer">
                              <div className="message">
                                <div className="text">
                                  <span>1</span>
                                  <div className="dateSpan">21:28</div>
                                </div>
                              </div>
                            </li>
                            <li className="support">
                              <div className="message">
                                <div className="text">
                                  <span>Thanks for reaching out convex Interactive, an expert will be connected to you soon.</span>
                                  <div className="dateSpan">21:28</div>
                                </div>
                              </div>
                            </li>       
                          </ul>   
                        </div>                    */}

                        {/* type box end */}

                    </div>
                </div>

                             {/* popup user field start */}
      
      </Content>
    );
  }
}

export default Builder;
