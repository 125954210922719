import React from 'react';

  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
  function NotificationPopup(){
    const notify = () => toast(<div className="notificationBox">
    <div className="notificationImg">
        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
    </div>
    <div className="notificationTxt">
        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
        <span>20 hours ago</span>
    </div>
</div>);

    return (
      <div>
        <button onClick={notify}>Notify!</button>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
  export default NotificationPopup;