import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content} from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Constant from "../../../App/Constant";
import axios from 'axios';
import $, { cssNumber, data } from 'jquery';
import {
  CaretDown,
  Checkmark,
  Add,
  OverflowMenuVertical,
  Close,
} from "@carbon/icons-react";
class Packages extends React.Component {
    constructor(){
        super();
        this.handlePackage = this.handlePackage.bind(this);
        this.getPackage = this.getPackage.bind(this);
        this.state = {
            packageList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            packageid : 0,
            packagestatus : 0,
            buttonname : "activate",
            requestid : "activate",
        }
    }

    componentDidMount() {
        this.getPackage();
    }

    getPackage = () => {
        $(".spinner").removeClass("uk-hidden");
        let classRef = this;
        let company_id = localStorage.getItem('company_id');
        axios({
            method: 'POST',
            url: Constant.url + "packages",
            data: { company_id: company_id }
        }).then(function (result) {
            if (result.data.status === true) {
                $(".spinner").addClass("uk-hidden");
                classRef.setState({
                    packageList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                })
            }
        })
    }

    showpause = (id,active_id,name)=>{
        this.setState({ packageid : id, packagestatus : active_id, buttonname : name });
        $('#deactivatepop').addClass('uk-flex uk-open');
    }

    pauseCancelClick = () =>{
        $('#deactivatepop').removeClass('uk-flex uk-open');
    }

    cancelbtnrequest = () =>{
        $('#requestpop').removeClass('uk-flex uk-open');
    }

    showrequest = (id)=>{
        this.setState({ requestid : id })
        $('#requestpop').addClass('uk-flex uk-open');
    }

    requestPackage = () =>{
        $('#requestBtn').text('Processing');
        $('#requestBtn').prop('disabled', true);
        let classRef = this;
        let company_id  = localStorage.getItem('company_id');
        let package_id = classRef.state.requestid;
        axios({
            method : 'POST',
            url : Constant.url + "package-request",
            data : {
                company_id : company_id,
                package_id : package_id
            }
        }).then(function (result){
            if(result.data.status === true){
                $("#successAlertRequest").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
                setTimeout(function(){
                    UIkit.alert('#msg-dialog').close();
                    $('#requestBtn').text('Request');
                    $('#requestBtn').prop('disabled', false);
                    $('#requestNow_'+package_id).addClass('disabled');
                    $('#requestNow_'+package_id).text('Requested');
                    $('#requestNow_'+package_id).prop('disabled', true);
                    $('#requestpop').removeClass('uk-flex uk-open');
                },1000);
            } else{
                $("#errorAlertRequest").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
                setTimeout(function(){
                    UIkit.alert('#msg-dialog').close();
                    $('#requestBtn').text('Request');
                    $('#requestBtn').prop('disabled', false);
                },2000);
            }
        })
    }
    
    changePackageStatus = () =>{
        $('#deactivateBtn').text('Processing');
        $('#deactivateBtn').prop('disabled', true);
        let classRef = this;
        let company_id = localStorage.getItem('company_id');
        axios({
            method: 'POST',
            url: Constant.url + "change-package-status",
            data: { 
                company_id: company_id,
                subscription_id : classRef.state.packageid,
                status : classRef.state.packagestatus,
            }
        }).then(function (result) {
            if (result.data.status === true) {
                $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Request send successfully.</p></div>');
                setTimeout(function(){
                    UIkit.alert('#msg-dialog').close();
                    $('#deactivateBtn').text('Deactivate');
                    $('#deactivateBtn').prop('disabled', false);
                    $('#deactivatepop').removeClass('uk-flex uk-open');
                    classRef.getPackage();
                },1000);
            } 
        })
    }

    handlePackage = () => {
        let result = "";
        let index = 0;
        result = this.state.packageList.list.map((response, index) => (  
             
            <div className="uk-width-1-3@m">
                <div className="uk-card uk-card-default">
                    <div className="uk-card-body">
                        
                        <h2 className="uk-card-title">{response.title}</h2>
                        <h6>{response.description}</h6>
                        <div className="actions prices">
                            <small>Starts at per month</small>
                            <h1>Rs. {response.price}</h1>
                        </div>
                        <ul className="actions prices bottom">
                            <li>
                                <h5>{response.monthly_active_title}</h5>
                                <p>{response.monthly_active_desc}</p>
                            </li>
                            
                            <li>
                                <h5>{response.whatsapp_conversation_title}</h5>
                                <p>{response.whatsapp_conversation_desc}</p>
                            </li>

                            <li>
                                <h5>{response.live_agent_title}</h5>
                                <p>{response.live_agent_desc}</p>
                            </li>
                            <li>
                                <h5>{response.faq_chatbot_title}</h5>
                                <p>{response.faq_chatbot_desc}</p>
                            </li>
                            <li>
                                <h5>{response.advanced_chatbot_monthly_title}</h5>
                                <p>{response.advanced_chatbot_monthly_desc}</p>
                            </li>
                            <li>
                                <h5>{response.advanced_chatbot_implementation_title}</h5>
                                <p>{response.advanced_chatbot_implementation_desc}</p>
                            </li>
                        </ul>
                        <hr></hr>
                        <div class="ModalStyleBtns priceAction">
                            {(response.active_package == 1) ? <div><button class="AddTeamBtn disabled" id={"Activate_"+response.id} disabled>Activated Package</button>{(response.is_active == 1) ? <button class="CnclBtn" id={"active_"+response.id} onClick={(e)=>this.showpause(response.id,0,"Deactivate")}>Deactivate</button> : <button class="CnclBtn" id={"Cancel_"+response.id} onClick={(e)=>this.showpause(response.id,1,"Activate")}>Activate</button>}</div> : <button class={(response.is_requested == 1) ? "AddTeamBtn disabled" : "AddTeamBtn"} disabled={(response.is_requested == 1) ? "disabled" : ""} id={"requestNow_"+response.id} onClick={(e)=>this.showrequest(response.id)} >{(response.is_requested == 1) ? "Requested" : "Request Now"}</button>}
                        </div>
                    </div>
                </div>
            </div>
        ));
        return result;
    }


    render() {
        return (
        <Content>
            <div
            className="contactContainer"
            uk-height-viewport=""
            uk-height-match=".h_match"
            >
                <div className="head">
                    <div className="uk-grid " uk-grid="">
                        <div className="uk-width-auto"><h3>Pricing Plan</h3></div>
                    </div>
                </div>
                <div className="chat_box ht_match uk-width-expand spinner uk-hidden">
                    <div className="uk-text-center uk-position-center "><span uk-spinner="ratio: 2" /></div>
                </div>
                {/* Standard Packages start */}
                <div className="integration ">
                    <h4>Standard Packages</h4>
                    <div className="cards packages">
                        <div className="uk-grid-small"  uk-grid="">
                            {(this.state.packageList.isLoaded) ? this.handlePackage() : ""}
                        </div>
                    </div>
                </div>
                {/* Standard Packages end */}
            </div>

            {/* Packages end */}


        {/* Deactivate popup */}
        <div id="deactivatepop" className="AlertPOPUP ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3 >
              <span className="uk-inline"> {this.state.buttonname} Package </span> 
              <span className="uk-inline uk-float-right"> 
                  <img src="/images/done_all_black_24dp.svg" />{" "} 
              </span>
            </h3>
            <div className="resolved_content">
                <p><b>Package will be {this.state.buttonname}</b></p>
                <p className="small">This action will affect 1 package. Are you sure?</p>
                <div id="errorAlert"></div>
                <div id="successAlert"></div>
              <form className="uk-form-stacked uk-margin-medium-top" action="javascript:void(0);">
                <div className="ModalStyleBtns uk-float-right">
                    <button className="CnclBtn" onClick={this.pauseCancelClick}>Cancel</button>
                    <button className="AddTeamBtn" id="deactivateBtn" onClick={this.changePackageStatus}>{this.state.buttonname}</button>
                </div>
            </form>
            </div>
          </div>
        </div>

        {/* Request popup */}
        <div id="requestpop" className="AlertPOPUP ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3 >
              <span className="uk-inline"> Request Package </span> 
              <span className="uk-inline uk-float-right"> 
                  <img src="/images/done_all_black_24dp.svg" />{" "} 
              </span>
            </h3>
            <div className="resolved_content">
                <p><b>Request will be send.</b></p>
                <p className="small">This action will affect 1 package. Are you sure?</p>
                <div id="errorAlertRequest"></div>
                <div id="successAlertRequest"></div>
              <form className="uk-form-stacked uk-margin-medium-top" action="javascript:void(0);">
                <div className="ModalStyleBtns uk-float-right">
                    <button className="CnclBtn" onClick={this.cancelbtnrequest}>Cancel</button>
                    <button className="AddTeamBtn" id="requestBtn" onClick={this.requestPackage}>Request</button>
                </div>
            </form>
            </div>
          </div>
        </div>
                    
        </Content>
        );
        
    }
}
export default Packages;
