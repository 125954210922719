import * as React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import { Header } from "@adobe/react-spectrum";
import {
  Settings,
  Logout,
  Notification,
  NotificationNew,
} from "@carbon/icons-react";
import Constant from "../../App/Constant";
import axios from "axios";
import $ from "jquery";

export class Menu extends React.Component {
  constructor() {
    super();
    // this.callApi = this.callApi.bind(this);
    this.state = {
      total_agents_allowed: 0,
      total_agents_rightnow: 0,
      total_conversations_allowed: 0,
      total_conversations_rightnow: 0,
      total_mau_allowed: 0,
      total_mau_rightnow: 0,
      expiry_date: 0,
      is_expired: 0,
      default: "no",
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      newnotification: "",
    };
  }

  componentDidMount() {
    var classRef = this;
    this.callApi();
    this.newNotificationApi();
    var url = window.location.pathname;

    // console.log(url,'hhhhhhhhhh')

    if (!localStorage.getItem("loginStatus")) {
      window.location.href = "/";
    }
    setInterval(function () {
      if (classRef.state.default == "no" && url == "/dashboard") {
        classRef.callApi();
      }
    }, 10000);

    setInterval(function () {
      if (classRef.state.default == "no") {
        classRef.newNotificationApi();
      }
    }, 10000);
  }

  seenNotification = () => {
    let classRef = this;
    var data = {};
    data = {
      user_id: localStorage.getItem("user_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "has-seen",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.getNotification();
        classRef.setState({ newnotification: "" });
      }
    });
  };

  getNotification = () => {
    let classRef = this;
    var data = {};
    data = {
      user_id: localStorage.getItem("user_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "notifications",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <li>
        {/* <div className="notification_profile_pic">
                <img src="/images/user.png"/>
            </div> */}
        <div className="notification_profile_text">
          <h6 className="title">{response.title}</h6>
          <span className="content">{response.text}</span>
          <span className="DateTime">{response.created_date}</span>
        </div>
      </li>
      // <li><button to="#"><span className="icon"><Settings /></span><span>{response.title}</span></button></li>
    ));
    return result;
  };

  newNotificationApi = () => {
    // console.log("newNotification");
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "has-notification",
      data: { user_id: localStorage.getItem("user_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          newnotification: result.data.data,
        });
      }
    });
  };

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "package-stats",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        var package_stats = result.data.data;
        classRef.setState({
          total_agents_allowed: package_stats.total_agents_allowed,
          total_agents_rightnow: package_stats.total_agents_rightnow,
          total_conversations_allowed:
          package_stats.total_conversations_allowed,
          total_conversations_rightnow:
          package_stats.total_conversations_rightnow,
          expiry_date: package_stats.expiry_date,
          is_expired: package_stats.is_expired,
          total_mau_allowed: package_stats.total_mau_allowed,
          total_mau_rightnow: package_stats.total_mau_rightnow,
           
        });
      }
    });
  };

  logout = () => {
    let classRef = this;

    axios({
      method: "POST",
      url: Constant.url + "loggedout",
      data: {
        company_id: localStorage.getItem("company_id"),
        agent_id: localStorage.getItem("user_id"),
      },
    }).then(function (result) {
      if (result.data.status === true) {
        localStorage.clear();
        window.location.href = "/";
      }
    });
  };

  render() {
    return (
      <div>{this.state.is_expired == "0" ? ( <div className="TopBar uk-text-center"><p>Your subscription has been expired</p>
          </div> ) : ("")}

        <Header id="header" backgroundColor="#000F3C">
          <div className="uk-container uk-container-expand">
            <div className="" uk-grid="">
              <div className="uk-width-auto left-padding">
                <div className="collpase_icon">
                  <a uk-toggle="target:.collapse_area; cls: collapse">
                    {" "}
                    <span uk-icon="menu"></span>
                  </a>
                </div>
              </div>
              <div className="uk-width-1-6@m uk-width-1-3@s left-padding-med">
                <Link to="#" className="toplogo">
                  <div className="mdl_inner">
                    <img src="/images/toplogo.svg" uk-svg="" alt="" />
                  </div>
                </Link>
                <div className="mobileMenuIcon">
                  {/* <a href="#menuIcon2" uk-toggle=""></a> */}
                  <a href="#menuIcon2" uk-toggle="">
                    <img src="/images/mobileMenu.svg" uk-svg="" />
                  </a>

                  <div className="uk-inline profile_action logedin_profile">
                    <button className="" type="button"></button>
                    <div uk-dropdown="mode: click" id="notificationLists">
                      <ul>
                        {/* <li><button to="#"><span className="icon"><Settings /></span><span>Settings</span></button></li>
                              <li><button  onClick={this.logout} ><span className="icon"><Logout /></span><span>Logout</span></button></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-width-expand">
                {localStorage.getItem("role") == 1 ||
                localStorage.getItem("role") == 3 ? (
                  ""
                ) : (
                  <div className="uk-width-expand">
                    <ul className="nav-links uk-float-left">
                      <li>
                        <Link to="/dashboard">Workspace</Link>
                      </li>
                      <li>
                        <Link to="/reports">Insights</Link>
                      </li>
                      <li>
                        <Link to="/integrations">Tools</Link>
                      </li>
                      <li>
                        <Link to="/admin">Admin</Link>
                      </li>
                    </ul>
                    <ul className="nav-links uk-float-right">
                      <li>
                        <a>
                          Agent: {this.state.total_agents_rightnow}/
                          {this.state.total_agents_allowed}
                        </a>
                      </li>
                      <li>
                        <a>
                          Conversations:{" "}
                          {this.state.total_conversations_rightnow}/
                          {this.state.total_conversations_allowed}
                        </a>
                      </li>
                      <li>
                        <a>
                          MAU: {this.state.total_mau_rightnow}/
                          {this.state.total_mau_allowed}
                        </a>
                      </li>
                      <li>
                        <a>Expiry Date: {this.state.expiry_date}</a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="uk-width-auto">
                <ul className="top_right_action_buttons">
                  <li>
                    <div className="uk-inline profile_action Notificationbutton">

                      
                      {this.state.newnotification ? (
                        <button
                          onClick={this.seenNotification}
                          to="#"
                          id="notification"
                        >
                          <NotificationNew />
                        </button>
                      ) : (
                        <button
                          onClick={this.getNotification}
                          to="#"
                          id="notification"
                        >
                          <Notification />
                        </button>
                      )}



                      <div
                        uk-dropdown="mode: click; pos:top-right"
                        id="notificationList"
                      >
                        <ul>
                          {this.state.dataList.isLoaded ? (
                            this.handleTable()
                          ) : this.state.dataList.msg == "not" ? (
                            <li>
                              <div className="uk-text-center uk-height-large">
                                Data not Found
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="uk-text-center uk-height-large">
                                <div className="mdl">
                                  <div className="mdl_inner ">
                                    <div
                                      className="uk-display-block noti_spinner uk-margin-auto"
                                      uk-spinner=""
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="uk-inline profile_action logedin_profile">
                      <button className="" type="button">
                        <img src={localStorage.getItem("login_photo_url")} />
                      </button>

                      <div uk-dropdown="mode: click" id="">
                        <ul className="action_menu">
                          {localStorage.getItem("role") == 1 ||
                          localStorage.getItem("role") == 3 ? (
                            ""
                          ) : (
                            <li>
                              <Link to="/settings">
                                <span className="icon">
                                  <Settings />
                                </span>
                                <span>Settings</span>
                              </Link>
                            </li>
                          )}

                          <li>
                            <button onClick={this.logout}>
                              <span className="icon">
                                <Logout />
                              </span>
                              <span>Logout</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Header>
      </div>
    );
  }
}
