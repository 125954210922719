import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Select from "react-select";
import axios from "axios";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Constant from "../../../App/Constant";
import { ContextualHelp } from "@adobe/react-spectrum";
import {
  CaretDown,
  Checkmark,
  Download,
  MessageQueue,
  InProgress,
  Information,
  ViewFilled,
  ReplyAll,
  Send,
  NotSent,
  Chat,
  Search,
  TrashCan,
  Add,
  AddAlt,
  Edit,
  ArrowRight,
  OverflowMenuVertical,
  Close,
} from "@carbon/icons-react";

class QuickReplies extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTempDescriptionChange =
      this.handleTempDescriptionChange.bind(this);
    this.handleTempNameChange = this.handleTempNameChange.bind(this);
    this.handlekeywordsChange = this.handlekeywordsChange.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      template_name: "",
      temp_description: "",
      keywords: "",
      temp_keywords: "",
      template_id: "",
      advancesearch: "",
      rowsCountQuick: 0,
    };
  }

  handleTempNameChange(event) {
    this.setState({ template_name: event.target.template_name });
    // console.log("first name updated");
  }

  handleTempDescriptionChange(event) {
    this.setState({ temp_description: event.target.temp_description });
  }

  handlekeywordsChange(event) {
    this.setState({ temp_keywords: event.target.temp_keywords });
  }

  componentDidMount() {
    var pageCount = 1;
    $("#field_wrapper").on("click", ".deleteBtn", function () {
      $(this).parent().parent().parent().remove();
    });

    this.callApi(pageCount);

    // if(!localStorage.getItem('role')=='3'){
    //   window.location.href = "/";
    //  }

    $("#redcross").hide();
  }

  EditTemplateModal = (id) => {
    $("#EditNewBot").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findtemplate(id);
  };

  closeEditNewBot = (id) => {
    $("#EditNewBot").removeClass("uk-flex uk-open");
  };

  findtemplate = (id) => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let template_id = id;
    axios({
      method: "POST",
      url: Constant.url + "single-chat-templates",
      data: { company_id: company_id, template_id: template_id },
    }).then(function (result) {
      if (result.data.status === true) {
        // console.log(result.data);
        var template = result.data.data;
        classRef.setState({
          template_name: template.name,
          temp_description: template.description,
          temp_keywords: template.keywords,
          template_id: template.id,
        });
      }
    });
  };

  callApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "chat-templates",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountQuick: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountQuick: result.data.rowscount,
        });
      }
    });
  };

  QuickSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#QuickSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "chat-templates",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        search:$("#searchwordd").val(),
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountQuick: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountQuick: result.data.rowscount,
        });
      }
    });
  };

  handleQuickClick = (data) => {
    let currentPage = data.selected + 1;
    this.QuickSorting(currentPage);
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  closeConsentss = () => {
    $("#AddConsent").removeClass("uk-flex uk-open");
  };

  deleteTemplate = () => {
    var id = $("#addconsents").val();
    let classRef = this;
    var template_id = id;
    var company_id = localStorage.getItem("company_id");
    var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "delete-chat-template",
      data: {
        company_id: company_id,
        template_ids: template_id,
        is_deleted: is_deleted,
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/quick-replies";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 1000);

        window.location.href = "/quick-replies";
      }
    });
  };

  addconsents = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddConsent").addClass("uk-flex uk-open");
    $("#addconsents").val(id);
  };

  editTag = () => {
    $("#edittags").text("Processing");
    $("#edittags").prop("disabled", true);

    let classRef = this;

    var data = {};
    data = {
      name: $("#edittagName").val(),
      description: $("#editdescription").val(),
      tag_id: $("#tag_id").val(),
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "tag-edit",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/tags";
        } else {
          $("#editerrorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#edittags").text("Edit Tag");
          $("#edittags").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  deleteWebhook = () => {
    var id = $("#addconsent").val();
    let classRef = this;
    var company_id = id;
    var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "delete-company",
      data: { company_id: company_id, delete: is_deleted },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/company";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 1000);

        window.location.href = "/company";
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr id={"temp_" + response.id}>
        <td>
          <input
            className="uk-checkbox autoSelects"
            name="multi_check[]"
            value={response.id}
            type="checkbox"
          />
        </td>
        <td>
          <span>{response.name}</span>
        </td>
        <td>
          <span>{response.description}</span>
        </td>
        <td>
          <span>{response.keywords}</span>
        </td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.EditTemplateModal(response.id)}><Edit /></button></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addconsents(response.id)}><TrashCan /></button></td>
      </tr>
    ));

    return result;
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchwordd").val();
    this.setState({ advancesearch: searchword });

    if (searchword !== "") {
      $("#redcross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "chat-templates",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountQuick: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountQuick: result.data.rowscount,
        });
      }
    });
  };

  deleteMultiTemp = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#temp_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "delete-chat-template",
      data: {
        company_id: localStorage.getItem("company_id"),
        template_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        window.location.reload();
      }
    });
  };

  selectCheckboxTemp = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_alls").prop("checked", true);
        $(".autoSelects").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_alls").prop("checked", false);
        $(".autoSelects").prop("checked", false);
      });
    }
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  edittingTemplate = () => {
    // console.log("Javevevevev");

    $("#edittemplate").text("Processing");
    $("#edittemplate").prop("disabled", true);
    let classRef = this;
    var template_id = $("#template_id").val();

    var data = {};
    data = {
      name: $("#new_temp").val(),
      description: $("#new_description").val(),
      keywords: $("#keywordsEdit").val(),
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-chat-template/" + template_id,
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/quick-replies";
        } else {
          $("#editerrorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#edittemplate").text("Edit Template");
          $("#edittemplate").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  advanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      status: $("#status").val(),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.status) {
      var key = key + " Status: " + $("#status :selected").text();
    }

    classRef.setState({ advancesearch: key });

    axios({
      method: "POST",
      url: Constant.url + "companies",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callApi();
    $("#redcross").hide();
  };

  addNewTemplate = () => {
    $("#NewBot").addClass("uk-flex uk-open");
  };

  closeNewBot = () => {
    $("#NewBot").removeClass("uk-flex uk-open");
  };

  addTemplate = () => {
    $("#addtemplate").text("Processing");
    $("#addtemplate").prop("disabled", true);
    let classRef = this;
    var data = {};
    data = {
      name: $("#tempname").val(),
      description: $("#tempdescription").val(),
      keywords: $("#keywords").val(),
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "add-chat-template",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/quick-replies";

          $("#addtemplate").text("Create Template");
          $("#addtemplate").prop("disabled", false);
          $("#tagtemp").addClass("uk-active");
          $("#tagtab").removeClass("uk-active");
          $("#tagtemplist").addClass("uk-active");
          $("#tagtablist").removeClass("uk-active");
          $("#tagtemp > a").attr("aria-expanded", "true");
          $("#tagtab > a").attr("aria-expanded", "false");
          $("#tagtemplistbutton").addClass("uk-active");
          $("#tagtablistbutton").removeClass("uk-active");
          classRef.callApi();
          classRef.callTemplateApi();
          $("#NewBot").removeClass("uk-flex uk-open");
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#addtemplate").text("Create Template");
          $("#addtemplate").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  downloadtemplate = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "download-chat-templates?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  render() {
    const options = [
      { value: "Urgent", label: "Urgent" },
      { value: "Resolved", label: "Resolved" },
    ];
    return (
      <Content>
        <div
          className="contactContainer BroadcastingContainer "
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="tagInputLabel">
                  <h3>Quick Replies</h3>
                </div>
                <div className="tagInput">
                  <div className="search_field">
                    <form action="javascript:void(0)">
                      <input
                        className="uk-input"
                        type="text"
                        onChange={this.searchrecord}
                        name="searchwordd"
                        id="searchwordd"
                        value={this.state.advancesearch}
                        placeholder="Search through name"
                      />
                    </form>
                    <div class="CampaignReports Filtermain">
                      <div className="uk-width-auto">
                        <button
                          id="redcross"
                          style={{ right: "10px" }}
                          onClick={this.advSearchClear}
                        >
                          <Close />
                        </button>
                        {/* <button onClick = {this.addSearchModal}>
                                                <img src="/images/newfilter.svg" uk-svg/>
                                            </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2@m">
                <button
                  className="newFlowBtn uk-float-right"
                  onClick={this.addNewTemplate}
                >
                  <Add /> <span>New Chat Template</span>
                </button>
                <button
                  class="newFlowBtn uk-float-right uk-margin-right"
                  onClick={this.downloadtemplate}
                >
                  <Add /> <span>Export Chat Template</span>
                </button>
              </div>
            </div>
          </div>
          <div className="numberSorting uk-float-right">
            <div class="uk-inline">
              <label>
                Show
                <select
                  onChange={(e) => this.QuickSorting()}
                  id="QuickSortingBtn"
                >
                  {/* <option disabled selected>Sorting</option> */}
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>500</option>
                </select>
                entries
              </label>
            </div>
          </div>
          <div className="tableSec">
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>
                    <label>
                      {/* <input className="uk-checkbox" type="checkbox" /> */}
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        id="select_alls"
                        onClick={this.selectCheckboxTemp}
                      />
                    </label>

                    <div className="uk-inline drpIconBox">
                      <button className="iconTble" type="button">
                        <CaretDown />
                      </button>
                      <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li>
                            <a onClick={this.deleteMultiTemp}>
                              Delete Selected Rows
                            </a>
                          </li>
                          {/* <li><a href="#">All</a></li>
                                <li><a href="#">Recently Modified</a></li>
                                <li><a href="#">Favourite</a></li> */}
                        </ul>
                      </div>
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>KEYWORDS</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.state.dataList.isLoaded ? (
                  this.handleTable()
                ) : this.state.dataList.msg == "not" ? (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        Data not Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        <div className="mdl">
                          <div className="mdl_inner">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {this.state.rowsCountQuick > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"...."}
                  pageCount={this.state.rowsCountQuick}
                  onPageChange={this.handleQuickClick}
                  containerClassName={"uk-pagination uk-float-right"}
                  activeClassName={"uk-active"}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={6}
                />
              ) : (
                ""
              )}
            </table>
          </div>
        </div>

        {/* Create New Bot Field */}
        <div
          id="NewBot"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Create New Chat Template</h3>

            <div id="errorAlert"></div>
            <div id="successAlert"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="user_cycle">
                  Field Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Field Name</b>
                      </h4>
                      <p>How this User Field appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="tempname"
                    name="tempname"
                    type="text"
                    placeholder="Field Name"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Description (Optional)
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Description</b>
                      </h4>
                      <p>
                        A brief note about the User Field for you and other
                        admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="tempdescription"
                    name="tempdescription"
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="user_cycle">
                  Keywords
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="keywords"
                    name="keywords"
                    type="text"
                    placeholder="Keywords"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button className="CnclBtn" onClick={this.closeNewBot}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addtemplate"
                    onClick={this.addTemplate}
                  >
                    Create Template
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="EditNewBot"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit Chat Template</h3>
            <div id="editerrorAlert"></div>
            <div id="editsuccessAlert"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="user_cycle">
                  Field Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Field Name</b>
                      </h4>
                      <p>How this User Field appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="template_id"
                    value={this.state.template_id}
                    name="template_id"
                    type="hidden"
                  />
                  <input
                    className="uk-input"
                    id="new_temp"
                    value={this.state.template_name}
                    onChange={this.handleTempNameChange}
                    name="new_temp"
                    type="text"
                    placeholder="user_cycle"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Description (Optional)
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Description</b>
                      </h4>
                      <p>
                        A brief note about the User Field for you and other
                        admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="new_description"
                    onChange={this.handleTempDescriptionChange}
                    value={this.state.temp_description}
                    name="new_description"
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="user_cycle">
                  Keywords
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="keywordsEdit"
                    value={this.state.temp_keywords}
                    onChange={this.handlekeywordsChange}
                    name="keywordsEdit"
                    type="text"
                    placeholder="Keywords"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button className="CnclBtn" onClick={this.closeEditNewBot}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="edittemplate"
                    onClick={this.edittingTemplate}
                  >
                    Edit Template
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="AddConsent"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Template?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsentss}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsents"
                    onClick={this.deleteTemplate}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form
              id="advsearchform"
              className="uk-form-stacked"
              action="javascript:void(0);"
            >
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="to_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="from_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Status
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="status">
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="1">Active</option>
                    <option value="0">In Active</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}
export default QuickReplies;
