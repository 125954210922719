import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { ContextualHelp } from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from "axios";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import PageActionBar from "../../Sections/PageActionBar";
import {
  Search,
  Filter,
  CaretDown,
  Information,
  Add,
  Edit,
  TrashCan,
  Download,
  OverflowMenuVertical,
  Close,
  AddAlt,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  Time,
} from "@carbon/icons-react";
import { Multiselect } from "multiselect-react-dropdown";

class Teams extends React.Component {
  constructor() {
    super();
    this.callTeamApi = this.callTeamApi.bind(this);
    this.callAgentApi = this.callAgentApi.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.handleRoles = this.handleRoles.bind(this);
    this.onSelectAgents = this.onSelectAgents.bind(this);
    this.onRemoveAgents = this.onRemoveAgents.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onSelectNewAgents = this.onSelectNewAgents.bind(this);
    this.onNewRemoveAgents = this.onNewRemoveAgents.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);

    this.state = {
      TeamList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      AgentsList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      role: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      selectOption: [],
      agents: [],
      new_name: "",
      users: "",
      team: "",
      old_agents: [],
      rowsCountTeam: 0,
      rowsCountAgent: 0,
    };
  }

  componentDidMount() {
    var pageCount = 1;
    this.callTeamApi(pageCount);
    this.callAgentApi(pageCount);
    this.getAgents();
    this.getRoles();
    if(localStorage.getItem('loginStatus')== 1){
      let screens = localStorage.getItem('rolesceens').split(',');
      var checking = screens.includes("6");
       if(checking == true){

          }else{
              window.location.href = "/"; 
          }
      }
      else{
          window.location.href = "/"; 
      }
    $("#cross").hide();
    $("#across").hide();
  }

  onSelectAgents(selectedList, selectedItem) {
    this.setState({ agents: selectedList });
  }
  onRemoveAgents(selectedList, removedItem) {
    this.setState({ agents: selectedList });
  }

  handleNameChange(event) {
    this.setState({ new_name: event.target.new_name });
  }

  onSelectNewAgents(selectedList, selectedItem) {
    this.setState({ old_agents: selectedList });
  }
  onNewRemoveAgents(selectedList, removedItem) {
    this.setState({ old_agents: selectedList });
  }

  handleFirstNameChange(event) {
    this.setState({ first_name: event.target.first_name });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.email });
  }

  handlePhoneChange(event) {
    this.setState({ phone: event.target.phone });
  }

  handleRoleChange(event) {
    this.setState({ roles: event.target.roles });
  }

  getAgents = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "get-agents",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          agentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  callTeamApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "teams",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          TeamList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTeam: result.data.rowscount,
        });
      } else {
        classRef.setState({
          TeamList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTeam: result.data.rowscount,
        });
      }
    });
  };

  TeamSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#TeamSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "teams",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
        search: $("#searchwordteam").val()
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          TeamList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTeam: result.data.rowscount,
        });
      } else {
        classRef.setState({
          TeamList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTeam: result.data.rowscount,
        });
      }
    });
  };

  handleTeamClick = (data) => {
    let currentPage = data.selected + 1;
    this.TeamSorting(currentPage);
  };

  handleSegmentsClick = (data) => {
    let currentPage = data.selected + 1;
    this.SegmentsSorting(currentPage);
  };

  handleTeamTable = () => {
    let result = "";
    let index = 0;
    result = this.state.TeamList.list.map((user) => (
      <tr id={"bteam_" + user.id}>
        <td>
          <label>
            <input
              className="uk-checkbox autoSelectteam"
              name="multi_check[]"
              value={user.id}
              type="checkbox"
            />
          </label>
          {/* <input className="star"  checked ={(user.is_star == 1 )? true : false} type="checkbox" title="bookmark page" /> */}
        </td>
        <td>
          <span><Link to={"/team-detail/"+user.id}>{user.name}</Link></span>
        </td>
        <td>{user.counter}</td>
        <td><button className="iconcircleBtn success" uk-tooltip="title: Download Data" onClick={(e) => this.download(user.id)}><Download /></button></td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.EditTeamModal(user.id)}><Edit /></button></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addTeamconsent(user.id)}><TrashCan /></button></td>
      </tr>
    ));
    return result;
  };

  searchnum = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    // console.log(searchword);
    this.setState({ advanceautosearch: searchword });
  };

  callAgentApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "agents",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          AgentsList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountAgent: result.data.rowscount,
        });
      } else {
        classRef.setState({
          AgentsList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountAgent: result.data.rowscount,
        });
      }
    });
  };

  handleTeamClick = (data) => {
    let currentPage = data.selected + 1;
    this.TeamSorting(currentPage);
  };

  handleAgentClick = (data) => {
    let currentPage = data.selected + 1;
    this.AgentSorting(currentPage);
  };

  AgentSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#AgentSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "agents",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        search: $("#searchwordagent").val(),
        dateto: $("#to_date").val(),
        datefrom: $("#from_date").val(),
        role: $("#search_role").val(),
        status: $("#status").val(),
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          AgentsList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountAgent: result.data.rowscount,
        });
      } else {
        classRef.setState({
          AgentsList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountAgent: result.data.rowscount,
        });
      }
    });
  };

  handleAgentTable = () => {
    let result = "";
    let index = 0;
    result = this.state.AgentsList.list.map((response, index) => (
      <tr id={"contact_" + response.id}>
        <td>
          <label>
            <input
              className="uk-checkbox autoSelect"
              name="multi_check[]"
              value={response.id}
              type="checkbox"
            />
          </label>
          {/* <input
                className="star"
                type="checkbox"
                title="bookmark page"
                checked ={(response.is_star == 1 )? true : false}
              /> */}
        </td>
        <td>
          <div className="avatarIcon">
            <img src={response.avatar} alt="Image" />
          </div>
        </td>
        <td>
          <span>{response.agent_name}</span>
        </td>
        <td>{response.phone}</td>
        <td>
          <Link to="">{response.email}</Link>
        </td>

        <td>{response.role}</td>
        <td>{response.team}</td>
        {/* <td>{response.status}</td> */}
        <td>
              <button className="ctmBtn infoBtn">{response.status}</button>
            </td>
        <td>{response.contact_time}</td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.EditAgentModal(response.id)}><Edit /></button></td>
        {response.role == "Administrator" ? (
                    ""
                  ) : (
                    <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addAgentconsent(response.id)}><TrashCan /></button></td>
                  )}
      </tr>
    ));
    return result;
  };

  getRoles = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "list-roles",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          role: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          role: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  handleRoles = () => {
    let result = "";
    let index = 0;
    result = this.state.role.list.map((response, index) => (
      <option value={response.id}>{response.role}</option>
    ));
    return result;
  };

  createTeam = () => {
    $("#addteam").text("Processing");
    $("#addteam").prop("disabled", true);

    let classRef = this;
    var agents = "";
    agents = Object.keys(classRef.state.agents)
      .map(function (k) {
        return classRef.state.agents[k].id;
      })
      .join(",");
    var nameArr = agents.split(",");

    var data = {};
    data = {
      name: $("#teamName").val(),
      company_id: localStorage.getItem("company_id"),
      user_id: localStorage.getItem("user_id"),
      members: nameArr,
    };
    axios({
      method: "POST",
      url: Constant.url + "add-team",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successTeamAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.href = "/teams";
        } else {
          $("#errorTeamAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#addteam").text("Add Team");
          $("#addteam").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  download = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "team-download?company_id=" +
        localStorage.getItem("company_id") +
        "&team_id=" +
        team_id,"_blank"
    );
  };

  downloadTeams = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "download-teams?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  downloadAgents = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "download-agents?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  EditTeamModal = (id) => {
    $("#EditTeamModal").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findteam(id);
  };

  findteam = (id) => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let team_id = id;
    axios({
      method: "POST",
      url: Constant.url + "single-team",
      data: { company_id: company_id, team_id: team_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var team = result.data.data;
        var child = team.users;
        classRef.setState({
          old_agents: child,
          new_name: team.name,
          users: team.users,
          team_id: team.id,
        });
      }
    });
  };

  editTeam = () => {
    $("#editteam").text("Processing");
    $("#editteam").prop("disabled", true);

    let classRef = this;
    var agents = "";
    var team_id = $("#team_id").val();
    agents = Object.keys(classRef.state.old_agents)
      .map(function (k) {
        return classRef.state.old_agents[k].id;
      })
      .join(",");
    var nameArr = agents.split(",");

    var data = {};
    data = {
      name: $("#new_teamName").val(),
      company_id: localStorage.getItem("company_id"),
      user_id: localStorage.getItem("user_id"),
      members: nameArr,
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-team/" + team_id,
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessTeamAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.href = "/teams";
        } else {
          $("#editerrorTeamAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#editteam").text("Edit Team");
          $("#editteam").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  deleteTeam = (id) => {
    let classRef = this;
    var id = $("#addteamconsent").val();
    var team = id;
    var company_id = localStorage.getItem("company_id");
    var is_deleted = 1;
    var user_id = localStorage.getItem("user_id");

    axios({
      method: "POST",
      url: Constant.url + "edit-team/" + team,
      data: {
        company_id: company_id,
        is_deleted: is_deleted,
        user_id: user_id,
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/teams";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 1000);

        window.location.href = "/teams";
      }
    });
  };

  deleteMultiTeam = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#bteam_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "bulk-delete-teams",
      data: {
        company_id: localStorage.getItem("company_id"),
        webhook_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        //  window.location.reload();
      }
    });
  };

  selectTeamCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all_team").prop("checked", true);
        $(".autoSelectteam").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all_team").prop("checked", false);
        $(".autoSelectteam").prop("checked", false);
      });
    }
  };

  searchteam = () => {
    let classRef = this;
    var searchword = $("#searchwordteam").val();

    this.setState({ searchteam: searchword });

    if (searchword !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "teams",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          TeamList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTeam: result.data.rowscount,
        });
      } else {
        classRef.setState({
          TeamList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTeam: result.data.rowscount,
        });
      }
    });
  };

  searchagent = () => {
    let classRef = this;
    var searchword = $("#searchwordagent").val();
    // console.log(searchword);

    if (searchword !== "") {
      $("#across").show();
    }
    this.setState({ advancesearch: searchword });

    axios({
      method: "POST",
      url: Constant.url + "agents",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          AgentsList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountAgent: result.data.rowscount,
        });
      } else {
        classRef.setState({
          AgentsList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountAgent: result.data.rowscount,
        });
      }
    });
  };

  addAgent = () => {
    $("#addAgent").text("Processing");
    $("#addAgent").prop("disabled", true);
    let classRef = this;
    var data = {};
    data = {
      role: $("#role").val(),
      full_name: $("#full_name").val(),
      phone: $("#phone").val(),
      email: $("#email").val(),
      password: $("#password").val(),
      password_confirmation: $("#password_confirmation").val(),
      invitation: "no",
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "add-agent",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAgentAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/agents";
        } else {
          $("#errorAgentAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          $("#addAgent").text("Add Team");
          $("#addAgent").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  deleteAgent = () => {
    var id = $("#addconsentagent").val();
    let classRef = this;
    var agent_id = id;
    var company_id = localStorage.getItem("company_id");
    var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "delete-agent",
      data: {
        company_id: company_id,
        agent_id: agent_id,
        is_deleted: is_deleted,
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/teams";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 1000);

        window.location.href = "/teams";
      }
    });
  };

  EditAgentModal = (id) => {
    $("#EditAgentsModal").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findagent(id);
  };

  findagent = (id) => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let agent_id = id;
    axios({
      method: "POST",
      url: Constant.url + "single-agent",
      data: { company_id: company_id, agent_id: agent_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var agent = result.data.data;
        classRef.setState({
          first_name: agent.first_name,
          roles: agent.role,
          phone: agent.phone,
          email: agent.email,
          agent_id: agent.id,
        });
      }
    });
  };

  edittingAgent = () => {
    $("#editAgent").text("Processing");
    $("#editAgent").prop("disabled", true);
    let classRef = this;

    var data = {};
    data = {
      role: $("#new_role").val(),
      first_name: $("#new_full_name").val(),
      phone: $("#new_phone").val(),
      email: $("#new_email").val(),
      password: $("#new_password").val(),
      agent_id: $("#agent_id").val(),
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-agents",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAgentAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          // window.location.href = "/agents";
          window.location.reload();

        } else {
          $("#editerrorAgentAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#editAgent").text("Edit Team");
          $("#editAgent").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  selectAgentCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  deleteMultiAgent = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#contact_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "delete-bulk-agentss",
      data: {
        company_id: localStorage.getItem("company_id"),
        agent_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        window.location.reload();
      }
    });
  };

  advanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      role: $("#search_role").val(),
      status: $("#status").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.role) {
      var key = key + " Role: " + $("#search_role :selected").text();
    }
    if (data.status) {
      var key = key + " Status: " + $("#status :selected").text();
    }

    if (key !== "") {
      $("#across").show();
    }

    classRef.setState({ advancesearch: key });

    axios({
      method: "POST",
      url: Constant.url + "agents",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          AgentsList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountAgent: result.data.rowscount,
        });

        $("#advSearchModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          AgentsList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountAgent: result.data.rowscount,
        });
        $("#advSearchModal").removeClass("uk-flex uk-open");
      }
    });
  };

  addAgentconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddAgentResolved").addClass("uk-flex uk-open");
    $("#addconsentagent").val(id);
  };

  closeAgentConsent = () => {
    $("#AddAgentResolved").removeClass("uk-flex uk-open");
  };

  TeamSearchClear = () => {
    let classRef = this;
    classRef.setState({ searchteam: "" });
    classRef.callTeamApi();
    $("#cross").hide();
  };

  AgentSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callAgentApi();
    $("#across").hide();
  };

  closeTeamConsent = () => {
    $("#AddTeamResolved").removeClass("uk-flex uk-open");
  };

  addTeamconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddTeamResolved").addClass("uk-flex uk-open");
    $("#addteamconsent").val(id);
  };

  addTeamModal = () => {
    $("#AddTeamModal").addClass("uk-flex uk-open");
  };

  closeTeamModal = () => {
    $("#AddTeamModal").removeClass("uk-flex uk-open");
  };

  editTeamModal = () => {
    $("#EditTeamModal").addClass("uk-flex uk-open");
  };

  editcloseTeamModal = () => {
    $("#EditTeamModal").removeClass("uk-flex uk-open");
  };

  addAgentModal = () => {
    $("#AddAgentsModal").addClass("uk-flex uk-open");
  };

  closeAgentModal = () => {
    $("#AddAgentsModal").removeClass("uk-flex uk-open");
  };
  CloseeditAgentModal = () => {
    $("#EditAgentsModal").removeClass("uk-flex uk-open");
  };

  advSearchModal = () => {
    $("#advSearchModal").addClass("uk-flex uk-open");
  };

  closeadvSearchModal = () => {
    $("#advSearchModal").removeClass("uk-flex uk-open");
  };

  clearFilters = () => {
    $("#from_date").val("");
    $("#to_date").val("");
    $("#search_role").val("");
    $("#status").val("");
  };

  render() {
    return (
      <Content>
        <div
          className="AutomationContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          {/* Automation Tab Start */}
          <div className="TabsHeadBox">
            <ul className="tabsBtns desktop" uk-switcher="connect: .multi_div">
              <li id="tagtab">
                <a href="#">Team</a>
              </li>
              <li id="tagtemp">
                <a href="#">Agents</a>
              </li>
            </ul>

            <div class="mobTabsBtn">
              <p class="AnimFade uk-scrollspy-inview uk-animation-fade">Team</p>
              <ul className="tabsBtns" uk-switcher="connect: .multi_div">
                <li id="tagtab">
                  <a href="#">Team</a>
                </li>
                <li id="tagtemp">
                  <a href="#">Agents</a>
                </li>
              </ul>
            </div>

            <ul className="uk-switcher multi_div" id="tabsBtns">
              <li id="tagtablistbutton">
                <div className="searchnFlowBtn mobile audiencesearchnFlowBtn">
                  <form className="uk-search" action="javascript:void(0)">
                    <div className="uk-width-auto">
                      <input
                        className="uk-search-input"
                        onChange={this.searchteam}
                        value={this.state.searchteam}
                        type="search"
                        name="searchwordteam"
                        id="searchwordteam"
                        placeholder="Search through team name"
                      />
                    </div>
                    <div
                      class="Filtermain teamTabFiltermain"
                      style={{ right: "7%", left: "auto" }}
                    >
                      <div className="uk-width-auto">
                        <button
                          id="cross"
                          style={{ right: "0px" }}
                          onClick={this.TeamSearchClear}
                        >
                          <Close />
                        </button>
                      </div>
                    </div>
                  </form>
                  <button
                    className="newFlowBtn uk-float-right"
                    onClick={this.addTeamModal}
                  >
                    <Add /> <span>Add Team</span>
                  </button>

                  <button
                    class="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.downloadTeams}
                  >
                    <Add /> <span>Export Teams</span>
                  </button>
                </div>
              </li>

              <li id="tagtemplistbutton">
                <div className="searchnFlowBtn mobile">
                  <form className="uk-search" action="javascript:void(0)">
                    <div className="uk-width-auto">
                      <input
                        className="uk-search-input"
                        type="text"
                        onChange={this.searchagent}
                        name="searchwordagent"
                        id="searchwordagent"
                        value={this.state.advancesearch}
                        placeholder="Search through name"
                      />
                    </div>

                    <div
                      class="Filtermain teamAgentFiltermain"
                      style={{ right: "7%" }}
                    >
                      <div className="uk-width-auto">
                        <button
                          id="across"
                          style={{ right: "30px" }}
                          onClick={this.AgentSearchClear}
                        >
                          <Close />
                        </button>
                        <button
                          className="popadvanceFilter"
                          onClick={this.advSearchModal}
                        >
                          <img src="/images/newfilter.svg" uk-svg />
                        </button>
                      </div>
                    </div>
                  </form>
                  <button
                    className="newFlowBtn uk-float-right"
                    onClick={this.addAgentModal}
                  >
                    <Add /> <span>Add Agent</span>
                  </button>
                  <button
                    class="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.downloadAgents}
                  >
                    <Add /> <span>Export Agents</span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div id="errorAlertmain"></div>
          <ul className="uk-switcher multi_div">
            <li id="tagtablist">
              <div className="numberSorting uk-float-right">
                <div class="uk-inline">
                  <label>
                    Show
                    <select
                      onChange={(e) => this.TeamSorting()}
                      id="TeamSortingBtn"
                    >
                      {/* <option disabled selected>Sorting</option> */}
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="tableSec">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-divider ">
                    <thead>
                      <tr>
                        <th>
                          <label>
                            <input
                              className="uk-checkbox"
                              type="checkbox"
                              id="select_all_team"
                              onClick={this.selectTeamCheckbox}
                            />
                          </label>
                          <div className="uk-inline drpIconBox">
                            <button className="iconTble" type="button">
                              <CaretDown />
                            </button>
                            <div uk-dropdown="mode: click">
                              <ul className="uk-nav uk-dropdown-nav">
                                <li class="hintAlert">
                                  <a onClick={this.deleteMultiTeam}>
                                    <TrashCan />
                                    Delete Selected Rows
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </th>
                        <th>TEAM NAME</th>
                        <th>TEAM SIZE</th>

                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.TeamList.isLoaded ? (
                        this.handleTeamTable()
                      ) : this.state.TeamList.msg == "not" ? (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              Data not Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              <div className="mdl">
                                <div className="mdl_inner">
                                  <div uk-spinner=""></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    
                  </table>
                  {this.state.rowsCountTeam > 1 ? (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"...."}
                        pageCount={this.state.rowsCountTeam}
                        onPageChange={this.handleTeamClick}
                        containerClassName={"uk-pagination uk-float-right"}
                        activeClassName={"uk-active"}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={6}
                      />
                    ) : (
                      ""
                    )}
                </div>
              </div>
            </li>

            <li id="tagtemplist">
              <div className="numberSorting uk-float-right">
                <div class="uk-inline">
                  <label>
                    Show
                    <select
                      onChange={(e) => this.AgentSorting()}
                      id="AgentSortingBtn"
                    >
                      {/* <option disabled selected>Sorting</option> */}
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="tableSec">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-divider CustomTableWidth CustomTableMobileWith">
                    <thead>
                      <tr>
                        <th>
                          <label>
                            <input
                              className="uk-checkbox"
                              type="checkbox"
                              id="select_all"
                              onClick={this.selectAgentCheckbox}
                            />
                          </label>
                          <div className="uk-inline drpIconBox">
                            <button className="iconTble" type="button">
                              <CaretDown />
                            </button>
                            <div uk-dropdown="mode: click">
                              <ul className="uk-nav uk-dropdown-nav">
                                <li class="hintAlert">
                                  <a onClick={this.deleteMultiAgent}>
                                    <TrashCan />
                                    Delete Selected Rows
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </th>
                        <th>AVATAR</th>
                        <th>CONTACT NAME</th>
                        <th>PHONE</th>
                        <th>EMAIL</th>
                        <th>ROLE</th>
                        <th>DEPARTMENT</th>
                        <th> STATUS</th>
                        <th>LAST LOGIN</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.AgentsList.isLoaded ? (
                        this.handleAgentTable()
                      ) : this.state.AgentsList.msg == "not" ? (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              Data not Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              <div className="mdl">
                                <div className="mdl_inner">
                                  <div uk-spinner=""></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    
                  </table>
                  {this.state.rowsCountAgent > 1 ? (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"...."}
                        pageCount={this.state.rowsCountAgent}
                        onPageChange={this.handleAgentClick}
                        containerClassName={"uk-pagination uk-float-right"}
                        activeClassName={"uk-active"}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={6}
                      />
                    ) : (
                      ""
                    )}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div
          id="AddTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Add team</h3>

            <div id="errorTeamAlert"></div>
            <div id="successTeamAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Team name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="teamName"
                    name="teamName"
                    type="text"
                    placeholder="Team 1"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Add agents </label>

                <div className="chipBox">
                  <div className="chipBox CustomChipBox">
                    <Multiselect
                      options={this.state.agentList.list}
                      selectedValues={this.state.agents}
                      onSelect={this.onSelectAgents}
                      onRemove={this.onRemoveAgents}
                      displayValue="name"
                    />
                  </div>
                </div>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeTeamModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.createTeam}
                  >
                    Add team
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="EditTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit team</h3>

            <div id="editerrorTeamAlert"></div>
            <div id="editsuccessTeamAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Team name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="team_id"
                    value={this.state.team_id}
                    name="team_id"
                    type="hidden"
                  />
                  <input
                    className="uk-input"
                    id="new_teamName"
                    value={this.state.new_name}
                    onChange={this.handleNameChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Add agents </label>
                <div className="chipBox">
                  <div className="chipBox CustomChipBox">
                    <Multiselect
                      options={this.state.agentList.list}
                      selectedValues={this.state.old_agents}
                      onSelect={this.onSelectNewAgents}
                      onRemove={this.onRemoveNewAgents}
                      displayValue="name"
                    />
                  </div>
                </div>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.editcloseTeamModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="editteam"
                    onClick={this.editTeam}
                  >
                    Edit team
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="AddTeamResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Team?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeTeamConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteamconsent"
                    onClick={this.deleteTeam}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="AddAgentsModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Add agent</h3>

            <div id="errorAgentAlert"></div>
            <div id="successAgentAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Role
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="role">
                    {this.state.role.isLoaded ? this.handleRoles() : ""}
                  </select>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Full name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="full_name"
                    name="full_name"
                    type="text"
                    placeholder="Full legal name"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Phone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Email address
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="name@example.com"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Password
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Minimum 8 characters"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Confirm password
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    placeholder="Minimum 8 characters"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label>
                  <input
                    className="uk-checkbox"
                    id="invitation"
                    name="invitation"
                    type="checkbox"
                    checked
                  />{" "}
                  Send an email invite
                </label>
                <p className="notice">
                  An invite can be sent later if you're not ready
                </p>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeAgentModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addAgent"
                    onClick={this.addAgent}
                  >
                    Add agent
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="AddAgentResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Agent?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeAgentConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsentagent"
                    onClick={this.deleteAgent}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="EditAgentsModal"
          className="EditAgentsModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit Agent</h3>

            <div id="editerrorAgentAlert"></div>
            <div id="editsuccessAgentAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Role
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="new_role"
                    value={this.state.roles}
                    onChange={this.handleRoleChange}
                  >
                    {this.state.role.isLoaded ? this.handleRoles() : ""}
                  </select>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Full name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="agent_id"
                    value={this.state.agent_id}
                    name="agent_id"
                    type="hidden"
                  />
                  <input
                    className="uk-input"
                    id="new_full_name"
                    value={this.state.first_name}
                    onChange={this.handleFirstNameChange}
                    name="full_name"
                    type="text"
                    placeholder="Full legal name"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Phone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="new_phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handlePhoneChange}
                    type="text"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Email address
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="new_email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    type="text"
                    placeholder="name@example.com"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Password
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="new_password"
                    name="password"
                    type="password"
                    placeholder="Minimum 8 characters"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Confirm password
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    placeholder="Minimum 8 characters"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label>
                  <input
                    className="uk-checkbox"
                    id="invitation"
                    name="invitation"
                    type="checkbox"
                    checked
                  />{" "}
                  Send an email invite
                </label>
                <p className="notice">
                  An invite can be sent later if you're not readyssss
                </p>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.CloseeditAgentModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="editAgent"
                    onClick={this.edittingAgent}
                  >
                    Edit agent
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="advSearchModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date"
                    />
                  </div>
                </div>
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className=" from_date"
                      id="to_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Role
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="search_role">
                    <option value="" disabled selected>
                      Select Role
                    </option>
                    {this.state.role.isLoaded ? this.handleRoles() : ""}
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Status
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="status">
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeadvSearchModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}
export default Teams;
