import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown
  } from "@carbon/icons-react";
import { ChannelsOverviewChart } from "../../Sections/ChannelsOverviewChart";
import { DateRange } from "../../Common/DateRange";
// import DateRangePicker from 'rsuite/DateRangePicker';
// // import { DateRangePicker, Stack } from 'rsuite';
// import subDays from 'date-fns/subDays';
// import startOfWeek from 'date-fns/startOfWeek';
// import endOfWeek from 'date-fns/endOfWeek';
// import addDays from 'date-fns/addDays';
// import startOfMonth from 'date-fns/startOfMonth';
// import endOfMonth from 'date-fns/endOfMonth';
// import addMonths from 'date-fns/addMonths';
  
class InsightsOverview extends React.Component {

    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2023-01-30";
        var from = "2021-01-01";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }


MultiSelectFilter =()=>{
    $(".UltabsBtns").toggleClass("showMenu");
    $(".UltabsBtns > li label").click(function(){
        $(".MultiSelectFilter > p span").text($(this).text());
    });
}


  
  

    render() {
        return (
         <>
            <div uk-grid="">
                <div className="uk-width-1-1">
                    <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                        <p><span>All Channels</span> <ChevronDown /></p>
                        <ul className="UltabsBtns">
                            <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="SMSCheck"><img src="/images/Integrations-sms.jpg" /> SMS <input id="SMSCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="EmlCheck"><img src="/images/Integrations-email.jpg" /> Email <input id="EmlCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="CLCheck"><img src="/images/Integrations-callcenter.jpg" /> Calls <input id="CLCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                        </ul>
                    </div>
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head"><h5>All Channels Overview</h5></div>
                            {/* Chart */}
                            <div className="ChannelsOverviewGraph">
                            <ChannelsOverviewChart
                                stateTo={this.state.to}
                                stateFrom={this.state.from}
                            />
                            </div>
                    </div>
                </div>
                {/* All Channels Performance */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head"><h5 className="uk-heading-divider">All Channels Performance</h5></div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>982</h3>
                                <p>Messages / Calls</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>37.55%</h3>
                                <p>Delivery Rate <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                <div uk-dropdown="mode: click">
                                    <h5>Delivery Rate</h5>
                                    <h6>Delivery Rate = Delivered / (Delivered + Pending + Undelivered + Expired)</h6> 
                                    <p>Message can be rejected because of <b>Do Not Contact</b> enabled, so messages with status <b>"Rejected"</b> are not included in formula.</p>
                                </div>
                                </div></p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>$454.29</h3>
                                <p>Cost</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>$128,529.60</h3>
                                <p>Your Balance</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breakdown by Status */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head"><h5 className="uk-heading-divider">Breakdown by Status</h5></div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>35.64%</h3>
                                <p><img className="Delivered" src="/images/doublecheckmark.svg" uk-svg="" /> Delivered</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>9.78%</h3>
                                <p><img src="/images/doublecheckmark.svg" uk-svg="" /> Pending</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>9.67%</h3>
                                <p><Checkmark /> Undeliverable</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>5.09%</h3>
                                <p><Error /> Rejected</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>39.82%</h3>
                                <p><Error /> Expired</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </>
    );
}
}
export default InsightsOverview;