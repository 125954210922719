import * as React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import axios from "axios";
import $ from "jquery";

class IntegrationsSidebar extends React.Component {

  constructor() {
    super();
    this.state = {
      path: "",
    }
  
  }

  componentDidMount() {

    let classRef = this;
    var url = window.location.pathname;
    var id = url.split("/");
    // console.log(id[2],'sahjjjjjjj');
    this.setState({path: id[2]});

    // {(this.state.path=="facebook-messenger")? className="active" :""}
  
  }



  render() {
    return (
      <aside className="IntegrationsSidebar h_match">
        <h5>All Integrations</h5>
        <ul>


          <li  className={ this.state.path=="whatsapp"? "active": ""}>
            <Link to={"/integrations/whatsapp"}>
              <img src="/images/Integrations-whatsapp.png" alt="" /> <span>WhatsApp</span>
            </Link>
          </li>


          <li  className={ this.state.path=="facebook-messenger"? "active": ""}>
            <Link to={"/integrations/facebook-messenger"}>
              <img src="/images/facebook.png" alt="" /> <span>Facebook Messanger</span>
            </Link>
          </li>


          <li>
            <Link to={"/integrations/instagram"}>
              <img src="/images/instagram.png" alt="" /> <span>Instagram</span>
            </Link>
          </li>


          <li>
            <Link to={"/integrations/whatsapp"}>
              <img src="/images/twitter.png" alt="" /> <span>Twitter</span>
            </Link>
          </li>

          
          <li>
            <Link to={"/integrations/shopify"}>
              <img src="/images/shopify.png" alt="" /> <span>Shopify</span>
            </Link>
          </li>

          <li className={ this.state.path=="email-account"? "active": ""}>
            <Link to={"/integrations/email-account"}>
              <img src="/images/Integrations-email.png" alt="" /> <span>Email</span>
            </Link>
          </li>
          <li>
            <Link to={"/integrations/sms"}>
              <img src="/images/Integrations-sms.png" alt="" /> <span>SMS</span>
            </Link>
          </li>
          <li>
            <Link to={"/integrations/google-sheet"}>
              <img src="/images/Integrations-googlesheets.png" alt="" /> <span>Google Sheets</span>
            </Link>
          </li>
          <li>
            <Link to={"/integrations/whatsapp"}>
              <img src="/images/Integrations-callcenter.png" alt="" /> <span>Call Center</span>
            </Link>
          </li>
          <li>
            <Link to={"/integrations/whatsapp"}>
              <img src="/images/zapier.png" alt="" /> <span>Zapier</span>
            </Link>
          </li>
          <li>
            <Link to={"/integrations/whatsapp"}>
              <img src="/images/zendesk.png" alt="" /> <span>Zendesk</span>
            </Link>
          </li>
          <li>
            <Link to={"/integrations/whatsapp"}>
              <img src="/images/zoho.png" alt="" /> <span>Zoho</span>
            </Link>
          </li>
        </ul>
      </aside>
    );
  }
}

export default IntegrationsSidebar;
