import React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import style from "../../../Assets/css/style.css";
//import InputFeild from "../../../UI_Components/InputFeild"
import { TextField, Button, Form, Checkbox } from "@adobe/react-spectrum";
UIkit.use(Icons);

class GoogleSignUp extends React.Component {
  render() {
    return (
      <section className="login_page signup">
        <div className="uk-container uk-container-expand">
            <div className="signup_logo">
                <Link to="#"><img src="/images/berrytalkslogodark.svg" alt=""/></Link>
            </div>
        </div>
        <div className="mdl">
          <div className="mdl_inner">
            <div className="uk-container uk-container-expand uk-padding-remove">
              <div className="login_page_inner">
                <div className="login_side1">
                  <div className="login_side1_inner">
                    {/* <img className="login_from_logo" src="/images/logo.png" alt="" /> */}
                    <h1>Welcome, Hadi.</h1>
                    <p>Now set your WhatsApp number.</p>
                    
                    <div className="social_signup">
                      <Form
                        maxWidth="size-3600"
                        isQuiet
                        aria-label="Quiet example"
                      >
                        <div className="uk-grid uk-grid-collapse">
                            <div className="uk-width-auto">
                                <div className="fields select_box">
                                <label  className=" uk-form-label" >Code</label>
                                    <select className="uk-select" id="form-stacked-select">
                                        <option>+92</option>
                                        <option>+92</option>
                                    </select>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="fields">
                                    <TextField
                                        width="100%"
                                        marginTop="size-200"
                                        maxWidth="100%"
                                        label="WhatsApp number"
                                        type="number"
                                        scale="medium"
                                        isRequired necessityIndicator="icon"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="fields uk-margin-top">
                          <div className="uk-grid uk-grid-collapse uk-child-width-1-1">
                            <div className="">
                              <Checkbox isEmphasized>I agree to the Terms & conditions and Privacy policy</Checkbox>
                            </div>
                          </div>
                        </div>

                        <div className=" form_action_btn uk-grid-small uk-grid uk-grid-collapse uk-child-width-1-2">
                            <div>
                                {/* <p >  Existing user? <Link to="#"> Sign in</Link> now</p> */}
                            </div>
                            <div>
                                <div className="fields spStyle">
                                    <Button
                                        id="signin"
                                        position="relative"
                                        marginTop="size-200"
                                        right="size-0"
                                        maxWidth="size-1200"
                                        variant="cta"
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </div>
                      </Form>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" copyright uk-position-bottom  ">
            <div className="uk-grid uk-grid-collapse uk-child-width-1-2">
                <div>
                    <ul>
                        <li><Link to="#">Terms of Use</Link></li>
                        <li><Link to="#">Privacy Policy</Link></li>
                    </ul>
                </div>
                <div>
                    <p>© 2022 Convex Interactive. All rights reserved.</p>
                </div>
            </div>
          
        </div>
      </section>
    );
  }
}

export default GoogleSignUp;
