import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const LiveChartStatusDistrubitionChart = () => {

     const data = {
        labels: ['Occupancy', 'Under capacity', 'Ringing', 'Idle', 'In a call', 'Available' , 'Missed Call'],
        datasets: [{
            label: 'All Live Chats',
            data: [100],
            backgroundColor: [
                'rgb(31, 115, 183)',
                'rgb(150, 204, 249)',
                'rgb(246, 193, 118)',
            ],
            borderColor: [
                'rgb(31, 115, 183)',   
                'rgb(150, 204, 249)',
                'rgb(246, 193, 118)',            
            ],
            borderWidth: 5,
            // offset: 10,	
            cutout:'50%',
          },
          { 
            weight: '4',
          },
          {
            label: 'All Live Chats',
            data: [66.06, 1, 33.9,  1,  1, 1 ],
            backgroundColor: [
                'rgb(31, 115, 183)',
                'rgb(150, 204, 249)',
                'rgb(246, 193, 118)',
              
              
            ],
            borderColor: [
                'rgb(31, 115, 183)',
                'rgb(150, 204, 249)',
                'rgb(246, 193, 118)',
            ],
            borderWidth: 1,
            cutout:'10%',
            borderRadius:0
          },
          { 
            weight: 10
          },
          
        ]
        
      };
      const options = {
        // datasetRadiusBuffer: 25,
        // cutoutPercentage: 75,
        plugins : {
        legend: {
          display: true,
          position: "bottom",
          
          // align: 'end',
          labels:{
            // boxWidth: 10,
            boxHeight: 4,
            usePointStyle:true,
            pointStyle: 'circle',
            pointStyleWidth	:4,
            pointStyleHeight: 2,
            
          }
        },
        
        },

        elements: {
            arc: {
                borderWidth: 20,
            },
        },
      };

      const plugins = [{
        beforeDraw: function(chart) {
         var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
            // var fontSize = 25;
            ctx.font = " 36px sans-serif";
            ctx.textBaseline = "top";
            var text = "66.06";
            var text1 = "Under capacity",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2.5;
            ctx.fillStyle = 'rgb(75, 75, 75)';
            ctx.fillText(text, textX, textY-20);
            ctx.fillStyle = 'Under capacity';
            ctx.font = " 14px sans-serif";
            ctx.fillText(text1, Math.round((width - ctx.measureText(text).width) / 2)-30, textY+40);
            ctx.save();
        }
      }]
  return (
    <div>
         <Doughnut data={data} options={options}  plugins={plugins} /> 
    </div>
  )
}
