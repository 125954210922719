import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { AgentStatusChart } from "../../Sections/AgentStatusChart";
import { AssignLiveChat } from "../../Sections/AssignLiveChat";
import { DateRange } from "../../Common/DateRange";
import { ChannelsOverviewChart } from "../../Sections/ChannelsOverviewChart";
import { ClosedConversationChart } from "../../Sections/ClosedConversationChart";
import { LiveChartStatusDistrubitionChart } from "../../Sections/LiveChartStatusDistrubitionChart";
  
class LiveChatAgent extends React.Component {
    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2022-11-01";
        var from = "2022-09-20";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Agent utilization and performance</h1>
                </div>
                
                {/* Performance highlights */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Performance highlights
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Performance highlights</h5>
                                        <p>Contact center performance metrics for conversations that were active in the selected period of time, for the selected channel and queue.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>105</h3>
                                <p>Closed conversations
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Closed conversations</h5>
                                            <p>The number of conversations that match the selected Channel and Queue and were closed within selected period of time.</p>
                                        </div>
                                    </div>
                                </p>
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>2m 40s</h3>
                                <p>Median first response time
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Median first response time</h5>
                                            <p>The first response time is measured from the moment the conversation was assigned to the agent, until the agent's first response.</p>
                                        </div>
                                    </div>
                                </p>
                                
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>5m 27s</h3>
                                <p>Median handling time
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Closed conversations</h5>
                                            <p>The number of conversations that were closed in the selected period of time.</p>
                                        </div>
                                    </div>
                                </p>
                                
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>0</h3>
                                <p>Sentiment</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                 {/* Average breakdown per statuses*/}
                 <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Average breakdown per statuses
                            <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Average breakdown per statuses</h5>
                                        <p>The status of agents assigned to a selected queue in the selected period.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1d 5h</h3>
                                <p>Logged in time</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>-</h3>
                                <p>Away status</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1d 5h</h3>
                                <p>Occupancy
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Occupancy</h5>
                                        <p>Agents are occupied when they spend time in any Available or Busy status.</p>
                                    </div>
                                </div>
                                </p>
                                
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>66.09%</h3>
                                <p>Engaged rate
                                
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1d 3h</h3>
                                <p>Engaged time
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Average breakdown per statuses</h5>
                                        <p>Agents are engaged whenever they spend time with customers in Under capacity, Full capacity, Ringing, In a call or Wrap-up status.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Status distribution*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Status distribution
                            <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Status distribution</h5>
                                        <p>The ratio of time occupied against time spent on breaks.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-1-3">
                                <LiveChartStatusDistrubitionChart/>
                            </div>
                            <div className="uk-width-2-3">
                                    <div className="labelResoltuionTimeMain">
                                        <ul className="labelResoltuionTime">
                                        <li>
                                                <span className="Label">Occupancy</span>
                                                <progress id="js-progressbar" class="uk-progress" value="100" max="100"></progress>
                                                <span className="Percent">100.00%</span>
                                            </li>
                                            <li>
                                                <span className="Label">Under capacity</span>
                                                <progress id="js-progressbar" class="uk-progress" value="66.06" max="100"></progress>
                                                <span className="Percent">66.06%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Ringing</span>
                                                <progress id="js-progressbar" class="uk-progress" value="10" max="100"></progress>
                                                <span className="Percent"> &lt; 1%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Idle</span>
                                                <progress id="js-progressbar" class="uk-progress" value="33" max="100"></progress>
                                                <span className="Percent">33.9%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Between 15 and 30 min</span>
                                                <progress id="js-progressbar" class="uk-progress" value="9" max="100"></progress>
                                                <span className="Percent">3.01%</span>
                                            </li>

                                            <li>
                                                <span className="Label">In a call</span>
                                                <progress id="js-progressbar" class="uk-progress" value="10" max="100"></progress>
                                                <span className="Percent"> &lt; 1%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Available</span>
                                                <progress id="js-progressbar" class="uk-progress" value="10" max="100"></progress>
                                                <span className="Percent"> &lt; 1%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Missed Call</span>
                                                <progress id="js-progressbar" class="uk-progress" value="10" max="100"></progress>
                                                <span className="Percent"> &lt; 1%</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>



                
                


                
                {/* Closed conversations*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <ul className="tabsBtns desktop uk-heading-divider" uk-switcher="connect: .multiTabNewLiveAgent">
                                <li className="uk-inline infoTool"><a>Closed conversations</a></li>
                                <li className="uk-inline infoTool"><a>Median response time</a></li>
                                <li className="uk-inline infoTool"><a>Median handling time</a></li>
                            </ul>
                        </div>
                        <ul className="uk-switcher multiTabNewLiveAgent">
                        <li>
                            <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    <div className="head"><h5>All Channels Overview</h5></div>
                                        {/* Chart */}
                                        <div className="ChannelsOverviewGraph">
                                            <ClosedConversationChart 
                                            stateTo={this.state.to}
                                            stateFrom={this.state.from} />
                                        </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            111
                        </li>
                        <li>
                            222
                        </li>
                        </ul>
                    </div>
                </div>


                {/* Utilization per agent */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Utilization per agent
                            <div className="uk-float-right ">
                                <div className="AgentDropDown">
                                    <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                                        <p><span>Export</span> <ChevronDown /></p>
                                        {/* <ul className="UltabsBtns">
                                            <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                <th className="uk-table-expand">AGENT</th>
                                <th className="uk-table-shrink uk-text-nowrap">LOGGED IN TIME</th>
                                <th className="uk-width-small">AWAY</th>
                                <th className="uk-table-shrink uk-text-nowrap">OCCUPANCY</th>
                                <th className="uk-table-shrink uk-text-nowrap">BUSY</th>
                                <th className="uk-width-small">AVAILABLE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
         </>
    );
}
}
export default LiveChatAgent;