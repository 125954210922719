import * as React from "react";

import EmailBroadcast from "./EmailBroadcast";
import CallAnalytics from "./CallAnalytic";
import CallBroadcast from "./CallBroadcast";


  
class CallMain extends React.Component {

    render() {
        return (
         <>
            <div uk-grid="">
                <div className="uk-width-1-1">
                    <div className="tabsInner">
                        <ul className="tabsBtns" uk-switcher="connect: .InnerMultiTabsWhatsapp">
                            <li>
                                <a href="#">Analytics</a>
                            </li>
                            <li>
                                <a href="#">Broadcast</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="uk-width-1-1">
                    <ul className="uk-switcher InnerMultiTabsWhatsapp">
                        <li><CallAnalytics/></li>
                        <li><CallBroadcast/></li>
                    </ul>
                </div>
            </div>
         </>
    );
}
}
export default CallMain;