import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const BroadCastDeliveryRateChart = () => {

     const data = {
        labels: ['No error', 'Bounced', 'Dropped', 'System error'],
        datasets: [
          {
            label: 'All Live Chats',
            data: [58.65, 12.78, 28.57, 0 , 0 ],
            backgroundColor: [
                '#1F73B7',
                '#96CCF9',
                '#F6C176',
                '#C9252D',
            ],
            borderColor: [
                '#1F73B7',
                '#96CCF9',
                '#F6C176',
                '#C9252D',
               
            ],
            borderWidth: 1,
            // offset: 10,	
            cutout:'90%',
          }
        ]
        
      };
      const options = {
        plugins : {
        legend: {
          display: true,
          position: "bottom",
          
          // align: 'end',
          labels:{
            // generateLabels: (chart) => {
            // const datasets = chart.data.datasets;
            // return datasets[0].data.map((data, i) => ({
            //   text: `${chart.data.labels[i]} ${data}`,
            //   fillStyle: datasets[0].backgroundColor[i],
            // }))},
            // boxWidth: 10,
            boxHeight: 4,
            usePointStyle:true,
            pointStyle: 'circle',
            pointStyleWidth	:5,
            pointStyleHeight: 5,
            
          }
        }
        }
      };

      const plugins = [{
        beforeDraw: function(chart) {
         var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
            // var fontSize = 25;
            ctx.font = " 36px sans-serif";
            ctx.textBaseline = "top";
            var text = "96.67%";
            var text1 = "Delivered",
            textX = Math.round((width - ctx.measureText(text).width) / 2)+10,
            textY = height / 2.5;
            ctx.fillText(text, textX, textY);
            ctx.fillStyle = 'rgb(75, 75, 75)';
            ctx.font = " 14px sans-serif";
            ctx.fillText(text1, Math.round((width - ctx.measureText(text).width) / 2)-10, textY+40);
            ctx.save();
        }
      }]
  return (
    <div>
         <Doughnut data={data} options={options}  plugins={plugins} /> 
    </div>
  )
}
