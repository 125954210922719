import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { AgentStatusChart } from "../../Sections/AgentStatusChart";
import { AssignLiveChat } from "../../Sections/AssignLiveChat";
import { DateRange } from "../../Common/DateRange";
import { LiveChatOverviewResolutionTIme } from "../../Sections/LiveChatOverviewResolutionTIme";
import { WhatsappBusinessMessStatus } from "../../Sections/WhatsappBusinessMessStatus";
import { WhatsappFrequencyChart } from "../../Sections/WhatsappFrequencyChart";
import { WhatsappSmsOverview } from "../../Sections/WhatsappSmsOverview";
import { WhatsappdeliveryTime } from "../../Sections/WhatsappdeliveryTime";
import { WhatsappMonthlyActiveChart } from "../../Sections/WhatsappMonthlyActiveChart";
import { WhatsappMonthlyActiveUserChart } from "../../Sections/WhatsappMonthlyActiveUserChart";
import { SmsOverviewChart } from "../../Sections/SmsOverviewChart";
import { SmsBusinessMessStatus } from "../../Sections/SmsBusinessMessStatus";
import { SmsDeliveryNetwork } from "../../Sections/SmsDeliveryNetwork";
import { SmsDeliveryTimeChart } from "../../Sections/SmsDeliveryTimeChart";
import { SmsInboundNetwork } from "../../Sections/SmsInboundNetwork";
import { SmsInboundCountry } from "../../Sections/SmsInboundCountry";
import { ChatBotMessageChart } from "../../Sections/ChatBotMessageChart";
import { SmsTotalMess } from "../../Sections/SmsTotalMess";
import { EmailOverviewChart } from "../../Sections/EmailOverviewChart";
import { EmailErrorGroup } from "../../Sections/EmailErrorGroup";


  
class EmailAnalytics extends React.Component {
    MultiSelectFilter =()=>{
        $(".UltabsBtns").toggleClass("showMenu");
        $(".UltabsBtns > li label").click(function(){
            $(".MultiSelectFilter > p span").text($(this).text());
        });
    }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="" uk-height-match="target: > div > .uk-card">
                <div className="uk-width-1-2">
                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>Email</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">

                    
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>
                
                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head"><h5>Email Overview</h5></div>
                            {/* Chart */}
                            <div className="ChannelsOverviewGraph">
                            <EmailOverviewChart/>
                            </div>
                    </div>
                </div>
                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Email Traffic Performance
                            </h5>
                        </div>
                        <div uk-grid="">
                        <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>331,560</h3>
                                <p><span uk-icon="icon: check" ></span>Traffic</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>321,652</h3>
                                <p><span uk-icon="icon: check" ></span> Delivered</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>9,908</h3>
                                <p>Bounced</p>
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>39.82%</h3>
                                <p><span uk-icon="icon: check" ></span>Opened</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>$356</h3>
                                <p><span uk-icon="icon: check" ></span>Cost</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Service-level agreemant*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Email Breakdown by Error Group
                            </h5>
                        </div>
                        
                            <div uk-grid="">
                                <div className="uk-width-1-3">
                                   <EmailErrorGroup/>
                                </div>

                                <div className="uk-width-2-3">
                                    <div className="labelResoltuionTimeMain">
                                        <ul className="uk-list heading">
                                            
                                            <li><b>Messages</b></li>
                                            <li><b>Percent age</b></li>
                                        </ul>
                                        <ul className="labelResoltuionTime whatsappmessage" >
                                            <li>
                                                <span className="Label">No error</span>
                                                <progress id="js-progressbar" class="uk-progress delivered" value="80" max="100"></progress>
                                                <span className="Percent">45.86%</span>
                                                <span className="Percent">72</span>
                                            </li>

                                            <li>
                                                <span className="Label">Bounced</span>
                                                <progress id="js-progressbar" class="uk-progress pending" value="15" max="100"></progress>
                                                <span className="Percent">6.02%</span>
                                                <span className="Percent">14</span>
                                            </li>

                                            <li>
                                                <span className="Label">Dropped</span>
                                                <progress id="js-progressbar" class="uk-progress expired" value="11" max="100"></progress>
                                                <span className="Percent">18.7%</span>
                                                <span className="Percent">23</span>
                                            </li>

                                            <li>
                                                <span className="Label">System error</span>
                                                <progress id="js-progressbar" class="uk-progress rejected" value="9" max="100"></progress>
                                                <span className="Percent">22.78%</span>
                                                <span className="Percent">28</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>

                
            </div>
         </>
    );
}
}
export default EmailAnalytics;