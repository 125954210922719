import React, { useEffect } from "react";
import { Content } from "@adobe/react-spectrum";
import { useParams } from "react-router-dom";
import Constant from "../../../App/Constant";
import axios from "axios";
import { useState } from "react";
import { param } from "jquery";

const KeywordReportDetail = () => {
  const params = useParams();
  const [dataList, setDataList] = useState({
    isLoaded: false,
    msg: "Loading...",
    list: [],
  });

  const getKeywordReportDetails = () => {
    axios({
      method: "POST",
      url: Constant.url + "keyword-reports-detail",
      data: {
        keyword: params.keyword,
        datefrom: params.datefrom,
        dateto: params.dateto,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        setDataList({
          isLoaded: true,
          msg: "Loading...",
          list: result.data.data,
        });
      } else {
        setDataList({
          isLoaded: false,
          msg: "not",
          list: [],
        });
      }
    });
  };
  const handleTable = () => {
    let result = "";

    result = dataList.list.map((response, index) => (
      <tr key={index}>
        <td>
          <p>{response.msisdn}</p>
        </td>
        <td>
          <p>{response.total}</p>
        </td>
      </tr>
    ));
    return result;
  };
  useEffect(() => {
    getKeywordReportDetails();
  }, []);
  return (
    <Content>
      <div
        className="TeamContainer contactContainer"
        uk-height-viewport=""
        uk-height-match=".h_match"
      >
        <div className="head">
          <div className=" uk-grid " uk-grid="">
            <div className="uk-width-auto">
              <h3>{params.heading}</h3>
            </div>
          </div>
        </div>
        <div className="tableSec">
          <table className="uk-table uk-table-small uk-table-divider uk-table-middle">
            <thead>
              <tr>
                <th>MSISDN</th>
                <th>COUNT</th>
              </tr>
            </thead>
            <tbody>
              {dataList.isLoaded ? (
                handleTable()
              ) : dataList.msg == "not" ? (
                <tr>
                  <td colSpan="15">
                    <div className="uk-text-center uk-height-large">
                      Data not Found
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="15">
                    <div className="uk-text-center uk-height-large">
                      <div className="mdl">
                        <div className="mdl_inner">
                          <div uk-spinner=""></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Content>
  );
};

export default KeywordReportDetail;
