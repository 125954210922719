import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../App/Constant";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);




export const SmsOverviewChart = () => {

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ] ,
        datasets: [
        {
            label: 'User Initiated Messages',
            data: [10, 7, 0, 7, 9, 22, 11, 8, 10, 25, 15, 18, 20, 30],
            backgroundColor: '#32D74B',
            borderColor: '#32D74B'


        },
        {
            label: 'Business Initiated Messages',
            data:[20, 16, 18, 22, 30, 10, 5, 2, 16, 25, 30, 11, 21, 5],
            backgroundColor: '007ff9',
            borderColor: '#007ff9'

        },
        
        ],
  
  }
        
     


const options = {
    responsive: true,
    plugins: {
    legend: {
    position: 'bottom',
    display: true,
    },
    title: {
    display: true,
    // text: 'Chart.js Line Chart',
    },
    },
}
        
        return <Line options={options} data={data}/>;
      }
