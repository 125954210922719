import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content} from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Select from 'react-select'
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import Constant from "../../../App/Constant";
import {ContextualHelp} from '@adobe/react-spectrum'
import { Multiselect } from 'multiselect-react-dropdown';
import {
  CaretDown,
  Checkmark,
  Download,
  MessageQueue,
  InProgress,
  Information,
  ViewFilled,
  ReplyAll,
  Send,
  NotSent,
  Chat,
  Search,
  TrashCan,
  Add,
  AddAlt,
  Edit, 
  ArrowRight,
  OverflowMenuVertical,
  Close
} from "@carbon/icons-react";

class Role extends React.Component {

  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.getScreen = this.getScreen.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onSelectAgents = this.onSelectAgents.bind(this);
    this.onRemoveAgents = this.onRemoveAgents.bind(this)
    this.oldonSelectAgents = this.oldonSelectAgents.bind(this);
    this.oldonRemoveAgents = this.oldonRemoveAgents.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleAssignChange = this.handleAssignChange.bind(this);

    

    
    this.state = {
      dataList: {
          isLoaded: false,
          msg: 'Loading...',
          list: []
      },
      screenList: {
          isLoaded: false,
          msg: 'Loading...',
          list: []
      },
      tag_name :"",
      description :"",
      tag_id:"",
      status:"",
      advancesearch:"",
      can_chat :"",
      rowsCountTags : 0,
      screens : [],
      oldscreens : [],
      }


  }

  onSelectAgents(selectedList, selectedItem){
    this.setState({screens:selectedList});
  }
onRemoveAgents(selectedList, removedItem){
    this.setState({screens:selectedList});
  }

  oldonSelectAgents(selectedList, selectedItem){
    this.setState({oldscreens:selectedList});
  }
oldonRemoveAgents(selectedList, removedItem){
    this.setState({oldscreens:selectedList});
  }


  handleStatusChange(event){
    this.setState({status: event.target.status});
}


  handleNameChange(event) {
    this.setState({ tag_name: event.target.tag_name });
    // console.log("first name updated")
  }

  handleDescriptionChange(event){
    this.setState({ description: event.target.description });
  }

  handleAssignChange(event){
    this.setState({can_chat: event.target.can_chat});
  }




componentDidMount() {
  var pageCount = 1;
  $("#field_wrapper").on('click','.deleteBtn',function(){
    $(this).parent().parent().parent().remove();
  });

  this.callApi(pageCount);
  this.getScreen();
  
  if(localStorage.getItem('loginStatus')== 1){
    let screens = localStorage.getItem('rolesceens').split(',');
    var checking = screens.includes("12");
     if(checking == true){

        }else{
            window.location.href = "/"; 
        }
    }
    else{
        window.location.href = "/"; 
    }

  $("#cross").hide()
}

getScreen = () => {
  let company_id = localStorage.getItem('company_id');
  let classRef = this;
  axios({
      method: 'POST',
      url: Constant.url + "screens",
      data: { company_id: company_id}
  }).then(function (result) {
      if (result.data.status === true) {
          classRef.setState({
            screenList: {
                  isLoaded: true,
                  msg: 'Loading...',
                  list: result.data.data
              },
          })
      }
  })
}

EditTagModal = (id) =>{
  $("#EditTagModal").addClass('uk-flex uk-open');
  $('.uk-dropdown').removeClass('uk-open');
  this.findtag(id);
}

download = (id) => {

  let classRef = this;
  var tag_id = id;
  var company_id = localStorage.getItem('company_id') 
  window.open(Constant.url +"download-tag?company_id=" +company_id+ "&tag_id=" + tag_id, "_blank")

}


findtag = (id) =>{

let classRef = this;
let company_id = localStorage.getItem('company_id');
let tag_id = id
axios({
method: 'POST',
url: Constant.url + "single-role",
data: { company_id: company_id ,role_id :tag_id }
}).then(function (result) {
if (result.data.status === true) {
  var tag = result.data.data
  var child = tag.screens;

  classRef.setState({
    tag_name:tag.role,
    oldscreens:child,
    tag_id :tag.id,
    status:tag.status,
    can_chat:tag.can_chat,
  })
  
}
})

}









closeEditNewBot = (id) =>{
  $("#EditNewBot").removeClass('uk-flex uk-open');

  
}


addTeamModal = () => {
  $("#AddTagModal").addClass('uk-flex uk-open');
}

closeAgentModal = () => {
  $("#AddTagModal").removeClass('uk-flex uk-open');
}


findtemplate = (id) =>{
  let classRef = this;
  let company_id = localStorage.getItem('company_id');
  let template_id = id
  axios({
    method: 'POST',
    url: Constant.url + "single-chat-templates",
    data: { company_id: company_id ,template_id :template_id }
  }).then(function (result) {
    if (result.data.status === true) {
      // console.log(result.data);
      var template = result.data.data
      classRef.setState({
        template_name:template.name,
        temp_description:template.description,
        temp_keywords:template.keywords,
        template_id :template.id,
      })
      
    }
  })
}

  callApi = (pageCount) => {

    let classRef = this;
    axios({
        method: 'POST',
        url: Constant.url + "list-roles",
        data: { company_id: localStorage.getItem('company_id'),page: pageCount }
    }).then(function (result) {
         
        if (result.data.status === true) {
            classRef.setState({
                dataList: {
                    isLoaded: true,
                    msg: 'Loading...',
                    list: result.data.data
                },
                rowsCountTags : result.data.rowscount
            })
        }else{
  
          classRef.setState({
            dataList: {
                isLoaded: false,
                msg: 'not',
                list: []
            },
            rowsCountTags : result.data.rowscount
        })
        }
    })
  
  }


  TagsSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#TagsSortingBtn").val();
    sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
    axios({
        method: 'POST',
        url: Constant.url + "list-roles",
        data: { company_id: localStorage.getItem('company_id'),
        page: pageCount, 
        limit : sortingValue,
        search : $("#searchword").val()
       }
    }).then(function (result) {
         
        if (result.data.status === true) {
            classRef.setState({
                dataList: {
                    isLoaded: true,
                    msg: 'Loading...',
                    list: result.data.data
                },
                rowsCountTags : result.data.rowscount
            })
        }else{
  
          classRef.setState({
            dataList: {
                isLoaded: false,
                msg: 'not',
                list: []
            },
            rowsCountTags : result.data.rowscount
        })
        }
    })
  
  }

  handleTagsClick = (data) =>{
    let currentPage = data.selected + 1;
    this.TagsSorting(currentPage);
  }

  



  closeConsent = () =>{
    $("#AddResolved").removeClass('uk-flex uk-open');
  }

 closeConsentss = () =>{
  $("#AddConsent").removeClass('uk-flex uk-open');
}

deleteTemplate = () => {

  var id = $("#addconsents").val()
  let classRef = this;
  var template_id = id;
  var company_id = localStorage.getItem('company_id');
  var is_deleted = 1;

        axios({
          method: 'POST',
          url: Constant.url + "delete-chat-template",
          data: { company_id: company_id ,template_ids: template_id ,is_deleted: is_deleted}
      }).then(function (result) {
      
          if (result.data.code === 200) {
      
              $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
      
              window.location.href = "/quick-replies";

          
      
          }else{
      
              $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
      
              setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
  
              window.location.href = "/quick-replies";   
              
    
      
          }
             
      })

}





addconsent = (id) =>{
  // console.log(id,"sajdain");
  $('.uk-dropdown').removeClass('uk-open');
  $("#AddResolved").addClass('uk-flex uk-open');
  $( "#addconsent" ).val(id)

}

editTag = () => {    
    
  $('#edittags').text('Processing')
  $('#edittags').prop('disabled', true);  


  let classRef = this;
  var oldscreens = '';
  oldscreens = Object.keys(classRef.state.oldscreens).map(function(k){return classRef.state.oldscreens[k].id}).join(",");
  var nameArr = oldscreens;

  var data = {};
  data = {
    role: $("#edittagName").val(), 
    screens: nameArr,
    role_id: $("#tag_id").val(),
    company_id:localStorage.getItem('company_id'),
    status:$("#role_status").val(),
    can_chat:$("#can_chat").val(),
  }

  axios({
    method: 'POST',
    url: Constant.url + "edit-role",
    data: data
  }).then(function (response) {

    if (response.data.code === 200) {

      $("#editsuccessAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');

      window.location.href = "/role";
    }else{

      $("#editerrorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
      $('#edittags').text('Edit Tag')
      $('#edittags').prop('disabled', false);
      setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
      return;
  

    }
  }).then(function (response) {

  });


};


deletetag = () => {

  var id = $("#addconsent").val()
  let classRef = this;
  var tag_id = id;
  var company_id = localStorage.getItem('company_id');
  var is_deleted = 1;

        axios({
          method: 'POST',
          url: Constant.url + "delete-roles",
          data: { company_id: company_id ,role_id: tag_id}
      }).then(function (result) {
      
          if (result.data.code === 200) {
      
              $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
      
              window.location.href = "/tag";
      
          }else{
      
              $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
      
              setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
  
              window.location.href = "/tag";      
      
          }
             
      })

}
handleTable = () => {
        let result = "";
        let index = 0;
        result = this.state.dataList.list.map((response, index) => (

          <tr id={"mtag_"+response.id}>
          {/* <td>
                <input className="uk-checkbox autoSelect"  name="multi_check[]" value={response.id} type="checkbox" />
          </td> */}
          <td>
          <span>{response.role}</span>
          </td>
          {/* <td>
          <span>{response.screens}</span>
          </td> */}
          <td><button  className={(response.status == '1' ? 'ctmBtn succesBtn' : "ctmBtn dangreBtn")}>{(response.status == '1') ? 'Active' : 'Inactive'}</button></td>
          <td><a className="iconcircleBtn" uk-tooltip="title: Edit" onClick= {(e)=>this.EditTagModal(response.id)}><Edit /></a></td>
          <td><a className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick= {(e)=>this.addconsent(response.id)}><TrashCan /></a></td>
        </tr>

        ));

  return result;
}

searchrecord = () =>{   

  let classRef = this;        
  var searchword = $("#searchword").val();
  this.setState({ advancesearch: searchword});

  if(searchword !==''){
    $("#cross").show()
}

 

  axios({
    method: 'POST',
    url: Constant.url + "list-roles",
    data: { company_id: localStorage.getItem('company_id'),search: searchword  }
}).then(function (result) {
    if (result.data.status === true) {
        classRef.setState({
            dataList: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data
            },
            rowsCountTags : result.data.rowscount
        })
    }else{

      classRef.setState({
        dataList: {
            isLoaded: false,
            msg: 'not',
            list: []
        },
        rowsCountTags : result.data.rowscount
    })
    }
})

  }




deleteMultiTemp = () => {          
  var ids=[];
  $('input[type=checkbox]').each(function (i) { 
      if($(this).is(":checked")){
       $("#temp_"+$(this).val()).hide();             
       ids[i] =$(this).val();
      }
  });
    
    let classRef = this;          
    axios({
        method: 'POST',
        url: Constant.url+"delete-chat-template",
        data :{
          company_id :localStorage.getItem('company_id'),
          template_ids :ids            
        }                       
    }).then(function (result) {
        if(result.data.status === true){                          
           window.location.reload();                           
        }
    })      
}

selectCheckboxTemp = () =>{
  if(!this.state.checkAll){
      this.setState({checkAll:true},function(){
          $('#select_alls').prop('checked',true);  
          $('.autoSelects').prop('checked',true);  
      })
  }else{
      this.setState({checkAll:false},function(){
          $('#select_alls').prop('checked',false);  
          $('.autoSelects').prop('checked',false);  
      })
  }
}



addSearchModal = () => {
  $("#SearchTeamModal").addClass('uk-flex uk-open');
}

closeSearchModal= () => {
  $("#SearchTeamModal").removeClass('uk-flex uk-open');
}





advanceSearch = () =>{  

  let classRef = this;
  var data = {};
  data = {
  dateto: $("#to_date").val(),
  datefrom: $("#from_date").val(),
  status: $("#status").val(),    
  }

  var key =''

  if(data.dateto){
  var key = "Date To: "+$("#to_date").val()
  }
  if(data.datefrom){
    var key = key+" Date From: "+$("#from_date").val()
  }
  if(data.status){
    var key = key+" Status: "+$("#status :selected").text()
  }
  
  classRef.setState({ advancesearch: key})

  axios({
  method: 'POST',
  url: Constant.url + "companies",
  data: data
  }).then(function (result) {
      if (result.data.status === true) {
          classRef.setState({
              dataList: {
                  isLoaded: true,
                  msg: 'Loading...',
                  list: result.data.data
              },
          })
          $("#SearchTeamModal").removeClass('uk-flex uk-open');
      }else{
          classRef.setState({
            dataList: {
                isLoaded: false,
                msg: 'not',
                list: []
            },
        })
        $("#SearchTeamModal").removeClass('uk-flex uk-open');
        }


  });

}

advSearchClear = () => {
  let classRef = this;     
      classRef.setState({ advancesearch:""})
      classRef.callApi()
      $("#cross").hide()
      

}


addNewTemplate = () => {
  $("#NewBot").addClass('uk-flex uk-open');
}

closeNewBot = () => {
  $("#NewBot").removeClass('uk-flex uk-open');
}

createTag = () => {

  $('#addtag').text('Processing')
  $('#addtag').prop('disabled', true);
  let classRef = this;


  var screens = '';
  screens = Object.keys(classRef.state.screens).map(function(k){return classRef.state.screens[k].id}).join(",");

  var nameArr = screens.split(',');

  var data = {};
  data = {
      role: $("#newtagName").val(),
      company_id: localStorage.getItem('company_id'),      
      screens: screens,  
      can_chat: $("#chatassigned").val(),
      status: $("#status").val(),     
      
  }
  axios({
      method: 'POST',
      url: Constant.url + "create-role",
      data: data
  }).then(function (response) {


     
      if (response.data.code === 200) {

      $("#successAlertss").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');

      
      $('#addtag').text('Create Role')
      $('#addtag').prop('disabled', false);
      setTimeout(function(){
        UIkit.alert('#msg-dialog').close();
        $("#AddTagModal").removeClass('uk-flex uk-open');
      },1000)
      
      // window.location.href = "/role";


      }else{

        $("#errorAlertss").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
        $('#addtag').text('Create Role')
        $('#addtag').prop('disabled', false);
        setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
        return;

      }
  }).then(function (response) {

  });

};


deleteMultiTag = () => {          
  var ids=[];
  $('input[type=checkbox]').each(function (i) { 
      if($(this).is(":checked")){
       $("#mtag_"+$(this).val()).hide();             
       ids[i] =$(this).val();
      }
  });
    
    let classRef = this;          
    axios({
        method: 'POST',
        url: Constant.url+"bulk-delete-tags",
        data :{
          company_id :localStorage.getItem('company_id'),
          tag_ids :ids            
        }                       
    }).then(function (result) {
        if(result.data.status === true){                          
           window.location.reload();                           
        }
    })      
}

selectCheckboxTag = () =>{
if(!this.state.checkAll){
    this.setState({checkAll:true},function(){
        $('#select_all').prop('checked',true);  
        $('.autoSelect').prop('checked',true);  
    })
}else{
    this.setState({checkAll:false},function(){
        $('#select_all').prop('checked',false);  
        $('.autoSelect').prop('checked',false);  
    })
}
}



downloadtags = (id) => {

  let classRef = this;
  var team_id = id;
  window.open(Constant.url +"download-tags?company_id=" + localStorage.getItem('company_id'), "_blank")

}






  render() {
   
    const options = [
        { value: 'Urgent', label: 'Urgent' },
        { value: 'Resolved', label: 'Resolved' }
      ]
    return (
      <Content>
        <div
          className="contactContainer BroadcastingContainer "
          uk-height-viewport=""
          uk-height-match=".h_match" 
        >
          <div className="head">
                <div className=" uk-grid " uk-grid="">
                    <div className="uk-width-1-2@m">
                    <div className="tagInputLabel"><h3>Roles</h3></div>
                    <div className="tagInput">
                        <div className="search_field">
                            <form action="javascript:void(0);">
                                <input className="uk-input" type="text" onChange={this.searchrecord} name="searchword" id="searchword" value={this.state.advancesearch}  placeholder="Search through name"/>
                            </form>
                            <div class="CampaignReports Filtermain">
                                        <div className="uk-width-auto">
                                        <button id='cross' style={{right: "10px"}} onClick = {this.advSearchClear}><Close/></button>
                                        </div>
                            </div> 
                        </div>
                    </div>
                    </div>
                    
                    <div className="uk-width-1-2@m">
                      <button className="newFlowBtn uk-float-right" onClick={this.addTeamModal} ><Add /> <span>New Role</span></button>
                      {/* <button class="newFlowBtn uk-float-right uk-margin-right"  onClick={this.downloadtags}><Add/> <span>Export Role</span></button> */}
                    </div>
                </div>
            </div>
            <div className="numberSorting uk-float-right">
              <div class="uk-inline">
                  <label>Show
                  <select onChange={(e)=>this.TagsSorting()} id="TagsSortingBtn">
                      {/* <option disabled selected>Sorting</option> */}
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                  </select>
                  entries</label>
              </div>
          </div> 

          <div className="tableSec">
            
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                {/* <th>
                    <label>
                        <input className="uk-checkbox" type="checkbox" id="select_all"  onClick = {this.selectCheckboxTag}/>
                    </label> 
                    
                    <div className="uk-inline drpIconBox">
                        <button className="iconTble" type="button"><CaretDown /></button>
                        <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                                <li><a onClick={this.deleteMultiTag}>Delete Selected Rows</a></li>
                            </ul>
                        </div>
                    </div>
                </th> */}
                  <th>Roles</th>
                  <th>Screens</th>            
                  <th></th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {(this.state.dataList.isLoaded) ? this.handleTable() : (this.state.dataList.msg == 'not') ? <tr><td colSpan="15"><div className="uk-text-center uk-height-large">Data not Found</div></td></tr>: <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr> }
              </tbody>
             
            </table>
            {(this.state.rowsCountTags > 1) ? 
                  <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'....'}
                      pageCount={this.state.rowsCountTags}
                      onPageChange={this.handleTagsClick}
                      containerClassName={'uk-pagination uk-float-right'}
                      activeClassName={'uk-active'}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                  /> : ""
              }
          </div>
        </div>

                        {/* create tag start */}

                        <div id="AddTagModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button"> <Close /></button>
                        <h3>Create New Role</h3>
                                <div id="errorAlertss"></div>
                                <div id="successAlertss"></div>
                        <form className="uk-form-stacked" action="javascript:void(0);">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="tagName">Role Name
                                <ContextualHelp variant="info">
                                   
                                   <Content>
                                   <h4><b>Role Name</b></h4>
                                       <p>How this role will access the dashboard</p>
                                   </Content>
                               </ContextualHelp>

                                </label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="newtagName" name="newtagName" type="text" placeholder="Enter Role Name" />
                                </div>
                            </div>

                          
                            <div className="uk-margin">
                            <label className="uk-form-label">Add Screens </label>

                            <div className="chipBox">
                            <div className="chipBox CustomChipBox fullWdth">
                                <Multiselect
                                options={this.state.screenList.list} 
                                selectedValues={this.state.screens}
                                onSelect={this.onSelectAgents}
                                onRemove={this.onRemoveAgents}
                                displayValue="screen"
                                />
                            </div>
                        </div>
                        </div>

                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">Chat Assign</label>
                            <div className="uk-form-controls">
                              <select className="uk-select" id="chatassigned">
                              <option value="">Select Status</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                                
                              </select>
                            </div>
                          </div>

                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">Role</label>
                            <div className="uk-form-controls">
                              <select className="uk-select" id="status">
                              <option value="">Select Status</option>
                              <option value="1">Active</option>
                              <option value="0">In Active</option>
                                
                              </select>
                            </div>
                          </div>
                            
                            <div className="uk-margin">
                                <div className="ModalStyleBtns ">
                                    <button className="CnclBtn uk-modal-close" onClick={this.closeAgentModal}>Cancel</button>
                                    <button className="AddTeamBtn" id="addtag" onClick={this.createTag} >Create Role</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>



                <div id="EditTagModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button"> <Close /></button>
                        <h3>Edit Role</h3>
                                 <div id="editerrorAlert"></div>
                                <div id="editsuccessAlert"></div>
                        <form className="uk-form-stacked" action="javascript:void(0);">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="tagName">Tag Name
                                <ContextualHelp variant="info">
                                   
                                   <Content>
                                   <h4><b>Role Name</b></h4>
                                       <p>How this role will access the dashboard</p>
                                   </Content>
                               </ContextualHelp>

                                </label>
                                <div className="uk-form-controls">
                                <input className="uk-input" id="tag_id" name="tag_id" value={this.state.tag_id} type="hidden" />
                                    <input className="uk-input" id="edittagName" name="edittagName" value={this.state.tag_name}  onChange={this.handleNameChange} type="text" placeholder="Enter Role Name" />
                                </div>
                            </div>

                            <div className="uk-margin">
                            <label className="uk-form-label">Add Screens </label>
                            <div className="chipBox">
                            <div className="chipBox CustomChipBox">
                                <Multiselect
                                options={this.state.screenList.list} 
                                selectedValues={this.state.oldscreens}
                                onSelect={this.oldonSelectAgents}
                                onRemove={this.oldonRemoveAgents}
                                displayValue="screen"
                                />
                            </div>
                        </div>
                        </div>

                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">Is Agent</label>
                            <div className="uk-form-controls">
                              <select className="uk-select" id="can_chat" name="can_chat"  value={this.state.can_chat} onChange={this.handleAssignChange}>
                              <option value="">Select Option</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                                
                              </select>
                            </div>
                          </div>


                            <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">Role</label>
                            <div className="uk-form-controls">
                              <select className="uk-select" id="role_status" value={this.state.status} onChange={this.handleStatusChange}>
                              <option value="">Select Status</option>
                              <option value="1">Active</option>
                              <option value="0">In Active</option>
                                
                              </select>
                            </div>
                          </div>
                            
                            <div className="uk-margin">
                                <div className="ModalStyleBtns ">
                                    <button className="CnclBtn uk-modal-close" onClick={this.closeAgentModal}>Cancel</button>
                                    <button className="AddTeamBtn" id="edittags" onClick={this.editTag} >Edit Role</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


        {/*----MODAL---*/}
        <div id="AddResolved" className="AlertPOPUP ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3 >
              <span className="uk-inline"> Warning</span> 
              <span className="uk-inline uk-float-right"> 
                  <img src="/images/done_all_black_24dp.svg" />{" "} 
              </span>
            </h3>
            <div className="resolved_content">
              <p><b>Are you sure you want to delete Role?</b></p>
              <p className="small">You won't be able to revert this!</p>

              <form className="uk-form-stacked uk-margin-medium-top" action="javascript:void(0);">
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>Cancel</button>
                  <button className="AddTeamBtn" id="addconsent" onClick={this.deletetag}>Confirm</button>
                </div>
            </form>
            </div>
          </div>
        </div>


        <div id="SearchTeamModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="">
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button"> <Close /></button>
                    <h3>Advance Search</h3>
                    <form id="advsearchform" className="uk-form-stacked" action="javascript:void(0);">

                        <div className="uk-margin">
                            <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="ToDate">To Date</label>
                                    <div class="uk-form-controls">
                                        <input type="date" label="Select Broadcast" className="to_date" id="to_date"/>
                                        
                                    </div>
                            </div>

                            <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="FromDate">From Date</label>
                                    <div class="uk-form-controls">
                                        <input type="date" label="Select Broadcast" className="from_date" id="from_date"/>
                                    </div>
                            </div>
                        </div>
   
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">Status</label>
                            <div className="uk-form-controls">
                            <select className="uk-select" id="status">
                            <option value="" disabled selected >Select Status</option>
                            <option value="1" >Active</option>
                            <option value="0" >In Active</option>
                            </select>
                            </div>
                        </div>



                        <div className="uk-margin">
                            <div className="ModalStyleBtns CampaignBtn">
                                <button className="CnclBtn" onClick={this.closeSearchModal}>Cancel</button>
                                <button  className="AddTeamBtn" id="addteam" onClick={this.advanceSearch}>Search</button>
                            </div>
                        </div>
                    </form>

                </div>
                </div>
                
      </Content>
    );
  }
}
export default Role;
