import * as React from "react";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { DateRange } from "../../Common/DateRange";
import $ from "jquery";
import { LiveChatVOCChart } from "../../Sections/LiveChatVOCChart";
import UIkit from "uikit";

  
class LiveChatVOC extends React.Component {
    constructor() {
        super();
        this.state = {
        from: "",
        to: "",
        }

    }


    MultiSelectFilter =()=>{
        $(".UltabsBtns").toggleClass("showMenu");
        $(".UltabsBtns > li label").click(function(){
            $(".MultiSelectFilter > p span").text($(this).text());
        });
    }


    Progessbar = () =>{
        UIkit.util.ready(function () {

            var bar = document.getElementById('js-progressbar');
    
            var animate = setInterval(function () {
    
                bar.value += 10;
    
                if (bar.value >= bar.max) {
                    clearInterval(animate);
                }
    
            }, 1000);
    
        });
    }
    

    componentDidMount() {
        var classRef = this;

        var to = "2022-11-01";
        var from = "2022-09-20";
        classRef.setState({ from: from });
        classRef.setState({ to: to });
                }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Customer satisfaction</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">

                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>All Channels</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>
                
                {/* Performance highlights */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">CSAT Breakdown by Response
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>CSAT Breakdown by Response</h5>
                                        <p>The ratio between satisfied and unsatisfied responses that match the selected filters.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-1-3@m">
                                <LiveChatVOCChart/>
                            </div>
                                <div className="uk-width-2-3@m">
                                    <div className="ProgressBarChartCustom">
                                        <div className="left">
                                            <h6>
                                                Rating by response
                                            </h6>
                                                <ul className="uk-list uk-padding-remove">
                                                    <li><span>Happy</span></li>
                                                    <li><span>Unhappy</span></li>
                                                </ul>
                                        </div>
                                        <div className="right">
                                            <ul className="uk-list uk-padding-remove">
                                                <li>
                                                    <span>3,402</span>
                                                    <progress id="js-progressbar" class="uk-progress" value="98" max="100"></progress>
                                                    <span>98%</span>
                                                </li>

                                                <li>
                                                    <span>12</span>
                                                    <progress id="js-progressbar" class="uk-progress unhappy" value="12" max="100"></progress>
                                                    <span>2%</span>
                                                </li>
                                            </ul>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                


                {/* Utilization per agent */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Surveys
                            <div className="uk-float-right ">
                                <div className="AgentDropDown">
                                    <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                                        <p><span>Export</span> <ChevronDown /></p>
                                        {/* <ul className="UltabsBtns">
                                            <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand">QUEUES</th>
                                    <th className="uk-table-expand ">LIVE CHATS</th>
                                    <th className="uk-table-auto uk-text-nowrap">SCORE</th>
                                    <th className="uk-table-expand uk-text-nowrap">COMMENTS</th>
                                    <th className="uk-table-shrink uk-text-nowrap">SURVEYED</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn unhappyBtn">Unhappy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>

                              
                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
         </>
    );
}
}
export default LiveChatVOC;