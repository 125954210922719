import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown,
    Timer,
    Chat,
    Forum,
    ChatLaunch,
  } from "@carbon/icons-react";

import { ChannelsOverviewChart } from "../../Sections/ChannelsOverviewChart";
import { DateRange } from "../../Common/DateRange";
import { ChatbotTotalSession } from "../../Sections/ChatbotTotalSession";
import { ChatBotMessageChart } from "../../Sections/ChatBotMessageChart";
import { ChatBotUserChart } from "../../Sections/ChatBotUserChart";
import { ConversationalAnalyticTotalMess } from "../../Sections/ConversationalAnalyticTotalMess";
import { ConversationalAnalyticPerSession } from "../../Sections/ConversationalAnalyticPerSession";
import { ClosedConversationChart } from "../../Sections/ClosedConversationChart";
// import DateRangePicker from 'rsuite/DateRangePicker';
// // import { DateRangePicker, Stack } from 'rsuite';
// import subDays from 'date-fns/subDays';
// import startOfWeek from 'date-fns/startOfWeek';
// import endOfWeek from 'date-fns/endOfWeek';
// import addDays from 'date-fns/addDays';
// import startOfMonth from 'date-fns/startOfMonth';
// import endOfMonth from 'date-fns/endOfMonth';
// import addMonths from 'date-fns/addMonths';
  
class InsightChatbotConversionalAnalytics extends React.Component {

    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2023-01-30";
        var from = "2021-01-01";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }


MultiSelectFilter =()=>{
    $(".UltabsBtns").toggleClass("showMenu");
    $(".UltabsBtns > li label").click(function(){
        $(".MultiSelectFilter > p span").text($(this).text());
    });
}


  
  

    render() {
        return (
         <>
            <div uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Conversational analytics</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">

                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>All Channels</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>


                {/* * Messages* */}
                <div className="uk-width-1-2">
                    <div className="uk-card uk-card-custom uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Messages</h5>
                        </div>
                        <div className="uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <ConversationalAnalyticTotalMess/>
                        </div>
                    </div>
                </div>


                {/* * Users* */}
                <div className="uk-width-1-2">
                    <div className="uk-card  uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Users</h5>
                        </div>
                        <div className="uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <ConversationalAnalyticPerSession/>
                        </div>
                    </div>
                </div>


                {/* * Total sessions* */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <ul className="tabsBtns desktop uk-heading-divider" uk-switcher="connect: .multiTabNewLiveAgent">
                                <li className="uk-inline infoTool"><a>Total messages</a></li>
                                <li className="uk-inline infoTool"><a>Average messages per session</a></li>
                            </ul>
                        </div>
                        <ul className="uk-switcher multiTabNewLiveAgent">
                        <li>
                            <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    {/* <div className="head"><h5>All Channels Overview</h5></div> */}
                                        {/* Chart */}
                                        <div className="ChannelsOverviewGraph">
                                            <ClosedConversationChart/>
                                        </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            111
                        </li>
                        <li>
                            222
                        </li>
                        </ul>
                    </div>
                </div>



                {/* * dialogs* */}
                 <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <ul className="tabsBtns desktop uk-heading-divider" uk-switcher="connect: .multiTabNewLiveAgent22">
                                <li className="uk-inline infoTool"><a>Total messages</a></li>
                                <li className="uk-inline infoTool"><a>Average messages per session</a></li>
                            </ul>
                        </div>
                        <ul className="uk-switcher multiTabNewLiveAgent22">
                            <li>
                            <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    <div className="uk-overflow-auto AgentTable">
                                        <table className="uk-table uk-table-striped">
                                        <thead>
                                            <tr>
                                                <th className="uk-table-expand">DIALOG</th>
                                                <th className="uk-table-shrink uk-text-nowrap">TRIGGERED</th>
                                                <th className="uk-table-shrink uk-text-nowrap">USERS</th>
                                                <th className="uk-table-shrink uk-text-nowrap">SESSIONS</th>
                                                <th className="uk-table-shrink uk-text-nowrap">EXIT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">EXIT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">EXPIRED SESSIONS</th>
                                                <th className="uk-table-shrink uk-text-nowrap">AGENT TAKE OVER</th>
                                                <th className="uk-table-shrink uk-text-nowrap">GO TO AGENT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">CLOSE REASON</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>

                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </li>
                            <li>
                            <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    <div className="uk-overflow-auto AgentTable">
                                        <table className="uk-table uk-table-striped">
                                        <thead>
                                            <tr>
                                                <th className="uk-table-expand">DIALOG</th>
                                                <th className="uk-table-shrink uk-text-nowrap">TRIGGERED</th>
                                                <th className="uk-table-shrink uk-text-nowrap">USERS</th>
                                                <th className="uk-table-shrink uk-text-nowrap">SESSIONS</th>
                                                <th className="uk-table-shrink uk-text-nowrap">EXIT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">EXIT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">EXPIRED SESSIONS</th>
                                                <th className="uk-table-shrink uk-text-nowrap">AGENT TAKE OVER</th>
                                                <th className="uk-table-shrink uk-text-nowrap">GO TO AGENT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">CLOSE REASON</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>

                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Default</span></a></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap"><span>1</span></td>
                                                <td className="uk-text-nowrap primary"><span>100.0%</span></td>
                                                <td className="uk-text-nowrap danger"><span>0.0%</span></td>
                                                <td className="uk-text-nowrap"><span>2</span></td>
                                                <td className="uk-text-nowrap"><span>4</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                                <td className="uk-text-nowrap"><span>0</span></td>
                                            </tr>
                                            
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </li>
                        </ul>
                    </div>
                </div>

                {/*Unrecognized intent messages */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <ul className="tabsBtns desktop uk-heading-divider" uk-switcher="connect: .multiTabNewLiveAgent221">
                                <li className="uk-inline infoTool"><a>Unrecognized intent messages</a></li>
                                <li className="uk-inline infoTool"><a>Unhandled messages</a></li>
                            </ul>
                        </div>
                        <ul className="uk-switcher multiTabNewLiveAgent221">
                            <li>
                            <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    <div className="uk-overflow-auto AgentTable">
                                        <table className="uk-table uk-table-striped">
                                        <thead>
                                            <tr>
                                                <th className="uk-table-expand">MESSAGE</th>
                                                <th className="uk-table-shrink uk-text-nowrap">DIALOG</th>
                                                <th className="uk-table-shrink uk-text-nowrap">DIALOG ELEMENT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">COUNT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">SESSION COUNT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">USER COUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            

                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </li>
                            <li>
                            <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    <div className="uk-overflow-auto AgentTable">
                                        <table className="uk-table uk-table-striped">
                                        <thead>
                                            <tr>
                                                <th className="uk-table-expand">MESSAGE</th>
                                                <th className="uk-table-shrink uk-text-nowrap">DIALOG</th>
                                                <th className="uk-table-shrink uk-text-nowrap">DIALOG ELEMENT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">COUNT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">SESSION COUNT</th>
                                                <th className="uk-table-shrink uk-text-nowrap">USER COUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            

                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            <tr>
                                                <td><a><span>Message text 62</span></a></td>
                                                <td className="uk-text-nowrap"><span>Dialog 62</span></td>
                                                <td className="uk-text-nowrap"><span>Process_User_I...</span></td>
                                                <td className="uk-text-nowrap"><span>14,893</span></td>
                                                <td className="uk-text-nowrap"><span>1,024</span></td>
                                                <td className="uk-text-nowrap"><span>2,196</span></td>
                                            </tr>
                                            
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </li>
                        </ul>
                    </div>
                </div>

                




               
               
            </div>
         </>
    );
}
}
export default InsightChatbotConversionalAnalytics;