import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../App/Constant";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);




export const SmsInboundNetwork = () => {

    const data = {
        labels: ['Sep30', 'Oct4', 'Oct8', 'Oct12', 'Oct16', 'Oct20', 'Oct28', 'Nov1'] ,
        datasets: [
        {
            label: 'Unknown',
            data: [10, 7, 0, 7, 9, 22, 11, 8, 10, 25, 15, 18, 20, 30],
            backgroundColor: '#32D74B',
            borderColor: '#32D74B'
        },
       
        
        ],
  
  }
        
     


const options = {
    responsive: true,
    plugins: {
    legend: {
    position: 'right',
    display: true,
    },
    title: {
    display: true,
    // text: 'Chart.js Line Chart',
    },
    },
}
        
        return <Line options={options} data={data}/>;
      }
