import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { ContextualHelp } from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import PageActionBar from "../../Sections/PageActionBar";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import ReactPaginate from "react-paginate";
import {
  Search,
  Filter,
  CaretDown,
  Information,
  Add,
  Edit,
  TrashCan,
  Download,
  OverflowMenuVertical,
  Close,
  AddAlt,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  CloudUpload,
  DocumentImport,
  Time,
} from "@carbon/icons-react";
import { Multiselect } from "multiselect-react-dropdown";

class Audience extends React.Component {
  constructor() {
    super();
    this.callContactApi = this.callContactApi.bind(this);
    this.callSegmentApi = this.callSegmentApi.bind(this);
    this.getTags = this.getTags.bind(this);
    this.onSelectTags = this.onSelectTags.bind(this);
    this.onRemoveTags = this.onRemoveTags.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.state = {
      uploadfiles : "" ,
      ContactList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      SegmentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      country: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      city: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      checkAlls: false,

      filterType: "is",
      popUpName: "",
      popUpNumber: "",
      popUpGender: "",
      popUpTag: "",
      popUpSunscribe: "",
      popUpContactTime: "",
      popAvatar: "",
      tagList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      tags: [],
      old_tags: [],
      old_contact_tags: [],
      rowsCountContact: 0,
      rowsCountSegments: 0,
      phoneintlnumber:"",
      phoneintlnumberedit:"",
    };
  }


  addContactProfile = (
    contact_name,
    phone,
    gender,
    tags,
    subscribed,
    contact_time,
    id,
    avatar,
    tag2
  ) => {
    this.setState({
      popUpName: contact_name,
      popUpNumber: phone,
      popUpGender: gender,
      popUpTag: tags,
      popUpSunscribe: subscribed,
      popUpContactTime: contact_time,
      popUpId: id,
      popAvatar: avatar,
      checkAll: false,
      old_contact_tags: tag2,
    });
    this.contactTags(tags, id);
    $("#ContactProfile").addClass("uk-flex uk-open");
  };

  contactTags = (tags, id) => {
    var cid = id;
    var ctags = [];
    var ctags = tags ? tags.split(",") : "";
    $("#tags").empty();
    for (let k = 0; k < ctags.length; k++) {
      $("#tags").append(
        "<div class='button' id='tags_" +
          (k + 1) +
          "'> " +
          ctags[k] +
          " <button data-id='" +
          cid +
          "' value='" +
          ctags[k] +
          "' id='" +
          k +
          "' onclick='removeContactTags(" +
          (k + 1) +
          ",this.value)'><span  uk-icon='close'></span></button></div>"
      );
    }
  };




uploadFile = () =>{
    $('#uploadButton').text('Processing')
    $('#uploadButton').prop('disabled', true); 
    let classRef = this;
    let company_id = localStorage.getItem('company_id');
    let segment_name = $('#segment_name').val();
    let importParams = new FormData();
    importParams.append( 'company_id', company_id );
    importParams.append( 'segment_name', segment_name );
    importParams.append( 'file', classRef.state.uploadfiles );
    if(segment_name != ""){
        if(classRef.state.uploadfiles != ""){
            axios({
                method: 'POST',
                url: Constant.url+"import-contacts",
                data: importParams            
            }).then(function (result) {
                if(result.data.status === true){     
                    $("#errorAlertContactImport").append('<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Contact uploaded successfully.</p></div>');                     
                    $('#uploadButton').text('Import')
                    $('#uploadButton').prop('disabled', false);   
                    setTimeout(function(){
                        UIkit.alert('#msg-dialog1').close();
                        classRef.hideImportcontactModal();
                    },1000);
                   
                }
            })
        } else {
            $("#errorAlertContactImport").append('<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please upload file.</p></div>');
            $('#uploadButton').text('Import')
            $('#uploadButton').prop('disabled', false); 
            setTimeout(function(){UIkit.alert('#msg-dialog1').close();},1000);
        }

    } else {
        $("#errorAlertContactImport").append('<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please Enter Segment Name.</p></div>');
        $('#uploadButton').text('Import')
        $('#uploadButton').prop('disabled', false); 
        setTimeout(function(){UIkit.alert('#msg-dialog1').close();},1000);
    }

}

onFileUpload = (event) =>{
    let classRef = this;
    if(event.target.files[0] != undefined){
        $("#uploadedFile").empty();
        $("#uploadedFile").append("Uploaded File : "+event.target.files[0].name);
        classRef.setState({ uploadfiles: event.target.files[0] });
    }
}

  closeContactProfile = () => {
    this.callContactApi();
    $("#ContactProfile").removeClass("uk-flex uk-open");
  };

  componentDidMount() {
    var pageCount = 1;
    this.callContactApi(pageCount);
    this.callSegmentApi(pageCount);
    this.getTags();
    this.getCountry();
    this.getCity();
    this.getAgents();
    var maxField = 10; //Input fields increment limitation
    var addButton = $(".add_button"); //Add button selector
    var wrapper = $(".field_wrapper"); //Input field wrapper
    var fieldHTML =
      '<div><input type="text" name="field_name[]" value=""/><a href="javascript:void(0);" class="remove_button"><img src="remove-icon.png"/></a></div>'; //New input field html
    var x = 1; //Initial field counter is 1

    //Once add button is clicked
    $(addButton).click(function () {
      //Check maximum number of input fields
      if (x < maxField) {
        x++; //Increment field counter
        $(wrapper).append(fieldHTML); //Add field html
      }
    });

    //Once remove button is clicked
    $(wrapper).on("click", ".remove_button", function (e) {
      e.preventDefault();
      $(this).parent("div").remove(); //Remove field html
      x--; //Decrement field counter
    });

    if(localStorage.getItem('loginStatus')== 1){
      let screens = localStorage.getItem('rolesceens').split(',');
      var checking = screens.includes("4");
       if(checking == true){

          }else{
              window.location.href = "/"; 
          }
      }
      else{
          window.location.href = "/"; 
      }

    $("#cross").hide();
    $("#across").hide();
  }

  showReinitiate = () =>{
    let company_id = localStorage.getItem('company_id');
    let messageParams = new FormData();
    messageParams.append( 'company_id', company_id );
    $("#phone_no").val($("#mobile_no").text());
    $("#phone_no").prop("disabled",true);
    axios({
    method: 'POST',
    url: Constant.url + "templates",
    data: messageParams,
    }).then(function (response) {
    if (response.data.status === true) {
        $('#suggested_msgs_pop').empty();
        $("#suggested_msgs_pop").append("<option id='0'>Select Message</option>");
        for(let i = 0; i < response.data.data.length; i++){
          // $("#suggested_msgs_pop ul").append("<li id='suggest_msg_"+response.data.data[i].id+"' onclick='sendMessage("+response.data.data[i].id+")'><button id='suggest_msg_"+response.data.data[i].id+"'>"+response.data.data[i].name+"</button></li>");
          $("#suggested_msgs_pop").append("<option id='suggest_msg_pop_"+response.data.data[i].id+"'>"+response.data.data[i].name+"</option>");
        }
    }
    })
    $("#reinitiate").addClass('uk-flex uk-open');
}

confirmReinitaite = () =>{
    let template_id  = $("#suggested_msgs_pop").find('option:selected').attr('id');
    template_id = template_id.split("_")[3];
    let phone_no = $("#phone_no").val();
    
    let company_id = localStorage.getItem('company_id');
    let company_msisdn = localStorage.getItem('company_msisdn');
    if(phone_no == ""){
        $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please enter number first!</p></div>');
        setTimeout(function(){UIkit.alert('#msg-dialog').close();},2000);
        return;
    }
    if(template_id == undefined) {
        $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please select message first!</p></div>');
        setTimeout(function(){UIkit.alert('#msg-dialog').close();},2000);
        return ;
    }

    let reInitiateParams = new FormData();
    reInitiateParams.append( 'business_number', company_msisdn );
    reInitiateParams.append( 'company_id', company_id );
    reInitiateParams.append( 'template_id', template_id );
    reInitiateParams.append( 'msisdn', phone_no );
    axios({
    method: 'POST',
    url: Constant.url + "quick-message",
    data : reInitiateParams
    }).then(function (result) {
    if (result.data.status === true) {
        $("#successAlertQuick").append('<div id="msg-dialog" class="uk-alert-success uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Message sent successfully.</p></div>');
        setTimeout(function(){
        UIkit.alert('#msg-dialog').close();
        $("#reinitiate").removeClass('uk-flex uk-open');
        },2000);
    } else {
        $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>An error occurred!</p></div>');
        setTimeout(function(){
        UIkit.alert('#msg-dialog').close();
        },2000);
    }
    })
}

  getAgents = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "get-agents",
      data: { company_id:localStorage.getItem('company_id') },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          agentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  handleAgent = () => {
    let result = "";
    let index = 0;
    result = this.state.agentList.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  filtercountryChange = () => {
    this.getfilterCity();
  };

  getfilterCity = () => {
    let classRef = this;
    let country = $("#filtercountry").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  getCountry = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "countries",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          country: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCountry = () => {
    let result = "";
    let index = 0;
    result = this.state.country.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  countryChange = () => {
    this.getCity();
  };

  getCity = () => {
    let classRef = this;
    let country = $("#country").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCity = () => {
    let result = "";
    let index = 0;
    result = this.state.city.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  getTags = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "tags",
      data: { company_id: localStorage.getItem('company_id') },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          tagList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  onSelectTags(selectedList, selectedItem) {
    this.setState({ tags: selectedList, old_contact_tags: selectedList });
  }
  onRemoveTags(selectedList, removedItem) {
    this.setState({ tags: selectedList, old_contact_tags: selectedList });
  }






  



  callContactApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "GET",
      url: Constant.url + "pso_bot_compaign",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          ContactList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountContact: result.data.rowscount,
        });
      } else {
        classRef.setState({
          ContactList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountContact: result.data.rowscount,
        });
      }
    });
  };



  handleSegmentsClick = (data) => {
    let currentPage = data.selected + 1;
    this.SegmentsSorting(currentPage);
  };

  handleContactTable = () => {
    let result = "";
    let index = 0;
    let tagCount = "";
    let tags = "";
    result = this.state.ContactList.list.map(
      (response, index) => (
        // (tagCount = response.tags.split(",")),
        // (tagCount = tagCount.length - 2),
        // (tags =
        //   response.tags.split(",")[0] + "," + response.tags.split(",")[1]),
        // (
          <tr id={"contact_" + response.id}>
            
            <td>
              <div className="">
                {response.name} 
              </div>
            </td>
        
            <td>{response.msisdn}</td>
            <td>{response.email_address}</td>
            
            <td>{response.city}</td>
            <td><a href={response.video_url} target="_blank">{response.video_url}</a></td>
          </tr>
        )
      
    );
    return result;
  };

  callSegmentApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SegmentList: {
            isLoaded: true,
            msg: "Data Found",
            list: result.data.data,
          },
          rowsCountSegments: result.data.rowscount,
        });
      } else {
        classRef.setState({
          SegmentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountSegments: result.data.rowscount,
        });
      }
    });
  };

  closeAutoSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  addAutoSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };


  downloadContacts = () => {
    let classRef = this;
    var company_id = localStorage.getItem("company_id");
    window.open(
      Constant.url + "pso_bot_data_export?company_id=" + company_id,
      "_blank"
    );
  };
 

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callSegmentApi();
    $("#across").hide();
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };


  AutoadvanceSearch = () => {
    try {
      const classRef = this;
      const dateto = $("#to_dates").val();
      const datefrom = $("#from_dates").val();
      const company_id = localStorage.getItem("company_id");
  
      const data = {
        dateto,
        datefrom,
        company_id,
      };
  
      let key = "";
  
      if (dateto) {
        key = "Date To: " + dateto;
      }
      if (datefrom) {
        key = key + " Date From: " + datefrom;
      }
  
      if (key !== "") {
        $("#across").show();
      }
  
      classRef.setState({ advancesearch: key });
  
      const apiUrl = Constant.url + "pso_bot_compaign?start_date="+dateto+"&end_date="+datefrom+"";
      const params = {
        start_date: dateto,
        end_date: datefrom,
      };
  
      axios
        .get(apiUrl, { params })
        .then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              SegmentList: {
                isLoaded: true,
                msg: "Loading...",
                list: result.data.data,
              },
            });
            $("#SearchTeamModal").removeClass("uk-flex uk-open");
          } else {
            classRef.setState({
              SegmentList: {
                isLoaded: false,
                msg: "not",
                list: [],
              },
            });
            $("#SearchTeamModal").removeClass("uk-flex uk-open");
          }
        })
        .catch(function (error) {
          console.error("Error in AutoadvanceSearch:", error);
          // Handle the error appropriately, e.g., show an error message to the user
        });
    } catch (error) {
      console.error("Error in AutoadvanceSearch:", error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  };

  advAtuoSearchClear = () => {
    let classRef = this;
    classRef.setState({ advanceautosearch: "" });
    classRef.callContactApi();
    $("#cross").hide();
  };

  clearFilters = () => {
    $("#from_dates").val("");
    $("#to_dates").val("");
  };

  render() {
    return (
      <Content>
        <div
          className="AutomationContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          {/* Automation Tab Start */}
          <div className="TabsHeadBox">
            <ul className="tabsBtns desktop" uk-switcher="connect: .multi_div">
              <li id="tagtab">
                <a href="#">Report</a>
              </li>
             
            </ul>
            <div class="mobTabsBtn">
              
              <ul className="tabsBtns" uk-switcher="connect: .multi_div">
                <li id="tagtab">
                  <a href="#">Report</a>
                </li>
                
              </ul>
            </div>

            <ul className="uk-switcher multi_div" id="tabsBtns">
              <li id="tagtablistbutton">
                <div className="searchnFlowBtn mobile audiencesearchnFlowBtn">
                  <form className="uk-search" action="javascript:void(0)">
                    <input
                      className="uk-search-input"
                      onChange={this.searchnum}
                      value={this.state.advanceautosearch}
                      type="search"
                      name="ssearchword"
                      id="ssearchword"
                      placeholder="Search through contacts name"
                    />
                  </form>
                  <div class="Filtermain audienceContactFiltermain" style={{right: '220px'}}>
                    <div className="uk-width-auto">
                      <button
                        id="cross"
                        style={{ right: "30px" }}
                        onClick={this.advAtuoSearchClear}
                      >
                        <Close />
                      </button>

                      <button
                        className="popadvanceFilter"
                        onClick={this.addSearchModal}
                      >
                        <img src="/images/newfilter.svg" uk-svg />
                      </button>
                    </div>
                  </div>

                  

                  <button
                    class="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.downloadContacts}
                  >
                    <Add /> <span>Export Report</span>
                  </button>
                  
                </div>
              </li>
            </ul>
          </div>
          <div id="errorAlertmain"></div>
          <ul className="uk-switcher TabSec multi_div">
            <li id="tagtablist">
              
              <div className="tableSec">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-divider CustomTableWidth">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Email Address</th>
                        <th>City</th>
                        <th>Video URL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {(this.state.ContactList.isLoaded) ? this.handleContactTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>} */}

                      {this.state.ContactList.isLoaded ? (
                        this.handleContactTable()
                      ) : this.state.ContactList.msg == "not" ? (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              Data not Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              <div className="mdl">
                                <div className="mdl_inner">
                                  <div uk-spinner=""></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {this.state.rowsCountContact > 1 ? (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"...."}
                      pageCount={this.state.rowsCountContact}
                      onPageChange={this.handleContactClick}
                      containerClassName={"uk-pagination uk-float-right"}
                      activeClassName={"uk-active"}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </li>        
          </ul>
        </div>




        {/* auto search start */}

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_dates"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_dates"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn ">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeAutoSearchModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.AutoadvanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* auto search end */}

      </Content>
    );
  }
}
export default Audience;
