import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from "axios";
import $, { cssNumber, data } from "jquery";
import { AddAlt, Close } from "@carbon/icons-react";
class Support extends React.Component {
  componentDidMount() {
    if(localStorage.getItem('loginStatus')== 1){
      let screens = localStorage.getItem('rolesceens').split(',');
      var checking = screens.includes("7");
       if(checking == true){

          }else{
              window.location.href = "/"; 
          }
      }
      else{
          window.location.href = "/"; 
      }
  }

  showContactModal = () => {
    $("#ContactExpert").addClass("uk-flex uk-open");
  };

  closeContactPopup = () => {
    $("#ContactExpert").removeClass("uk-flex uk-open");
  };

  emailContactDetails = () => {
    $("#SendDetail").text("Processing");
    $("#SendDetail").prop("disabled", false);
    let name = $("#name").val();
    let email = $("#email").val();
    let comments = $("#comments").val();
    axios({
      method: "POST",
      url: Constant.url + "contact-expert",
      data: { name: name, email: email, comments: comments },
    }).then(function (result) {
      if (result.data.status === true) {
        $("#contact_expert")[0].reset();
        $("#successAlertContactExpert").append(
          '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Email has been sent successfully.</p></div>'
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#SendDetail").text("Send");
          $("#SendDetail").prop("disabled", false);
          $("#ContactExpert").removeClass("uk-flex uk-open");
        }, 1000);
      } else {
        $("#errorAlertContactExpert").append(
          '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#SendDetail").text("Send");
          $("#SendDetail").prop("disabled", false);
        }, 1000);
      }
    });
  };

  startChat = () => {
    this.showTawk();
  };

  showTawk = () => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/6317225e37898912e9677602/1gc96fvvt";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
    // $(".tawk-custom-color").click();
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer adminContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Support help</h3>
              </div>
            </div>
          </div>
          <div className="integration inner fullWdth">
            <div className="SupportMain">
              <h2>
                <b>Hi, what can we help you with?</b>
              </h2>
              <p>
                For help with account management, please contact your account
                manager.
              </p>
              <div class="uk-margin">
                <div class="ModalStyleBtns">
                  <button class="AddTeamBtn" onClick={this.startChat}>
                    Start Chat
                  </button>
                  <button class="CnclBtn" onClick={this.showContactModal}>
                    Request Expert Session
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="widget-tawk"></div>
        {/* ContactExpert start */}
        <div
          id="ContactExpert"
          className="AddNewContact  ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Contact Expert</h3>
            <p>All fields is mandatory to fill.</p>
            <form
              className="uk-form-stacked"
              id="contact_expert"
              action="javascript:void(0);"
            >
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Full Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    placeholder="Enter full name"
                    id="name"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="email"
                    name="Email"
                    type="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Email
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="comments"
                    name="comments"
                    rows={3}
                  ></textarea>
                </div>
              </div>
              <div id="errorAlertContactExpert"></div>
              <div id="successAlertContactExpert"></div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeContactPopup}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="SendDetail"
                    onClick={this.emailContactDetails}
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* contact edit end */}
      </Content>
    );
  }
}
export default Support;
