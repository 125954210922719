import * as React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
    Filter,
    Tag,
    Add,    
    Close,
  } from "@carbon/icons-react";
import Constant from "../../App/Constant";
import axios from 'axios';
import $ from 'jquery';
class PageActionBar extends React.Component {

    constructor() {
        super();        
        this.getRoles = this.getRoles.bind(this);
        this.handleRoles = this.handleRoles.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.state = {
          role: {
            isLoaded: false,
            msg: 'Loading...',
            list: []
          },
          dataList :{
            isLoaded: false,
            msg: 'Loading...',
            list: []
          },
          country: {
              isLoaded: false,
              msg: 'Loading...',
              list: []
          },
          city: {
              isLoaded: false,
              msg: 'Loading...',
              list: []
          },
          agentList: {
              isLoaded: false,
              msg: 'Loading...',
              list: []
          },
          filterType : "is",
          popUpName : "",
          popUpNumber : "",
          popUpGender : "",            
          popUpTag : "",
          popUpSunscribe : "",
          popUpContactTime : "",
        }
      }

      componentDidMount() {
        this.getRoles();
        this.getCountry();
        this.getAgents();
      }
      getAgents = () => {
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "get-agents",
            data: { company_id: localStorage.getItem('company_id') }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    agentList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                })
            }
        })
    }


    handleAgent = () => {
        let result = "";
        let index = 0;
        result = this.state.agentList.list.map((response, index) => (   
          <option value={response.id}>{response.name}</option>
        ));
        return result;
      }

    getCountry = () => {
        let classRef = this;
        axios({
          method: 'POST',
          url: Constant.url + "countries",
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              country: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data
              }
            })
          }
        })
      }
    
      handleCountry = () => {
        let result = "";
        let index = 0;
        result = this.state.country.list.map((response, index) => (   
          <option value={response.name}>{response.name}</option>
        ));
        return result;
      }

      countryChange = () =>{
        this.getCity();
      }

      filtercountryChange = () =>{
        this.getfilterCity();
      }

      getfilterCity = () => {
        let classRef = this;
        let country = $('#filtercountry').val();
        axios({
          method: 'POST',
          url: Constant.url + "cities",
          data : { country_name : country }
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              city: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data
              }
            })
          }
        })
      }

      getCity = () => {
        let classRef = this;
        let country = $('#country').val();
        axios({
          method: 'POST',
          url: Constant.url + "cities",
          data : { country_name : country }
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              city: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data
              }
            })
          }
        })
      }
    
      handleCity = () => {
        let result = "";
        let index = 0;
        result = this.state.city.list.map((response, index) => (   
          <option value={response.name}>{response.name}</option>
        ));
        return result;
      }

    emailFilter = () => {
        $("#emailDrop").addClass('uk-open');        
    }

    nameFilter = () => {
      $("#nameDrop").addClass('uk-open');        
    }

    addAgentModal = () =>{
        $("#AddAgentsModal").addClass('uk-flex uk-open');
      }
    
      closeAgentModal = () =>{
        $("#AddAgentsModal").removeClass('uk-flex uk-open');
      }

      handleRoles = () => {
        let result = "";
        let index = 0;
        result = this.state.role.list.map((response, index) => (   
          <option value={response.id}>{response.name}</option>
        ));
        return result;
      }      
    
      addAgent = () => {    

        $('.AddTeamBtn').text('Processing')
        $('.AddTeamBtn').prop('disabled', true);  
        let classRef = this;
        var data = {};
        data = {
          role: $("#role").val(), 
          full_name: $("#full_name").val(),
          phone:$("#phone").val(),
          email: $("#email").val(),
          password: $("#password").val(),
          password_confirmation: $("#password_confirmation").val(),
          invitation: "no",
          company_id:localStorage.getItem('company_id')
        }
    
        axios({
          method: 'POST',
          url: Constant.url + "add-agent",
          data: data
        }).then(function (response) {
          // let response = {};
          // response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
          if (response.data.code === 200) {
    
            $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
    
            window.location.href = "/agents";
          }else{
    
            $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
            $('.AddTeamBtn').text('Add Team')
            $('.AddTeamBtn').prop('disabled', false);
            return;
        
    
          }
        }).then(function (response) {
    
        });
      };      

      getRoles = () => {
        let classRef = this;
        axios({
          method: 'POST',
          url: Constant.url + "roles",
          data: { company_id: localStorage.getItem('company_id') }
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              role: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data
              }
            })
          }
        })
      }

      searchnum = () =>{        
        let classRef = this;        
        var name = $("#filterName").val();
        var email = $("#filterEmail").val();
        var date = $("#filterDate").val();
        var phone = $("#filterPhone").val();
        var gender = $("#filterGender").val();
        if(name != ""){
          window.filterSearch("first_name",name);
          this.clearTextbox("","email","date","phone","gender");
        }
        if(email != ""){
          window.filterSearch("email",email);
          this.clearTextbox("name","","date","phone","gender");
        }
        if(date != ""){
          window.filterSearch("date",date);
          this.clearTextbox("name","email","","phone","gender");
        }
        if(phone != ""){
          window.filterSearch("phone",phone);
          this.clearTextbox("name","email","date","","gender");
        }
        if(gender != ""){
          window.filterSearch("gender",gender);
          this.clearTextbox("name","email","date","phone","");
        }
      }

      clearTextbox = (name,email,date,phone,gender)=>{
        if(name != ""){
          $("#filterName").val("");
        }
        if(email != ""){
          $("#filterEmail").val("");
        }
        if(date != ""){
          $("#filterDate").val("");
        }
        if(phone != ""){
          $("#filterPhone").val("");
        }
        if(gender != ""){
          $("#filterGender").val("");
        }
      }

      openFilter = (seletorID) =>{
        this.hideAllFilter();
        $("#"+seletorID).show();
        $("#"+seletorID).addClass('uk-dropdown uk-open');
      }

      hideFilter = (seletorID) =>{
          $("#"+seletorID).removeClass('uk-dropdown uk-open');
          $("#"+seletorID).hide();
      }

      hideAllFilter = () =>{
          if(!$("#mainModal").hasClass('uk-open')){
              this.searchnum();
          }
          $("#nameDrop").hide();
          $("#emailDrop").hide();
          $("#dateDrop").hide();
          $("#phoneDrop").hide();
          // $("#cityDrop").hide();
          // $("#countryDrop").hide();
          // $("#regionDrop").hide();
          $("#genderDrop").hide();
          // $("#usernameDrop").hide();
          $("#nameDrop").removeClass('uk-dropdown uk-open');
          $("#emailDrop").removeClass('uk-dropdown uk-open');
          $("#dateDrop").removeClass('uk-dropdown uk-open');
          $("#phoneDrop").removeClass('uk-dropdown uk-open');
          // $("#cityDrop").removeClass('uk-dropdown uk-open');
          // $("#countryDrop").removeClass('uk-dropdown uk-open');
          // $("#regionDrop").removeClass('uk-dropdown uk-open');
          $("#genderDrop").removeClass('uk-dropdown uk-open');
          // $("#usernameDrop").removeClass('uk-dropdown uk-open');


      }
    
      handleRoles = () => {
        let result = "";
        let index = 0;
        result = this.state.role.list.map((response, index) => (   
          <option value={response.id}>{response.name}</option>
        ));
        return result;
      }
      getcurrentDate = () =>{
        let today = new Date();
        let date = today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+("0" + (today.getDate())).slice(-2);
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        return date+'T'+time;
      }
      handleKeyPress = (e) => {
        let date = new Date();
        if(e.target.value.length == 0){
          $('#chatImage').removeClass("uk-hidden");
          $("#data-error").addClass("uk-hidden");
          $(".contacts_list ul").empty();
          $("#selectDiv").addClass("uk-hidden");
          $('#chat_box').addClass("uk-hidden");
          $('#profile_details').addClass("uk-hidden");
          $(".spinner").removeClass("uk-hidden");
          let company_id = localStorage.getItem('company_id');
          let user_from = localStorage.getItem('user_id');
          let mTimerClass = "";
          let mExpiredClass = "";
          let threadParams = new FormData();
          threadParams.append( 'user_id', user_from );
          threadParams.append( 'company_id', company_id );
          $.ajax({
              type:'POST',
              url: Constant.url + "live-chat/"+company_id,
              data: threadParams,
              contentType: false,
              processData: false,
              success:function(response){
                  if(response.status === true){
                      $(".spinner").addClass("uk-hidden");
                      $(".contacts_list ul").empty();
                      for(let i = 0; i < response.data.length; i++){
                        let today = new Date();
                        let date = today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+("0" + (today.getDate())).slice(-2);
                        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                        if(response.data[i].timer.replace("PK", "") > date+'T'+time){
                            mTimerClass = "";
                            mExpiredClass = "uk-hidden";
                        } else {
                            mTimerClass = "uk-hidden";
                            mExpiredClass = "";
                        }
                        let mAssignedCondition = response.data[i].user_id == null ? "<div class='assignedunassigned'>Unassigned</div>" : "<div class='assignedunassigned'>Assigned</div>";
                        $(".contacts_list ul").append("<li id='li"+response.data[i].conversation_id+"'><div class='chat_tab uk-grid uk-grid-small' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='chat_select'><input class='uk-checkbox checkbox' id='select"+response.data[i].conversation_id+"' onClick='onClickCheckbox()' type='checkbox' name='threadSelect'></div></div><a class='user_list uk-width-expand' id='convesation_iteation_"+response.data[i].conversation_id+"' onclick='fetchUserChat("+response.data[i].conversation_id+")'><div class='uk-grid-small uk-grid' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='pp'><img src='"+response.data[i].avatar+"'/></div></div><div class='uk-width-expand'><p id='customer_"+response.data[i].conversation_id+"'>"+response.data[i].name+"</p><p id='msg_"+response.data[i].conversation_id+"'>"+response.data[i].preview+"</p></div><div class='uk-width-auto uk-flex-last'> <div class='timingBox'>"+mAssignedCondition+"<div class='time timeLeft'><div class='countdown "+mTimerClass+"'><div class='uk-grid-collapse uk-child-width-auto uk-grid uk-countdown uk-grid-stack' uk-grid='' uk-countdown='date: "+response.data[i].timer.replace("PK", "") +"+05:00'><div><div class=' uk-countdown-hours'><span>1</span><span>8</span></div></div></div></div><p class='blue "+mTimerClass+"'>h<span class='left'>left</span></p><p class='red "+mExpiredClass+"'><span class='left'>expired</span></p></div><div class='time newTime'><p>"+response.data[i].last_reply_at+"</p></div></div></div></div></a></div></li>");
                          // $(".contacts_list ul").append("<li id='li"+response.data[i].conversation_id+"'><div class='chat_tab uk-grid uk-grid-small' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='chat_select'><input class='uk-checkbox checkbox' id='select"+response.data[i].conversation_id+"' onClick='onClickCheckbox()' type='checkbox' name='threadSelect'></div></div><a class='user_list uk-width-expand' id='convesation_iteation_"+response.data[i].conversation_id+"' onclick='fetchUserChat("+response.data[i].conversation_id+")'><div class='uk-grid-small uk-grid' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='pp'><img src='"+response.data[i].avatar+"'/></div></div><div class='uk-width-expand'><p id='customer_"+response.data[i].conversation_id+"'>"+response.data[i].name+"</p><p id='msg_"+response.data[i].conversation_id+"'>"+response.data[i].preview+"</p></div><div class='uk-width-auto uk-flex-last'><div class='time'><div class='countdown "+mTimerClass+"'><div class='uk-grid-collapse uk-child-width-auto uk-grid uk-countdown uk-grid-stack' uk-grid='' uk-countdown='date: "+response.data[i].timer.replace("PK","")+"+05:00'><div><div class=' uk-countdown-hours'><span>1</span><span>8</span></div></div></div></div><p class='blue "+mTimerClass+"'>h<span class='left'>left</span></p><p class='red "+mExpiredClass+"'><span class='left'>expired</span></p></div><div class='time newTime'><p>"+response.data[i].last_reply_at+"</p></div></div></div></a></div></li>");
                      }
                      $("#selectDiv").removeClass("uk-hidden");
                  }
              }
          });
        }
        if (e.target.value.length > 2){
          $("#data-error").addClass("uk-hidden");
          $(".contacts_list ul").empty();
          $("#selectDiv").addClass("uk-hidden");
          $('#chat_box').addClass("uk-hidden");
          $('#profile_details').addClass("uk-hidden");
          $('#chatImage').removeClass("uk-hidden");
          $(".spinner").removeClass("uk-hidden");
          let search_text = $('#search_box').val().trim();
          let mTimerClass = "";
          let mExpiredClass = "";
          let user_from = localStorage.getItem('user_id');
          axios({
            method: 'POST',
            url: Constant.url + "live-chat/1",
            data: { search : search_text , user_id : user_from}
          }).then(function (response) {
            if (response.data.code == 200) {
              $(".contacts_list ul").empty();
                for(let i = 0; i < response.data.data.length; i++){
                  let today = new Date();
                  let date = today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+("0" + (today.getDate())).slice(-2);
                  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                  if(response.data.data[i].timer.replace("PK", "") > date+'T'+time){
                      mTimerClass = "";
                      mExpiredClass = "uk-hidden";
                  } else {
                      mTimerClass = "uk-hidden";
                      mExpiredClass = "";
                  }
                  let mAssignedCondition = response.data.data[i].user_id == null ? "<div class='assignedunassigned'>Unassigned</div>" : "<div class='assignedunassigned'>Assigned</div>";
                  $(".contacts_list ul").append("<li id='li"+response.data.data[i].conversation_id+"'><div class='chat_tab uk-grid uk-grid-small' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='chat_select'><input class='uk-checkbox checkbox' id='select"+response.data.data[i].conversation_id+"' onClick='onClickCheckbox()' type='checkbox' name='threadSelect'></div></div><a class='user_list uk-width-expand' id='convesation_iteation_"+response.data.data[i].conversation_id+"' onclick='fetchUserChat("+response.data.data[i].conversation_id+")'><div class='uk-grid-small uk-grid' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='pp'><img src='"+response.data.data[i].avatar+"'/></div></div><div class='uk-width-expand'><p id='customer_"+response.data.data[i].conversation_id+"'>"+response.data.data[i].name+"</p><p id='msg_"+response.data.data[i].conversation_id+"'>"+response.data.data[i].preview+"</p></div><div class='uk-width-auto uk-flex-last'> <div class='timingBox'>"+mAssignedCondition+"<div class='time timeLeft'><div class='countdown "+mTimerClass+"'><div class='uk-grid-collapse uk-child-width-auto uk-grid uk-countdown uk-grid-stack' uk-grid='' uk-countdown='date: "+response.data.data[i].timer.replace("PK", "") +"+05:00'><div><div class=' uk-countdown-hours'><span>1</span><span>8</span></div></div></div></div><p class='blue "+mTimerClass+"'>h<span class='left'>left</span></p><p class='red "+mExpiredClass+"'><span class='left'>expired</span></p></div><div class='time newTime'><p>"+response.data.data[i].last_reply_at+"</p></div></div></div></div></a></div></li>");
                    // $(".contacts_list ul").append("<li id='li"+response.data.data[i].conversation_id+"'><div class='chat_tab uk-grid uk-grid-small' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='chat_select'><input class='uk-checkbox checkbox' id='select"+response.data.data[i].conversation_id+"' onClick='onClickCheckbox()' type='checkbox' name='threadSelect'></div></div><a class='user_list uk-width-expand' id='convesation_iteation_"+response.data.data[i].conversation_id+"' onclick='fetchUserChat("+response.data.data[i].conversation_id+")'><div class='uk-grid-small uk-grid' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='pp'><img src='"+response.data.data[i].avatar+"'/></div></div><div class='uk-width-expand'><p id='customer_"+response.data.data[i].conversation_id+"'>"+response.data.data[i].name+"</p><p id='msg_"+response.data.data[i].conversation_id+"'>"+response.data.data[i].preview+"</p></div><div class='uk-width-auto uk-flex-last'><div class='time'><div class='countdown "+mTimerClass+"'><div class='uk-grid-collapse uk-child-width-auto uk-grid uk-countdown uk-grid-stack' uk-grid='' uk-countdown='date: "+response.data.data[i].timer.replace("PK","")+"+05:00'><div><div class=' uk-countdown-hours'><span>1</span><span>8</span></div></div></div></div><p class='blue "+mTimerClass+"'>h<span class='left'>left</span></p><p class='red "+mExpiredClass+"'><span class='left'>expired</span></p></div><div class='time newTime'><p>"+response.data.data[i].last_reply_at+"</p></div></div></div></a></div></li>");
                }
                $(".spinner").addClass("uk-hidden");
                $("#selectDiv").removeClass("uk-hidden");
            } else {
              $(".spinner").addClass("uk-hidden");
              $('#chatImage').addClass("uk-hidden");
              $("#selectDiv").removeClass("uk-hidden");
              $("#data-error").removeClass("uk-hidden");
            }
          })
        }
      }
    
      addSearchModal = () => {
        $("#SearchTeamModal").addClass('uk-flex uk-open');
      }
    
      closeSearchModal= () => {
          $("#SearchTeamModal").removeClass('uk-flex uk-open');
      }
    
      advanceSearch = () =>{  
        let classRef = this;
        var data = {};
        data = {
        dateto: $("#to_date").val(),
        datefrom: $("#from_date").val(),
        country: $("#filtercountry").val(),
        city: $("#filtercity").val(),
        gender: $("#filtergender").val(),
        agent: $("#filteragents").val(),
        company_id: localStorage.getItem('company_id')
        }
        let company_id = localStorage.getItem('company_id');
        axios({
        method: 'POST',
        url: Constant.url + "live-chat/"+company_id,
        data: data
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    dataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                })
                $("#SearchTeamModal").removeClass('uk-flex uk-open');
            }else{
                classRef.setState({
                  dataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
              })
              $("#SearchTeamModal").removeClass('uk-flex uk-open');
              }
      
      
        });
      
      }

    render() {
        return (
            
            <div className="head">
                <div className=" uk-grid " uk-grid="">
                    <div className="uk-width-1-4"><h3>Live chat</h3></div>
                    <div className="uk-width-expand uk-text-center">
                        <div className="search_field">
                            <form>
                                <input className="uk-input" type="text" id="search_box" onKeyUp={this.handleKeyPress} placeholder="Search through live chat conversations"/>
                            </form>
                            <div class="CampaignReports Filtermain">
                                <div className="uk-width-auto">
                                    <button onClick = {this.addSearchModal}>
                                        <img src="/images/newfilter.svg" uk-svg/>
                                    </button>
                                </div>
                            </div> 
                        </div>
                        <div class="Filtermain uk-display-inline-block uk-margin-left">
                          <div className="uk-width-auto">
                              <button class="" onClick={this.hideAllFilter}  type="button"><Filter /><span>Filter</span></button>
                              <div id="mainModal" uk-dropdown="mode: click;">
                                  <div class="uk-margin-small">
                                      {/* <div class="uk-inline"> */}
                                          {/* <span class="uk-form-icon" uk-icon="icon: user"></span> */}
                                          {/* <input class="uk-input" type="text" placeholder="Search"/> */}
                                      {/* </div> */}
                                  </div>
                                  <ul className="uk-nav uk-dropdown-nav">
                                      <li class="">
                                          <button class="uk-button" type="button" onClick={(e)=>{this.openFilter("nameDrop")}}><span>Name</span></button>
                                      </li>
                                      <li class="uk-active">
                                          <button class="uk-button" onClick={(e)=>{this.openFilter("emailDrop")}} type="button"><span>Email</span></button>
                                      </li>                                                                                                                                    
                                      <li ><button class="uk-button" onClick={(e)=>{this.openFilter("dateDrop")}}><span>Date</span></button></li>
                                      <li ><button class="uk-button" onClick={(e)=>{this.openFilter("phoneDrop")}}><span>Phone</span></button></li>
                                      {/* <li ><button class="uk-button" onClick={(e)=>{this.openFilter("cityDrop")}}><span>City</span></button></li> */}
                                      {/* <li ><button class="uk-button" onClick={(e)=>{this.openFilter("countryDrop")}}><span>Country</span></button></li> */}
                                      {/* <li ><button class="uk-button" onClick={(e)=>{this.openFilter("regionDrop")}}><span>Region</span></button></li>                                                                                                                                                                  */}
                                      <li ><button class="uk-button" onClick={(e)=>{this.openFilter("genderDrop")}}><span>Gender</span></button></li>
                                      {/* <li ><button class="uk-button" onClick={(e)=>{this.openFilter("usernameDrop")}}><span>Username</span></button></li>                                             */}
                                  </ul>
                              </div>

                              <div id="nameDrop" style={{display:'none'}}>
                                  <p><b>Name</b></p>
                                  <ul className="uk-nav uk-dropdown-nav customNavDrop">
                                      <li>
                                          <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                          <input type="text" id="filterName" />
                                      </li>
                                  </ul>
                              </div>

                              <div id="emailDrop" style={{display:'none'}}>
                                  <p><b>Email</b></p>
                                  <ul className="uk-nav uk-dropdown-nav customNavDrop">
                                      <li>
                                          <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                          <input type="email" id="filterEmail" />
                                      </li>
                                  </ul>
                              </div>

                              <div id="dateDrop" style={{display:'none'}}>
                                  <p><b>Date</b></p>
                                  <ul className="uk-nav uk-dropdown-nav customNavDrop">
                                      <li>
                                          <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                          <input type="date" id="filterDate"/>
                                      </li>
                                  </ul>
                              </div>                                    
                              
                              <div id="phoneDrop" style={{display:'none'}}>
                                  <p><b>Phone</b></p>
                                  <ul className="uk-nav uk-dropdown-nav customNavDrop">
                                      <li>
                                          <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                          <input type="text" id="filterPhone"/>
                                      </li>
                                  </ul>
                              </div>       

                              {/* <div id="countryDrop" style={{display:'none'}}>
                                  <p><b>Country</b></p>
                                  <ul className="uk-nav uk-dropdown-nav">
                                      <li>
                                          <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                          <input type="text" id="filterCountry"/>
                                      </li>
                                  </ul>
                              </div> 

                              <div id="regionDrop" style={{display:'none'}}>
                                  <p><b>Region</b></p>
                                  <ul className="uk-nav uk-dropdown-nav">
                                      <li>
                                          <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                          <input type="text" id="filterRegion" />
                                      </li>
                                  </ul>
                              </div>  */}

                              <div id="genderDrop" style={{display:'none'}}>
                                  <p><b>Gender</b></p>
                                  <ul className="uk-nav uk-dropdown-nav customNavDrop">
                                      <li>
                                          <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                          <input type="text" id="filterGender"/>
                                          <select className="uk-hidden">
                                              <option value="male">male</option>
                                              <option value="female">female</option>
                                          </select>
                                      </li>
                                  </ul>
                              </div>  

                              {/* <div id="usernameDrop" style={{display:'none'}}>
                                  <p><b>Username</b></p>
                                  <ul className="uk-nav uk-dropdown-nav">
                                      <li>
                                          <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                          <input type="text" id="filterUsername"/>
                                      </li>
                                  </ul>
                              </div>                                                                                                                                            */}
                          </div>
                      </div>
                    </div>
                    
                    {/* <div class="Filtermain">
                        <div className="uk-width-auto">
                            <button class="" type="button"><Filter /><span>Filter</span></button>
                            <div uk-dropdown="mode: click;pos">
                                <div class="uk-margin-small">
                                    <div class="uk-inline">
                                        <span class="uk-form-icon" uk-icon="icon: user"></span>
                                        <input class="uk-input" type="text" placeholder="Search"/>
                                    </div>
                                </div>
                                <ul className="uk-nav uk-dropdown-nav">
                                    <li class="">
                                        <button class="uk-button" onClick={this.nameFilter} type="button"><span>Name</span></button>
                                    </li>
                                    <li>
                                        <button class="uk-button" onClick={this.emailFilter} type="button"><span>Email</span></button>
                                    </li>
                                    <li ><button class="uk-button"><span>Last seen</span></button></li>
                                    
                                    <li ><button class="uk-button"><span>First seen</span></button></li>
                                    <li ><button class="uk-button"><span>Date</span></button></li>
                                    <li ><button class="uk-button"><span>City</span></button></li>
                                    <li ><button class="uk-button"><span>Country</span></button></li>
                                    <li ><button class="uk-button"><span>Region</span></button></li>
                                    <li ><button class="uk-button"><span>Language</span></button></li>
                                    <li ><button class="uk-button"><span>URL Referrer</span></button></li>
                                    <li ><button class="uk-button"><span>IP</span></button></li>
                                    <li ><button class="uk-button"><span>Timezone</span></button></li>
                                    <li ><button class="uk-button"><span>Integration</span></button></li>
                                    <li ><button class="uk-button"><span>URL</span></button></li>
                                    <li ><button class="uk-button"><span>Gender</span></button></li>
                                    <li ><button class="uk-button"><span>Username</span></button></li>
                                    <li ><button class="uk-button"><span>Username</span></button></li>
                                </ul>
                            </div>


                            <div id="emailDrop" uk-dropdown="mode: click;">
                              <p><b>Email</b></p>
                              <ul className="uk-nav uk-dropdown-nav">
                                  <li>
                                      <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                      <input type="text" />
                                  </li>
                              </ul>
                            </div>

                            <div id="nameDrop" uk-dropdown="mode: click;">
                              <p><b>Name</b></p>
                              <ul className="uk-nav uk-dropdown-nav">
                                  <li>
                                      <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                      <input type="text" />
                                  </li>
                              </ul>
                            </div>

                        </div>
                    </div> */}
                    {/* <div className="uk-width-auto">
                        <Link to="javascript:;"><Tag /><span>Segments</span></Link>
                    </div>
                    <div className="uk-width-auto">
                        <Link to="javascript:;"><Tag /><span>Tags</span></Link>
                    </div> */}
                    <div className="uk-width-1-4" id="add-agent">
                        <button className="newFlowBtn uk-float-right"  uk-toggle="target: #AddBroadcastModal"><Add /> <span>Add Agent</span></button>

                    </div>
                </div>
                <div id="SearchTeamModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button"> <Close /></button>
                        <h3>Advance Search</h3>
                        <form className="uk-form-stacked" action="javascript:void(0);">

                            <div className="uk-margin">
                                <div className="Campaigndate">
                                    <label className="uk-form-label" htmlFor="ToDate">To Date</label>
                                        <div class="uk-form-controls">
                                            <input type="date" label="Select Broadcast" className="to_date" id="to_date"/>
                                            
                                        </div>
                                </div>

                                <div className="Campaigndate">
                                    <label className="uk-form-label" htmlFor="FromDate">From Date</label>
                                        <div class="uk-form-controls">
                                            <input type="date" label="Select Broadcast" className="from_date" id="from_date"/>
                                        </div>
                                </div>
                            </div>


                            <div className="uk-margin">
                                <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="teamName">Country</label>
                                <div className="uk-form-controls">
                                <select className="uk-select to_date" id="filtercountry" onChange={this.filtercountryChange} >
                                <option value="" disabled selected >Select Country</option>
                                {(this.state.country.isLoaded) ? this.handleCountry() : ""}
                                </select>
                                </div>

                                
                                </div>

                                <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="teamName">City</label>
                                <div className="uk-form-controls">
                                <select className="uk-select from_date" id="filtercity" >
                                <option value="" disabled selected >Select City</option>
                                {(this.state.city.isLoaded) ? this.handleCity() : ""}
                                </select>
                                </div>
                                
                                </div>
                            </div>


                            <div className="uk-margin">
                                <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="teamName">Gender</label>
                                <div className="uk-form-controls">
                                <select className="uk-select to_date" id="filtergender" >
                                <option value="" disabled selected >Select Gender</option>
                                <option value="Male" >Male</option>
                                <option value="Female" >Female</option>
                                </select>
                                </div>

                                            
                                </div>

                                <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="teamName">Agents</label>
                                <div className="uk-form-controls">
                                <select className="uk-select from_date" id="filteragents" >
                                <option value="" disabled selected >Select Agent</option>
                                {(this.state.agentList.isLoaded) ? this.handleAgent() : ""}
                                </select>
                                </div>
                                
                                </div>
                            </div>                       

                            <div className="uk-margin">
                                <div className="ModalStyleBtns CampaignBtn">
                                    <button className="CnclBtn" onClick={this.closeSearchModal}>Cancel</button>
                                    <button  className="AddTeamBtn" id="addteam" onClick={this.advanceSearch}>Search</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
        <div id="AddAgentsModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3>Add agent</h3>

            <div id="errorAlert"></div>
            <div id="successAlert"></div>


            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">Role</label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="role">
                    {(this.state.role.isLoaded) ? this.handleRoles() : ""}
                  </select>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">Full name</label>
                <div className="uk-form-controls">
                  <input className="uk-input" id="full_name" name="full_name" type="text" placeholder="Full legal name" />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">Phone</label>
                <div className="uk-form-controls">
                  <input className="uk-input" id="phone" name="phone" type="text" placeholder="Phone" />
                </div>
              </div>              
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">Email address</label>
                <div className="uk-form-controls">
                  <input className="uk-input" id="email" name="email" type="text" placeholder="name@example.com" />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">Password</label>
                <div className="uk-form-controls">
                  <input className="uk-input" id="password" name="password" type="password" placeholder="Minimum 8 characters" />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">Confirm password</label>
                <div className="uk-form-controls">
                  <input className="uk-input" id="password_confirmation" name="password_confirmation" type="password" placeholder="Minimum 8 characters" />
                </div>
              </div>
              <div className="uk-margin">
                <label>
                  <input className="uk-checkbox" id="invitation" name="invitation" type="checkbox" checked /> Send an email invite
                </label>
                <p className="notice">An invite can be sent later if you're not ready</p>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeAgentModal}>Cancel</button>
                  <button className="AddTeamBtn" id="addAgent" onClick={this.addAgent}>Add agent</button>
                </div>
              </div>
            </form>
          </div>
        </div>
            </div>
        )
    }

}

export default PageActionBar;


