import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import {
  CaretDown,
  Checkmark,
  Add,
  OverflowMenuVertical,
  Close,
} from "@carbon/icons-react";
import $, { cssNumber, data } from "jquery";
import axios from "axios";
import Constant, { url } from "../../../App/Constant";
class Integrations extends React.Component {
  componentDidMount() {
    // if (localStorage.getItem("loginStatus") == 1) {
    //   let screens = localStorage.getItem("rolesceens").split(",");
    //   var checking = screens.includes("9");
    //   if (checking == true) {
    //   } else {
    //     window.location.href = "/";
    //   }
    // } else {
    //   window.location.href = "/";
    // }

    // if (localStorage.getItem("loginStatus") !== 1) {

    //   window.location.href = "/";

      
      
    // } 




  }

  addIntegration = (event) => {
    var id = event.currentTarget.id;
    $("#ContactExpert").addClass("uk-flex uk-open");

    var email = localStorage.getItem("email");
    $("#email").val(email);

    if (id == "instagram") {
      $("#name").val("Instagram");
    }

    if (id == "zoho") {
      $("#name").val("zoho");
    }

    if (id == "zapier") {
      $("#name").val("Zapier");
    }

    if (id == "zendesk") {
      $("#name").val("Zendesk");
    }

    if (id == "shopify") {
      $("#name").val("shopify");
    }

    if (id == "googlesheets") {
      $("#name").val("Google Sheets");
    }

    if (id == "crm") {
      $("#name").val("CRM");
    }

    
  };

  createRquest = () => {
    $("#addtag").text("Processing");
    $("#addtag").prop("disabled", true);
    let classRef = this;

    var data = {};
    data = {
      channel_type: $("#name").val(),
      description: $("#description").val(),
      company_id: localStorage.getItem("company_id"),
      email: $("#email").val(),
      user_id: localStorage.getItem("user_id"),
    };
    axios({
      method: "POST",
      url: Constant.url + "integration-request-create",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#errorAlertContactExpert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/integrations";
        } else {
          $("#successAlertContactExpert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#addtag").text("Send");
          $("#addtag").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Integrations</h3>
              </div>
            </div>
          </div>
          <div className="integration">
            <h4>Popular integrating</h4>
            <div className="cards">
              <div
                className="uk-child-width-1-3@m"
                uk-grid=""
                uk-height-match=".uk-card-body"
              >
                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/pexels-charlotte.jpg"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/Integrations-whatsapp.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">WhatsApp</h3>
                        <p>
                          We integrates directly with the WhatsApp Business API
                          allowing you to start collecting contacts in a few
                          simple steps. You can select to either use your own
                          new phone number or have one given to you us.{" "}
                          <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        <Link to="/integrations/whatsapp">Integrate</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/facebookbg.png"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/facebook.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Facebook</h3>
                        <p>
                          Sell products, book appointments, nurture leads,
                          capture contact info, and build relationships all
                          through Messenger with Berry Talks multi-agent
                          platform. <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        <Link to="/integrations/facebook-messenger">
                          Integrate
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/instabg.png"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/instagram.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Instagram</h3>
                        <p>
                          We integrates directly with the WhatsApp Business API
                          allowing you to start collecting contacts in a few
                          simple steps. You can select to either use your own
                          new phone number or have one given to you us.{" "}
                          <Link to="/integrations/instagram">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        {/* <button>Under Development</button> */}
                        <Link to="/integrations/instagram">
                          Integrate
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/shopifyBg.jpg"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/shopify.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Shopify</h3>
                        <p>
                          Manage inventory, track payments, and view real-time
                          business insights from a single dashboard. We’ve built
                          all the tools you need, so you can focus on building
                          your business. <Link to="#">Learn more</Link>
                        </p>
                      </div>
                   

                      <div className="actions actionStyle">
                        {/* <button>Under Development</button> */}
                        <button id="shopify" onClick={this.addIntegration}>
                          Apply
                        </button>
                      </div>



                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/pexels-alex-andrews-894318.jpg"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/Integrations-sms.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">SMS</h3>
                        <p>
                          90% of text messages are read within 3 minutes! If you
                          want to make sure your reminders, promotions and news
                          are actually seen by your customers SMS marketing is
                          for you. <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        <a
                          href="https://sms.convexinteractive.com?rfap=6b775d308be35a685b6c3db432fab32c"
                          target="_blank"
                        >
                          Integrate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/pexels-george-desipris.jpg"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/Integrations-email.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Email</h3>
                        <p>
                          Create powerful Email marketing campaigns to re-engage
                          your customers. Reach out to your leads, increase
                          engagement, and learn how to win your customers back.{" "}
                          <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        {/* <button>Under Development</button> */}

                        <Link to="/integrations/email-account">Integrate</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/callcenterbg.png"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/Integrations-callcenter.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Call Center</h3>
                        <p>
                          Set up the live agent trigger, provide support,
                          assisting customers regarding, complaints, queries
                          about products or services, or intent to purchase from
                          the organization.
                          <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        <a
                          href="http://whatsapp.convexinteractive.com:6565/"
                          target="_blank"
                        >
                          Integrate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/googlesheetbg.jpg"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/Integrations-googlesheets.jpg" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Google Sheets</h3>
                        <p>
                          You can add the integration action in one of your
                          flows, and when your contact passes through this flow,
                          their data will be pushed to your Google Sheet.{" "}
                          <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      
                       <div className="actions actionStyle">
                        {/* <button>Under Development</button> */}
                        <button id="googlesheets" onClick={this.addIntegration}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/zapierbg.png"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/zapier.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Zapier</h3>
                        <p>
                          You can send Text Messages that contain text, emojis,
                          and links that you send to your contact. We can
                          successfully validates phone numbers it help your
                          messages delivered effectively.{" "}
                          <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        <button id="zapier" onClick={this.addIntegration}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/zendeskbg.png"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/zendesk.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Zendesk</h3>
                        <p>
                          Set up the live agent trigger, provide support,
                          assisting customers regarding, complaints, queries
                          about products or services, or intent to purchase from
                          the organization. <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        {/* <button>Under Development</button> */}
                        <button id="zendesk" onClick={this.addIntegration}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/zohobg.png"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/zoho.png" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">Zoho</h3>
                        <p>
                          Send an email when a new lead comes in, trigger
                          scheduled email sequences, log responses, track open
                          and engagement rates, and update contact records for
                          your business. <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        {/* <button>Under Development</button> */}
                        <button id="zoho" onClick={this.addIntegration}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="uk-card uk-card-default">
                    <div className="uk-card-media-top">
                      <img
                        src="/images/crmbg.jpg"
                        width="1800"
                        height="1200"
                        alt=""
                      />
                      <div className="brand_label">
                        <img src="images/Integrations-crm.jpg" />
                      </div>
                    </div>
                    <div className="uk-card-body">
                      <div className="integBodyTxt">
                        <h3 className="uk-card-title">CRM</h3>
                        <p>
                          CRM is everything you need to organize, track, and
                          nurture your leads and customers. Our own CRM allows
                          you to submit data to a form, create/update contact
                          properties and get contact properties.{" "}
                          <Link to="#">Learn more</Link>
                        </p>
                      </div>
                      <div className="actions actionStyle">
                        {/* <button>Under Development</button> */}
                        <button id="crm" onClick={this.addIntegration}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ContactExpert start */}
              <div
                id="ContactExpert"
                className="AddNewContact  ModalStyle uk-flex-top"
                uk-modal=""
              >
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                  <button className="uk-modal-close-default" type="button">
                    {" "}
                    <Close />
                  </button>
                  <h3>Contact Team</h3>
                  <p>All fields is mandatory to fill.</p>
                  <form
                    className="uk-form-stacked"
                    id="contact_expert"
                    action="javascript:void(0);"
                  >
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="">
                        Channel Name
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input"
                          name="Name"
                          placeholder="Enter full name"
                          id="name"
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="">
                        Email
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input"
                          id="email"
                          name="Email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>

                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="Description">
                        Requirments
                      </label>
                      <div className="uk-form-controls">
                        <textarea
                          className="uk-textarea textareaHeight"
                          id="description"
                          name="description"
                          type="text"
                          placeholder="Enter your requirements."
                        />
                      </div>
                    </div>

                    <div id="errorAlertContactExpert"></div>
                    <div id="successAlertContactExpert"></div>
                    <div className="uk-margin">
                      <div className="ModalStyleBtns">
                        <button className="CnclBtn uk-modal-close">
                          Cancel
                        </button>
                        <button
                          className="AddTeamBtn"
                          id="addtag"
                          onClick={this.createRquest}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default Integrations;
