import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { ContextualHelp } from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import PageActionBar from "../../Sections/PageActionBar";
import {
  Search,
  Filter,
  CaretDown,
  Information,
  Add,
  Edit,
  TrashCan,
  Download,
  OverflowMenuVertical,
  Close,
  AddAlt,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  Time,
} from "@carbon/icons-react";
import { Multiselect } from "multiselect-react-dropdown";
import PackagesRequest from "../Packages/PackagesRequest";
import OfficeHours from "../Admin/OfficeHours";
import Packages from "../Notification/Packages";
import Integrations from "../Integrations/Integrations";
import Webhook from "../Webhook/Webhook";
import Tag from "../Tags/Tag";
import QuickReplies from "../Tags/QuickReplies";

class Settings extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {

  

    if(localStorage.getItem('loginStatus')== 1){
      let screens = localStorage.getItem('rolesceens').split(',');
      var checking = screens.includes("11");

       if(checking == true){

          }else{
              window.location.href = "/"; 
          }
      }
      else{
          window.location.href = "/"; 
      }


  }

  render() {
    return (
      <Content>
        <div
          className="AutomationContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          {/* Automation Tab Start */}
          <div className="TabsHeadBox">
            <ul className="tabsBtns desktop" uk-switcher="connect: .multi_div">
              {/* <li id="packages">
                <a href="#">Subscription Plan</a>
              </li> */}
              <li id="tagtab">
                <a href="#">Office Hours</a>
              </li>
              {/* <li id="tagtemp"><a  href="#">Segments</a></li> */}
              <li id="integerationtemp">
                <a href="#">Integerations</a>
              </li>
              {/* <li id="webhooktemp"><a  href="#">Webhook</a></li> */}
              <li id="integerationtag">
                <a href="#">Tags</a>
              </li>
              <li id="integerationquick">
                <a href="#">Quick Replies</a>
              </li>
            </ul>

            <div class="mobTabsBtn">
              <p class="AnimFade uk-scrollspy-inview uk-animation-fade">
                Subscription Plan
              </p>
              <ul className="tabsBtns" uk-switcher="connect: .multi_div">
                {/* <li>
                  <a href="#">Subscription Plan</a>
                </li> */}
                <li>
                  <a href="#">Office Hours</a>
                </li>
                {/* <li><a href="#">Segments</a></li> */}
                <li>
                  <a href="#">Integerations</a>
                </li>
                {/* <li><a href="#">Webhook</a></li> */}
                <li id="integerationtag">
                  <a href="#">Tags</a>
                </li>
                <li id="integerationquick">
                  <a href="#">Quick Replies</a>
                </li>
              </ul>
            </div>

            <ul className="uk-switcher multi_div uk-margin-large" id="tabsBtns">
              {/* <li id="packageslistbutton">
                <Packages />
              </li> */}
              <li id="tagtablistbutton">
                <OfficeHours />
              </li>

              {/* <li id="tagtemplistbutton">
                              <PackagesRequest/>
                            </li> */}

              <li id="integerationlistbutton">
                <Integrations />
              </li>

              {/* <li id="webhooklistbutton">
                              <Webhook/>
                            </li> */}

              <li id="integerationtag">
                <Tag />
              </li>

              <li id="integerationquick">
                <QuickReplies />
              </li>
            </ul>
          </div>
          <div id="errorAlertmain"></div>
        </div>
      </Content>
    );
  }
}
export default Settings;
