import React from 'react';
import axios from 'axios';
class WaveChart extends React.Component {

    constructor() {
        super();
        this.state = {
            label: [],
            total_entries_recieved: [],
            total_customer_base: [],
            total_customer_reached: []
        }
    }

    componentDidMount() {
        this.callApi();
    }

    drawMap = () => {
        var classRef = this;
        // console.log(classRef.state.label, 'labelll')
        var ctx = document.getElementById('overview_chart');
        var myChart = new window.Chart(ctx, {
            type: 'line',
            data: {
                labels: classRef.state.label,
                datasets: [{
                    label: 'Total Entries Received',
                    data: classRef.state.total_entries_recieved,
                    backgroundColor: [
                        'rgba(212, 113, 177, .7)',
                        'rgba(212, 113, 177, .65)',
                        'rgba(212, 113, 177, .6)',
                        'rgba(212, 113, 177, .55)',
                        'rgba(212, 113, 177, .50)',
                        'rgba(212, 113, 177, .55)'
                    ],
                    borderColor: [
                        'rgba(212, 113, 177, 1)',
                        'rgba(212, 113, 177, 1)',
                        'rgba(212, 113, 177, 1)',
                        'rgba(212, 113, 177, 1)',
                        'rgba(212, 113, 177, 1)',
                        'rgba(212, 113, 177, 1)'
                    ],
                    borderWidth: 2
                }, {
                    label: 'Consumers Reached',
                    data: classRef.state.total_customer_base,
                    backgroundColor: [
                        'rgba(125, 209, 209, .7)',
                        'rgba(125, 209, 209, .65)',
                        'rgba(125, 209, 209, .6)',
                        'rgba(125, 209, 209, .55)',
                        'rgba(125, 209, 209, .50)',
                        'rgba(125, 209, 209, .55)'
                    ],
                    borderColor: [
                        'rgba(125, 209, 209, 1)',
                        'rgba(125, 209, 209, 1)',
                        'rgba(125, 209, 209, 1)',
                        'rgba(125, 209, 209, 1)',
                        'rgba(125, 209, 209, 1)',
                        'rgba(125, 209, 209, 1)'
                    ],
                    borderWidth: 2
                }, {
                    label: 'Total Consumer Base',
                    data: classRef.state.total_customer_reached,
                    backgroundColor: [
                        'rgba(118, 122, 193, .7)',
                        'rgba(118, 122, 193, .65)',
                        'rgba(118, 122, 193, .6)',
                        'rgba(118, 122, 193, .55)',
                        'rgba(118, 122, 193, .50)',
                        'rgba(118, 122, 193, .55)'
                    ],
                    borderColor: [
                        'rgba(118, 122, 193, 1)',
                        'rgba(118, 122, 193, 1)',
                        'rgba(118, 122, 193, 1)',
                        'rgba(118, 122, 193, 1)',
                        'rgba(118, 122, 193, 1)',
                        'rgba(118, 122, 193, 1)'
                    ],
                    borderWidth: 2
                }]
            },
            options: {
                borderWidth: 0,
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }


    callApi = () => {
        let classRef = this;

        classRef.setState({
                        label: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                        total_customer_reached: ['45', '35', '6', '46', '55', '60', '85'],
                        total_customer_base: ['45', '35', '6', '46', '55', '60', '85'],
                        total_entries_recieved: ['45', '35', '6', '46', '55', '60', '85']
                    })
        classRef.drawMap();

        // axios({
        //     method: 'POST',
        //     url: "http://dashboard.convexinteractive.com/proton/index.php/api/overview",
        // }).then(function (result) {

        //     if (result.data.status === true) {
        //         console.log(result.data);
        //         classRef.setState({
        //             label: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        //             total_customer_reached: ['45', '35', '6', '46', '55', '60', '85'],
        //             total_customer_base: ['45', '35', '6', '46', '55', '60', '85'],
        //             total_entries_recieved: ['45', '35', '6', '46', '55', '60', '85']
        //         })
        //         classRef.drawMap();
        //     }
        // })


    }

    render() {
        return (
            <div id='chartBox' className="uk-grid-medium uk-margin-top" uk-height-match=".uk-card" uk-grid="">
                <div className="uk-width-1-1 grid_a">
                    <div className="uk-card uk-card-default uk-card-hover uk-card-body card_space chart_box">
                        <h3>Overview</h3>
                        <canvas id="overview_chart" width="650" height="210"></canvas>
                        <div uk-tooltip="Click to fliter" id="tooltip"></div>
                    </div>
                </div>
            </div>
        );
    }

}
export default WaveChart;