import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../../App/Constant";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);


let dummyData = {

  labels: [],
  datasets: [
    {
      fill: true,
      label: 'Day wise Average',
      data:  [],
      borderColor: 'rgb(148, 208, 190)',
      backgroundColor: 'rgba(207, 245, 222)',
    },
  ],


}

export const BotDayMessages = (stateCompany,stateTo,stateFrom) => {

  const [graphData, setGraphData] = useState(dummyData);

  useEffect(() => {

    formValidation();

}, [stateCompany,stateTo,stateFrom]);


const formValidation = async () => {

  axios({
      method: 'POST',
      url: Constant.url+"admin-bot-day-wise-solved-chats",
      data: {company_id:stateCompany.stateCompany,stateTo:stateCompany.stateTo,stateFrom:stateCompany.stateFrom} 
  }).then(result =>{

    var stats = result.data.data;
    setGraphData({

    labels: stats.labels_bot,
    datasets: [
      {
        fill: true,
        label: 'Day wise Average',
        data:  stats.values_bot,
        borderColor: 'rgb(148, 208, 190)',
        backgroundColor: 'rgba(207, 245, 222)',
      },
      

    ],

    })

    
  }).catch(err => {

    // console.log(err)
          
  });


}


        const options = {
          responsive: true,
          plugins: {
          legend: {
          position: 'bottom',
          },
          title: {
          display: false,
          // text: 'Chart.js Line Chart',
          },
          },
          }
        
        return <Line options={options} data={graphData}/>;
      }
