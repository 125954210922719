import React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import style from "../../../Assets/css/style.css";
import {TextField,TextArea,Button,Content,Form,Checkbox} from '@adobe/react-spectrum';
import Constant from "../../../App/Constant";
import axios from 'axios';
import $, { cssNumber, data } from 'jquery';
UIkit.use(Icons);

class AccountRequest extends React.Component {

    constructor(){
        super();
        this.getPackage = this.getPackage.bind(this);
        this.state = {
            packageList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            uploadImage : "",
        }
    }

    componentDidMount() {
        this.getPackage();
    }

    getPackage = () => {
        let classRef = this;
        let company_id = localStorage.getItem('company_id');
        axios({
            method: 'POST',
            url: Constant.url + "packages",
            data: { company_id: company_id }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    packageList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                })
            }
        })
    }

    handlePackage = () => {
        let result = "";
        let index = 0;
        result = this.state.packageList.list.map((response, index) => (   
          <option value={response.id}>{response.title}</option>
        ));
        return result;
    }

    onFileChange = (event) => {
        let classRef = this;
        if(event.target.files[0] != undefined){
            classRef.setState({
                uploadImage : event.target.files[0]
            });
        }
    }

    requestClick = () =>{
        let classRef = this;
        let company_name = $("#company_name").val();
        let company_industry = $("#company_industry").val();
        let whatsapp_no = $("#whatsapp_no").val();
        let whatsapp_name = $("#whatsapp_name").val();
        let whatsapp_picture = $("#whatsapp_picture").val();
        let description = $("#description").val();
        let package_id = $("#package").val();
        let partnername = $("#partnername").val();
        let name = $("#name").val();
        let emailaddress = $("#emailaddress").val();
        let region = $("#region").val();
        let businessmanagerid = $("#businessmanagerid").val();
        let companyfacebookurl = $("#companyfacebookurl").val();
        let companywebsite = $("#companywebsite").val();
        let brandName = $("#brandName").val();
        let picture = classRef.state.uploadImage;
        $('#requestComplete').text('Processing')
        $('#requestComplete').prop('disabled', true);
        if(company_name != "" && company_industry != "" && whatsapp_no != "" && whatsapp_name != "" && whatsapp_picture != "" && description != "" && package_id != "" && partnername != "" && name != "" && emailaddress != "" && region != "" && businessmanagerid != "" && companyfacebookurl != "" && companywebsite != "" && brandName != ""){
            if(whatsapp_no.length == 10){
                const formData = new FormData();
                formData.append("company_name", company_name);
                formData.append("company_type", company_industry);
                formData.append("whatsapp_name", whatsapp_name);
                formData.append("whatsapp_number", "92"+whatsapp_no);
                formData.append("package_id", package_id);
                formData.append("partner_name", partnername);
                formData.append("your_name", name);
                formData.append("email_address", emailaddress);
                formData.append("region", region);
                formData.append("company_facebook_business_manager_id", businessmanagerid);
                formData.append("company_facebook_url", companyfacebookurl);
                formData.append("company_website", companywebsite);
                formData.append("brand_name", brandName);
                formData.append("display_picture", picture);
                formData.append("description", description);
                axios({
                    method: 'POST',
                    url: Constant.url + "account-request",
                    data: formData
                }).then(function (result) {
                    if (result.data.status === true) {
                        $("#requestForm")[0].reset();
                        $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Request send successfully.</p></div>');
                        setTimeout(function(){
                            UIkit.alert('#msg-dialog').close();
                            $('#requestComplete').text('Request Now');
                            $('#requestComplete').prop('disabled', false);
                        },1000);
                    }
                })
            } else {
                $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Number must be in 10 digits.</p></div>');
                setTimeout(function(){
                    UIkit.alert('#msg-dialog').close();
                    $('#requestComplete').text('Request Now');
                    $('#requestComplete').prop('disabled', false);
                },1000);
            }
        } else {
            $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Please Fill all Fields</p></div>');
            setTimeout(function(){
                UIkit.alert('#msg-dialog').close();
                $('#requestComplete').text('Request Now');
                $('#requestComplete').prop('disabled', false);
            },1000);
        }
    }

    render() {
        return (
            <Content>
                <section id="step3" className="login_page signup">
                    <div className="uk-container uk-container-expand">
                        <div className="signup_logo">
                        <Link to="#"><img src="/images/berrytalkslogodark.svg" alt="" /></Link>
                        </div>
                    </div>
                    <div className="mdl">
                        <div className="mdl_inner">
                        <div className="uk-container uk-container-expand uk-padding-remove">
                            <div className="login_page_inner ii">
                            <div className="login_side1">
                                <div className="login_side1_inner">
                                {/* <img className="login_from_logo" src="/images/logo.png" alt="" /> */}
                                <h1>Request New Account</h1>
                                <div className="social_signup">
                                    <Form
                                    maxWidth="size-3600"
                                    isQuiet
                                    aria-label="Quiet example"
                                    id="requestForm"
                                    >
                                    <div className="uk-grid uk-grid-collapse">
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="company_name"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Company Name"
                                                type="text"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields select_box ii">
                                                <label className=" uk-form-label uk-width-1-1 uk-display-block" >Company industry</label>
                                                <select className="uk-select uk-width-1-1 " id="company_industry">
                                                <option value={'IT'}>IT</option>
                                                <option value={'Digital Agency'}>Digital Agency</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="whatsapp_no"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Whatsapp Number"
                                                type="number"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="whatsapp_name"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Whatsapp Name"
                                                type="text"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <label class="spectrum-FieldLabel_d2db1f" for="whatsapp-image" style={{ marginTop : "13px"}}>Whatsapp Image <span  style={{ fontSize: "19px"}} >*</span></label>
                                                <input type="file" width="100%" id="whatsapp_picture" marginTop="size-200" maxWidth="100%"label="Picture" scale="medium" onChange={this.onFileChange}/>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="partnername"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Partner Name"
                                                type="text"
                                                placeholder="If applicable; name of the Partner managing the Company onto WhatsApp"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="name"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Your Name"
                                                type="text"
                                                placeholder="Who will be managing the WhatsApp onboarding for the end brand"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="emailaddress"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Email Address"
                                                type="text"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields select_box ii">
                                                <label className=" uk-form-label uk-width-1-1 uk-display-block" >Region</label>
                                                <select className="uk-select uk-width-1-1 " id="region">
                                                    <option value="Europe & the Middle East & Africa (EMEA)">Europe & the Middle East & Africa (EMEA)</option>
                                                    <option value="North Asia">North Asia</option>
                                                    <option value="South East Asia (SEA)">South East Asia (SEA)</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="North America">North America</option>
                                                    <option value="LATAM">LATAM</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <label class="spectrum-FieldLabel_d2db1f" for="whatsapp-image" style={{ marginTop : "13px"}}>Company Facebook Business Manager ID<span  style={{ fontSize: "19px"}} >*</span></label>
                                                <label class="spectrum-FieldLabel_d2db1f" for="whatsapp-image">This must be the Business Manager ID of the Brand that will be using WhatsApp, NOT a partner or agency. To locate your ID, please see screenshot below</label>
                                                <img src="images/businessmanager.png" />
                                                <TextField
                                                width="100%"
                                                id="businessmanagerid"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                type="text"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="companyfacebookurl"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Company Facebook URL"
                                                type="text"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="companywebsite"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Company Website"
                                                type="text"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="brandName"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Brand Name"
                                                type="text"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields select_box ii">
                                                <label className=" uk-form-label uk-width-1-1 uk-display-block" >Package</label>
                                                <select className="uk-select uk-width-1-1 " id="package">
                                                {(this.state.packageList.isLoaded) ? this.handlePackage() : ""}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-1">
                                            <div className="fields">
                                                <TextField
                                                width="100%"
                                                id="description"
                                                marginTop="size-200"
                                                maxWidth="100%"
                                                label="Description"
                                                type="text"
                                                scale="medium"
                                                isRequired necessityIndicator="icon"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div id="errorAlert"></div>
                                    <div id="successAlert"></div>
                                    <div className=" form_action_btn uk-grid-small uk-grid uk-grid-collapse ">
                                        <div className="uk-width-expand">
                                        {/* <p >  Existing user? <Link to="#"> Sign in</Link> now</p> */}
                                        </div>
                                        <div className="uk-width-auto">
                                            <div className="fields spStyle">
                                                <Button
                                                id="requestComplete"
                                                position="relative"
                                                marginTop="size-200"
                                                right="size-0"
                                                maxWidth="size-1400"
                                                variant="cta"
                                                onPress={this.requestClick}
                                                >
                                                Request Now
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    

                                    </Form>
                                </div>


                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className=" copyright uk-position-bottom  ">
                        <div className="uk-grid uk-grid-collapse uk-child-width-1-2">
                        <div>
                            <ul>
                            <li><Link to="#">Terms of Use</Link></li>
                            <li><Link to="#">Privacy Policy</Link></li>
                            </ul>
                        </div>
                        <div>
                            <p>© 2022 Convex Interactive. All rights reserved.</p>
                        </div>
                        </div>

                    </div>
                    </section>
            </Content>
        );
    }
}

export default AccountRequest;