import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { Picker, Item, Section } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import {
  CaretDown,
  Image,
  CloudUpload,
  Add,
  Edit,
  Document,
  DocumentImport,
  TrashCan,
  Information,
  OverflowMenuVertical,
  InProgress,
  CheckmarkOutline,
  ViewFilled,
  FaceSatisfied,
  ReplyAll,
  Send,
  NotSent,
  Close,
  Checkmark,
  NonCertified,
} from "@carbon/icons-react";
import Constant from "../../../App/Constant";
import axios from "axios";
import { DatePicker, TimeField } from "@adobe/react-spectrum";
import ReactPaginate from "react-paginate";
import $ from "jquery";

class Broadcasting extends React.Component {
  constructor() {
    super();
    this.getTemplate = this.getTemplate.bind(this);
    this.handleTemplate = this.handleTemplate.bind(this);
    this.handleSegment = this.handleSegment.bind(this);
    this.callApi = this.callApi.bind(this);
    this.callScheduledApi = this.callScheduledApi.bind(this);
    this.callHistoryApi = this.callHistoryApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.handleHistoryTable = this.handleHistoryTable.bind(this);
    this.getCategory = this.getCategory.bind(this);
    this.getLanguages = this.getLanguages.bind(this);
    this.importTempList = this.importTempList.bind(this);
    this.handleImportTemplate = this.handleImportTemplate.bind(this);
    this.callPackageApi = this.callPackageApi.bind(this);
    this.callTemplatesApi = this.callTemplatesApi.bind(this);
    this.onEditTemplateChange = this.onEditTemplateChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleBroadcastClick = this.handleBroadcastClick.bind(this);
    this.handleLogsClick = this.handleLogsClick.bind(this);
    this.handleScheduleClick = this.handleScheduleClick.bind(this);
    this.PendinghandlePageClick = this.PendinghandlePageClick.bind(this);
    this.callPendingTemplatesApi = this.callPendingTemplatesApi.bind(this);

    this.callLogsApi = this.callLogsApi.bind(this);
    this.ApihandlePageClick = this.ApihandlePageClick.bind(this);

    this.state = {
      uploadfiles: "",
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      historyDataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      templatesDataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      pendingtemplatesDataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      SceduledDataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      templateList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      apiLogsDataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },

      segmentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      categoryList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      languagesList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      importTempList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      headerContent: "Header",
      bodyContent: "body",
      footerContent: "footer",
      buttonOneContent: "",
      buttonTwoContent: "",
      buttonThreeContent: "",
      buttonFourContent: "",
      stats: {
        sent: 0,
        delivered: 0,
        read: 0,
        replied: 0,
        sending: 0,
      },
      previewHeader: "",
      previewFooter: "",
      previewBody: "",
      previewButtonOne: "",
      previewButtonTwo: "",
      previewButtonThree: "",
      previewImage: "",
      previewVideo: "",
      broadcastEditID: "",
      checkAll: false,
      default: "no",
      is_expired: 0,
      rowsCountTemplate: 0,
      rowsCountBroadcast: 0,
      rowsCountLogs: 0,
      rowsCountSchedule: 0,
      tempDeleteId: 0,
      tempAppId: 0,
      rowsCountPendingTemplate: 0,
      rowsCountapiLogsDataList: 0,
      messagesList: [],
      noofParams: 0,
      typeExist: "",
      media: "",
      templateCustomParameters: [],
      param1: null,
      filteredDataForTemplate: [],
      // messageArray: [],
      // paramArray: [],

      messageArrayForQuickMessage: [],
      paramArrayForQuickMessage: [],
      paramErrorArrayForQuickMessage: [],
      previewHeaderForQuickMessage: "",
      previewFooterForQuickMessage: "",
      previewBodyForQuickMessage: "",
      filteredDataForQuickMessageTemplate: [],
      templateCustomParametersForQuickMessage: [],

      templateCustomParameters: [],
      filteredDataForTemplate: [],
      paramErrorArray: [],
      messageArray: [],
      paramArray: [],
    };
  }

  componentDidMount() {
    var classRef = this;
    var type = "all";
    var pageCount = 1;

    this.getTemplate();
    this.getSegment();
    this.callApi(1);
    this.getCategory();
    this.getLanguages();
    this.callScheduledApi();
    this.callHistoryApi(pageCount, type);
    this.importTempList();
    $("#logs").removeClass("uk-active");
    $("#logsData").removeClass("uk-active");
    this.callTemplatesApi(1);
    this.callPendingTemplatesApi(1);
    this.callLogsApi(1);
    // setInterval(function(){
    //     if(classRef.state.default == "no"){
    //         classRef.callPackageApi();
    //     }
    //   },3000)

    if (localStorage.getItem("loginStatus") == 1) {
      let screens = localStorage.getItem("rolesceens").split(",");
      var checking = screens.includes("5");
      if (checking == true) {
      } else {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  }

  confirmReinitaite = () => {
    let classRef = this;
    let template_id = $("#suggested_msgs_pop")
      .find("option:selected")
      .attr("id");
    template_id = template_id.split("_")[3];
    let phone_no = $("#phone_no").val();
    let fileName = $("#fileName").val();

    var paramError = this.state.paramErrorArrayForQuickMessage;
    const containEmptyString = (element) => element === "";
    let customTemplateParamErrros = paramError.filter(containEmptyString);
    let template_message = this.state.messageArrayForQuickMessage.join(" ");
    let media = this.state.media;
    let company_id = localStorage.getItem("company_id");
    let company_msisdn = localStorage.getItem("company_msisdn");
    if (phone_no == "") {
      $("#errorAlertQuick").append(
        '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please enter number first!</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 2000);
      return;
    }
    if (template_id == undefined) {
      $("#errorAlertQuick").append(
        '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please select message first!</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 2000);
      return;
    }
    if (customTemplateParamErrros.length > 0) {
      $("#errorAlertQuick").append(
        '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please fill all params</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 2000);
      return;
    }
    if (this.state.typeExist !== "") {
      if (media === "") {
        $("#errorAlertQuick").append(
          '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Media is required</p></div>'
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 2000);
        return;
      }
      if (fileName === "") {
        $("#errorAlertQuick").append(
          '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>FileName is Required</p></div>'
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 2000);
        return;
      }
    }
    let reInitiateParams = new FormData();
    reInitiateParams.append("business_number", company_msisdn);
    reInitiateParams.append("template_message", template_message);
    reInitiateParams.append("company_id", company_id);
    reInitiateParams.append("template_id", template_id);
    reInitiateParams.append("msisdn", phone_no);
    if (this.state.typeExist != "") {
      reInitiateParams.append("file", media);
      reInitiateParams.append("filename", fileName);
    }
    if (paramError.length > 0) {
      paramError.map((val, index) => {
        reInitiateParams.append(`param${index + 1}`, val);
      });
    }
    axios({
      method: "POST",
      url: Constant.url + "quick-message-v2",
      data: reInitiateParams,
    }).then(function (result) {
      $("#AddTeamBtn").text("Processing");
      $("#AddTeamBtn").prop("disabled", true);
      if (result.data.status === true) {
        $("#successAlertQuick").append(
          '<div id="msg-dialog" class="uk-alert-success uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Message sent successfully.</p></div>'
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#reinitiate").removeClass("uk-flex uk-open");
          $("#AddTeamBtn").text("Send");
          $("#AddTeamBtn").prop("disabled", false);
          $("#phone_no").val("");
          $("#fileName").val("");
          classRef.setState({
            messageArrayForQuickMessage: [],
            paramArrayForQuickMessage: [],
            paramErrorArrayForQuickMessage: [],
            previewHeaderForQuickMessage: "",
            previewFooterForQuickMessage: "",
            previewBodyForQuickMessage: "",
            filteredDataForQuickMessageTemplate: [],
            templateCustomParametersForQuickMessage: [],
            typeExist: "",
          });
        }, 2000);
       
      } else {
        $("#errorAlertQuick").append(
          '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>An error occurred!</p></div>'
        );
        $("#AddTeamBtn").text("Send");
        $("#AddTeamBtn").prop("disabled", false);
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 2000);
      }
    });
  };

  showReinitiate = () => {
    let classRef = this;

    let company_id = localStorage.getItem("company_id");
    let messageParams = new FormData();
    messageParams.append("company_id", company_id);

    axios({
      method: "POST",
      url: Constant.url + "templates",
      data: messageParams,
    }).then(function (response) {
      if (response.data.status === true) {
        classRef.setState({
          messagesList: response.data.data,
        });
        $("#suggested_msgs_pop").empty();
        $("#suggested_msgs_pop").append(
          "<option id='0'>Select Message</option>"
        );
        for (let i = 0; i < response.data.data.length; i++) {
          // $("#suggested_msgs_pop ul").append("<li id='suggest_msg_"+response.data.data[i].id+"' onclick='sendMessage("+response.data.data[i].id+")'><button id='suggest_msg_"+response.data.data[i].id+"'>"+response.data.data[i].name+"</button></li>");
          $("#suggested_msgs_pop").append(
            "<option id='suggest_msg_pop_" +
              response.data.data[i].id +
              "'>" +
              response.data.data[i].name +
              "</option>"
          );
        }
      }
    });
    $("#reinitiate").addClass("uk-flex uk-open");
  };

  callPackageApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "package-stats",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        var package_stats = result.data.data;
        classRef.setState({
          is_expired: package_stats.is_expired,
        });
      }
    });
  };

  callApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "broadcasts",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data.list,
          },
          stats: {
            sent: result.data.data.stats.sent,
            delivered: result.data.data.stats.delivered,
            read: result.data.data.stats.read,
            replied: result.data.data.stats.replied,
            sending: result.data.data.stats.sending,
            Failed: result.data.data.stats.Failed,
          },

          rowsCountBroadcast: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountBroadcast: result.data.rowscount,
        });
      }
    });
  };

  BroadcastSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#BroadcastSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "broadcasts",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data.list,
          },
          stats: {
            sent: result.data.data.stats.sent,
            delivered: result.data.data.stats.delivered,
            read: result.data.data.stats.read,
            replied: result.data.data.stats.replied,
            sending: result.data.data.stats.sending,
            Failed: result.data.data.stats.Failed,
          },
          rowsCountBroadcast: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountBroadcast: result.data.rowscount,
        });
      }
    });
  };

  callScheduledApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "broadcasts",
      data: { company_id: localStorage.getItem("company_id"), status: 1 },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SceduledDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data.list,
          },
          rowsCountSchedule: result.data.rowscount,
        });
      } else {
        classRef.setState({
          SceduledDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountSchedule: result.data.rowscount,
        });
      }
    });
  };

  ScheduleSorting = () => {
    let classRef = this;
    let sortingValue = $("#ScheduleSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "broadcasts",
      data: {
        company_id: localStorage.getItem("company_id"),
        status: 1,
        page: 1,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SceduledDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data.list,
          },
          rowsCountSchedule: result.data.rowscount,
        });
      } else {
        classRef.setState({
          SceduledDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountSchedule: result.data.rowscount,
        });
      }
    });
  };
  TemplateSorting = () => {
    let classRef = this;
    let sortingValue = $("#TemplateSortingBtn").val();
    axios({
      method: "POST",
      url: Constant.url + "templates",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: 1,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          templatesDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTemplate: result.data.rowscount,
        });
      } else {
        classRef.setState({
          templatesDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTemplate: result.data.rowscount,
        });
      }
    });
  };

  PendingTemplateSorting = () => {
    let classRef = this;
    let sortingValue = $("#PendingTemplateSortingBtn").val();
    axios({
      method: "POST",
      url: Constant.url + "pending-templates",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: 1,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          pendingtemplatesDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountPendingTemplate: result.data.rowscount,
        });
      } else {
        classRef.setState({
          pendingtemplatesDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountPendingTemplate: result.data.rowscount,
        });
      }
    });
  };

  callHistoryApi = (pageCount, type) => {
    let classRef = this;
    var page = pageCount;
    var type = type;

    axios({
      method: "POST",
      url: Constant.url + "broadcast-history",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        type: type,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          historyDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountLogs: result.data.rowscount,
        });
      } else {
        classRef.setState({
          historyDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountLogs: result.data.rowscount,
        });
      }
    });
  };

  LogsSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#LogsSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "broadcast-history",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          historyDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountLogs: result.data.rowscount,
        });
      } else {
        classRef.setState({
          historyDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountLogs: result.data.rowscount,
        });
      }
    });
  };
  callTemplatesApi = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#TemplateSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "templates",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          templatesDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTemplate: result.data.rowscount,
        });
      } else {
        classRef.setState({
          templatesDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTemplate: result.data.rowscount,
        });
      }
    });
  };

  callPendingTemplatesApi = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#PendingTemplateSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "pending-templates",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          pendingtemplatesDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountPendingTemplate: result.data.rowscount,
        });
      } else {
        classRef.setState({
          pendingtemplatesDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountPendingTemplate: result.data.rowscount,
        });
      }
    });
  };

  importTempList = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "popular-templates",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          importTempList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          importTempList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  importTemplate = (id) => {
    $("#import_" + id).html("Processing...");
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "import-template",
      data: { company_id: localStorage.getItem("company_id"), template_id: id },
    }).then(function (result) {
      if (result.data.status === true) {
        $("#main_div_" + id).hide();
      }
    });
  };

  handleButtonAction = () => {
    var selectedAction = $("#button_action").children("option:selected").val();
    if (selectedAction == "call_to_action") {
      $("#call_to_action").show();
      $("#quick_reply").hide();
    } else {
      $("#quick_reply").show();
      $("#call_to_action").hide();
    }
  };

  handleImportTemplate = () => {
    let classRef = this;
    let result = "";
    let index = 0;
    result = this.state.importTempList.list.map((response, index) => (
      <div
        className="uk-width-1-4@m uk-width-1-1@s"
        id={"main_div_" + response.id}
      >
        <div className="customCard">
          <div className="uk-card uk-card-default">
            <div className="uk-card-header">
              <div className="uk-flex-middle">
                <div className="uk-width-auto">
                  <img className="" src="/images/b1.svg" />
                  {/* <img className="" src={response.image} /> */}
                </div>
                <div className="uk-width-expand">
                  <h4 className="">
                    <b>{response.name}</b>
                  </h4>
                  <p className="uk-text-meta uk-margin-remove-top">
                    {response.body}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="uk-card-body">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div> */}
            <div className="uk-card-footer">
              <a
                className="uk-button uk-button-text"
                id={"import_" + response.id}
                onClick={(e) => this.importTemplate(response.id)}
              >
                Import
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
    return result;
  };

  handleTable = () => {
    let classRef = this;
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr id={"broadcast_" + response.id}>
        <td>
          <label>
            <input className="uk-checkbox autoSelect" type="checkbox" />
          </label>
          {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
        </td>
        <td>
          <span>
            <Link to={"/broadcasting-detail/" + response.id}>
              {response.name}
            </Link>
          </span>
        </td>
        <td>{response.success_rate}</td>
        <td>{response.read}</td>
        <td>{response.replied}</td>
        <td>{response.failed}</td>
        <td>
          <button className="ctmBtn infoBtn">{response.status}</button>
        </td>
        <td>{response.scheduled}</td>
        <td>
          <a
            className="iconcircleBtn"
            uk-tooltip="title: Edit"
            onClick={(e) => classRef.EditBroadcastModal(response.id)}
          >
            <Edit />
          </a>
        </td>
        <td>
          <a
            className="iconcircleBtn danger"
            uk-tooltip="title: Delete"
            onClick={(e) => classRef.deleteBroadcast(response.id)}
          >
            <TrashCan />
          </a>
        </td>
      </tr>
    ));
    return result;
  };

  handleSchduledTable = () => {
    let result = "";
    let index = 0;
    let classRef = this;
    result = this.state.SceduledDataList.list.map((response, index) => (
      <tr>
        <td>
          <label>
            <input className="uk-checkbox autoSelectSchedule" type="checkbox" />
          </label>
          {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
        </td>
        <td>
          <span>{response.name}</span>
        </td>
        <td>
          <button className="ctmBtn infoBtn">{response.status}</button>
        </td>
        <td>{response.scheduled}</td>
        <td>
          <a
            className="iconcircleBtn"
            uk-tooltip="title: Edit"
            onClick={(e) => classRef.EditBroadcastModal(response.id)}
          >
            <Edit />
          </a>
        </td>
        <td>
          <a
            className="iconcircleBtn danger"
            uk-tooltip="title: Delete"
            onClick={(e) => classRef.deleteBroadcast(response.id)}
          >
            <TrashCan />
          </a>
        </td>
      </tr>
    ));
    return result;
  };

  handleHistoryTable = () => {
    let result = "";
    let index = 0;
    // console.log(this.state.historyDataList,'historylogs')
    result = this.state.historyDataList.list.map((response, index) => (
      <tr>
        {/* <td> */}
        {/* <label><input className="uk-checkbox" type="checkbox" /></label> */}
        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
        {/* </td> */}
        <td>{response.contact_number}</td>
        <td>{response.msg}</td>
        <td>{response.status}</td>
        <td>{response.created_date}</td>
      </tr>
    ));
    return result;
  };

  showBroadcastingPopup = (id) => {
    // console.log(id, "umair");
    let classRef = this;
    $("#AddBroadcast").click();
    $("#template").val(id);
    classRef.onTemplateChange(id);
  };

  // showPendingBroadcastingPopup = (id)  =>{

  //     console.log(id,'hhhhhhADII')
  //     let classRef = this;
  //     $("#PendingAddBroadcast").click();
  //     $("#Pendingtemplate").val(id);
  //     classRef.onPendingTemplateChange();
  // }

  showTemplateDelete = (id) => {
    $("#TemplateDeleteDrop").removeClass("uk-open");
    let classRef = this;
    classRef.setState({ tempDeleteId: id });
    $("#TemplateDelPop").addClass("uk-flex uk-open");
  };

  showTemplateApproved = (id) => {
    $("#TemplateDeleteDrop").removeClass("uk-open");
    let classRef = this;
    classRef.setState({ tempAppId: id });
    $("#TemplateAppPop").addClass("uk-flex uk-open");
  };

  DeleteTemplate = () => {
    $("#TemplateDelete").text("Processing");
    $("#TemplateDelete").prop("disabled", true);
    let classRef = this;
    let tempId = classRef.state.tempDeleteId;
    axios({
      method: "POST",
      url: Constant.url + "delete-bulk-template",
      data: {
        company_id: localStorage.getItem("company_id"),
        template_ids: tempId,
      },
    }).then(function (response) {
      if (response.data.code == 200) {
        $("#TemplateDelete").text("Save");
        $("#TemplateDelete").prop("disabled", false);
        $("#successAlertTemplate2").append(
          '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            response.data.message +
            "</p></div>"
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#TemplateDelPop").removeClass("uk-flex uk-open");
        }, 1000);
        return;
      } else {
        $("#TemplateDelete").text("Save");
        $("#TemplateDelete").prop("disabled", false);
        $("#errorAlertTemplate2").append(
          '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            response.data.message +
            "</p></div>"
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#TemplateDelPop").removeClass("uk-flex uk-open");
        }, 1000);
        return;
      }
    });
  };

  ApprovedTemplate = () => {
    $("#TemplateDelete").text("Processing");
    $("#TemplateDelete").prop("disabled", true);
    let classRef = this;
    let tempId = classRef.state.tempAppId;
    axios({
      method: "POST",
      url: Constant.url + "approve-template",
      data: {
        company_id: localStorage.getItem("company_id"),
        template_id: tempId,
      },
    }).then(function (response) {
      if (response.data.code == 200) {
        $("#TemplateDelete").text("Save");
        $("#TemplateDelete").prop("disabled", false);
        $("#successAlertTemplate2").append(
          '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            response.data.message +
            "</p></div>"
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#TemplateAppPop").removeClass("uk-flex uk-open");
        }, 1000);
        window.location.reload();
        return;
      } else {
        $("#TemplateDelete").text("Save");
        $("#TemplateDelete").prop("disabled", false);
        $("#errorAlertTemplate2").append(
          '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            response.data.message +
            "</p></div>"
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#TemplateAppPop").removeClass("uk-flex uk-open");
        }, 1000);
        window.location.reload();
        return;
      }
    });
  };

  handleTemplatesTable = () => {
    let classRef = this;
    let result = "";
    let index = 0;
    // console.log(this.state.templatesDataList,'historylogs')
    result = this.state.templatesDataList.list.map((response, index) => (
      <tr>
        <td>{response.name}</td>
        <td>
          <a
            href="javascript:;"
            onClick={(e) => this.showBroadcastingPopup(response.id)}
          >
            Click to Preview
          </a>
        </td>
        <td>{response.created_date}</td>
        <td>
          <a
            className="iconcircleBtn danger"
            uk-tooltip="title: Delete"
            onClick={(e) => classRef.showTemplateDelete(response.id)}
          >
            <TrashCan />
          </a>
        </td>
      </tr>
    ));
    return result;
  };

  handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    this.callTemplatesApi(currentPage);
  };

  PendinghandleTemplatesTable = () => {
    let classRef = this;
    let result = "";
    let index = 0;
    // console.log(this.state.templatesDataList,'historylogs')
    result = this.state.pendingtemplatesDataList.list.map((response, index) => (
      <tr>
        <td>{response.name}</td>
        <td>
          <a
            href="javascript:;"
            onClick={(e) => this.showBroadcastingPopup(response.id)}
          >
            Click to Preview
          </a>
        </td>
        {/* <td><button className="ctmBtn infoBtn">{response.status}</button></td>  */}
        <td>
          <button
            className={
              response.status == "1" ? "ctmBtn succesBtn" : "ctmBtn dangreBtn"
            }
          >
            {response.status == "1" ? "Active" : "Pending"}
          </button>
        </td>
        <td>{response.created_date}</td>
        <td>
          <a
            className="iconcircleBtn success"
            uk-tooltip="title: Approved"
            onClick={(e) => classRef.showTemplateApproved(response.id)}
          >
            <Checkmark />
          </a>
        </td>
        <td>
          <a
            className="iconcircleBtn danger"
            uk-tooltip="title: Delete"
            onClick={(e) => classRef.showTemplateDelete(response.id)}
          >
            <TrashCan />
          </a>
        </td>
      </tr>
    ));
    return result;
  };

  PendinghandlePageClick = (data) => {
    let currentPage = data.selected + 1;
    this.callPendingTemplatesApi(currentPage);
  };

  handleBroadcastClick = (data) => {
    let currentPage = data.selected + 1;
    this.BroadcastSorting(currentPage);
  };

  handleLogsClick = (data) => {
    let currentPage = data.selected + 1;
    this.LogsSorting(currentPage);
  };

  handleScheduleClick = (data) => {
    let currentPage = data.selected + 1;
    this.ScheduleSorting(currentPage);
  };

  getTemplate = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "templates",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          templateList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          previewHeader: result.data.data[0].header,
          previewFooter: result.data.data[0].footer,
          previewBody: result.data.data[0].body,
          previewButtonOne: result.data.data[0].button_1,
          previewButtonTwo: result.data.data[0].button_2,
          previewButtonThree: result.data.data[0].button_3,
        });
      }
    });
  };

  getSegment = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          segmentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  uploadFile = () => {
    $("#uploadButton").text("Processing");
    $("#uploadButton").prop("disabled", true);
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let segment_name = $("#segment_name").val();
    let importParams = new FormData();
    importParams.append("company_id", company_id);
    importParams.append("segment_name", segment_name);
    importParams.append("file", classRef.state.uploadfiles);
    // console.log("file", importParams);
    if (segment_name != "") {
      if (classRef.state.uploadfiles != "") {
        axios({
          method: "POST",
          url: Constant.url + "import-contacts",
          data: importParams,
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.getSegment();
            $("#ImportcontactModal").removeClass("uk-flex uk-open");
          }
        });
      } else {
        $("#errorAlertSegment").append(
          '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please upload file.</p></div>'
        );
        $("#uploadButton").text("Continue");
        $("#uploadButton").prop("disabled", false);
        setTimeout(function () {
          UIkit.alert("#msg-dialog1").close();
        }, 1000);
      }
    } else {
      $("#errorAlertSegment").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please Enter Segment Name.</p></div>'
      );
      $("#uploadButton").text("Continue");
      $("#uploadButton").prop("disabled", false);
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  onFileUpload = (event) => {
    let classRef = this;
    if (event.target.files[0] != undefined) {
      $("#uploadedFile").empty();
      $("#uploadedFile").append(
        "Uploaded File : " + event.target.files[0].name
      );
      classRef.setState({ uploadfiles: event.target.files[0] });
    }
  };

  handleSegment = () => {
    let result = "";
    let index = 0;
    result = this.state.segmentList.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  handleTemplate = () => {
    let result = "";
    let index = 0;
    result = this.state.templateList.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  getCategory = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "categories",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          categoryList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCategory = () => {
    let result = "";
    let index = 0;
    result = this.state.categoryList.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  getLanguages = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "languages",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          languagesList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleParamChange = (e, index) => {
    let classRef = this;
    let paramsErrorArray = [...this.state.paramErrorArray];
    if (e.target.value === "") {
      paramsErrorArray[index] = "";
    } else {
      paramsErrorArray[index] = e.target.value;
    }

    classRef.setState({ paramErrorArray: paramsErrorArray });

    let paramArray = [...this.state.paramArray];
    paramArray[index][e.target.name] = e.target.value;
    paramArray[index]["value"] = e.target.value;
    if (paramArray[index][e.target.name] == "") {
      paramArray[index]["parmamVal"] = false;
    } else {
      paramArray[index]["parmamVal"] = true;
    }

    this.setState({ paramArray });

    let tempArray = [...this.state.templateCustomParameters];
    tempArray[index] = e.target.value;
    classRef.setState({ templateCustomParameters: tempArray });

    const filteredData = this.state.filteredDataForTemplate;
    var finalArray = [];
    for (var a = 0; a < filteredData[0].message.length; a++) {
      if (filteredData[0].no_params == 0) {
        finalArray.push(filteredData[0].message[a]);
      } else {
        if (a < filteredData[0].no_params) {
          finalArray.push(filteredData[0].message[a] + tempArray[a]);
        } else {
          finalArray.push(filteredData[0].message[a]);
        }
      }
    }

    classRef.setState({ messageArray: finalArray });
  };

  handleParamChangeForQuickMessage = (e, index) => {
    let classRef = this;

    //for error validation params
    let paramsErrorArray = [...this.state.paramErrorArrayForQuickMessage];
    if (e.target.value === "") {
      paramsErrorArray[index] = "";
    } else {
      paramsErrorArray[index] = e.target.value;
    }
    classRef.setState({ paramErrorArrayForQuickMessage: paramsErrorArray });

    let paramArrayForQuickMessage = [...this.state.paramArrayForQuickMessage];
    paramArrayForQuickMessage[index][e.target.name] = e.target.value;
    paramArrayForQuickMessage[index]["value"] = e.target.value;
    if (paramArrayForQuickMessage[index][e.target.name] == "") {
      paramArrayForQuickMessage[index]["parmamVal"] = false;
    } else {
      paramArrayForQuickMessage[index]["parmamVal"] = true;
    }

    this.setState({ paramArrayForQuickMessage });

    let tempArray = [...this.state.templateCustomParametersForQuickMessage];
    tempArray[index] = e.target.value;
    classRef.setState({ templateCustomParametersForQuickMessage: tempArray });

    const filteredData = this.state.filteredDataForQuickMessageTemplate;
    var finalArray = [];
    for (var a = 0; a < filteredData[0].message.length; a++) {
      if (filteredData[0].no_params == 0) {
        finalArray.push(filteredData[0].message[a]);
      } else {
        if (a < filteredData[0].no_params) {
          finalArray.push(filteredData[0].message[a] + tempArray[a]);
        } else {
          finalArray.push(filteredData[0].message[a]);
        }
      }
    }
    classRef.setState({ messageArrayForQuickMessage: finalArray });
  };
  onMessageSelectionForQuickMessage = (event) => {
    let classRef = this;
    let selectedmessage = event.target.value;
    const filteredData = this.state.templateList.list.filter((val) => {
      return val.name == selectedmessage;
    });

    const no_of_param = filteredData[0].no_params;

    //for mdeia exist
    var typeExist = filteredData[0].type;
    classRef.setState({ noofParams: no_of_param });
    if (typeExist === "media") {
      classRef.setState({ typeExist: typeExist });
    } else {
      classRef.setState({ typeExist: "" });
    }

    if (filteredData.length > 0) {
      this.setState({
        previewHeaderForQuickMessage: filteredData[0].header,
        previewFooterForQuickMessage: filteredData[0].footer,
        previewBodyForQuickMessage: filteredData[0].body,
      });
    }

    classRef.setState({ filteredDataForQuickMessageTemplate: filteredData });

    // for error validation
    var paramError = [];
    for (var a = 0; a < filteredData[0].no_params; a++) {
      paramError.push("");
    }
    classRef.setState({
      paramErrorArrayForQuickMessage: paramError,
    });

    var paramArray = [];
    for (var a = 0; a < filteredData[0].no_params; a++) {
      paramArray.push("{{" + (a + 1) + "}}");
    }
    this.setState({ templateCustomParametersForQuickMessage: paramArray });
    var finalArray = [];
    for (var a = 0; a < filteredData[0].message.length; a++) {
      if (filteredData[0].no_params == 0) {
        finalArray.push(filteredData[0].message[a]);
      } else {
        if (a < filteredData[0].no_params) {
          finalArray.push(
            filteredData[0].message[a] +
              // +`${this.state.templateCustomParametersForQuickMessage[a]}`)
              "{{" +
              `${a + 1}` +
              "}}"
          );
        } else {
          finalArray.push(filteredData[0].message[a]);
        }
      }
    }

    classRef.setState({ messageArrayForQuickMessage: finalArray });
    var paramValues = [];
    if (no_of_param > 0) {
      for (let i = 0; i < filteredData[0].no_params; i++) {
        paramValues.push({
          parmamVal: false,
          value: "",
        });
      }
      classRef.setState({ paramArrayForQuickMessage: paramValues });
    } else {
      classRef.setState({ paramArrayForQuickMessage: [] });
    }
    classRef.setState({ noofParamsForNewBroadcast: no_of_param });
  };

  handleFile = (event) => {
    if (event.target.files[0] != undefined) {
      this.setState({ media: event.target.files[0] });
    }
  };
  handleLanguages = () => {
    let result = "";
    let index = 0;
    result = this.state.languagesList.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  deleteBroadcast = (id) => {
    $("#broadcast_" + id).hide();
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "edit-broadcast",
      data: {
        company_id: localStorage.getItem("company_id"),
        broadcast_id: id,
        is_deleted: 1,
      },
    }).then(function (result) {
      if (result.data.status === true) {
      }
    });
  };

  EditBroadcastModal = (id) => {
    // console.log(id, "EditBroadcastModal");
    $("#EditBroadcastModal").addClass("uk-flex uk-open");
    this.broadCastByID(id);
  };

  CloseEditBroadcastModal = () => {
    $("#EditBroadcastModal").removeClass("uk-flex uk-open");
  };

  CreateTemplateModal = () => {
    $("#CreateTemplateModal").addClass("uk-flex uk-open");
  };

  CloseTemplateModal = () => {
    $("#CreateTemplateModal").removeClass("uk-flex uk-open");
  };

  hideTemplateDeletePop = () => {
    $("#TemplateDelPop").removeClass("uk-flex uk-open");
  };

  hideTemplateAppPop = () => {
    $("#TemplateAppPop").removeClass("uk-flex uk-open");
  };

  AddBroadcastModal2 = () => {
    var teamName = $("#teamName").val();
    var template_id = $("#template").val();
    var start_date = $("#broadcast_date").val();
    var start_time = $("#broadcast_time").val();

    var paramError = this.state.paramErrorArray;

    const containEmptyString = (element) => element === "";
    let customTemplateParamErrros = paramError.filter(containEmptyString);

    // console.log("customTemplateParamErrros", customTemplateParamErrros);

    // console.log("template_id", template_id);
    if (teamName == "") {
      $("#logerror").show();
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Title is required</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
      return;
    }
    if (template_id == null) {
      $("#logerror").show();
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Template is required</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
      return;
    }
    if (customTemplateParamErrros.length > 0) {
      $("#logerror").show();
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>All Params are Required</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
      return;
    }
    if (start_date == "") {
      $("#logerror").show();
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Date is required</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
      return;
    }
    if (start_time == "") {
      $("#logerror").show();
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Time is required</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
      return;
    }

    if (
      teamName !== "" &&
      template_id !== null &&
      start_date !== "" &&
      start_time !== "" &&
      customTemplateParamErrros.length === 0
    ) {
      $("#AddBroadcastModal2").addClass("uk-flex uk-open");
      $("#AddBroadcastModal").removeClass("uk-flex uk-open");
    }

    // $("#AddBroadcastModal2").addClass('uk-flex uk-open');
    // $("#AddBroadcastModal").removeClass('uk-flex uk-open');
  };

  CloseBroadcastModal2 = () => {
    let classRef = this
    $("#AddBroadcastModal2").removeClass("uk-flex uk-open");
    $("#AddBroadcastModal").removeClass("uk-flex uk-open");

    //  $("#teamName").val('');
    //  $("#template").val('');
    //  $("#segment").val('');
    // $("#broadcast_date").val('');
    //  $("#broadcast_time").val('');
    // classRef.setState({
    //   templateCustomParameters: [],
    //   filteredDataForTemplate: [],
    //   paramErrorArray: [],
    //   messageArray: [],
    //   paramArray: [],
    // })
  };

  AddBroadcastModal = () => {
    $("#template").val($("#template option:first").val());
    if (this.state.is_expired == "1") {
      $("#errorAlertcheck").append(
        '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Your Subscription has been expired.Please resubscribe your package</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else {
      $("#AddBroadcastModal").addClass("uk-flex uk-open");
    }
  };

  CloseBroadcastModal2 = () => {
    $("#AddBroadcastModal").removeClass("uk-flex uk-open");
  };

  AddImportcontactModal = () => {
    $("#ImportcontactModal").addClass("uk-flex uk-open");
  };

  hideImportcontactModal = () => {
    $("#ImportcontactModal").removeClass("uk-flex uk-open");
  };

  CloseImportcontactModal = () => {
    $("#ImportcontactModal").removeClass("uk-flex uk-open");
  };

  AddImportcontactModalUpload = () => {
    $("#ImportcontactModalUpload").addClass("uk-flex uk-open");
  };

  CloseImportcontactModalUpload = () => {
    $("#ImportcontactModalUpload").removeClass("uk-flex uk-open");
  };

  editBroadcast = () => {
    $(".AddTeamBtn").text("Processing");
    $(".AddTeamBtn").prop("disabled", true);

    let classRef = this;
    var data = {};
    data = {
      name: $("#teamNameEdit").val(),
      template_id: $("#templateEdit").val(),
      start_date: $("#broadcast_date_edit").val(),
      start_time: $("#broadcast_date_time").val(),
      is_deleted: 0,
      broadcast_id: classRef.state.broadcastEditID,
      company_id: localStorage.getItem("company_id"),
    };
    axios({
      method: "POST",
      url: Constant.url + "edit-broadcast",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlertEdit").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.reload();
        }

        $("#errorAlertsEdit").append(
          '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            response.data.data +
            "</p></div>"
        );
        $(".AddTeamBtn").text("Next");
        $(".AddTeamBtn").prop("disabled", false);
        //   setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
        //   return;
      })
      .then(function (response) {});
  };

  createBroacast = () => {
    $(".AddTeamBtn").text("Processing");
    $(".AddTeamBtn").prop("disabled", true);

    let classRef = this;
    var data = {};
    data = {
      name: $("#teamName").val(),
      template_id: $("#template").val(),
      segment_id: $("#segment").val(),
      company_id: localStorage.getItem("company_id"),
      start_date: $("#broadcast_date").val(),
      start_time: $("#broadcast_time").val(),
      template_message: this.state.messageArrayForQuickMessage.join(" "),
    };
    axios({
      method: "POST",
      url: Constant.url + "add-broadcast",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          setTimeout(()=>{
          window.location.href = "/broadcasting";
          },1000)
        }else{

          $("#errorAlerts").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $(".AddTeamBtn").text("Next");
          $(".AddTeamBtn").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;

        }

      })
      .then(function (response) {});
  };

  createTemplate = () => {
    let classRef = this;
    var data = {};
    $("#saveTemplate").text("Processing");
    $("#saveTemplate").prop("disabled", true);
    data = {
      button_action: $("#button_action").val(),
      button_1: $("#button_1").val(),
      button_2: $("#button_2").val(),
      button_3: $("#button_3").val(),
      button_web_phone: $("#button_web_phone").val(),
      btn_text: $("#btn_text").val(),
      link_number: $("#link_number").val(),
      tempname: $("#tempname").val(),
      category: $("#category").val(),
      language: $("#language").val(),
      header_type: $("#header_type").val(),
      header_text: $("#header_text").val(),
      body: $("#body").val(),
      footer: $("#footer").val(),
      content_variable: $("#content_variable").val(),
      status: 1,
      company_id: localStorage.getItem("company_id"),
    };
    axios({
      method: "POST",
      url: Constant.url + "add-template",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlertTemplate").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          $("#saveTemplate").text("Save and Submit");
          $("#saveTemplate").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
            $("#ImportcontactModal").removeClass("uk-flex uk-open");
          }, 1000);
        } else {
          $("#errorAlertTemplate").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          $("#saveTemplate").text("Save and Submit");
          $("#saveTemplate").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
        }
      })
      .then(function (response) {});
  };

  createTemplateDraft = () => {
    let classRef = this;
    var data = {};
    $("#saveTemplateDraft").text("Processing");
    $("#saveTemplateDraft").prop("disabled", true);
    data = {
      button_action: $("#button_action").val(),
      button_1: $("#button_1").val(),
      button_2: $("#button_2").val(),
      button_3: $("#button_3").val(),
      button_web_phone: $("#button_web_phone").val(),
      btn_text: $("#btn_text").val(),
      link_number: $("#link_number").val(),
      tempname: $("#tempname").val(),
      category: $("#category").val(),
      language: $("#language").val(),
      header_type: $("#header_type").val(),
      header_text: $("#header_text").val(),
      body: $("#body").val(),
      footer: $("#footer").val(),
      content_variable: $("#content_variable").val(),
      status: 1,
      company_id: localStorage.getItem("company_id"),
    };
    axios({
      method: "POST",
      url: Constant.url + "add-template",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlertTemplate").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          $("#saveTemplateDraft").text("Save and Submit");
          $("#saveTemplateDraft").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
            $("#ImportcontactModal").removeClass("uk-flex uk-open");
          }, 1000);
        } else {
          $("#errorAlertTemplate").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          $("#saveTemplateDraft").text("Save and Submit");
          $("#saveTemplateDraft").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
        }
      })
      .then(function (response) {});
  };

  headerContentType = () => {
    if ($("#header_type").val() == "media") {
      $("#media_section").show();
      $("#text_header_text").hide();
      $("#media_preview").show();
      $("#text_preview").hide();
      $("#footer_sec").hide();
      $("#button_sec").hide();
      $("#header_text_media").attr("placeholder", "Url");
    } else {
      $("#media_section").hide();
      $("#text_preview").show();
      $("#text_header_text").show();
      $("#media_preview").hide();
      $("#footer_sec").show();
      $("#button_sec").show();
      $("#header_text_media").attr("placeholder", "Please Enter Header Text");
    }
  };

  handlePreview = (preview) => {
    if (preview == "header_text") {
      this.setState({ headerContent: $("#header_text").val() });
    } else if (preview == "header_text_image") {
      this.setState({ headerContent: $("#header_text_image").val() });
    } else if (preview == "body") {
      this.setState({ bodyContent: $("#body").val() });
    } else if (preview == "footer") {
      this.setState({ footerContent: $("#footer").val() });
    } else if (preview == "button_1") {
      this.setState({ buttonOneContent: $("#button_1").val() });
    } else if (preview == "button_2") {
      this.setState({ buttonTwoContent: $("#button_2").val() });
    } else if (preview == "button_3") {
      this.setState({ buttonThreeContent: $("#button_3").val() });
    } else if (preview == "button_4") {
      this.setState({ buttonFourContent: $("#button_4").val() });
    } else if (preview == "header_text_media") {
      this.setState({ previewImage: $("#header_text_media").val() });
    }
  };

  deleteButton = (button) => {
    // console.log("asdasd");
    if (button == "button_delete_2") {
      $("#button_delete_2").hide();
      this.setState({ buttonTwoContent: "" });
      $("#add_button").show();
    } else if (button == "button_delete_3") {
      $("#button_delete_3").hide();
      this.setState({ buttonThreeContent: "" });
      $("#add_button").show();
    }
  };

  addButton = () => {
    if ($("#button_delete_2").css("display") == "none") {
      $("#button_delete_2").show();
    } else if ($("#button_delete_3").css("display") == "none") {
      $("#button_delete_3").show();
      $("#add_button").hide();
    }
  };

  button_web_phone = () => {
    var selectedAction = $("#button_web_phone")
      .children("option:selected")
      .val();
  };

  onTemplateChange = (id) => {
    let classRef = this;
    let selectedmessage = id.target.value;
    const filteredData = this.state.templateList.list.filter((val) => {
      return val.id == selectedmessage;
    });
    // console.log("filtered", filteredData);
    if (filteredData.length > 0) {
      this.setState({
        previewHeader: filteredData[0].header,
        previewFooter: filteredData[0].footer,
        previewBody: filteredData[0].body,
      });
    }

    classRef.setState({ filteredDataForTemplate: filteredData });

    // for error validation
    var paramError = [];
    for (var a = 0; a < filteredData[0].no_params; a++) {
      paramError.push("");
    }
    classRef.setState({
      paramErrorArray: paramError,
    });

    var paramArray = [];
    for (var a = 0; a < filteredData[0].no_params; a++) {
      paramArray.push("{{" + (a + 1) + "}}");
    }
    this.setState({ templateCustomParameters: paramArray });
    var finalArray = [];
    for (var a = 0; a < filteredData[0].message.length; a++) {
      if (filteredData[0].no_params == 0) {
        finalArray.push(filteredData[0].message[a]);
      } else {
        if (a < filteredData[0].no_params) {
          finalArray.push(
            filteredData[0].message[a] +
            "{{" +
            `${a + 1}` +
            "}}"
              // `${this.state.templateCustomParameters[a]}`
          );
        } else {
          finalArray.push(filteredData[0].message[a]);
        }
      }
    }

    classRef.setState({ messageArray: finalArray });
    const no_of_param = filteredData[0].no_params;
    var paramValues = [];
    if (no_of_param > 0) {
      for (let i = 0; i < filteredData[0].no_params; i++) {
        paramValues.push({
          parmamVal: false,
          value: "",
        });
      }
      classRef.setState({ paramArray: paramValues });
    } else {
      classRef.setState({ paramArray: [] });
    }
    // // var typeExist = data[0].type;
    classRef.setState({ noofParamsForNewBroadcast: no_of_param });

    axios({
      method: "POST",
      url: Constant.url + "single-template",
      data: { company_id: localStorage.getItem("company_id"), template_id: id },
    }).then(function (result) {
      if (result.data.status === true) {
        // console.log(result.data.data, "templateee");
        classRef.setState({
          previewHeader: result.data.data.header,
          previewFooter: result.data.data.footer,
          previewBody: result.data.data.body,
          previewButtonOne: result.data.data.button_1,
          previewButtonTwo: result.data.data.button_2,
          previewButtonThree: result.data.data.button_3,
        });
      }
    });
  };

  templateLoad = (id) => {
    let classRef = this;

    axios({
      method: "POST",
      url: Constant.url + "single-template",
      data: { company_id: localStorage.getItem("company_id"), template_id: id },
    }).then(function (result) {
      if (result.data.status === true) {
        // console.log(result.data.data, "templateee");
        classRef.setState({
          previewHeader: result.data.data.header,
          previewFooter: result.data.data.footer,
          previewBody: result.data.data.body,
          previewButtonOne: result.data.data.button_1,
          previewButtonTwo: result.data.data.button_2,
          previewButtonThree: result.data.data.button_3,
        });
      }
    });
  };

  onEditTemplateChange = () => {
    let classRef = this;
    let id = $("#templateEdit").val();
    axios({
      method: "POST",
      url: Constant.url + "single-template",
      data: { company_id: localStorage.getItem("company_id"), template_id: id },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          previewHeader: result.data.data.header,
          previewFooter: result.data.data.footer,
          previewBody: result.data.data.body,
          previewButtonOne: result.data.data.button_1,
          previewButtonTwo: result.data.data.button_2,
          previewButtonThree: result.data.data.button_3,
        });
      }
    });
  };

  broadCastByID = (id) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "single-broadcast",
      data: {
        company_id: localStorage.getItem("company_id"),
        broadcast_id: id,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({ broadcastEditID: result.data.data.name });
        $("#teamNameEdit").val(result.data.data.name);
        $("#templateEdit").val(result.data.data.template_id);
        $("#broadcast_date_edit").val(result.data.data.start_date);
        $("#broadcast_time_edit").val(result.data.data.start_time);
        classRef.templateLoad(result.data.data.template_id);
      }
    });
  };

  mediaHandler = () => {
    if ($('input[name="radio2"]:checked').val() == "image") {
      $("#image_div").show();
      $("#video_div").hide();
    } else if ($('input[name="radio2"]:checked').val() == "video") {
      $("#image_div").hide();
      $("#video_div").show();
    }
  };

  selectCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  autoSelectSchedule = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all_schedule").prop("checked", true);
        $(".autoSelectSchedule").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all_schedule").prop("checked", false);
        $(".autoSelectSchedule").prop("checked", false);
      });
    }
  };

  allChats = (event) => {
    var id = event.currentTarget.id;
    var pageCount = 1;
    // console.log(id, "karacjiiiii");

    if (id == "sent") {
      var type = "is_sent";
      this.callHistoryApi(pageCount, type);
      $("#Broadcasting").removeClass("uk-active");
      $("#BroadcastingData").removeClass("uk-active");
      $("#logs").addClass("uk-active");
      $("#logsData").addClass("uk-active");
    }
    if (id == "delivered") {
      var type = "is_delivered";
      this.callHistoryApi(pageCount, type);
      $("#Broadcasting").removeClass("uk-active");
      $("#BroadcastingData").removeClass("uk-active");
      $("#logs").addClass("uk-active");
      $("#logsData").addClass("uk-active");
    }
    if (id == "read") {
      var type = "is_read";
      this.callHistoryApi(pageCount, type);
      $("#Broadcasting").removeClass("uk-active");
      $("#BroadcastingData").removeClass("uk-active");
      $("#logs").addClass("uk-active");
      $("#logsData").addClass("uk-active");
    }
    if (id == "replied") {
      var type = "is_replied";
      this.callHistoryApi(pageCount, type);
      $("#Broadcasting").removeClass("uk-active");
      $("#BroadcastingData").removeClass("uk-active");
      $("#logs").addClass("uk-active");
      $("#logsData").addClass("uk-active");
    }
    if (id == "failed") {
      var type = "failed";
      this.callHistoryApi(pageCount, type);
      $("#Broadcasting").removeClass("uk-active");
      $("#BroadcastingData").removeClass("uk-active");
      $("#logs").addClass("uk-active");
      $("#logsData").addClass("uk-active");
    }
    if (id == "sending") {
      var type = "sending";
      this.callHistoryApi(pageCount, type);
      $("#Broadcasting").removeClass("uk-active");
      $("#BroadcastingData").removeClass("uk-active");
      $("#logs").addClass("uk-active");
      $("#logsData").addClass("uk-active");
    }
  };

  handleTableContent = () => {
    $("#Broadcasting").removeClass("uk-active");
    $("#BroadcastingData").removeClass("uk-active");
    $("#logs").addClass("uk-active");
    $("#logsData").addClass("uk-active");
    // alert('111')
    // UIkit.tab('.TabSec').show(1);
  };

  downloadbroadcast = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "download-broadcasts?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  downloadbroadcasthistory = () => {
    let classRef = this;

    window.open(
      Constant.url +
        "download-broadcast-history?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  downloadschedulebroadcast = () => {
    let classRef = this;
    window.open(
      Constant.url +
        "download-scheduled-broadcast?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  downloadTemplate = () => {
    let classRef = this;
    window.open(
      Constant.url +
        "templates-download?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  downloadPendingTemplate = () => {
    let classRef = this;
    window.open(
      Constant.url +
        "templates-pending-download?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  callLogsApi = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#ApiSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "broadcast_api_list",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          apiLogsDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountapiLogsDataList: result.data.rowscount,
        });
      } else {
        classRef.setState({
          apiLogsDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountapiLogsDataList: result.data.rowscount,
        });
      }
    });
  };

  ApihandlePageClick = (data) => {
    let currentPage = data.selected + 1;
    this.callLogsApi(currentPage);
  };

  ApiLogsTable = () => {
    let classRef = this;
    let result = "";
    let index = 0;
    // console.log(this.state.templatesDataList,'historylogs')
    result = this.state.apiLogsDataList.list.map((response, index) => (
      <tr>
        <td>{response.msisdn}</td>
        <td>{response.template_id}</td>
        <td>{response.param1}</td>
        <td>{response.param2}</td>
        <td>{response.param3}</td>
        <td>{response.param4}</td>
        <td>{response.param5}</td>
        <td>
          <button
            className={
              response.msg_sent_status == "sent"
                ? "ctmBtn succesBtn"
                : "ctmBtn dangreBtn"
            }
          >
            {response.msg_sent_status == "sent" ? "Sent" : "Pending"}
          </button>
        </td>
        <td>{response.created_date}</td>
      </tr>
    ));
    return result;
  };

  ApiLogsSorting = () => {
    let classRef = this;
    let sortingValue = $("#ApiSortingBtn").val();
    axios({
      method: "POST",
      url: Constant.url + "broadcast_api_list",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: 1,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          apiLogsDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountapiLogsDataList: result.data.rowscount,
        });
      } else {
        classRef.setState({
          apiLogsDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountapiLogsDataList: result.data.rowscount,
        });
      }
    });
  };

  downloadApilogs = () => {
    let classRef = this;
    window.open(
      Constant.url +
        "download_broadcast_api?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  render() {
    return (
      <Content>
        <div
          className="BroadcastingContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          {/* Broadcasting Tab Start */}
          <div className="TabsHeadBox">
            <ul className="tabsBtns desktop" uk-switcher="connect: .TabSec2">
              <li id="Broadcasting">
                <a href="#">Broadcasting</a>
              </li>
              <li id="logs">
                <a href="#">Logs</a>
              </li>
              {/* <li>
                <a href="#">Schedule</a>
              </li> */}
              {/* <li>
                <a href="#">Template Messages</a>
              </li> */}
              <li>
                <a href="#">Templates</a>
              </li>
              {/* <li>
                <a href="#">Pending Templates</a>
              </li> */}

              <li>
                <a href="#">Api Logs</a>
              </li>
            </ul>

            <div class="mobTabsBtn">
              <p class="AnimFade uk-scrollspy-inview uk-animation-fade">
                Broadcasting
              </p>
              <ul className="tabsBtns" uk-switcher="connect: .TabSec">
                <li>
                  <a href="#">Broadcasting</a>
                </li>
                <li>
                  <a href="#">Logs</a>
                </li>
                {/* <li>
                  <a href="#">Schedule</a>
                </li> */}
                <li>
                  <a href="#">Template Messages</a>
                </li>
                {/* <li>
                  <a href="#">Pending Templates</a>
                </li> */}

                <li>
                  <a href="#">Api Logs</a>
                </li>
              </ul>
            </div>
            <div className="searchnFlowBtn mobile audiencesearchnFlowBtn">
              <button
                className="newFlowBtn uk-float-right"
                id="AddBroadcast"
                onClick={this.AddBroadcastModal}
              >
                <Add /> <span>New Broadcast</span>
              </button>
              <button
                class="newFlowBtn uk-float-right uk-margin-right"
                onClick={this.downloadbroadcast}
              >
                <Add /> <span>Export Broadcast</span>
              </button>
              <button
                class="newFlowBtn uk-float-right uk-margin-right"
                onClick={this.showReinitiate}
              >
                <Add /> <span>Quick Message</span>
              </button>
            </div>
          </div>

          <div id="errorAlertcheck"></div>

          <ul className="uk-switcher TabSec TabSec2">
            <li id="BroadcastingData">
              <div className="stats_container">
                <div
                  className="uk-child-width-expand@s uk-grid uk-grid-collapse"
                  uk-grid=""
                >
                  <div>
                    <div
                      id="sent"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <h6>{this.state.stats.sent}</h6>
                        <span>Sent</span>
                      </div>
                      <div className="icon">
                        <InProgress />
                        <div
                          className="info"
                          uk-tooltip="Number of Sent Messages"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      id="delivered"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <h6>{this.state.stats.delivered}</h6>
                        <span>Delivered</span>
                      </div>
                      <div className="icon">
                        <img src="/images/delivered.svg" uk-svg="" />
                        <div
                          className="info"
                          uk-tooltip="Number of Delivered Messages"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      id="read"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <h6>{this.state.stats.read}</h6>
                        <span>Read</span>
                      </div>
                      <div className="icon">
                        <ViewFilled />
                        <div
                          className="info"
                          uk-tooltip="Number of Read Messages"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>
                                        <div id="replied" onClick={this.allChats} className="box" uk-height-match="">
                                            <div className="text">
                                                <h6>{this.state.stats.replied}</h6>
                                                <span>Replied</span>
                                            </div>
                                            <div className="icon">
                                                <ReplyAll />
                                                <div className="info" uk-tooltip="Number of Replied Messages"><Information /></div>
                                            </div>
                                        </div>
                                    </div> */}

                  <div>
                    <div
                      id="sending"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <h6>{this.state.stats.sending}</h6>
                        <span>Sending</span>
                      </div>
                      <div className="icon">
                        <Send />
                        <div
                          className="info"
                          uk-tooltip="Number of Sending Messages"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      id="failed"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <h6>{this.state.stats.Failed}</h6>
                        <span>Failed</span>
                      </div>
                      <div className="icon">
                        <NotSent />
                        <div
                          className="info"
                          uk-tooltip="Number of Failed Messages"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tableSec">
                <div className="numberSorting uk-float-right">
                  <div class="uk-inline">
                    <label>
                      Show
                      <select
                        onChange={(e) => this.BroadcastSorting()}
                        id="BroadcastSortingBtn"
                      >
                        {/* <option disabled selected>Sorting</option> */}
                        <option>10</option>
                        <option>20</option>
                        <option>50</option>
                        <option>500</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <table className="uk-table uk-table-small uk-table-divider">
                  <thead>
                    <tr>
                      <th>
                        <label>
                          <input
                            className="uk-checkbox"
                            type="checkbox"
                            id="select_all"
                            onClick={this.selectCheckbox}
                          />
                        </label>
                        <div className="uk-inline drpIconBox">
                          <button className="iconTble" type="button">
                            <CaretDown />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li className="hintAlert">
                                <a href="#">
                                  <TrashCan />
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </th>
                      <th>BROADCAST NAME</th>
                      <th>SUCCESSFUL</th>
                      <th>READ</th>
                      <th>REPLIED</th>
                      <th>FAILED</th>
                      <th>STATUS</th>
                      <th>SCHEDULED</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {(this.state.dataList.isLoaded) ? this.handleTable() : ""} */}
                    {this.state.dataList.isLoaded ? (
                      this.handleTable()
                    ) : this.state.dataList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountBroadcast > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountBroadcast}
                    onPageChange={this.handleBroadcastClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li>

            <li id="logsData">
              <div className="tableSec">
                <div className="uk-grid">
                  <div className="uk-width-expand"></div>
                  <div className="uk-width-auto">
                    <button
                      className="newFlowBtn uk-margin-small-top"
                      id="AddBroadcast"
                      onClick={this.downloadbroadcasthistory}
                    >
                      <Add /> <span>Export Logs Broadcast</span>
                    </button>
                  </div>
                  <div className="uk-width-auto">
                    <div className="numberSorting uk-float-right">
                      <div class="uk-inline">
                        <label>
                          Show
                          <select
                            onChange={(e) => this.LogsSorting()}
                            id="LogsSortingBtn"
                          >
                            {/* <option disabled selected>Sorting</option> */}
                            <option>10</option>
                            <option>20</option>
                            <option>50</option>
                            <option>500</option>
                          </select>
                          entries
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <table className="uk-table uk-table-small uk-table-divider">
                  <thead>
                    <tr>
                      <th>NUMBER</th>
                      <th>MESSAGE</th>
                      <th>STATUS</th>
                      <th>DATETIME</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {(this.state.historyDataList.isLoaded) ? this.handleHistoryTable() : ""} */}
                    {this.state.historyDataList.isLoaded ? (
                      this.handleHistoryTable()
                    ) : this.state.historyDataList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountLogs > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountLogs}
                    onPageChange={this.handleLogsClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li>

            {/* schedule listing */}
            {/* <li>
              <div className="tableSec">
                <div className="uk-grid">
                  <div className="uk-width-expand"></div>
                  <div className="uk-width-auto">
                    <button
                      className="newFlowBtn uk-margin-small-top"
                      id="AddBroadcast"
                      onClick={this.downloadschedulebroadcast}
                    >
                      <Add /> <span>Export Schedule Broadcast</span>
                    </button>
                  </div>
                  <div className="numberSorting uk-float-right">
                    <div class="uk-inline">
                      <label>
                        Show
                        <select
                          onChange={(e) => this.ScheduleSorting()}
                          id="ScheduleSortingBtn">
                          <option>10</option>
                          <option>20</option>
                          <option>50</option>
                          <option>500</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                </div>

                <table className="uk-table uk-table-small uk-table-divider">
                  <thead>
                    <tr>
                      <th>
                        <label>
                          <input
                            className="uk-checkbox"
                            type="checkbox"
                            id="select_all_schedule"
                            onClick={this.autoSelectSchedule}
                          />
                        </label>
                        <div className="uk-inline drpIconBox">
                          <button className="iconTble" type="button">
                            <CaretDown />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li className="hintAlert">
                                <a href="#">
                                  <TrashCan /> Delete Select Rows
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </th>
                      <th>BROADCAST NAME</th>
                      <th>STATUS</th>
                      <th>SCHEDULED</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.SceduledDataList.isLoaded ? (
                      this.handleSchduledTable()
                    ) : this.state.SceduledDataList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountSchedule > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountSchedule}
                    onPageChange={this.handleScheduleClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li> */}

            {/* template cards lising */}

            {/* <li>
              <div className="TabFourBroadCast">
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-1">
                    <h4>Popular integrating</h4>
                  </div>
                  {this.state.importTempList.isLoaded ? (
                    this.handleImportTemplate()
                  ) : this.state.importTempList.msg == "not" ? (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          Data not Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          <div className="mdl">
                            <div className="mdl_inner">
                              <div uk-spinner=""></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </div>
              </div>
            </li> */}

            {/* template listing */}

            <li>
              <div className="uk-grid">
                <div className="uk-width-expand"></div>
                <div className="uk-width-auto">
                  <button
                    className="newFlowBtn uk-margin-small-top"
                    id="AddBroadcast"
                    onClick={this.downloadTemplate}
                  >
                    <Add /> <span>Export Template</span>
                  </button>
                </div>

                <div className="numberSorting uk-float-right">
                  <div class="uk-inline">
                    <label>
                      Show
                      <select
                        onChange={(e) => this.TemplateSorting(e)}
                        id="TemplateSortingBtn"
                      >
                        <option>10</option>
                        <option>20</option>
                        <option>50</option>
                        <option>500</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
              </div>
              <div className="tableSec">
                <table className="uk-table uk-table-small uk-table-divider">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>LINK</th>
                      <th>DATETIME</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.templatesDataList.isLoaded ? (
                      this.handleTemplatesTable()
                    ) : this.state.templatesDataList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountTemplate > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountTemplate}
                    onPageChange={this.handlePageClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li>

            {/* pending broadcast */}

            {/* <li>
              <div className="uk-grid">
                <div className="uk-width-expand"></div>
                <div className="uk-width-auto">
                  <button
                    className="newFlowBtn uk-margin-small-top"
                    id="AddBroadcast"
                    onClick={this.downloadPendingTemplate}
                  >
                    <Add /> <span>Export Pending Template</span>
                  </button>
                </div>

                <div className="numberSorting uk-float-right">
                  <div class="uk-inline">
                    <label>
                      Show
                      <select
                        onChange={(e) => this.PendingTemplateSorting(e)}
                        id="PendingTemplateSortingBtn"
                      >
                        <option>10</option>
                        <option>20</option>
                        <option>50</option>
                        <option>500</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
              </div>
              <div className="tableSec">
                <table className="uk-table uk-table-small uk-table-divider">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>LINK</th>
                      <th>STATUS</th>
                      <th>DATETIME</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.pendingtemplatesDataList.isLoaded ? (
                      this.PendinghandleTemplatesTable()
                    ) : this.state.pendingtemplatesDataList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountPendingTemplate > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountPendingTemplate}
                    onPageChange={this.PendinghandlePageClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li> */}

            {/* api logs */}

            <li>
              <div className="uk-grid">
                <div className="uk-width-expand"></div>
                <div className="uk-width-auto">
                  <button
                    className="newFlowBtn uk-margin-small-top"
                    id="AddBroadcast"
                    onClick={this.downloadApilogs}
                  >
                    <Add /> <span>Export API Logs</span>
                  </button>
                </div>

                <div className="numberSorting uk-float-right">
                  <div class="uk-inline">
                    <label>
                      Show
                      <select
                        onChange={(e) => this.ApiLogsSorting(e)}
                        id="ApiSortingBtn"
                      >
                        {/* <option disabled selected>Sorting</option> */}
                        <option>10</option>
                        <option>20</option>
                        <option>50</option>
                        <option>500</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
              </div>
              <div className="tableSec">
                <table className="uk-table uk-table-small uk-table-divider">
                  <thead>
                    <tr>
                      <th>MSISDN</th>
                      <th>TEMPLATE ID</th>
                      <th>PARAM 1</th>
                      <th>PARAM 2</th>
                      <th>PARAM 3</th>
                      <th>PARAM 4</th>
                      <th>PARAM 5</th>
                      <th>DELIVERY STATUS</th>
                      <th>DELIVERY DATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {(this.state.historyDataList.isLoaded) ? this.handleHistoryTable() : ""} */}
                    {this.state.apiLogsDataList.isLoaded ? (
                      this.ApiLogsTable()
                    ) : this.state.apiLogsDataList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountapiLogsDataList > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountapiLogsDataList}
                    onPageChange={this.ApihandlePageClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li>
          </ul>
        </div>

        <div
          id="EditBroadcastModal"
          className="EditBroadcastModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit Broadcast</h3>

            <div className="uk-grid" uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="AddBroadcastSide_i">
                  <div id="errorAlertEdit"></div>
                  <div id="successAlertEdit"></div>

                  <div className="uk-width-1-1">
                    <p>
                      With the broadcast feature, you can send a message to
                      several of your contacts at once.
                    </p>
                  </div>
                  <form action="javascript:void(0);">
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="teamName">
                        Broadcast name
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input"
                          id="teamNameEdit"
                          name="teamNameEdit"
                          type="text"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="template">
                        Select WhatsApp template message
                      </label>
                      <select
                        name="templateEdit"
                        className="uk-select"
                        id="templateEdit"
                        onChange={this.onEditTemplateChange}
                      >
                        {this.state.templateList.isLoaded
                          ? this.handleTemplate()
                          : ""}
                      </select>
                    </div>

                    <div class="date">
                      <input
                        type="date"
                        label="Select Broadcast date"
                        id="broadcast_date_edit"
                        className="date"
                      />
                      <input
                        type="time"
                        label="Time"
                        id="broadcast_time_edit"
                        className="time"
                      />
                    </div>
                    <div className="uk-margin uk-margin-medium-top">
                      <div className="ModalStyleBtns">
                        <button
                          className="CnclBtn"
                          onClick={this.CloseEditBroadcastModal}
                        >
                          Cancel
                        </button>
                        <a onClick={this.editBroadcast} className="AddTeamBtn">
                          Edit
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="uk-width-1-2@m">
                <div className="msg_preview">
                  <h2>Preview</h2>
                  <div className="msg_box">
                    <h6>{this.state.previewHeader}</h6>
                    <p>{this.state.previewBody}</p>
                    <p>{this.state.previewFooter}</p>
                  </div>
                  <div className="action_btns">
                    <div className="uk-flex uk-flex-center uk-grid-small uk-grid">
                      {this.state.previewButtonOne ? (
                        <div
                          className={
                            this.state.previewButtonTwo == "" &&
                            this.state.previewButtonTwo == ""
                              ? "uk-width-1-1 uk-text-center"
                              : "uk-width-1-2 uk-text-center"
                          }
                        >
                          <div className="msg_box">
                            <h6>{this.state.previewButtonOne}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.previewButtonTwo ? (
                        <div className="uk-width-1-2 uk-text-center">
                          <div className="msg_box">
                            <h6>{this.state.previewButtonTwo}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.previewButtonThree ? (
                        <div className="uk-width-1-1 uk-text-center">
                          <div className="msg_box">
                            <h6>{this.state.previewButtonThree}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="AddBroadcastModal"
          className="AddBroadcastModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>New Broadcast</h3>

            <div className="uk-grid" uk-grid="">
              <div className="uk-width-1-2@m uk-width-1-1@s">
                <div className="AddBroadcastSide_i">
                  <div id="errorAlert"></div>

                  <div className="uk-width-1-1">
                    <p>
                      With the broadcast feature, you can send a message to
                      several of your contacts at once.
                    </p>
                  </div>
                  <form action="javascript:void(0);">
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="teamName">
                        Broadcast name
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input"
                          id="teamName"
                          name="teamName"
                          type="text"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="template">
                        Select WhatsApp template message
                      </label>
                      <select
                        name="template"
                        className="uk-select"
                        id="template"
                        onChange={this.onTemplateChange}
                      >
                        <option disabled selected value="">
                          Select Message
                        </option>
                        {this.state.templateList.isLoaded
                          ? this.handleTemplate()
                          : ""}
                      </select>
                    </div>
                    {this.state.paramArray.length == 0
                      ? ""
                      : this.state?.paramArray?.map((val, index) => {
                          return (
                            <div className="uk-margin" key={index}>
                              <label
                                className="uk-form-label"
                                htmlFor="template"
                              >
                                Param {index + 1}
                              </label>
                              <input
                                type="text"
                                class="uk-input"
                                placeholder={`Enter Param ${index + 1}`}
                                id={`param${index}business`}
                                name={`param${index}`}
                                onChange={(e) =>
                                  this.handleParamChange(e, index)
                                }
                              />
                            </div>
                          );
                        })}
                    <div className="toggle_box uk-margin">
                      <p>
                        {" "}
                        A WhatsApp message template is a message format that you
                        can use over and over again to message users once they
                        have opted-in and given your app permission to send them
                        messages.
                      </p>
                      <div className="modal_2">
                        <a onClick={this.CreateTemplateModal}>
                          {" "}
                          <Add /> Create a new message template
                        </a>
                      </div>
                    </div>
                    <div class="date">
                      <input
                        type="date"
                        min={
                          new Date().getFullYear() +
                          "-" +
                          ("0" + (new Date().getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + new Date().getDate()).slice(-2)
                        }
                        label="Select Broadcast date"
                        id="broadcast_date"
                      />
                      <input type="time" label="Time" id="broadcast_time" />
                      {/* <span uk-icon="icon: clock"></span> */}
                    </div>
                    <div className="uk-margin uk-margin-medium-top">
                      <div className="ModalStyleBtns">
                        <button className="CnclBtn uk-modal-close">
                          Cancel
                        </button>
                        <a
                          onClick={this.AddBroadcastModal2}
                          className="AddTeamBtn"
                        >
                          Next
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="uk-width-1-2@m uk-width-1-1@s">
                <div className="msg_preview">
                  <h2>Preview</h2>
                  <div className="msg_box">
                    <h6>{this.state.previewHeader}</h6>
                    <p>{this.state.messageArray.join(" ")}</p>
                    <p>{this.state.previewFooter}</p>
                  </div>
                  <div className="action_btns">
                    <div className="uk-flex uk-flex-center uk-grid-small uk-grid">
                      {this.state.previewButtonOne ? (
                        <div
                          className={
                            this.state.previewButtonTwo == "" &&
                            this.state.previewButtonTwo == ""
                              ? "uk-width-1-1 uk-text-center"
                              : "uk-width-1-2 uk-text-center"
                          }
                        >
                          <div className="msg_box">
                            <h6>{this.state.previewButtonOne}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.previewButtonTwo ? (
                        <div className="uk-width-1-2 uk-text-center">
                          <div className="msg_box">
                            <h6>{this.state.previewButtonTwo}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.previewButtonThree ? (
                        <div className="uk-width-1-1 uk-text-center">
                          <div className="msg_box">
                            <h6>{this.state.previewButtonThree}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="AddBroadcastModal2"
          className="AddBroadcastModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>New Broadcast</h3>

            <div className="uk-grid" uk-grid="">
              <div className="uk-width-1-2">
                <div className="AddBroadcastSide_i">
                  <div id="errorAlerts"></div>
                  <div id="successAlert"></div>

                  <div className="uk-width-1-1">
                    <p>
                      With the broadcast feature, you can send a message to
                      several of your contacts at once.
                    </p>
                  </div>
                  <form action="javascript:void(0);">
                    <div className="uk-margin">
                      <label className="uk-form-label" htmlFor="template">
                        Select Audience Segment
                      </label>
                      <select
                        name="template"
                        className="uk-select"
                        id="segment"
                      >
                        {this.state.segmentList.isLoaded
                          ? this.handleSegment()
                          : ""}
                        {/* <option>
                          Default Audience Segment (36,4825,290 Contacts)
                        </option>
                        <option>
                          SMS Burst Audience Segment (658,021 Contacts)
                        </option>
                        <option>
                          Lahore Audience Segment (786,153 Contacts)
                        </option>
                        <option>
                          Karachi Audience Segment (947,619 Contacts)
                        </option>
                        <option>Renewal Reminder (128,931 Contacts)</option>
                        <option>
                          Payment Reminder Segment (243,456 Contacts)
                        </option> */}
                      </select>
                    </div>
                    <div className="uk-margin">
                      <div className="ImportBtn">
                        <button
                          className=""
                          onClick={this.AddImportcontactModal}
                        >
                          {" "}
                          <DocumentImport /> Import Contacts
                        </button>
                        {/* <button className=""> <Add /> New Audience Segment</button> */}
                      </div>
                    </div>
                    <div className="toggle_box uk-margin">
                      <p>
                        {" "}
                        Before you import a file of contacts, it's a good idea
                        to make sure you have the right file type and that the
                        data is formatted correctly.
                      </p>
                      <div className="modal_2">
                        {/* uk-toggle="target: #CreateTemplateModal" */}
                        {/* <a  to="/files/contacts_import.csv" target="_blank" download> <Document /> Download sample format</a> */}
                        <Link
                          className="uk-text-left"
                          to="/files/contacts_import.csv"
                          target="_blank"
                          download
                        >
                          <Document /> Download Sample File
                        </Link>
                      </div>
                    </div>
                    <div className="uk-margin uk-margin-medium-top">
                      <div className="ModalStyleBtns">
                        <button
                          className="CnclBtn uk-modal-close"
                          onClick={this.CloseBroadcastModal2}
                        >
                          Cancel
                        </button>
                        <button
                          className="AddTeamBtn"
                          onClick={this.createBroacast}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="uk-width-1-2">
                <div className="msg_preview">
                  <h2>Preview</h2>
                  {/* <div className="msg_box">
                    <h6>Send your first message</h6>
                    <p>Hi Customer 👋🏻</p>
                    <p> Welcome to our Demo! </p>
                    <p>Click below button to send your first message 👇🏻</p>
                    <p className="time">22:00</p>
                  </div> */}
                  <div className="msg_box">
                    <h6>{this.state.previewHeader}</h6>
                    <p>{this.state.messageArray.join(" ")}</p>
                    <p>{this.state.previewFooter}</p>
                  </div>
                  {/* <div className="msg_box uk-text-center">
                    <h6>Hello</h6>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="CreateTemplateModal"
          className="AddBroadcastModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Create new template message</h3>

            <div className="uk-grid" uk-grid="" uk-height-match=".ht_same">
              <div className="uk-width-1-2  ">
                <div className="AddBroadcastSide_i ht_same">
                  <div className="uk-width-1-1">
                    <p>
                      With the broadcast feature, you can send a message to
                      several of your contacts at once.
                    </p>
                  </div>
                  <form className="" action="javascript:void(0);">
                    <div className="uk-grid uk-grid-small">
                      <div className="uk-width-1-3">
                        <div className="">
                          <label className="uk-form-label" htmlFor="teamName">
                            Template name
                          </label>
                          <div className="uk-form-controls">
                            <input
                              className="uk-input"
                              id="tempname"
                              name="tempname"
                              type="text"
                              placeholder="Template Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="uk-width-1-3">
                        <div className="">
                          <label className="uk-form-label" htmlFor="template">
                            Category
                          </label>
                          <select
                            name="template"
                            className="uk-select"
                            id="category"
                          >
                            {this.state.categoryList.isLoaded
                              ? this.handleCategory()
                              : ""}
                          </select>
                        </div>
                      </div>
                      <div className="uk-width-1-3">
                        <div className="">
                          <label className="uk-form-label" htmlFor="template">
                            Language
                          </label>
                          <select
                            name="template"
                            className="uk-select"
                            id="language"
                          >
                            {this.state.languagesList.isLoaded
                              ? this.handleLanguages()
                              : ""}
                          </select>
                        </div>
                      </div>

                      <div className="fields_section uk-width-1-1">
                        <h5>Header (Optional)</h5>
                        <p>
                          Add a title or choose which type of media you'll use
                          for this header.
                        </p>
                        <p>Your title can't include more than one variable.</p>
                        <div className="uk-width-1-3">
                          <div className="">
                            <label className="uk-form-label" htmlFor="template">
                              Header Content
                            </label>
                            <select
                              name="template"
                              className="uk-select"
                              id="header_type"
                              onChange={this.headerContentType}
                            >
                              <option value="text">Text</option>
                              <option value="media">Media</option>
                            </select>
                          </div>
                        </div>
                        {/* media start */}
                        <div
                          className="mediaformfield"
                          id="media_section"
                          style={{ display: "none" }}
                        >
                          <p>Header media</p>
                          <div class="uk-child-width-auto uk-grid uk-margin-small">
                            <label>
                              <input
                                onClick={this.mediaHandler}
                                class="uk-radio"
                                type="radio"
                                value="image"
                                name="radio2"
                              />
                              Image
                            </label>
                            <label>
                              <input
                                onClick={this.mediaHandler}
                                class="uk-radio"
                                type="radio"
                                value="video"
                                name="radio2"
                              />
                              Video
                            </label>
                            <label>
                              <input
                                onClick={this.mediaHandler}
                                class="uk-radio"
                                type="radio"
                                value="document"
                                name="radio2"
                              />
                              Document
                            </label>
                          </div>
                          <div className="uk-form-controls">
                            <label className="uk-form-label">
                              Image url
                              <span class=" uk-float-right">0/2000</span>
                            </label>
                            <input
                              className="uk-input"
                              id="header_text_media"
                              type="text"
                              placeholder="Please Enter Header Text"
                              onChange={(e) =>
                                this.handlePreview("header_text_media")
                              }
                            />
                            <p className="Hint">
                              The recommended dimension is 900x900 pixels - your
                              image will be bigger and sharper with this
                              resolution. There’s a 16 MB size limit and use a
                              JPEG. PNG or GIF file (no animations).
                            </p>
                            {/* <h5>OR</h5>
                                                    <input className="" type="file" placeholder="" /> */}
                          </div>
                        </div>
                        <div className="uk-width-1-1">
                          <div className="" id="text_header_text">
                            <label className="uk-form-label uk-float-right">
                              5/60
                            </label>
                            <div className="uk-form-controls">
                              <input
                                className="uk-input"
                                id="header_text"
                                type="text"
                                placeholder="Please Enter Header Text"
                                onChange={(e) =>
                                  this.handlePreview("header_text")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <hr className="uk-width-1-1"></hr>
                      </div>

                      <div className="fields_section uk-width-1-1">
                        <h5>Body</h5>
                        <p>
                          To add a custom variable, please add a variable in
                          double curly brackets without a space. Example: $
                          {"{{shop_name}}"}
                        </p>

                        <div className="uk-width-1-1">
                          <div className="">
                            <label className="uk-form-label uk-float-right">
                              43/1024
                            </label>
                            <textarea
                              id="body"
                              onChange={(e) => this.handlePreview("body")}
                              placeholder="Your number has been registered on {{date}}"
                            ></textarea>
                            <div className="emojis">
                              <button>
                                <FaceSatisfied />
                              </button>
                              <button>{"{}"}</button>
                            </div>
                          </div>
                        </div>

                        <hr className="uk-width-1-1"></hr>
                      </div>

                      <div
                        className="fields_section uk-width-1-1"
                        id="footer_sec"
                      >
                        <h5>Footer (Optional)</h5>
                        <p>
                          Add a short line of text to the bottom of your message
                          template.
                        </p>

                        <div className="uk-width-1-1">
                          <div className="">
                            <label className="uk-form-label uk-float-right">
                              15/60
                            </label>
                            <input
                              id="footer"
                              onChange={(e) => this.handlePreview("footer")}
                              className="uk-input"
                              type="text"
                              placeholder="Have a nice day"
                            />
                          </div>
                        </div>

                        <hr className="uk-width-1-1"></hr>
                      </div>

                      <div
                        className="fields_section uk-width-1-1"
                        id="button_sec"
                      >
                        <h5>Buttons (Optional)</h5>
                        <p>
                          Create up to 3 buttons that let customers respond to
                          your message or take action.
                        </p>

                        <div className="uk-width-1-3">
                          <div className="">
                            <select
                              className="uk-select"
                              id="button_action"
                              onChange={this.handleButtonAction}
                            >
                              <option value="quick_reply">Quick reply</option>
                              <option value="call_to_action">
                                Call to action
                              </option>
                            </select>
                          </div>
                        </div>

                        <div
                          className="uk-grid uk-grid-small uk-margin-top"
                          id="quick_reply"
                        >
                          <div className="uk-width-1-3" id="button_delete_1">
                            <div class="CustomInputOption">
                              <label className="uk-form-label">
                                Button option 1{" "}
                                <span class=" uk-float-right">3/20</span>
                              </label>
                              <input
                                className="uk-input"
                                id="button_1"
                                onChange={(e) => this.handlePreview("button_1")}
                                type="text"
                                placeholder="Yes"
                              />
                              {/* <a class=" uk-float-right"  onClick={(e) => this.deleteButton('button_delete_1')}   uk-icon="trash"></a> */}
                            </div>
                          </div>
                          <div
                            className="uk-width-1-3"
                            id="button_delete_2"
                            style={{ display: "none" }}
                          >
                            <div class="CustomInputOption">
                              <label className="uk-form-label">
                                Button option 2{" "}
                                <span class=" uk-float-right">2/20</span>
                              </label>
                              <input
                                className="uk-input"
                                id="button_2"
                                onChange={(e) => this.handlePreview("button_2")}
                                type="text"
                                placeholder="No"
                              />
                              <a
                                class=" uk-float-right"
                                onClick={(e) =>
                                  this.deleteButton("button_delete_2")
                                }
                                uk-icon="trash"
                              ></a>
                            </div>
                          </div>
                          <div
                            className="uk-width-1-3"
                            id="button_delete_3"
                            style={{ display: "none" }}
                          >
                            <div class="CustomInputOption">
                              <label className="uk-form-label ">
                                Button option 3{" "}
                                <span class=" uk-float-right">5/20</span>
                              </label>
                              <input
                                className="uk-input"
                                id="button_3"
                                onChange={(e) => this.handlePreview("button_3")}
                                type="text"
                                placeholder="Maybe"
                              />
                              <a
                                class=" uk-float-right"
                                onClick={(e) =>
                                  this.deleteButton("button_delete_3")
                                }
                                uk-icon="trash"
                              ></a>
                            </div>
                          </div>

                          <div className="uk-width-1-3">
                            <div className="CustomAddBtn">
                              <button
                                className="newFlowBtn"
                                id="add_button"
                                onClick={this.addButton}
                              >
                                <Add /> <span>Add New</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* new */}
                        <div
                          className="uk-grid uk-grid-small uk-margin-top"
                          id="call_to_action"
                          style={{ display: "none" }}
                        >
                          <div className="uk-width-1-3">
                            <div class="CustomInputOption">
                              {/* <label className="uk-form-label" htmlFor="newDrop" id="langiage">Language</label> */}
                              <select
                                name="newDrop"
                                className="uk-select"
                                id="button_web_phone"
                                onChange={this.button_web_phone}
                              >
                                <option value="visit_website">
                                  Visit Website
                                </option>
                                <option value="call_phone">Call Phone</option>
                              </select>
                            </div>
                          </div>
                          <div className="uk-width-1-3">
                            <div class="CustomInputOption">
                              {/* <label className="uk-form-label" >Button option 2 <span class=" uk-float-right">2/20</span></label> */}
                              <input
                                className="uk-input"
                                type="text"
                                id="button_4"
                                placeholder="Button Text"
                                onChange={(e) => this.handlePreview("button_4")}
                              />
                              {/* <a class=" uk-float-right"  href="" uk-icon="trash"></a> */}
                            </div>
                          </div>
                          <div className="uk-width-1-3">
                            <div class="CustomInputOption">
                              {/* <label className="uk-form-label" >Button option 2 <span class=" uk-float-right">2/20</span></label> */}
                              <input
                                className="uk-input"
                                type="text"
                                id="link_number"
                                placeholder="Link Or Phone Number"
                              />
                              {/* <a class=" uk-float-right"  href="" uk-icon="trash"></a> */}
                            </div>
                          </div>
                        </div>

                        <hr className="uk-width-1-1"></hr>
                      </div>
                    </div>

                    <div className="toggle_box uk-margin">
                      <h3>Sample Content</h3>
                      <p>
                        {" "}
                        Make sure not to include any actual user or customer
                        information, and provide only sample content in your
                        examples. Learn more
                      </p>
                    </div>

                    <div className="uk-width-1-1">
                      <div class="">
                        <input
                          className="uk-input"
                          type="text"
                          id="content_variable"
                          placeholder="Enter content for {{date}}"
                        />
                      </div>
                    </div>
                    <div id="errorAlertTemplate"></div>
                    <div id="successAlertTemplate"></div>
                    <div className="uk-margin">
                      <div className="ModalStyleBtns">
                        <button
                          className="CnclBtn"
                          id="saveTemplateDraft"
                          onClick={this.createTemplateDraft}
                        >
                          Save as draft
                        </button>
                        <button
                          className="AddTeamBtn"
                          id="saveTemplate"
                          onClick={this.createTemplate}
                        >
                          Save and submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="uk-width-1-2">
                <div className="msg_preview ht_same">
                  <h2>Preview</h2>
                  <div className="msg_box" id="text_preview">
                    <h6>{this.state.headerContent}</h6>
                    <p>{this.state.bodyContent}</p>
                    <p className="time uk-float-left">
                      {this.state.footerContent}
                    </p>
                    {/* <p className="time uk-float-right">22:00</p> */}
                  </div>
                  <div id="media_preview" style={{ display: "none" }}>
                    <div className="msg_box media_msg_box">
                      {/* <h6>Hello</h6> */}
                      <div class="media_msg_box_img" id="image_div">
                        {this.state.previewImage == "" ? (
                          <Image />
                        ) : (
                          <img src={this.state.previewImage} />
                        )}
                      </div>
                      <div
                        class="media_msg_box_img"
                        id="video_div"
                        style={{ display: "none" }}
                      >
                        <video
                          style={{ display: "none" }}
                          id="video_url"
                          src={this.state.previewVideo}
                        />
                      </div>
                      <p>{this.state.bodyContent}</p>
                    </div>
                  </div>
                  <div className="action_btns">
                    <div className="uk-flex uk-flex-center uk-grid-small uk-grid">
                      {this.state.buttonOneContent != "" ? (
                        <div className=" uk-width-1-2 uk-text-center">
                          <div className="msg_box">
                            <h6>{this.state.buttonOneContent}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.buttonTwoContent != "" ? (
                        <div className=" uk-width-1-2 uk-text-center">
                          <div className="msg_box">
                            <h6>{this.state.buttonTwoContent}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.buttonThreeContent != "" ? (
                        <div className="uk-width-1-1 uk-text-center">
                          <div className="msg_box">
                            <h6>{this.state.buttonThreeContent}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.buttonFourContent != "" ? (
                        <div className="uk-width-1-1 uk-text-center">
                          <div className="msg_box">
                            <h6>{this.state.buttonFourContent}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="ImportcontactModal"
          className="ImportcontactModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Import Contacts</h3>

            <div className="uk-grid" uk-grid="">
              <div className="uk-width-1-1@m">
                <div class="AddBroadcastSide_i">
                  <div class="uk-width-1-1">
                    <p>
                      <b>Upload a file</b>
                    </p>
                  </div>
                  <form action="javascript:void(0);">
                    <div id="errorAlertSegment"></div>

                    <div class="uk-margin CustomForm">
                      <input
                        type="text"
                        name="segment_name"
                        id="segment_name"
                        className="uk-input"
                        placeholder="Segment Name"
                      />
                    </div>

                    <div class="uk-margin CustomForm">
                      <label class="uk-form-label" for="template">
                        Import contacts from a CSV or tab-delimited TXT file.
                      </label>
                      <div class="uk-margin">
                        <div uk-form-custom="">
                          <CloudUpload />
                          <p>Browser</p>
                          <input type="file" onChange={this.onFileUpload} />
                        </div>

                        {/* <p>validation text: File upload error. Select a file to continue.</p> */}
                      </div>
                      <p className="uk-text-left" id="uploadedFile"></p>
                    </div>
                    <Link
                      className="uk-text-left"
                      to="/files/contacts_import.csv"
                      target="_blank"
                      download
                    >
                      Download Sample File
                    </Link>
                    <div class="uk-margin uk-margin-medium-top">
                      <div class="ModalStyleBtns">
                        <button
                          class="CnclBtn"
                          onClick={this.hideImportcontactModal}
                        >
                          Cancel
                        </button>
                        <button
                          class="AddTeamBtn"
                          id="uploadButton"
                          onClick={this.uploadFile}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* when media upload design start */}

        <div
          id="ImportcontactModalUpload"
          className="ImportcontactModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Import Contacts</h3>

            <div className="uk-grid" uk-grid="">
              <div className="uk-width-1-1@m">
                <div class="AddBroadcastSide_i">
                  <div class="uk-width-1-1">
                    <p>
                      <b>Upload a file</b>
                    </p>
                  </div>
                  <form action="javascript:void(0);">
                    <div class="uk-margin CustomForm CustomFormUpload">
                      <label class="uk-form-label" for="template">
                        Import contacts from a CSV or tab-delimited TXT file.
                      </label>
                      <div class="uk-margin">
                        <div uk-form-custom="">
                          {/* <CloudUpload /> */}
                          {/* <p>Browser</p>
                                                <input type="file"/> */}
                          <h5>
                            contacts.csv{" "}
                            <a
                              className="uk-float-right"
                              href=""
                              uk-icon="icon: close"
                            ></a>
                          </h5>
                          <span>12.0kb</span>
                        </div>
                        {/* <p>validation text: File upload error. Select a file to continue.</p> */}
                      </div>
                    </div>
                    <div class="uk-margin uk-margin-medium-top">
                      <div class="ModalStyleBtns">
                        <button class="CnclBtn">Cancel</button>
                        <button class="AddTeamBtn">Continue</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Show Template Delete popup */}
        <div
          id="TemplateDelPop"
          className="AlertPOPUP  ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure?</b>
              </p>
              <p className="small">You want to delete this template?</p>
              <div id="errorAlertTemplate2"></div>
              <div id="successAlertTemplate2"></div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button
                    className="CnclBtn"
                    onClick={this.hideTemplateDeletePop}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="TemplateDelete"
                    onClick={this.DeleteTemplate}
                  >
                    Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* reinitiate popup */}
        <div
          id="reinitiate"
          className="quickModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline">Quick Message</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div id="errorAlert2"></div>
            <div className="uk-grid" uk-grid="">
              <div className="uk-width-1-2@m uk-width-1-1@s">
                <div className="resolved_content">
                  <input
                    type="number"
                    class="uk-input uk-margin"
                    placeholder="Enter Phone No"
                    id="phone_no"
                    name="phone_no"
                  />
                  <select
                    class="uk-select"
                    id="suggested_msgs_pop"
                    name="suggested_msgs_pop"
                    onChange={this.onMessageSelectionForQuickMessage}
                  >
                    <option>Select Message</option>
                  </select>
                  {this.state.typeExist !== "" ? (
                    <>
                      <div class="js-upload uk-placeholder uk-text-center uk-margin-remove-bottom">
                        <span uk-icon="icon: cloud-upload"></span>
                        <div uk-form-custom>
                          <input
                            type="file"
                            name="media"
                            id="myfile"
                            onChange={this.handleFile}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        class="uk-input uk-margin-top"
                        placeholder="Enter File Name"
                        id="fileName"
                        name="fileName"
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.paramArrayForQuickMessage.length == 0
                    ? ""
                    : this.state?.paramArrayForQuickMessage?.map(
                        (val, index) => {
                          return (
                            <div className="uk-margin" key={index}>
                              <label
                                className="uk-form-label"
                                htmlFor="template"
                              >
                                Param {index + 1}
                              </label>
                              <input
                                type="text"
                                class="uk-input"
                                placeholder={`Enter Param ${index + 1}`}
                                id={`param${index}business`}
                                name={`param${index}`}
                                onChange={(e) =>
                                  this.handleParamChangeForQuickMessage(
                                    e,
                                    index
                                  )
                                }
                              />
                            </div>
                          );
                        }
                      )}

                  <div id="paramFields"></div>
                  <div id="errorAlertQuick"></div>
                  <div id="successAlertQuick"></div>
                  <form
                    className="uk-form-stacked uk-margin-medium-top"
                    action="javascript:void(0);"
                  >
                    <div className="ModalStyleBtns uk-float-right">
                      {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                      <button
                        className="AddTeamBtn"
                        id="AddTeamBtn"
                        onClick={this.confirmReinitaite}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="uk-width-1-2@m uk-width-1-1@s">
                <div className="msg_preview">
                  <h2>Preview</h2>
                  <div className="msg_box">
                    {/* <h6>{this.state.previewHeader}</h6> */}
                    <p>{this.state.messageArrayForQuickMessage.join(" ")}</p>
                    {/* <p>{this.state.previewFooter}</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Show Template Delete popup */}
        <div
          id="TemplateAppPop"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure?</b>
              </p>
              <p className="small">You want to Approved this template?</p>
              <div id="errorAlertTemplate2"></div>
              <div id="successAlertTemplate2"></div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.hideTemplateAppPop}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="TemplateDelete"
                    onClick={this.ApprovedTemplate}
                  >
                    Approved
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* when field upload design end */}
      </Content>
    );
  }
}
export default Broadcasting;
