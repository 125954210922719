import * as React from "react";
import * as Spectrum from "@adobe/react-spectrum";

const SpectrumProvider = process.env.SSR_ENABLED
  ? Spectrum.SSRProvider
  : Spectrum.Provider;

export const AppProviders = (props: AppProvidersPropsT) => {
  return (
    <SpectrumProvider  theme={Spectrum.defaultTheme}>
      {props.children}
    </SpectrumProvider>
  );
};

type AppProvidersPropsT = {
  children: React.ReactNode;
};