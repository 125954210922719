import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import $ from "jquery";
import Constant from "../../../App/Constant";
import IntegrationsSidebar from "../../Common/IntegrationsSidebar";
import axios from "axios";
import { AddAlt, Close, Unlink, TextLink, Settings } from "@carbon/icons-react";
class WA_Integrate extends React.Component {
  showDisconnect = (mobile, name, id) => {
    $("#txtConfirmDisconnect").val("");
    this.setState({ companyMobile: mobile, companyName: name, channel_id: id });
    $("#disconnectAcountModal").addClass("uk-flex uk-open");
  };

  hideDisconnect = () => {
    this.setState({ companyMobile: "", companyName: "", channel_id: 0 });
    $("#disconnectAcountModal").removeClass("uk-flex uk-open");
  };

  showConnect = (mobile, name, id) => {
    $("#txtConfirmConnect").val("");
    this.setState({ companyMobile: mobile, companyName: name, channel_id: id });
    $("#ConnectAcountModal").addClass("uk-flex uk-open");
  };

  hideConnect = () => {
    this.setState({ companyMobile: "", companyName: "", channel_id: 0 });
    $("#ConnectAcountModal").removeClass("uk-flex uk-open");
  };

  addAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    if ($("input#termsPolicy").is(":checked")) {
      let c_code = $("#country_code").val();
      let number = $("#whatsapp_number").val();
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("c_code", c_code);
      compParams.append("number", number);
      compParams.append("type", "whatsapp");
      compParams.append("name", "Convex");
      axios({
        method: "POST",
        url: Constant.url + "add-company-channel",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          $("#termsPolicy").prop("checked", false);
          $("#whatsapp_number").val("");
          classRef.companyChannelsApi();
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
        }
      });
    } else {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please agree Terms & conditions before send request.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    }
  };

  constructor() {
    super();
    this.companyChannelsApi = this.companyChannelsApi.bind(this);
    this.handleChannels = this.handleChannels.bind(this);
    this.state = {
      accountList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      companyMobile: "",
      companyName: "",
      companyId: 0,
    };
  }

  companyChannelsApi = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let compParams = new FormData();
    compParams.append("company_id", company_id);
    axios({
      method: "POST",
      url: Constant.url + "company-channels",
      data: compParams,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          accountList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  componentDidMount() {
    this.companyChannelsApi();
  }

  connectAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let txtConfirm = $("#txtConfirmConnect").val();
    let channel_id = classRef.state.channel_id;
    if (txtConfirm != "" && txtConfirm == "connect") {
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("channel_id", channel_id);
      compParams.append("is_active", "yes");
      axios({
        method: "POST",
        url: Constant.url + "change-channel-status",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successConnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            $("#ConnectAcountModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
          classRef.companyChannelsApi();
        } else {
          $("#errorConnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
        }
      });
    } else {
      $("#errorConnectAlert").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please type connect in input field.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  disconnectAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let txtConfirm = $("#txtConfirmDisconnect").val();
    let channel_id = classRef.state.channel_id;
    if (txtConfirm != "" && txtConfirm == "disconnect") {
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("channel_id", channel_id);
      compParams.append("is_active", "no");
      axios({
        method: "POST",
        url: Constant.url + "change-channel-status",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successDisconnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            $("#disconnectAcountModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
          classRef.companyChannelsApi();
        } else {
          $("#errorDisconnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
        }
      });
    } else {
      $("#errorDisconnectAlert").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please type disconnect in input field.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  handleChannels = () => {
    let result = "";
    let index = 0;

    result = this.state.accountList.list.map((response, index) => (
      <tr>
        <td width={90}>
          <img className="uk-border-circle" src="/images/whatsapp.png" alt="" />
        </td>
        <td>
          <h5>{response.name}</h5>
          <p>{response.c_code + response.number}</p>
        </td>
        <td width={100}>
          <span className="status">{response.status}</span>
        </td>
        <td width={100}>
        <Link to={"/integrations/whatsapp"}> <Settings /> Settings</Link>
        </td>
        <td width={150}>
          {response.is_active == "Disconnected" ? (
            <button
              onClick={(e) =>
                this.showConnect(
                  response.c_code + response.number,
                  response.name,
                  response.id
                )
              }
              className="disconnect success"
            >
              <TextLink />
              Disconnected
            </button>
          ) : (
            <button
              onClick={(e) =>
                this.showDisconnect(
                  response.c_code + response.number,
                  response.name,
                  response.id
                )
              }
              className="disconnect danger"
            >
              <Unlink />
              Connected
            </button>
          )}
        </td>
      </tr>
    ));
    return result;
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>
                  Integrations <span> {">"} Whatsapp</span>
                </h3>
              </div>
            </div>
          </div>
          <IntegrationsSidebar />
          <div className="integration inner h_match">
            <div className="uk-flex-top uk-grid">
              <div className="uk-width-auto@m uk-flex-first">
                <img className="Fimg" src="/images/whatsapp.png" alt="" />
              </div>
              <div className="uk-width-3-4">
                <h3>WhatsApp</h3>
                <p>We integrates directly with the WhatsApp Business API allowing you to start collecting contacts in a few simple steps. You can select to either use your own new phone number or have one given to you us.</p>

                {/**ADD NUM */}
                <div className="addnum_box uk-margin-medium-top">
                  <h4>Enter your WhatsApp or WhatsApp Business number.</h4>
                  <div className="uk-grid uk-grid-collapse">
                    <div className="uk-width-auto">
                      <div className="fields select_box">
                        <label className=" uk-form-label">Code</label>
                        <select className="uk-select" id="country_code">
                          <option>+92</option>
                        </select>
                      </div>
                    </div>
                    <div className="uk-width-expand">
                      <div className="fields">
                        <TextField
                          width="100%"
                          marginTop="size-0"
                          maxWidth="100%"
                          label="WhatsApp number"
                          id="whatsapp_number"
                          type="text"
                          scale="medium"
                          isRequired
                          necessityIndicator="icon"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="fields uk-margin-top">
                    <div className="uk-grid uk-grid-collapse uk-child-width-1-1">
                      <div className="">
                        <input
                          type="checkbox"
                          id="termsPolicy"
                          name="termsPolicy"
                        />
                        <label for="termsPolicy">
                          {" "}
                          I agree to the Berry Talks <a>Terms & conditions</a> and <a>Privacy policy</a>
                        </label>
                        {/* <Checkbox isEmphasized>I agree to the Terms & conditions and Privacy policy</Checkbox> */}
                      </div>
                    </div>
                  </div>
                  <div id="errorAlert"></div>
                  <div id="successAlert"></div>
                  <div className="fields uk-margin-top spStyle">
                    <Button
                      className="signinBtn"
                      id=""
                      position="relative"
                      marginTop="size-200"
                      right=""
                      maxWidth="size-1400"
                      variant="cta"
                      onClick={this.addAccount}
                    >
                      Connect WhatsApp
                    </Button>
                  </div>
                </div>

                {/**ADD NUM */}

                {/**Connected */}
                <div className="connected" id="connected">
                  <h4>WhatsApp Account</h4>
                  <div className="AccountCampain">
                    <table class="uk-table uk-table-middle">
                      {this.state.accountList.isLoaded
                        ? this.handleChannels()
                        : ""}
                    </table>
                    </div>
                    
                  {/* <div className="uk-width-1-1 uk-margin-medium-top">
                    <button className="addRowBtn">
                      {" "}
                      <span>
                        {" "}
                        <AddAlt />
                      </span>{" "}
                      Add another
                    </button>
                  </div> */}
                </div>
                <div>
                      <p>You need to disconnect this account if you want to connect another WhatsApp account to Berry Talks.</p>
                    </div>
                {/**Connected */}
              </div>
            </div>
          </div>
        </div>

        {/* Disconnected Popup */}
        <div
          id="disconnectAcountModal"
          className="disconectAcount ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              Disconnect WhatsApp Account{" "}
              <span className="uk-inline uk-float-right">
                <img src="/images/warning--alt--filled.png" alt="" />
              </span>
            </h3>
            <p className="">
              <b>
                Are you sure you want to disconnect this WhatsApp Account from
                telenor WABA
              </b>
            </p>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-flex-middle uk-grid uk-grid-small ">
                <div className="uk-width-auto uk-flex-first">
                  <img className="uk-border-circle" src="/images/whatsapp.png" alt="" />
                </div>
                <div className="uk-width-expand">
                  <h5>{this.state.companyName}</h5>
                  <p>{this.state.companyMobile}</p>
                </div>
              </div>
              <div className="uk-margin">
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="txtConfirmDisconnect"
                    name="txtConfirmDisconnect"
                    type="text"
                    placeholder="Confirm by typing disconnect here"
                  />
                </div>
              </div>
              <div id="errorDisconnectAlert"></div>
              <div id="successDisconnectAlert"></div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.hideDisconnect}>
                    Cancel
                  </button>
                  <button className="confirm" onClick={this.disconnectAccount}>
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Connected Popup */}
        <div
          id="ConnectAcountModal"
          className="disconectAcount ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              Connect WhatsApp Account{" "}
              <span className="uk-inline uk-float-right">
                <img src="/images/warning--alt--filled.png" alt="" />
              </span>
            </h3>
            <p className="">
              <b>
                Are you sure you want to connect this WhatsApp Account from
                telenor WABA
              </b>
            </p>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-flex-middle uk-grid uk-grid-small ">
                <div className="uk-width-auto uk-flex-first">
                  <img className="uk-border-circle" src="/images/whatsapp.png" alt="" />
                </div>
                <div className="uk-width-expand">
                  <h5>{this.state.companyName}</h5>
                  <p>{this.state.companyMobile}</p>
                </div>
              </div>
              <div className="uk-margin">
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="txtConfirmConnect"
                    name="txtConfirmConnect"
                    type="text"
                    placeholder="Confirm by typing connect here"
                  />
                </div>
              </div>
              <div id="errorConnectAlert"></div>
              <div id="successConnectAlert"></div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.hideConnect}>
                    Cancel
                  </button>
                  <button className="confirm" onClick={this.connectAccount}>
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}
export default WA_Integrate;
