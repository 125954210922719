import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import {
  CaretDown,
  Checkmark,
  Add,
  Edit,
  TrashCan,
  OverflowMenuVertical,
  Close,
} from "@carbon/icons-react";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";

class Agents extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.handleRoles = this.handleRoles.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);

    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      role: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      first_name: "",
      roles: "",
      phone: "",
      email: "",
      agent_id: "",

      //  advancesearch:"",
    };
  }

  addconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  componentDidMount() {
    this.callApi();
    this.getRoles();
  }

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "agents",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  getRoles = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "roles",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          role: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleRoles = () => {
    let result = "";
    let index = 0;
    result = this.state.role.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  deleteAgent = () => {
    var id = $("#addconsent").val();
    let classRef = this;
    var agent_id = id;
    var company_id = localStorage.getItem("company_id");
    var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "delete-agent",
      data: {
        company_id: company_id,
        agent_id: agent_id,
        is_deleted: is_deleted,
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/agents";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/agents";
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr id={"contact_" + response.id}>
        <td>
          <label>
            <input
              className="uk-checkbox autoSelect"
              name="multi_check[]"
              value={response.id}
              type="checkbox"
            />
          </label>
          <input
            className="star"
            type="checkbox"
            title="bookmark page"
            checked={response.is_star == 1 ? true : false}
          />
        </td>
        <td>
          <div className="avatarIcon">
            <img src={response.avatar} alt="Image" />
          </div>
        </td>
        <td>
          <span>{response.agent_name}</span>
        </td>
        <td>{response.phone}</td>
        <td>
          <Link to="">{response.email}</Link>
        </td>

        <td>{response.role}</td>
        <td>{response.team}</td>
        <td>
          <button className="ctmBtn infoBtn">{response.status}</button>
        </td>
        <td>{response.contact_time}</td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.EditAgentModal(response.id)}><Edit /></button></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addconsent(response.id)}><TrashCan /></button></td>
      </tr>
    ));
    return result;
  };

  addAgent = () => {
    $("#addAgent").text("Processing");
    $("#addAgent").prop("disabled", true);
    let classRef = this;
    var data = {};
    data = {
      role: $("#role").val(),
      full_name: $("#full_name").val(),
      phone: $("#phone").val(),
      email: $("#email").val(),
      password: $("#password").val(),
      password_confirmation: $("#password_confirmation").val(),
      invitation: "no",
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "add-agent",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/agents";
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#addAgent").text("Add Team");
          $("#addAgent").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  edittingAgent = () => {
    // console.log("Javevevevev");

    $("#editAgent").text("Processing");
    $("#editAgent").prop("disabled", true);
    let classRef = this;

    var data = {};
    data = {
      role: $("#new_role").val(),
      first_name: $("#new_full_name").val(),
      phone: $("#new_phone").val(),
      email: $("#new_email").val(),
      password: $("#new_password").val(),
      agent_id: $("#agent_id").val(),
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-agents",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/agents";
        } else {
          $("#editerrorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#editAgent").text("Edit Team");
          $("#editAgent").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  EditAgentModal = (id) => {
    $("#EditAgentsModal").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findagent(id);
  };

  findagent = (id) => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let agent_id = id;
    axios({
      method: "POST",
      url: Constant.url + "single-agent",
      data: { company_id: company_id, agent_id: agent_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var agent = result.data.data;
        classRef.setState({
          first_name: agent.first_name,
          roles: agent.role,
          phone: agent.phone,
          email: agent.email,
          agent_id: agent.id,
        });
      }
    });
  };

  handleFirstNameChange(event) {
    this.setState({ first_name: event.target.first_name });
    // console.log("first name updated");
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.email });
  }

  handlePhoneChange(event) {
    this.setState({ phone: event.target.phone });
  }

  handleRoleChange(event) {
    this.setState({ roles: event.target.roles });
  }

  CloseeditAgentModal = () => {
    $("#EditAgentsModal").removeClass("uk-flex uk-open");
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  addAgentModal = () => {
    $("#AddAgentsModal").addClass("uk-flex uk-open");
  };

  closeAgentModal = () => {
    $("#AddAgentsModal").removeClass("uk-flex uk-open");
  };

  closeBulk = () => {
    $("#BulkDelete").removeClass("uk-flex uk-open");
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    this.setState({ advancesearch: searchword });
    axios({
      method: "POST",
      url: Constant.url + "agents",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  selectCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  bulkConsent = () => {
    $("#BulkDelete").addClass("uk-flex uk-open");
  };

  bulkDelete = () => {
    this.deleteMultiContact();
  };

  deleteMultiContact = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#contact_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "delete-bulk-agentss",
      data: {
        company_id: localStorage.getItem("company_id"),
        agent_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        window.location.reload();
      }
    });
  };

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callApi();
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  advanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      role: $("#search_role").val(),
      status: $("#status").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.role) {
      var key = key + " Role: " + $("#search_role :selected").text();
    }
    if (data.status) {
      var key = key + " Status: " + $("#status :selected").text();
    }

    classRef.setState({ advancesearch: key });

    axios({
      method: "POST",
      url: Constant.url + "agents",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });

        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Agents</h3>
              </div>
              <div className="uk-width-1-3">
                <div className="search_field">
                  <form>
                    <input
                      className="uk-input"
                      onChange={this.searchrecord}
                      name="searchword"
                      id="searchword"
                      value={this.state.advancesearch}
                      type="text"
                      placeholder="Search agents through names"
                    />
                  </form>

                  <div class="CampaignReports Filtermain">
                    <div className="uk-width-auto">
                      <button
                        style={{ right: "30px" }}
                        onClick={this.advSearchClear}
                      >
                        <Close />
                      </button>
                      <button onClick={this.addSearchModal}>
                        <img src="/images/newfilter.svg" uk-svg />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-width-expand">
                <button
                  className="newFlowBtn uk-float-right"
                  onClick={this.addAgentModal}
                >
                  <Add /> <span>Add Agent</span>
                </button>
              </div>
            </div>
          </div>
          <div className="tableSec ">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-small uk-table-divider CustomTableWidth">
                <thead>
                  <tr>
                    <th>
                      <label>
                        <input
                          className="uk-checkbox"
                          type="checkbox"
                          id="select_all"
                          onClick={this.selectCheckbox}
                        />
                      </label>
                      <div className="uk-inline drpIconBox">
                        <button className="iconTble" type="button">
                          <CaretDown />
                        </button>
                        <div uk-dropdown="mode: click">
                          <ul className="uk-nav uk-dropdown-nav">
                            {/* <li>
                            <a href="#">Delete</a>
                          </li> */}

                            <li>
                              <a onClick={this.deleteMultiContact}>
                                Delete Selected Rows
                              </a>
                            </li>

                            {/* <li>
                            <a href="#">Remove Tag</a>
                          </li>
                          <li>
                            <a href="#">Subscribe to Sequence</a>
                          </li>
                          <li>
                            <a href="#">Unsubscribe from Sequence</a>
                          </li>
                          <li>
                            <a href="#">Set Custom Field</a>
                          </li>
                          <li>
                            <a href="#">Clear contact Custom Field</a>
                          </li>
                          <li>
                            <a href="#">Clear contact Custom Field</a>
                          </li>
                          <li>
                            <a href="#">Unsubscribe from Account</a>
                          </li> */}
                          </ul>
                        </div>
                      </div>
                    </th>
                    <th>AVATAR</th>
                    <th>CONTACT NAME</th>
                    <th>PHONE</th>
                    <th>EMAIL</th>
                    <th>
                      <div className="uk-inline drpDownBtnBox">
                        <a href="#">ROLE</a>
                        {/* <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li className="active">
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                        </ul>
                      </div> */}
                      </div>
                    </th>
                    <th>
                      <div className="uk-inline drpDownBtnBox">
                        <a href="#">DEPARTMENT</a>
                        {/* <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li className="active">
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                          <li>
                            <button>
                              <div className="drpDwnList">
                                <img src="/images/avatar.jpg" alt="Image" />
                                <span>Usman Ramnani</span>
                                <Checkmark />
                              </div>
                            </button>
                          </li>
                        </ul>
                      </div> */}
                      </div>
                    </th>
                    <th>
                      <div className="uk-inline drpIconBox">
                        <a href="#">STATUS</a>
                        {/* <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li>
                            <a href="#">All</a>
                          </li>
                          <li>
                            <a href="#">Open</a>
                          </li>
                          <li>
                            <a href="#">In Progress</a>
                          </li>
                          <li>
                            <a href="#">Need Details</a>
                          </li>
                          <li>
                            <a href="#">Researching</a>
                          </li>
                          <li>
                            <a href="#">Resolved</a>
                          </li>
                          <li>
                            <a href="#">Expired</a>
                          </li>
                          <li>
                            <a href="#">Closed</a>
                          </li>
                          <li>
                            <a href="#">Canceled</a>
                          </li>
                        </ul>
                      </div> */}
                      </div>
                    </th>
                    <th>LAST LOGIN</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* {(this.state.dataList.isLoaded) ? this.handleTable() : ""} */}
                  {/* {(this.state.dataList.isLoaded) ? this.handleTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>}
                   */}
                  {this.state.dataList.isLoaded ? (
                    this.handleTable()
                  ) : this.state.dataList.msg == "not" ? (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          Data not Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          <div className="mdl">
                            <div className="mdl_inner">
                              <div uk-spinner=""></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          id="AddAgentsModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Add agent</h3>

            <div id="errorAlert"></div>
            <div id="successAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Role
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="role">
                    {this.state.role.isLoaded ? this.handleRoles() : ""}
                  </select>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Full name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="full_name"
                    name="full_name"
                    type="text"
                    placeholder="Full legal name"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Phone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Email address
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="name@example.com"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Password
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Minimum 8 characters"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Confirm password
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    placeholder="Minimum 8 characters"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label>
                  <input
                    className="uk-checkbox"
                    id="invitation"
                    name="invitation"
                    type="checkbox"
                    checked
                  />{" "}
                  Send an email invite
                </label>
                <p className="notice">
                  An invite can be sent later if you're not ready
                </p>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeAgentModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addAgent"
                    onClick={this.addAgent}
                  >
                    Add agent
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="EditAgentsModal"
          className="EditAgentsModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit agent</h3>

            <div id="editerrorAlert"></div>
            <div id="editsuccessAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Role
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="new_role"
                    value={this.state.roles}
                    onChange={this.handleRoleChange}
                  >
                    {this.state.role.isLoaded ? this.handleRoles() : ""}
                  </select>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Full name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="agent_id"
                    value={this.state.agent_id}
                    name="agent_id"
                    type="hidden"
                  />
                  <input
                    className="uk-input"
                    id="new_full_name"
                    value={this.state.first_name}
                    onChange={this.handleFirstNameChange}
                    name="full_name"
                    type="text"
                    placeholder="Full legal name"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Phone
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="new_phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handlePhoneChange}
                    type="text"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Email address
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="new_email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    type="text"
                    placeholder="name@example.com"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Password
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="new_password"
                    name="password"
                    type="password"
                    placeholder="Minimum 8 characters"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Confirm password
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    placeholder="Minimum 8 characters"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label>
                  <input
                    className="uk-checkbox"
                    id="invitation"
                    name="invitation"
                    type="checkbox"
                    checked
                  />{" "}
                  Send an email invite
                </label>
                <p className="notice">
                  An invite can be sent later if you're not readyssss
                </p>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.CloseeditAgentModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="editAgent"
                    onClick={this.edittingAgent}
                  >
                    Edit agent
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Agent?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsent"
                    onClick={this.deleteAgent}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="BulkDelete"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to Bulk delete Agent?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeBulk}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="bulkconsent"
                    onClick={this.bulkDelete}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="to_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="from_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Role
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="search_role">
                    <option value="" disabled selected>
                      Select Role
                    </option>
                    {this.state.role.isLoaded ? this.handleRoles() : ""}
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Status
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="status">
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="1">Online</option>
                    <option value="0">Offline</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}
export default Agents;
