import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import axios from 'axios';
import Constant from "../../App/Constant";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  let dummyData = {

    labels: [] ,
    datasets: [
      {
        label: 'Dataset 1',
        data: [],
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Dataset 2',
        data:[],
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Dataset 3',
        data: [],
        backgroundColor: 'rgb(53, 162, 235)',
      },
    ],
  
  }

  export const StackChart = () => {
    const [graphData, setGraphData] = useState(dummyData);

    useEffect(() => {
      formValidation();

  }, []);

  const formValidation = async () => {

    axios({
        method: 'POST',
        url: Constant.url+"month-wise_ticket-statuses",
        data: {company_id:localStorage.getItem('company_id')}  
    }).then(result =>{

      var stats = result.data.data;
      setGraphData({
        labels : stats.month,
        datasets: [
          {
            label: 'Open Ticket',
            data: stats.open_ticket,
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'Closed By Bot',
            data: stats.closed_by_bot,
            backgroundColor: 'rgb(75, 192, 192)',
          },
          {
            label: 'Closed By Agent',
            data: stats.closed_by_agent,
            backgroundColor: 'rgb(53, 162, 235)',
          },
        ],

      })

      
    }).catch(err => {

      // console.log(err)
            
    });


}

    const options = {
      plugins: {
        title: {
          display: true,
          // text: 'Chart.js Bar Chart - Stacked',
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    return <Bar options={options} data={graphData} />;

  }







