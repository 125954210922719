import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content} from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Select from 'react-select'
import axios from 'axios';
import $ from 'jquery';
import Constant from "../../../App/Constant";

import {
  CaretDown,
  Checkmark,
  TrashCan,
  Add,
  AddAlt,
  Edit, 
  ArrowRight,
  OverflowMenuVertical,
  Close
} from "@carbon/icons-react";

class Webhook extends React.Component {

  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTokenChange = this.handleTokenChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.state = {
      dataList: {
          isLoaded: false,
          msg: 'Loading...',
          list: []
      },
      inc: 0,
      webhook_name:"",
      url:"",
      verification_token:"",
      webhook_id:"",


      }


  }


  addRow = () =>{

    this.setState({inc: this.state.inc + 1})
    var x = this.state.inc;

    if(x < 5){ 
   

      $('#field_wrapper').append('<div class=" uk-margin dynamic_row"><label class="uk-form-label" htmlFor="">Headers</label><div class="uk-grid uk-grid-small"><div class="uk-width-expand uk-form-controls"><input type="text" id="headername" name="headername[]" class="uk-input" placeholder="Enter key" /></div><div class="uk-width-expand uk-form-controls"><input type="text" id="headerValue" name="headerValue[]" class="uk-input" placeholder="Enter value" /></div><div class="uk-width-auto"><button class="deleteBtn"><svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true"><path d="M12 12H14V24H12zM18 12H20V24H18z"></path><path d="M4 6V8H6V28a2 2 0 002 2H24a2 2 0 002-2V8h2V6zM8 28V8H24V28zM12 2H20V4H12z"></path></svg></button></div></div></div>'); //Add field html
     
      }
      
}


handleNameChange(event) {
  this.setState({ webhook_name: event.target.webhook_name });
  // console.log("first name updated")
}

handleTokenChange(event){
  this.setState({ verification_token: event.target.verification_token });
}


handleUrlChange(event){
  this.setState({ url: event.target.url });
}



EditWebhooks = () => {    

  // console.log('Javevevevev')

  $('#editwebhook').text('Processing')
  $('#editwebhook').prop('disabled', true);  

  let classRef = this;

  var data = {};
  data = {
    webhook_name: $("#new_webhookName").val(), 
    url: $("#new_url").val(),
    verificationToken: $("#new_verificationToken").val(),
    webhook_id: $("#webhook_id").val(),
    company_id:localStorage.getItem('company_id')
  }

  axios({
    method: 'POST',
    url: Constant.url + "edit-webhook",
    data: data
  }).then(function (response) {

    if (response.data.code === 200) {

      $("#editsuccessAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');

      // window.location.href = "/webhook";
    }else{

      $("#editerrorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
      $('#editwebhook').text('Edit Tag')
      $('#editwebhook').prop('disabled', false);
      setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
      return;
  

    }
  }).then(function (response) {

  });


};


closeConsent = () =>{
  $("#AddResolved").removeClass('uk-flex uk-open');
}







  componentDidMount() {
    $("#field_wrapper").on('click','.deleteBtn',function(){
      $(this).parent().parent().parent().remove();
    });

    this.callApi();

  //   setTimeout(function(){
  //     var maxField = 10; //Input fields increment limitation
  //     var addButton = $('.simpleAddBtn'); //Add button selector
  //     var wrapper = $('.field_wrapper'); //Input field wrapper         
  //     var x = 0; //Initial field counter is 1
      
  //     //Once add button is clicked
      
      
  //     //Once remove button is clicked
  //     // $(wrapper).on('click', '.deleteBtn', function(e){
  //     //     e.preventDefault();
  //     //     $(this).parent('div').parent('div').parent('div').parent('div').remove(); //Remove field html
  //     //     x--; 
  //     // });
      
  // },1000)

  }


  
  EditWebhookModal = (id) =>{
    $("#EditTagsModal").addClass('uk-flex uk-open');
    $('.uk-dropdown').removeClass('uk-open');
    this.findhook(id);
  }


  findhook = (id) =>{
    let classRef = this;
    let company_id = localStorage.getItem('company_id');
    let webhook_id = id
    axios({
      method: 'POST',
      url: Constant.url + "single-webhook",
      data: { company_id: company_id ,webhook_id :webhook_id }
    }).then(function (result) {
      if (result.data.status === true) {
        var hook = result.data.data
        classRef.setState({
          webhook_name:hook.webhook_name,
          url:hook.url,
          verification_token:hook.verification_token,
          webhook_id :hook.id,
        })
        
      }
    })
  }



  closeEditWebhookModal = () => {
    $("#EditTagsModal").removeClass('uk-flex uk-open');
  }



  addWebhookModal = () => {
  $("#AddTagsModal").addClass('uk-flex uk-open');
 }

 closeWebhookModal = () => {
  $("#AddTagsModal").removeClass('uk-flex uk-open');
}



  callApi = () => {

  let classRef = this;
  axios({
      method: 'POST',
      url: Constant.url + "webhooks",
      data: { company_id: localStorage.getItem('company_id') }
  }).then(function (result) {
      if (result.data.status === true) {
          classRef.setState({
              dataList: {
                  isLoaded: true,
                  msg: 'Loading...',
                  list: result.data.data
              },
          })
      }else{

        classRef.setState({
          dataList: {
              isLoaded: false,
              msg: 'not',
              list: []
          },
      })
      }
  })

}


addconsent = (id) =>{
  // console.log(id,"sajdain");
  $('.uk-dropdown').removeClass('uk-open');
  $("#AddResolved").addClass('uk-flex uk-open');
  $( "#addconsent" ).val(id)

}

deleteWebhook = () => {

  var id = $("#addconsent").val()
  let classRef = this;
  var webhook_id = id;
  var company_id = localStorage.getItem('company_id');
  var is_deleted = 1;

        axios({
          method: 'POST',
          url: Constant.url + "edit-webhook",
          data: { company_id: company_id ,webhook_id: webhook_id ,is_deleted: is_deleted}
      }).then(function (result) {
      
          if (result.data.code === 200) {
      
              $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
      
              window.location.href = "/webhook";
      
          }else{
      
              $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
      
              setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
  
              window.location.href = "/webhook";      
      
          }
             
      })

}

handleTable = () => {
        let result = "";
        let index = 0;
        result = this.state.dataList.list.map((response, index) => (

          <tr id={"web_"+response.id}>
          <td>
            <label>
            <input className="uk-checkbox autoSelect"  name="multi_check[]" value={response.id} type="checkbox" />
            </label>
            {/* <input
              className="star"
              type="checkbox"
              title="bookmark page"
              checked ={(response.is_star == 1 )? true : false}
            /> */}
          </td>
          <td>
          <span>{response.webhook_name}</span>
          </td>
          <td>
          <span>{response.url}</span>
          </td>
          <td>
            <span>{response.verification_token}</span>
          </td>
          <td>
            <button className="ctmBtn infoBtn">{response.status}</button>
          </td>
          <td>{response.created_at}</td>
          <td>{response.updated_at}</td>
          <td>
                                <div className="uk-inline drpIconBox">
                                    <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                                    <div uk-dropdown="mode: click">
                                        <ul className="uk-nav uk-dropdown-nav">
                                        <li>
                                        <li><button onClick= {(e)=>this.EditWebhookModal(response.id)}><span className="icon"><Edit /> </span>{" "}<span>Edit</span></button></li>
                                        </li>
                                        <li>
                                        <li class="hintAlert"><button onClick= {(e)=>this.addconsent(response.id)}><TrashCan />Delete</button></li>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
        </tr>

        ));

  return result;
}


createWebhook = () => {

  $('.addwebhook').text('Processing')
  $('.addwebhook').prop('disabled', true);

  let classRef = this;
  var data = {};
  const headername = [];
  const headerValue = [];

  var input = document.getElementsByName('headername[]'); 

  for (var i = 0; i < input.length; i++) { 
    headername.push(input[i].value);
  }

  var inputvalue = document.getElementsByName('headerValue[]');  

  for (var z = 0; z < inputvalue.length; z++) { 

    headerValue.push(inputvalue[z].value);
  }



  data = {
      company_id: localStorage.getItem('company_id'),
      webhook_name: $("#webhookName").val(),
      url: $("#url").val(),
      verificationToken: $("#verificationToken").val(),
      auth: $("#auth").val(),
      authPassword: $("#authPassword").val(),
      headername: headername,
      headerValue: headerValue,
      event_type: $("#webhookName").val(),

  }
  axios({
      method: 'POST',
      url: Constant.url + "add-webhook",
      data: data
  }).then(function (response) {

      if (response.data.code === 200) {

        $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');

        window.location.href = "/webhook";

      }else{

        $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
        $('.addwebhook').text('Save')
        $('.addwebhook').prop('disabled', false);
        return;
      }

  }).then(function (response) {

  });

};


searchrecord = () =>{   

  let classRef = this;        
  var searchword = $("#searchword").val();
  this.setState({ advancesearch: searchword});

  axios({
    method: 'POST',
    url: Constant.url + "webhooks",
    data: { company_id: localStorage.getItem('company_id'),search: searchword  }
}).then(function (result) {
    if (result.data.status === true) {
        classRef.setState({
            dataList: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data
            },
        })
    }else{

      classRef.setState({
        dataList: {
            isLoaded: false,
            msg: 'not',
            list: []
        },
    })
    }
})

  }


  deleteMultiSegment = () => {          
    var ids=[];
    $('input[type=checkbox]').each(function (i) { 
        if($(this).is(":checked")){
         $("#web_"+$(this).val()).hide();             
         ids[i] =$(this).val();
        }
    });
      
      let classRef = this;          
      axios({
          method: 'POST',
          url: Constant.url+"bulk-delete-webhook",
          data :{
            company_id :localStorage.getItem('company_id'),
            webhook_ids :ids            
          }                       
      }).then(function (result) {
          if(result.data.status === true){                          
             window.location.reload();                           
          }
      })      
}

selectCheckbox = () =>{
  if(!this.state.checkAll){
      this.setState({checkAll:true},function(){
          $('#select_all').prop('checked',true);  
          $('.autoSelect').prop('checked',true);  
      })
  }else{
      this.setState({checkAll:false},function(){
          $('#select_all').prop('checked',false);  
          $('.autoSelect').prop('checked',false);  
      })
  }
}



addSearchModal = () => {
  $("#SearchTeamModal").addClass('uk-flex uk-open');
}

closeSearchModal= () => {
  $("#SearchTeamModal").removeClass('uk-flex uk-open');
}


advanceSearch = () =>{  

  let classRef = this;
  var data = {};
  data = {
  dateto: $("#to_date").val(),
  datefrom: $("#from_date").val(),
  status: $("#status").val(),
  company_id: localStorage.getItem('company_id')        
  }

  var key =''

  if(data.dateto){
  var key = "Date To: "+$("#to_date").val()
  }
  if(data.datefrom){
    var key = key+" Date From: "+$("#from_date").val()
  }
  if(data.status){
    var key = key+" Status: "+$("#status :selected").text()
  }
  
  classRef.setState({ advancesearch: key})

  axios({
  method: 'POST',
  url: Constant.url + "webhooks",
  data: data
  }).then(function (result) {
      if (result.data.status === true) {
          classRef.setState({
              dataList: {
                  isLoaded: true,
                  msg: 'Loading...',
                  list: result.data.data
              },
          })
          $("#SearchTeamModal").removeClass('uk-flex uk-open');
      }else{
          classRef.setState({
            dataList: {
                isLoaded: false,
                msg: 'not',
                list: []
            },
        })
        $("#SearchTeamModal").removeClass('uk-flex uk-open');
        }


  });

}

advSearchClear = () => {
  let classRef = this;     
      classRef.setState({ advancesearch:""})
      classRef.callApi()
}



  render() {
   
    const options = [
        { value: 'Urgent', label: 'Urgent' },
        { value: 'Resolved', label: 'Resolved' }
      ]
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
                <div className=" uk-grid " uk-grid="">
                    <div className="uk-width-auto"><h3>Webhook</h3></div>
                    <div className="uk-width-1-3@m">
                        <div className="search_field">
                            <form>
                                <input className="uk-input" type="text" onChange={this.searchrecord} name="searchword" id="searchword" value={this.state.advancesearch}  placeholder="Search webhooks through name"/>
                            </form>
                            <div class="CampaignReports Filtermain">
                                        <div className="uk-width-auto">
                                        <button style={{right: "30px"}} onClick = {this.advSearchClear}><Close/></button>
                                            <button onClick = {this.addSearchModal}>
                                                <img src="/images/newfilter.svg" uk-svg/>
                                                {/* <Search onClick = {this.addSearchModal}/> */}
                                            </button>
                                        </div>
                              </div> 
                        </div>
                    </div>
                    
                    <div className="uk-width-expand@m">
                        <button className="newFlowBtn uk-float-right" onClick={this.addWebhookModal} ><Add /> <span>Add Webhook</span></button>
                    </div>
                </div>
            </div>
          <div className="tableSec">
            <div className="uk-overflow-auto">
            <table className="uk-table uk-table-small uk-table-divider webhookMobileRes">
              <thead>
                <tr>
                  <th>
                    <label>
                    <input className="uk-checkbox" type="checkbox" id="select_all"  onClick = {this.selectCheckbox}/>
                    </label>
                    <div className="uk-inline drpIconBox">
                      <button className="iconTble" type="button">
                        <CaretDown />
                      </button>
                      <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                        <li><a onClick={this.deleteMultiSegment}>Delete Selected Rows</a></li>
                          {/* <li>
                            <a href="#">Delete Selected Rows</a>
                          </li> */}
                          {/* <li>
                            <a href="#">Remove Tag</a>
                          </li>
                          <li>
                            <a href="#">Subscribe to Sequence</a>
                          </li>
                          <li>
                            <a href="#">Unsubscribe from Sequence</a>
                          </li>
                          <li>
                            <a href="#">Set Custom Field</a>
                          </li>
                          <li>
                            <a href="#">Clear contact Custom Field</a>
                          </li>
                          <li>
                            <a href="#">Clear contact Custom Field</a>
                          </li>
                          <li>
                            <a href="#">Unsubscribe from Account</a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </th>
                  <th>Name</th>
                  <th>URL</th>
                  <th>Verification Token</th>
                  {/* <th>EVENT TYPES</th> */}
                  <th>STATUS</th>
                  <th>
                    CREATED
                  </th>
                  <th>LAST UPDATED</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {/* {(this.state.dataList.isLoaded) ? this.handleTable() : ""} */}
                {/* {(this.state.dataList.isLoaded) ? this.handleTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>} */}
                
                {(this.state.dataList.isLoaded) ? this.handleTable() : (this.state.dataList.msg == 'not') ? <tr><td colSpan="15"><div className="uk-text-center uk-height-large">Data not Found</div></td></tr>: <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr> }

                {/* <tr>
                  <td>
                    <label>
                      <input className="uk-checkbox" type="checkbox" />
                    </label>
                    <input
                      className="star"
                      type="checkbox"
                      title="bookmark page"
                      defaultChecked
                    />
                  </td>
                  <td>
                  <span>
                    center.daraz.pk/manual/orders/webhook/payment.chargeback_notification</span>
                  </td>
                  <td>
                    <span>payment.chargeback_notification</span>
                  </td>
                  <td>
                    <button className="ctmBtn infoBtn">Active</button>
                  </td>
                  <td>17:44 (UTC+05)</td>
                  <td>17:44 (UTC+05)</td>
                </tr> */}
                
              </tbody>
            </table>
            </div>
           
          </div>
        </div>





                 <div id="AddTagsModal" className=" ModalStyle uk-flex-top" uk-modal="esc-close: false; bg-close: false">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button"> <Close /></button>
                        <h3>Create New Webhook</h3>
                        <div id="errorAlert"></div>
                        <div id="successAlert"></div>
                        <form className="uk-form-stacked" action="javascript:void(0);">
                        <div className="uk-margin">
                          <label className="uk-form-label" htmlFor="">Webhook name</label>
                          <div className="uk-form-controls">
                            <input type="text" id="webhookName" name="webhookName" className="uk-input" placeholder="Your webhook name (required)" />
                          </div>
                        </div>
                        <div className="uk-margin">
                          <label className="uk-form-label" htmlFor="">Webhook url</label>
                          <div className="uk-form-controls">
                            <input type="text" id="url" name="url" className="uk-input" placeholder="Your Webhook url (required)" />
                          </div>
                        </div>
                        <div className="uk-margin">
                          <label className="uk-form-label" htmlFor="">Verification token</label>
                          <div className="uk-form-controls">
                            <input type="text" id="verificationToken" name="verificationToken"  className="uk-input" placeholder="Verification token" />
                          </div>
                        </div>

                        {/* <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="">Basic Auth</label>
                            <div className="uk-grid uk-grid-small">
                              <div className="uk-width-2-3 uk-form-controls">
                                <input type="text" id="auth" name="auth" className="uk-input" placeholder="Enter username" />
                              </div>
                              <div className="uk-width-expand uk-form-controls">
                                <input type="password" id="authPassword" name="authPassword" className="uk-input" placeholder="Enter password" />
                              </div>
                            </div>
                        </div> */}

                        {/* <div className="uk-margin">
                            <div className="dynamic_row">
                            <label className="uk-form-label" htmlFor="">Headers</label>
                            <div className="uk-grid uk-grid-small">
                              <div className="uk-width-expand uk-form-controls">
                                <input type="text" id="headername" name="headername[]" className="uk-input" placeholder="Enter key" />
                              </div>

                              <div className="uk-width-expand uk-form-controls">
                                <input type="text" id="headerValue" name="headerValue[]" className="uk-input" placeholder="Enter value" />
                              </div>

                            </div>
                            </div>
                          </div>

                        <div className="uk-margin  " id="field_wrapper">

                        </div> 

                        <div className="uk-margin uk-width-1-1">
                              <button id="add_button" onClick={this.addRow} className="simpleAddBtn"><AddAlt /> Add Button</button>
                        </div> */}
                            
                        <div className="uk-margin">
                            <div className="ModalStyleBtns uk-margin-top">
                              <div className="uk-float-right">
                                <button className="CnclBtn" onClick={this.closeWebhookModal}>Cancel</button>
                                <button className="AddTeamBtn" id="addwebhook" onClick={this.createWebhook} >Save <ArrowRight /></button>
                              </div>
                            </div>
                        </div>
                        </form>

                    </div>
                </div>

                <div id="EditTagsModal" className=" ModalStyle uk-flex-top" uk-modal="esc-close: false; bg-close: false">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button"> <Close /></button>
                        <h3>Edit New Webhook</h3>
                        <div id="editerrorAlert"></div>
                        <div id="editsuccessAlert"></div>
                        <form className="uk-form-stacked" action="javascript:void(0);">
                        <div className="uk-margin">
                          <label className="uk-form-label" htmlFor="">Webhook name</label>
                          <div className="uk-form-controls">
                          <input className="uk-input" id="webhook_id" name="webhook_id" value={this.state.webhook_id} type="hidden" />
                            <input type="text" id="new_webhookName" name="new_webhookName" value={this.state.webhook_name} onChange={this.handleNameChange}  className="uk-input" placeholder="Your webhook name (required)" />
                          </div>
                        </div>
                        <div className="uk-margin">
                          <label className="uk-form-label" htmlFor="">Webhook url</label>
                          <div className="uk-form-controls">
                            <input type="text" id="new_url" name="new_url" className="uk-input" value={this.state.url} onChange={this.handleUrlChange}  placeholder="Your Webhook url (required)" />
                          </div>
                        </div>
                        <div className="uk-margin">
                          <label className="uk-form-label" htmlFor="">Verification token</label>
                          <div className="uk-form-controls">
                            <input type="text" id="new_verificationToken" name="new_verificationToken" value={this.state.verification_token}   onChange={this.handleTokenChange}  className="uk-input" placeholder="Verification token" />
                          </div>
                        </div>

                        {/* <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="">Basic Auth</label>
                            <div className="uk-grid uk-grid-small">
                              <div className="uk-width-2-3 uk-form-controls">
                                <input type="text" id="auth" name="auth" className="uk-input" placeholder="Enter username" />
                              </div>
                              <div className="uk-width-expand uk-form-controls">
                                <input type="password" id="authPassword" name="authPassword" className="uk-input" placeholder="Enter password" />
                              </div>
                            </div>
                        </div>

                        <div className="uk-margin">
                            <div className="dynamic_row">
                            <label className="uk-form-label" htmlFor="">Headers</label>
                            <div className="uk-grid uk-grid-small">
                              <div className="uk-width-expand uk-form-controls">
                                <input type="text" id="headername" name="headername[]" className="uk-input" placeholder="Enter key" />
                              </div>

                              <div className="uk-width-expand uk-form-controls">
                                <input type="text" id="headerValue" name="headerValue[]" className="uk-input" placeholder="Enter value" />
                              </div>

                            </div>
                            </div>
                          </div>

                        <div className="uk-margin  " id="field_wrapper">

                        </div> 

                        <div className="uk-margin uk-width-1-1">
                              <button id="add_button" onClick={this.addRow} className="simpleAddBtn"><AddAlt /> Add Button</button>
                        </div>                           */}
                            
                        <div className="uk-margin">
                            <div className="ModalStyleBtns uk-margin-top">
                              <div className="uk-float-right">
                                <button className="CnclBtn" onClick={this.closeEditWebhookModal}>Cancel</button>
                                <button className="AddTeamBtn" id="editwebhook" onClick={this.EditWebhooks} >Save <ArrowRight /></button>
                              </div>
                            </div>
                        </div>
                        </form>

                    </div>
                </div>


                <div id="AddRemoveModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="esc-close: false; bg-close: false">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button"> <Close /></button>
                        <h3>Remove tag</h3>
                        <form className="uk-form-stacked">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="teamName">Select tags to remove</label>
                                <div className="uk-form-controls">
                                    
                                <Select
                                        defaultValue={[options[0], options[1]]}
                                        isMulti
                                        name="tags"
                                        options={options}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                            </div>
                            
                            <div className="uk-margin">
                                <div className="ModalStyleBtns">
                                    <button className="AddTeamBtns">Save</button>
                                </div>
                                
                            </div>
                        </form>

                    </div>
                </div>

                 {/*----MODAL---*/}
        <div id="AddResolved" className="AlertPOPUP ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3 >
              <span className="uk-inline"> Warning</span> 
              <span className="uk-inline uk-float-right"> 
                  <img src="/images/done_all_black_24dp.svg" />{" "} 
              </span>
            </h3>
            <div className="resolved_content">
              <p><b>Are you sure you want to delete Wehook?</b></p>
              <p className="small">You won't be able to revert this!</p>

              <form className="uk-form-stacked uk-margin-medium-top" action="javascript:void(0);">
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>Cancel</button>
                  <button className="AddTeamBtn" id="addconsent" onClick={this.deleteWebhook}>Confirm</button>
                </div>
            </form>
            </div>
          </div>
        </div>


        <div id="SearchTeamModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="">
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button"> <Close /></button>
                    <h3>Advance Search</h3>
                    <form id="advsearchform" className="uk-form-stacked" action="javascript:void(0);">

                        <div className="uk-margin">
                            <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="ToDate">To Date</label>
                                    <div class="uk-form-controls">
                                        <input type="date" label="Select Broadcast" className="to_date" id="to_date"/>
                                        
                                    </div>
                            </div>

                            <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="FromDate">From Date</label>
                                    <div class="uk-form-controls">
                                        <input type="date" label="Select Broadcast" className="from_date" id="from_date"/>
                                    </div>
                            </div>
                        </div>
   
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">Status</label>
                            <div className="uk-form-controls">
                            <select className="uk-select" id="status">
                            <option value="" disabled selected >Select Status</option>
                            <option value="1" >Active</option>
                            <option value="0" >In Active</option>
                            </select>
                            </div>
                        </div>



                        <div className="uk-margin">
                            <div className="ModalStyleBtns CampaignBtn">
                                <button className="CnclBtn" onClick={this.closeSearchModal}>Cancel</button>
                                <button  className="AddTeamBtn" id="addteam" onClick={this.advanceSearch}>Search</button>
                            </div>
                        </div>
                    </form>

                </div>
                </div>
                
      </Content>
    );
  }
}
export default Webhook;
