import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { CutOut, RotateCounterclockwiseAltFilled } from '@carbon/icons-react';

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChatBotSessionExpireChart = () => {

     const data = {
        labels: ['Expired session', 'Agent takeover', 'Go to Agent action', 'Close session action'],
        datasets: [
          {
            label: 'Open Live Chats',
            data: [48, 12, 20, 20],
            backgroundColor: [
              '#1F73B7',
              '#96CCF9',
              '#F6C176',
              '#1DB954',
              
            ],
            borderColor:[
              '#1F73B7',
              '#96CCF9',
              '#F6C176',
              '#1DB954',
              
            ],
           
            borderWidth: 1,
            cutout:'90%',
          },
        ],
      };
      const options = {
        aspectRatio:1.5,
        plugins : {
        legend: {
          display: true,
          position: "right",
          
          // align: 'end',
          labels:{
            // boxWidth: 10,
            boxHeight: 4,
            usePointStyle:true,
            pointStyle: 'circle',
            pointStyleWidth	:5,
            pointStyleHeight: 5,
            
          }
        }
        }
      };

      const plugins = [{
        beforeDraw: function(chart) {
         var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
            ctx.restore();
            
            ctx.font = "36px sans-serif";
            ctx.textBaseline = "top";
            var text = "11/38";
            var text1 = "Open Live Chats",
            textX = Math.round((width - ctx.measureText(text).width) / 2)-70,
            textY = height / 2.5;
            ctx.fillStyle = 'rgb(75, 75, 75)';
            ctx.fillText(text, textX, textY);
            ctx.font = "16px sans-serif";
            ctx.fillStyle = '#8E8E8E';
            ctx.fillText(text1, Math.round((width - ctx.measureText(text).width) / 2)-115, textY+40);
            ctx.save();
        }
      }]
      

  return (
    <div>
      
         <Doughnut 
            data={data}   
            options={options}
            plugins={plugins}
            // plugins={[textCenter]} 
            />
    </div>
  )
}
