import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { AgentStatusChart } from "../../Sections/AgentStatusChart";
import { AssignLiveChat } from "../../Sections/AssignLiveChat";
import { DateRange } from "../../Common/DateRange";
import { LiveChatOverviewResolutionTIme } from "../../Sections/LiveChatOverviewResolutionTIme";


  
class LiveChatOverview extends React.Component {
    MultiSelectFilter =()=>{
        $(".UltabsBtns").toggleClass("showMenu");
        $(".UltabsBtns > li label").click(function(){
            $(".MultiSelectFilter > p span").text($(this).text());
        });
    }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Live Chats</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">

                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>All Channels</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>
                {/* Live chats highlights */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Breakdown by live chats 
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Breakdown by conversations</h5>
                                        <p>Shows the throughput of conversations that match the selected channel, queue, and were active in the selected period.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>340</h3>
                                <p>Old
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Old conversations</h5>
                                            <p>The number of conversations that were created before the selected period of time and active during it.</p>
                                        </div>
                                    </div>
                                </p>
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>273</h3>
                                <p>New
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>New conversations</h5>
                                            <p>The number of conversations that were created in the selected period of time.</p>
                                        </div>
                                    </div>
                                </p>
                                
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>2,751</h3>
                                <p>Closed
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Closed conversations</h5>
                                            <p>The number of conversations that were closed in the selected period of time.</p>
                                        </div>
                                    </div>
                                </p>
                                
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>318</h3>
                                <p>Not Closed/Unsolved
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Not Closed/Unsolved</h5>
                                            <p>The number of conversations that were not closed in the selected period of time.</p>
                                        </div>
                                    </div>
                                </p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service-level agreemant*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Service-level agreemant 
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>7</h3>
                                <p>First response time breach</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1</h3>
                                <p>Next replay time breach</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>0</h3>
                                <p>Total resolution time breach</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Breakdown by live chats*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Breakdown by live chats
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>28</h3>
                                <p>Total</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>11</h3>
                                <p>Missed</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>16</h3>
                                <p>Accepted</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>0</h3>
                                <p>Unanswered</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1</h3>
                                <p>Rejected</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Live chat waiting time 
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Waiting time</h5>
                                        <p>These metrics shows how long customers are waiting for agents to respond. These results can be filtered by selecting a specific channel from the Channels list above.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Timer className="iconBara" /> First response time
                                            <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                                <div uk-dropdown="mode: click">
                                                    <h5>First response time</h5>
                                                    <p>How long are customers waiting for agent's first response.</p>
                                                </div>
                                            </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>20s</h3>
                                <p>min</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>8m 2s</h3>
                                <p>max</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>6m 15s</h3>
                                <p>avg</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Timer className="iconBara" /> All response time
                                            <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                                <div uk-dropdown="mode: click">
                                                    <h5>All response time</h5>
                                                    <p>The metric that displays agent's responsiveness.</p>
                                                </div>
                                            </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3> &lt;1 sec</h3>
                                <p>min</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>6m 14s</h3>
                                <p>max</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>4m 48s</h3>
                                <p>avg</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Service-level agreemant*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <ul className="tabsBtns desktop uk-heading-divider" uk-switcher="connect: .multiTabNewLive">
                                <li className="uk-inline infoTool"><a>Total resolution time <Information /></a>
                                <div uk-dropdown="mode: click">
                                    <h5>Total resolution time</h5>
                                    <p>The time between a conversation was started and the most recent change of its status to Solve or Closed. Includes chatbot, agent, and unassigned time.</p>
                                </div>
                                </li>
                                <li className="uk-inline infoTool"><a>Resolution time <Information /></a>
                                <div uk-dropdown="mode: click">
                                    <h5>Resolution time</h5>
                                    <p>The time between a chatbot transfer happens and the most recent change of conversation status to <b>Solved</b> or <b>Closed</b>. Includes agent and unassigned time. Applies only to conversations that were transferred from bot.</p>
                                </div>
                                </li>
                            </ul>
                        </div>
                        <ul className="uk-switcher multiTabNewLive">
                        <li>
                            <div uk-grid="">
                                <div className="uk-width-1-3">
                                   <LiveChatOverviewResolutionTIme/>
                                </div>

                                <div className="uk-width-2-3">
                                    <div className="labelResoltuionTimeMain">
                                        <ul className="labelResoltuionTime">
                                            <li>
                                                <span className="Label">Below 5 Min</span>
                                                <progress id="js-progressbar" class="uk-progress" value="40" max="100"></progress>
                                                <span className="Percent">45.86%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Between 5 and 10 min</span>
                                                <progress id="js-progressbar" class="uk-progress" value="15" max="100"></progress>
                                                <span className="Percent">6.02%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Between 10 and 15 min</span>
                                                <progress id="js-progressbar" class="uk-progress" value="11" max="100"></progress>
                                                <span className="Percent">3.01%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Between 15 and 30 min</span>
                                                <progress id="js-progressbar" class="uk-progress" value="9" max="100"></progress>
                                                <span className="Percent">3.01%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Between 30 and 1 Hour</span>
                                                <progress id="js-progressbar" class="uk-progress" value="5" max="100"></progress>
                                                <span className="Percent"> &lt; 1%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Between 1 and 24 Hour</span>
                                                <progress id="js-progressbar" class="uk-progress" value="0" max="100"></progress>
                                                <span className="Percent">0%</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        </ul>
                    </div>
                </div>
                
            </div>
         </>
    );
}
}
export default LiveChatOverview;