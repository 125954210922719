import * as React from "react";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { DateRange } from "../../Common/DateRange";

  
class LiveChatQueries extends React.Component {
    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2022-11-01";
        var from = "2022-09-20";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Queues overview</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">
                    <div className="CustomCalender">
                        <DateRange />
                    </div>
                </div>
                
                {/* Performance highlights */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Highlights
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Highlights</h5>
                                        <p>The performance of all queues in the selected period of time.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>276</h3>
                                <p>Closed conversations
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Closed conversations</h5>
                                            <p>The number of conversations that match the selected Channel and Queue and were closed within selected period of time.</p>
                                        </div>
                                    </div>
                                </p>
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>7m 12s</h3>
                                <p>Median first response time
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Median first response time</h5>
                                            <p>The first response time is measured from the moment the conversation was assigned to the agent, until the agent's first response.</p>
                                        </div>
                                    </div>
                                </p>
                                
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>3m 7s</h3>
                                <p>Median handling time
                                    <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Median handling time</h5>
                                            <p>Handling time is measured from the moment the conversation was assigned until it was marked as closed.</p>
                                        </div>
                                    </div>
                                </p>
                                
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>0</h3>
                                <p>Sentiment</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                


                {/* Utilization per agent */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Queue details
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Queue details</h5>
                                        <p>Performance of each queue in the selected period of time.</p>
                                    </div>
                                </div>
                            <div className="uk-float-right ">
                                <div className="AgentDropDown">
                                    <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                                        <p><span>Export</span> <ChevronDown /></p>
                                        {/* <ul className="UltabsBtns">
                                            <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand">QUEUES</th>
                                    <th className="uk-table-shrink uk-text-nowrap">OLD</th>
                                    <th className="uk-table-shrink uk-text-nowrap">NEW</th>
                                    <th className="uk-table-shrink uk-text-nowrap">CLOSE</th>
                                    <th className="uk-table-shrink uk-text-nowrap">OPEN</th>
                                    <th className="uk-width-small uk-text-nowrap">FIRST RESPONSE TIME</th>
                                    <th className="uk-width-small">HANDLING TIME</th>
                                    <th className="uk-width-small">SENTIMENT</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>-</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>01:34:01</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                    <td className="uk-text-nowrap"><span>29:18:18</span></td>
                                </tr>
                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
         </>
    );
}
}
export default LiveChatQueries;