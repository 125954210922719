import React, { useEffect } from "react";
import * as locales from "react-date-range/dist/locale";
import { Link } from "react-router-dom";
import userChat from "../../../Assets/images/user-admin.svg";
import checkmark from "../../../Assets/images/checkmark.svg";
import chatbot from "../../../Assets/images/chat-bot.svg";
import coloredchat from "../../../Assets/images/colored-chat.svg";
import userchat from "../../../Assets/images/user-chat.svg";
import description from "../../../Assets/images/description.svg";
import message from "../../../Assets/images/message.svg";
import { Line } from "react-chartjs-2";
import whatsapp from "../../../Assets/images/whatsapp.png";
import userInitiatedImage from "../../../Assets/images/user-inititated.svg";
import businessInitiated from "../../../Assets/images/business-initiated.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import {
  CalendarHeatMap,
  ChevronDown,
  WarningAltFilled,
  WarningFilled,
} from "@carbon/icons-react";
import { Doughnut } from "react-chartjs-2";
import { useState } from "react";
import Calendar from "./Calender";
import axios from "axios";
import Constant from "../../../App/Constant";
import moment from "moment/moment";
import UIkit from "uikit";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

const Stats = () => {
  const [token, setToken] = useState("");
  const [userInitiated, setUserInitiated] = useState({});
  const [businessinitiated, setBusinessInitiated] = useState({});
  const [hightlight, setHightlight] = useState({});
  const [breakdown, setBreakdown] = useState({});
  const [chatBot, setChatBot] = useState({});
  const [activeAgent, setActiveAgent] = useState({});
  const [activeConversation, setActiveConversation] = useState({});
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState({});
  const [assignedLiveChats, setAssignedLiveChats] = useState({});
  const [whatsAppOverViews, setWhatsAppOverViews] = useState({});
  const [closedAndSolvedLiveChat, setClosedAndSolvedliveChat] = useState({});
  const [chatBotMessage, setChatBotMessage] = useState({});
  const [engagedUsers, setEngagedUsers] = useState({});
  const [STARTDATE, setSTARTDATE] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [ENDDATE, setENDDATE] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [DateChange, setDateChange] = useState(false);
  const onChange = (ranges) => {
    setActiveAgent({});
    setActiveConversation({});
    setMonthlyActiveUsers({});
    setHightlight({});
    setBusinessInitiated({});
    setUserInitiated({});
    setBreakdown({});
    setWhatsAppOverViews({});
    setChatBot({});
    setAssignedLiveChats({});
    setClosedAndSolvedliveChat({});
    setChatBotMessage({});
    setEngagedUsers({});
    let startDate = moment(ranges.startDate).format("YYYY-MM-DD");
    let endDate = moment(ranges.endDate).format("YYYY-MM-DD");
    setSTARTDATE(startDate);
    setENDDATE(endDate);
    setDateChange(true);
  };

  //for whatsapp graph
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "",
      },
    },
  };
  var newUpdataedData;
  if (whatsAppOverViews?.length > 0) {
    newUpdataedData = whatsAppOverViews?.map((val, index) => {
      return Object.keys(val)[0];
    });
  }
  const newvalues = Object.entries(whatsAppOverViews);
  let business = [];
  let user = [];
  var dataValues = newvalues.map((val, index) => {
    newUpdataedData.map((item, ind) => {
      if (index == ind) {
        return (
          business.push(val[1][item].Business_Initiated_Messages),
          user.push(val[1][item].User_Initiated_Messages)
        );
      }
    });
  });

  // let newBusinessinitaitedValues = business.slice(0, -1);
  // let newUserinitaitedValues = user.slice(0, -1);
  // const newData = Object.keys(whatsAppOverViews);
  // const labels = newData;
  // let newlabels = labels.slice(0, -1);
  const data = {
    // labels: labels,
    labels: newUpdataedData,

    //for object values
    datasets: [
      {
        label: "User Initiated Messages",
        data: user,
        borderColor: "#007FF9",
      },
      {
        label: "Business initiated Messages",
        data: business,
        borderColor: "#32D74B",
      },
    ],
  };

  // for assigned live chats
  const dataForLiveChat = {
    labels: ["Unassigned", "Assigned"],
    datasets: [
      {
        label: "Open Chats",
        data: [assignedLiveChats.Unassigned, assignedLiveChats.Assigned],
        backgroundColor: ["rgb(31, 115, 183)", "rgb(150, 204, 249)"],
        borderColor: ["rgb(31, 115, 183)", "rgb(150, 204, 249)"],
        borderWidth: 1,
        cutout: "95%",
        circumference: 180,
        rotation: 270,
      },
    ],
  };
  const optionsForLiveChat = {
    aspectRatio: 1.5,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const forLiveChat = [
    {
      id: "textMeter",
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          left = chart.left,
          ctx = chart.ctx;
        ctx.restore();
        // var fontSize = 25;
        // ctx.font = fontSize + "px sans-serif";

        var text = "11/38";
        var text1 = "Open Live Chats",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2.5;
        ctx.font = "bolder 12px san-serif";
        ctx.textBaseline = "top";
        ctx.fillText(
          "0%",
          Math.round((width - ctx.measureText(text).width) / 2) - 155,
          textY + 115
        );
        ctx.font = "bolder 12px san-serif";
        ctx.textBaseline = "top";
        ctx.fillText(
          "98%",
          Math.round((width - ctx.measureText(text).width) / 2) + 165,
          textY + 115
        );
        ctx.font = "bolder 55px san-serif";
        ctx.textBaseline = "top";
        ctx.fillStyle = "rgb(75, 75, 75)";
        // ctx.fillText(
        //   `${assignedLiveChats.Open_Chats}`,
        //   Math.round((width - ctx.measureText(text).width) / 2) + 10,
        //   textY + 35
        // );
        ctx.font = "20px san-serif";
        ctx.textBaseline = "top";
        ctx.fillStyle = "rgb(75, 75, 75)";
        ctx.fillText(
          "Open Chats ",
          Math.round((width - ctx.measureText(text).width) / 2) - 30,
          textY + 60
        );
        ctx.save();
      },
    },
  ];

  // for close and solved live chats
  const dataForClosedAndSolvedLiveChats = {
    labels: ["Not Closed/Unsolved", "closed/Solved"],
    datasets: [
      {
        label: "No of Chats",
        data: [
          closedAndSolvedLiveChat.Not_Closed_or_Solved,
          closedAndSolvedLiveChat.Closed_or_Solved,
        ],
        backgroundColor: ["#B05127", "#F3C175"],

        borderWidth: 1,
        cutout: "95%",
        circumference: 180,
        rotation: 270,
      },
    ],
  };
  const optionsForClosedAndSolvedLiveChats = {
    aspectRatio: 1.5,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const ForClosedAndSolvedLiveChats = [
    {
      id: "textMeter",
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          left = chart.left,
          ctx = chart.ctx;
        ctx.restore();

        var text = "11/38";
        var text1 = "Open Live Chats",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2.5;
        ctx.font = "bolder 12px san-serif";
        ctx.textBaseline = "top";
        ctx.fillText(
          "0%",
          Math.round((width - ctx.measureText(text).width) / 2) - 155,
          textY + 115
        );
        ctx.font = "bolder 12px san-serif";
        ctx.textBaseline = "top";
        ctx.fillText(
          "98%",
          Math.round((width - ctx.measureText(text).width) / 2) + 165,
          textY + 115
        );
        ctx.font = "bolder 55px san-serif";
        ctx.textBaseline = "top";
        ctx.fillStyle = "rgb(75, 75, 75)";
        // ctx.fillText(
        //   "24",
        //   Math.round((width - ctx.measureText(text).width) / 2) + 10,
        //   textY + 35
        // );
        ctx.font = "20px san-serif";
        ctx.textBaseline = "top";
        ctx.fillStyle = "rgb(75, 75, 75)";
        ctx.fillText(
          "No of Chats ",
          Math.round((width - ctx.measureText(text).width) / 2) - 30,
          textY + 60
        );
        ctx.save();
      },
    },
  ];

  // for chatBot Messages
  const dataForChatBot = {
    labels: ["Inbound", "Outbound"],
    datasets: [
      {
        label: "Exchanged Messages",
        data: [
          chatBotMessage?.Inbound?.slice(0, 1),
          chatBotMessage?.Outbound?.slice(0, 1),
        ],
        // data: [1,44],
        backgroundColor: ["rgb(31, 115, 183)", "rgb(150, 204, 249)"],
        borderColor: ["rgb(31, 115, 183)", "rgb(150, 204, 249)"],
        borderWidth: 1,
        cutout: "95%",
        circumference: 180,
        rotation: 270,
      },
    ],
  };
  const optionsForChatBot = {
    aspectRatio: 1.5,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const ForChatBot = [
    {
      id: "textMeter",
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          left = chart.left,
          ctx = chart.ctx;
        ctx.restore();
        // var fontSize = 25;
        // ctx.font = fontSize + "px sans-serif";

        var text = "11/38";
        var text1 = "Open Live Chats",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2.5;
        ctx.font = "bolder 12px san-serif";
        ctx.textBaseline = "top";
        ctx.fillText(
          "0%",
          Math.round((width - ctx.measureText(text).width) / 2) - 155,
          textY + 115
        );
        ctx.font = "bolder 12px san-serif";
        ctx.textBaseline = "top";
        ctx.fillText(
          "98%",
          Math.round((width - ctx.measureText(text).width) / 2) + 165,
          textY + 115
        );
        ctx.font = "bolder 55px san-serif";
        ctx.textBaseline = "top";
        ctx.fillStyle = "rgb(75, 75, 75)";
        // ctx.fillText(
        //   "333,20",
        //   Math.round((width - ctx.measureText(text).width) / 2) + 10,
        //   textY + 35
        // );
        ctx.font = "20px san-serif";
        ctx.textBaseline = "top";
        ctx.fillStyle = "rgb(75, 75, 75)";
        ctx.fillText(
          "Exchange Messages ",
          Math.round((width - ctx.measureText(text).width) / 2) - 50,
          textY + 60
        );
        ctx.save();
      },
    },
  ];

  // for Total No of User
  const dataForUsers = {
    labels: ["New users", "Returning users"],
    datasets: [
      {
        label: "Total no of users",
        data: [engagedUsers.New_User, engagedUsers.Recurring_User],
        backgroundColor: ["#B05127", "#F3C175"],

        borderWidth: 1,
        cutout: "95%",
        circumference: 180,
        rotation: 270,
      },
    ],
  };
  const optionsForUsers = {
    aspectRatio: 1.5,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const ForUsers = [
    {
      id: "textMeter",
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          left = chart.left,
          ctx = chart.ctx;
        ctx.restore();

        var text = "11/38";
        var text1 = "Open Live Chats",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2.5;
        ctx.font = "bolder 12px san-serif";
        ctx.textBaseline = "top";
        ctx.fillText(
          "0%",
          Math.round((width - ctx.measureText(text).width) / 2) - 155,
          textY + 115
        );
        ctx.font = "bolder 12px san-serif";
        ctx.textBaseline = "top";
        ctx.fillText(
          "98%",
          Math.round((width - ctx.measureText(text).width) / 2) + 165,
          textY + 115
        );
        ctx.font = "bolder 55px san-serif";
        ctx.textBaseline = "top";
        ctx.fillStyle = "rgb(75, 75, 75)";
        // ctx.fillText(
        //   "92,453",
        //   Math.round((width - ctx.measureText(text).width) / 2) + 10,
        //   textY + 35
        // );
        ctx.font = "20px san-serif";
        ctx.textBaseline = "top";
        ctx.fillStyle = "rgb(75, 75, 75)";
        ctx.fillText(
          "Total no of users ",
          Math.round((width - ctx.measureText(text).width) / 2) - 30,
          textY + 60
        );
        ctx.save();
      },
    },
  ];
  const handleClickScroll = (id) => {
    navigationActivation();
    const element = document.getElementById(id);
    const boxes = document.querySelectorAll(".test1, .test2, .test3");
    boxes.forEach((box) => {
      if (box.id === id) {
        element.setAttribute("class", `${box.className} democlass`);
      } else {
        box.classList.remove("democlass");
      }
    });
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigationActivation = () => {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("nav-btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active", "");
        }
        this.className += " active";
      });
    }
  };
  useEffect(() => {
    navigationActivation();
    getToken();
  }, []);
  useEffect(() => {
    if (DateChange == true) {
      apiCalling(token);
    }
  }, [DateChange]);

  const whatsAppOverview = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/whatsappOverview/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      // console.log('result',result)
      setWhatsAppOverViews(result.data.data);
    });
  };
  const assignedLiveChat = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/AssignedLiveChats/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      // console.log("result",result)
      setAssignedLiveChats(result.data.data);
    });
  };
  const closedandLiveChat = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/ClosedANDLiveChats/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      // console.log("result",result)
      setClosedAndSolvedliveChat(result.data.data);
    });
  };
  const chatBotMessages = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/ChatBotMessages/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      // console.log('result',result)
      setChatBotMessage(result.data.data);
    });
  };
  const EngagedUsers = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/EngagedUsers/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      // console.log('result',result)
      setEngagedUsers(result.data.data);
    });
  };
  const userandBusinessinitiated = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/UAndBInitiatedMessages/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      // console.log("user initiated result",result)
      setUserInitiated(result.data.data.User_Initiated_Messages);
      setBusinessInitiated(result.data.data.Business_Initiated_Messages);
    });
  };

  const highlights = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/highlights/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      setHightlight(result.data.data);
    });
  };
  const breakDownByLiveChats = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/BreakdownbyLiveChats/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      // console.log('result',result)
      setBreakdown(result.data.data);
    });
  };

  const TotalChatBotConversations = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/TotalChatBotConversations/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      // console.log("result",result)
      setChatBot(result.data.data);
    });
  };
  const activePlans = (apiToken) => {
    axios({
      method: "POST",
      url: Constant.url1 + "overview/ActivePlans/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + apiToken,
      },
      data: {
        START_DATE: STARTDATE,
        END_DATE: ENDDATE,
        COMPANY: localStorage.getItem("company_id"),
      },
    }).then(function (result) {
      setActiveAgent(result.data.data.Active_Agents);
      setActiveConversation(result.data.data.Active_Conversations);
      setMonthlyActiveUsers(result.data.data.Monthly_Active_Users);
    });
  };
  const apiCalling = (apiToken) => {
    userandBusinessinitiated(apiToken);
    highlights(apiToken);
    breakDownByLiveChats(apiToken);
    TotalChatBotConversations(apiToken);
    activePlans(apiToken);
    assignedLiveChat(apiToken);
    EngagedUsers(apiToken);
    closedandLiveChat(apiToken);
    chatBotMessages(apiToken);
    whatsAppOverview(apiToken);
    setDateChange(false);
  };
  const getToken = () => {
    axios({
      method: "POST",
      url: Constant.url1 + "api/auth/TokenGenerate",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        // "Accept-Encoding": "gzip, deflate, br",
      },
      data: {
        email: "info@convexinteractive.com",
        password: "convex123",
      },
    }).then(function (result) {
      if (result.data.code == 200) {
        setToken(result?.data?.data?.access_token);
        apiCalling(result?.data?.data?.access_token);
      }
    });
  };

  const handleDatePickerClose = () => {
    UIkit.drop("#datepickerdrop").hide(true);
  };

  const dataExport = () => {
    window.location.href =
      Constant.url1 +
      "download_excel?start_date=" +
      STARTDATE +
      "&end_date=" +
      ENDDATE +
      "&company_id=" +
      localStorage.getItem("company_id") +
      "&channel_type=" +
      localStorage.getItem("channeltype");
  };

  return (
    <div className="main">
      <div className="navbar" uk-grid="">
        <div className="new-nav uk-width-1-2" id="myDIV">
          <button
            className="nav-btn active"
            onClick={() => handleClickScroll("overview")}
          >
            Overview
          </button>
          <button
            className="nav-btn"
            onClick={() => handleClickScroll("assignedchats")}
          >
            Agents
          </button>
          <button
            className="nav-btn"
            onClick={() => handleClickScroll("conversation")}
          >
            ChatBot
          </button>
        </div>
        {/* <div className="expiry-date-text uk-width-1-2 uk-text-right">
          Activation : 20 Nov 2022 | Expiry : 30 Nov 2024
        </div> */}
      </div>
      {/* <div class="red-alert uk-margin-remove" uk-alert="">
        <a class="uk-alert-close" uk-close=""></a>
        <p>
          <WarningAltFilled />
          <span className="uk-margin-small-left">
            Your package will expire in 10 days
          </span>
        </p>
      </div>
      <div class="blue-alert uk-margin-remove" uk-alert="">
        <a class="uk-alert-close" uk-close=""></a>
        <p>
          <WarningFilled />
          <span className="uk-margin-small-left">
            You have consumed 90% of alloted conversations, please contact with
            your account manager
          </span>
        </p>
      </div> */}
      {/* <div className="uk-container uk-container-center main-content"> */}
      {/* <div className=" main-content uk-width-4-5 uk-margin-auto"> */}
      <div className=" main-content uk-width-4-5 uk-margin-auto">
        <div id="overview" className="test1">
          <div uk-grid="">
            {/* <div className="uk-width-1-2"> */}
            <div className="uk-width-2-5">
              <p className="greet-message">
                {localStorage.getItem("agent-photo")}
              </p>
            </div>
            {/* <div className="uk-width-1-2 uk-text-right"> */}
            {/* <div className="uk-width-3-5 uk-text-right">
              <span className="uk-margin-medium-right">
              <div className="uk-inline uk-text-left">
                <button
                  className="uk-button date-filter-button uk-padding-remove"
                  type="button"
                >
                  <CalendarHeatMap />
                  <span className="filter-name">
                    {moment(STARTDATE).format("Do MMMM YYYY")} -{" "}
                    {moment(ENDDATE).format("Do MMMM YYYY")}
                  </span>
                  <ChevronDown />
                </button>
                <div
                  id="datepickerdrop"
                  uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                  className="date-picker-container"
                >
                  <Calendar onChange={onChange} />
                  <div className="date-picker-btn-container uk-text-right">
                    <button
                      className="date-selection-btn"
                      onClick={handleDatePickerClose}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
              </span>
              <span className="primary-color">
                <span className="uk-margin-small-right ">
                  <button className="export-btn" onClick={dataExport}>
                    {" "}
                    Export <ChevronDown />
                  </button>
                </span>
              </span>
            </div> */}
          </div>
        </div>
        <div>
          <ul className="page-links">
            <li>
              <a href="">{localStorage.getItem("company_name")}</a>
            </li>
            <li>|</li>
            <li>
              <a href=""> {localStorage.getItem("roleName")} </a>
            </li>
          </ul>
        </div>
        <div
          uk-grid=""
          uk-height-match="target: > div > .card-container"
          className="uk-grid-column-small uk-grid-row-large  "
        >
          {/* <div className="uk-width-3-4 uk-margin-medium-bottom"> */}
          <div className="uk-width-3-5 uk-margin-medium-bottom">
            <div className="card-container active-plan-padding ">
              <div
                uk-grid=""
                className="uk-child-width-1-2 uk-grid-column-small uk-grid-row-large card-heading"
              >
                <div>Active plans</div>
                <div className="uk-text-right">Package amount PKR 30,000</div>
              </div>
              <br />
              <hr className="divider" />
              {JSON.stringify(activeAgent) === "{}" ? (
                <div className="uk-text-center">
                  <div className="mdl">
                    <div className="mdl_inner">
                      <div uk-spinner=""></div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    uk-grid=""
                    className="uk-child-width-1-2 uk-grid-column-small uk-grid-row-large"
                  >
                    <div>
                      <Link className="page-route-links" to="/users">
                        Active Agents
                      </Link>
                      {/* <span className="plans-text">
                    {"(" + activeAgent.Active_Agents_Percentage + ")"}
                  </span> */}
                    </div>
                    <div className="uk-text-right plan-stats">
                      {activeAgent.Count === undefined
                        ? "0"
                        : activeAgent.Count.toLocaleString()}
                      {"/"}
                      {activeAgent.Total === undefined
                        ? "0"
                        : activeAgent.Total.toLocaleString()}
                      {" - "}
                      {activeAgent.Active_Agents_Percentage === undefined
                        ? "0%"
                        : activeAgent.Active_Agents_Percentage}
                    </div>
                  </div>
                  <progress
                    className="uk-progress progressbar-style"
                    value={activeAgent.Count}
                    max={activeAgent.Total}
                  ></progress>

                  <div
                    uk-grid=""
                    className="uk-child-width-1-2 uk-grid-column-small uk-grid-row-large uk-margin-small-top"
                  >
                    <div>
                      <Link
                        className="page-route-links"
                        to="/conversation-detail"
                      >
                        Conversations
                      </Link>
                      {/* <span className="plans-text">
                    {"(" + activeConversation.Percentage + ")"}
                  </span> */}
                    </div>
                    <div className="uk-text-right plan-stats">
                      {activeConversation.Count === undefined
                        ? "0"
                        : activeConversation.Count.toLocaleString()}
                      {"/"}
                      {activeConversation.Total === undefined
                        ? "0"
                        : activeConversation.Total.toLocaleString()}
                      {" - "}
                      {activeConversation.Percentage === undefined
                        ? "0%"
                        : activeConversation.Percentage}
                    </div>
                  </div>
                  <progress
                    className="uk-progress progress-light-coral progressbar-style"
                    value={activeConversation.Count}
                    max={activeConversation.Total}
                  ></progress>

                  <div
                    uk-grid=""
                    className="uk-child-width-1-2 uk-grid-column-small uk-grid-row-large uk-margin-small-top "
                  >
                    <div>
                      <Link
                        className="page-route-links"
                        to="/monthly-active-users"
                      >
                        Monthly Active Users
                      </Link>
                      {/* <span className="plans-text">
                    {"(" + monthlyActiveUsers.Percentage + ")"}
                  </span> */}
                    </div>
                    <div className="uk-text-right plan-stats">
                      {monthlyActiveUsers.Count === undefined
                        ? "0"
                        : monthlyActiveUsers.Count.toLocaleString()}
                      {"/"}
                      {monthlyActiveUsers.Total === undefined
                        ? "0"
                        : monthlyActiveUsers.Total.toLocaleString()}
                      {" - "}
                      {monthlyActiveUsers.Percentage === undefined
                        ? "0%"
                        : monthlyActiveUsers.Percentage}
                    </div>
                  </div>
                  <progress
                    className="uk-progress progress-coral progressbar-style"
                    value={monthlyActiveUsers.Count}
                    max={monthlyActiveUsers.Total}
                  ></progress>
                </>
              )}
            </div>
          </div>
          {/* <div className="uk-width-1-4"> */}
          <div className="uk-width-2-5">
            <div className="card-container card-heading">
              <p>Active Channels</p>
              <hr className="divider" />
              {/* <div className="channels-icon-container">
                <img
                  src={whatsapp}
                  className="channels-icons"
                  alt=""
                  width={"40px"}
                  height={"40px"}
                />
                <span className="channel-notification-count">22</span>
              </div> */}
              <div className="channels-icon-container">
                <img
                  src={whatsapp}
                  className="channels-icons"
                  alt=""
                  width={"40px"}
                  height={"40px"}
                />
              </div>
              <p>WhatsApp</p>
            </div>
          </div>
        </div>
        <div>
          <div className=" uk-margin-medium-bottom uk-text-right">
            <div className="uk-inline uk-text-left">
              <button
                className="uk-button date-filter-button uk-padding-remove"
                type="button"
              >
                <CalendarHeatMap />
                <span className="filter-name">
                  {moment(STARTDATE).format("Do MMMM YYYY")} -{" "}
                  {moment(ENDDATE).format("Do MMMM YYYY")}
                </span>
                <ChevronDown />
              </button>
              <div
                id="datepickerdrop"
                uk-drop="mode: click;animation: uk-animation-slide-top-small; animate-out: true"
                className="date-picker-container"
              >
                <Calendar onChange={onChange} />
                <div className="date-picker-btn-container uk-text-right">
                  <button
                    className="date-selection-btn"
                    onClick={handleDatePickerClose}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-container uk-margin-medium-bottom">
          <div className="card-heading">
            Highlights
            <span className="uk-inline">
              <img src={description} alt="" />
              <div
                className="uk-card uk-card-body uk-card-default uk-text-left"
                uk-drop="mode: hover"
              >
                <h6>Highlights</h6>
                The performance of all queues in the selected period of time.
              </div>
            </span>
          </div>
          <br />
          <hr className="divider " />
          <div
            className="uk-child-width-1-4 uk-grid-small uk-text-center"
            uk-grid=""
          >
            <div>
              <div className="stats">
                {hightlight.All_Conversations == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  hightlight.All_Conversations
                )}
              </div>
              <div className="stats-description">All Conversations</div>
            </div>
            <div>
              <div className="stats">
                {hightlight.Chats_Routed_To_Agent == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  hightlight.Chats_Routed_To_Agent
                )}
              </div>
              <div className="stats-description">
                <img src={userChat} alt="" /> Chats Routed to Agents
              </div>
            </div>
            <div>
              <div className="stats">
                {hightlight.Chatbot_Conversations == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  hightlight.Chatbot_Conversations
                )}
              </div>
              <div className="stats-description">
                <img src={chatbot} alt="" /> ChatBot Conversation
              </div>
            </div>
            <div>
              <div className="stats">
                {hightlight.Broadcast == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  hightlight.Broadcast
                )}
              </div>
              <div className="stats-description">
                <img src={checkmark} alt="" /> BroadCast
              </div>
            </div>
          </div>
        </div>

        <div className="card-container uk-margin-medium-bottom WhatsAppOverviewMain">
          <div className="card-heading">WhatsApp Overview</div>
          <div className="line-chart-graph uk-margin-small-top">
            {JSON.stringify(whatsAppOverViews) == "{}" ? (
              <div className="whatsapp-graph" uk-spinner=""></div>
            ) : (
              <Line
                options={options}
                data={data}
                style={{ width: "100%", height: "250px" }}
              />
            )}
            <div className="uk-text-center uk-margin-small">
              <span className="uk-margin-small-right whatsapp-graph-labels">
                <img src={userInitiatedImage} alt="" /> User Initiated
                Conversations
              </span>
              <span className="whatsapp-graph-labels">
                <img src={businessInitiated} alt="" /> Business Initiated
                Conversations
              </span>
            </div>
          </div>
        </div>

        <div className="card-container uk-margin-medium-bottom">
          <div className="card-heading">
            User initiated and Business Initiated Message Performance
          </div>
          <br />
          {/* <hr className="divider uk-margin-remove-bottom"  /> */}
          <hr className="divider " />

          <div
            className="uk-child-width-1-4  uk-grid-small uk-text-left alignment-text"
            uk-grid=""
          >
            <div className="stats" uk-grid="">
              <div className="uk-width-1-2">
                <img
                  src={coloredchat}
                  alt=""
                  className=" uk-margin-small-left"
                />
              </div>
              <div className="uk-width-1-2 colored-heading uk-text-left">
                Business Initiated Conversations
              </div>
            </div>
            <div className="uk-text-center">
              <div className="stats">
                {businessinitiated.Users == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  businessinitiated.Users
                )}
              </div>
              <div className="stats-description">
                User
                <span className="uk-inline">
                  <img src={description} alt="" />
                  <div
                    className="uk-card uk-card-body uk-card-default uk-text-left"
                    uk-drop="mode: hover"
                  >
                    <h6>Traffic</h6>
                    Count of individual message segments.
                  </div>
                </span>
              </div>
            </div>
            <div className="uk-text-center">
              <div className="stats">
                {businessinitiated.Messages == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  businessinitiated.Messages
                )}
              </div>
              <div className="stats-description">
                Conversations{" "}
                <span className="uk-inline">
                  <img src={description} alt="" />
                  <div
                    className="uk-card uk-card-body uk-card-default uk-text-left"
                    uk-drop="mode: hover"
                  >
                    <h6>Messages</h6>
                    Count of messages, where a message can be one or more
                    segements long. .
                  </div>
                </span>
              </div>
            </div>
            {/* <div className="uk-text-center">
              <div className="stats">
                {businessinitiated.Free_Tier_Consumption === undefined
                  ? "0%"
                  : businessinitiated.Free_Tier_Consumption + "%"}
              </div>
              <div className="stats-description">Free Tire Consumption</div>
            </div> */}
            <div className="uk-text-center">
              <div className="stats">
                {businessinitiated.Delivery_Rate === undefined ? (
                  <div uk-spinner=""></div>
                ) : (
                  businessinitiated.Delivery_Rate
                )}
              </div>
              <div className="stats-description">
                Delivery Rate{" "}
                <span className="uk-inline">
                  <img src={description} alt="" />
                  <div
                    className="uk-card uk-card-body uk-card-default uk-text-left"
                    uk-drop="mode: hover"
                  >
                    <h6>
                      Delivery Rate
                      <br />
                      Delivery Rate = Deleivered / (Delivered + Pending +
                      Undelivered + Expired)
                    </h6>
                    Message can be rejected because of Don Not Contact eanbled
                    so, messages with status "Rejected" are not included in
                    formula.
                  </div>
                </span>
              </div>
            </div>
          </div>
          {/* <hr className="uk-margin-remove-bottom " /> */}
          <hr />
          <div
            className="uk-child-width-1-4 uk-grid-small uk-text-left alignment-text"
            uk-grid=""
          >
            <div className="stats" uk-grid="">
              <div className="uk-width-1-2">
                <img
                  src={userchat}
                  alt=""
                  // className="uk-margin-small-top uk-margin-medium-left"
                  className=" uk-margin-small-left"
                />
              </div>
              <div className="uk-width-1-2 colored-heading uk-text-left">
                User Initiated Conversations
              </div>
            </div>
            <div className="uk-text-center">
              <div className="stats">
                {" "}
                {userInitiated.Users == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  userInitiated.Users
                )}
              </div>
              <div className="stats-description">
                User
                <span className="uk-inline">
                  <img src={description} alt="" />
                  <div
                    className="uk-card uk-card-body uk-card-default uk-text-left"
                    uk-drop="mode: hover"
                  >
                    <h6>New Engaged Users</h6>
                    Users that communicated with the chatbot for the first time
                    in the selected time period.
                  </div>
                </span>
              </div>
            </div>
            <div className="uk-text-center">
              <div className="stats">
                {" "}
                {userInitiated.Messages == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  userInitiated.Messages
                )}
              </div>
              <div className="stats-description">
                Conversations
                <span className="uk-inline">
                  <img src={description} alt="" />
                  <div
                    className="uk-card uk-card-body uk-card-default uk-text-left"
                    uk-drop="mode: hover"
                  >
                    <h6>Messages</h6>
                    Count of message where a message can be one or more segments
                    long.
                  </div>
                </span>
              </div>
            </div>
            {/* <div className="uk-text-center">
              <div className="stats">
                {userInitiated.Free_Tier_Consumption === undefined
                  ? "0%"
                  : userInitiated.Free_Tier_Consumption + "%"}{" "}
              </div>
              <div className="stats-description">Free Tire Consumption</div>
            </div> */}
          </div>
        </div>
        <div className="card-container uk-margin-medium-bottom">
          <div className="card-heading">
            Breakdown by Live Chats Status
            <span className="uk-inline">
              <img src={description} alt="" />
              <div
                className="uk-card uk-card-body uk-card-default"
                uk-drop="mode: hover"
              >
                <h6>Break Down By Conversations</h6>
                shows the thoughput of conversation that match the selected
                channel, queue, and were active in the selcted period.
              </div>
            </span>
          </div>
          <br />
          <hr className="divider" />
          <div
            className="uk-child-width-1-4 uk-grid-small uk-text-center"
            uk-grid=""
          >
            <div>
              <div className="stats">
                {JSON.stringify(breakdown) == "{}" ? (
                  <div uk-spinner=""></div>
                ) : (
                  breakdown.Chats_Routed_to_Agents
                )}
              </div>
              <div className="stats-description">Chats Routes to Agents</div>
            </div>
            <div>
              <div className="stats">
                {breakdown.Unassigned == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  breakdown.Unassigned
                )}
              </div>
              <div className="stats-description">Unassigned</div>
            </div>
            <div>
              <div className="stats">
                {breakdown.Assigned == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  breakdown.Assigned
                )}
              </div>
              <div className="stats-description">Assigned</div>
            </div>
            <div>
              <div className="stats">
                {breakdown.Closed_Solved == null ? (
                  <div uk-spinner=""></div>
                ) : (
                  breakdown.Closed_Solved
                )}
              </div>
              <div className="stats-description">Closed / Solved</div>
            </div>
          </div>
        </div>
        <div id="assignedchats" className="test2">
          <div uk-grid="" className="uk-grid-column-small uk-grid-row-large">
            <div className="uk-width-1-2 uk-margin-medium-bottom">
              <div className="card-container  ">
                <div className="card-heading">Assigned Live Chats</div>
                <br />
                <hr className="divider" />
                <div className="semi-circle-graph">
                  {JSON.stringify(assignedLiveChats) == "{}" ? (
                    <div
                      uk-spinner=""
                      className="semicircle-graph-loader"
                    ></div>
                  ) : (
                    <>
                      <Doughnut
                        data={dataForLiveChat}
                        options={optionsForLiveChat}
                        plugins={forLiveChat}
                      />
                      <h3 className="centered-text semicircle-chart-text">
                        {assignedLiveChats.Open_Chats}
                      </h3>
                    </>
                  )}
                  <div uk-grid="">
                    <div className="uk-width-1-2">
                      <span className="circle-spots dark-blue"></span>Unassigned
                    </div>
                    <div className="uk-width-1-2 uk-text-right">
                      <span>
                        {assignedLiveChats.Unassigned == null ? (
                          <span uk-spinner=""></span>
                        ) : (
                          assignedLiveChats.Unassigned
                        )}
                      </span>
                    </div>
                  </div>
                  <div uk-grid="" className="legend-margin">
                    <div className="uk-width-1-2">
                      <span className="circle-spots light-blue"></span> Assigned
                    </div>
                    <div className="uk-width-1-2 uk-text-right">
                      <span>
                        {assignedLiveChats.Assigned == null ? (
                          <span uk-spinner=""></span>
                        ) : (
                          assignedLiveChats.Assigned
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-2">
              <div className="card-container  ">
                <div className="card-heading">Closed and solved live Chats</div>
                <br />
                <hr className="divider" />
                <div className="semi-circle-graph">
                  {JSON.stringify(closedAndSolvedLiveChat) == "{}" ? (
                    <div
                      uk-spinner=""
                      className="semicircle-graph-loader"
                    ></div>
                  ) : (
                    <>
                      <Doughnut
                        data={dataForClosedAndSolvedLiveChats}
                        options={optionsForClosedAndSolvedLiveChats}
                        plugins={ForClosedAndSolvedLiveChats}
                      />
                      <h3 className="centered-text semicircle-chart-text">
                        {closedAndSolvedLiveChat.No_of_Chats}
                      </h3>
                    </>
                  )}
                  <div uk-grid="">
                    <div className="uk-width-3-4">
                      <span className="circle-spots dark-brown"></span>Not
                      Closed/Unsolved
                    </div>
                    <div className="uk-width-1-4 uk-text-right">
                      <span>
                        {closedAndSolvedLiveChat.Not_Closed_or_Solved ==
                        null ? (
                          <span uk-spinner=""></span>
                        ) : (
                          closedAndSolvedLiveChat.Not_Closed_or_Solved
                        )}
                      </span>
                    </div>
                  </div>
                  <div uk-grid="" className="legend-margin">
                    <div className="uk-width-3-4">
                      <span className="circle-spots light-brown"></span>
                      Closed/Solved
                    </div>
                    <div className="uk-width-1-4 uk-text-right">
                      <span>
                        {closedAndSolvedLiveChat.Closed_or_Solved == null ? (
                          <span uk-spinner=""></span>
                        ) : (
                          closedAndSolvedLiveChat.Closed_or_Solved
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="conversation" className="test3">
          <div className="card-container uk-margin-medium-bottom">
            <p>ChatBot Conversation Summary</p>
            <hr className="divider" />
            <div
              className="uk-child-width-1-4 uk-grid-small uk-text-left alignment-text"
              uk-grid=""
            >
              {/* <div>
                <div className="stats">
                  <img src={message} alt="" className="uk-margin-small-top" />
                  <span className="colored-heading uk-margin-small-left">
                    Total ChatBot Conversations
                  </span>
                </div>
              </div> */}
              <div className="stats" uk-grid="">
                <div className="uk-width-1-2">
                  <img src={message} alt="" className=" uk-margin-small-left" />
                </div>
                <div className="uk-width-1-2 colored-heading uk-text-left ">
                  Total ChatBot Conversations
                </div>
              </div>
              {/* <div>
                <div className="stats">{chatBot.Total_Chats}</div>
                <div className="stats-description">Total chat</div>
              </div> */}
              <div className="uk-text-center">
                <div className="stats">
                  {chatBot.Total_Chats == null ? (
                    <div uk-spinner=""></div>
                  ) : (
                    chatBot.Total_Chats
                  )}
                </div>
                <div className="stats-description">Total chat</div>
              </div>
              <div className="uk-text-center">
                <div className="stats">
                  {chatBot.New_User == null ? (
                    <div uk-spinner=""></div>
                  ) : (
                    chatBot.New_User
                  )}
                </div>
                <div className="stats-description">
                  New users
                  <span className="uk-inline">
                    <img src={description} alt="" />
                    <div
                      className="uk-card uk-card-body uk-card-default uk-text-left"
                      uk-drop="mode: hover"
                    >
                      <h6>New Users</h6>
                      Users that communicated with the chatbot for the first
                      time in the selected time range.{" "}
                    </div>
                  </span>
                </div>
              </div>
              <div className="uk-text-center">
                <div className="stats">
                  {chatBot.Recurring_User == null ? (
                    <div uk-spinner=""></div>
                  ) : (
                    chatBot.Recurring_User
                  )}
                </div>
                <div className="stats-description">
                  Recurring users{" "}
                  <span className="uk-inline">
                    <img src={description} alt="" />
                    <div
                      className="uk-card uk-card-body uk-card-default uk-text-left"
                      uk-drop="mode: hover"
                    >
                      <h6>Returing users</h6>
                      Returning users that communicated with the chatbot in the
                      selected time period, and at least once before that.
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div uk-grid="" className="uk-grid-column-small uk-grid-row-large  ">
          <div className="uk-width-1-2 uk-margin-medium-bottom">
            <div className="card-container  ">
              <p>ChatBot Messages</p>
              <hr className="divider" />
              <div className="semi-circle-graph">
                {JSON.stringify(chatBotMessage) == "{}" ? (
                  <div uk-spinner="" className="semicircle-graph-loader"></div>
                ) : (
                  <>
                    <Doughnut
                      data={dataForChatBot}
                      options={optionsForChatBot}
                      plugins={ForChatBot}
                    />
                    <h3 className="centered-text semicircle-chart-3">
                      {chatBotMessage.Exchanged_Messages}
                    </h3>
                  </>
                )}
                <div uk-grid="">
                  <div className="uk-width-3-4">
                    <span className="circle-spots dark-blue"></span>Inbound
                  </div>
                  <div className="uk-width-1-4 uk-text-right">
                    <span>
                      {chatBotMessage.Inbound == null ? (
                        <span uk-spinner=""></span>
                      ) : (
                        chatBotMessage.Inbound
                      )}
                    </span>
                  </div>
                </div>
                <div uk-grid="" className="legend-margin">
                  <div className="uk-width-3-4">
                    <span className="circle-spots light-blue"></span>
                    OutBound
                  </div>
                  <div className="uk-width-1-4 uk-text-right">
                    <span>
                      {chatBotMessage.Outbound == null ? (
                        <span uk-spinner=""></span>
                      ) : (
                        chatBotMessage.Outbound
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-2">
            <div className="card-container  ">
              <p>Engaged Users</p>
              <hr className="divider" />
              <div className="semi-circle-graph">
                {JSON.stringify(engagedUsers) == "{}" ? (
                  <div uk-spinner="" className="semicircle-graph-loader"></div>
                ) : (
                  <>
                    <Doughnut
                      data={dataForUsers}
                      options={optionsForUsers}
                      plugins={ForUsers}
                    />
                    <h3 className="centered-text semicircle-chart-text">
                      {engagedUsers.Total_No_of_Users}
                    </h3>
                  </>
                )}
                <div uk-grid="" className="uk-padding-remove">
                  <div className="uk-width-3-4">
                    <span className="circle-spots dark-brown"></span>New Users
                  </div>
                  <div className="uk-width-1-4 uk-text-right">
                    <span>
                      {engagedUsers.New_User == null ? (
                        <span uk-spinner=""></span>
                      ) : (
                        engagedUsers.New_User
                      )}
                    </span>
                  </div>
                </div>
                <div uk-grid="" className="legend-margin">
                  <div className="uk-width-3-4">
                    <span className="circle-spots light-brown"></span>
                    Returning users
                  </div>
                  <div className="uk-width-1-4 uk-text-right">
                    <span>
                      {engagedUsers.Recurring_User == null ? (
                        <span uk-spinner=""></span>
                      ) : (
                        engagedUsers.Recurring_User
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
