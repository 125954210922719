import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../App/Constant";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  let dummyData = {

      labels: [] ,
      datasets: [
        {
          label: 'Day wise Resolved Chats',
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
      ],


  }


  export const AgentDaySolvedTickets = (stateTo,stateFrom) => {

    const [graphData, setGraphData] = useState(dummyData);

    useEffect(() => {

      formValidation();

  }, [stateTo,stateFrom]);

  const formValidation = async () => {

    axios({
        method: 'POST',
        url: Constant.url+"agents-day-wise-solved-chats",
        data: {company_id:localStorage.getItem('company_id'),stateTo:stateTo.stateTo,stateFrom:stateTo.stateFrom,channel_type:localStorage.getItem("channeltype")} 
    }).then(result =>{

      var stats = result.data.data;
      setGraphData({

      labels: stats.labels_agent,
      datasets: [
        {
          label: 'Day wise Resolved Chats',
          data: stats.values_agent,
          borderColor: 'rgb(253, 186,92)',
          backgroundColor: 'rgb(253, 186,92)',
        },
        

      ],

      })

      
    }).catch(err => {

      // console.log(err)
            
    });


}


   const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: false,
          // text: 'Chart.js Bar Chart',
        },
      },
    };

    return <Bar options={options} data={graphData} />;


  }







