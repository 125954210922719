import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from '@adobe/react-spectrum';
import { Multiselect } from 'multiselect-react-dropdown';
import { Add, CaretDown, Edit, Search, TrashCan, Chat,MessageQueue, OverflowMenuVertical, Download,Close, Checkmark,InProgress,Information,ViewFilled,ReplyAll,Send,NotSent } from '@carbon/icons-react';
import Constant from "../../../App/Constant";
import axios from 'axios';
import $ from 'jquery';
import Select from 'react-select'

class KeywordReport extends React.Component {

    constructor() {
        super();
        this.callApi = this.callApi.bind(this);
        this.handleTable = this.handleTable.bind(this);
        this.getKeywords = this.getKeywords.bind(this);
        this.handleKeywords = this.handleKeywords.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.state = {
            dataList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            keyList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            stats:{
                all_messages:0,
                all_conversations:0,
                sent:0,
                sent:0,
                delivered:0,
                read:0,
                replied:0,
                sending:0
            },
            search:"",
        }
    }

    componentDidMount() {
   
        this.callApi();
        this.getKeywords();
        $("#cross").hide()
    }

    handleSearchChange(event) {
        var clearsearch = ""
        this.setState({ search: clearsearch});
        this.callApi();
      }

      searchrecord = () =>{    
      

        let classRef = this;
        var url = window.location.pathname;
        var id = url.split("/");
        var campaign_id = id[3];
        var keyword = id[4];
        var keyword = keyword.replace("%20", " ");

        var searchword = $("#searchword").val();
        // console.log(searchword)

        if(searchword !==''){
            $("#cross").show()
             }
    
        this.setState({ search: searchword});
    
        axios({
          method: 'POST',
          url: Constant.url + "keyword-wise-report",
          data: { company_id: localStorage.getItem('company_id'),campaign_id:campaign_id,keyword:keyword ,search: searchword }
      }).then(function (result) {

        if (result.data.status === true) {
                    classRef.setState({
                        dataList: {
                            isLoaded: true,
                            msg: 'Loading...',
                            list: result.data.data.list
                        },
                        stats:{
                            all_messages:result.data.data.stats.all_messages,
                            all_conversations:result.data.data.stats.all_conversations,
                        }
                    })
                }else{

                    classRef.setState({
                    dataList: {
                        isLoaded: false,
                        msg: 'not',
                        list: []
                    },
                })
                }
          
      })
    
      }




    

    getKeywords = () => {
        let classRef = this;
        var url = window.location.pathname;
        var id = url.split("/");
        var campaign_id = id[3];
        axios({
          method: 'POST',
          url: Constant.url + "bot-search-filters",
          data: { company_id: localStorage.getItem('company_id'),campaign_id: campaign_id }
        }).then(function (result) {

          if (result.data.status === true) {
            classRef.setState({
            keyList: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data.keywords
              }
            })
          }
        })
      }


      handleKeywords = () => {
        let result = "";
        let index = 0;
        result = this.state.keyList.list.map((response, index) => (   
          <option value={response.keyword}>{response.keyword_name}</option>
        ));
        return result;
      }


    addSearchModal = () => {
        $("#AddTeamModal").addClass('uk-flex uk-open');
    }
    
    closeSearchModal= () => {
        $("#AddTeamModal").removeClass('uk-flex uk-open');
    }
    

    callApi = () => {
        let classRef = this;
        var url = window.location.pathname;
        var id = url.split("/");
        var campaign_id = id[3];
        var keyword = id[4];
        var keyword = keyword.replace("%20", " ");
        axios({
            method: 'POST',
            url: Constant.url + "keyword-wise-report",
            data: { company_id: localStorage.getItem('company_id'),campaign_id:campaign_id,keyword:keyword }
        }).then(function (result) {
            
            if (result.data.status === true) {
                classRef.setState({
                    dataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data.list

                    },
                    stats:{
                        all_messages:result.data.data.stats.all_messages,
                        all_conversations:result.data.data.stats.all_conversations,
                    } 
                })
            }else{

                classRef.setState({
                  dataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
              })
              }
        })

    }

    handleTable = () => {
        let result = "";
        let index = 0;
        result = this.state.dataList.list.map((response, index) => (
            <tr>
                <td><span>{response.telco}</span></td>
                <td>{response.msisdn}</td>
                <td>{response.msg_sent}</td>
                <td>{response.msg_received}</td>
                <td>{response.status}</td>
                <td>{response.insert_time}</td>
            </tr>
        ));
        return result;
    }

    advanceSearch = () =>{  

        let classRef = this;
       
        var url = window.location.pathname;
        var id = url.split("/");
        var campaign_id = id[3];
        var keyword = id[4];
        var keyword = keyword.replace("%20", " ");
        var data = {};
        data = {
        dateto: $("#to_date").val(),
        datefrom: $("#from_date").val(),
        keyword: keyword,
        campaign_id: campaign_id,     
        company_id: localStorage.getItem('company_id')        
        }


        var searchval = $("#keyword option:selected").text();

        var key =''

        if(data.dateto){
        var key = "Date To: "+$("#to_date").val()
        }
        if(data.datefrom){
          var key = key+" Date From: "+$("#from_date").val()
        }

        if(key !==''){
            $("#cross").show()
             }
    
        this.setState({ search: key});

        axios({
        method: 'POST',
        url: Constant.url + "keyword-wise-report",
        data: data
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    dataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data.list
                    },
                    stats:{
                        all_messages:result.data.data.stats.all_messages,
                        all_conversations:result.data.data.stats.all_conversations,
                    } 
                })
                $("#AddTeamModal").removeClass('uk-flex uk-open');
            }else{
                classRef.setState({
                  dataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
              })
              $("#AddTeamModal").removeClass('uk-flex uk-open');
              }


        });

    }


    clearFilters = () => {
        $("#from_date").val("");
        $("#to_date").val("");
      }
    


    
advSearchClear = () => {
    let classRef = this;     
        classRef.setState({ search:""})
        classRef.callApi()
        $("#cross").hide()
  }




  render() {

    return (
        <Content>
            <div className="TeamContainer BroadcastingContainer contactContainer" uk-height-viewport="" uk-height-match=".h_match">
                
                <div className="head">
                    <div className=" uk-grid " uk-grid="">
                        <div className="uk-width-auto"><h3>Keyword wise Report</h3></div>
                        <div className="uk-width-1-3">
                            <div className="search_field">
                                <form action="javascript:;">
                                    <input className="uk-input" value={this.state.search} onChange={this.searchrecord} name="searchword" id="searchword"  placeholder="Search through msisdn" type="text"/>                                   
                                    <div class="CampaignReports Filtermain">
                                        <div className="uk-width-auto">
                                        <button id='cross' style={{right: "30px"}} onClick = {this.advSearchClear}><Close/></button>
                                            <button onClick = {this.addSearchModal}>
                                                <img src="/images/newfilter.svg" uk-svg="" />
                                                {/* <Search onClick = {this.addSearchModal}/> */}
                                            </button>
                                        </div>
                                    </div>    
                                </form>
                            </div>
                        </div>

                        {/* <div className="uk-width-expand">
                            <div className="searchnFlowBtn">
                                <button className="newFlowBtn" onClick={this.addTeamModal}><Add /> <span>Add Team</span></button>
                            </div>
                        </div> */}


                    </div>
                </div>

                <div className="stats_container">
                                <div className="uk-child-width-expand@s uk-grid uk-grid-collapse" uk-grid="">
                                    <div>
                                        <div className="box" uk-height-match="">
                                            <div className="text">
                                                <h6>{this.state.stats.all_messages}</h6>
                                                <span>All Messages</span>
                                            </div>
                                          <div className="icon">
                                          <MessageQueue />
                                            <div className="info" uk-tooltip="Number of Messages"><Information /></div>
                                          </div>

                                           </div>
                                    </div>
                                    <div>
                                        <div className="box" uk-height-match="">
                                            <div className="text">
                                                <h6>{this.state.stats.all_conversations}</h6>
                                                <span>All Conversations</span>
                                            </div>
                                            <div className="icon">
                                            <Chat />
                                                <div className="info" uk-tooltip="Number of Conversations"><Information /></div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div>
                                        <div className="box" uk-height-match="">
                                            <div className="text">
                                                <h6>0</h6>
                                                <span>Read</span>
                                            </div>
                                            <div className="icon">
                                                <ViewFilled />
                                                <div className="info" uk-tooltip="Number of Read Messages"><Information /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="box" uk-height-match="">
                                            <div className="text">
                                                <h6>0</h6>
                                                <span>Replied</span>
                                            </div>
                                            <div className="icon">
                                                <ReplyAll />
                                                <div className="info" uk-tooltip="Number of Replied Messages"><Information /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="box" uk-height-match="">
                                            <div className="text">
                                                <h6>0</h6>
                                                <span>Sending</span>
                                            </div>
                                            <div className="icon">
                                                <Send />
                                                <div className="info" uk-tooltip="Number of Sending Messages"><Information /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="box" uk-height-match="">
                                            <div className="text">
                                                <h6>0</h6>
                                                <span>Failed</span>
                                            </div>
                                            <div className="icon">
                                                <NotSent />
                                                <div className="info" uk-tooltip="Number of Failed Messages"><Information /></div>
                                            </div>
                                        </div>
                                    </div> */}
                                   
                                </div>
                            </div>



                <div className="tableSec">
                    <table className="uk-table uk-table-small uk-table-divider">
                        <thead>
                            <tr>
                                <th>TELCO OPEATOR</th>
                                <th>MISISDN</th>
                                <th>MESSAGE SENT</th>
                                <th>MESSAGE RECIEVED</th>
                                <th>STATUS</th>
                                <th>DATE | TIME</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : (this.state.dataList.msg == 'not') ? <tr><td colSpan="15"><div className="uk-text-center uk-height-large">Data not Found</div></td></tr>: <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr> }
                        </tbody>
                    </table>
                </div>
            </div>


            <div id="AddTeamModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="">
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button"> <Close /></button>
                    <h3>Advance Search</h3>
                    <form className="uk-form-stacked" action="javascript:void(0);">

                        <div className="uk-margin">
                            <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="ToDate">To Date</label>
                                    <div class="uk-form-controls">
                                        <input type="date" label="Select Broadcast" className="to_date" id="to_date"/>
                                        
                                    </div>
                            </div>


                            <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="FromDate">From Date</label>
                                    <div class="uk-form-controls">
                                        <input type="date" label="Select Broadcast" className="from_date" id="from_date"/>
                                    </div>
                            </div>
                        </div>

                        {/* <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">keyword</label>
                            <div className="uk-form-controls">
                            <select className="uk-select" id="keyword">
                            <option value="" disabled selected >Select keyword</option>
                            {(this.state.keyList.isLoaded) ? this.handleKeywords() : ""}
                            </select>
                            </div>
                        </div> */}

                        <div className="uk-margin">
                              <div className="ModalStyleBtns ClearFiltersBtn ">
                                  <button className="CnclBtn" onClick={this.clearFilters}>Clear Filters</button>
                              </div>
                        </div>   

                        <div className="uk-margin">
                            <div className="ModalStyleBtns CampaignBtn ClearBtns">
                                <button className="CnclBtn" onClick={this.closeSearchModal}>Cancel</button>
                                <button  className="AddTeamBtn" id="addteam" onClick={this.advanceSearch}>Search</button>
                            </div>
                        </div>
                    </form>

                </div>
                </div>







        </Content>
    )
}
}
export default KeywordReport;