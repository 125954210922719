import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import $ from "jquery";
import Constant from "../../../App/Constant";
import IntegrationsSidebar from "../../Common/IntegrationsSidebar";
import axios from "axios";
import { AddAlt, Close, Unlink, TextLink, Settings } from "@carbon/icons-react";
class InstaSettingIntegrate extends React.Component {
  showDisconnect = (mobile, name, id) => {
    $("#txtConfirmDisconnect").val("");
    this.setState({ companyMobile: mobile, companyName: name, channel_id: id });
    $("#disconnectAcountModal").addClass("uk-flex uk-open");
  };

  hideDisconnect = () => {
    this.setState({ companyMobile: "", companyName: "", channel_id: 0 });
    $("#disconnectAcountModal").removeClass("uk-flex uk-open");
  };

  showConnect = (mobile, name, id) => {
    $("#txtConfirmConnect").val("");
    this.setState({ companyMobile: mobile, companyName: name, channel_id: id });
    $("#ConnectAcountModal").addClass("uk-flex uk-open");
  };

  hideConnect = () => {
    this.setState({ companyMobile: "", companyName: "", channel_id: 0 });
    $("#ConnectAcountModal").removeClass("uk-flex uk-open");
  };

  addAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    if ($("input#termsPolicy").is(":checked")) {
      let c_code = $("#country_code").val();
      let number = $("#whatsapp_number").val();
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("c_code", c_code);
      compParams.append("number", number);
      compParams.append("type", "whatsapp");
      compParams.append("name", "Convex");
      axios({
        method: "POST",
        url: Constant.url + "add-company-channel",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          $("#termsPolicy").prop("checked", false);
          $("#whatsapp_number").val("");
          classRef.companyChannelsApi();
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
        }
      });
    } else {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please agree Terms & conditions before send request.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    }
  };

  constructor() {
    super();
    this.companyChannelsApi = this.companyChannelsApi.bind(this);
    this.handleChannels = this.handleChannels.bind(this);
    this.state = {
      accountList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      companyMobile: "",
      companyName: "",
      companyId: 0,
    };
  }

  companyChannelsApi = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let compParams = new FormData();
    compParams.append("company_id", company_id);
    axios({
      method: "POST",
      url: Constant.url + "company-channels",
      data: compParams,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          accountList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  componentDidMount() {
    this.companyChannelsApi();
  }

  connectAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let txtConfirm = $("#txtConfirmConnect").val();
    let channel_id = classRef.state.channel_id;
    if (txtConfirm != "" && txtConfirm == "connect") {
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("channel_id", channel_id);
      compParams.append("is_active", "yes");
      axios({
        method: "POST",
        url: Constant.url + "change-channel-status",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successConnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            $("#ConnectAcountModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
          classRef.companyChannelsApi();
        } else {
          $("#errorConnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
        }
      });
    } else {
      $("#errorConnectAlert").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please type connect in input field.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  disconnectAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let txtConfirm = $("#txtConfirmDisconnect").val();
    let channel_id = classRef.state.channel_id;
    if (txtConfirm != "" && txtConfirm == "disconnect") {
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("channel_id", channel_id);
      compParams.append("is_active", "no");
      axios({
        method: "POST",
        url: Constant.url + "change-channel-status",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successDisconnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            $("#disconnectAcountModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
          classRef.companyChannelsApi();
        } else {
          $("#errorDisconnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
        }
      });
    } else {
      $("#errorDisconnectAlert").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please type disconnect in input field.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  handleChannels = () => {
    let result = "";
    let index = 0;

    result = this.state.accountList.list.map((response, index) => (
      <tr>
        <td width={90}>
          <img className="uk-border-circle" src="/images/whatsapp.png" alt="" />
        </td>
        <td>
          {/* <h5>{response.name}</h5> */}
          <h5>Cocomo Pakistan</h5>
          {/* <p>{response.c_code + response.number}</p> */}
          <p>Instagram ID 423966583059595</p>
        </td>
        <td width={100}>
          <span className="status">{response.status}</span>
        </td>
        <td width={100}>
          <Link to={"/integrations/Instagram-messenger"}>
            {" "}
            <Settings /> Settings
          </Link>
        </td>
        <td width={150}>
          {response.is_active == "Disconnected" ? (
            <button
              onClick={(e) =>
                this.showConnect(
                  response.c_code + response.number,
                  response.name,
                  response.id
                )
              }
              className="disconnect success"
            >
              <TextLink />
              Disconnected
            </button>
          ) : (
            <button
              onClick={(e) =>
                this.showDisconnect(
                  response.c_code + response.number,
                  response.name,
                  response.id
                )
              }
              className="disconnect danger"
            >
              <Unlink />
              Connected
            </button>
          )}
        </td>
      </tr>
    ));
    return result;
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>
                  Integrations <span> {">"} Instagram Messenger</span>
                </h3>
              </div>
            </div>
          </div>
          <IntegrationsSidebar />
          <div className="integration inner h_match bgWhite">
            <div className="uk-flex-top uk-grid">
              <div className="uk-width-3-4">
                <h3>Connected Instagram Account</h3>

                {/**ADD NUM */}
                <div className="addnum_box uk-margin-medium-top addnum_boxLarge">
                  <h4>Connection Status</h4>
                  <div className="uk-grid-medium" uk-grid="">
                    <div className="uk-width-1">
                      <div className="AvailableToggle">
                        <label className="switch">
                          <input
                            type="checkbox"
                            class="EnabledSwitch"
                            id="EnabledSwitch"
                          />
                          <span className="slider round" />
                        </label>
                        <label className="CustomNoti">Enabled</label>
                      </div>
                    </div>
                    <div className="uk-width-3-4">
                      <div className="fields fieldsGrey">
                        <TextField
                          width="100%"
                          marginTop="size-0"
                          maxWidth="100%"
                          label="Instagram Account Name"
                          id="InstagramAccountName"
                          type="text"
                          scale="medium"
                          isRequired
                          necessityIndicator="icon"
                        />
                      </div>
                    </div>
                    <div className="uk-width-1">
                      <h4 className="uk-margin-remove">
                      Refresh Instagram Permissions
                      </h4>
                      <p className="uk-margin-small-top">
                      Instagram can unexpectedly lose the connection to your page permissions. 
                      If you encounter any trouble, like content not sending or setting not saving, always refresh your permissions first.
                      </p>
                    </div>

                    <div className="uk-width-1">
                      <div className="fields uk-margin-top spStyle">
                        <Button
                          className="signinBtn"
                          id=""
                          position="relative"
                          marginTop="size-200"
                          right=""
                          maxWidth="size-1400"
                          variant="cta"
                          onClick={this.addAccount}
                        >
                          Refresh Permissions
                        </Button>
                      </div>
                    </div>
                    <div className="uk-width-1">
                      <h4 className="uk-margin-remove">
                      Remove Instagram Account from Berry Talks
                      </h4>
                      <p className="uk-margin-small-top">
                      You can completely remove Instagram account from Berry Talks
                      if you want to connect another page or disable Instagram channel.
                      </p>
                    </div>
                    <div className="uk-width-1">
                      <div className="fields uk-margin-top spStyle">
                        <button className="confirm">Remove</button>
                      </div>
                    </div>
                  </div>
                </div>

                {/**ADD NUM */}

               
                
                
              </div>
            </div>
          </div>
        </div>

      </Content>
    );
  }
}
export default InstaSettingIntegrate;
