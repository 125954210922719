import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, Checkbox } from "@adobe/react-spectrum";
import Select from "react-select";
import $ from "jquery";
import {
  Checkmark,
  FaceSatisfied,
  UserRole,
  Image,
  Attachment,
  Location,
  DecisionTree,
  Edit,
  StopOutline,
  TrashCan,
  Download,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  Time,
  Credentials,
  Add,
  Close,
} from "@carbon/icons-react";
import PageActionBar from "../../Sections/PageActionBar";

class BulkAction extends React.Component {
  addMarkAsRead = () => {
    $("#AddResolved").addClass("uk-flex uk-open");
  };
  addUnassigned = () => {
    $("#AddUnassigned").addClass("uk-flex uk-open");
  };
  assignConvo = () => {
    UIkit.dropdown(".AssignConvoUser").hide();
    $("#AssignConversation").addClass("uk-flex uk-open");
  };

  render() {
    const options = [
      {
        value: 1,
        label: (
          <div className="chip">
            <img src="/images/avatar.jpg" alt="Image" />
            <span>Usman Ramnani</span>
          </div>
        ),
      },
      {
        value: 2,
        label: (
          <div className="chip">
            <img src="/images/avatar.jpg" alt="Image" />
            <span>Usman Ramnani</span>
          </div>
        ),
      },
    ];
    return (
      <Content>
        <div className="chat_container" uk-height-match=".ht_match">
          <PageActionBar />
          <div className="uk-grid uk-grid-collapse" uk-grid>
            <div className="chat_heads_list  ht_match uk-width-1-4">
              <div className="chat_box_head">
                <div className="mdl">
                  <div className="mdl_inner">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand">
                        <div className="user_selection">
                          <div class="uk-inline">
                            <div className="select_all">
                              <input
                                className="uk-checkbox"
                                type="checkbox"
                                name=""
                              />
                            </div>
                            <button class="" type="button">
                              <span>UNASSIGNED</span>
                              <span uk-icon="triangle-down"></span>
                            </button>
                            <div uk-dropdown="mode: click">
                              <ul>
                                <li>
                                  <button class="active" type="button">
                                    <span className="name">Unassigned</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                                <li>
                                  <button class="" type="button">
                                    <span className="name">Assigned</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                                <li>
                                  <button class="" type="button">
                                    <span className="name">Closed</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                                <li>
                                  <button class="" type="button">
                                    <span className="name">All</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contacts_list">
                <ul>
                  <li>
                    <div
                      className="uk-flex-middle chat_tab uk-grid uk-grid-small"
                      uk-grid=""
                    >
                      <div className="uk-width-auto uk-flex-first">
                        <div className="chat_select">
                          <Checkbox
                            isEmphasized
                            onChange={this.showBox}
                          ></Checkbox>
                        </div>
                      </div>
                      <div className="uk-width-auto uk-flex-first">
                        <div className="pp"></div>
                      </div>
                      <div className="uk-width-expand">
                        <p>Naveen Asim</p>
                        <p>Please make your process...</p>
                      </div>
                      <div className="uk-width-auto uk-flex-last">
                        <div className="time">
                          <p>3m</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="chat_box uk-hidden ht_match uk-width-expand">
              <div className="chat_box_head">
                <div className="uk-grid" uk-grid="">
                  <div className="uk-width-expand">
                    <div className="user_selection">
                      <div class="uk-inline">
                        <button class="" type="button">
                          <div className="icon"></div>
                          <span>Usman Khan</span>
                          <span uk-icon="chevron-down"></span>
                        </button>
                        <div uk-dropdown="mode: click">
                          <ul>
                            <li>
                              <button class="active" type="button">
                                <div className="icon"></div>
                                <span className="name">Usman Ramnani</span>
                                <span className="uk-icon">
                                  <Checkmark />
                                </span>
                              </button>
                            </li>
                            <li>
                              <button class="" type="button">
                                <div className="icon"></div>
                                <span className="name">Yumna Zaidi</span>
                                <span className="uk-icon">
                                  <Checkmark />
                                </span>
                              </button>
                            </li>
                            <li>
                              <button class="" type="button">
                                <div className="icon"></div>
                                <span className="name">Usman Khan</span>
                                <span className="uk-icon">
                                  <Checkmark />
                                </span>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-auto">
                    <div className="seen_status">
                      <button>
                        {" "}
                        <span>
                          <img src="/images/done_all_black_24dp.svg" />
                        </span>{" "}
                        Mark as resolved
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat_date">
                <p>Today, 13:38</p>
              </div>

              <div className="messages_box">
                <ul>
                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <span>👋</span>
                        <span>Get started</span>
                      </div>
                    </div>
                  </li>
                  <li className="prompt_msg">
                    <p className="uk-text-center">
                      Opted-in through widget: 👋 Get Started
                    </p>
                  </li>
                  <li className="support">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <span>
                          We are very sorry, that we could not resolve your
                          issue. Unfortunately, we haveconstantly been looking
                          for solutions and have found none so far.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="prompt_msg">
                    <p className="uk-text-center">
                      Usman Khan attached a note to WhatsApp conversation:
                    </p>
                  </li>
                </ul>
              </div>

              <div className="suggested_msgs">
                <ul>
                  <li>
                    <button>Hi</button>
                  </li>
                  <li>
                    <button>🙋 Your info</button>
                  </li>
                  <li>
                    <button>📱 Preferred No?</button>
                  </li>
                  <li>
                    <button>✉️ Preferred Email?</button>
                  </li>
                  <li>
                    <button>📍 Locate Us</button>
                  </li>
                </ul>
              </div>

              <div className="type_box">
                <ul
                  className="uk-subnav "
                  uk-switcher="animation: uk-animation-fade"
                >
                  <li>
                    <a href="#">Reply</a>
                  </li>
                  <li>
                    <a href="#">Notes</a>
                  </li>
                </ul>

                <ul class="uk-switcher">
                  <li>
                    <div className="placeholder">
                      <textarea placeholder="Reply here"></textarea>
                    </div>
                  </li>
                  <li>
                    <div className="placeholder ii">
                      <textarea placeholder="Leave a note for your team members or reminder for yourself"></textarea>
                    </div>
                  </li>
                </ul>

                <div className="submit_bar">
                  <div className="uk-grid uk-grid-collapse">
                    <div className="uk-width-1-2">
                      <div className="attacments">
                        <ul>
                          <li>
                            <button>
                              <FaceSatisfied />
                            </button>
                          </li>
                          <li>
                            <div class="js-upload" uk-form-custom="">
                              <input type="file" multiple />
                              <button type="button" tabindex="-1">
                                <Image />
                              </button>
                            </div>
                          </li>
                          <li>
                            <button>
                              <Attachment />
                            </button>
                          </li>
                          <li>
                            <button>
                              <Location />
                            </button>
                          </li>
                          <li>
                            <button>
                              <DecisionTree />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className=" action_btns uk-grid uk-grid-small uk-float-right uk-child-width-auto">
                        <div>
                          <button>Send & Close</button>
                        </div>
                        <div>
                          <button className="fill"> Send WhatsApp</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_details uk-hidden ht_match uk-width-1-4">
              <div className="chat_box_head">
                <div className="mdl">
                  <div className="mdl_inner">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand">
                        <div className="profile_name">
                          <h4>Naveen Asim</h4>
                        </div>
                      </div>
                      <div className="uk-width-auto">
                        <div className="profile_action">
                          <div className="uk-inline">
                            <button className="" type="button">
                              <span uk-icon="more"></span>
                            </button>
                            <div uk-dropdown="mode: click">
                              <ul className="action_menu">
                                <li>
                                  <button>
                                    <span className="icon">
                                      <Edit />
                                    </span>{" "}
                                    <span>Edit</span>
                                  </button>
                                </li>
                                <li>
                                  <button>
                                    <span className="icon">
                                      <StopOutline />
                                    </span>
                                    <span>Unsubscribe</span>
                                  </button>
                                </li>
                                <li>
                                  <button>
                                    <span className="icon">
                                      <Download />
                                    </span>
                                    <span>Download Data</span>
                                  </button>
                                </li>
                              </ul>
                              <hr></hr>
                              <ul className="action_menu">
                                <li className="delete">
                                  <button>
                                    <span className="icon">
                                      <TrashCan />
                                    </span>{" "}
                                    <span>Delete</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_content">
                <div className="profile_pic">
                  <img src="/images/" alt="" />
                </div>
                <div className="contact_details">
                  <ul>
                    <li>
                      <button>
                        {" "}
                        <span>
                          <MobileCheck />
                        </span>{" "}
                        <span>033565472945</span>
                      </button>
                    </li>
                    <li>
                      <button>
                        {" "}
                        <span>
                          <CheckmarkOutline />
                        </span>{" "}
                        <span>Subscribed</span>
                      </button>
                    </li>
                    <li>
                      {" "}
                      <span>
                        <GenderFemale />
                      </span>{" "}
                      <span>Female</span>
                    </li>
                    <li>
                      {" "}
                      <span>
                        <Time />
                      </span>{" "}
                      <span>Contact Time: 16:07 (UTC+05)</span>
                    </li>
                    <li>
                      {" "}
                      <span>
                        <Credentials />
                      </span>{" "}
                      <span>5947368681956251</span>
                    </li>
                  </ul>
                </div>
                <div className="pause_automation">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-2-3">
                      <h4>Pause Automation</h4>
                    </div>
                    <div className="uk-width-1-3">
                      <button className="time uk-float-right">
                        <span>
                          <Add />
                        </span>
                        30 min
                      </button>
                    </div>
                  </div>
                  <div className="button">
                    <button>
                      {" "}
                      <span className="awsome"></span> Automatically
                    </button>
                  </div>
                </div>
                <div className="pause_automation ">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-2-3">
                      <h4>Contact Tags</h4>
                    </div>
                    <div className="uk-width-1-3">
                      <button className="time uk-float-right">
                        <span>
                          <Add />
                        </span>
                        Add Tag
                      </button>
                    </div>
                  </div>
                  <div className="tags">
                    <button>
                      {" "}
                      New-customer{" "}
                      <span>
                        <Close />
                      </span>
                    </button>
                    <button>
                      {" "}
                      opt-in-for-SMS{" "}
                      <span>
                        <Close />
                      </span>
                    </button>
                    <button>
                      {" "}
                      user-Registered{" "}
                      <span>
                        <Close />
                      </span>
                    </button>
                    <button>
                      {" "}
                      Loyalty{" "}
                      <span>
                        <Close />
                      </span>
                    </button>
                    <button>
                      {" "}
                      Retail{" "}
                      <span>
                        <Close />
                      </span>
                    </button>
                    <button>
                      {" "}
                      preferred_Email{" "}
                      <span>
                        <Close />
                      </span>
                    </button>
                    <button>
                      {" "}
                      preferred_Phone{" "}
                      <span>
                        <Close />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="pause_automation ">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-1-1">
                      <h4>Opted in through</h4>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="tags uk-margin-remove">
                        <button> 👋 Get Started</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="multi_chat_select_prompt ht_match uk-width-expand">
              <div className="mdl">
                <div className="mdl_inner">
                  <div className="assignment_actions">
                    <ul>
                      <li>
                        <button className="" id="" onClick={this.addMarkAsRead}>
                          <span>
                            <img
                              src="/images/done_all_black_24dp.svg"
                              uk-svg=""
                            />
                          </span>{" "}
                          Mark as resolved
                        </button>
                      </li>
                      <li>
                        <div className="user_selection">
                          <div class="uk-inline uk-width-1-1">
                            <button class="" type="button">
                              <span>
                                <UserRole />
                              </span>
                              Assign Conversations
                            </button>
                            <div
                              uk-dropdown="mode: click"
                              className="AssignConvoUser"
                            >
                              <ul>
                                <li>
                                  <button
                                    class="active"
                                    type="button"
                                    onClick={this.assignConvo}
                                  >
                                    <div className="icon"></div>
                                    <span className="name">Usman Ramnani</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    class=""
                                    type="button"
                                    onClick={this.assignConvo}
                                  >
                                    <div className="icon"></div>
                                    <span className="name">Yumna Zaidi</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    class=""
                                    type="button"
                                    onClick={this.assignConvo}
                                  >
                                    <div className="icon"></div>
                                    <span className="name">Usman Khan</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                        <button className="" id="" onClick={this.addUnassigned}>
                          <span>
                            <img src="/images/user--role.svg" uk-svg="" />
                          </span>{" "}
                          Unassign Conversations
                        </button>
                      </li>
                    </ul>

                    <div className="clear_selection uk-text-center">
                      <Link to="#">Clear Selection</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Mark as resolved </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>2 conversations will be marked as resolved!</b>
              </p>
              <p className="small">
                This action will affect 2 conversations. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick="">
                    Cancel
                  </button>
                  <button className="AddTeamBtn" id="addAgent" onClick="">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="AddUnassigned"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Unassign Conversations </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/warning--alt--filled.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>2 conversations will be marked as resolved!</b>
              </p>
              <p className="small">
                This action will affect 2 conversations. Are you sure?
              </p>
              <div className="uk-margin">
                <label className="uk-form-label">Agents </label>
                <div className="chipBox">
                  <Select
                    defaultValue={[options[0], options[1]]}
                    isMulti
                    name="agents"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick="">
                    Cancel
                  </button>
                  <button className="AddTeamBtn" id="addAgent" onClick="">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="AssignConversation"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Assign Conversations </span>
              <span className="uk-inline uk-float-right">
                <UserRole />
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>2 conversations will be marked as resolved!</b>
              </p>
              <p className="small">
                This action will affect 2 conversations. Are you sure?
              </p>
              <div className="uk-margin">
                <label className="uk-form-label">Agents </label>
                <div className="chipBox">
                  <Select
                    defaultValue={[options[0], options[1]]}
                    isMulti
                    name="agents"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick="">
                    Cancel
                  </button>
                  <button className="AddTeamBtn" id="addAgent" onClick="">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

export default BulkAction;
