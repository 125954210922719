import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../App/Constant";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);


let dummyData = {

  labels: [],
  datasets: [
    {
      fill: false,
      label: 'WhatsApp',
      data:  [],
      borderColor: '#1DB954',
      
    },
  ],


}

export const ChannelsOverviewChart = (stateTo,stateFrom) => {

  const [graphData, setGraphData] = useState(dummyData);

  useEffect(() => {

    formValidation();

}, [stateTo,stateFrom]);


const formValidation = async () => {

  axios({
      method: 'POST',
      url: Constant.url+"day-wise-avg-messages",
      data: {company_id:localStorage.getItem('company_id'),stateTo:stateTo.stateTo,stateFrom:stateTo.stateFrom,channel_type:localStorage.getItem("channeltype")}   
  }).then(result =>{

    var stats = result.data.data;
    // console.log(stats.values,'statscheck')
    setGraphData({

    labels: stats.labels,
    datasets: [
      {
        fill: false,
        label: 'WhatsApp',
        data:  [7000, 27000, 50, 28000, 10000 ],
        borderColor: '#1DB954',
        
      },
      {
        fill: false,
        label: 'Facebook',
        data:  [21000, 17000, 8000, 15000, 27500 ],
        borderColor: '#1877F2',
        
      },
      {
        fill: false,
        label: 'Instagram',
        data:  [8000, 15000, 27500, 16000, 20000 ],
        borderColor: '#FF9F0A',
        
      },
      {
        fill: false,
        label: 'Twitter',
        data:  [800, 9000, 17500, 3000, 10500 ],
        borderColor: '#1AC6FB',
      },
      {
        fill: false,
        label: 'SMS',
        data:  [1200, 9660, 12500, 13000, 12500 ],
        borderColor: '#5BF676',
      },
      {
        fill: false,
        label: 'Email',
        data:  [12000, 19660, 13500, 9000, 2500 ],
        borderColor: '#1AC6FB',
      },
      {
        fill: false,
        label: 'Call Centre',
        data:  [6000, 13660, 11500, 19000, 10500 ],
        borderColor: '#1DB954',
      },
      
    ],

    })

    
  }).catch(err => {

    // console.log(err)
          
  });


}


        const options = {
          responsive: true,
          plugins: {
          legend: {
          position: 'bottom',
          },
          title: {
          display: false,
          // text: 'Chart.js Line Chart',
          },
          },
          }
        
        return <Line options={options} data={graphData}/>;
      }
