import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import $ from "jquery";
import Constant from "../../../App/Constant";
import axios from "axios";
import { AddAlt, Close, Information } from "@carbon/icons-react";
import IntegrationsSidebar from "../../Common/IntegrationsSidebar";
class EditEmailIntegration extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handlename = this.handlename.bind(this);
    this.handlesmpt = this.handlesmpt.bind(this);
    this.handleport = this.handleport.bind(this);
    this.handleusername = this.handleusername.bind(this);
    this.handleencryption = this.handleencryption.bind(this);
    this.handleserver = this.handleserver.bind(this);
    this.handleinport = this.handleinport.bind(this);
    this.handleinusername = this.handleinusername.bind(this);
    this.handleinencryption = this.handleinencryption.bind(this);
    this.handleinpassword = this.handleinpassword.bind(this);
    this.handleoutpassword = this.handleoutpassword.bind(this);

    this.state = {
      name: "",
      email: "",

      out_server: "",
      out_username: "",
      out_password: "",
      out_port: "",
      out_encryption: "",

      in_server: "",
      in_port: "",
      in_username: "",
      in_password: "",
      in_encryption: "",

      in_password: "",
      out_password: "",

      // in_protocol:"",
    };
  }

  handleinpassword(event) {
    this.setState({ in_password: event.target.in_password });
  }

  handleoutpassword(event) {
    this.setState({ out_password: event.target.out_password });
  }

  handlename(event) {
    this.setState({ uname: event.target.uname });
  }

  handlesmpt(event) {
    this.setState({ out_server: event.target.out_server });
  }

  handleport(event) {
    this.setState({ out_port: event.target.out_port });
  }

  handleusername(event) {
    this.setState({ out_username: event.target.out_username });
  }

  handleencryption(event) {
    this.setState({ out_encryption: event.target.out_encryption });
  }

  handleserver(event) {
    this.setState({ in_server: event.target.in_server });
  }

  handleinport(event) {
    this.setState({ in_port: event.target.in_port });
  }

  handleinusername(event) {
    this.setState({ in_username: event.target.in_username });
  }

  handleinencryption(event) {
    this.setState({ in_encryption: event.target.in_encryption });
  }

  componentDidMount() {
    this.callApi();
  }

  callApi = () => {
    var url = window.location.pathname;
    var id = url.split("/");

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "single-company-channel",
      data: {
        company_id: localStorage.getItem("company_id"),
        channel_id: id[4],
      },
    }).then(function (result) {
      if (result.data.status === true) {
        var email = result.data.data;
        classRef.setState({
          uname: email.name,
          email: email.email,
          out_server: email.out_server,
          out_username: email.out_username,
          out_password: email.out_password,
          out_port: email.out_port,
          out_encryption: email.out_encryption,
          in_server: email.in_server,
          in_port: email.in_port,
          in_username: email.in_username,
          in_password: email.in_password,
          in_encryption: email.in_encryption,
          in_password: email.in_password,
          out_password: email.out_password,
        });
      }
    });
  };

  addEmailAccount = () => {
    let classRef = this;
    var data = {};
    var url = window.location.pathname;
    var id = url.split("/");
    data = {
      company_id: localStorage.getItem("company_id"),
      type: "email",
      channel_id: id[2],
      name: $("#name").val(),
      email: $("#username").val(),
      out_server: $("#smpt").val(),
      out_username: $("#username").val(),
      out_password: $("#Password").val(),
      out_port: $("#port").val(),
      out_encryption: $("#encryption").val(),
      in_server: $("#server").val(),
      in_port: $("#inport").val(),
      in_username: $("#inusername").val(),
      in_password: $("#inPassword").val(),
      in_encryption: $("#inencryption").val(),
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-company-channel-email",
      data: data,
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/edit-email/" + id[2];
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );
        $("#AddTeamBtn").text("Save");
        $("#AddTeamBtn").prop("disabled", false);
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);
        return;
      }
    });

    // console.log(data, "hafeeez");
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>
                  Integrations <span> {">"} Email</span>
                </h3>
              </div>
            </div>
          </div>
          <IntegrationsSidebar />
          <div className="integration inner h_match">
            {/* <h4>Popular integrating</h4> */}
            <div className="uk-flex-top uk-grid">
              <div className="uk-width-auto@m uk-flex-first">
                <img
                  className="Fimg"
                  src="/images/Integrations-email.png"
                  alt=""
                />
              </div>
              <div className="uk-width-3-4">
                <h3>Email</h3>
                <p>
                  Create powerful Email marketing campaigns to re-engage your
                  customers. Reach out to your leads, increase engagement, and
                  learn how to win your customers back.
                </p>

                {/**Sending Mail start */}
                <div className="addnum_box uk-margin-medium-top">
                  <h4>Sending Email</h4>
                </div>
                <div className="CustomBgAdmin removePad">
                  <div className="integration inner">
                    <form
                      className="uk-form-stacked"
                      action="javascript:void(0);"
                    >
                      <div id="errorAlert"></div>
                      <div id="successAlert"></div>

                      <div className="MainFormsection emailForm">
                        <div className="uk-grid  uk-grid-small" uk-grid="">
                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">SMTP Server</label>
                            <input
                              class="uk-input"
                              id="smpt"
                              name="smpt"
                              placeholder="Enter SMTP server address"
                              value={this.state.out_server}
                              onClick={this.handlesmpt}
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Port</label>
                            <input
                              class="uk-input"
                              id="port"
                              name="port"
                              placeholder="Enter Port"
                              value={this.state.out_port}
                              onClick={this.handleport}
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">User Name</label>
                            <input
                              class="uk-input"
                              id="username"
                              name="username"
                              type="email"
                              placeholder="Enter user name"
                              value={this.state.out_username}
                              onClick={this.handleusername}
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Password</label>
                            <input
                              class="uk-input"
                              id="Password"
                              name="Password"
                              type="password"
                              placeholder="Password"
                              value={this.state.out_password}
                              onClick={this.handleoutpassword}
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Encription</label>
                            <select
                              className="uk-select"
                              id="encryption"
                              name="encryption"
                              value={this.state.out_encryption}
                              onClick={this.handleencryption}
                            >
                              <option>Choose an encryption method</option>
                              <option value="TLS">TLS</option>
                              <option value="SSL">SSL</option>
                            </select>
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Name</label>
                            <input
                              class="uk-input"
                              id="name"
                              name="name"
                              placeholder="enter name"
                              value={this.state.uname}
                              onChange={this.handlename}
                            />
                          </div>

                          {/* <div className="uk-width-1-1 uk-margin-medium-top">
                                            <div className="ModalStyleBtns dayFormBtn email">
                                                <button className="AddTeamBtn">Save Settings</button>
                                            </div>
                                        </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/**Sending Mail end */}

                {/**Fetching Emails start */}

                <div className="addnum_box uk-margin-medium-top">
                  <h4>Fetching Emails</h4>
                </div>
                <div className=" CustomBgAdmin emailBg removePad">
                  <div className="integration inner">
                    <form
                      className="uk-form-stacked"
                      action="javascript:void(0);"
                    >
                      <div className="MainFormsection emailForm">
                        <div className="uk-grid  uk-grid-small" uk-grid="">
                          {/* <div className="uk-width-1-2@m">
                                            <label className="uk-form-label">Fetch From</label>
                                            <input class="uk-input"  id="fetch"  name="fetch"  placeholder="Enter address to fetch emails from"  />
                                        </div> */}

                          {/* <div className="uk-width-1-2@m">
                                            <label className="uk-form-label">Protocol</label>
                                            <select className="uk-select" id="protocol" name="protocol">
                                                <option>Choose Protocol</option>
                                            </select>
                                        </div> */}

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Server</label>
                            <input
                              class="uk-input"
                              id="server"
                              name="server"
                              value={this.state.in_server}
                              placeholder="Enter email server address"
                              onClick={this.handleserver}
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Port</label>
                            <input
                              class="uk-input"
                              id="inport"
                              name="inport"
                              value={this.state.in_port}
                              placeholder="Enter Port"
                              onClick={this.handleinport}
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">User Name</label>
                            <input
                              class="uk-input"
                              id="inusername"
                              type="email"
                              value={this.state.in_username}
                              name="inusername"
                              placeholder="Enter user name"
                              onClick={this.handleinusername}
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Password</label>
                            <input
                              class="uk-input"
                              id="inPassword"
                              type="password"
                              name="inPassword"
                              placeholder="Password"
                              value={this.state.in_password}
                              onClick={this.handleinpassword}
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Encription</label>
                            <select
                              className="uk-select"
                              id="inencryption"
                              name="inencryption"
                              value={this.state.in_encryption}
                              onClick={this.handleinencryption}
                            >
                              <option>Choose an encryption method</option>
                              <option value="TLS">TLS</option>
                              <option value="SSL">SSL</option>
                            </select>
                          </div>

                          {/* <div className="uk-width-1-1@m label">
                                            <label className="uk-form-label">Validate Certificate</label>
                                        </div>
                                        <div className="uk-width-2-3@m">

                                        <label class="switch">
                                            <input type="checkbox" class="abc" id="chkLive" />
                                                <a  id="tog_button" class="slider round"></a></label>
                                                <span className="uk-margin-left">Enabled  <span className="info" uk-tooltip="dummp text"><Information /></span> </span>
                                        </div> */}
                          <div className="uk-width-1 uk-margin-medium-top">
                            <h4>Validate Certificate</h4>
                          </div>
                          <div className="uk-width-1">
                            <div className="AvailableToggle">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  class="abc"
                                  id="chkLive"
                                  onClick={this.handleToggleChange}
                                />
                                <span className="slider round" />
                              </label>

                              <label className="CustomNoti">Enabled</label>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-medium-top">
                            <div class="ModalStyleBtns">
                              <button
                                class="AddTeamBtn"
                                onClick={this.addEmailAccount}
                              >
                                Save Settings
                              </button>
                              <button class="CnclBtn">Check Connection</button>
                            </div>
                          </div>
                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">
                              Save settings before checking connection.
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/**Fetching Emails end */}
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default EditEmailIntegration;
