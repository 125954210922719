import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content} from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Select from 'react-select'
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import Constant from "../../../App/Constant";
import {ContextualHelp} from '@adobe/react-spectrum'
import {
  CaretDown,
  Checkmark,
  Download,
  MessageQueue,
  InProgress,
  Information,
  ViewFilled,
  ReplyAll,
  Send,
  NotSent,
  Chat,
  Search,
  TrashCan,
  Add,
  AddAlt,
  Edit, 
  ArrowRight,
  OverflowMenuVertical,
  Close
} from "@carbon/icons-react";

class MonthlyActiveUsersDetail extends React.Component {

  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.state = {
      dataList: {
          isLoaded: false,
          msg: 'Loading...',
          list: []
      },
      tag_name :"",
      description :"",
      tag_id:"",
      advancesearch:"",
      rowsCountTags : 0,
      }


  }


  handleNameChange(event) {
    this.setState({ tag_name: event.target.tag_name });
    // console.log("first name updated")
  }

  handleDescriptionChange(event){
    this.setState({ description: event.target.description });
  }




componentDidMount() {
  var pageCount = 1;
  $("#field_wrapper").on('click','.deleteBtn',function(){
    $(this).parent().parent().parent().remove();
  });

  this.callApi(pageCount);
  // if(!localStorage.getItem('role')=='3'){
  //   window.location.href = "/"; 
  //  }

  $("#cross").hide()
}

EditTagModal = (id) =>{
  $("#EditTagModal").addClass('uk-flex uk-open');
  $('.uk-dropdown').removeClass('uk-open');



  this.findtag(id);
}

download = (id) => {

  let classRef = this;
  var tag_id = id;
  var company_id = localStorage.getItem('company_id') 
  window.open(Constant.url +"download-tag?company_id=" +company_id+ "&tag_id=" + tag_id, "_blank")

}


findtag = (id) =>{

let classRef = this;
let company_id = localStorage.getItem('company_id');
let tag_id = id
axios({
method: 'POST',
url: Constant.url + "single-tag",
data: { company_id: company_id ,tag_id :tag_id }
}).then(function (result) {
if (result.data.status === true) {
  var tag = result.data.data

  classRef.setState({
    tag_name:tag.name,
    description:tag.description,
    tag_id :tag.id,
  })
  
}
})

}









closeEditNewBot = (id) =>{
  $("#EditNewBot").removeClass('uk-flex uk-open');

  
}


addTeamModal = () => {
  $("#AddTagModal").addClass('uk-flex uk-open');
}

closeAgentModal = () => {
  $("#AddTagModal").removeClass('uk-flex uk-open');
}


findtemplate = (id) =>{
  let classRef = this;
  let company_id = localStorage.getItem('company_id');
  let template_id = id
  axios({
    method: 'POST',
    url: Constant.url + "single-chat-templates",
    data: { company_id: company_id ,template_id :template_id }
  }).then(function (result) {
    if (result.data.status === true) {
      // console.log(result.data);
      var template = result.data.data
      classRef.setState({
        template_name:template.name,
        temp_description:template.description,
        temp_keywords:template.keywords,
        template_id :template.id,
      })
      
    }
  })
}

  callApi = (pageCount) => {

    let classRef = this;
    var url = window.location.pathname;
    var id = url.split('/');
    axios({
        method: 'POST',
        url: Constant.url + "monthly-active-users-detail",
        data: { company_id: localStorage.getItem('company_id'),page: pageCount,msisdn:id[2] }
    }).then(function (result) {
         
        if (result.data.status === true) {
            classRef.setState({
                dataList: {
                    isLoaded: true,
                    msg: 'Loading...',
                    list: result.data.data
                },
                rowsCountTags : result.data.rowscount
            })
        }else{
  
          classRef.setState({
            dataList: {
                isLoaded: false,
                msg: 'not',
                list: []
            },
            rowsCountTags : result.data.rowscount
        })
        }
    })
  
  }


  
  AutoadvanceSearch = () =>{  

    let classRef = this;
    var url = window.location.pathname;
    var id = url.split('/');
    var data = {};
    data = {
    dateto: $("#to_date").val(),
    datefrom: $("#from_date").val(),
    company_id: localStorage.getItem('company_id'),
    source:localStorage.getItem('channeltype'),
    msisdn:id[2]      
    
    
    }

    var key =''

    if(data.dateto){
    var key = "Date To: "+$("#to_date").val()
    }
    if(data.datefrom){
        var key = key+" Date From: "+$("#from_date").val()
    }
  

    if(key !==''){
        $("#cross").show()
    }

    
    classRef.setState({ advancesearch: key})
  
    axios({
    method: 'POST',
    url: Constant.url + "monthly-active-users-detail",
    data: data
    }).then(function (result) {
        if (result.data.status === true) {

            classRef.setState({
              dataList: {
                  isLoaded: true,
                  msg: 'Loading...',
                  list: result.data.data
              },
              rowsCountTags : result.data.rowscount
          })


            $("#SearchAutoTeamModal").removeClass('uk-flex uk-open');
        }else{
            classRef.setState({
              dataList: {
                  isLoaded: false,
                  msg: 'not',
                  list: []
              },
          })
          $("#SearchAutoTeamModal").removeClass('uk-flex uk-open');
          }
  
  
    });
  
  }


  TagsSorting = (pageCount) => {
    let classRef = this;
    var url = window.location.pathname;
    var id = url.split('/');
    let sortingValue = $("#TagsSortingBtn").val();
    sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
    axios({
        method: 'POST',
        url: Constant.url + "monthly-active-users-detail",
        data: { company_id: localStorage.getItem('company_id'),page: pageCount, limit : sortingValue ,msisdn:id[2]}
    }).then(function (result) {
         
        if (result.data.status === true) {
            classRef.setState({
                dataList: {
                    isLoaded: true,
                    msg: 'Loading...',
                    list: result.data.data
                },
                rowsCountTags : result.data.rowscount
            })
        }else{
  
          classRef.setState({
            dataList: {
                isLoaded: false,
                msg: 'not',
                list: []
            },
            rowsCountTags : result.data.rowscount
        })
        }
    })
  
  }

  handleTagsClick = (data) =>{
    let currentPage = data.selected + 1;
    this.TagsSorting(currentPage);
  }

  



  closeConsent = () =>{
    $("#AddResolved").removeClass('uk-flex uk-open');
  }

 closeConsentss = () =>{
  $("#AddConsent").removeClass('uk-flex uk-open');
}

// deleteTemplate = () => {

//   var id = $("#addconsents").val()
//   let classRef = this;
//   var template_id = id;
//   var company_id = localStorage.getItem('company_id');
//   var is_deleted = 1;

//         axios({
//           method: 'POST',
//           url: Constant.url + "delete-chat-template",
//           data: { company_id: company_id ,template_ids: template_id ,is_deleted: is_deleted}
//       }).then(function (result) {
      
//           if (result.data.code === 200) {
      
//               $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
      
//               window.location.href = "/quick-replies";

          
      
//           }else{
      
//               $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
      
//               setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
  
//               window.location.href = "/quick-replies";   
              
    
      
//           }
             
//       })

// }





addconsent = (id) =>{
  // console.log(id,"sajdain");
  $('.uk-dropdown').removeClass('uk-open');
  $("#AddResolved").addClass('uk-flex uk-open');
  $( "#addconsent" ).val(id)

}

editTag = () => {    
    
  $('#edittags').text('Processing')
  $('#edittags').prop('disabled', true);  

  let classRef = this;

  var data = {};
  data = {
    name: $("#edittagName").val(), 
    description: $("#editdescription").val(),
    tag_id: $("#tag_id").val(),
    company_id:localStorage.getItem('company_id')
  }

  axios({
    method: 'POST',
    url: Constant.url + "tag-edit",
    data: data
  }).then(function (response) {

    if (response.data.code === 200) {

      $("#editsuccessAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');

      window.location.href = "/tag";
    }else{

      $("#editerrorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
      $('#edittags').text('Edit Tag')
      $('#edittags').prop('disabled', false);
      setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
      return;
  

    }
  }).then(function (response) {

  });


};


deletetag = () => {

  var id = $("#addconsent").val()
  let classRef = this;
  var tag_id = id;
  var company_id = localStorage.getItem('company_id');
  var is_deleted = 1;

        axios({
          method: 'POST',
          url: Constant.url + "tag-edit",
          data: { company_id: company_id ,tag_id: tag_id ,is_deleted: is_deleted}
      }).then(function (result) {
      
          if (result.data.code === 200) {
      
              $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
      
              window.location.href = "/tag";
      
          }else{
      
              $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
      
              setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
  
              window.location.href = "/tag";      
      
          }
             
      })

}

searchrecord = () =>{   

  let classRef = this;     
  var url = window.location.pathname;
  var id = url.split('/');   
  
  var searchword = $("#searchword").val();
  this.setState({ advancesearch: searchword});

  if(searchword !==''){
    $("#cross").show()
}

 

  axios({
    method: 'POST',
    url: Constant.url + "monthly-active-users-detail",
    data: { company_id: localStorage.getItem('company_id'),search: searchword,msisdn:id[2]  }
}).then(function (result) {
    if (result.data.status === true) {
        classRef.setState({
            dataList: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data
            },
        })
    }else{

      classRef.setState({
        dataList: {
            isLoaded: false,
            msg: 'not',
            list: []
        },
    })
    }
})

  }




deleteMultiTemp = () => {          
  var ids=[];
  $('input[type=checkbox]').each(function (i) { 
      if($(this).is(":checked")){
       $("#temp_"+$(this).val()).hide();             
       ids[i] =$(this).val();
      }
  });
    
    let classRef = this;          
    axios({
        method: 'POST',
        url: Constant.url+"delete-chat-template",
        data :{
          company_id :localStorage.getItem('company_id'),
          template_ids :ids            
        }                       
    }).then(function (result) {
        if(result.data.status === true){                          
           window.location.reload();                           
        }
    })      
}

selectCheckboxTemp = () =>{
  if(!this.state.checkAll){
      this.setState({checkAll:true},function(){
          $('#select_alls').prop('checked',true);  
          $('.autoSelects').prop('checked',true);  
      })
  }else{
      this.setState({checkAll:false},function(){
          $('#select_alls').prop('checked',false);  
          $('.autoSelects').prop('checked',false);  
      })
  }
}



addSearchModal = () => {
  $("#SearchTeamModal").addClass('uk-flex uk-open');
}

closeSearchModal= () => {
  $("#SearchTeamModal").removeClass('uk-flex uk-open');
}





advanceSearch = () =>{  

  let classRef = this;
  var data = {};
  data = {
  dateto: $("#to_date").val(),
  datefrom: $("#from_date").val(),
  status: $("#status").val(),    
  }

  var key =''

  if(data.dateto){
  var key = "Date To: "+$("#to_date").val()
  }
  if(data.datefrom){
    var key = key+" Date From: "+$("#from_date").val()
  }
  if(data.status){
    var key = key+" Status: "+$("#status :selected").text()
  }
  
  classRef.setState({ advancesearch: key})

  axios({
  method: 'POST',
  url: Constant.url + "companies",
  data: data
  }).then(function (result) {
      if (result.data.status === true) {
          classRef.setState({
              dataList: {
                  isLoaded: true,
                  msg: 'Loading...',
                  list: result.data.data
              },
          })
          $("#SearchTeamModal").removeClass('uk-flex uk-open');
      }else{
          classRef.setState({
            dataList: {
                isLoaded: false,
                msg: 'not',
                list: []
            },
        })
        $("#SearchTeamModal").removeClass('uk-flex uk-open');
        }


  });

}

advSearchClear = () => {
  let classRef = this;     
      classRef.setState({ advancesearch:""})
      classRef.callApi()
      $("#cross").hide()
      

}


addNewTemplate = () => {
  $("#NewBot").addClass('uk-flex uk-open');
}

closeNewBot = () => {
  $("#NewBot").removeClass('uk-flex uk-open');
}

createTag = () => {

  $('#addtag').text('Processing')
  $('#addtag').prop('disabled', true);
  let classRef = this;

  var data = {};
  data = {
      tag_name: $("#newtagName").val(),
      description: $("#description").val(),
      company_id: localStorage.getItem('company_id'),          
  }
  axios({
      method: 'POST',
      url: Constant.url + "add-tag",
      data: data
  }).then(function (response) {


     
      if (response.data.code === 200) {

      $("#successAlertss").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');

      

      window.location.href = "/tag";


      }else{

        $("#errorAlertss").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
        $('#addtag').text('Create Tag')
        $('#addtag').prop('disabled', false);
        setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
        return;

      }
  }).then(function (response) {

  });

};


deleteMultiTag = () => {          
  var ids=[];
  $('input[type=checkbox]').each(function (i) { 
      if($(this).is(":checked")){
       $("#mtag_"+$(this).val()).hide();             
       ids[i] =$(this).val();
      }
  });
    
    let classRef = this;          
    axios({
        method: 'POST',
        url: Constant.url+"bulk-delete-tags",
        data :{
          company_id :localStorage.getItem('company_id'),
          tag_ids :ids            
        }                       
    }).then(function (result) {
        if(result.data.status === true){                          
           window.location.reload();                           
        }
    })      
}

selectCheckboxTag = () =>{
if(!this.state.checkAll){
    this.setState({checkAll:true},function(){
        $('#select_all').prop('checked',true);  
        $('.autoSelect').prop('checked',true);  
    })
}else{
    this.setState({checkAll:false},function(){
        $('#select_all').prop('checked',false);  
        $('.autoSelect').prop('checked',false);  
    })
}
}



downloadtags = (id) => {

  let classRef = this;
  var url = window.location.pathname;
  var id = url.split('/');
  var msisdn=id[2]
  var dateto=$("#to_date").val();
  var datefrom=$("#from_date").val();
  window.open(Constant.url +"monthly-active-users-detail-export?company_id=" + localStorage.getItem('company_id')+"&datefrom="+datefrom+""+"&dateto="+dateto+"&msisdn="+msisdn+"", "_blank")


}


addAutoSearchModal = () => {
  $("#SearchAutoTeamModal").addClass('uk-flex uk-open');
}

clearFilters = () => {
  $("#to_date").val("");
  $("#from_date").val("");
  $('#keyword').val("");
}






handleTable = () => {
  let result = "";
  let index = 0;
  result = this.state.dataList.list.map((response, index) => (

    <tr id={"mtag_"+response.msisdn}>
      <td>
      <span>{response.msisdn}</span>
      </td>
      <td>
      <span>{response.business_initiated}</span>
      </td>

      <td>
      <span>{response.user_initiated}</span>
      </td>

      <td>
      <span>{response.insert_time}</span>
      </td>



   
  </tr>

  ));

return result;
}


  render() {
   
    
    const options = [
        { value: 'Urgent', label: 'Urgent' },
        { value: 'Resolved', label: 'Resolved' }
      ]
    return (
      <Content>
        <div
          className="contactContainer BroadcastingContainer "
          uk-height-viewport=""
          uk-height-match=".h_match" 
        >
          <div className="head">
                <div className=" uk-grid " uk-grid="">
                    <div className="uk-width-1-2@m">
                    <div className="tagInputLabel"><h3>MAU Details</h3></div>
                    <div className="tagInput">
                        <div className="search_field">
                            <form action="javascript:void(0);">
                                <input className="uk-input" type="text" onChange={this.searchrecord} name="searchword" id="searchword" value={this.state.advancesearch}  placeholder="Search through msisdn"/>
                            </form>
                            <div class="CampaignReports Filtermain">
                                        <div className="uk-width-auto">
                                        <button id='cross' style={{right: "30px"}} onClick = {this.advSearchClear}><Close/></button>
                                          

                                        <button className="popadvanceFilter" onClick = {this.addAutoSearchModal}>
                                                <img src="/images/newfilter.svg" uk-svg=""/>
                                                {/* <Search onClick = {this.addSearchModal}/> */}
                                        </button>


                                        </div>
                            </div> 

                            





                        </div>
                    </div>
                    </div>
                    
                    <div className="uk-width-1-2@m">
                      {/* <button className="newFlowBtn uk-float-right" onClick={this.addTeamModal} ><Add /> <span>New Tag</span></button> */}
                      <button class="newFlowBtn uk-float-right uk-margin-right"  onClick={this.downloadtags}><Add/> <span>Export MAU Details</span></button>
                    </div>
                </div>
            </div>
            <div className="numberSorting uk-float-right">
              <div class="uk-inline">
                  <label>Show
                  <select onChange={(e)=>this.TagsSorting()} id="TagsSortingBtn">
                      {/* <option disabled selected>Sorting</option> */}
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                  </select>
                  entries</label>
              </div>
          </div> 

          <div className="tableSec">
            
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>MSISIDN</th>
                  <th>BUSINESS INITIATED</th>
                  <th>USER INITIATED</th>
                  <th>DATE</th>           
                  
                </tr>
              </thead>

              <tbody>
                {(this.state.dataList.isLoaded) ? this.handleTable() : (this.state.dataList.msg == 'not') ? 
                <tr>
                  <td colSpan="15"><div className="uk-text-center uk-height-large">Data not Found</div>
                  </td>
                </tr>: 
                <tr>
                  <td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div>
                  </td>
                </tr> }
              </tbody>
              {/* <tbody>
                
                {(this.state.dataList.isLoaded) ? this.handleTable() : (this.state.dataList.msg == 'not') ? 
                <tr>
                  <td colSpan="15">
                    <div className="uk-text-center uk-height-large">Data not Found</div>
                    </td>
                </tr>: 
                <tr>
                  <td colSpan="15">
                    <div className="uk-text-center uk-height-large">
                      <div className="mdl">
                        <div className="mdl_inner">
                          <div uk-spinner=""></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr> }
              </tbody> */}
             
            </table>
            {(this.state.rowsCountTags > 1) ? 
                  <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'....'}
                      pageCount={this.state.rowsCountTags}
                      onPageChange={this.handleTagsClick}
                      containerClassName={'uk-pagination uk-float-right'}
                      activeClassName={'uk-active'}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                  /> : ""
              }
          </div>
        </div>




                <div id="SearchAutoTeamModal" className="AddTeamModal ModalStyle uk-flex-top" uk-modal="">
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button"> <Close /></button>
                    <h3>Advance Search</h3>
                    <form className="uk-form-stacked" action="javascript:void(0);">

                        <div className="uk-margin">


                            <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="ToDate">From Date</label>
                                    <div class="uk-form-controls">
                                        <input type="date" label="Select Broadcast" className="to_date" id="from_date"/>
                                    </div>
                            </div>

                            <div className="Campaigndate">
                                <label className="uk-form-label" htmlFor="FromDate">To Date</label>
                                    <div class="uk-form-controls">
                                        <input type="date" label="Select Broadcast" className="from_date" id="to_date"/>
                                    </div>
                            </div>

                        </div>

                        

                        <div className="uk-margin">
                              <div className="ModalStyleBtns ClearFiltersBtn">
                                  <button className="CnclBtn" onClick={this.clearFilters}>Clear Filters</button>
                              </div>
                        </div> 


                        <div className="uk-margin">
                            <div className="ModalStyleBtns CampaignBtn ClearBtns">
                                <button className="CnclBtn uk-modal-close">Cancel</button>
                                <button  className="AddTeamBtn" id="addteam" onClick={this.AutoadvanceSearch}>Search</button>
                            </div>
                        </div>
                    </form>

                </div>
                </div>

                
      </Content>
    );
  }
}
export default MonthlyActiveUsersDetail;
