import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown
  } from "@carbon/icons-react";
import InsightsLiveChatAnalytics from "./InsightsLiveChatAnalytics";
import LiveChatOverview from "./LiveChatOverview";
import LiveChatAgent from "./LiveChatAgent";
import LiveChatQueries from "./LiveChatQueries";
import LiveChatVOC from "./LiveChatVOC";
import LiveChatChanel from "./LiveChatChanel";
import LiveChatTags from "./LiveChatTags";
import InsightChatboxOverview from "./InsightChatboxOverview";
import InsightChatBotAnalysis from "./InsightChatBotAnalysis";
import InsightChatbotConversionalAnalytics from "./InsightChatbotConversionalAnalytics";
import { ChatBotUserChart } from "../../Sections/ChatBotUserChart";
import ChatBotUserAnalytic from "./ChatBotUserAnalytic";


  
class InsightChatbox extends React.Component {

    render() {
        return (
         <>
            <div uk-grid="">
                <div className="uk-width-1-1">
                    <div className="tabsInner">
                        <ul className="tabsBtns" uk-switcher="connect: .InnerMultiTabs1">
                            <li>
                                <a href="#">Overview</a>
                            </li>
                            <li>
                                <a href="#">Session analytics</a>
                            </li>
                            <li>
                                <a href="#">Conversational analytics</a>
                            </li>
                            <li>
                                <a href="#">User analytics</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="uk-width-1-1">
                    <ul className="uk-switcher InnerMultiTabs1">
                        <li><InsightChatboxOverview/> </li>
                        <li><InsightChatBotAnalysis/></li>
                        <li><InsightChatbotConversionalAnalytics/></li>
                        <li><ChatBotUserAnalytic/></li>
                        
                    </ul>
                </div>
            </div>
         </>
    );
}
}
export default InsightChatbox;