import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { Multiselect } from "multiselect-react-dropdown";
import {
  Add,
  CaretDown,
  Edit,
  TrashCan,
  Chat,
  OverflowMenuVertical,
  Download,
  Close,
  Checkmark,
} from "@carbon/icons-react";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import moment from "moment";

class StatsReport extends React.Component {
  constructor() {
    super();
    this.getToken = this.getToken.bind(this);
    this.callApi = this.callApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      token: "",
      rowsCountStats: 0,
      advancesearch: "",
    };
  }

  componentDidMount() {
    var pageCount = 1;
    this.getToken();
    // this.callApi(pageCount);
    $("#cross").hide();
  }

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    $("#to_dates").val("");
    $("#from_dates").val("");
    classRef.callApi(this.state.token, 1);
    $("#cross").hide();
  };

  EditTeamModal = (id) => {
    localStorage.setItem("stats_conversation_id", id);

    window.location.href = "/chat";
  };

  EditTeamBotModal = (id) => {
    localStorage.setItem("bot_stats_conversation_id", id);

    window.location.href = "/bot-chat";
  };

  callApi = (token, pageCount) => {
    let classRef = this;
    let sortingValue = $("#QuickSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;

    
    axios({
      method: "POST",
      url: Constant.stats_api_url + "dataToExcel/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: {
        company_id: localStorage.getItem("company_id"),
        end_date: "",
        start_date: "",
        limit: sortingValue,
        page: pageCount,
        channel_type: "whatsapp",
      },
    }).then(function (result) {
      if (
        result.data.status === true &&
        result.data.message != "No Record Found"
      ) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountStats: result.data.pagecount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountStats: result.data.pagecount,
        });
      }
    });
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    this.setState({ advancesearch: searchword });

    let sortingValue = $("#QuickSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;

    classRef.setState({
      dataList: {
        isLoaded: false,
      },
    });
    if (searchword !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.stats_api_url + "dataToExcelSearchByAgentName/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: "Bearer " + this.state.token,
      },
      data: {
        company_id: localStorage.getItem("company_id"),
        end_date: $("#to_dates").val(),
        start_date: $("#from_dates").val(),
        channel_type: "whatsapp",
        search_query: searchword,
        page: 1,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (
        result.data.status === true &&
        result.data.message != "No Record Found"
      ) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountStats: result.data.pagecount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountStats: result.data.pagecount,
        });
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response) => (
      <tr>
        <td>
          <span>{response.AccountName}</span>
        </td>
        <td>{response.AgentName}</td>
        <td>{response.ChannelName}</td>
        <td>{response.ConversationId}</td>
        <td>{response.CustomerContact}</td>
        <td>{response.CustomerName}</td>
        <td>{response.FirstAgentResponse}</td>
        <td>{response.FirstAssignedToAgentAt}</td>
        <td>{response.InboundMessages}</td>
        <td>{response.OutboundMessages}</td>
        <td>{response.conversationStatus}</td>
        <td>{response.conversationTopic}</td>
        <td>{response.created_at}</td>
        <td>{response.resolutionTime}</td>
        <td>{response.tags}</td>
        <td>{response.totalMessages}</td>
        <td>{response.updated_at}</td>
        <td>{response.waitTime}</td>
      </tr>
    ));
    return result;
  };

  downloadStats = () => {
    $("#stats-download").text("downloading...");
    var channel_type = "whatsapp";
    var apiUrl = "";
    var start_date = $("#from_dates").val();
    var end_date = $("#to_dates").val();

    if (start_date == "" && end_date == "") {
      apiUrl =
        Constant.stats_api_url +
        "download_excel/?company_id=" +
        localStorage.getItem("company_id") +
        "&channel_type=" +
        channel_type +
        "&start_date=" +
        "2022-10-01" +
        "&end_date=" +
        moment(new Date()).format("YYYY-MM-DD");
    } else {
      apiUrl =
        Constant.stats_api_url +
        "download_excel/?company_id=" +
        localStorage.getItem("company_id") +
        "&channel_type=" +
        channel_type +
        "&start_date=" +
        start_date +
        "&end_date=" +
        end_date;
    }
    axios({
      url: apiUrl,
      method: "GET",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: "Bearer " + this.state.token,
      },
      responseType: "blob",
    }).then((response) => {
      $("#stats-download").text("Export Stats");
      const outputFilename = "data.xls";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    });
  };

  handleQuickClick = (data) => {
    let currentPage = data.selected + 1;
    this.QuickSorting(currentPage);
  };

  getToken = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url1 + "/api/auth/TokenGenerate",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      data: {
        email: "info@convexinteractive.com",
        password: "convex123",
      },
    }).then(function (result) {
      classRef.setState({ token: result.data.data.access_token });
      classRef.callApi(result.data.data.access_token, 1);
    });
  };

  QuickSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#QuickSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;

    let pageCountVal = pageCount == undefined ? 1 : pageCount;
    axios({
      method: "POST",
      url: Constant.stats_api_url + "dataToExcel/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: "Bearer " + this.state.token,
      },
      data: {
        company_id: localStorage.getItem("company_id"),
        end_date: $("#to_dates").val(),
        start_date: $("#from_dates").val(),
        limit: sortingValue,
        page: pageCountVal,
        channel_type: "whatsapp",
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountStats: result.data.pagecount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountStats: result.data.pagecount,
        });
      }
    });
  };

  closeAutoSearchModal = () => {
    $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
  };

  addAutoSearchModal = () => {
    $("#SearchAutoTeamModal").addClass("uk-flex uk-open");
  };

  AutoadvanceSearch = () => {
    let sortingValue = $("#QuickSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    let classRef = this;
    var data = {};
    data = {
      company_id: localStorage.getItem("company_id"),
      end_date: $("#to_dates").val(),
      start_date: $("#from_dates").val(),
      channel_type: "whatsapp",
      limit: sortingValue,
      page: 1,
    };

    var key = "";

    if (data.end_date) {
      var key = "Date To: " + $("#to_dates").val();
    }
    if (data.start_date) {
      var key = key + " Date From: " + $("#from_dates").val();
    }

    if (key !== "") {
      $("#cross").show();
    }

    classRef.setState({ advancesearch: key });

    axios({
      method: "POST",
      url: Constant.stats_api_url + "dataToExcel/",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: "Bearer " + this.state.token,
      },
      data: data,
    }).then(function (result) {
      if (result.data.status === true && result.data.data.length > 0) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountStats: result.data.pagecount,
        });

        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountStats: result.data.pagecount,
        });
        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  render() {
    return (
      <Content>
        <div
          className="TeamContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head new-container">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Stats Report</h3>
              </div>
              <div className="uk-width-expand">
                <div className="tagInput">
                  <div className="search_field">
                    <form action="javascript:void(0);">
                      <input
                        className="uk-input"
                        type="text"
                        onChange={this.searchrecord}
                        name="searchword"
                        id="searchword"
                        value={this.state.advancesearch}
                        placeholder="Search by agent name"
                      />
                    </form>
                    <div class="CampaignReports Filtermain">
                      <div className="uk-width-auto">
                        <button
                          id="cross"
                          style={{ right: "30px", display: "block" }}
                          onClick={this.advSearchClear}
                        >
                          <Close />
                        </button>

                        <button
                          className="popadvanceFilter"
                          onClick={this.addAutoSearchModal}
                        >
                          <img src="/images/newfilter.svg" uk-svg />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-auto">
                <button
                  className="newFlowBtn uk-margin-small-top"
                  id="stats-download"
                  onClick={this.downloadStats}
                >
                  <Add />
                  <span>Export Stats</span>
                </button>

                {/* {/* <a onClick={this.downloadStats}>Download</a> */}
              </div>
            </div>
          </div>
          <div className="uk-grid">
            <div className="uk-width-expand"></div>

            <div className="numberSorting uk-float-right">
              <div class="uk-inline">
                <label>
                  Show
                  <select
                    onChange={(e) => this.QuickSorting()}
                    id="QuickSortingBtn"
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>50</option>
                    <option>500</option>
                  </select>
                  entries
                </label>
              </div>
            </div>
          </div>

          <div className="tableSec">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-small uk-table-divider">
                <thead>
                  <tr>
                    <th>Account Name</th>
                    <th>Agent Name</th>
                    <th>Channel Name</th>
                    <th>Conversation Id</th>
                    <th>Customer Contact</th>
                    <th>Customer Name</th>
                    <th>First Agent Response</th>
                    <th>First Assigned To Agent At</th>
                    <th>Inbound Messages</th>
                    <th>Outbound Messages</th>
                    <th>Conversation Status</th>
                    <th>Conversation Topic</th>
                    <th>Created At</th>
                    <th>Resolution Time</th>
                    <th>Tags</th>
                    <th>Total Messages</th>
                    <th>Updated At</th>
                    <th>Wait Time</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.dataList.isLoaded ? (
                    this.handleTable()
                  ) : this.state.dataList.msg == "not" ? (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          Data not Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          <div className="mdl">
                            <div className="mdl_inner">
                              <div uk-spinner=""></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {this.state.rowsCountStats > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"...."}
                  // pageCount={Math.ceil(this.state.rowsCountStats / 10)}
                  pageCount={Math.ceil(this.state.rowsCountStats)}
                  onPageChange={this.handleQuickClick}
                  containerClassName={"uk-pagination uk-float-right"}
                  activeClassName={"uk-active"}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={6}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* auto search start */}

        <div
          id="SearchAutoTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_dates"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_dates"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn ">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeAutoSearchModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.AutoadvanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* auto search end */}
      </Content>
    );
  }
}
export default StatsReport;
