import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { ContextualHelp } from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import PageActionBar from "../../Sections/PageActionBar";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import ReactPaginate from "react-paginate";
import {
  Search,
  Filter,
  CaretDown,
  Information,
  Add,
  Edit,
  TrashCan,
  Download,
  OverflowMenuVertical,
  Close,
  AddAlt,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  CloudUpload,
  DocumentImport,
  Time,
} from "@carbon/icons-react";
import { Multiselect } from "multiselect-react-dropdown";

class Audience extends React.Component {
  constructor() {
    super();
    this.callContactApi = this.callContactApi.bind(this);
    this.callSegmentApi = this.callSegmentApi.bind(this);
    this.getTags = this.getTags.bind(this);
    this.onSelectTags = this.onSelectTags.bind(this);
    this.onRemoveTags = this.onRemoveTags.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.state = {
      uploadfiles : "" ,
      ContactList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      SegmentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      country: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      city: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      checkAlls: false,

      filterType: "is",
      popUpName: "",
      popUpNumber: "",
      popUpGender: "",
      popUpTag: "",
      popUpSunscribe: "",
      popUpContactTime: "",
      popAvatar: "",
      tagList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      tags: [],
      old_tags: [],
      old_contact_tags: [],
      rowsCountContact: 0,
      rowsCountSegments: 0,
      phoneintlnumber:"",
      phoneintlnumberedit:"",
    };
  }


  addContactProfile = (
    contact_name,
    phone,
    gender,
    tags,
    subscribed,
    contact_time,
    id,
    avatar,
    tag2
  ) => {
    this.setState({
      popUpName: contact_name,
      popUpNumber: phone,
      popUpGender: gender,
      popUpTag: tags,
      popUpSunscribe: subscribed,
      popUpContactTime: contact_time,
      popUpId: id,
      popAvatar: avatar,
      checkAll: false,
      old_contact_tags: tag2,
    });
    this.contactTags(tags, id);
    $("#ContactProfile").addClass("uk-flex uk-open");
  };

  contactTags = (tags, id) => {
    var cid = id;
    var ctags = [];
    var ctags = tags ? tags.split(",") : "";
    $("#tags").empty();
    for (let k = 0; k < ctags.length; k++) {
      $("#tags").append(
        "<div class='button' id='tags_" +
          (k + 1) +
          "'> " +
          ctags[k] +
          " <button data-id='" +
          cid +
          "' value='" +
          ctags[k] +
          "' id='" +
          k +
          "' onclick='removeContactTags(" +
          (k + 1) +
          ",this.value)'><span  uk-icon='close'></span></button></div>"
      );
    }
  };


  AddImportcontactModal = () => {
      $("#ImportcontactModal").addClass('uk-flex uk-open');
  }

  hideImportcontactModal = () => {
      $("#ImportcontactModal").removeClass('uk-flex uk-open');
  }

  CloseImportcontactModal = () => {
      $("#ImportcontactModal").removeClass('uk-flex uk-open');
  }

uploadFile = () =>{
    $('#uploadButton').text('Processing')
    $('#uploadButton').prop('disabled', true); 
    let classRef = this;
    let company_id = localStorage.getItem('company_id');
    let segment_name = $('#segment_name').val();
    let importParams = new FormData();
    importParams.append( 'company_id', company_id );
    importParams.append( 'segment_name', segment_name );
    importParams.append( 'file', classRef.state.uploadfiles );
    if(segment_name != ""){
        if(classRef.state.uploadfiles != ""){
            axios({
                method: 'POST',
                url: Constant.url+"import-contacts",
                data: importParams            
            }).then(function (result) {
                if(result.data.status === true){     
                    $("#errorAlertContactImport").append('<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Contact uploaded successfully.</p></div>');                     
                    $('#uploadButton').text('Import')
                    $('#uploadButton').prop('disabled', false);   
                    setTimeout(function(){
                        UIkit.alert('#msg-dialog1').close();
                        classRef.hideImportcontactModal();
                    },1000);
                   
                }
            })
        } else {
            $("#errorAlertContactImport").append('<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please upload file.</p></div>');
            $('#uploadButton').text('Import')
            $('#uploadButton').prop('disabled', false); 
            setTimeout(function(){UIkit.alert('#msg-dialog1').close();},1000);
        }

    } else {
        $("#errorAlertContactImport").append('<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please Enter Segment Name.</p></div>');
        $('#uploadButton').text('Import')
        $('#uploadButton').prop('disabled', false); 
        setTimeout(function(){UIkit.alert('#msg-dialog1').close();},1000);
    }

}

onFileUpload = (event) =>{
    let classRef = this;
    if(event.target.files[0] != undefined){
        $("#uploadedFile").empty();
        $("#uploadedFile").append("Uploaded File : "+event.target.files[0].name);
        classRef.setState({ uploadfiles: event.target.files[0] });
    }
}

  closeContactProfile = () => {
    this.callContactApi();
    $("#ContactProfile").removeClass("uk-flex uk-open");
  };

  componentDidMount() {
    var pageCount = 1;
    this.callContactApi(pageCount);
    this.callSegmentApi(pageCount);
    this.getTags();
    this.getCountry();
    this.getCity();
    this.getAgents();
    var maxField = 10; //Input fields increment limitation
    var addButton = $(".add_button"); //Add button selector
    var wrapper = $(".field_wrapper"); //Input field wrapper
    var fieldHTML =
      '<div><input type="text" name="field_name[]" value=""/><a href="javascript:void(0);" class="remove_button"><img src="remove-icon.png"/></a></div>'; //New input field html
    var x = 1; //Initial field counter is 1

    //Once add button is clicked
    $(addButton).click(function () {
      //Check maximum number of input fields
      if (x < maxField) {
        x++; //Increment field counter
        $(wrapper).append(fieldHTML); //Add field html
      }
    });

    //Once remove button is clicked
    $(wrapper).on("click", ".remove_button", function (e) {
      e.preventDefault();
      $(this).parent("div").remove(); //Remove field html
      x--; //Decrement field counter
    });

    if(localStorage.getItem('loginStatus')== 1){
      let screens = localStorage.getItem('rolesceens').split(',');
      var checking = screens.includes("4");
       if(checking == true){

          }else{
              window.location.href = "/"; 
          }
      }
      else{
          window.location.href = "/"; 
      }

    $("#cross").hide();
    $("#across").hide();
  }

  showReinitiate = () =>{
    let company_id = localStorage.getItem('company_id');
    let messageParams = new FormData();
    messageParams.append( 'company_id', company_id );
    $("#phone_no").val($("#mobile_no").text());
    $("#phone_no").prop("disabled",true);
    axios({
    method: 'POST',
    url: Constant.url + "templates",
    data: messageParams,
    }).then(function (response) {
    if (response.data.status === true) {
        $('#suggested_msgs_pop').empty();
        $("#suggested_msgs_pop").append("<option id='0'>Select Message</option>");
        for(let i = 0; i < response.data.data.length; i++){
          // $("#suggested_msgs_pop ul").append("<li id='suggest_msg_"+response.data.data[i].id+"' onclick='sendMessage("+response.data.data[i].id+")'><button id='suggest_msg_"+response.data.data[i].id+"'>"+response.data.data[i].name+"</button></li>");
          $("#suggested_msgs_pop").append("<option id='suggest_msg_pop_"+response.data.data[i].id+"'>"+response.data.data[i].name+"</option>");
        }
    }
    })
    $("#reinitiate").addClass('uk-flex uk-open');
}

confirmReinitaite = () =>{
    let template_id  = $("#suggested_msgs_pop").find('option:selected').attr('id');
    template_id = template_id.split("_")[3];
    let phone_no = $("#phone_no").val();
    
    let company_id = localStorage.getItem('company_id');
    let company_msisdn = localStorage.getItem('company_msisdn');
    if(phone_no == ""){
        $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please enter number first!</p></div>');
        setTimeout(function(){UIkit.alert('#msg-dialog').close();},2000);
        return;
    }
    if(template_id == undefined) {
        $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please select message first!</p></div>');
        setTimeout(function(){UIkit.alert('#msg-dialog').close();},2000);
        return ;
    }

    let reInitiateParams = new FormData();
    reInitiateParams.append( 'business_number', company_msisdn );
    reInitiateParams.append( 'company_id', company_id );
    reInitiateParams.append( 'template_id', template_id );
    reInitiateParams.append( 'msisdn', phone_no );
    axios({
    method: 'POST',
    url: Constant.url + "quick-message",
    data : reInitiateParams
    }).then(function (result) {
    if (result.data.status === true) {
        $("#successAlertQuick").append('<div id="msg-dialog" class="uk-alert-success uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Message sent successfully.</p></div>');
        setTimeout(function(){
        UIkit.alert('#msg-dialog').close();
        $("#reinitiate").removeClass('uk-flex uk-open');
        },2000);
    } else {
        $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>An error occurred!</p></div>');
        setTimeout(function(){
        UIkit.alert('#msg-dialog').close();
        },2000);
    }
    })
}

  getAgents = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "get-agents",
      data: { company_id:localStorage.getItem('company_id') },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          agentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  handleAgent = () => {
    let result = "";
    let index = 0;
    result = this.state.agentList.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  filtercountryChange = () => {
    this.getfilterCity();
  };

  getfilterCity = () => {
    let classRef = this;
    let country = $("#filtercountry").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  getCountry = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "countries",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          country: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCountry = () => {
    let result = "";
    let index = 0;
    result = this.state.country.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  countryChange = () => {
    this.getCity();
  };

  getCity = () => {
    let classRef = this;
    let country = $("#country").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCity = () => {
    let result = "";
    let index = 0;
    result = this.state.city.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  getTags = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "tags",
      data: { company_id: localStorage.getItem('company_id') },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          tagList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  onSelectTags(selectedList, selectedItem) {
    this.setState({ tags: selectedList, old_contact_tags: selectedList });
  }
  onRemoveTags(selectedList, removedItem) {
    this.setState({ tags: selectedList, old_contact_tags: selectedList });
  }

  attachTag = () => {
    // var user_id = $("#user_id").val()

    $("#addtagss").text("Processing");
    $("#addtagss").prop("disabled", true);

    let classRef = this;
    var tags = "";
    var contact_id = $("#user_id").val();
    tags = Object.keys(classRef.state.tags)
      .map(function (k) {
        return classRef.state.tags[k].name;
      })
      .join(",");
    var data = {};

    data = {
      contact_id: contact_id,
      company_id: localStorage.getItem("company_id"),
      tag_name: tags,
    };
    axios({
      method: "POST",
      url: Constant.url + "attach-contact-tags",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlerttag").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.reload();
          $("#addtagss").text("Save");
          $("#addtagss").prop("disabled", false);
        } else {
          $("#errorAlerttag").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $(".AddTeamBtn").text("Save");
          $(".AddTeamBtn").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  addTag = () => {
    $("#AddTagsModal").addClass("uk-flex uk-open");
  };

  editNewContact = (
    id,
    first_name,
    last_name,
    phone,
    email,
    gender,
    country,
    city,
    tags,
    address
  ) => {
    $(".editOptions").hide();
    this.getCity();
    $("#EditNewContact").addClass("uk-flex uk-open");
    $("#firstNameEdit").val(first_name);
    $("#lastNameEdit").val(last_name);

    // $("#phoneEdit").val(phone);

    

    let text = phone;
    var front = text.slice(0, 2)
    let part = text.slice(2)
    let pho = '+'+front+" "+part;

    // console.log('sajjjj',pho);

    $("#emailEdit").val(email);
    $("#genderEdit").val(gender);
    $("#countryEdit").val(country);
    $("#cityEdit").val(city);
    $("#contact_id").val(id);
    $("#addressEdit").val(address);
    // tags = tags.filter(function (element) {
    //   return element.name !== "";
    // });
    this.setState({
      old_tags: tags,
      phoneintlnumberedit: pho    
    });


    // console.log('umair' , this.state.phoneintlnumberedit )
  };


  
  closeEditContactModal = () => {
    $("#EditNewContact").removeClass("uk-flex uk-open");
  };
  addNewContact = () => {
    $("#AddNewContact").addClass("uk-flex uk-open");
  };
  closeContactModal = () => {
    $("#AddNewContact").removeClass("uk-flex uk-open");
  };

  closeContactProfileModal = () => {
    $("#tags").empty();
    this.callContactApi();
    $("#ContactProfile").removeClass("uk-flex uk-open");
  };

  createContact = () => {
    // let phoneCount = $("#phone").val();

    let phoneCount = $("#phon").val();
    var phonesam = $("#phon").val();
    var phonesamp = phonesam.replace("+", " ");
    var phonesample = phonesamp.replace(/\s/g, '');



    if (phoneCount.length > 10) {
      $(".AddTeamBtn").text("Processing");
      $(".AddTeamBtn").prop("disabled", true);

      let classRef = this;
      var data = {};
      var tags = "";
      tags = Object.keys(classRef.state.tags)
        .map(function (k) {
          return classRef.state.tags[k].name;
        })
        .join(",");
      data = {
        first_name: $("#firstName").val(),
        last_name: $("#lastName").val(),
        code: $("#country_code").val(),
        // phone: $("#phone").val(),
        phone: phonesample,
        email: $("#email").val(),
        gender: $("#gender").val(),
        country: $("#country").val(),
        city: $("#city").val(),
        address : $("#addressAdd").val(),
        tags: tags,
        company_id: localStorage.getItem('company_id'),
      };
      axios({
        method: "POST",
        url: Constant.url + "add-contact",
        data: data,
      })
        .then(function (response) {
          if (response.data.code === 200) {
            $("#successAlert").append(
              '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
                response.data.message +
                "</p></div>"
            );
            window.location.href = "/audience";
          } else {
            $("#errorAlert").append(
              '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
                response.data.data +
                "</p></div>"
            );
            $(".AddTeamBtn").text("Create");
            $(".AddTeamBtn").prop("disabled", false);
            setTimeout(function () {
              UIkit.alert("#msg-dialog").close();
            }, 1000);
            return;
          }
        })
        .then(function (response) {});
    } else {
      $("#errorAlert").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Phone no must be 10 digits.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  editContact = () => {
    $("#editContact").text("Processing");
    $("#editContact").prop("disabled", true);

    let classRef = this;
    var data = {};
    var id = $("#contact_id").val();
    var tags = "";
    tags = Object.keys(classRef.state.tags)
      .map(function (k) {
        return classRef.state.tags[k].name;
      }).join(",");

      var phonesam = $("#phonedit").val();
        var phonesamp = phonesam.replace("+", " ");
        var phonesample = phonesamp.replace(/\s/g, '');



    data = {
      full_name: $("#firstNameEdit").val(),
      last_name: $("#lastNameEdit").val(),
      code: $("#country_codeEdit").val(),
      // phone: $("#phoneEdit").val(),
      phone: phonesample,
      email: $("#emailEdit").val(),
      gender: $("#genderEdit").val(),
      country: $("#countryEdit").val(),
      city: $("#cityEdit").val(),
      address: $("#addressEdit").val(),
      tags: tags,
      company_id: localStorage.getItem('company_id'),
    };
    axios({
      method: "POST",
      url: Constant.url + "edit-contact/" + id,
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlertEdit").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.href = "/audience";
        } else {
          $("#errorAlertEdit").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#editContact").text("Edit");
          $("#editContact").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  searchnum = () => {
    this.hideAllFilter();
    let classRef = this;
    var name = $("#filterName").val();
    var email = $("#filterEmail").val();
    var date = $("#filterDate").val();
    var city = $("#filterCity").val();
    var country = $("#filterCountry").val();
    var region = $("#filterRegion").val();
    var gender = $("#filterGender").val();
    var username = $("#filterUsername").val();
    var searchword = $("#ssearchword").val();
    classRef.setState({
      ContactList: {
        isLoaded: false,
      },
    });

    if (searchword !== "") {
      $("#cross").show();
    }

    this.setState({ advanceautosearch: searchword });

    axios({
      method: "POST",
      url: Constant.url + "contacts",
      data: {
        company_id: localStorage.getItem('company_id'),
        search: searchword,
        name: name,
        email: email,
        date: date,
        city: city,
        country: country,
        region: region,
        gender: gender,
        username: username,
      },
    }).then(function (result) {
      // console.log("checkinh");

      if (result.data.status === true) {
        classRef.setState({
          ContactList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountContact: result.data.rowscount,
        });
      } else {
        classRef.setState({
          ContactList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountContact: result.data.rowscount,
        });
      }
    });
  };

  openFilter = (seletorID) => {
    this.hideAllFilter();
    $("#" + seletorID).show();
    $("#" + seletorID).addClass("uk-dropdown uk-open");
  };

  hideFilter = (seletorID) => {
    $("#" + seletorID).removeClass("uk-dropdown uk-open");
    $("#" + seletorID).hide();
  };

  hideAllFilter = () => {
    $("#nameDrop").hide();
    $("#emailDrop").hide();
    $("#dateDrop").hide();
    $("#cityDrop").hide();
    $("#countryDrop").hide();
    $("#regionDrop").hide();
    $("#genderDrop").hide();
    $("#usernameDrop").hide();
    $("#nameDrop").removeClass("uk-dropdown uk-open");
    $("#emailDrop").removeClass("uk-dropdown uk-open");
    $("#dateDrop").removeClass("uk-dropdown uk-open");
    $("#cityDrop").removeClass("uk-dropdown uk-open");
    $("#countryDrop").removeClass("uk-dropdown uk-open");
    $("#regionDrop").removeClass("uk-dropdown uk-open");
    $("#genderDrop").removeClass("uk-dropdown uk-open");
    $("#usernameDrop").removeClass("uk-dropdown uk-open");
  };

  createSegment = () => {
    $("#savesegment").text("Processing...");
    const filterName = [];
    const filterCondition = [];
    const filterValue = [];

    var filterNameInput = document.getElementsByName("filter_name[]");
    for (var i = 0; i < filterNameInput.length; i++) {
      filterName.push(filterNameInput[i].value);
    }
    var filterConditionInput = document.getElementsByName("filter_condition[]");
    for (var i = 0; i < filterConditionInput.length; i++) {
      filterCondition.push(filterConditionInput[i].value);
    }
    var filterValueInput = document.getElementsByName("filter_value[]");
    for (var i = 0; i < filterValueInput.length; i++) {
      filterValue.push(filterValueInput[i].value);
    }

    // console.log(filterName, "filterName");
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "add-segments",
      data: {
        company_id: localStorage.getItem("company_id"),
        name: $("#segmentname").val(),
        description: $("#description").val(),
        filter_name: filterName,
        filter_condition: filterCondition,
        filter_value: filterValue,
        status: 1,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        $("#savesegment").text("Save");
        window.location.reload();
      } else {
        $("#savesegment").text("Save");
      }
    });
  };

  editUserSegment = (id, name, description) => {
    $("#editsegmentname").val(name);
    $("#editdescription").val(description);
    $("#EditUserSegment").addClass("uk-flex uk-open");
  };

  closeEditUserSegment = () => {
    $("#EditUserSegment").removeClass("uk-flex uk-open");
  };

  callContactApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "contacts",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          ContactList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountContact: result.data.rowscount,
        });
      } else {
        classRef.setState({
          ContactList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountContact: result.data.rowscount,
        });
      }
    });
  };

  ContactSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#ContactSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "contacts",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
        dateto: $("#to_date").val(),
        datefrom: $("#from_date").val(),
        country: $("#filtercountry").val(),
        city: $("#filtercity").val(),
        gender: $("#filtergender").val(),
        agent: $("#filteragents").val(),
        searchword : $("#ssearchword").val(),
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          ContactList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountContact: result.data.rowscount,
        });
      } else {
        classRef.setState({
          ContactList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountContact: result.data.rowscount,
        });
      }
    });
  };

  handleContactClick = (data) => {
    let currentPage = data.selected + 1;
    this.ContactSorting(currentPage);
  };

  handleSegmentsClick = (data) => {
    let currentPage = data.selected + 1;
    this.SegmentsSorting(currentPage);
  };

  handleContactTable = () => {
    let result = "";
    let index = 0;
    let tagCount = "";
    let tags = "";
    result = this.state.ContactList.list.map(
      (response, index) => (
        (tagCount = response.tags.split(",")),
        (tagCount = tagCount.length - 2),
        (tags =
          response.tags.split(",")[0] + "," + response.tags.split(",")[1]),
        (
          <tr id={"contact_" + response.id}>
            <td>
              <label>
                <input
                  className="uk-checkbox autoSelect"
                  name="multi_check[]"
                  value={response.id}
                  type="checkbox"
                />
              </label>
            </td>
            <td>
              <div className="avatarIcon">
                <img src={response.avatar} alt="Image" />
              </div>
            </td>
            <td>
              <button
                className="dataTable"
                onClick={(e) =>
                  this.addContactProfile(
                    response.contact_name,
                    response.phone,
                    response.gender,
                    response.tags,
                    response.subscribed,
                    response.contact_time,
                    response.id,
                    response.avatar
                  )
                }
              >
                <span>{response.contact_name}</span>
              </button>
            </td>
            <td>{response.phone}</td>
            <td>{response.gender}</td>
            <td>
              {response.tags == "" ? (
                "None"
              ) : (
                <div>
                  {tags}
                  <button
                    className="dataTable"
                    onClick={(e) =>
                      this.addContactProfile(
                        response.contact_name,
                        response.phone,
                        response.gender,
                        response.tags,
                        response.subscribed,
                        response.contact_time,
                        response.id,
                        response.avatar,
                        response.tags_2
                      )
                    }
                  >
                    {tagCount > 0 ? "+" + tagCount + " others" : ""}
                  </button>
                </div>
              )}{" "}
            </td>
            <td>{response.assigned}</td>
            <td>{response.subscribed}</td>
            <td>{response.contact_time}</td>
            <td><a className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) =>
                          this.editNewContact(
                            response.id,
                            response.first_name,
                            response.last_name,
                            response.phone,
                            response.email,
                            response.gender,
                            response.country,
                            response.city,
                            response.tags_2,
                            response.address
                          )
                        }><Edit /></a></td>
            <td><a className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.deleteContact(response.id)}><TrashCan /></a></td>

          </tr>
        )
      )
    );
    return result;
  };

  callSegmentApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SegmentList: {
            isLoaded: true,
            msg: "Data Found",
            list: result.data.data,
          },
          rowsCountSegments: result.data.rowscount,
        });
      } else {
        classRef.setState({
          SegmentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountSegments: result.data.rowscount,
        });
      }
    });
  };

  SegmentsSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#SegmentsSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SegmentList: {
            isLoaded: true,
            msg: "Data Found",
            list: result.data.data,
          },
          rowsCountSegments: result.data.rowscount,
        });
      } else {
        classRef.setState({
          SegmentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountSegments: result.data.rowscount,
        });
      }
    });
  };

  handleSegmentTable = () => {
    let result = "";
    let index = 0;
    result = this.state.SegmentList.list.map((response, index) => (
      <tr id={"segment_" + response.id}>
        <td>
          <label>
            <input
              className="uk-checkbox autoSelects"
              name="multi_check[]"
              value={response.id}
              type="checkbox"
            />
          </label>
        </td>
        <td>
          <span>{response.name}</span>
        </td>
        <td>
          <p>{response.descriptio ? response.name : "--"}</p>
        </td>
        <td>{response.counter}</td>
        <td>{response.created_at}</td>
        <td><button className="iconcircleBtn success" uk-tooltip="title: Download Data" onClick={(e) => this.download(response.id)}> <Download /></button></td>
        <td><a className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.editUserSegment( response.id, response.name, response.description )}><Edit /></a></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addconsent(response.id)}> <TrashCan /></button></td>
      </tr>
    ));
    return result;
  };

  addSaveUserSegment = () => {
    $("#SaveUserSegment").addClass("uk-flex uk-open");
  };

  closeSaveUserSegment = () => {
    $("#SaveUserSegment").removeClass("uk-flex uk-open");
  };

  addNewSegment = () => {
    $("#NewSegment").addClass("uk-flex uk-open");
  };

  closeNewSegment = () => {
    $("#NewSegment").removeClass("uk-flex uk-open");
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  closeAutoSearchModal = () => {
    $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
  };

  addAutoSearchModal = () => {
    $("#SearchAutoTeamModal").addClass("uk-flex uk-open");
  };

  closeAutoSearchContactModal = () => {
    $("#SearchAutoContactTeamModal").removeClass("uk-flex uk-open");
  };

  addAutoSearchContactModal = () => {
    $("#SearchAutoContactTeamModal").addClass("uk-flex uk-open");
  };

  addAdditionalSegment = () => {
    var fieldHTML =
      '<div class="uk-width-1-1"><p>Condition</p></div><div class="uk-width-auto"><select name="filter_name[]" class="uk-select" id="filter_name[]"><option value="tag">Tag</option><option value = "number">Number</option></select></div><div class="uk-width-auto"><select name="filter_condition[]" class="uk-select" id="filter_condition[]"><option>is not</option><option>is</option></select></div><div class="uk-width-auto"><div class="uk-form-controls"><input class="uk-input" id="filter_value[]" name="filter_value[]" type="text" placeholder="user_registered" /></div></div><div class="uk-width-auto"><button class="deleteBtn"><svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true"><path d="M12 12H14V24H12zM18 12H20V24H18z"></path><path d="M4 6V8H6V28a2 2 0 002 2H24a2 2 0 002-2V8h2V6zM8 28V8H24V28zM12 2H20V4H12z"></path></svg></button></div>';
    $("#main_segment").append(fieldHTML);
  };

  deleteSegment = (id) => {
    var id = $("#addconsent").val();
    $("#segment_" + id).hide();
    let classRef = this;
    var segment_ids = [id];
    var company_id = localStorage.getItem("company_id");

    axios({
      method: "POST",
      url: Constant.url + "delete-segment",
      data: { company_id: company_id, segment_ids: segment_ids },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#deletesuccessAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );
        $("#AddResolved").removeClass("uk-flex uk-open");
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 1000);
      } else {
        $("#deleteerrorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 1000);
      }
    });
  };

  download = (id) => {
    let classRef = this;
    var segment_ids = id;
    var company_id = localStorage.getItem("company_id");
    window.open(
      Constant.url +
        "download-segment?company_id=" +
        company_id +
        "&segment_id=" +
        segment_ids,
      "_blank"
    );
  };

  downloadContacts = () => {
    let classRef = this;
    var company_id = localStorage.getItem("company_id");
    window.open(
      Constant.url + "download-contacts?company_id=" + company_id,
      "_blank"
    );
  };

  downloadSegments = () => {
    let classRef = this;
    var company_id = localStorage.getItem("company_id");
    window.open(
      Constant.url + "download-segments?company_id=" + company_id,
      "_blank"
    );
  };

  editSegment = (id) => {};

  addconsent = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  deleteMultiSegment = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#segment_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "delete-segments",
      data: {
        company_id: localStorage.getItem('company_id'),
        segment_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        //  window.location.reload();
      }
    });
  };

  selectSegmentCheckbox = () => {
    if (!this.state.checkAlls) {
      this.setState({ checkAlls: true }, function () {
        $("#Segmentselect_all").prop("checked", true);
        $(".autoSelects").prop("checked", true);
      });
    } else {
      this.setState({ checkAlls: false }, function () {
        $("#Segmentselect_all").prop("checked", false);
        $(".autoSelects").prop("checked", false);
      });
    }
  };

  deleteMultiContact = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#contact_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "delete-profile",
      data: {
        company_id: localStorage.getItem('company_id'),
        contact_id: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        //  window.location.reload();
      }
    });
  };

  showSegmentPopup = () => {
    // console.log("clicked");
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        ids[i] = $(this).val();
      }
    });
    ids = ids.filter(function (element) {
      return element !== "on";
    });
    if (ids.length > 0) {
      $("#select_dropdown").hide();
      $("#AddSegmentPopup").addClass("uk-flex uk-open");
    } else {
      $("#errorAlertmain").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please select contact first.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  addSegmentContact = () => {
    $("#Addsegments").text("Processing");
    $("#Addsegments").prop("disabled", true);
    if ($("#segment_title").val() != "") {
      var ids = [];
      $("input[type=checkbox]").each(function (i) {
        if ($(this).is(":checked")) {
          ids[i] = $(this).val();
        }
      });
      ids = ids.filter(function (element) {
        return element !== "on";
      });
      let classRef = this;
      let company_id = localStorage.getItem("company_id");
      let segment_name = $("#segment_title").val();
      axios({
        method: "POST",
        url: Constant.url + "create-segment-by-select-contacts",
        data: {
          company_id: company_id,
          contact_id: ids,
          segment_name: segment_name,
        },
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successAlertSegment").append(
            '<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          $("#Addsegments").text("Save");
          $("#Addsegments").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog1").close();
            $("#AddSegmentPopup").removeClass("uk-flex uk-open");
          }, 1000);
        }
      });
    } else {
      $("#errorAlertSegment").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please enter segment title.</p></div>'
      );
      $("#Addsegments").text("Save");
      $("#Addsegments").prop("disabled", false);
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  selectCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    // console.log(searchword);
    this.setState({ advancesearch: searchword });

    if (searchword !== "") {
      $("#across").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: { company_id: localStorage.getItem('company_id'), search: searchword },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SegmentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          SegmentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callSegmentApi();
    $("#across").hide();
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  advanceSearch = () => {
    let classRef = this;
    var data = {};
    var tags = "";
    tags = Object.keys(classRef.state.tags)
      .map(function (k) {
        return classRef.state.tags[k].name;
      })
      .join(",");
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      country: $("#filtercountry").val(),
      city: $("#filtercity").val(),
      gender: $("#filtergender").val(),
      agent: $("#filteragents").val(),
      tags: tags,
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.country) {
      var key = key + " Country: " + $("#filtercountry :selected").text();
    }
    if (data.city) {
      var key = key + " City: " + $("#filtercity :selected").text();
    }
    if (data.gender) {
      var key = key + " Gender: " + $("#filtergender :selected").text();
    }
    if (data.agent) {
      var key = key + " Agents: " + $("#filteragents :selected").text();
    }
    if (data.tags) {
      var key = key + " Tags: " + tags;
    }

    if (key !== "") {
      $("#cross").show();
    }

    this.setState({ advanceautosearch: key });

    axios({
      method: "POST",
      url: Constant.url + "contacts",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          ContactList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountContact: result.data.rowscount,
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          ContactList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountContact: result.data.rowscount,
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  AutoadvanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_dates").val(),
      datefrom: $("#from_dates").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_dates").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_dates").val();
    }

    if (key !== "") {
      $("#across").show();
    }

    classRef.setState({ advancesearch: key });

    axios({
      method: "POST",
      url: Constant.url + "segments",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          SegmentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          SegmentList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  advAtuoSearchClear = () => {
    let classRef = this;
    classRef.setState({ advanceautosearch: "" });
    classRef.callContactApi();
    $("#cross").hide();
  };

  StartChat = (id) => {
    localStorage.setItem("stats_conversation_id", id);

    window.location.href = "/chat";
  };

  clearFilters = () => {
    $("#from_dates").val("");
    $("#to_dates").val("");
  };

  clearcontactFilters = () => {
    $("#from_date").val("");
    $("#to_date").val("");
    $("#filtercountry").val("");
    $("#filtercity").val("");
    $("#filtergender").val("");
    $("#filteragents").val("");
    $("#saj").val("");
  };

  handleInternationalnumber = (data) =>{

    let classRef = this;        
    var searchword = $("#phon").val();
    this.setState({ phoneintlnumber: searchword});
  }

  handleInternationalnumberedit = (data) =>{

    let classRef = this;        
    var searchword = $("#phonedit").val();
    this.setState({ phoneintlnumberedit: searchword});
  }

  render() {
    return (
      <Content>
        <div
          className="AutomationContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          {/* Automation Tab Start */}
          <div className="TabsHeadBox">
            <ul className="tabsBtns desktop" uk-switcher="connect: .multi_div">
              <li id="tagtab">
                <a href="#">Contacts</a>
              </li>
              <li id="tagtemp">
                <a href="#">Segments</a>
              </li>
            </ul>
            <div class="mobTabsBtn">
              <p class="AnimFade uk-scrollspy-inview uk-animation-fade">
                Contacts
              </p>
              <ul className="tabsBtns" uk-switcher="connect: .multi_div">
                <li id="tagtab">
                  <a href="#">Contacts</a>
                </li>
                <li id="tagtemp">
                  <a href="#">Segments</a>
                </li>
              </ul>
            </div>

            <ul className="uk-switcher multi_div" id="tabsBtns">
              <li id="tagtablistbutton">
                <div className="searchnFlowBtn mobile audiencesearchnFlowBtn">
                  <form className="uk-search" action="javascript:void(0)">
                    <input
                      className="uk-search-input"
                      onChange={this.searchnum}
                      value={this.state.advanceautosearch}
                      type="search"
                      name="ssearchword"
                      id="ssearchword"
                      placeholder="Search through contacts name"
                    />
                  </form>
                  <div class="Filtermain audienceContactFiltermain">
                    <div className="uk-width-auto">
                      <button
                        id="cross"
                        style={{ right: "30px" }}
                        onClick={this.advAtuoSearchClear}
                      >
                        <Close />
                      </button>

                      <button
                        className="popadvanceFilter"
                        onClick={this.addSearchModal}
                      >
                        <img src="/images/newfilter.svg" uk-svg />
                        {/* <Search onClick = {this.addSearchModal}/> */}
                      </button>
                    </div>
                  </div>

                  <button
                    class="newFlowBtn uk-float-right"
                    onClick={this.addNewContact}
                  >
                    <Add /> <span>Add New Contact</span>
                  </button>

                  <button
                    class="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.downloadContacts}
                  >
                    <Add /> <span>Export Contact</span>
                  </button>
                  <button 
                    class="newFlowBtn uk-float-right uk-margin-right"  
                    onClick={this.AddImportcontactModal}
                  > <DocumentImport /> 
                    <span>Import Contact</span>
                  </button>
                </div>
              </li>

              <li id="tagtemplistbutton">
                <div className="searchnFlowBtn mobile">
                  <form className="uk-search" action="javascript:void(0)">
                    <div className="uk-width-auto">
                      <input
                        className="uk-search-input"
                        type="text"
                        onChange={this.searchrecord}
                        name="searchword"
                        id="searchword"
                        value={this.state.advancesearch}
                        placeholder="Search Segments through name"
                      />
                    </div>
                    <div class="Filtermain audienceSegFiltermain">
                      <div className="uk-width-auto">
                        <button
                          id="across"
                          style={{ right: "30px" }}
                          onClick={this.advSearchClear}
                        >
                          <Close />
                        </button>
                        <button
                          className="popadvanceFilter"
                          onClick={this.addAutoSearchModal}
                        >
                          <img src="/images/newfilter.svg" uk-svg />
                          {/* <Search onClick = {this.addSearchModal}/> */}
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* <button className="newFlowBtn" onClick={this.addNewTemplate}><Add /> <span>New Chat Template</span></button> */}
                  <button
                    className="newFlowBtn uk-float-right"
                    onClick={this.addNewSegment}
                  >
                    <Add /> <span>New Segment</span>
                  </button>
                  <a
                    href="#"
                    class="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.downloadSegments}
                  >
                    <Add /> <span>Export Segment</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div id="errorAlertmain"></div>
          <ul className="uk-switcher TabSec multi_div">
            <li id="tagtablist">
              <div className="numberSorting uk-float-right">
                <div class="uk-inline">
                  <label>
                    Show
                    <select
                      onChange={(e) => this.ContactSorting()}
                      id="ContactSortingBtn"
                    >
                      {/* <option disabled selected>Sorting</option> */}
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="tableSec">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-divider CustomTableWidth">
                    <thead>
                      <tr>
                        <th>
                          <label>
                            <input
                              className="uk-checkbox "
                              type="checkbox"
                              id="select_all"
                              onClick={this.selectCheckbox}
                            />
                          </label>
                          <div className="uk-inline drpIconBox">
                            <button className="iconTble" type="button">
                              <CaretDown />
                            </button>
                            <div uk-dropdown="mode: click" id="select_dropdown">
                              <ul className="uk-nav uk-dropdown-nav">
                                <li>
                                  <a onClick={this.showSegmentPopup}>
                                    <Add />
                                    Add Segments
                                  </a>
                                </li>
                                <li className="hintAlert">
                                  <a onClick={this.deleteMultiContact}>
                                    <TrashCan />
                                    Delete Selected Rows
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </th>
                        <th>AVATAR</th>
                        <th>CONTACT NAME</th>
                        <th>PHONE</th>
                        <th>GENDER</th>
                        <th>TAGS</th>
                        <th>ASSIGNED AGENT</th>
                        <th>SUBSCRIBED</th>
                        <th>CONTACT TIME</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {(this.state.ContactList.isLoaded) ? this.handleContactTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>} */}

                      {this.state.ContactList.isLoaded ? (
                        this.handleContactTable()
                      ) : this.state.ContactList.msg == "not" ? (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              Data not Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              <div className="mdl">
                                <div className="mdl_inner">
                                  <div uk-spinner=""></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {this.state.rowsCountContact > 1 ? (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"...."}
                      pageCount={this.state.rowsCountContact}
                      onPageChange={this.handleContactClick}
                      containerClassName={"uk-pagination uk-float-right"}
                      activeClassName={"uk-active"}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </li>

            <li id="tagtemplist">
              <div className="numberSorting uk-float-right">
                <div class="uk-inline">
                  <label>
                    Show
                    <select
                      onChange={(e) => this.SegmentsSorting()}
                      id="SegmentsSortingBtn"
                    >
                      {/* <option disabled selected>Sorting</option> */}
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="tableSec">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-divider">
                    <thead>
                      <tr>
                        <th>
                          <label>
                            <input
                              className="uk-checkbox"
                              type="checkbox"
                              id="Segmentselect_all"
                              onClick={this.selectSegmentCheckbox}
                            />
                          </label>
                          <div className="uk-inline drpIconBox">
                            <button className="iconTble" type="button">
                              <CaretDown />
                            </button>
                            <div uk-dropdown="mode: click">
                              <ul className="uk-nav uk-dropdown-nav">
                                <li className="hintAlert">
                                  <a onClick={this.deleteMultiSegment}>
                                    <TrashCan />
                                    Delete Selected Rows
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </th>
                        <th>NAME</th>
                        <th>DESCRIPTION</th>
                        <th>CONTACTS</th>
                        <th>CREATED TIME</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.SegmentList.isLoaded ? (
                        this.handleSegmentTable()
                      ) : this.state.SegmentList.msg == "not" ? (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              Data not Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              <div className="mdl">
                                <div className="mdl_inner">
                                  <div uk-spinner=""></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {this.state.rowsCountSegments > 1 ? (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"...."}
                        pageCount={this.state.rowsCountSegments}
                        onPageChange={this.handleSegmentsClick}
                        containerClassName={"uk-pagination uk-float-right"}
                        activeClassName={"uk-active"}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={6}
                      />
                    ) : (
                      ""
                    )}
                  </table>
                </div>
              </div>
            </li>
          </ul>

          {/*---DELETE-MODAL---*/}
          <div
            id="AddResolved"
            className="AlertPOPUP ModalStyle uk-flex-top"
            uk-modal=""
          >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <button className="uk-modal-close-default" type="button">
                {" "}
                <Close />
              </button>
              <h3>
                <span className="uk-inline"> Warning</span>
                <span className="uk-inline uk-float-right">
                  <img src="/images/done_all_black_24dp.svg" />{" "}
                </span>
              </h3>
              <div className="resolved_content">
                <p>
                  <b>Are you sure you want to delete Segment?</b>
                </p>
                <p className="small">You won't be able to revert this!</p>

                <form
                  className="uk-form-stacked uk-margin-medium-top"
                  action="javascript:void(0);"
                >
                  <div className="ModalStyleBtns uk-float-right">
                    <button className="CnclBtn" onClick={this.closeConsent}>
                      Cancel
                    </button>
                    <button
                      className="AddTeamBtn"
                      id="addconsent"
                      onClick={this.deleteSegment}
                    >
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* popup one start */}
          <div
            id="NewSegment"
            className="AddTeamModal ModalStyle uk-flex-top"
            uk-modal=""
          >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <button className="uk-modal-close-default" type="button">
                {" "}
                <Close />
              </button>
              <h3>Create New Segment</h3>

              <h5>Contacts match any of the following conditions:</h5>
              <form
                className="uk-form-stacked uk-grid-small"
                action="javascript:void(0);"
              >
                <div className="uk-grid-small" id="main_segment" uk-grid="">
                  <div className="uk-width-1-1">
                    <p>Condition</p>
                  </div>

                  <div className="uk-width-auto">
                    <select
                      name="filter_name[]"
                      className="uk-select"
                      id="filter_name[]"
                    >
                      <option value="tag">Tag</option>
                      <option value="number">Number</option>
                    </select>
                  </div>

                  <div className="uk-width-auto">
                    <select
                      name="filter_condition[]"
                      className="uk-select"
                      id="filter_condition[]"
                    >
                      <option>is not</option>
                      <option>is</option>
                    </select>
                  </div>

                  <div className="uk-width-auto">
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="filter_value[]"
                        name="filter_value[]"
                        type="text"
                        placeholder="user_registered"
                      />
                    </div>
                  </div>

                  {/* <div className="uk-width-auto">
                                <button className="deleteBtn"><TrashCan /></button>
                            </div> */}
                </div>
                <div className="uk-margin uk-width-1-1">
                  <button
                    className="simpleAddBtn"
                    onClick={this.addAdditionalSegment}
                  >
                    <AddAlt /> Add Button
                  </button>
                </div>

                <div className="uk-margin uk-width-1-1">
                  <div className="ModalStyleBtns ">
                    <button className="CnclBtn" onClick={this.closeNewSegment}>
                      Cancel
                    </button>
                    <button
                      className="AddTeamBtn"
                      onClick={this.addSaveUserSegment}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* popup user field start */}

          <div
            id="SaveUserSegment"
            className="AddTeamModal ModalStyle uk-flex-top"
            uk-modal=""
          >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <button className="uk-modal-close-default" type="button">
                {" "}
                <Close />
              </button>
              {/* <h4><b>Save New Segment - 1,243,453 contacts match your condition</b></h4> */}
              <h4>
                <b>Save New Segment </b>
              </h4>
              <h5>
                <b>What would you like to name your segment?</b>
              </h5>
              <form className="uk-form-stacked" action="javascript:void(0);">
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="SegmentName">
                    Segment Name
                    <ContextualHelp variant="info">
                      <Content>
                        <h5>
                          <b>Segment Name</b>
                        </h5>
                        <p>How this Segment appears in Telenor WABA</p>
                      </Content>
                    </ContextualHelp>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="segmentname"
                      name="segmentname"
                      type="text"
                      placeholder="Enter Segment Name"
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="teamName">
                    Description
                    <ContextualHelp variant="info">
                      <Content>
                        <h5>
                          <b>Description</b>
                        </h5>
                        <p>
                          A brief note about this Segment for you and other
                          admins.
                        </p>
                      </Content>
                    </ContextualHelp>
                  </label>
                  <div className="uk-form-controls">
                    <textarea
                      className="uk-input"
                      id="description"
                      name="description"
                      type="text"
                      placeholder="A note about this Tag for you and other admins."
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <h5>
                    <b>What would you like to name your segment?</b>
                  </h5>
                  <p>Contacts that match any of the following conditions:</p>
                  <ol>
                    <li>
                      Tags contact is tagged <b>user_registered</b>
                    </li>
                    <li>
                      Tags contact is not tagged <b>preferred_phone</b>
                    </li>
                  </ol>
                </div>

                <div className="uk-margin">
                  <div className="ModalStyleBtns ">
                    <button
                      className="CnclBtn"
                      onClick={this.closeSaveUserSegment}
                    >
                      Close
                    </button>
                    <button
                      className="AddTeamBtn"
                      id="savesegment"
                      onClick={this.createSegment}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* AddSegmentPopup popup */}
          <div
            id="AddSegmentPopup"
            className="AlertPOPUP ModalStyle uk-flex-top"
            uk-modal=""
          >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <button className="uk-modal-close-default" type="button">
                {" "}
                <Close />
              </button>
              <h3>
                <span className="uk-inline"> Add Segments</span>
                <span className="uk-inline uk-float-right">
                  <img src="/images/done_all_black_24dp.svg" />{" "}
                </span>
              </h3>
              <div id="errorAlertSegment"></div>
              <div id="successAlertSegment"></div>
              <div className="resolved_content">
                <label for="segmenttitle"></label>
                <input
                  type="text"
                  name="segment_title"
                  id="segment_title"
                  className="uk-input"
                  placeholder="Segment Title"
                />
                <form
                  className="uk-form-stacked uk-margin-medium-top"
                  action="javascript:void(0);"
                >
                  <div className="ModalStyleBtns uk-float-right">
                    {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                    <button
                      className="AddTeamBtn"
                      id="Addsegments"
                      onClick={this.addSegmentContact}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Edit */}
          <div
            id="EditUserSegment"
            className="AddTeamModal ModalStyle uk-flex-top"
            uk-modal=""
          >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <button className="uk-modal-close-default" type="button">
                {" "}
                <Close />
              </button>
              {/* <h4><b>Save New Segment - 1,243,453 contacts match your condition</b></h4> */}
              <h4>
                <b>Edit Segment </b>
              </h4>
              <h5>
                <b>What would you like to name your segment?</b>
              </h5>
              <form className="uk-form-stacked" action="javascript:void(0);">
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="SegmentName">
                    Segment Name
                    <ContextualHelp variant="info">
                      <Content>
                        <h5>
                          <b>Segment Name</b>
                        </h5>
                        <p>How this Segment appears in Telenor WABA</p>
                      </Content>
                    </ContextualHelp>
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="editsegmentname"
                      name="segmentname"
                      type="text"
                      placeholder="Enter Segment Name"
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="teamName">
                    Description
                    <ContextualHelp variant="info">
                      <Content>
                        <h5>
                          <b>Description</b>
                        </h5>
                        <p>
                          A brief note about this Segment for you and other
                          admins.
                        </p>
                      </Content>
                    </ContextualHelp>
                  </label>
                  <div className="uk-form-controls">
                    <textarea
                      className="uk-input"
                      id="editdescription"
                      name="description"
                      type="text"
                      placeholder="A note about this Tag for you and other admins."
                    />
                  </div>
                </div>

                <div className="uk-margin">
                  <h5>
                    <b>What would you like to name your segment?</b>
                  </h5>
                  <p>Contacts that match any of the following conditions:</p>
                  <ol>
                    <li>
                      Tags contact is tagged <b>user_registered</b>
                    </li>
                    <li>
                      Tags contact is not tagged <b>preferred_phone</b>
                    </li>
                  </ol>
                </div>

                <div className="uk-margin">
                  <div className="ModalStyleBtns ">
                    <button
                      className="CnclBtn"
                      onClick={this.closeSaveUserSegment}
                    >
                      Close
                    </button>
                    <button
                      className="AddTeamBtn"
                      id="editsegment"
                      onClick={this.editSegment}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div
            id="AddNewContact"
            className="AddNewContact  ModalStyle uk-flex-top"
            uk-modal=""
          >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <button className="uk-modal-close-default" type="button">
                {" "}
                <Close />
              </button>
              <h3>Create New Contact</h3>

              <div id="errorAlert"></div>
              <div id="successAlert"></div>

              <p>It is mandatory to fill the Phone number or Email</p>
              <form className="uk-form-stacked" action="javascript:void(0);">
                <div className="uk-grid uk-child-width-1-2 uk-grid-small">
                  <div className="">
                    <label className="uk-form-label" htmlFor="">
                      First Name
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="firstName"
                        name="firstName"
                        placeholder="Enter first name"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label className="uk-form-label" htmlFor="">
                      Last Name
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="lastName"
                        name="lastName"
                        placeholder="Enter last name"
                      />
                    </div>
                  </div>
                </div>


                <div className="uk-width-expand">

                <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">Phone Number</label>
                <div className="uk-form-controls">

                <PhoneInput
                international
                defaultCountry="PK"
                id="phon"
                className="uk-input"
                placeholder="Enter phone number"
                value={this.state.phoneintlnumber}
                onChange={this.handleInternationalnumber}/>

                </div>
            </div>


                </div>




                {/* <div className="uk-grid uk-grid-small">

                  <div className="uk-width-1-4">
                    <label className="uk-form-label " htmlFor="">
                      Phone Number
                    </label>
                    <div className="uk-form-controls">
                      <select
                        className="uk-select"
                        id="country_code"
                        name="country_code"
                      >
                        <option>+92</option>
                      </select>
                    </div>
                  </div>


                  



                  <div className="uk-width-expand">
                    <label
                      className="uk-form-label uk-margin-top"
                      htmlFor=""
                    ></label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="phone"
                        name="phone"
                        type="number"
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                </div> */}



                <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="">Addess</label>
                    <div className="uk-form-controls">
                    <input className="uk-input" id="addressAdd" name="addressAdd" type="text" placeholder="Address" />
                    </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    Email
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    Gender
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select" id="gender" name="gender">
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    Country
                  </label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      id="country"
                      name="country"
                      onChange={this.countryChange}
                    >
                      <option value="">Select Country</option>
                      {this.state.country.isLoaded ? this.handleCountry() : ""}
                      {/* <option value="Pakistan">Pakistan</option> */}
                    </select>
                    {/* <input className="uk-input" id="country" name="country" type="country" placeholder="Country" /> */}
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    City
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select" id="city" name="city">
                      <option value="">Select City</option>
                      {this.state.city.isLoaded ? this.handleCity() : ""}
                      {/* <option value="Pakistan">Pakistan</option> */}
                    </select>
                    {/* <input className="uk-input" id="city" name="city" type="city" placeholder="City" /> */}
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    Tags
                  </label>
                  <div className="uk-form-controls">
                    <div className="chipBox">
                      <div className="chipBox CustomChipBox">
                        <Multiselect
                          id="saj"
                          options={this.state.tagList.list}
                          selectedValues={this.state.tags}
                          onSelect={this.onSelectTags}
                          onRemove={this.onRemoveTags}
                          displayValue="name"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="uk-margin">
                                <label>
                                <input className="uk-checkbox" id="invitation" name="invitation" type="checkbox" checked /> I confirm that we have obtained consent to send message, email from contact being created with regulations and Telenor <Link to="javascript"> Terms of Service</Link>
                                </label>
                                
                            </div> */}
                <div className="uk-margin">
                  <div className="ModalStyleBtns">
                    <button
                      className="CnclBtn"
                      onClick={this.closeContactModal}
                    >
                      Cancel
                    </button>
                    <button className="AddTeamBtn" onClick={this.createContact}>
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            id="EditNewContact"
            className="EditNewContact  ModalStyle uk-flex-top"
            uk-modal=""
          >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <button className="uk-modal-close-default" type="button">
                {" "}
                <Close />
              </button>
              <h3>Edit Contact</h3>

              <div id="errorAlertEdit"></div>
              <div id="successAlertEdit"></div>
              <form className="uk-form-stacked" action="javascript:void(0);">
                <div className="uk-grid uk-child-width-1-2 uk-grid-small">
                  <div className="">
                    <label className="uk-form-label" htmlFor="">
                      First Name
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="firstNameEdit"
                        name="firstName"
                        placeholder="Enter first name"
                      />
                      <input
                        type="hidden"
                        className="uk-input"
                        id="contact_id"
                        name="contact_id"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label className="uk-form-label" htmlFor="">
                      Last Name
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="lastNameEdit"
                        name="lastName"
                        placeholder="Enter last name"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="uk-grid uk-grid-small">
                  <div className="uk-width-1-4" style={{ display: "none" }}>
                    <label className="uk-form-label " htmlFor="">
                      Phone Number
                    </label>
                    <div className="uk-form-controls">
                      <select
                        className="uk-select"
                        id="country_codeEdit"
                        name="country_code"
                      >
                        <option>+92</option>
                      </select>
                    </div>
                  </div>
                  <div className="uk-width-expand">
                    <label
                      className="uk-form-label uk-margin-top"
                      htmlFor=""
                    ></label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="phoneEdit"
                        name="phone"
                        type="number"
                        placeholder="Enter phone number"
                        readOnly
                      />
                    </div>
                  </div>
                </div> */}


            
              <div className="uk-width-expand">

              <div className="uk-margin">
              <label className="uk-form-label" htmlFor="">Phone Number</label>
              <div className="uk-form-controls">

              <PhoneInput
                  international
                  defaultCountry="PK"
                  id="phonedit"
                  className="uk-input"
                  placeholder="Enter phone number"
                  value={this.state.phoneintlnumberedit}
                  onChange={this.handleInternationalnumberedit}/>

              </div>
              </div>


              </div>

              



                <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="">Addess</label>
                    <div className="uk-form-controls">
                    <input className="uk-input" id="addressEdit" name="addressEdit" type="text" placeholder="Address" />
                    </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    Email
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="emailEdit"
                      name="email"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    Gender
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select" id="genderEdit" name="gender">
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    Country
                  </label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      id="countryEdit"
                      name="countryEdit"
                      onChange={this.countryChange}
                    >
                      <option value="">Select Country</option>
                      {this.state.country.isLoaded ? this.handleCountry() : ""}
                      {/* <option value="Pakistan">Pakistan</option> */}
                    </select>
                    {/* <input className="uk-input" id="country" name="country" type="country" placeholder="Country" /> */}
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    City
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select" id="cityEdit" name="cityEdit">
                      <option value="">Select City</option>
                      {this.state.city.isLoaded ? this.handleCity() : ""}
                      {/* <option value="Pakistan">Pakistan</option> */}
                    </select>
                    {/* <input className="uk-input" id="city" name="city" type="city" placeholder="City" /> */}
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label" htmlFor="">
                    Tags
                  </label>
                  <div className="uk-form-controls">
                    <div className="chipBox">
                      <div className="chipBox CustomChipBox">
                        <Multiselect
                          id="saj"
                          options={this.state.tagList.list}
                          selectedValues={this.state.old_tags}
                          onSelect={this.onSelectTags}
                          onRemove={this.onRemoveTags}
                          displayValue="name"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="uk-margin">
                                <label>
                                <input className="uk-checkbox" id="invitationEdit" name="invitation" type="checkbox" checked /> I confirm that we have obtained consent to send message, email from contact being created with regulations and Telenor <Link to="javascript"> Terms of Service</Link>
                                </label>
                                
                            </div> */}
                <div className="uk-margin">
                  <div className="ModalStyleBtns">
                    <button
                      className="CnclBtn"
                      onClick={this.closeEditContactModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="AddTeamBtn"
                      id="editContact"
                      onClick={this.editContact}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* popup contact start */}

          <div
            id="ContactProfile"
            className="AddTeamModal ModalStyle uk-flex-top"
            uk-modal=""
          >
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
              <button
                className="uk-modal-close-default"
                onClick={this.closeContactProfileModal}
                type="button"
              >
                {" "}
                <Close />
              </button>
              <h3>
                {this.state.popUpName}
                <div className="uk-inline SegmentAction uk-float-right">
                <button class="newFlowBtn uk-float-right uk-margin-right" onClick={this.showReinitiate}><Add/> <span>Quick Reply</span></button>
                            {/* <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                            <div uk-dropdown="mode: click;pos:top-right">
                                <ul className="uk-nav uk-dropdown-nav">
                                    <li><a href="#"><Edit />Edit</a></li>
                                    <li><a href="#"><StopOutline />Unsubscribe</a></li>
                                    <li><a href="#"><Download />Download Data</a></li>
                                    <hr/>
                                    <li class="hintAlert"><a href="#"><TrashCan />Delete</a></li>
                                </ul>
                            </div> */}
                        </div>
              </h3>
              <div className="uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                  <div className="ProfileLeft">
                    <div className="profile_pic audience_pic">
                      {/* <img src="/images/placeholder.jpg" alt="" id="profile_pic" /> */}
                      <img
                        src={this.state.popAvatar}
                        alt="Image"
                        id="profile_pic"
                      />
                    </div>
                    <div className="contact_details">
                      <ul>
                        <li>
                          <button>
                            {" "}
                            <span>
                              <MobileCheck />
                            </span>{" "}
                            <span id="mobile_no">{this.state.popUpNumber}</span>
                          </button>
                        </li>
                        <li>
                          <button>
                            {" "}
                            <span>
                              <CheckmarkOutline />
                            </span>{" "}
                            <span>{this.state.popUpSunscribe}</span>
                          </button>
                        </li>
                        <li>
                          {" "}
                          <span>
                            <GenderFemale />
                          </span>{" "}
                          <span id="gender">{this.state.popUpGender}</span>
                        </li>
                        <li>
                          {" "}
                          <span>
                            <Time />
                          </span>{" "}
                          <span id="time">{this.state.popUpContactTime}</span>
                        </li>
                        <li className="uk-hidden">
                          <button
                            id="user_id"
                            value={this.state.popUpId}
                          ></button>
                          {this.state.popUpId}
                        </li>
                        {/* <li> <span><Credentials /></span> <span>{this.state.popUpContactTime}</span></li> */}
                      </ul>
                      {/* <button class="newFlowBtn"><span>Start Chat</span></button> */}
                    </div>
                  </div>
                </div>
                <div className="uk-width-1-2">
                  <div className="pause_automation ">
                    <div className="head">
                      <div className="">
                        <h4>
                          Contact Tags{" "}
                          <button
                            className="time uk-float-right"
                            onClick={this.addTag}
                          >
                            <span>
                              <Add />
                            </span>
                            Add Tag
                          </button>
                        </h4>
                      </div>
                    </div>
                    <div className="tags" id="tags"></div>
                    <hr />
                    <div className="CustomHead">
                      <div className="head">
                        <div className="">
                          <h4>Opted in through</h4>
                        </div>
                        <div className="">
                          <div className="tags uk-margin-remove">
                            <button
                              onClick={(e) =>
                                this.StartChat(this.state.popUpName)
                              }
                            >
                              {" "}
                              👋 Get Started
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>

                    <div
                      id="AddTagsModal"
                      className="AddTeamModal AudienceAddTagsModal ModalStyle uk-flex-top"
                      uk-modal="esc-close: false; bg-close: false"
                    >
                      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button
                          className="uk-modal-close-default"
                          type="button"
                        >
                          {" "}
                          <Close />
                        </button>
                        <h3>Add tag</h3>
                        <div id="errorAlerttag"></div>
                        <div id="successAlerttag"></div>
                        <form
                          id="tagform"
                          className="uk-form-stacked"
                          action="javascript:void(0);"
                        >
                          <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="teamName">
                              Select tags
                            </label>

                            <div className="chipBox">
                              <div className="chipBox CustomChipBox">
                                <Multiselect
                                  options={this.state.tagList.list}
                                  selectedValues={this.state.old_contact_tags}
                                  onSelect={this.onSelectTags}
                                  onRemove={this.onRemoveTags}
                                  displayValue="name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="uk-margin">
                            <div className="ModalStyleBtns">
                              <button
                                className="AddTeamBtn"
                                id="addtagss"
                                onClick={this.attachTag}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* auto search start */}

        <div
          id="SearchAutoTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_dates"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_dates"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn ">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeAutoSearchModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.AutoadvanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* auto search end */}

        <div id="ImportcontactModal" className="ImportcontactModal ModalStyle uk-flex-top" uk-modal="">
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                        <button className="uk-modal-close-default" type="button"> <Close /></button>
                        <h3>Import Contacts</h3>
                        
                        <div className="uk-grid" uk-grid="">

                            <div className="uk-width-1-1@m">

                                <div class="AddBroadcastSide_i">
                                <div class="uk-width-1-1">
                                <p><b>Upload a file</b></p>
                                </div>
                                <form action="javascript:void(0);">

                                    <div id="errorAlertContactImport"></div>
                                    
                                    <div class="uk-margin CustomForm">
                                        <input type="text" name= "segment_name" id="segment_name" className="uk-input" placeholder="File Name"/>
                                    </div>

                                    <div class="uk-margin CustomForm">
                                        <label class="uk-form-label" for="template">Import contacts from a CSV or tab-delimited TXT file.</label>
                                        <div class="uk-margin">
                                            <div uk-form-custom="">
                                                <CloudUpload />
                                                <p>Browser</p>
                                                <input type="file" onChange={this.onFileUpload}/>
                                            </div>
                                            
                                            {/* <p>validation text: File upload error. Select a file to continue.</p> */}                                            
                                        </div>
                                        <p className="uk-text-left" id="uploadedFile"></p>
                                    </div>
                                    <Link className="uk-text-left" to="/files/contacts_import.csv" target="_blank" download>Download Sample File</Link>    
                                    <div class="uk-margin uk-margin-medium-top">
                                        <div class="ModalStyleBtns">
                                            <button class="CnclBtn" onClick={this.hideImportcontactModal}>Cancel</button>
                                            <button class="AddTeamBtn" id="uploadButton" onClick={this.uploadFile}>Import</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>



        {/* contact search start */}

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="teamName">
                    Country
                  </label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select to_date"
                      id="filtercountry"
                      onChange={this.filtercountryChange}
                    >
                      <option value="" disabled selected>
                        Select Country
                      </option>
                      {this.state.country.isLoaded ? this.handleCountry() : ""}
                    </select>
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="teamName">
                    City
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select from_date" id="filtercity">
                      <option value="" disabled selected>
                        Select City
                      </option>
                      {this.state.city.isLoaded ? this.handleCity() : ""}
                    </select>
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="teamName">
                    Gender
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select to_date" id="filtergender">
                      <option value="" disabled selected>
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="teamName">
                    Agents
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select from_date" id="filteragents">
                      <option value="" disabled selected>
                        Select Agent
                      </option>
                      {this.state.agentList.isLoaded ? this.handleAgent() : ""}
                    </select>
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Tag
                </label>
                <div className="uk-form-controls">
                  <div className="chipBox">
                    <div className="chipBox CustomChipBox">
                      <Multiselect
                        id="saj"
                        options={this.state.tagList.list}
                        selectedValues={this.state.tags}
                        onSelect={this.onSelectTags}
                        onRemove={this.onRemoveTags}
                        displayValue="name"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn">
                  <button
                    className="CnclBtn"
                    onClick={this.clearcontactFilters}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* contact search end */}
          {/* reinitiate popup */}
     <div id="reinitiate" className="AlertPOPUP ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3 >
              <span className="uk-inline">Quick Reply</span> 
              <span className="uk-inline uk-float-right"> 
                  <img src="/images/done_all_black_24dp.svg" />{" "} 
              </span>
            </h3>
            <div id="errorAlert2"></div>
            <div className="resolved_content">
                <input type="number" class="uk-input uk-margin" placeholder="Enter Phone No" id="phone_no" name="phone_no"/>
                <select class="uk-select" id="suggested_msgs_pop" name="suggested_msgs_pop">
                    <option>Select Message</option>
                </select>
                <div id="errorAlertQuick"></div>
                <div id="successAlertQuick"></div>
                <form className="uk-form-stacked uk-margin-medium-top" action="javascript:void(0);">
                    <div className="ModalStyleBtns uk-float-right">
                        {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                        <button className="AddTeamBtn"  onClick={this.confirmReinitaite}>Send</button>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default Audience;
