import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Menu } from "../Components/Common/Menu";
import { Sidebar } from "../Components/Common/Sidebar";
import Footer from "../Components/Common/Footer";
//import Sidebar from "../Components/Common/Sidebar";
import SignUp from "../Components/Pages/Account/SignUp";
import AccountRequest from "../Components/Pages/Account/AccountRequest";
import GoogleSignUp from "../Components/Pages/Account/GoogleSignUp";
import GoogleSignUp2 from "../Components/Pages/Account/GoogleSignUp2";
import Contacts from "../Components/Pages/Contacts/Contacts";
import Automation from "../Components/Pages/Automation/Automation";
import Broadcasting from "../Components/Pages/Broadcasting/Broadcasting";
import Integrations from "../Components/Pages/Integrations/Integrations";
import WA_Integrate from "../Components/Pages/Integrations/WA-Integrate";
import Stats from "../Components/Pages/Stats/Stats";
import Webhook from "../Components/Pages/Webhook/Webhook";
import Agents from "../Components/Pages/Agent/Agent";
import Team from "../Components/Pages/Team/Team";
import BulkAction from "../Components/Pages/BulkAction/BulkAction";
import { Loader } from "../Components/Common/Loader";
import { Helmet } from "react-helmet";
import SignIn from "../Components/Pages/Account/SignIn";
import Builder from "../Components/Pages/FlowBuilder/Builder";
import Tags from "../Components/Pages/Tags/Tags";
import EditBuilder from "../Components/Pages/FlowBuilder/EditBuilder";
import Segments from "../Components/Pages/Contacts/Segment";
import Admin from "../Components/Pages/Admin/Admin";
import Support from "../Components/Pages/Support/Support";
import EditAgent from "../Components/Pages/Agent/EditAgent";
import Reports from "../Components/Pages/Reports/Reports";
import Notification from "../Components/Pages/Tags/Notification";
import Audience from "../Components/Pages/Audience/Audience";
import CampaignReport from "../Components/Pages/Automation/CampaignReport";
import OfficeHours from "../Components/Pages/Admin/OfficeHours";
import Teams from "../Components/Pages/Team/Teams";
import PackagesRequest from "../Components/Pages/Packages/PackagesRequest";
import DayForm from "../Components/Sections/DayForm";
import { Package } from "@carbon/icons-react";
import Packages from "../Components/Pages/Notification/Packages";
import LiveChat from "../Components/Pages/LiveChat/LiveChat";
import CampaignChat from "../Components/Pages/Automation/CampaignChat";
import BotChat from "../Components/Pages/LiveChat/BotChat";
import Settings from "../Components/Pages/Audience/Settings";
import KeywordReport from "../Components/Pages/Automation/KeywordReport";
import Company from "../Components/Pages/Company/Company";
import CreateCompany from "../Components/Pages/Company/CreateCompany";
import { AdminWorkspace } from "../Components/Pages/Workspace/AdminWorkspace";
import StatsDetail from "../Components/Pages/Stats/StatsDetail";
import StatsReportOld from "../Components/Pages/Stats/StatsReportOld";

import StatsReport from "../Components/Pages/Stats/StatsReport";

import EventCoverageReport from "../Components/Pages/Stats/Hico/EventCoverageReport";
import OtherCityReport from "../Components/Pages/Stats/Hico/OtherCityReport";
import BusinessQueriesReport from "../Components/Pages/Stats/Hico/BusinessQueriesReport";
import CustomerSatisfactionReport from "../Components/Pages/Stats/Hico/CustomerSatisfactionReport";

import CreateNewCompany from "../Components/Pages/Company/CreateNewCompany";
import AdminBroadcasting from "../Components/Pages/Broadcasting/AdminBroadcasting";
import QuickReplies from "../Components/Pages/Tags/QuickReplies";
import Tag from "../Components/Pages/Tags/Tag";
import FB_Integrate from "../Components/Pages/Integrations/FB-Integrate";
import FBSettingIntegrate from "../Components/Pages/Integrations/FBSettingIntegrate";
import Insta_Integrate from "../Components/Pages/Integrations/Insta-Integrate";
import InstaSettingIntegrate from "../Components/Pages/Integrations/InstaSettingIntegrate";
import EmailIntegration from "../Components/Pages/Integrations/Email";
import EditEmailIntegration from "../Components/Pages/Integrations/Edit_Email";
import Email_Integrate from "../Components/Pages/Integrations/Email-Integrate";
import ShopifyIntegrate from "../Components/Pages/Integrations/ShopifyIntegrate";
import SMSIntegrate from "../Components/Pages/Integrations/SMSIntegrate";
import GoogleSheetsIntegrate from "../Components/Pages/Integrations/GoogleSheetsIntegrate";
import { Workspace } from "../Components/Pages/Workspace/Workspace";
import { EmailWorkspace } from "../Components/Pages/Workspace/EmailWorkspace";
import { FacebookWorkspace } from "../Components/Pages/Workspace/FacebookWorkspace";
import { WhatsappWorkspace } from "../Components/Pages/Workspace/WhatsappWorkspace";
import Role from "../Components/Pages/Team/Role";
import Users from "../Components/Pages/Team/Users";
import ConversationDetail from "../Components/Pages/Stats/ConversationDetail";
import MonthlyActiveUsers from "../Components/Pages/Stats/MonthlyActiveUsers";
import MonthlyActiveUsersDetail from "../Components/Pages/Stats/MonthlyActiveUsersDetail";
import IntegrationQueries from "../Components/Pages/Admin/IntegrationQueries";
import Insights from "../Components/Pages/Insights/Insights";
import BroadCastAnalytic from "../Components/Pages/Insights/BroadCastAnalyticMain";
import BroadCastAnalyticMain from "../Components/Pages/Insights/BroadCastAnalyticMain";
import BroadcastingDetail from "../Components/Pages/Broadcasting/BroadcastingDetail";
import TeamDetail from "../Components/Pages/Team/TeamDetail";
import Query from "../Components/Pages/Query/QueryForm";
import QueryForm from "../Components/Pages/Query/QueryForm";
import PackagesConsumptionMonthly from "../Components/Pages/Packages/PackagesConsumptionMonthly";
import ForgetPassword from "../Components/Pages/Account/ForgetPassword";
import ConfirmPassword from "../Components/Pages/Account/ConfirmPassword";
import PasswordRecovery from "../Components/Pages/Account/PasswordRecovery";
import KeywordReportDetail from "../Components/Pages/Automation/KeywordReportDetail";
import PsoReport from "../Components/Pages/Form/PsoReport";
import Report from "../Components/Pages/Report/Report";
function App() {
  return (
    <BrowserRouter>
      {/*<Loader/>*/}
      <Routes>
        <Route exact path="/" element={<SignIn />} />
        <Route exact path="/forget-password" element={<ForgetPassword />} />
        <Route
          exact
          path="/password-recovery/:verification_code"
          element={<PasswordRecovery />}
        />
        <Route exact path="/confirm-password" element={<ConfirmPassword />} />
        <Route exact path="signup" element={<SignUp />} />
        <Route exact path="google-signup" element={<GoogleSignUp />} />
        <Route exact path="google-signup-2" element={<GoogleSignUp2 />} />
        <Route exact path="account-request" element={<AccountRequest />} />

        <Route
          exact
          path="/dashboard"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content workspace">
                  {/* <TopBar/> */}
                  <Workspace />
                </div>
              </div>
            </div>
          }
        />
        <Route
          exact
          path="/statdashboard"
          // path="/dashboard"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content RemovePadding">
                  <Stats />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/admin-dashboard"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content workspace">
                  <AdminWorkspace />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/contacts"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Contacts />
                </div>
              </div>
            </div>
          }
        />
        <Route
          exact
          path="/automation"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className=" page_body_content h_match RemovePadding">
                  <Automation />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/reports"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Reports />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/broadcasting"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Broadcasting />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/settings"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Settings />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/agents"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className=" page_body_content h_match RemovePadding">
                  <Agents />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/webhook"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Webhook />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Integrations />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/whatsapp"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <WA_Integrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/facebook-messenger/settings/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <FBSettingIntegrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/instagram"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Insta_Integrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/instagram/settings"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <InstaSettingIntegrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="chat"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content chatSec h_match">
                  <LiveChat />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="bulk-action"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content chatSec h_match">
                  <BulkAction />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/team"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Team />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/teams"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Teams />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/edit-flow-builder/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <EditBuilder />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/flow-builder"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Builder />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/tags"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className=" page_body_content h_match RemovePadding">
                  <Tags />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/audience"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className=" page_body_content h_match RemovePadding">
                  <Audience />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/admin"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Admin />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/support"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Support />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/notification"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Notification />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/edit-agent"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <EditAgent />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/contacts/segments"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Segments />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/automation/campaign-report/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <CampaignReport />
                </div>
              </div>
            </div>
          }
        />
        <Route
          exact
          path="/automation/keywordReportDetails/details/:keyword/:dateto/:datefrom/:heading"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <KeywordReportDetail />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/automation/campaign-chat/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content chatSec h_match ">
                  <CampaignChat />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/packages"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className=" page_body_content h_match RemovePadding">
                  <Packages />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/office-hours"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <OfficeHours />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/packages-requests"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <PackagesRequest />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/bot-chat"
          element={
            <div>
              <div
                className="collapse_area page_wrapper "
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content chatSec h_match">
                  <BotChat />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/automation/keyword-report/:slug/:slugs"
          element={
            <div>
              <div
                className="collapse_area  page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content  h_match RemovePadding">
                  <KeywordReport />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/company"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Company />
                </div>
              </div>
            </div>
          }
        />
        <Route
          exact
          path="/packages-consumption-monthly"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <PackagesConsumptionMonthly />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/create-company/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <CreateCompany />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/stats_detail"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <StatsDetail />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/stats_report_old"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <StatsReportOld />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/stats-report"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <StatsReport />
                </div>
              </div>
            </div>
          }
        />


<Route
          exact
          path="/report"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Report/>
                </div>
              </div>
            </div>
          }
        />
        
        <Route
          exact
          path="/campagin-report"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <PsoReport />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/create-new-company"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <CreateNewCompany />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/admin-broadcasting"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className=" page_body_content h_match RemovePadding">
                  <AdminBroadcasting />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/quick-replies"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className=" page_body_content h_match RemovePadding">
                  <QuickReplies />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/tag"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Tag />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/facebook-messenger"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <FB_Integrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/facebook-messenger/settings/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <FBSettingIntegrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/integrations/email-integration"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <EmailIntegration />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/email-account"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Email_Integrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/integrations/edit-email/settings/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <EditEmailIntegration />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/shopify"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <ShopifyIntegrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/sms"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <SMSIntegrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integrations/google-sheet"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <GoogleSheetsIntegrate />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/role"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Role />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/monthly-active-users"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <MonthlyActiveUsers />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/monthly-active-users/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <MonthlyActiveUsersDetail />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/users"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <Users />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/broadcasting-detail/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <BroadcastingDetail />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/team-detail/:slug"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <TeamDetail />
                </div>
              </div>
            </div>
          }
        />

        <Route
          path="/conversation-detail"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <ConversationDetail />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/integration-queries"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <IntegrationQueries />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/broadcast-analytic-main"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match">
                  <BroadCastAnalyticMain />
                </div>
              </div>
            </div>
          }
        />
        <Route
          exact
          path="/insights"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match">
                  <Insights />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/query"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <QueryForm />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/event_coverage_report"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <EventCoverageReport />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/other_cities_report"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <OtherCityReport />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/customer_satisfaction_report"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <CustomerSatisfactionReport />
                </div>
              </div>
            </div>
          }
        />

        <Route
          exact
          path="/business_queries_report"
          element={
            <div>
              <div
                className="collapse_area page_wrapper"
                uk-height-match="target: > .h_match"
              >
                <Sidebar />
                <div className="page_body_content h_match RemovePadding">
                  <BusinessQueriesReport />
                </div>
              </div>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
