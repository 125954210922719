import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown,
    Timer,
    Chat,
    Forum,
    ChatLaunch,
  } from "@carbon/icons-react";

import { ChannelsOverviewChart } from "../../Sections/ChannelsOverviewChart";
import { DateRange } from "../../Common/DateRange";
import { ChatbotTotalSession } from "../../Sections/ChatbotTotalSession";
import { ChatBotMessageChart } from "../../Sections/ChatBotMessageChart";
import { ChatBotUserChart } from "../../Sections/ChatBotUserChart";
import { UserAnalyticTotalUser } from "../../Sections/UserAnalyticTotalUser";
import { CSATBreakDown } from "../../Sections/CSATBreakDown";
import { CSATBreakdownResponseVolume } from "../../Sections/CSATBreakdownResponseVolume";
// import DateRangePicker from 'rsuite/DateRangePicker';
// // import { DateRangePicker, Stack } from 'rsuite';
// import subDays from 'date-fns/subDays';
// import startOfWeek from 'date-fns/startOfWeek';
// import endOfWeek from 'date-fns/endOfWeek';
// import addDays from 'date-fns/addDays';
// import startOfMonth from 'date-fns/startOfMonth';
// import endOfMonth from 'date-fns/endOfMonth';
// import addMonths from 'date-fns/addMonths';
  
class ChatBotUserAnalytic extends React.Component {

    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2023-01-30";
        var from = "2021-01-01";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }


MultiSelectFilter =()=>{
    $(".UltabsBtns").toggleClass("showMenu");
    $(".UltabsBtns > li label").click(function(){
        $(".MultiSelectFilter > p span").text($(this).text());
    });
}


  
  

    render() {
        return (
         <>
            <div uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">User analytics</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">

                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>All Channels</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>




                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Summary</h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Chat  className="iconBara" /> Total Chats</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>203,820</h3>
                                <p>Total users</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>186,457</h3>
                                <p>New users
                                    <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>New users</h5>
                                            <p>Users that communicated with the chatbot for the first time in the selected time range.</p>
                                        </div>
                                    </div>
                                </p>
                                
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>17,363</h3>
                                <p>Returning users
                                    <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Returning users</h5>
                                            <p>Returning users that communicated with the chatbot in the selected time period, and at least once before that.</p>
                                        </div>
                                    </div>
                                </p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Forum  className="iconBara" /> Engagement
                                            {/* <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                                <div uk-dropdown="mode: click">
                                                    <h5>All response time</h5>
                                                    <p>The metric that displays agent's responsiveness.</p>
                                                </div>
                                            </div> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>17,623</h3>
                                <p>Total engaged users</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>11,261</h3>
                                <p>New engaged users
                                    <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>New engaged users</h5>
                                            <p>Users that communicated with the chatbot for the first time in the selected time period.</p>
                                        </div>
                                    </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>18,628</h3>
                                <p>Returning engaged users
                                    <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Returning engaged users</h5>
                                            <p>Returning users that communicated with the chatbot in the selected time period, and at least once before that.</p>
                                        </div>
                                    </div>
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                


                {/* * Total sessions* */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <ul className="tabsBtns desktop uk-heading-divider" uk-switcher="connect: .multiTabNewLiveAgent">
                                <li className="uk-inline infoTool"><a>Total users</a></li>
                                <li className="uk-inline infoTool"><a>Engaged users</a></li>
                            </ul>
                        </div>
                        <ul className="uk-switcher multiTabNewLiveAgent">
                        <li>
                            <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    {/* <div className="head"><h5>All Channels Overview</h5></div> */}
                                        {/* Chart */}
                                        <div className="ChannelsOverviewGraph">
                                            <UserAnalyticTotalUser
                                            stateTo={this.state.to}
                                            stateFrom={this.state.from} />
                                        </div>
                                </div>
                            </div>
                        </li>
                        <li>
                        <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    {/* <div className="head"><h5>All Channels Overview</h5></div> */}
                                        {/* Chart */}
                                        <div className="ChannelsOverviewGraph">
                                            <UserAnalyticTotalUser
                                            stateTo={this.state.to}
                                            stateFrom={this.state.from} />
                                        </div>
                                </div>
                            </div>
                        </li>
                        
                        </ul>
                    </div>
                </div>



               




                {/* User details */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">User details
                            <div className="uk-float-right ">
                                <div className="AgentDropDown">
                                    <a  className="MultiSelectFilter">
                                        <p><span>Export</span> <ChevronDown /></p>
                                    </a>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                <th className="uk-table-expand">USER ID</th>
                                <th className="uk-table-shrink uk-text-nowrap">SESSIONS</th>
                                <th className="uk-table-shrink uk-text-nowrap">INBOUND MESSAGES</th>
                                <th className="uk-table-shrink uk-text-nowrap">USER STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>923312051572</span></a></td>
                                    <td className="uk-text-nowrap"><span>1</span> </td>
                                    <td className="uk-text-nowrap"><span>2</span></td>
                                    <td className="uk-text-nowrap"><span>Chatbot deactivated</span></td>
                                </tr>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>923312051572</span></a></td>
                                    <td className="uk-text-nowrap"><span>1</span> </td>
                                    <td className="uk-text-nowrap"><span>2</span></td>
                                    <td className="uk-text-nowrap"><span>Chatbot deactivated</span></td>
                                </tr>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>923312051572</span></a></td>
                                    <td className="uk-text-nowrap"><span>1</span> </td>
                                    <td className="uk-text-nowrap"><span>2</span></td>
                                    <td className="uk-text-nowrap"><span>Chatbot deactivated</span></td>
                                </tr>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>923312051572</span></a></td>
                                    <td className="uk-text-nowrap"><span>1</span> </td>
                                    <td className="uk-text-nowrap"><span>2</span></td>
                                    <td className="uk-text-nowrap"><span>Chatbot deactivated</span></td>
                                </tr>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>923312051572</span></a></td>
                                    <td className="uk-text-nowrap"><span>1</span> </td>
                                    <td className="uk-text-nowrap"><span>2</span></td>
                                    <td className="uk-text-nowrap"><span>Chatbot deactivated</span></td>
                                </tr>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>923312051572</span></a></td>
                                    <td className="uk-text-nowrap"><span>1</span> </td>
                                    <td className="uk-text-nowrap"><span>2</span></td>
                                    <td className="uk-text-nowrap"><span>Chatbot deactivated</span></td>
                                </tr>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>923312051572</span></a></td>
                                    <td className="uk-text-nowrap"><span>1</span> </td>
                                    <td className="uk-text-nowrap"><span>2</span></td>
                                    <td className="uk-text-nowrap"><span>Chatbot deactivated</span></td>
                                </tr>




                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                 {/* * CSAT Response* */}
                 <div className="uk-width-1-2">
                    <div className="uk-card uk-card-custom uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">CSAT breakdown by response</h5>
                        </div>
                        <div className="uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <CSATBreakDown/>
                        </div>
                    </div>
                </div>


                {/* * CSAT VOLUME* */}
                <div className="uk-width-1-2">
                    <div className="uk-card  uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">CSAT breakdown by response volume</h5>
                        </div>
                        <div className="uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <CSATBreakdownResponseVolume/>
                        </div>
                    </div>
                </div>



                 {/* Surveys */}
                 <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Surveys
                            <div className="uk-float-right ">
                                <div className="AgentDropDown">
                                    <a className="MultiSelectFilter">
                                        <p><span>Export</span> <ChevronDown /></p>
                                        {/* <ul className="UltabsBtns">
                                            <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                                        </ul> */}
                                    </a>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand">QUEUES</th>
                                    <th className="uk-table-expand ">LIVE CHATS</th>
                                    <th className="uk-table-auto uk-text-nowrap">SCORE</th>
                                    <th className="uk-table-expand uk-text-nowrap">COMMENTS</th>
                                    <th className="uk-table-shrink uk-text-nowrap">SURVEYED</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn happyBtn">Happy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>Talking with Osama Alam</span> </td>
                                    <td><button className="ctmBtn unhappyBtn">Unhappy</button></td>
                                    <td className="uk-text-nowrap"><span></span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>

                              
                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
         </>
    );
}
}
export default ChatBotUserAnalytic;