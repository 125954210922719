import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Select from "react-select";
import axios from "axios";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Constant from "../../../App/Constant";
import { ContextualHelp } from "@adobe/react-spectrum";
import { Multiselect } from "multiselect-react-dropdown";
import {
  CaretDown,
  Checkmark,
  Download,
  MessageQueue,
  InProgress,
  Information,
  ViewFilled,
  ReplyAll,
  Send,
  NotSent,
  Chat,
  Search,
  TrashCan,
  Add,
  AddAlt,
  Edit,
  ArrowRight,
  OverflowMenuVertical,
  Close,
} from "@carbon/icons-react";

class IntegrationQueries extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      channel_name: "",
      tag_name: "",
      description: "",
      tag_id: "",
      status: "",
      email: "",
      rowsCountTags: 0,
    };
  }

  handleStatusChange(event) {
    this.setState({ status: event.target.status });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.email });
  }

  handleNameChange(event) {
    this.setState({ channel_name: event.target.channel_name });
    // console.log("first name updated");
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.description });
  }

  componentDidMount() {
    var pageCount = 1;
    $("#field_wrapper").on("click", ".deleteBtn", function () {
      $(this).parent().parent().parent().remove();
    });

    this.callApi(pageCount);

    // if(localStorage.getItem('loginStatus')== 1){
    //   let screens = localStorage.getItem('rolesceens').split(',');
    //   var checking = screens.includes("16");
    //    if(checking == true){

    //       }else{
    //           window.location.href = "/";
    //       }
    //   }
    //   else{
    //       window.location.href = "/";
    //   }

    $("#cross").hide();
  }

  EditTagModal = (id) => {
    $("#EditTagModal").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findtag(id);
  };

  findtag = (id) => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let tag_id = id;
    axios({
      method: "POST",
      url: Constant.url + "single-integration-request",
      data: { company_id: company_id, id: tag_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var tag = result.data.data;
        // console.log("yaseeeeeeen", tag);

        classRef.setState({
          channel_name: tag.channel_type,
          description: tag.description,
          email: tag.email,
          tag_id: tag.id,
        });
      }
    });
  };

  closeEditNewBot = (id) => {
    $("#EditNewBot").removeClass("uk-flex uk-open");
  };

  addTeamModal = () => {
    $("#AddTagModal").addClass("uk-flex uk-open");
  };

  closeAgentModal = () => {
    $("#AddTagModal").removeClass("uk-flex uk-open");
  };

  callApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "list-integration-request",
      data: { page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTags: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTags: result.data.rowscount,
        });
      }
    });
  };

  TagsSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#TagsSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "list-integration-request",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountTags: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountTags: result.data.rowscount,
        });
      }
    });
  };

  handleTagsClick = (data) => {
    let currentPage = data.selected + 1;
    this.TagsSorting(currentPage);
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  closeConsentss = () => {
    $("#AddConsent").removeClass("uk-flex uk-open");
  };

  addconsent = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  editTag = () => {
    $("#edittags").text("Processing");
    $("#edittags").prop("disabled", true);

    let classRef = this;
    var oldscreens = "";
    oldscreens = Object.keys(classRef.state.oldscreens)
      .map(function (k) {
        return classRef.state.oldscreens[k].id;
      })
      .join(",");
    var nameArr = oldscreens;

    var data = {};
    data = {
      role: $("#edittagName").val(),
      screens: nameArr,
      role_id: $("#tag_id").val(),
      company_id: localStorage.getItem("company_id"),
      status: $("#role_status").val(),
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-role",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/role";
        } else {
          $("#editerrorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#edittags").text("Edit Tag");
          $("#edittags").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  deletetag = () => {
    var id = $("#addconsent").val();
    let classRef = this;
    var tag_id = id;
    var company_id = localStorage.getItem("company_id");
    var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "delete-roles",
      data: { company_id: company_id, role_id: tag_id },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/tag";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 1000);

        window.location.href = "/tag";
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr id={"mtag_" + response.id}>
        {/* <td>
                <input className="uk-checkbox autoSelect"  name="multi_check[]" value={response.id} type="checkbox" />
          </td> */}
        <td>
          <span>{response.channel_type}</span>
        </td>
        <td>
          <span>{response.email}</span>
        </td>

        <td>
          <span>{response.description}</span>
        </td>

        <td>
          <span>{response.create_date}</span>
        </td>
        <td>
          <a
            className="iconcircleBtn"
            uk-tooltip="title: View"
            onClick={(e) => this.EditTagModal(response.id)}
          >
            <Edit />
          </a>
        </td>
      </tr>
    ));

    return result;
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    this.setState({ advancesearch: searchword });

    if (searchword !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "list-integration-request",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callApi();
    $("#cross").hide();
  };

  addNewTemplate = () => {
    $("#NewBot").addClass("uk-flex uk-open");
  };

  closeNewBot = () => {
    $("#NewBot").removeClass("uk-flex uk-open");
  };

  createTag = () => {
    $("#addtag").text("Processing");
    $("#addtag").prop("disabled", true);
    let classRef = this;

    var screens = "";
    screens = Object.keys(classRef.state.screens)
      .map(function (k) {
        return classRef.state.screens[k].id;
      })
      .join(",");

    var nameArr = screens.split(",");

    var data = {};
    data = {
      role: $("#newtagName").val(),
      company_id: localStorage.getItem("company_id"),
      screens: screens,
      status: $("#status").val(),
    };
    axios({
      method: "POST",
      url: Constant.url + "create-role",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlertss").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          // window.location.href = "/role";
        } else {
          $("#errorAlertss").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#addtag").text("Create Tag");
          $("#addtag").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          return;
        }
      })
      .then(function (response) {});
  };

  deleteMultiTag = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#mtag_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "bulk-delete-tags",
      data: {
        company_id: localStorage.getItem("company_id"),
        tag_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        window.location.reload();
      }
    });
  };

  selectCheckboxTag = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  downloadtags = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "download-tags?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  render() {
    const options = [
      { value: "Urgent", label: "Urgent" },
      { value: "Resolved", label: "Resolved" },
    ];
    return (
      <Content>
        <div
          className="contactContainer BroadcastingContainer "
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="tagInputLabel widthMax">
                  <h3>Integration Queries</h3>
                </div>
                <div className="tagInput">
                  <div className="search_field">
                    <form action="javascript:void(0);">
                      <input
                        className="uk-input"
                        type="text"
                        onChange={this.searchrecord}
                        name="searchword"
                        id="searchword"
                        value={this.state.advancesearch}
                        placeholder="Search through name"
                      />
                    </form>
                    <div class="CampaignReports Filtermain">
                      <div className="uk-width-auto">
                        <button
                          id="cross"
                          style={{ right: "10px" }}
                          onClick={this.advSearchClear}
                        >
                          <Close />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-width-1-2@m">
                {/* <button className="newFlowBtn uk-float-right" onClick={this.addTeamModal} ><Add /> <span>New Role</span></button> */}
                {/* <button class="newFlowBtn uk-float-right uk-margin-right"  onClick={this.downloadtags}><Add/> <span>Export Role</span></button> */}
              </div>
            </div>
          </div>
          <div className="numberSorting uk-float-right">
            <div class="uk-inline">
              <label>
                Show
                <select
                  onChange={(e) => this.TagsSorting()}
                  id="TagsSortingBtn"
                >
                  {/* <option disabled selected>Sorting</option> */}
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>500</option>
                </select>
                entries
              </label>
            </div>
          </div>

          <div className="tableSec">
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>Channel Name</th>
                  <th>Email</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.state.dataList.isLoaded ? (
                  this.handleTable()
                ) : this.state.dataList.msg == "not" ? (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        Data not Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        <div className="mdl">
                          <div className="mdl_inner">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {this.state.rowsCountTags > 1 ? (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"...."}
                pageCount={this.state.rowsCountTags}
                onPageChange={this.handleTagsClick}
                containerClassName={"uk-pagination uk-float-right"}
                activeClassName={"uk-active"}
                marginPagesDisplayed={3}
                pageRangeDisplayed={6}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          id="EditTagModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>View Query</h3>
            <div id="editerrorAlert"></div>
            <div id="editsuccessAlert"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Channel Name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    name="Name"
                    id="name"
                    value={this.state.channel_name}
                    onChange={this.handleNameChange}
                    readOnly
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="email"
                    name="Email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    type="email"
                    readOnly
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="Description">
                  Requirments
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-textarea textareaHeight"
                    id="description"
                    name="description"
                    type="text"
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                    readOnly
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button
                    className="CnclBtn uk-modal-close"
                    onClick={this.closeAgentModal}
                  >
                    Cancel
                  </button>
                  {/* <button className="AddTeamBtn" id="edittags" onClick={this.editTag} >Edit Role</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Role?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsent"
                    onClick={this.deletetag}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default IntegrationQueries;
