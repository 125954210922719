import * as React from "react";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { TextField, Button, Form } from "@adobe/react-spectrum";
import Constant, { url } from "../../../App/Constant";
import "react-phone-number-input/style.css";
import axios from "axios";
import $ from "jquery";

class ForgetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      forgetRequest: false,
    };
  }

  initializeToken = () => {};

  forgetRequest = () => {
    let email = $("#forgetemail").val();
    $("#forgetReset").text("Continue");
    $("#forgetReset").prop("disabled", false);
    if (email == "") {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Email is required</p></div>'
      );

      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 500);

      return;
    } else {
      axios({
        method: "POST",
        url: Constant.url + "forget-passowrd",
        data: { email },
      }).then(function (response) {
        $("#forgetReset").text("Continue");
        $("#forgetReset").prop("disabled", true);
        if (response.data.code == 200) {
          $("#successAlert").empty();
          $("#forgetemail").val("");
          $("#forgetReset").text("Reset");
          $("#forgetReset").prop("disabled", false);
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>we have emailed you instructions for setting your password, if an account exist with the email you entered. You should receive them shortly.<br/>If you do not receive an email, please make sure you have entered the address you registered with, and check your spam folder.</p></div>'
          );
        } else if (response.data.data.length > 0) {
          $("#errorAlert").empty();
          $("#forgetReset").text("Reset");
          $("#forgetReset").prop("disabled", false);
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data.errors[0] +
              "</p></div>"
          );
        } else {
          $("#errorAlert").empty();
          $("#forgetReset").text("Reset");
          $("#forgetReset").prop("disabled", false);
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
        }
      });
    }
  };

  render() {
    return (
      <section className="login_page">
        <div className="mdl">
          <div className="mdl_inner">
            <div className="uk-container uk-container-expand uk-padding-remove">
              <div className="login_page_inner">
                <div className="login_side2 h_match desktop BgImg">
                  <img src="/images/berrytalkslogo.svg" />
                </div>
                <div className="login_side1 h_match">
                  <div className="login_side1_inner mobile">
                    <img src="/images/berrytalkslogodark.svg" uk-svg="" />
                    <h1>Forgot Password</h1>
                    <Form
                      maxWidth="size-3600"
                      isQuiet
                      aria-label="Quiet example"
                    >
                      <div className="fields">
                        <TextField
                          width="100%"
                          maxWidth="100%"
                          marginTop="size-200"
                          label="Email address"
                          scale="medium"
                          id="forgetemail"
                          name="forgetemail"
                          type="email"
                          defaultValue=""
                        />
                      </div>
                      <div className="fields">
                        <div className="uk-grid uk-grid-collapse uk-child-width-1-1">
                          <div id="errorAlert"></div>
                          <div id="successAlert"></div>
                        </div>
                      </div>
                      <div className="fields">
                        <Button
                          className="signinBtn"
                          id="forgetReset"
                          position="relative"
                          marginTop="size-200"
                          right="size-0"
                          maxWidth="size-1200"
                          variant="cta"
                          onPress={this.forgetRequest}
                        >
                          Reset
                        </Button>
                      </div>
                    </Form>
                  </div>

                  <div className=" copyright uk-position-bottom">
                    <p>© 2023 Convex Interactive. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ForgetPassword;
