import * as React from "react";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { TextField, Button, Form } from "@adobe/react-spectrum";
import Constant, { url } from "../../../App/Constant";
import "react-phone-number-input/style.css";
import axios from "axios";
import $ from "jquery";

class ConfirmPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      forgetRequest: false,
    };
  }

  initializeToken = () => {};

  changePassword = () => {
    let password = $("#passwordForget").val();
    let confirmpassword = $("#confirmPasswordForget").val();
    $("#passwordReset").text("Continue");
    $("#passwordReset").prop("disabled", false);
    if (password == "") {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Password is required</p></div>'
      );

      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
        $("#passwordReset").text("Send");
        $("#passwordReset").prop("disabled", false);
      }, 1000);

      return;
    } else if (password != "" && password.length <= 7) {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Password must be atleast 8 characters long</p></div>'
      );

      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
        $("#passwordReset").text("Send");
        $("#passwordReset").prop("disabled", false);
      }, 1000);

      return;
    } else if (confirmpassword == "") {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Confirm Password is required</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
        $("#passwordReset").text("Send");
        $("#passwordReset").prop("disabled", false);
      }, 1000);

      return;
    } else if (confirmpassword != "" && confirmpassword.length <= 7) {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Confirm password must be atleast 8 characters long</p></div>'
      );

      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
        $("#passwordReset").text("Send");
        $("#passwordReset").prop("disabled", false);
      }, 1000);

      return;
    } else if (password !== confirmpassword) {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>password and Confirm Password are not matched</p></div>'
      );

      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
        $("#passwordReset").text("Send");
        $("#passwordReset").prop("disabled", false);
      }, 1000);

      return;
    }

    if (password != "" && confirmpassword != "") {
      axios({
        method: "POST",
        url: Constant.url + "reset-password",
        data: {
          user_id: localStorage.getItem("user_id"),
          password: password,
          password_confirmation: confirmpassword,
        },
      }).then(function (response) {
        $("#passwordReset").text("Continue");
        $("#passwordReset").prop("disabled", true);
        if (
          response.data.code == 200 &&
          response.data.message == "Password Reset Successfully"
        ) {
          $("#passwordForget").val("");
          $("#confirmPasswordForget").val("");
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Password Reset Successfully</p></div>'
          );
          $("#passwordReset").text("Send");
          $("#passwordReset").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 2000);
          setTimeout(function () {
            window.location.href = "/";
          }, 2000);
        }
      });
    }
  };

  render() {
    return (
      <section className="login_page">
        <div className="mdl">
          <div className="mdl_inner">
            <div className="uk-container uk-container-expand uk-padding-remove">
              <div className="login_page_inner">
                <div className="login_side2 h_match desktop BgImg">
                  <img src="/images/berrytalkslogo.svg" />
                </div>
                <div className="login_side1 h_match">
                  <div className="login_side1_inner mobile">
                    <img src="/images/berrytalkslogodark.svg" uk-svg="" />
                    <h1>Change Password</h1>
                    <Form
                      maxWidth="size-3600"
                      isQuiet
                      aria-label="Quiet example"
                    >
                      <div className="fields">
                        <TextField
                          width="100%"
                          marginTop="size-200"
                          maxWidth="100%"
                          label="Password"
                          type="password"
                          scale="medium"
                          name="passwordForget"
                          id="passwordForget"
                          defaultValue=""
                        />
                      </div>
                      <div className="fields">
                        <TextField
                          width="100%"
                          marginTop="size-200"
                          maxWidth="100%"
                          label="Confirm Password"
                          type="password"
                          scale="medium"
                          id="confirmPasswordForget"
                        />
                      </div>
                      <div className="fields">
                        <div className="uk-grid uk-grid-collapse uk-child-width-1-1">
                          <div id="errorAlert"></div>
                          <div id="successAlert"></div>
                        </div>
                      </div>
                      <div className="fields">
                        <Button
                          className="signinBtn"
                          id="passwordReset"
                          position="relative"
                          marginTop="size-200"
                          right="size-0"
                          maxWidth="size-1200"
                          variant="cta"
                          onPress={this.changePassword}
                        >
                          Send
                        </Button>
                      </div>
                    </Form>
                  </div>

                  <div className=" copyright uk-position-bottom">
                    <p>© 2023 Convex Interactive. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ConfirmPassword;
