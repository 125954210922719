import React from "react";
import ReactDOM from "react-dom";

// import "./styles.css";
import "../../../node_modules/react-bubble-chart/src/style.css";



import ZoomableBubbleChart from "./ZoomableBubbleChart";


function LiveChatBubbleChart() {


  // console.log('umair',DATA)




  return (
    <div className="App">
      <ZoomableBubbleChart  />
    </div>
  );
}

export default LiveChatBubbleChart;