import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../App/Constant";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);




export const WhatsappMonthlyActiveUserChart = () => {

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'] ,
        datasets: [
        {
            data: [100, 50, 350, 300, 0, 100, 350, 250, 100, 350, 50, 350, 100, 300, 320, 300, 310],
            backgroundColor: '#007FF9',
            borderColor: '#007FF9'


        },
        
        ],
  
  }
        
     


const options = {
    responsive: true,
    plugins: {
    legend: {
    position: 'right',
    display: false  ,
    },
    title: {
    display: true,
    // text: 'Chart.js Line Chart',
    },
    },
}
        
        return <Line options={options} data={data}/>;
      }
