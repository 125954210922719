import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import $ from "jquery";
import Constant from "../../../App/Constant";
import IntegrationsSidebar from "../../Common/IntegrationsSidebar";
import axios from "axios";
import { AddAlt, Close, Unlink, TextLink, Settings } from "@carbon/icons-react";

class FBSettingIntegrate extends React.Component {

  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handlepagename = this.handlepagename.bind(this);
    this.handlepageaccess = this.handlepageaccess.bind(this);
    this.handlepageid = this.handlepageid.bind(this);
    this.state = {
        facebook_page:"",
        facebook_access:"",
        facebook_page_id:"",
        status:"",
        type:"",
      
      
    }
  }


    componentDidMount() {
      this.callApi();
    }

    handlepagename(event){
        this.setState({ facebook_page: event.target.facebook_page });
    }

    handlepageaccess(event){
        this.setState({ facebook_access: event.target.facebook_access });
    }

    handlepageid(event){
        this.setState({ facebook_page_id: event.target.facebook_page_id });
    }


    callApi = () => {

      var url = window.location.pathname;
      var id = url.split('/');


      // console.log(id[4],'hefeeez')

      let classRef = this;
      axios({
        method: 'POST',
        url: Constant.url + "single-company-channel",
        data: { company_id: localStorage.getItem('company_id'),channel_id:id[4]}
      }).then(function (result) {

        if (result.data.status === true) {
          var office = result.data.data
          classRef.setState({
              facebook_page:office.name,
              facebook_access:office.page_access_token,
              facebook_page_id:office.page_id,
              status:office.is_active,
              type:office.type,
            })

          

        }
      })
    }

    mucheck  = () => { 
      $('a#tog_button').trigger('click');
      $('input#chkLive').attr( 'checked', true );
      $('#isactive').val(1);
     }

     handleToggleChange(event) {

    
      if($("#chkLive").prop('checked')){

      $("#isactive").val(0) 
      
      } else {

      $("#isactive").val(1) 

      }
    }

    editAccount = () =>{  
      let classRef = this;
      var data = {};
      var url = window.location.pathname;
      var id = url.split('/');
      data = {
      company_id: localStorage.getItem('company_id'),
      channel_id: id[4],
      name: $("#facebook_page").val(),
      page_access_token: $("#facebook_access").val(),
      page_id: $("#facebook_page_id").val(),
      type: $("#type").val(),
      is_active: $("#isactive").val(),
 
      }

      axios({
          method: 'POST',
          url: Constant.url + "edit-company-channel",
          data: data
        }).then(function (result) {

          if (result.data.code === 200) {
  
              $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
      
   

                     window.location.reload();
            }else{
      
              $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
              $('#editcompany').text('Save')
              $('#editcompany').prop('disabled', false);
              setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
              return;
          
      
            }
        
      });

      // console.log(data,'hafeeez')

  }


  addconsent = () =>{
    $("#AddResolved").addClass('uk-flex uk-open');
  }

  deletefb = () => {
    

    let classRef = this;

    var url = window.location.pathname;
    var id = url.split('/');

    var company_id = localStorage.getItem('company_id');
  
    axios({
      method: 'POST',
      url: Constant.url + "delete-company-channel",
      data: { company_id: company_id ,channel_id:id[2]}
  }).then(function (result) {
  
      if (result.data.code === 200) {
  
          $("#deletesuccessAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
          $("#AddResolved").removeClass('uk-flex uk-open');
          setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
          
           window.location.href = "/integrations/facebook-messenger";
  
      }else{
  
          $("#deleteerrorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
          setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
  
      }
  
  })
  
  }



  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>
                  Integrations <span> {">"} Facebook Messenger</span>
                </h3>
              </div>
            </div>
          </div>
          <IntegrationsSidebar />
          <div className="integration inner h_match bgWhite">
            <div className="uk-flex-top uk-grid">
              <div className="uk-width-3-4">

                <div id="errorAlert"></div>
                <div id="successAlert"></div>


                <h3>Connected Facebook Page</h3>
                

                {/**ADD NUM */}
                <div className="addnum_box uk-margin-medium-top addnum_boxLarge">
                  <h4>Connection Status</h4>
                  <div className="uk-grid-medium" uk-grid="">
                    <div className="uk-width-1">
                      <div className="AvailableToggle">

                      {(this.state.status == 'no') ? "":this.mucheck()}


                        <label className="switch">
                          <input
                            type="checkbox"
                            class="abc"
                            id="chkLive"
                            onClick={this.handleToggleChange}
                          />
                          <span className="slider round" />
                          {/* <a  id="tog_button" class="slider round" onClick={this.handleToggleChange} ></a> */}
                        </label>


                        <label className="CustomNoti">Enabled</label>
                      </div>
                    </div>
                    <div className="uk-width-3-4">
                      <div className="fields fieldsGrey">

                      <input class="uk-input"  id="facebook_page"  name="facebook_page" value={this.state.facebook_page} onChange={this.handlepagename} placeholder=""  />

                        {/* <TextField
                          width="100%"
                          marginTop="size-0"
                          maxWidth="100%"
                          label="Facebook Page Name"
                          id="facebook_page"  
                          name="facebook_page" 
                          value={this.state.facebook_page} 
                          onChange={this.handlepagename}
                          type="text"
                          scale="medium"
                          isRequired
                          necessityIndicator="icon"
                        /> */}
                        <input class="uk-input"  id="type" name="type" type="hidden" value={this.state.type}/>
                        <input class="uk-input"  id="isactive" name="isactive" type="hidden"/>


                      </div>
                    </div>
                    <div className="uk-width-3-4">
                      <div className="fields fieldsGrey">
                      <input class="uk-input" id="facebook_access" name="facebook_access" value={this.state.facebook_access} onChange={this.handlepageaccess} placeholder=""  />
                        {/* <TextField
                          width="100%"
                          marginTop="size-0"
                          maxWidth="100%"
                          label="Facebook Page Access Token"
                          id="facebook_access" 
                          name="facebook_access" 
                          value={this.state.facebook_access} 
                          onChange={this.handlepageaccess}
                          type="text"
                          scale="medium"
                          isRequired
                          necessityIndicator="icon"
                        /> */}
                      </div>
                    </div>
                    <div className="uk-width-3-4">
                      <div className="fields fieldsGrey">
                      <input class="uk-input" id="facebook_page_id" name="facebook_page_id" value={this.state.facebook_page_id} onChange={this.handlepageid} placeholder=""  />
                        {/* <TextField
                          width="100%"
                          marginTop="size-0"
                          maxWidth="100%"
                          label="Messanger short links"
                          id="facebook_page_id" 
                          name="facebook_page_id" 
                          value={this.state.facebook_page_id} 
                          onChange={this.handlepageid}
                          type="text"
                          scale="medium"
                          isRequired
                          necessityIndicator="icon"
                        /> */}
                      </div>
                    </div>
                    <div className="uk-width-1">
                      <h4 className="uk-margin-remove">
                        Refresh Facebook Permissions
                      </h4>
                      <p className="uk-margin-small-top">
                        Messenger can unexpectedly lose the connection to your
                        Facebook page permissions. If you encounter any trouble,
                        like content not sending or setting not saving, always
                        refresh your permissions first.
                      </p>
                    </div>

                    <div className="uk-width-1">
                      <div className="fields uk-margin-top spStyle">
                        <Button
                          className="signinBtn"
                          id="editcompany"
                          position="relative"
                          marginTop="size-200"
                          right=""
                          maxWidth="size-1400"
                          variant="cta"
                          onClick={this.editAccount}
                        >
                          Refresh Permissions
                        </Button>


                        
                      </div>
                    </div>
                    <div className="uk-width-1">
                      <h4 className="uk-margin-remove">
                        Remove Facebook Page from Berry Talks
                      </h4>
                      <p className="uk-margin-small-top">
                        You can completely disconnect and remove your Facebook
                        page from Berry Talks. You can do this if you want to
                        connect another page or completely disable Berry Talks
                        for that page.
                      </p>
                    </div>
                    <div className="uk-width-1">
                      <div className="fields uk-margin-top spStyle">
                        <button className="confirm" id="editcompany"  onClick= {(e)=>this.addconsent()}>Remove</button>
                      </div>
                    </div>
                  </div>
                </div>

                {/**ADD NUM */}


                
            <div id="AddResolved" className="AlertPOPUP ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3 >
              <span className="uk-inline"> Warning</span> 
              <span className="uk-inline uk-float-right"> 
                  <img src="/images/done_all_black_24dp.svg" />{" "} 
              </span>
            </h3>
            <div className="resolved_content">
              <p><b>Are you sure you want to delete Facebook Account?</b></p>
              <p className="small">You won't be able to revert this!</p>

              <form className="uk-form-stacked uk-margin-medium-top" action="javascript:void(0);">
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn uk-modal-close">Cancel</button>
                  <button className="AddTeamBtn" id="addconsent" onClick={this.deletefb}>Confirm</button>
                </div>
            </form>
            </div>
          </div>
        </div>

               
                
                
              </div>
            </div>
          </div>
        </div>

      </Content>
    );
  }
}
export default FBSettingIntegrate;
