import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { BarChart } from "../../Sections/BarChart";
import { AreaChart } from "../../Sections/AreaChart";
import { StackChart } from "../../Sections/StackChart";

import { MonthTickets } from "../../Sections/Admin/MonthTickets";

import { MonthTicketsAgents } from "../../Sections/Admin/MonthTicketsAgents";
import { MonthMessages } from "../../Sections/Admin/MonthMessages";
import { AgentsMonthMessages } from "../../Sections/Admin/AgentsMonthMessages";
import { BotMonthMessages } from "../../Sections/Admin/BotMonthMessages";

import { MessagesRates } from "../../Sections/Admin/MessagesRates";
import { AgentsMessagesRates } from "../../Sections/Admin/AgentsMessagesRates";
import { BotMessagesRates } from "../../Sections/Admin/BotMessagesRates";

import { DaySolvedTickets } from "../../Sections/Admin/DaySolvedTickets";
import { AgentDaySolvedTickets } from "../../Sections/Admin/AgentDaySolvedTickets";
import { BotDaySolvedTickets } from "../../Sections/Admin/BotDaySolvedTickets";

import { DayMessages } from "../../Sections/Admin/DayMessages";
import { AgentsDayMessages } from "../../Sections/Admin/AgentsDayMessages";
import { BotDayMessages } from "../../Sections/Admin/BotDayMessages";

import {
  Time,
  InProgress,
  Hourglass,
  CheckmarkOutline,
  ChatBot,
  UserAdmin,
  Error,
  ChevronDown,
  Chat,
  ReplyAll,
  Events,
  Information,
  Money,
  User,
  UserMultiple,
  Bot,
} from "@carbon/icons-react";
import $ from "jquery";
import axios from "axios";
import Constant from "../../../App/Constant";
import moment from "moment";

export class AdminWorkspace extends React.Component {
  constructor() {
    super();
    this.getCompanies = this.getCompanies.bind(this);
    this.handleCompanies = this.handleCompanies.bind(this);

    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },

      all_ticket: 0,
      new_ticket: 0,
      open_ticket: 0,
      pending: 0,
      closed: 0,
      closed_by_bot: 0,
      // closed_by_agent: '',
      expired: 0,
      total_customers: 0,
      companies: 0,
      revenues: 0,
      isLoaded: false,

      agent:0,
      comsumed_maus:0,
      consumed_conv: 0,
      bussiness_initiated: 0,
      user_initiated: 0,
      broadcast_campaigns: 0,
      allowed_maus:0,
      allowed_agents:0,
      //agent

      all_chats_agents: 0,
      assigned_chats_agents: 0,
      unassigned_chats_agents: 0,
      closed_by_agent: 0,
      total_agents: 0,
      replies_sent_by_agents: 0,

      isAgentLoaded: false,

      //bot

      all_chats_bot: 0,
      new_chats_bot: 0,
      open_chats_bot: 0,
      total_customers_bot: 0,
      total_incomming_outgoing_msgs_bot: 0,
      total_incomming_msgs_bot: 0,
      total_outgoing_msgs_bot: 0,

      isBotLoaded: false,

      from: "",
      to: "",
      company: "",
      default: "no",

      STARTDATE:
        moment().startOf("month").format("YYYY-MM-DD"),
     
      ENDDATE:
        moment().endOf("month").format("YYYY-MM-DD"),
      // companyId:localStorage.getItem('company_id'),
    };
  }

  componentDidMount() {
    var classRef = this;
    var fromdate = $("#fromdate").val();
    var todate = $("#todate").val();
    var companyid = $("#company").val();

    if (!localStorage.getItem("loginStatus")) {
      window.location.href = "/";

      if (!localStorage.getItem("role") == "3") {
        window.location.href = "/";
      }
    }

    // console.log(localStorage.getItem("role"), "user_data");
    classRef.setState({ company: companyid, from: fromdate, to: todate });
    this.callApi();
    this.packageConsumeMonthlyOverall('', this.state.STARTDATE, this.state.ENDDATE)
    this.getCompanies();
    this.callAgentApi();
    this.callBotApi();
    this.populate();

    $("#contypes").text("Over All");
    $("#all").show();
    $("#agent").hide();
    $("#bot").hide();

    setInterval(function () {
      if (classRef.state.default == "no") {
        classRef.callApi();
        // classRef.packageConsumeMonthlyOverall();
        classRef.callAgentApi();
        classRef.callBotApi();
      }
    }, 10000);
  }

  getCompanies = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "companies",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  handleCompanies = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <option value={response.id}>{response.company_name}</option>
    ));
    return result;
  };

  callApi = (company = "", from = "", todate = "") => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "admin-overview",
      data: { company_id: company, stateFrom: from, stateTo: todate },
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          isLoaded: false,
          new_ticket: stats.new_ticket,
          open_ticket: stats.open_ticket,
          pending: stats.pending,
          closed: stats.closed,
          closed_by_bot: stats.closed_by_bot,
          closed_by_agent: stats.closed_by_agent,
          expired: stats.expired,
          all_ticket: stats.all_ticket,
          total_customers: stats.total_customers,
          companies: stats.companies,
          revenues: stats.revenues,
        });
      }
    });
  };
  packageConsumeMonthlyOverall = (company, fromdate, todate) => {
    let classRef = this;
    let apiData={}

    if(company===''){
      apiData= { vendor_id:1, 
      from: fromdate=== '' ? this.state.STARTDATE: fromdate,
      to: todate==='' ? this.state.ENDDATE: todate}
    }
    else{
      apiData= { 
        vendor_id:1, 
        company_id:company,
        from: fromdate=== '' ? this.state.STARTDATE: fromdate,
        to: todate==='' ? this.state.ENDDATE: todate}
    }
    axios({
      method: "POST",
      url: Constant.url + "package-consumption-monthly-overall",
      data:apiData  
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          // isLoaded: false,
          agent: stats.agent,
          allowed_agents: stats.allowed_agents,
          allowed_maus: stats.allowed_maus,
          consumed_maus: stats.consumed_maus,
          consumed_conv: stats.consumed_conv,
          bussiness_initiated: stats.bussiness_initiated,
          user_initiated: stats.user_initiated,
          broadcast_campaigns: stats.broadcast_campaigns,
              });
      }
    });
  };

  callAgentApi = (company = "", from = "", todate = "") => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "admin-agents-overview",
      data: { company_id: company, stateFrom: from, stateTo: todate },
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          isAgentLoaded: true,
          all_chats_agents: stats.all_chats_agents,
          assigned_chats_agents: stats.assigned_chats_agents,
          unassigned_chats_agents: stats.unassigned_chats_agents,
          closed_by_agent: stats.closed_by_agent,
          total_agents: stats.total_agents,
          replies_sent_by_agents: stats.replies_sent_by_agents,
        });
      }
    });
  };

  callBotApi = (company = "", from = "", todate = "") => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "admin-bot-overview",
      data: { company_id: company, stateFrom: from, stateTo: todate },
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          isBotLoaded: true,
          all_chats_bot: stats.all_chats_bot,
          new_chats_bot: stats.new_chats_bot,
          open_chats_bot: stats.open_chats_bot,
          total_customers_bot: stats.total_customers_bot,
          total_incomming_outgoing_msgs_bot:
            stats.total_incomming_outgoing_msgs_bot,
          total_incomming_msgs_bot: stats.total_incomming_msgs_bot,
          total_outgoing_msgs_bot: stats.total_outgoing_msgs_bot,
        });
      }
      $("#searchButton").html("Search");
    });
  };

  handleFromChange(event) {
    this.setState({ from: event.target.from });
  }

  searchApi = () => {
    $("#searchButton").html("Searching...");
    let classRef = this;
    var fromdate = $("#fromdate").val();
    var todate = $("#todate").val();
    var companyid = $("#company").val();

    classRef.setState({
      company: companyid,
      from: fromdate,
      to: todate,
      default: "yes",
    });
    this.callApi(companyid, fromdate, todate);
    this.packageConsumeMonthlyOverall(companyid, fromdate, todate);
    this.callAgentApi(companyid, fromdate, todate);
    this.callBotApi(companyid, fromdate, todate);
  };

  populate = () => {
    var classRef = this;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var to = yyyy + "-" + mm + "-" + dd;
    var from = "2021-01-01";
    var companyid = "";
    classRef.setState({ from: from });
    classRef.setState({ to: to });
    classRef.setState({ company: companyid });

    // classRef.setState({from: from,to: to},()=>{
    // classRef.setState({from: from,to: to},()=>{
    //   console.log(this.state.to,'sajdain')
    // });
    // classRef.setState({});

    // console.log("hhhh", companyid);

    // console.log("javed", to);
    // console.log("hassam", from);
  };

  PopulateChoiceall = () => {
    $("#contypes").text("Over All");

    $("#all").show();
    $("#agent").hide();
    $("#bot").hide();
    // $("#all").addClass('uk-flex uk-open');
    // $('.uk-dropdown').removeClass('uk-open');

    // if(choice=="Bot"){

    //   $("#bot").show();
    //   $("#all").hide();
    //   $("#agent").hide();

    // }
  };

  PopulateChoiceagents = () => {
    $("#contypes").text("Agent");
    $("#agent").show();
    $("#all").hide();
    $("#bot").hide();
  };
  PopulateChoicebot = () => {
    $("#contypes").text("Bot");
    $("#bot").show();
    $("#all").hide();
    $("#agent").hide();
  };

  render() {
    return (
      <Content>
        <div className="topbarFixed">
          <ul className="tabsStyle">
            <li className="active">
              <a href="javascript:;" onClick={this.PopulateChoiceall}>
                Over All
              </a>
            </li>
            {/* <li>
              <a href="javascript:;" onClick={this.PopulateChoiceagents}>
                Agent
              </a>
            </li>
            <li>
              <a href="javascript:;" onClick={this.PopulateChoicebot}>
                Bot
              </a>
            </li> */}
          </ul>
          <div className="uk-float-right">
            <form
              className="uk-grid-small"
              uk-grid=""
              action="javascript:void(0);"
              id="chatform"
              method="post"
              enctype="multipart/form-data"
            >
              <div className="uk-width-auto@m uk-width-1-2@s">
                <label className="lineht">Company</label>
              </div>

              <div className="uk-width-auto">
                <select
                  className="uk-select uk-border-rounded"
                  id="company"
                  name="company"
                >
                  <option value="">Select Company</option>
                  {this.state.dataList.isLoaded ? this.handleCompanies() : ""}
                </select>
              </div>

              <div className="uk-width-auto@m uk-width-1-2@s">
                <label className="lineht">From Date</label>
              </div>
              <div className="uk-width-auto">
                <input
                  type="date"
                  id="fromdate"
                  min="2021-07-01"
                  name="fromdate"
                  onChange={this.handleFromChange}
                  className="uk-select uk-border-rounded uk-form-width-medium"
                />
              </div>
              <div className="uk-width-auto">
                <label className="lineht">To Date</label>
              </div>
              <div className="uk-width-auto">
                <input
                  type="date"
                  id="todate"
                  min="2021-07-01"
                  name="todate"
                  className="uk-select uk-border-rounded uk-form-width-medium"
                />
              </div>
              <div className="uk-width-auto">
                <button
                  style={{ color: "white", background: "#007AD7" }}
                  onClick={this.searchApi}
                  id="searchButton"
                  type="submit"
                  className="uk-button uk-button-defaul uk-border-rounded"
                >
                  {" "}
                  Search{" "}
                </button>
              </div>
              <div className="uk-width-auto">
                <button
                  style={{ color: "white", background: "#007AD7" }}
                  type="submit"
                  onClick={() => window.location.reload(false)}
                  className="uk-button uk-button-defaul uk-border-rounded"
                >
                  {" "}
                  Clear{" "}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="DateFilterIcon">
          {/* <a href="#menuIcon2" uk-toggle=""></a> */}
          <a href="#mobiledateFilter" uk-toggle="">
            Filter by Date
            {/* <img src="/images/dateFilter.svg" uk-svg="" /> */}
          </a>
        </div>

        <div id="mobiledateFilter" className="sidebar mobile" uk-offcanvas="">
          <div className="uk-offcanvas-bar">
            <button
              className="uk-offcanvas-close"
              type="button"
              uk-close=""
            ></button>
            <div
              className="mobiledateFilter uk-grid-small uk-flex-center"
              uk-height-match=".uk-card"
              uk-grid=""
            >
              <div className="uk-width-1-1">
                <div className="uk-float-right">
                  <form
                    className="uk-grid-small"
                    uk-grid=""
                    action="javascript:void(0);"
                    id="chatform"
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <div className="uk-width-auto@m uk-width-1-1@s">
                      <label className="lineht">From Date</label>
                    </div>
                    <div className="uk-width-auto@m uk-width-1-1@s">
                      <input
                        type="date"
                        id="fromdate"
                        min="2021-07-01"
                        name="fromdate"
                        onChange={this.handleFromChange}
                        className="uk-select fromdate uk-border-pill uk-form-width-medium"
                      />
                    </div>
                    <div className="uk-width-auto@m uk-width-1-1@s">
                      <label className="lineht">To Date</label>
                    </div>
                    <div className="uk-width-auto@m uk-width-1-1@s">
                      <input
                        type="date"
                        id="todate"
                        min="2021-07-01"
                        name="todate"
                        className="todate uk-select uk-border-pill uk-form-width-medium"
                      />
                    </div>
                    <div className="uk-width-auto@m uk-width-1-1@s">
                      <button
                        style={{ color: "white", background: "#007AD7" }}
                        onClick={this.searchApi}
                        id="searchButton"
                        type="submit"
                        className="DataFormBtn uk-button uk-button-defaul uk-border-pill"
                      >
                        {" "}
                        Search{" "}
                      </button>
                    </div>
                    <div className="uk-width-auto@m uk-width-1-1@s">
                      <button
                        style={{ color: "white", background: "#007AD7" }}
                        type="submit"
                        onClick={() => window.location.reload(false)}
                        className="DataFormBtn uk-button uk-button-defaul uk-border-pill"
                      >
                        {" "}
                        Clear{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-container ContainerCustom">
          <div id="all" className="all">
            <div className="agenList">
              <ul>
                <li>
                  <a>
                    <User />{" "}
                    <p>
                      Agents: <span>{this.state.agent}/ {this.state.allowed_agents}</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <Chat />{" "}
                    <p>
                      Conversations: <span>{this.state.consumed_conv}/{this.state.allowed_maus}</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <UserMultiple />{" "}
                    <p>
                      MAU: <span>4/4500</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <p>
                      Expiry Date: <span>20 Nov 2022</span>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="stats_container">
            <div
                className="uk-child-width-expand@s uk-grid uk-grid-collapse uk-margin-medium-bottom"
                uk-grid=""
              >

                <div>
                <Link className="page-route-links" to="/packages-consumption-monthly">
                  <div className="box" uk-height-match="">
                    <div className="text">
                      {/* <h6>{this.state.new_ticket}</h6><span>New Ticket</span> */}
                      <div className="card_txt">
                        <h6>{this.state.agent}</h6>
                        <span>Total Agent</span>
                      </div>
                    </div>
                    <div className="icon">
                      {/* <Bot /> */}

                      <div
                        className="info"
                        uk-tooltip="Number of Total Agent"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

                <div>
                <Link className="page-route-links" to="/packages-consumption-monthly">
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.consumed_maus}</h6>
                        <span>Consumed MAUs</span>
                      </div>
                    </div>
                    <div className="icon">
                      {/* <InProgress /> */}

                      <div
                        className="info"
                        uk-tooltip="Number of Consumed MAUs"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

                <div>
                <Link className="page-route-links" to="/packages-consumption-monthly">

                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.consumed_conv}</h6>
                        <span>Consumed Conversation</span>
                      </div>
                    </div>
                    <div className="icon">
                      {/* <Chat /> */}
                      <div
                        className="info"
                        uk-tooltip="Number of Consumed Conversation"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                  </Link>

                </div>

      

               

              

              
              </div>
            <div
                className="uk-child-width-expand@s uk-grid uk-grid-collapse uk-margin-bottom"
                uk-grid=""
              >

                <div>
                <Link className="page-route-links" to="/packages-consumption-monthly">
                  <div className="box" uk-height-match="">
                    <div className="text">
                      {/* <h6>{this.state.new_ticket}</h6><span>New Ticket</span> */}
                      <div className="card_txt">
                        <h6>{this.state.agent}</h6>
                        <span>Total Agent</span>
                      </div>
                    </div>
                    <div className="icon">
                      {/* <Bot /> */}

                      <div
                        className="info"
                        uk-tooltip="Number of Total Agent"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
          <div>
                <Link className="page-route-links" to="/packages-consumption-monthly">

                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.user_initiated}</h6>
                        <span>User Initiated</span>
                      </div>
                    </div>
                    <div className="icon">
                      {/* <CheckmarkOutline /> */}

                      <div
                        className="info"
                        uk-tooltip="Number of User Initiated"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

                <div>
                <Link className="page-route-links" to="/packages-consumption-monthly">
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.broadcast_campaigns}</h6>
                        <span>Broadcast Campagins</span>
                      </div>
                    </div>
                    <div className="icon">
                      {/* <Events /> */}

                      <div
                        className="info"
                        uk-tooltip="Number of Broadcast Campagins"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

              
              </div>
              {/* <div
                className="uk-child-width-expand@s uk-grid uk-grid-collapse"
                uk-grid=""
              >
                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.all_ticket}</h6>
                        <span>All Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Time />

                      <div
                        className="info"
                        uk-tooltip="Number of conversations"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.new_ticket}</h6>
                        <span>New Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <InProgress />

                      <div
                        className="info"
                        uk-tooltip="Number of conversations today"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.open_ticket}</h6>
                        <span>Open Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Chat />
                      <div
                        className="info"
                        uk-tooltip="Number of conversations that are unserolved"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.pending}</h6>
                        <span>Pending</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Hourglass />

                      <div
                        className="info"
                        uk-tooltip="Number of conversations that are not replied by agents"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.closed}</h6>
                        <span>Resolved</span>
                      </div>
                    </div>
                    <div className="icon">
                      <CheckmarkOutline />

                      <div
                        className="info"
                        uk-tooltip="Number of conversations that are not replied by agents"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.total_customers}</h6>
                        <span>Total Customers</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Events />

                      <div
                        className="info"
                        uk-tooltip="Number of customers approached"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.companies}</h6>
                        <span>Total Companies</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Events />
                      <div
                        className="info"
                        uk-tooltip="Number of customers approached"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.revenues}</h6>
                        <span>Total Revenues</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Money />

                      <div
                        className="info"
                        uk-tooltip="Number of customers approached"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
            <div className="box">
              <div className="text">
                <h6>{this.state.expired}</h6>
                <span>Expired</span>
                {(this.state.isLoaded) ? 
                <div className="card_txt"><h6>{this.state.expired}</h6><span>Expired</span></div>:
                <div class="uk-position"><div uk-spinner=""></div></div>}
              </div>
              <div className="icon">
                <Error />
              </div>
            </div>
          </div>
              </div> */}
            </div>
            {/* <div className="graphs_container">
              <div className="graphs_section">
                <div
                  className="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s"
                  uk-grid=""
                  uk-height-match
                >
                  <div className="">
                    <div className="head">
                      <h5>Month wise Chat Status</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <MonthTickets
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="head">
                      <h5>Month wise Total message</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <MonthMessages
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="graphs_section uk-margin-medium-top">
                <div
                  className="uk-grid uk-child-width-1-3@m uk-child-width-1-1@s "
                  uk-grid=""
                >
                  <div>
                    <div className="head">
                      <h5>Message Delivery Rate</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <MessagesRates
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="head">
                      <h5>Resolved Chats</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <DaySolvedTickets
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="head">
                      <h5>Messages per Day Average</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <DayMessages
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div id="agent" className="agent">
            <div className="agenList">
              <ul>
                <li>
                  <a>
                    <User />{" "}
                    <p>
                      Agents: <span>14/15</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <Chat />{" "}
                    <p>
                      Conversations: <span>67/5000</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <UserMultiple />{" "}
                    <p>
                      MAU: <span>4/4500</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <p>
                      Expiry Date: <span>20 Nov 2022</span>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="stats_container">
              <div
                className="uk-child-width-expand@s uk-grid uk-grid-collapse"
                uk-grid=""
              >
                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.all_chats_agents}</h6>
                        <span>All Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Time />
                      <div
                        className="info"
                        uk-tooltip="Number of conversations"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.assigned_chats_agents}</h6>
                        <span>Assign Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <InProgress />
                      <div
                        className="info"
                        uk-tooltip="Number of assign conversations"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.unassigned_chats_agents}</h6>
                        <span>UnAssigned Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <CheckmarkOutline />
                      <div
                        className="info"
                        uk-tooltip="Number of unassign conversations"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.closed_by_agent}</h6>
                        <span>Solved by Agent</span>
                      </div>
                    </div>
                    <div className="icon">
                      <UserAdmin />
                      <div
                        className="info"
                        uk-tooltip="Number of conversations solved by agents"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.total_agents}</h6>
                        <span>Total Agents</span>
                      </div>
                    </div>
                    <div className="icon">
                      <ChatBot />
                      <div className="info" uk-tooltip="Number of total agents">
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.replies_sent_by_agents}</h6>
                        <span>Total Replies Sent</span>
                      </div>
                    </div>
                    <div className="icon">
                      <ReplyAll />
                      <div
                        className="info"
                        uk-tooltip="Number of agent replies"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div>
    <div className="box">
      <div className="text">
        <h6>{this.state.expired}</h6>
        <span>Expired</span>
        {(this.state.isLoaded) ? 
        <div className="card_txt"><h6>{this.state.expired}</h6><span>Expired</span></div>:
        <div class="uk-position"><div uk-spinner=""></div></div>}
      </div>
      <div className="icon">
        <Error />
      </div>
    </div>
  </div> */}
              </div>
            </div>
            <div className="graphs_container">
              <div className="graphs_section">
                <div
                  className="uk-grid uk-child-width-1-2"
                  uk-grid=""
                  uk-height-match
                >
                  <div className="">
                    <div className="head">
                      <h5>Month wise Chat Status</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <MonthTicketsAgents
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                      {/* <StackChart /> */}
                    </div>
                  </div>
                  <div className="">
                    <div className="head">
                      <h5>Month wise Total message</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <AgentsMonthMessages
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                      {/* <BarChart /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="graphs_section uk-margin-medium-top">
                <div
                  className="uk-grid uk-child-width-1-3m uk-child-width-1-1@s"
                  uk-grid=""
                >
                  <div>
                    <div className="head">
                      <h5>Message Delivery Rate</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      {/* <AreaChart /> */}
                      <AgentsMessagesRates
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="head">
                      <h5>Resolved Chats</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      {/* <BarChart /> */}
                      <AgentDaySolvedTickets
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="head">
                      <h5>Messages per Day Average</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      {/* <BarChart /> */}
                      <AgentsDayMessages
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="bot" className="bot">
            <div className="agenList">
              <ul>
                <li>
                  <a>
                    <User />{" "}
                    <p>
                      Agents: <span>14/15</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <Chat />{" "}
                    <p>
                      Conversations: <span>67/5000</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <UserMultiple />{" "}
                    <p>
                      MAU: <span>4/4500</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a>
                    <p>
                      Expiry Date: <span>20 Nov 2022</span>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="stats_container">
              <div
                className="uk-child-width-expand@s uk-grid uk-grid-collapse"
                uk-grid=""
              >
                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.all_chats_bot}</h6>
                        <span>All Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Time />
                      <div
                        className="info"
                        uk-tooltip="Number Of Conversations"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.new_chats_bot}</h6>
                        <span>New Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <InProgress />
                      <div
                        className="info"
                        uk-tooltip="Number of conversations today"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.open_chats_bot}</h6>
                        <span>Open Chats</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Chat />
                      <div
                        className="info"
                        uk-tooltip="Number of conversations that are unresolved"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.total_customers_bot}</h6>
                        <span>Total Customer Bot</span>
                      </div>
                    </div>
                    <div className="icon">
                      <Hourglass />
                      <div
                        className="info"
                        uk-tooltip="Number of conversations by bot"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.total_incomming_outgoing_msgs_bot}</h6>
                        <span>Total In/Out Messages</span>
                      </div>
                    </div>
                    <div className="icon">
                      <CheckmarkOutline />
                      <div
                        className="info"
                        uk-tooltip="Number of incoming and outgoing messages"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.total_incomming_msgs_bot}</h6>
                        <span>Total Incoming Messages</span>
                      </div>
                    </div>
                    <div className="icon">
                      <UserAdmin />
                      <div
                        className="info"
                        uk-tooltip="Number of incoming messages"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="box" uk-height-match="">
                    <div className="text">
                      <div className="card_txt">
                        <h6>{this.state.total_outgoing_msgs_bot}</h6>
                        <span>Total Outgoing Messages</span>
                      </div>
                    </div>
                    <div className="icon">
                      <ReplyAll />
                      <div
                        className="info"
                        uk-tooltip="Number of outgoing messages"
                      >
                        <Information />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div>
    <div className="box">
      <div className="text">
        <h6>{this.state.expired}</h6>
        <span>Expired</span>
        {(this.state.isLoaded) ? 
        <div className="card_txt"><h6>{this.state.expired}</h6><span>Expired</span></div>:
        <div class="uk-position"><div uk-spinner=""></div></div>}
      </div>
      <div className="icon">
        <Error />
      </div>
    </div>
  </div> */}
              </div>
            </div>
            <div className="graphs_container">
              <div className="graphs_section">
                <div
                  className="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s"
                  uk-grid=""
                  uk-height-match
                >
                  <div className="">
                    <div className="head">
                      <h5>Month wise Total message</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <BotMonthMessages
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                      {/* <BarChart /> */}
                    </div>
                  </div>

                  <div className="">
                    <div className="head">
                      <h5>Message Delivery Rate</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      {/* <AreaChart /> */}
                      <BotMessagesRates
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="graphs_section uk-margin-medium-top">
                <div
                  className="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s"
                  uk-grid=""
                  uk-height-match
                >
                  <div className="">
                    <div className="head">
                      <h5>Resolved Chats</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      <BotDaySolvedTickets
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="head">
                      <h5>Messages per Day Average</h5>
                    </div>
                    <div className="uk-card uk-card-default uk-padding-small">
                      {/* <AreaChart /> */}
                      <BotDayMessages
                        stateCompany={this.state.company}
                        stateTo={this.state.to}
                        stateFrom={this.state.from}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
