import * as React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
  Home,
  Chat,
  User,
  WatsonHealth3DCurveAutoColon,
  Rocket,
  UserMultiple,
  Flash,
  Settings,
  Help,
  CaretSortDown,
  Events,
  TagGroup,
  Credentials,
  TaskTools,
  Analytics,
  Logout,
  NotificationNew,
  DocumentImport,
  Notification,
  ReportData,
} from "@carbon/icons-react";
import Constant from "../../App/Constant";
import axios from "axios";
import $ from "jquery";
import { SidebarNotifications } from "./SidebarNotifications";
import NotificationPopup from "./NotificationPopup";

export class Sidebar extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleNumbers = this.handleNumbers.bind(this);

    this.state = {
      screens: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
      ],
      roleScreen: [],
      roleWiseScreen: [],

      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      datanotificationList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      newnotification: "",
      default: "no",
    };
  }

  componentDidMount() {
    let classRef = this;
    this.callApi();
    this.newNotificationApi();
    this.roleScreens();

    setInterval(function () {
      if (classRef.state.default == "no") {
        classRef.newNotificationApi();
      }
    }, 10000);
  }

  roleScreens = () => {
    let classRef = this;
    var screens = localStorage.getItem("rolesceens").split(",");
    var roleScreen = [];
    // console.log(screens);
    for (var i = 0; i < classRef.state.screens.length; i++) {
      for (var j = 0; j < screens.length; j++) {
        if (classRef.state.screens[i] == screens[j]) {
          roleScreen[i] = true;
          break;
        } else {
          roleScreen[i] = false;
        }
      }
    }
    // console.log('ROLEEEE',roleScreen);
    classRef.setState({ roleScreen: roleScreen });

    // console.log(screens,'ALI')
    // classRef.setState({roleScreen:screens})
  };

  seenNotification = () => {
    let classRef = this;
    var data = {};
    data = {
      user_id: localStorage.getItem("user_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "has-seen",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.getNotification();
        classRef.setState({ newnotification: "" });
      }
    });
  };

  newNotificationApi = () => {
    // console.log("newNotification");
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "has-notification",
      data: {
        user_id: localStorage.getItem("user_id"),
        access_token: localStorage.getItem("access_token"),
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          newnotification: result.data.data,
        });

        if (result.data.authenticated == false) {
          localStorage.clear();
          window.location.href = "/";

          // axios({
          //   method: "POST",
          //   url: Constant.url + "loggedout",
          //   data: {
          //     company_id: localStorage.getItem("company_id"),
          //     agent_id: localStorage.getItem("user_id"),
          //   },
          // }).then(function (result) {
          //   if (result.data.status === true) {
          //     localStorage.clear();
          //     window.location.href = "/";
          //   }
          // });
        }
      } else {
        if (result.data.authenticated == false) {
          localStorage.clear();
          window.location.href = "/";

          // axios({
          //   method: "POST",
          //   url: Constant.url + "loggedout",
          //   data: {
          //     company_id: localStorage.getItem("company_id"),
          //     agent_id: localStorage.getItem("user_id"),
          //   },
          // }).then(function (result) {
          //   if (result.data.status === true) {
          //     localStorage.clear();
          //     window.location.href = "/";
          //   }
          // });
        }
      }
    });
  };

  getNotification = () => {
    window.UIkit.offcanvas("#SidebarNotification").show();

    let classRef = this;
    var data = {};
    data = {
      user_id: localStorage.getItem("user_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "notifications",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          datanotificationList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          datanotificationList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "company-channels",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  changeNumber = (type, number, icon) => {
    // alert('sjadinnn')

    localStorage.setItem("channelimage", "/images/" + icon);
    localStorage.setItem("channeltype", type);
    localStorage.setItem("channelnumber", number);
    localStorage.setItem("company_msisdn", number);

    window.location.href = "/dashboard";
  };

  handleNumbers = () => {
    let result = "";
    let index = 0;

    result = this.state.dataList.list.map((response, index) => (
      <li key={index}>
        <button
          onClick={(e) =>
            this.changeNumber(
              response.type,
              localStorage.getItem("channeltype") == "whatsapp"
                ? response.number
                : response.number,
              response.icon
            )
          }
        >
          <img src={"/images/" + response.icon} alt="" />{" "}
          {localStorage.getItem("channeltype") == "whatsapp"
            ? response.number
            : response.number}
        </button>
      </li>
    ));
    return result;
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.datanotificationList.list.map((response, index) => (
      <li>
        <div className="notificationBox">
          <div className="notificationImg">
            <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
            <img
              className="uk-border-circle bp"
              src="/images/whatsapp.png"
              alt=""
            />
          </div>
          <div className="notificationTxt">
            <p>
              <b>{response.title}</b>
              {response.text}
            </p>
            <span>{response.created_date}</span>
          </div>
        </div>
      </li>
    ));
    return result;
  };

  logout = () => {
    let classRef = this;

    axios({
      method: "POST",
      url: Constant.url + "loggedout",
      data: {
        company_id: localStorage.getItem("company_id"),
        agent_id: localStorage.getItem("user_id"),
      },
    }).then(function (result) {
      if (result.data.status === true) {
        localStorage.clear();
        window.location.href = "/";
      }
    });
  };
  render() {
    return (
      <div>
        <audio
          id="buzzer"
          src="/audio/message_tone.mp3"
          type="audio/mp3"
        ></audio>
        <div id="menuIcon2" className="sidebar mobile" uk-offcanvas="">
          <div className="uk-offcanvas-bar">
            <div className="sidebar">
              <aside className="Sidebar h_match">
                <div className="Sidebar_inner"></div>
              </aside>
            </div>
          </div>
        </div>

        <div className="collapse_area sidebar desktop">
          <aside className="Sidebar h_match">
            <div className="Sidebar_inner">
              <Link to="#" className="sidebarLogo">
                <img src="/images/logosidebar.svg" alt="" />
              </Link>

              {/* <NotificationPopup/> */}
              {localStorage.getItem("role") == 3 ? (
                <ul>
                  {localStorage.getItem("loginStatus") == 1 &&
                  this.state.roleScreen[12] == true ? (
                    <li>
                      <Link to="/admin-dashboard">
                        <Home />
                        <span>Admin Home</span>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}

                  {/* {(localStorage.getItem('loginStatus') == 1 && this.state.roleScreen[15] == true )?<li><Link to="/packages-requests"><User /><span>Packages Request</span></Link></li>:""} */}

                  {localStorage.getItem("loginStatus") == 1 &&
                  this.state.roleScreen[13] == true ? (
                    <li>
                      <Link to="/company">
                        <UserMultiple />
                        <span>Company</span>
                      </Link>{" "}
                    </li>
                  ) : (
                    ""
                  )}

                  {/* {(localStorage.getItem('loginStatus') == 1 && this.state.roleScreen[16] == true )?<li><Link to="/admin-broadcasting"><UserMultiple /><span>Admin Broadcasting</span></Link>{" "}</li>:""} */}

                  {localStorage.getItem("loginStatus") == 1 &&
                  this.state.roleScreen[14] == true ? (
                    <li>
                      <Link to="/create-new-company">
                        <UserMultiple />
                        <span>Add Company</span>
                      </Link>{" "}
                    </li>
                  ) : (
                    ""
                  )}

                  {/* {(localStorage.getItem('loginStatus') == 1 && this.state.roleScreen[18] == true )?<li><Link to="/integration-queries"><UserMultiple /><span>Integration Queries</span></Link>{" "}</li>:""} */}

                  {/* <li><Link to="/admin-dashboard"><Home /><span>Admin Home</span></Link></li>
              <li><Link to="/packages-requests"><User /><span>Packages Request</span></Link></li>
              <li><Link to="/company"><UserMultiple /><span>Company</span></Link>{" "}</li>
              <li><Link to="/admin-broadcasting"><UserMultiple /><span>Admin Broadcasting</span></Link>{" "}</li> */}

                  {this.state.newnotification ? (
                    <li>
                      <a
                        onClick={this.seenNotification}
                        to="#"
                        id="notification"
                      >
                        <NotificationNew />
                        <span>Notification</span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a
                        onClick={this.getNotification}
                        to="#"
                        id="notification"
                      >
                        <Notification />
                        <span>Notification</span>
                      </a>
                    </li>
                  )}
                  <li>
                    <a onClick={this.logout}>
                      <Logout />
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              ) : (
                <div>
                  <ul>
                    <li>
                      <div className="Sidebar_dropdown selectBox">
                        <div className="CustomDrop">
                          <button className="" type="button">
                            <img
                              src={localStorage.getItem("channelimage")}
                              alt="Image"
                            />
                            <span>
                              {localStorage.getItem("channeltype") == "whatsapp"
                                ? localStorage.getItem("channelnumber")
                                : localStorage.getItem("channelnumber")}
                            </span>
                            <span
                              uk-icon="chevron-down"
                              className="chevron-down"
                            ></span>
                          </button>
                          <div uk-dropdown="mode: click" className="">
                            <ul className="uk-nav uk-dropdown-nav">
                              {this.state.dataList.isLoaded ? (
                                this.handleNumbers()
                              ) : (
                                <li>Loading...</li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* {(localStorage.getItem('loginStatus') == 1 && this.state.roleScreen[0] == true )?<li><Link to="/dashboard"><Home /><span>Home</span></Link></li>:""} */}
                    {/* {(localStorage.getItem("loginStatus") == 1 && this.state.roleScreen [0] == true) ? (<li><Link to="/stats"><Analytics/><span>Stats</span></Link></li>) : ("")} */}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[0] == true ? (
                      <li>
                        <Link to="/dashboard">
                          <Home />
                          <span>Home</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[1] == true ? (
                      <li>
                        <Link to="/chat">
                          <Chat />
                          <span>Inbox</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[2] == true ? (
                      <li>
                        <Link to="/automation">
                          <WatsonHealth3DCurveAutoColon />
                          <span>Automation</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[3] == true ? (
                      <li>
                        <Link to="/audience">
                          <Events />
                          <span>Audience</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[4] == true ? (
                      <li>
                        <Link to="/broadcasting">
                          <Rocket />
                          <span>Broadcasting</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[5] == true ? (
                      <li>
                        <Link to="/teams">
                          <UserMultiple />
                          <span>Team</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[5] == true &&
                    localStorage.getItem("company_id") == 16 ? (
                      <li>
                        <Link to="/query">
                          <DocumentImport />
                          <span>Queries</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* {(localStorage.getItem('loginStatus') == 1 && this.state.roleScreen[5] == true && localStorage.getItem("company_id") == 10)?<li><Link to="/stats_report"><ReportData /><span>Stats Report-</span></Link></li>:""} */}

                    {/* {(localStorage.getItem('loginStatus') == 1 && this.state.roleScreen[7] == true )?<li><Link to="/reports"><Analytics /><span>Insights</span></Link></li>:""} */}
                    {/* {(localStorage.getItem('loginStatus') == 1 && this.state.roleScreen[7] == true )?<li><Link to="/insights"><Analytics /><span>Insights</span></Link></li>:""} */}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[8] == true ? (
                      <li>
                        <Link to="/integrations">
                          <TaskTools />
                          <span>Tools</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[9] == true ? (
                      <li>
                        <Link to="/admin">
                          <Credentials />
                          <span>Admin</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[9] == true &&
                    localStorage.getItem("company_id") == 8 ? (
                      <li>
                        {/* <Link to="/reports"><Credentials /><span>Reports</span></Link> */}
                        <div className="menuDropDown">
                          <ul className="uk-nav-default" uk-nav="">
                            <li className="uk-parent">
                              <a href="#">
                                <ReportData />
                                <span>Reports</span>
                                <span
                                  className="dropIconMenu"
                                  uk-nav-parent-icon=""
                                ></span>
                              </a>
                              <ul className="uk-nav-sub">
                                <li>
                                  <Link to="/customer_satisfaction_report">
                                    <span>Customer Not Satisfied</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/other_cities_report">
                                    <span>Other Cities</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/business_queries_report">
                                    <span>Business Queries</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/event_coverage_report">
                                    <span>Event Coverage Queries</span>
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                    ) : (
                      ""
                    )}

                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[0] == true ? (
                      <li>
                        <Link to="/stats-report">
                          <ReportData />
                          <span>Stats Report</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                  {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[0] == true ? (
                      <li>
                        <Link to="/report">
                          <ReportData />
                          <span>Report</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {localStorage.getItem("company_id") == 24 ? (
                      <li>
                        <Link to="/campagin-report">
                        <ReportData />
                        <span>Campagin Report</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[11] == true ? (
                      <li>
                        <Link to="/role">
                          <UserMultiple />
                          <span>Role Management</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    <li>
                      <a onClick={this.logout}>
                        <Logout />
                        <span>Logout</span>
                      </a>
                    </li>
                  </ul>
                  <ul className="sidebarUlFixed">
                    {this.state.newnotification ? (
                      <li>
                        <a
                          onClick={this.seenNotification}
                          to="#"
                          id="notification"
                        >
                          <NotificationNew />
                          <span>Notification</span>
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a
                          onClick={this.getNotification}
                          to="#"
                          id="notification"
                        >
                          <Notification />
                          <span>Notification</span>
                        </a>
                      </li>
                    )}
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[10] == true ? (
                      <li>
                        <Link to="/settings">
                          <Settings />
                          <span>Settings</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    <li></li>
                    {localStorage.getItem("loginStatus") == 1 &&
                    this.state.roleScreen[6] == true ? (
                      <li>
                        <Link to="/support">
                          <Help />
                          <span>Support</span>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              )}
            </div>
          </aside>
        </div>
        {/* <SidebarNotifications /> */}

        <div
          id="SidebarNotification"
          className="SidebarNotification"
          uk-offcanvas="overlay: true"
        >
          <div className="uk-offcanvas-bar">
            <button className="uk-offcanvas-close" type="button" uk-close="" />
            <h3>Notifications</h3>
            <h6>High Priority</h6>
            <ul>
              {this.state.datanotificationList.isLoaded ? (
                this.handleTable()
              ) : this.state.datanotificationList.msg == "not" ? (
                <li>
                  <div className="uk-text-center uk-height-large">
                    Data not Found
                  </div>
                </li>
              ) : (
                <li>
                  <div className="uk-text-center uk-height-large">
                    Data not Foundd
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        {/* Notification Permission */}
        <div className="notify-alert-box" id="notifyPermission">
          <img src="/images/logosidebar.svg" />
          <p>We'd like to send you notifications of the latest live chat.</p>
          <div className="buttons">
            <button id="notify-cancel-button">Cancel</button>
            <button id="notify-button">Allow</button>
          </div>
        </div>
        {/* End Notification Permission */}
      </div>
    );
  }
}
