import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content,TextField,Checkbox,Button} from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from 'axios';
import $ from 'jquery';
import {
  AddAlt,
  Close
} from "@carbon/icons-react";
class Notification extends React.Component {


    constructor() {
        super();
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleIndustryChange = this.handleIndustryChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleWhatsappChange = this.handleWhatsappChange.bind(this);
        this.state = {
            company_name: "",
            industry: "",
            email: "",
            code: "",
            whatsapp_number: "",
            company_id:"",
            notification : ""
        }
    }



        handleNameChange(event) {
        this.setState({ company_name: event.target.company_name });
        }
        
        handleIndustryChange(event) {
        this.setState({ industry: event.target.industry });
        }
        
        handleEmailChange(event) {
        this.setState({ email: event.target.email });
        }
        
        handleCodeChange(event) {
        this.setState({ code: event.target.code });
        }
        
        handleWhatsappChange(event) {
        this.setState({ whatsapp_number: event.target.whatsapp_number });
        
        }



    addconsent = (id) =>{
        // console.log(id,"sajdain");
        $("#AddResolved").addClass('uk-flex uk-open');
        $( "#addconsent" ).val(id)
    
      }


      deleteCompany = () => {

        var id = $("#addconsent").val()
        let classRef = this;
        var company_id = id;
        // var company_id = localStorage.getItem('company_id');
        // var is_deleted = 1;
    
              axios({
                method: 'POST',
                url: Constant.url + "account-deletess",
                data: { company_id: company_id}
            }).then(function (result) {
            
                if (result.data.code === 200) {
            
                    $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
        
                    localStorage.clear();
                    window.location.href = "/";
            
                }else{
            
                    $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
            
                    setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
        
                    window.location.href = "/agents";      
            
                }
                   
            })
      
      }
    


    componentDidMount() {
        this.callApi();
        this.checkNotification();
      }

      checkNotification = () =>{
        localStorage.getItem('chat-msg-notification') =='true' ? $("#chkTone").prop("checked", true) : $("#chkTone").prop("checked", false)
      }

      callApi = () => {
        let classRef = this;
        axios({
          method: 'POST',
          url: Constant.url + "account-overview",
          data: { company_id: localStorage.getItem('company_id') }
        }).then(function (result) {
          if (result.data.status === true) {
            var company = result.data.data
            classRef.setState({
                company_name: company.company_name,
                industry: company.industry,
                email: company.email,
                code: company.code,
                whatsapp_number: company.whatsapp_number,
                company_id:company.id,
              })
            
          }
        })
      }

      closeConsent = () =>{
        $("#AddResolved").removeClass('uk-flex uk-open');
      }


      handleToggleChange(event) {
        // <input> will have target.checked == true
        // if (event.target.checked == null) return null;\
        if($("#chkTone").prop('checked')){
          localStorage.setItem('chat-msg-notification', false);   
        } else {
          localStorage.setItem('chat-msg-notification', true);   
        }
      }

      editCompany = (id) => {    

        $('#editcompany').text('Processing')
        $('#editcompany').prop('disabled', true);  
        let classRef = this;
        var company_id = id;

        var data = {};
        data = {
          company_name: $("#Companyname").val(), 
          industry: $("#Companyindustry").val(),
          company_id:company_id
        }
    
        axios({
          method: 'POST',
          url: Constant.url + "account-edit",
          data: data
        }).then(function (response) {
    
          if (response.data.code === 200) {
    
            $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
    
            window.location.href = "/admin";
          }else{
    
            $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
            $('#editcompany').text('Update')
            $('#editcompany').prop('disabled', false);
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
            return;
        
    
          }
        }).then(function (response) {
    
        });
    
    
      };

   

  render() {
    return (
      <Content>
        <div
          className="contactContainer adminContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
                <div className=" uk-grid " uk-grid="">
                    <div className="uk-width-auto"><h3>Notifications</h3></div>
                </div>
            </div>
          <div className=" CustomBgAdmin NotificationView">
            <div className="integration inner fullWdth">

                <h3><b>Notifications Settings</b></h3>
                <div>
                  <label class="switch"> 
                      {/* { localStorage.getItem('chat-msg-notification') == "true" ? checked : "" } */}

                      <input type="checkbox" class="abc" id="chkTone"   />
                      <span class="slider round" onClick={this.handleToggleChange}></span>
                  </label>
                  <label className="CustomNoti">Notification tone</label>
                </div>
                    
            </div>
          </div>
        </div>


        
      </Content>
    );
  }
}
export default Notification;
