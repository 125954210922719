import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import FacebookWorkspace from "../Workspace/FacebookWorkspace";
import WhatsappWorkspace from "../Workspace/WhatsappWorkspace";
import EmailWorkspace from "../Workspace/EmailWorkspace";
import {
  Time,
  InProgress,
  Hourglass,
  CheckmarkOutline,
  ChatBot,
  UserAdmin,
  Error,
  ChevronDown,
  Chat,
  ReplyAll,
  Events,
  Information,
  User,
  UserMultiple
} from "@carbon/icons-react";
import $ from "jquery";
import axios from "axios";
import Constant from "../../../App/Constant";

export class Workspace extends React.Component {

  constructor(){
    super();
  }


  componentDidMount() {

  }

  render() {
    return (
      <Content>



    {(localStorage.getItem('channeltype') =="facebook")?<FacebookWorkspace/>:(localStorage.getItem('channeltype') =="email")?<EmailWorkspace/>:(localStorage.getItem('channeltype') =="whatsapp")?<WhatsappWorkspace/>:""}

   </Content>
    );
  }
}
