import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import { DaySolvedTickets } from "../../Sections/DaySolvedTickets";
import { Bar } from "react-chartjs-2";

import $ from "jquery";
import {
  OrderDetails,
  UserOnline,
  Email,
  MobileCheck,
  CheckmarkOutline,
  InProgress,
  Hourglass,
  Time,
  ChatBot,
  UserAdmin,
  GenderFemale,
} from "@carbon/icons-react";
class EditAgent extends React.Component {
  render() {
    return (
      <Content>
        <div
          className="contactContainer adminContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>
                  Agents &nbsp; &gt; <span> &nbsp; Yumna Zaidi </span>
                </h3>
              </div>
            </div>
          </div>

          <div className="EditAgentMain">
            <div className="uk-grid" uk-grid>
              <div className="uk-width-1-3">
                <div className="EditAgentHead">
                  <p>Agent Details</p>
                </div>

                <div className="EditAgentInfo">
                  <div
                    id="EditAgentProfile"
                    className="AddTeamModal ModalStyle uk-flex-top"
                  >
                    <div className="ProfileLeft">
                      <div className="Agent_profile_pic">
                        <img src="/images/" alt="" id="profile_pic" />
                      </div>
                      <h3>
                        <b>Yumna Zaidi</b>
                      </h3>
                      <div className="contact_details contact_details_Content">
                        <ul>
                          <li>
                            <button>
                              <span>
                                <MobileCheck />
                              </span>{" "}
                              <span id="mobile_no">+923042682812</span>
                            </button>
                          </li>
                          <li>
                            <button>
                              {" "}
                              <span>
                                <Email />
                              </span>{" "}
                              <span>yumna.zaidi@convexinteractive.com</span>
                            </button>
                          </li>
                          <li>
                            {" "}
                            <span>
                              <OrderDetails />
                            </span>{" "}
                            <span id="Livecaht">Live Chat Agent</span>
                          </li>
                          <li>
                            {" "}
                            <span>
                              <UserOnline />
                            </span>{" "}
                            <span id="online">Online</span>
                          </li>
                        </ul>
                        <p>Last Login at 17:44 (UTC+05)</p>
                        <p>Joined on 5 Jan 2022</p>
                        {/* <button class="newFlowBtn"><span>Start Chat</span></button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-3-5">
                <div className="EditAgentHead">
                  <p>Agent Performance</p>
                </div>
                <div className="EditAgentInfo">
                  <div class="stats_container">
                    <div className="uk-grid-small" uk-grid="">
                      <div class="uk-width-1-3@m">
                        <div class="box active">
                          <div class="text">
                            <div class="card_txt">
                              <h6>0 to 10 mins</h6>
                              <span>Avg. First Reply Time</span>
                            </div>
                          </div>
                          <div class="icon">
                            <CheckmarkOutline />
                          </div>
                        </div>
                      </div>
                      <div class="uk-width-1-3@m">
                        <div class="box">
                          <div class="text">
                            <div class="card_txt">
                              <h6> &gt; 20 min</h6>
                              <span>Avg. Response Time</span>
                            </div>
                          </div>
                          <div class="icon">
                            <InProgress />
                          </div>
                        </div>
                      </div>

                      <div class="uk-width-1-3@m">
                        <div class="box">
                          <div class="text">
                            <div class="card_txt">
                              <h6> &gt; 10min</h6>
                              <span>Avg. Resolution Time</span>
                            </div>
                          </div>
                          <div class="icon">
                            <Time />
                          </div>
                        </div>
                      </div>

                      <div class="uk-width-1-3@m">
                        <div class="box">
                          <div class="text">
                            <div class="card_txt">
                              <h6> &gt; 10min</h6>
                              <span>Avg Time Spent</span>
                            </div>
                          </div>
                          <div class="icon">
                            <Hourglass />
                          </div>
                        </div>
                      </div>

                      <div class="uk-width-1-3@m">
                        <div class="box">
                          <div class="text">
                            <div class="card_txt">
                              <h6>12,361</h6>
                              <span>No of Responses</span>
                            </div>
                          </div>
                          <div class="icon">
                            <UserAdmin />
                          </div>
                        </div>
                      </div>

                      <div class="uk-width-1-3@m">
                        <div class="box">
                          <div class="text">
                            <div class="card_txt">
                              <h6>12,361</h6>
                              <span>No of Resolved Chats</span>
                            </div>
                          </div>
                          <div class="icon">
                            <ChatBot />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="monthGraph">
                      <div>
                        {/* <div className="head">
                                        <h5>Total Messages Per Day Avg.</h5>
                                    </div> */}
                        <div className="uk-card uk-card-default uk-padding-small">
                          {/* <BarChart /> */}
                          <DaySolvedTickets />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default EditAgent;
