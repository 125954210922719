import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../../App/Constant";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  let dummyData = {

       labels: [] ,
      datasets: [
        {
          label: 'Total Chat',
          data: [],
          borderColor: 'rgb(255, 100, 140)',
          backgroundColor: 'rgb(255, 100, 140)',
        },
        {
          label: 'Sent Chat',
          data: [],
          borderColor: 'rgb(253, 186,92)',
          backgroundColor: 'rgb(253, 186,92)',
        },
        {
          label: 'Recieved Chat',
          data: [],
          borderColor: 'rgb(24, 170, 248)',
          backgroundColor: 'rgb(24, 170, 248)',
        }
      ],


  }


  export const BotMonthMessages = (stateCompany,stateTo,stateFrom) => {

    const [graphData, setGraphData] = useState(dummyData);

    useEffect(() => {

      formValidation();

  }, [stateCompany,stateTo,stateFrom]);

  const formValidation = async () => {

    axios({
        method: 'POST',
        url: Constant.url+"admin-bot-month-wise-chat-messages",
        data: {company_id:stateCompany.stateCompany,stateTo:stateCompany.stateTo,stateFrom:stateCompany.stateFrom} 
    }).then(result =>{

      var stats = result.data.data;
      setGraphData({

      labels: stats.month_bot,
      datasets: [
        {
          label: 'Total Chat',
          data: stats.total_chat_bot,
          borderColor: 'rgb(220, 220, 220)',
          backgroundColor: 'rgb(220, 220, 220)',
        },
        {
          label: 'Sent Chat',
          data: stats.sent_chat_bot,
          borderColor: 'rgb(184, 203,252)',
          backgroundColor: 'rgb(184, 203,252)',
        },{
          label: 'Recieved Chat',
          data: stats.recieved_chat_bot,
          borderColor: 'rgb(75, 103, 238)',
          backgroundColor: 'rgb(75, 103, 238)',
        }

      ],

      })

      
    }).catch(err => {

      // console.log(err)
            
    });


}

    
   const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom'
        },
        title: {
          display: false,
          // text: 'Chart.js Horizontal Bar Chart',
        },
      },
    };

    return <Bar options={options} data={graphData} />;


  }







