import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { ContextualHelp } from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import PageActionBar from "../../Sections/PageActionBar";
import {
  CaretDown,
  Information,
  Add,
  Edit,
  TrashCan,
  Download,
  OverflowMenuVertical,
  Close,
} from "@carbon/icons-react";
class Tags extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.callTemplateApi = this.callTemplateApi.bind(this);
    this.handleTemplateTable = this.handleTemplateTable.bind(this);
    this.handleTempDescriptionChange =
      this.handleTempDescriptionChange.bind(this);
    this.handleTempNameChange = this.handleTempNameChange.bind(this);
    this.handlekeywordsChange = this.handlekeywordsChange.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      TemplateList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      tag_name: "",
      description: "",
      tag_id: "",
      template_name: "",
      temp_description: "",
      keywords: "",
      temp_keywords: "",
      template_id: "",
    };
  }

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  closeConsentss = () => {
    $("#AddConsent").removeClass("uk-flex uk-open");
  };

  edittingTemplate = () => {
    // console.log("Javevevevev");

    $("#edittemplate").text("Processing");
    $("#edittemplate").prop("disabled", true);
    let classRef = this;
    var template_id = $("#template_id").val();

    var data = {};
    data = {
      name: $("#new_temp").val(),
      description: $("#new_description").val(),
      keywords: $("#keywordsEdit").val(),
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-chat-template/" + template_id,
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/tags";
        } else {
          $("#editerrorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#edittemplate").text("Edit Template");
          $("#edittemplate").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  callTemplateApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "chat-templates",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          TemplateList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  addTemplate = () => {
    $("#addtemplate").text("Processing");
    $("#addtemplate").prop("disabled", true);
    let classRef = this;
    var data = {};
    data = {
      name: $("#tempname").val(),
      description: $("#tempdescription").val(),
      keywords: $("#keywords").val(),
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "add-chat-template",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/tags";

          $("#addtemplate").text("Create Template");
          $("#addtemplate").prop("disabled", false);
          $("#tagtemp").addClass("uk-active");
          $("#tagtab").removeClass("uk-active");
          $("#tagtemplist").addClass("uk-active");
          $("#tagtablist").removeClass("uk-active");
          $("#tagtemp > a").attr("aria-expanded", "true");
          $("#tagtab > a").attr("aria-expanded", "false");
          $("#tagtemplistbutton").addClass("uk-active");
          $("#tagtablistbutton").removeClass("uk-active");
          classRef.callApi();
          classRef.callTemplateApi();
          $("#NewBot").removeClass("uk-flex uk-open");
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#addtemplate").text("Create Template");
          $("#addtemplate").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  EditTemplateModal = (id) => {
    $("#EditNewBot").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findtemplate(id);
  };

  closeEditNewBot = (id) => {
    $("#EditNewBot").removeClass("uk-flex uk-open");
  };

  findtemplate = (id) => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let template_id = id;
    axios({
      method: "POST",
      url: Constant.url + "single-chat-templates",
      data: { company_id: company_id, template_id: template_id },
    }).then(function (result) {
      if (result.data.status === true) {
        // console.log(result.data);
        var template = result.data.data;
        classRef.setState({
          template_name: template.name,
          temp_description: template.description,
          temp_keywords: template.keywords,
          template_id: template.id,
        });
      }
    });
  };

  handleTemplateTable = () => {
    let result = "";
    let index = 0;
    result = this.state.TemplateList.list.map((response, index) => (
      <tr id={"temp_" + response.id}>
        <td>
          <input
            className="uk-checkbox autoSelects"
            name="multi_check[]"
            value={response.id}
            type="checkbox"
          />
        </td>
        <td>
          <p>
            <b>{response.name}</b>
          </p>
        </td>
        <td>
          <p>
            <b>{response.description}</b>
          </p>
        </td>
        <td>
          <p>
            <b>{response.keywords}</b>
          </p>
        </td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit"  onClick={(e) => this.EditTemplateModal(response.id)}><Edit /></button></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addconsents(response.id)}><TrashCan /></button></td>
      </tr>
    ));
    return result;
  };

  componentDidMount() {
    this.callApi();
    this.callTemplateApi();

    // if(localStorage.getItem('role')=='3'){
    //     window.location.href = "/";
    //    }
  }

  addconsent = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  addconsents = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddConsent").addClass("uk-flex uk-open");
    $("#addconsents").val(id);
  };

  editTag = () => {
    $("#edittags").text("Processing");
    $("#edittags").prop("disabled", true);

    let classRef = this;

    var data = {};
    data = {
      name: $("#edittagName").val(),
      description: $("#editdescription").val(),
      tag_id: $("#tag_id").val(),
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "tag-edit",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/tags";
        } else {
          $("#editerrorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#edittags").text("Edit Tag");
          $("#edittags").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  handleNameChange(event) {
    this.setState({ tag_name: event.target.tag_name });
    // console.log("first name updated");
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.description });
  }

  handleTempNameChange(event) {
    this.setState({ template_name: event.target.template_name });
    // console.log("first name updated");
  }

  handleTempDescriptionChange(event) {
    this.setState({ temp_description: event.target.temp_description });
  }

  handlekeywordsChange(event) {
    this.setState({ temp_keywords: event.target.temp_keywords });
  }

  deletetag = () => {
    var id = $("#addconsent").val();
    let classRef = this;
    var tag_id = id;
    var company_id = localStorage.getItem("company_id");
    var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "tag-edit",
      data: { company_id: company_id, tag_id: tag_id, is_deleted: is_deleted },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/tags";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/tags";
      }
    });
  };

  deleteTemplate = () => {
    var id = $("#addconsents").val();
    let classRef = this;
    var template_id = id;
    var company_id = localStorage.getItem("company_id");
    var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "delete-chat-template",
      data: {
        company_id: company_id,
        template_ids: template_id,
        is_deleted: is_deleted,
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/tags";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/tags";
      }
    });
  };

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "tags",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  EditTagModal = (id) => {
    $("#EditTagModal").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findtag(id);
  };

  download = (id) => {
    let classRef = this;
    var tag_id = id;
    var company_id = localStorage.getItem("company_id");
    window.open(
      Constant.url +
        "download-tag?company_id=" +
        company_id +
        "&tag_id=" +
        tag_id,
      "_blank"
    );
  };

  findtag = (id) => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let tag_id = id;
    axios({
      method: "POST",
      url: Constant.url + "single-tag",
      data: { company_id: company_id, tag_id: tag_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var tag = result.data.data;

        classRef.setState({
          tag_name: tag.name,
          description: tag.description,
          tag_id: tag.id,
        });
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr id={"mtag_" + response.id}>
        <td>
          <input
            className="uk-checkbox autoSelect"
            name="multi_check[]"
            value={response.id}
            type="checkbox"
          />
          {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
        </td>
        <td>
          <p>
            <b>{response.name}</b>
          </p>
          {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
        </td>
        <td>
          <p>
            <b>{response.name}</b>
          </p>
          {/* <p>1234</p> */}
        </td>
        <td>
          <p>
            <b>{response.description}</b>
          </p>
          {/* <p>1234</p> */}
        </td>
        <td><button className="iconcircleBtn success" uk-tooltip="title: Download Data" onClick={(e) => this.download(response.id)}><Download /></button></td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.EditTagModal(response.id)}><Edit /></button></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addconsent(response.id)}><TrashCan /></button></td>
      </tr>
    ));
    return result;
  };

  addTeamModal = () => {
    $("#AddTagModal").addClass("uk-flex uk-open");
  };

  closeAgentModal = () => {
    $("#AddTagModal").removeClass("uk-flex uk-open");
  };

  addUserField = () => {
    $("#UserField").addClass("uk-flex uk-open");
  };

  closeUserField = () => {
    $("#UserField").removeClass("uk-flex uk-open");
  };

  addNewTemplate = () => {
    $("#NewBot").addClass("uk-flex uk-open");
  };

  closeNewBot = () => {
    $("#NewBot").removeClass("uk-flex uk-open");
  };

  createTag = () => {
    $("#addtag").text("Processing");
    $("#addtag").prop("disabled", true);
    let classRef = this;

    var data = {};
    data = {
      tag_name: $("#newtagName").val(),
      description: $("#description").val(),
      company_id: localStorage.getItem("company_id"),
    };
    axios({
      method: "POST",
      url: Constant.url + "add-tag",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.href = "/tags";
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#addteam").text("Add Team");
          $("#addteam").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    // console.log(searchword);

    axios({
      method: "POST",
      url: Constant.url + "tags",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  searchTemplaterecord = () => {
    let classRef = this;
    var searchword = $("#searctemplatehword").val();
    // console.log(searchword);

    axios({
      method: "POST",
      url: Constant.url + "chat-templates",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          TemplateList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          TemplateList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  deleteMultiTag = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#mtag_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "bulk-delete-tags",
      data: {
        company_id: localStorage.getItem("company_id"),
        tag_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        window.location.reload();
      }
    });
  };

  selectCheckboxTag = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  deleteMultiTemp = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#temp_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "delete-chat-template",
      data: {
        company_id: localStorage.getItem("company_id"),
        template_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        window.location.reload();
      }
    });
  };

  selectCheckboxTemp = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_alls").prop("checked", true);
        $(".autoSelects").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_alls").prop("checked", false);
        $(".autoSelects").prop("checked", false);
      });
    }
  };

  render() {
    return (
      <Content>
        <div
          className="AutomationContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          {/* Automation Tab Start */}
          <div className="TabsHeadBox">
            <ul className="tabsBtns desktop" uk-switcher="connect: .multi_div">
              {/* <li><a href="#">User Fields</a></li> */}
              {/* <li><a href="#">Bot Fields</a></li> */}

              <li id="tagtab">
                <a href="#">Tags</a>
              </li>
              <li id="tagtemp">
                <a href="#">Quick Replies</a>
              </li>

              {/* <li><a href="#">Rules</a></li> */}
            </ul>
            <div class="mobTabsBtn">
              <p class="AnimFade uk-scrollspy-inview uk-animation-fade">Tags</p>
              <ul className="tabsBtns" uk-switcher="connect: .multi_div">
                <li id="tagtab">
                  <a href="#">Tags</a>
                </li>
                <li id="tagtemp">
                  <a href="#">Quick Replies</a>
                </li>
              </ul>
            </div>
            <ul className="uk-switcher multi_div" id="tabsBtns">
              <li id="tagtablistbutton">
                <div className="searchnFlowBtn mobile">
                  <form className="uk-search" action="javascript:void(0)">
                    <input
                      className="uk-search-input"
                      type="search"
                      onChange={this.searchrecord}
                      name="searchword"
                      id="searchword"
                      placeholder="Search through tags name"
                    />
                  </form>
                  <button className="newFlowBtn" onClick={this.addTeamModal}>
                    <Add /> <span>New Tag</span>
                  </button>
                </div>
              </li>

              <li id="tagtemplistbutton">
                <div className="searchnFlowBtn mobile">
                  <form className="uk-search" action="javascript:void(0)">
                    <input
                      className="uk-search-input"
                      type="search"
                      onChange={this.searchTemplaterecord}
                      name="searctemplatehword"
                      id="searctemplatehword"
                      placeholder="Search through name"
                    />
                  </form>
                  <button className="newFlowBtn" onClick={this.addNewTemplate}>
                    <Add /> <span>New Chat Template</span>
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <ul className="uk-switcher TabSec multi_div">
            <li id="tagtablist">
              <div className="tableSec">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-divider">
                    <thead>
                      <tr>
                        <th>
                          <label>
                            <input
                              className="uk-checkbox"
                              type="checkbox"
                              id="select_all"
                              onClick={this.selectCheckboxTag}
                            />
                          </label>
                          <div className="uk-inline drpIconBox">
                            <button className="iconTble" type="button">
                              <CaretDown />
                            </button>
                            <div uk-dropdown="mode: click">
                              <ul className="uk-nav uk-dropdown-nav">
                                <li>
                                  <a onClick={this.deleteMultiTag}>
                                    Delete Selected Rows
                                  </a>
                                </li>
                                {/* <li><a href="#">All</a></li>
                                                                <li><a href="#">Recently Modified</a></li>
                                                                <li><a href="#">Favourite</a></li> */}
                              </ul>
                            </div>
                          </div>
                        </th>
                        <th>NAME</th>
                        <th>TYPE </th>
                        <th>DESCRIPTION</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {(this.state.dataList.isLoaded) ? this.handleTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>}  */}
                      {this.state.dataList.isLoaded ? (
                        this.handleTable()
                      ) : this.state.dataList.msg == "not" ? (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              Data not Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              <div className="mdl">
                                <div className="mdl_inner">
                                  <div uk-spinner=""></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li id="tagtemplist">
              <div className="tableSec">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-divider">
                    <thead>
                      <tr>
                        <th>
                          <label>
                            {/* <input className="uk-checkbox" type="checkbox" /> */}
                            <input
                              className="uk-checkbox"
                              type="checkbox"
                              id="select_alls"
                              onClick={this.selectCheckboxTemp}
                            />
                          </label>

                          <div className="uk-inline drpIconBox">
                            <button className="iconTble" type="button">
                              <CaretDown />
                            </button>
                            <div uk-dropdown="mode: click">
                              <ul className="uk-nav uk-dropdown-nav">
                                <li>
                                  <a onClick={this.deleteMultiTemp}>
                                    Delete Selected Rows
                                  </a>
                                </li>
                                {/* <li><a href="#">All</a></li>
                                                                <li><a href="#">Recently Modified</a></li>
                                                                <li><a href="#">Favourite</a></li> */}
                              </ul>
                            </div>
                          </div>
                        </th>
                        <th>NAME</th>
                        <th>DESCRIPTION</th>
                        <th>KEYWORDS</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {(this.state.TemplateList.isLoaded) ? this.handleTemplateTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>} */}
                      {this.state.TemplateList.isLoaded ? (
                        this.handleTemplateTable()
                      ) : this.state.TemplateList.msg == "not" ? (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              Data not Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              <div className="mdl">
                                <div className="mdl_inner">
                                  <div uk-spinner=""></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
            <li>
              <div className="tableSec">
                <table className="uk-table uk-table-small uk-table-divider">
                  <thead>
                    <tr>
                      <th>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        <div className="uk-inline drpIconBox">
                          <button className="iconTble" type="button">
                            <CaretDown />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">All</a>
                              </li>
                              <li>
                                <a href="#">Recently Modified</a>
                              </li>
                              <li>
                                <a href="#">Favourite</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </th>
                      <th>NAME</th>
                      <th>DESCRIPTION</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>preferred_phone</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person's preferred Email Address</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>preferred_email</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person's preferred Phone Number</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>user_unsatisfied_customer</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person's Feedback</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>user_guest</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person is a Guest</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>user_registered</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person is a Registered Customer</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>user_new_customer</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person is a New Customer</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>user_returning_customer</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person is a Returning Customer</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>user_loyal</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person is a Loyal Customer</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>opt-in-for-sms</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person is opted in for sms</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>opt-in-for-email</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person is opted in for email</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>
                          <input className="uk-checkbox" type="checkbox" />
                        </label>
                        {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
                      </td>
                      <td>
                        <p>
                          <b>loc_karachi</b>
                        </p>
                        {/* <div className="whatsAppTxt"><img src="/images/whatsappicon.png" alt="" />Message is start, subscribe</div> */}
                      </td>
                      <td>
                        <p>
                          <b>Person is from Karachi</b>
                        </p>
                        {/* <p>1234</p> */}
                      </td>
                      <td>
                        <div className="uk-inline">
                          <button className="iconTble" type="button">
                            <OverflowMenuVertical />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li>
                                <a href="#">Option 1</a>
                              </li>
                              <li>
                                <a href="#">Option 2</a>
                              </li>
                              <li>
                                <a href="#">Option 3</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <div className="TabFourBroadCast">
                <div className="uk-grid uk-grid-small" uk-grid="">
                  <div className="uk-width-1-1">
                    <h4>Popular integrating</h4>
                  </div>
                  <div className="uk-width-1-4">
                    <div className="customCard">
                      <div className="uk-card uk-card-default">
                        <div className="uk-card-header">
                          <div className="uk-flex-middle">
                            <div className="uk-width-auto">
                              <img className="" src="images/b1.svg" />
                            </div>
                            <div className="uk-width-expand">
                              <h4 className="">
                                <b>Customer Service</b>
                              </h4>
                              <p className="uk-text-meta uk-margin-remove-top">
                                Automate repetitive queries and give your
                                customers instant answers 24/7/365.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-body">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                </div> */}
                        <div className="uk-card-footer">
                          <a href="#" className="uk-button uk-button-text">
                            Import
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-width-1-4">
                    <div className="customCard">
                      <div className="uk-card uk-card-default">
                        <div className="uk-card-header">
                          <div className="uk-flex-middle">
                            <div className="uk-width-auto">
                              <img className="" src="images/b1.svg" />
                            </div>
                            <div className="uk-width-expand">
                              <h4 className="">
                                <b>Customer Satisfaction</b>
                              </h4>
                              <p className="uk-text-meta uk-margin-remove-top">
                                Automate collecting surveys to capture the voice
                                and opinions of your customers.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-body">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                </div> */}
                        <div className="uk-card-footer">
                          <a href="#" className="uk-button uk-button-text">
                            Import
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-width-1-4">
                    <div className="customCard">
                      <div className="uk-card uk-card-default">
                        <div className="uk-card-header">
                          <div className="uk-flex-middle">
                            <div className="uk-width-auto">
                              <img className="" src="images/b1.svg" />
                            </div>
                            <div className="uk-width-expand">
                              <h4 className="">
                                <b>E-commerce Support</b>
                              </h4>
                              <p className="uk-text-meta uk-margin-remove-top">
                                Improve customer experience to grow your sales.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-body">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                </div> */}
                        <div className="uk-card-footer">
                          <a href="#" className="uk-button uk-button-text">
                            Import
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-width-1-4">
                    <div className="customCard">
                      <div className="uk-card uk-card-default">
                        <div className="uk-card-header">
                          <div className="uk-flex-middle">
                            <div className="uk-width-auto">
                              <img className="" src="images/b1.svg" />
                            </div>
                            <div className="uk-width-expand">
                              <h4 className="">
                                <b>Live Chat Essentials</b>
                              </h4>
                              <p className="uk-text-meta uk-margin-remove-top">
                                Transfer users to human agents and create
                                tickets in the chat window.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-body">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                </div> */}
                        <div className="uk-card-footer">
                          <a href="#" className="uk-button uk-button-text">
                            Import
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-width-1-4">
                    <div className="customCard">
                      <div className="uk-card uk-card-default">
                        <div className="uk-card-header">
                          <div className="uk-flex-middle">
                            <div className="uk-width-auto">
                              <img className="" src="images/b1.svg" />
                            </div>
                            <div className="uk-width-expand">
                              <h4 className="">
                                <b>Call Scheduling</b>
                              </h4>
                              <p className="uk-text-meta uk-margin-remove-top">
                                Let customers call you at the click of a button
                                or schedule a call for later.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-body">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                </div> */}
                        <div className="uk-card-footer">
                          <a href="#" className="uk-button uk-button-text">
                            Import
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-width-1-4">
                    <div className="customCard">
                      <div className="uk-card uk-card-default">
                        <div className="uk-card-header">
                          <div className="uk-flex-middle">
                            <div className="uk-width-auto">
                              <img className="" src="images/b1.svg" />
                            </div>
                            <div className="uk-width-expand">
                              <h4 className="">
                                <b>Discount</b>
                              </h4>
                              <p className="uk-text-meta uk-margin-remove-top">
                                Engage browsing visitors and automate lead
                                generation.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-body">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                </div> */}
                        <div className="uk-card-footer">
                          <a href="#" className="uk-button uk-button-text">
                            Import
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-width-1-4">
                    <div className="customCard">
                      <div className="uk-card uk-card-default">
                        <div className="uk-card-header">
                          <div className="uk-flex-middle">
                            <div className="uk-width-auto">
                              <img className="" src="images/b1.svg" />
                            </div>
                            <div className="uk-width-expand">
                              <h4 className="">
                                <b>Lead Generation</b>
                              </h4>
                              <p className="uk-text-meta uk-margin-remove-top">
                                Engage browsing visitors and automate lead
                                generation.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-body">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                </div> */}
                        <div className="uk-card-footer">
                          <a href="#" className="uk-button uk-button-text">
                            Import
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-width-1-4">
                    <div className="customCard">
                      <div className="uk-card uk-card-default">
                        <div className="uk-card-header">
                          <div className="uk-flex-middle">
                            <div className="uk-width-auto">
                              <img className="" src="images/b1.svg" />
                            </div>
                            <div className="uk-width-expand">
                              <h4 className="">
                                <b>Package Tracking</b>
                              </h4>
                              <p className="uk-text-meta uk-margin-remove-top">
                                Let customers check the delivery status in the
                                chat window
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="uk-card-body">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                </div> */}
                        <div className="uk-card-footer">
                          <a href="#" className="uk-button uk-button-text">
                            Import
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        {/* popup user field start */}

        <div
          id="UserField"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Create New User Field</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Field Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Field Name</b>
                      </h4>
                      <p>How this User Field appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="tagName"
                    name="tagName"
                    type="text"
                    placeholder="easypaisa_accounte"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="file type">
                  Field Type
                  <ContextualHelp className="typehint" variant="info">
                    <Content>
                      <h4>
                        <b>Field Type</b>
                      </h4>
                      <p>
                        Choose the type of the field according to the nature of
                        data that you want to store. String means any amount of
                        text, Boolean means yes, no and undefined states, while
                        number and data are self-explanatory.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <select name="file type" className="uk-select" id="filetype">
                  {/* {(this.state.categoryList.isLoaded) ? this.handleCategory() : ""} */}
                  <option>Text</option>
                  <option>Number</option>
                  <option>Date</option>
                  <option>Date and Number</option>
                  <option>True / False</option>
                  <option>Array</option>
                </select>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Description (Optional)
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Description</b>
                      </h4>
                      <p>
                        A brief note about the User Field for you and other
                        admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="description"
                    name="description"
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button className="CnclBtn" onClick={this.closeUserField}>
                    Cancel
                  </button>
                  <button className="AddTeamBtn">Create User Field</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* popup user field end */}

        {/* Create New Bot Field */}
        <div
          id="NewBot"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Create New Chat Template</h3>

            <div id="errorAlert"></div>
            <div id="successAlert"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="user_cycle">
                  Field Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Field Name</b>
                      </h4>
                      <p>How this User Field appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="tempname"
                    name="tempname"
                    type="text"
                    placeholder="Field Name"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Description (Optional)
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Description</b>
                      </h4>
                      <p>
                        A brief note about the User Field for you and other
                        admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="tempdescription"
                    name="tempdescription"
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="user_cycle">
                  Keywords
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="keywords"
                    name="keywords"
                    type="text"
                    placeholder="Keywords"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button className="CnclBtn" onClick={this.closeNewBot}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addtemplate"
                    onClick={this.addTemplate}
                  >
                    Create Template
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="EditNewBot"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit Chat Template</h3>
            <div id="editerrorAlert"></div>
            <div id="editsuccessAlert"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="user_cycle">
                  Field Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Field Name</b>
                      </h4>
                      <p>How this User Field appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="template_id"
                    value={this.state.template_id}
                    name="template_id"
                    type="hidden"
                  />
                  <input
                    className="uk-input"
                    id="new_temp"
                    value={this.state.template_name}
                    onChange={this.handleTempNameChange}
                    name="new_temp"
                    type="text"
                    placeholder="user_cycle"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Description (Optional)
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Description</b>
                      </h4>
                      <p>
                        A brief note about the User Field for you and other
                        admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="new_description"
                    onChange={this.handleTempDescriptionChange}
                    value={this.state.temp_description}
                    name="new_description"
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="user_cycle">
                  Keywords
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="keywordsEdit"
                    value={this.state.temp_keywords}
                    onChange={this.handlekeywordsChange}
                    name="keywordsEdit"
                    type="text"
                    placeholder="Keywords"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button className="CnclBtn" onClick={this.closeEditNewBot}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="edittemplate"
                    onClick={this.edittingTemplate}
                  >
                    Edit Template
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Create New Bot Field end */}

        {/* create tag start */}

        <div
          id="AddTagModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Create New Tag</h3>
            <div id="errorAlert"></div>
            <div id="successAlert"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="tagName">
                  Tag Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Tag Name</b>
                      </h4>
                      <p>How this Tag appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="newtagName"
                    name="newtagName"
                    type="text"
                    placeholder="Enter Tag Name"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="Description">
                  Description
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Description</b>
                      </h4>
                      <p>
                        A brief note about this Tag for you and other admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="description"
                    name="description"
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button className="CnclBtn" onClick={this.closeAgentModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addtag"
                    onClick={this.createTag}
                  >
                    Create Tag
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="EditTagModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit Tag</h3>
            <div id="editerrorAlert"></div>
            <div id="editsuccessAlert"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="tagName">
                  Tag Name
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Tag Name</b>
                      </h4>
                      <p>How this Tag appears in Telenor WABA</p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="tag_id"
                    name="tag_id"
                    value={this.state.tag_id}
                    type="hidden"
                  />
                  <input
                    className="uk-input"
                    id="edittagName"
                    name="edittagName"
                    value={this.state.tag_name}
                    onChange={this.handleNameChange}
                    type="text"
                    placeholder="Enter Tag Name"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="Description">
                  Description
                  <ContextualHelp variant="info">
                    <Content>
                      <h4>
                        <b>Description</b>
                      </h4>
                      <p>
                        A brief note about this Tag for you and other admins.
                      </p>
                    </Content>
                  </ContextualHelp>
                </label>
                <div className="uk-form-controls">
                  <textarea
                    className="uk-input"
                    id="editdescription"
                    name="editdescription"
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                    type="text"
                    placeholder="A note about this Tag for you and other admins."
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ">
                  <button className="CnclBtn" onClick={this.closeAgentModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="edittags"
                    onClick={this.editTag}
                  >
                    Edit Tag
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Tag?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsent"
                    onClick={this.deletetag}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="AddConsent"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Template?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsentss}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsents"
                    onClick={this.deleteTemplate}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default Tags;
