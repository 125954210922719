import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../App/Constant";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  let dummyData = {

       labels: [] ,
      datasets: [
        {
          label: 'Sent',
          data: [],
          borderColor: 'rgb(75, 103, 238)',
          backgroundColor: 'rgb(75, 103, 238)',
        },
        {
          label: 'Recieved',
          data: [],
          borderColor: 'rgb(255, 197, 122)',
          backgroundColor: 'rgb(255, 197, 122)',
        },
        // {
        //   label: 'Recieved',
        //   data: [],
        //   borderColor: 'rgb(40, 180, 99)',
        //   backgroundColor: 'rgba(40, 180, 99, 0.5)',
        // }
      ],


  }


  export const MonthMessages = (stateTo,stateFrom) => {

    const [graphData, setGraphData] = useState(dummyData);

    useEffect(() => {

      formValidation();

  }, [stateTo,stateFrom]);

  const formValidation = async () => {

    axios({
        method: 'POST',
        url: Constant.url+"month-wise-total-messages",
        data: {company_id:localStorage.getItem('company_id'),stateTo:stateTo.stateTo,stateFrom:stateTo.stateFrom,channel_type:localStorage.getItem("channeltype")}  
    }).then(result =>{

      var stats = result.data.data;
      setGraphData({

      labels: stats.month,
      datasets: [
        {
          label: 'Sent',
          data: stats.sent,
          borderColor: '#527AAD',
          backgroundColor: '#527AAD',
        },
        {
          label: 'Recieved',
          data: stats.recieved,
          borderColor: '#6AB49B',
          backgroundColor: '#6AB49B',
        },
        //  {
        //   label: 'Failed',
        //   data: stats.failed,
        //   borderColor: 'rgb(40, 180, 99)',
        //   backgroundColor: 'rgba(40, 180, 99, 0.5)',
        // },
        

      ],

      })

      
    }).catch(err => {

      // console.log(err)
            
    });


}

    
   const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom'
        },
        title: {
          display: false,
          // text: 'Chart.js Horizontal Bar Chart',
        },
      },
    };

    return <Bar options={options} data={graphData} />;


  }







