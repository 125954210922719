import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

export const BroadcastCompRateChart = () => {

     const data = {
        labels: ['Sent', 'Not delivered'],
        datasets: [
          {
            label: 'All Live Chats',
            data: [100, 0],
            backgroundColor: [
                'rgb(31, 115, 183)',
                'rgb(150, 204, 249)',
              
              
            ],
            borderColor: [
                'rgb(31, 115, 183)',
                'rgb(150, 204, 249)',
            ],
            borderWidth: 1,
            cutout:'95%',
            circumference:180,
            rotation:270,
          }
        ]
        
      };
      const options = {
        aspectRatio: 1.5,
        plugins : {
        legend: {
          display: true,
          position: "bottom",
          labels:{
            // boxWidth: 10,
            boxHeight: 4,
            usePointStyle:true,
            pointStyle: 'circle',
            pointStyleWidth	:5,
            pointStyleHeight: 5,
            
          },
        },
       
        tooltip:{
            enabled: false
        }
        }
      };

      const textMeter = [{
        id: 'textMeter',
        beforeDraw: function(chart) {
            var width = chart.width,
            height = chart.height,
            left = chart.left,
            ctx = chart.ctx;
            ctx.restore();
            // var fontSize = 25;
            // ctx.font = fontSize + "px sans-serif";
            
            var text = "11/38";
            var text1 = "Open Live Chats",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2.5;
            
            ctx.font = '45px san-serif';
            ctx.textBaseline = "top";
            ctx.fillStyle = 'rgb(75, 75, 75)';
            ctx.fillText('100%', Math.round((width - ctx.measureText(text).width) / 2)-0, textY+35);
            ctx.font = '18px san-serif';
            ctx.textBaseline = "top";
            ctx.fillStyle = '#8E8E8E';
            ctx.fillText('Messages Sent', Math.round((width - ctx.measureText(text).width) / 2)-30, textY+100);
            ctx.save();
        }
      }]
  return (
    <div>
         <Doughnut data={data} options={options}  plugins={textMeter} /> 
    </div>
  )
}
