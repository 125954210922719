import React, { useState } from 'react';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import $ from 'jquery';
import { Close } from '@carbon/icons-react';

export default function Emoji() {
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };

  return (
    <div>

      
      <Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} native="" />
      {chosenEmoji && <EmojiData chosenEmoji={chosenEmoji} />}
    </div>
  );
};

const EmojiData = ({ chosenEmoji }) => {
    document.getElementById("txtReply").value += chosenEmoji.emoji;
}