import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, Checkbox } from "@adobe/react-spectrum";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  Checkmark,
  FaceSatisfied,
  UserRole,
  Image,
  Attachment,
  Location,
  DecisionTree,
  Edit,
  StopOutline,
  TrashCan,
  Download,
  Filter,
  Tag,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  Time,
  Credentials,
  Add,
  Close,
  Renew,
  Microphone,
  Delete,
  SubtractAlt,
  UserMultiple,
} from "@carbon/icons-react";
import PageActionBar from "../../Sections/PageActionBar";
import Constant from "../../../App/Constant";
import axios from "axios";
import $, { cssNumber, data } from "jquery";
import { Multiselect } from "multiselect-react-dropdown";
import Emoji from "../../Common/Emoji";
import InfiniteScroll from "react-infinite-scroll-component";
import { index } from "d3";
class LiveChat extends React.Component {
  addMarkAsRead = () => {
    $("#AddResolved").addClass("uk-flex uk-open");
  };

  reopenShow = () => {
    $("#ReopenChat").addClass("uk-flex uk-open");
  };

  MarkResolved = () => {
    $("#MarkResolved").addClass("uk-flex uk-open");
  };

  markCancelClick = () => {
    $("#MarkResolved").removeClass("uk-flex uk-open");
  };

  cancelBulkResolved = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  cancelReopen = () => {
    $("#ReopenChat").removeClass("uk-flex uk-open");
  };

  addUnassigned = () => {
    $("#AddUnassigned").addClass("uk-flex uk-open");
  };

  AddUnsubscribe = () => {
    $("#Unsubscribe").addClass("uk-flex uk-open");
  };

  closeUnsubscribe = () => {
    $("#Unsubscribe").removeClass("uk-flex uk-open");
  };

  AddAudioplayer = () => {
    $("#audioplayer").addClass("uk-flex uk-open");
  };

  closeAudioplayer = () => {
    $("#audioplayer").removeClass("uk-flex uk-open");
  };

  cancelBulkAssign = () => {
    $("#bulkAssign").removeClass("uk-flex uk-open");
  };

  unassignShow = () => {
    $("#unAssign").addClass("uk-flex uk-open");
  };

  unassignHide = () => {
    $("#unAssign").removeClass("uk-flex uk-open");
  };

  hideTagList = () => {
    $("#ShowAllTag").removeClass("uk-flex uk-open");
  };

  hideConversationTagList = () => {
    $("#ShowAllConversationTag").removeClass("uk-flex uk-open");
  };

  showQuickreply = () => {
    $("#QuickReply").addClass("uk-flex uk-open");
  };

  closeQuickreply = () => {
    $("#QuickReply").removeClass("uk-flex uk-open");
  };

  showTagList = () => {
    $("#ShowAllTag").addClass("uk-flex uk-open");
  };

  showConversationTagList = () => {
    $("#ShowAllConversationTag").addClass("uk-flex uk-open");
  };

  // for loader form
  addLoaderModal = () => {
    $("#LoaderModal").addClass("uk-flex uk-open");
  };
  closeLoaderModal = () => {
    $("#LoaderModal").removeClass("uk-flex uk-open");
  };

  addErrorDocument = () => {
    $("#errorDocument").addClass("uk-flex uk-open");
  };
  closeErrorDocument = () => {
    $("#errorDocument").removeClass("uk-flex uk-open");
  };
  addLocation = () => {
    $("#location").addClass("uk-flex uk-open");
  };
  closeLocation = () => {
    $("#location").removeClass("uk-flex uk-open");
  };


  

  showReinitiate = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let messageParams = new FormData();
    messageParams.append("company_id", company_id);

    axios({
      method: "POST",
      url: Constant.url + "templates",
      data: messageParams,
    }).then(function (response) {
      if (response.data.status === true) {
        classRef.setState({
          messagesList: response.data.data,
        });
        $("#suggested_msgs_pop").empty();
        $("#suggested_msgs_pop").append(
          "<option id='0'>Select Message</option>"
        );
        for (let i = 0; i < response.data.data.length; i++) {
          // $("#suggested_msgs_pop ul").append("<li id='suggest_msg_"+response.data.data[i].id+"' onclick='sendMessage("+response.data.data[i].id+")'><button id='suggest_msg_"+response.data.data[i].id+"'>"+response.data.data[i].name+"</button></li>");
          $("#suggested_msgs_pop").append(
            "<option id='suggest_msg_pop_" +
              response.data.data[i].id +
              "'>" +
              response.data.data[i].name +
              "</option>"
          );
        }
      }
    });
    $("#reinitiate").addClass("uk-flex uk-open");
  };

  DeleteContactShow = () => {
    // $("#customerOptions").hide();
    $("#customerOptions").removeClass("uk-open");
    $("#DeleteContact").addClass("uk-flex uk-open");
  };
  DeleteContactHide = () => {
    $("#DeleteContact").removeClass("uk-flex uk-open");
  };

  constructor() {
    super();
    this.charUserApi = this.charUserApi.bind(this);
    this.handleUserList = this.handleUserList.bind(this);
    this.charClearApi = this.charClearApi.bind(this);
    this.charsearchApi = this.charsearchApi.bind(this);
    this.getTags = this.getTags.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.searchrecord = this.searchrecord.bind(this);
    this.onSelectTags = this.onSelectTags.bind(this);
    this.onRemoveTags = this.onRemoveTags.bind(this);
    this.onSelectConversationTags = this.onSelectConversationTags.bind(this);
    this.onRemoveConversationTags = this.onRemoveConversationTags.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.handleRoles = this.handleRoles.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleQuickReply = this.handleQuickReply.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.state = {
      chatList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      tagList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      role: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      hasComplaintExist: false,
      // conversation_selected_id: "",
      cancellationReason: [
        "Retailer Doesnt have cash",
        "Shop Closed",
        "Fake Order",
        "Wrong Location",
        "Market Rate Issue",
        "Wrong SKU",
        "Previous Complaint",
        "Asking For Extra Discount",
        "Quality Issue",
        "Late Delivery",
        "MRP Issue",
        "Service Fee Issue",
        "Discount Issue",
        "Delivery Code",
        "FA-Behavior",
        "SKU Missing",
        "Retailer purchased the items from elsewhere",
        "Price-Miscommitment ",
        "Customer wants to cancel this order due to short expiry",
        "Retailer didnt pickup the call",
      ],
      onHoldReasons: [
        "Shop Closed",
        "Wrong Location",
        "Retailer didnt have cash",
        "We could not attempt",
        "Item missing in inventory",
      ],

      partialDeliveryReasons: [
        "Item missing in inventory",
        "Retailer does not have cash for full order",
        "Item damaged at the time of delivery",
        "Mis-commitment",
        "Market Rate Issue",
        "Wrong SKU",
        "Quality Issue",
        "Late Delivery",
        "MRP Issue",
        "SA added extra SKU",
        "Customer refused to accept the full order",
        "Customer refused to accept the order due to short expiry",
      ],
      
      OwnerArray: [
        "FA Mistake",
        "SA Mistake",
        "Commercial Team Mistake",
      ],
      
      formItems: [
        {
          whatsapp_escalated: "",
          order_status: "",
          owner: "",
          // title: null,
          title: "N/A",
          duplicate_conv: "",
          description: "",
          order_id: "",
          company_id: localStorage.getItem("company_id"),
          customer_id: localStorage.getItem("customer_id"),
          conversation_id: localStorage.getItem("conversation_id"),
          // conversation_id: "",
          // conversation_id: this.state.conversation_selected_id,
          agent_id: localStorage.getItem("user_id"),
        },
      ],
      OrderIdError: [
        {
          error: "",
        },
      ],
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      country: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      suggestedList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      city: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentListForFilter: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      FilterList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      media: "",
      filterType: "is",
      popUpName: "",
      popUpNumber: "",
      popUpGender: "",
      popUpTag: "",
      popUpSunscribe: "",
      popUpContactTime: "",
      address: "",
      first_name: "",
      last_name: "",
      gender: "",
      email: "",
      phone: "",
      files: null,
      threadCount: 0,
      oldContainerId: 0,
      conversations_id: 0,
      tags: [],
      conversationtags: [],
      advancesearch: "",
      automationText: "",
      noEntry: 0,
      pageNumber: 2,
      hasMore: true,
      allowbot: false,
      phoneintlnumber: "",
      open: false,

      messagesList: [],
      messageArrayForQuickMessage: [],
      paramArrayForQuickMessage: [],
      paramErrorArrayForQuickMessage: [],
      previewHeaderForQuickMessage: "",
      previewFooterForQuickMessage: "",
      previewBodyForQuickMessage: "",
      filteredDataForQuickMessageTemplate: [],
      templateCustomParametersForQuickMessage: [],
      typeExist: "",
    };
    $("#notifyPermission").addClass("uk-flex uk-open");
  }

  onSelectTags(selectedList, selectedItem) {
    this.setState({ tags: selectedList });
  }
  onRemoveTags(selectedList, removedItem) {
    this.setState({ tags: selectedList });
  }

  startRecorder = () => {
    window.startRecording();
    $("#stopButton").prop("disabled", false);
    $("#pauseButton").prop("disabled", false);
  };

  stopRecorder = () => {
    window.stopRecording();
  };

  pauseRecorder = () => {
    window.pauseRecording();
  };

  onSelectConversationTags(selectedList, selectedItem) {
    this.setState({ conversationtags: selectedList });
  }
  onRemoveConversationTags(selectedList, removedItem) {
    this.setState({ conversationtags: selectedList });
  }

  handleQuickReply = () => {
    let result = "";
    let index = 0;
    result = this.state.suggestedList.list.map((response, index) => (
      <li>
        <button onClick={(e) => this.btnQuickReply(response.name)}>
          {response.name}
        </button>
      </li>
    ));
    return result;
  };

  checkNotification = () => {
    localStorage.getItem("chat-msg-notification") == "true"
      ? $("#chkTone").prop("checked", true)
      : $("#chkTone").prop("checked", false);
  };
  onChangeForm = (event) => {
    if (event.target.value === "Complaint Form") {
      {
        this.addNewFormModal();
      }
    } else {
      {
        this.addTicketFormModal();
      }
    }
  };

  // handleFormLiveOps = () => {
  //   let conversation = this.state.conversation_selected_id;

  //   if (conversation == "") {
  //     return (
  //       <div className="loader-form">
  //         <div uk-spinner=""></div>
  //         <div>Please wait getting conversation detail</div>
  //       </div>
  //     );
  //   }

  // };
  addFormFields = () => {
    var newfield = {
      whatsapp_escalated: "",
      order_status: "",
      owner: "",
      // title: null,
      title: "N/A",
      duplicate_conv: "",
      description: "",
      order_id: "",
      company_id: localStorage.getItem("company_id"),
      customer_id: localStorage.getItem("customer_id"),
      conversation_id: localStorage.getItem("conversation_id"),
      // conversation_id: this.state.conversation_selected_id,
      agent_id: localStorage.getItem("user_id"),
    };
    this.setState((prevState) => ({
      formItems: [...prevState.formItems, newfield],
    }));
    var newError = { error: "" };
    this.setState((prevState) => ({
      OrderIdError: [...prevState.OrderIdError, newError],
    }));
  };

  removeFormFields = (index) => {
    let formItems = [...this.state.formItems];
    formItems.splice(index, 1);
    this.setState({ formItems });

    let OrderIdError = [...this.state.OrderIdError];
    OrderIdError.splice(index, 1);
    this.setState({ OrderIdError });
  };
  onClose = () => this.setState({ open: false });

  handleToggleChange(event) {
    // <input> will have target.checked == true
    // if (event.target.checked == null) return null;\
    if ($("#chkTone").prop("checked")) {
      localStorage.setItem("chat-msg-notification", false);
    } else {
      localStorage.setItem("chat-msg-notification", true);
    }
  }

  changeAgentStatus(event) {
    // <input> will have target.checked == true
    // if (event.target.checked == null) return null;\
    let user_id = localStorage.getItem("user_id");
    let company_id = localStorage.getItem("company_id");
    if ($("#Available").prop("checked")) {
      localStorage.setItem("agentStatus", false);
      let changestate = new FormData();
      changestate.append("status", "no");
      changestate.append("user_id", user_id);
      changestate.append("company_id", company_id);
      axios({
        method: "POST",
        url: Constant.url + "activate-deactivate-user",
        contentType: "multipart/form-data",
        processData: false,
        data: changestate,
      }).then(function (result) {});
    } else {
      localStorage.setItem("agentStatus", true);
      let changestate = new FormData();
      changestate.append("status", "yes");
      changestate.append("user_id", user_id);
      changestate.append("company_id", company_id);
      axios({
        method: "POST",
        url: Constant.url + "activate-deactivate-user",
        contentType: "multipart/form-data",
        processData: false,
        data: changestate,
      }).then(function (result) {});
    }
  }

  onFileChange = (event) => {
    if(this.bytesToMegaBytes(event.target.files[0].size) >= localStorage.getItem('max_file_upload')){
      this.addErrorDocument();
    } else {
      if (event.target.files[0] != undefined) {
      
        this.addLoaderModal();
        var classRef = this;
        let agentPhoto = localStorage.getItem("agent-photo");
        let conversation_id = localStorage.getItem("conversation_id");
        let company_id = localStorage.getItem("company_id");
        let user_id = localStorage.getItem("user_id");
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        formData.append("conversation_id", conversation_id);
        formData.append("company_id", company_id);
        formData.append("user_id", user_id);
        formData.append("status", 1);
        formData.append("action", "send_reply");
  
        axios({
          method: "POST",
          url: Constant.url + "send-reply",
          contentType: "multipart/form-data",
          processData: false,
          data: formData,
        }).then(function (result) {
          if (result.status == 200) {
            let date = new Date();
            if (event.target.files[0].type.includes("text")) {
              $(".message_box_list").append(
                "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                  agentPhoto +
                  "'></div><div class='text'><span>" +
                  result.data.data.msg +
                  "</span><div class='dateSpan'>" +
                  date.getHours() +
                  ":" +
                  date.getMinutes() +
                  "</div></div></div></li>"
              );
            } else if (event.target.files[0].type.includes("image")) {
              $(".message_box_list").append(
                "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                  agentPhoto +
                  "' /></div><div class='text'><div class='whatsAppDownload'><a><img src='./images/whatsappDownload.svg' alt='Download' uk-svg=''/></a></div><img src='" +
                  result.data.data.msg +
                  "'  width='200' height='200' /><div class='dateSpan'>" +
                  date.getHours() +
                  ":" +
                  date.getMinutes() +
                  "</div></div></div></li>"
              );
            } else if (event.target.files[0].type.includes("video")) {
              $(".message_box_list").append(
                "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                  agentPhoto +
                  "' /></div><div class='text'><div class='whatsAppDownload'><a><img src='./images/whatsappDownload.svg' alt='Download' uk-svg=''/></a></div><video width='320' height='240' controls><source src='" +
                  result.data.data.msg +
                  "' type='video/mp4' /></video><div class='dateSpan'>" +
                  date.getHours() +
                  ":" +
                  date.getMinutes() +
                  "</div></div></div></li>"
              );
            } else if (event.target.files[0].type.includes("audio")) {
              $(".message_box_list").append(
                "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                  agentPhoto +
                  "' /></div><div class='text'><audio id='player' src='" +
                  result.data.data.msg +
                  "'  type='audio/mp3' controls></audio><div class='dateSpan'>" +
                  date.getHours() +
                  ":" +
                  date.getMinutes() +
                  "</div></div></div></li>"
              );
            } else if (event.target.files[0].type.includes("application")) {
              $(".message_box_list").append(
                "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                  agentPhoto +
                  "' /></div><div class='text customPdfBtn'><a href='" +
                  result.data.data.msg +
                  "' download target='_blank'><img src='images/box.svg' uk-svg='' /> Download </a><div class='dateSpan'>" +
                  date.getHours() +
                  ":" +
                  date.getMinutes() +
                  "</div></div></div></li>"
              );
            }
            $(".messages_box").scrollTop($(".messages_box")[0].scrollHeight);
            $("#file_attach").val("");
            $("#file_image").val("");
            classRef.closeLoaderModal();
          }
        });
      }
    }
  };

  bytesToMegaBytes = (bytes) => { 
    return bytes / (1024*1024); 
  }

  showpause = () => {
    $("#AumationPopup").addClass("uk-flex uk-open");
    if ($("#pauseAutomation").text().trim() == "Resume Automation") {
      this.setState({ automationText: "resume" });
    } else {
      this.setState({ automationText: "pause" });
    }
  };

  pauseCancelClick = () => {
    $("#AumationPopup").removeClass("uk-flex uk-open");
  };

  pauseConfirmClick = () => {
    let customer_id = localStorage.getItem("customer_id");
    let automationParams = new FormData();
    automationParams.append("contact_id", customer_id);
    axios({
      method: "POST",
      url: Constant.url + "pause-automation",
      data: automationParams,
    }).then(function (response) {
      if (response.data.status === true) {
        if ($("#pauseAutomation").text().trim() == "Resume Automation") {
          $("#pauseAutomation").html(
            "<span class='awsome'></span>  Pause Automation"
          );
        } else {
          $("#pauseAutomation").html(
            "<span class='awsome'></span>  Resume Automation"
          );
        }
        $("#AumationPopup").removeClass("uk-flex uk-open");
      }
    });
  };

  showAll = () => {
    $("#notes").addClass("showNotes");
    $("#showLess").removeClass("uk-hidden");
    $("#showAll").addClass("uk-hidden");
  };

  showLess = () => {
    $("#notes").removeClass("showNotes");
    $("#showAll").removeClass("uk-hidden");
    $("#showLess").addClass("uk-hidden");
  };

  refreshMsg = () => {
    window.refreshMsg();
  };

  handleFirstNameChange(event) {
    this.setState({ first_name: event.target.first_name });
  }

  handleLastNameChange(event) {
    this.setState({ last_name: event.target.last_name });
  }

  handleGenderChange(event) {
    this.setState({ gender: event.target.gender });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.email });
  }

  handleAddressChange(event) {
    this.setState({ address: event.target.address });
  }

  componentDidUpdate() {
    $("#chkLive").prop("checked", true);
  }

  componentDidMount() {
    // var classRef = this;

    // this.charUserApi();
    this.charClearApi();
    this.loadFilter();
    this.getTags();
    $("#add-agent").hide();
    this.selectCheckbox();
    this.loadAgents();
    $("#selectDiv").addClass("uk-hidden");
    this.loadSuggestedMsgs();
    this.getRoles();
    this.getCountry();
    this.getAgents();
    this.getAgentsForFilter();
    this.checkNotification();
    this.checkLocalStorage();
    $("#stopButton").prop("disabled", true);
    $("#pauseButton").prop("disabled", true);
    if (localStorage.getItem("loginStatus") == 1) {
      let screens = localStorage.getItem("rolesceens").split(",");
      var checking = screens.includes("2");
      if (checking == true) {
      } else {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }

    if (localStorage.getItem("loginStatus") == 1) {
      let screens = localStorage.getItem("rolesceens").split(",");
      var allowbotchecking = screens.includes("18");
      if (allowbotchecking == true) {
        this.setState({ allowbot: true });
      }
    }
    $("#closeBtn").hide();
    $("#chatImage").removeClass("uk-hidden");

    if (localStorage.getItem("agentStatus") == "true") {
      $("#Available").prop("checked", true);
    } else {
      $("#Available").prop("checked", false);
    }
  }

  checkLocalStorage = () => {
    if (localStorage.getItem("stats_conversation_id")) {
      setTimeout(function () {
        window.fetchUserChat(localStorage.getItem("stats_conversation_id"));
        $("#selectDiv").addClass("uk-hidden");
        localStorage.removeItem("stats_conversation_id");
      }, 700);
    }
  };

  getAgents = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    axios({
      method: "POST",
      url: Constant.url + "get-agents-for-chat",
      data: { company_id: company_id },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };
  getAgentsForFilter = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    axios({
      method: "POST",
      url: Constant.url + "get-agents-for-filter",
      data: { company_id: company_id },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentListForFilter: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleAgent = () => {
    let result = "";
    let index = 0;
    result = this.state.agentListForFilter.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  getCountry = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "countries",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          country: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCountry = () => {
    let result = "";
    let index = 0;
    result = this.state.country.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  countryChange = () => {
    this.getCity();
  };

  filtercountryChange = () => {
    this.getfilterCity();
  };

  getfilterCity = () => {
    let classRef = this;
    let country = $("#filtercountry").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  getCity = () => {
    let classRef = this;
    let country = $("#country").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };
  handleFile = (event) => {
    if (event.target.files[0] != undefined) {
      this.setState({ media: event.target.files[0] });
    }
  };

  

  handleOrderId = (e) => {
    let orderId = e.target.value;
    let error = this.checkOrderIDRegex(e.target.value);
    if (error !== "") {
      $(`#order_id`).empty();
      $(`#order_id`).append(
        '<div id="msg-dialog1" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Invalid Order ID</p></div>'
      );
    } else {
      UIkit.alert("#msg-dialog1").close();
    }
  };
  handleTicketForm = (e) => {
    e.preventDefault();
    $("#ticketSaveBtn").text("Processing");
    $("#ticketSaveBtn").prop("disabled", true);
    let formData = new FormData();
    let title = null;
    let company_id = localStorage.getItem("company_id");
    let customer_id = localStorage.getItem("customer_id");
    let conversation_id = localStorage.getItem("conversation_id");
    let agent_id = localStorage.getItem("user_id");
    let company_name = localStorage.getItem("company_name");
    let order_id = e.target.order_id.value;
    let customer_name = e.target.customer_name.value;
    let status = e.target.status.value;
    let description = e.target.description.value;
    let email = e.target.email.value;
    let phone = e.target.phone.value;
    let media = formData;

    formData.append("media", this.state.media);
    formData.append("title", title);
    formData.append("company_id", company_id);
    formData.append("customer_id", customer_id);
    formData.append("conversation_id", conversation_id);
    formData.append("agent_id", agent_id);
    formData.append("company_name", company_name);
    formData.append("order_id", order_id);
    formData.append("customer_name", customer_name);
    formData.append("status", status);
    formData.append("description", description);
    formData.append("email", email);
    formData.append("phone", phone);
    var errorOrderId = this.checkOrderIDRegex(order_id);

    if (e.target.title.value != "") {
      title = e.target.title.value;
    }

    // if (title === "") {
    //   $("#errorAlertTicket").append(
    //     '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Title is Required</p></div>'
    //   );
    //   setTimeout(function () {
    //     UIkit.alert("#msg-dialog").close();
    //   }, 1000);
    // }
    //  else
    if (customer_name == "") {
      $("#errorAlertTicket").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Customer Name is Required</p></div>'
      );
      setTimeout(function () {
        $("#ticketSaveBtn").text("Send");
        $("#ticketSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (status == "") {
      $("#errorAlertTicket").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Status is Required</p></div>'
      );
      setTimeout(function () {
        $("#ticketSaveBtn").text("Send");
        $("#ticketSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (order_id == "") {
      $("#errorAlertTicket").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Order Id is Required</p></div>'
      );
      setTimeout(function () {
        $("#ticketSaveBtn").text("Send");
        $("#ticketSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (description == "") {
      $("#errorAlertTicket").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Description is Required</p></div>'
      );
      setTimeout(function () {
        $("#ticketSaveBtn").text("Send");
        $("#ticketSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (email == "") {
      $("#errorAlertTicket").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Email is Required</p></div>'
      );
      setTimeout(function () {
        $("#ticketSaveBtn").text("Send");
        $("#ticketSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (phone == "") {
      $("#errorAlertTicket").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Phone is Required</p></div>'
      );
      setTimeout(function () {
        $("#ticketSaveBtn").text("Send");
        $("#ticketSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    // } else if (this.state.media == "") {
    //   $("#errorAlertTicket").append(
    //     '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Media is Required</p></div>'
    //   );
    //   setTimeout(function () {
    //     $("#ticketSaveBtn").text("Send");
    //     $("#ticketSaveBtn").prop("disabled", false);
    //     UIkit.alert("#msg-dialog").close();
    //   }, 1000);
    }
    // else if (order_id !== "") {
    //   if (errorOrderId !== "") {
    //     $(`#order_id`).empty();
    //     $(`#order_id`).append(
    //       '<div id="msg-dialog1" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Invalid Order ID</p></div>'
    //     );
    //     setTimeout(function () {
    //       UIkit.alert("#msg-dialog1").close();
    //     }, 1000);
    //   }
    //   // else {
    //   //   UIkit.alert("#msg-dialog1").close();
    //   // }
    // }
    // this.state.media !== "" &&
    if (
      order_id !== "" &&
      customer_name !== "" &&
      status !== "" &&
      description !== "" &&
      email !== "" &&
      phone !== "" &&
      
      errorOrderId == ""
    ) {
      axios({
        method: "POST",
        url: Constant.url + "create-ticket",
        data: formData,
      }).then(function (response) {
        // console.log("response", response);
        $("#my_select").val("");
        $("#ticketSaveBtn").text("Processing");
        $("#ticketSaveBtn").prop("disabled", true);
        if (response.data.code === 200) {
          $("#errorAlertTicket").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>CS Form submitted successfully.</p></div>'
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
            $("#ticketFormModal").removeClass("uk-flex uk-open");
            $("#ticketSaveBtn").text("Save");
            $("#ticketSaveBtn").prop("disabled", false);
          }, 2000);

          document.getElementById("ticket-form").reset();
        } else {
          $("#errorAlertTicket").empty();
          $("#errorAlertTicket").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
            $("#ticketSaveBtn").text("Save");
            $("#ticketSaveBtn").prop("disabled", false);
          }, 4000);
        }
      });
    }
  };


  handleSubmit = (e) => {
    e.preventDefault();

    let title = [];
    let escalted = [];
    let description = [];
    let order_id = [];
    let order_status = [];
    let duplicate_conv = [];
    let owner = [];
    this.state.formItems.map((val, index) => {
      escalted.push(val.whatsapp_escalated);
      order_status.push(val.order_status);
      title.push(val.title);
      duplicate_conv.push(val.duplicate_conv);
      order_id.push(val.order_id);
      if (val.order_status !== "Delivery") {
        description.push(val.description);
        owner.push(val.owner);
      }
    });
    const containEmptyString = (element) => element === "";
    let whatsappEsclatedError = escalted.filter(containEmptyString);
    let orderStatusError = order_status.filter(containEmptyString);
    let ownerError = owner.filter(containEmptyString);
    // let titleError = title.filter(containEmptyString);
    let duplicateConvError = duplicate_conv.filter(containEmptyString);
    let orderError = order_id.filter(containEmptyString);
    let descriptionError = description.filter(containEmptyString);

    // console.log("formItems", this.state.formItems)
    if (whatsappEsclatedError.length > 0) {
      $("#errorTitle").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Please select WhatsApp Esclated</p></div>'
      );
      setTimeout(function () {
        $("#complaintSaveBtn").text("Save");
        $("#complaintSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (orderStatusError.length > 0) {
      $("#errorTitle").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Please select Order Status</p></div>'
      );
      setTimeout(function () {
        $("#complaintSaveBtn").text("Save");
        $("#complaintSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (ownerError.length > 0) {
      $("#errorTitle").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Please select Owner</p></div>'
      );
      setTimeout(function () {
        $("#complaintSaveBtn").text("Save");
        $("#complaintSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    }
    // else if (titleError.length > 0) {
    //   $("#errorTitle").append(
    //     '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Title is Required</p></div>'
    //   );
    //   setTimeout(function () {
    //     UIkit.alert("#msg-dialog").close();
    //   }, 1000);
    // }
    else if (duplicateConvError.length > 0) {
      $("#errorTitle").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Please Select Duplicate Conversation</p></div>'
      );
      setTimeout(function () {
        $("#complaintSaveBtn").text("Save");
        $("#complaintSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (orderError.length > 0) {
      $("#errorOrder").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Order Id is Required</p></div>'
      );
      setTimeout(function () {
        $("#complaintSaveBtn").text("Save");
        $("#complaintSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    } else if (descriptionError.length > 0) {
      $("#errorDescription").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Please Select Reason</p></div>'
      );
      setTimeout(function () {
        $("#complaintSaveBtn").text("Save");
        $("#complaintSaveBtn").prop("disabled", false);
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    }

    let OrderIdErrorExist = this.state.OrderIdError.filter((val) => {
      return val.error == "invalid";
    });

    // console.log("OrderIdError", OrderIdErrorExist)
    // console.log("complaint form"  , this.state.formItems);
    let classref = this;
    if (
      whatsappEsclatedError.length === 0 &&
      orderStatusError.length === 0 &&
      ownerError.length === 0 &&
      // titleError.length === 0 &&
      duplicateConvError.length === 0 &&
      orderError.length === 0 &&
      descriptionError.length === 0 &&
      OrderIdErrorExist.length === 0
    ) {
      // $("#complaintSaveBtn").text("Processing");
      // $("#complaintSaveBtn").prop("disabled", true);
      axios({
        method: "POST",
        url: Constant.url + "create-complaint",
        data: this.state.formItems,
      }).then(function (response) {
        $("#resolveBtn").prop("disabled", false);

        $("#complaintSaveBtn").text("Processing");
        $("#complaintSaveBtn").prop("disabled", true);
        $("#my_select").val("");
        if (response.data.code === 200) {
          $("#complaintSaveBtn").text("Save");
          $("#complaintSaveBtn").prop("disabled", false);
          $("#errorAlertComplaint").append(
            '<div id="msg-dialog2" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Live Ops Form submitted successfully.</p></div>'
          );
          let field = [
            {
              whatsapp_escalated: "",
              order_status: "",
              owner: "",
              title: "",
              duplicate_conv: "",
              description: "",
              order_id: "",
              company_id: localStorage.getItem("company_id"),
              customer_id: localStorage.getItem("customer_id"),
              conversation_id: localStorage.getItem("conversation_id"),
              agent_id: localStorage.getItem("user_id"),
            },
          ];
          // $(`.ow0`).val("")
          setTimeout(function () {
            UIkit.alert("#msg-dialog2").close();
            $("#formModal").removeClass("uk-flex uk-open");
            classref.setState({ formItems: field });
            $("errorAlertComplaint").empty();
            $("#whatsapp_escalated").val("");
            $("#order_status").val("");
            $("#owner0").val("");
            $("#duplicate_conv").val("");
            $("#description0").val("");
            $(`#owner0`).attr('disabled',false);
            $(`#description0`).attr('disabled',false);
          }, 2000);
          // $("#whatsapp_escalated").val("");
          // $("#order_status").val("");
          // $("#owner").val("");
          // $("#duplicate_conv").val("");
          // $("#description0").val("");
          document.getElementById("complaintForm").reset();
        } else {
          $("#complaintSaveBtn").text("Save");
          $("#complaintSaveBtn").prop("disabled", false);
          $("#errorAlertComplaint").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Something went wrong</p></div>'
          );
          //   $("complaintSaveBtn").text("Save");
          //   $("complaintSaveBtn").prop("disabled", false);
          //   return;
        }
      });
    }
  };
  checkOrderIDRegex = (value) => {
    const regexExpression = /^[23]\d{6}(,[2]\d{6})*$/;
    if (!regexExpression.test(value)) {
      return "Invalid Order Id";
    }
    return "";
  };
  handleFormChange = (index, event, name) => {
  var inputElemet = document.getElementById('conversation_input_field').value
  // console.log("inputElemet",inputElemet)
    let OrderIdError = [...this.state.OrderIdError];

    let formItems = [...this.state.formItems];
    formItems[index][event.target.name] = event.target.value;
    var error;

    // if (index == 0) {
    //   formItems[0]["conversation_id"] = this.state.conversation_selected_id;
    // }
    formItems[index]['conversation_id'] = inputElemet
    // console.log("event.target.conversationid.value",event.target.conversationid.value)
    if (name == "order_id") {
      error = this.checkOrderIDRegex(event.target.value);
      if (error !== "") {
        $(`#order_id${index}`).empty();
        $(`#order_id${index}`).append(
          '<div id="' +
            `msg-dialog-${index}` +
            '"class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a> <p>Invalid Order ID</p></div>'
        );
        OrderIdError[index]["error"] = "invalid";
      } else {
        OrderIdError[index]["error"] = "valid";

        // console.log("`msg-dialog-${index}`", `msg-dialog-${index}`);
        UIkit.alert(`#msg-dialog-${index}`).close();
      }
    }
    if (event.target.value === "Cancellation") {
      $(`#description${index}`).empty();
      $(`#description${index}`).append(
        "<option id='0' value='' selected >Select Reason</option>"
      );
      for (let i = 0; i < this.state.cancellationReason.length; i++) {
        $(`#description${index}`).append(
          '<Option value="' +
            this.state.cancellationReason[i] +
            '">' +
            this.state.cancellationReason[i] +
            "</Option>"
        );
      }
    }
    if (event.target.value === "On-Hold") {
      $(`#description${index}`).empty();
      $(`#description${index}`).append(
        "<option id='0' value='' selected >Select Reason</option>"
      );
      for (let i = 0; i < this.state.onHoldReasons.length; i++) {
        $(`#description${index}`).append(
          '<Option value="' +
            this.state.onHoldReasons[i] +
            '">' +
            this.state.onHoldReasons[i] +
            "</Option>"
        );
      }
    }
    if (event.target.value === "Partial Delivery") {
      $(`#description${index}`).empty();
      $(`#description${index}`).append(
        "<option id='0' value='' selected >Select Reason</option>"
      );
      for (let i = 0; i < this.state.partialDeliveryReasons.length; i++) {
        // console.log("i", this.state.partialDeliveryReasons[i]);
        $(`#description${index}`).append(
          '<Option value="' +
            this.state.partialDeliveryReasons[i] +
            '">' +
            this.state.partialDeliveryReasons[i] +
            "</Option>"
        );
      }
    }

    if (event.target.value === "Delivery") {
      $(`#description${index}`).empty();
      $(`#description${index}`).append(
        "<option value=''>Select Reason</option>"
      );

      $(`#owner${index}`).empty();
      $(`#owner${index}`).append(
        "<option value=''>Select Owner</option>"
      );

      formItems[index]["description"] = "";
      formItems[index]["owner"] = "";
      $(`#owner${index}`).attr('disabled',true);
      $(`#description${index}`).attr('disabled',true);
      $(`#owner${index}`).val("")
      $(`#description${index}`).val("")
    }

    if(event.target.value === "Cancellation" ||event.target.value === "On-Hold" ||event.target.value === "Partial Delivery" ){
      $(`#owner${index}`).attr('disabled',false);
      $(`#description${index}`).attr('disabled',false);

      $(`#owner${index}`).empty();
      $(`#owner${index}`).append(
        "<option id='0' value='' selected >Select Owner</option>"
      );
      for (let i = 0; i < this.state.OwnerArray.length; i++) {
        $(`#owner${index}`).append(
          '<Option value="' +
            this.state.OwnerArray[i] +
            '">' +
            this.state.OwnerArray[i] +
            "</Option>"
        );
      }  

    }
    this.setState({ formItems });
    this.setState({ OrderIdError });
  };
  handleCity = () => {
    let result = "";
    let index = 0;
    result = this.state.city.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  emailFilter = () => {
    $("#emailDrop").addClass("uk-open");
  };

  nameFilter = () => {
    $("#nameDrop").addClass("uk-open");
  };

  addAgentModal = () => {
    $("#AddAgentsModal").addClass("uk-flex uk-open");
  };

  closeAgentModal = () => {
    $("#AddAgentsModal").removeClass("uk-flex uk-open");
  };

  handleRoles = () => {
    let result = "";
    let index = 0;
    result = this.state.role.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  addAgent = () => {
    $(".AddTeamBtn").text("Processing");
    $(".AddTeamBtn").prop("disabled", true);
    let classRef = this;
    var data = {};
    data = {
      role: $("#role").val(),
      full_name: $("#full_name").val(),
      phone: $("#phone").val(),
      email: $("#email").val(),
      password: $("#password").val(),
      password_confirmation: $("#password_confirmation").val(),
      invitation: "no",
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "add-agent",
      data: data,
    })
      .then(function (response) {
        // let response = {};
        // response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/agents";
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $(".AddTeamBtn").text("Add Team");
          $(".AddTeamBtn").prop("disabled", false);
          return;
        }
      })
      .then(function (response) {});
  };

  getRoles = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "roles",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          role: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  filterSearch = (filters,value) =>{
    // $(".contacts_list ul").empty();
    let classRef = this;
    let company_id = localStorage.getItem('company_id');
    let user_from = localStorage.getItem('user_id');
    $("#selectDiv").addClass("uk-hidden");
    $('#chat_box').addClass("uk-hidden");
    $('#profile_details').addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let mTimerClass = "";
    let mExpiredClass = "";
    //Fill Thread List
    let threadParams = new FormData();
    threadParams.append( 'filters', filters );
    threadParams.append( 'value', value );
    threadParams.append( 'company_id', company_id );
    threadParams.append( 'user_id', user_from );
    axios({
      method: "POST",
      url: Constant.url + "live-chat/" + company_id,
      data: threadParams,
    }).then(function (result) {
      if (result.data.code === 200) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#chat_box").addClass("uk-hidden");
        $("#profile_details").addClass("uk-hidden");
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          pageNumber: 1,

          hasMore: result.data.hasMore,
        });
        $("#chatImage").removeClass("uk-hidden");
        $("#selectOptions").addClass("uk-hidden");
        $("#data-error").addClass("uk-hidden");
      } else {
        classRef.setState({ noEntry: 1, hasMore: false, chatList:{
          isLoaded: false,
          msg: "No Data Found",
          list: [],
        } });
        $(".spinner").addClass("uk-hidden");
        $('#chatImage').addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#data-error").removeClass("uk-hidden");
      }
    });
    // $.ajax({
    //     type:'POST',
    //     url:apiUrl+"live-chat/"+company_id,
    //     data: threadParams,
    //     contentType: false,
    //     processData: false,
    //     success:function(response){
    //         if(response.status === true){
    //             $(".spinner").addClass("uk-hidden");
    //             for(let i = 0; i < response.data.length; i++){
    //                 if(response.data[i].timer.replace("PK", "") > getcurrentDate()){
    //                     mTimerClass = "";
    //                     mExpiredClass = "uk-hidden";
    //                 } else {
    //                     mTimerClass = "uk-hidden";
    //                     mExpiredClass = "";
    //                 }
    //                 mAssignedCondition =response.data[i].status==3? '<div class="assignedunassigned">Resolved</div>' : response.data[i].user_id == null ? "<div class='assignedunassigned'>Unassigned</div>" : "<div class='assignedunassigned'>Assigned</div>";
                    
    //                 $(".contacts_list ul").append("<li id='li"+response.data[i].conversation_id+"'><div class='chat_tab uk-grid uk-grid-small' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='chat_select'><input class='uk-checkbox checkbox' id='select"+response.data[i].conversation_id+"' onClick='onClickCheckbox()' type='checkbox' name='threadSelect'></div></div><a class='user_list uk-width-expand' id='convesation_iteation_"+response.data[i].conversation_id+"' onclick='fetchUserChat("+response.data[i].conversation_id+")'><div class='uk-grid-small uk-grid' uk-grid=''><div class='uk-width-auto uk-flex-first uk-first-column'><div class='pp'><img src='"+response.data[i].avatar+"'/></div></div><div class='uk-width-expand'><p id='customer_"+response.data[i].conversation_id+"'>"+response.data[i].name+"</p><p id='msg_"+response.data[i].conversation_id+"'>"+response.data[i].preview+"</p></div><div class='uk-width-auto uk-flex-last'> <div class='timingBox'>"+mAssignedCondition+"<div class='time timeLeft'><div class='countdown "+mTimerClass+"'><div class='uk-grid-collapse uk-child-width-auto uk-grid uk-countdown uk-grid-stack' uk-grid='' uk-countdown='date: "+response.data[i].timer.replace("PK", "") +"+05:00'><div><div class=' uk-countdown-hours'><span>1</span><span>8</span></div></div></div></div><p class='blue "+mTimerClass+"'>h<span class='left'>left</span></p><p class='red "+mExpiredClass+"'><span class='left'>expired</span></p></div><div class='time newTime'><p>"+response.data[i].last_reply_at.slice(0,-3)+"</p></div></div></div></div></a></div></li>");
    //             }
    //             $("#chatImage").removeClass("uk-hidden");
    //             $("#selectOptions").addClass("uk-hidden");
    //             $("#selectDiv").removeClass("uk-hidden");
    //         } else {
    //             $(".spinner").addClass("uk-hidden");
    //             $('#chatImage').addClass("uk-hidden");
    //             $("#selectDiv").removeClass("uk-hidden");
    //             $("#data-error").removeClass("uk-hidden");
    //         }
    //     }
    // });
}

  searchnum = () => {
    let classRef = this;
    var name = $("#filterName").val();
    var email = $("#filterEmail").val();
    var date = $("#filterDate").val();
    var phone = $("#filterPhone").val();
    var gender = $("#filterGender").val();
    if (name != "") {
      this.filterSearch("first_name", name);
      this.clearTextbox("", "email", "date", "phone", "gender");
    }
    if (email != "") {
      this.filterSearch("email", email);
      this.clearTextbox("name", "", "date", "phone", "gender");
    }
    if (date != "") {
      this.filterSearch("date", date);
      this.clearTextbox("name", "email", "", "phone", "gender");
    }
    if (phone != "") {
      this.filterSearch("phone", phone);
      this.clearTextbox("name", "email", "date", "", "gender");
    }
    if (gender != "") {
      this.filterSearch("gender", gender);
      this.clearTextbox("name", "email", "date", "phone", "");
    }
  };

  clearTextbox = (name, email, date, phone, gender) => {
    if (name != "") {
      $("#filterName").val("");
    }
    if (email != "") {
      $("#filterEmail").val("");
    }
    if (date != "") {
      $("#filterDate").val("");
    }
    if (phone != "") {
      $("#filterPhone").val("");
    }
    if (gender != "") {
      $("#filterGender").val("");
    }
  };

  openFilter = (seletorID) => {
    this.hideAllFilter();
    $("#" + seletorID).show();
    $("#" + seletorID).addClass("uk-dropdown uk-open");
  };

  hideFilter = (seletorID) => {
    $("#" + seletorID).removeClass("uk-dropdown uk-open");
    $("#" + seletorID).hide();
  };

  hideAllFilter = () => {
    if (!$("#mainModal").hasClass("uk-open")) {
      this.searchnum();
    }
    $("#nameDrop").hide();
    $("#emailDrop").hide();
    $("#dateDrop").hide();
    $("#phoneDrop").hide();
    // $("#cityDrop").hide();
    // $("#countryDrop").hide();
    // $("#regionDrop").hide();
    $("#genderDrop").hide();
    // $("#usernameDrop").hide();
    $("#nameDrop").removeClass("uk-dropdown uk-open");
    $("#emailDrop").removeClass("uk-dropdown uk-open");
    $("#dateDrop").removeClass("uk-dropdown uk-open");
    $("#phoneDrop").removeClass("uk-dropdown uk-open");
    // $("#cityDrop").removeClass('uk-dropdown uk-open');
    // $("#countryDrop").removeClass('uk-dropdown uk-open');
    // $("#regionDrop").removeClass('uk-dropdown uk-open');
    $("#genderDrop").removeClass("uk-dropdown uk-open");
    // $("#usernameDrop").removeClass('uk-dropdown uk-open');
  };

  handleRoles = () => {
    let result = "";
    let index = 0;
    result = this.state.role.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };
  getcurrentDate = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return date + "T" + time;
  };
  handleFormClose = () => {
    $("#my_select").val("");
    $("#ticket-form").trigger("reset");
    $("#complaintForm").trigger("reset");
  };
  handleKeyPress = (e) => {
    let date = new Date();
    let classRef = this;
    if (e.target.value.length == 0) {
      $("#chatImage").removeClass("uk-hidden");
      $("#data-error").addClass("uk-hidden");
      $("#selectDiv").addClass("uk-hidden");
      $("#chat_box").addClass("uk-hidden");
      $("#profile_details").addClass("uk-hidden");
      $(".spinner").removeClass("uk-hidden");
      let company_id = localStorage.getItem("company_id");
      let user_from = localStorage.getItem("user_id");
      let mTimerClass = "";
      let mExpiredClass = "";
      let threadParams = new FormData();
      threadParams.append("user_id", user_from);
      threadParams.append("company_id", company_id);
      threadParams.append("filter_name", $("#filterTitle").text().trim());
      axios({
        method: "POST",
        url: Constant.url + "live-chat/" + company_id,
        data: threadParams,
      }).then(function (result) {
        if (result.data.code == 200) {
          classRef.setState({
            chatList: {
              isLoaded: true,
              msg: "Loading...",
              list: result.data.data,
            },
            pageNumber: 1,
  
            hasMore: result.data.hasMore,
          });
          $(".spinner").addClass("uk-hidden");
          $("#selectDiv").removeClass("uk-hidden");
        } else {
          $(".spinner").addClass("uk-hidden");
          $("#chatImage").addClass("uk-hidden");
          $("#selectDiv").removeClass("uk-hidden");
          $("#data-error").removeClass("uk-hidden");
        }
      });
    }
    if (e.target.value.length > 2) {
      $("#data-error").addClass("uk-hidden");
      // $(".contacts_list ul").empty();
      $("#selectDiv").addClass("uk-hidden");
      $("#chat_box").addClass("uk-hidden");
      $("#profile_details").addClass("uk-hidden");
      $("#chatImage").removeClass("uk-hidden");
      $(".spinner").removeClass("uk-hidden");
      let search_text = $("#search_box").val().trim();
      let mTimerClass = "";
      let mExpiredClass = "";
      let user_from = localStorage.getItem("user_id");
      let company_id = localStorage.getItem("company_id");
      let filtername = $("#filterTitle").text().trim();
      if (filtername == "Resolved") {
        filtername = "closed";
      }
      // console.log("axio");
      axios({
        method: "POST",
        url: Constant.url + "live-chat/" + company_id,
        data: {
          search: search_text,
          user_id: user_from,
          filter_name: filtername.toLowerCase(),
        },
      }).then(function (result) {
        if (result.data.code == 200) {
          classRef.setState({
            chatList: {
              isLoaded: true,
              msg: "Loading...",
              list: result.data.data,
            },
            pageNumber: 1,
  
            hasMore: result.data.hasMore,
          });
          $(".spinner").addClass("uk-hidden");
          $("#selectDiv").removeClass("uk-hidden");
        } else {
          $(".spinner").addClass("uk-hidden");
          $("#chatImage").addClass("uk-hidden");
          $("#selectDiv").removeClass("uk-hidden");
          $("#data-error").removeClass("uk-hidden");
        }
      });
    }
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  clearFilters = () => {
    $("#to_date").val("");
    $("#from_date").val("");
    $("#filtercountry").prop("selectedIndex", 0);
    $("#filtercity").prop("selectedIndex", 0);
    $("#filtergender").prop("selectedIndex", 0);
    $("#filteragents").prop("selectedIndex", 0);
    $("#txtName").val("");
    $("#txtPhone").val("");
  };

  advanceSearch = () => {
    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");
    $("#selectDiv").addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let classRef = this;
    var data = {};
    localStorage.setItem("to_date_search",$("#to_date").val());
    localStorage.setItem("from_date_search",$("#from_date").val());
    localStorage.setItem("filtercountry_search",$("#filtercountry").val());
    localStorage.setItem("filtercity_search",$("#filtercity").val());
    localStorage.setItem("filtergender_search",$("#filtergender").val());
    localStorage.setItem("filteragents_search",$("#filteragents").val());
    localStorage.setItem("txtName_search",$("#txtName").val());
    localStorage.setItem("txtPhone_search",$("#txtPhone").val());
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      country: $("#filtercountry").val(),
      city: $("#filtercity").val(),
      gender: $("#filtergender").val(),
      agent: $("#filteragents").val(),
      name: $("#txtName").val(),
      phone: $("#txtPhone").val(),
      company_id: localStorage.getItem("company_id"),
    };
    let company_id = localStorage.getItem("company_id");
    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
      $("#closeBtn").show();
    }
    if (data.datefrom) {
      var key = key + "Date From: " + $("#from_date").val();
      $("#closeBtn").show();
    }
    if (data.country) {
      var key = key + "Country: " + $("#filtercountry :selected").text();
      $("#closeBtn").show();
    }
    if (data.city) {
      var key = key + "City: " + $("#filtercity :selected").text();
      $("#closeBtn").show();
    }
    if (data.gender) {
      var key = key + "Gender: " + $("#filtergender :selected").text();
      $("#closeBtn").show();
    }
    if (data.agent) {
      var key = key + "Agent: " + $("#filteragents :selected").text();
      $("#closeBtn").show();
    }
    if (data.name) {
      var key = key + "Name: " + $("#txtName").val();
      $("#closeBtn").show();
    }
    if (data.phone) {
      var key = key + "Phone: " + $("#txtPhone").val();
      $("#closeBtn").show();
    }

    classRef.setState({ advancesearch: key });
    axios({
      method: "POST",
      url: Constant.url + "live-chat/" + company_id,
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
        $("#chatImage").removeClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#data-error").addClass("uk-hidden");
        $(".spinner").addClass("uk-hidden");
      } else {
        classRef.setState({
          chatList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
        $("#selectDiv").removeClass("uk-hidden");
        $("#chatImage").addClass("uk-hidden");
        $("#data-error").removeClass("uk-hidden");
        $(".spinner").addClass("uk-hidden");
      }
    });
  };

  loadAgents = () => {
    let company_id = localStorage.getItem("company_id");
    let agentsParams = new FormData();
    agentsParams.append("company_id", company_id);

    axios({
      method: "POST",
      url: Constant.url + "get-agents-for-chat",
      data: agentsParams,
    }).then(function (response) {
      if (response.data.status === true) {
        $("#agentsList ul").empty();
        for (let i = 0; i < response.data.data.length; i++) {
          $("#agentsList ul").append(
            "<li id='agents_" +
              response.data.data[i].id +
              "'><button class='' type='button' onclick='agentsSelection(" +
              response.data.data[i].id +
              ")' id='agents_id_" +
              response.data.data[i].id +
              "'><div class='icon'><img src='" +
              response.data.data[i].photo_url +
              "'/></div><span class='name'>" +
              response.data.data[i].name +
              "</span><span class='uk-icon'><svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='16' height='16' viewBox='0 0 32 32' aria-hidden='true'><path d='M13 24L4 15 5.414 13.586 13 21.171 26.586 7.586 28 9 13 24z'></path></svg></span></button></li>"
          );
        }
      }
    });
  };

  btnQuickReply = (x) => {
    $("#txtReply").val(x);
    $("#QuickReply").removeClass("uk-flex uk-open");
  };

  loadSuggestedMsgs = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let messageParams = new FormData();
    messageParams.append("company_id", company_id);
    axios({
      method: "POST",
      url: Constant.url + "chat-templates",
      data: messageParams,
    }).then(function (response) {
      if (response.data.status === true) {
        classRef.setState({
          suggestedList: {
            isLoaded: true,
            msg: "Loading...",
            list: response.data.data,
          },
        });
        $("#suggested_msgs ul").empty();
        for (let i = 0; i < response.data.data.length; i++) {
          $("#suggested_msgs ul").append(
            "<li id='suggest_msg_" +
              response.data.data[i].id +
              "' data-keywords='" +
              response.data.data[i].keywords +
              "' onclick='sendSuggestMessage(" +
              response.data.data[i].id +
              ",0)'><button id='suggest_msg_" +
              response.data.data[i].id +
              "'>" +
              response.data.data[i].name +
              "</button></li>"
          );
        }
      }
    });
  };

  loadFilter = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "filters",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          FilterList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data.sidebar,
          },
        });
        // $("#assignFilter ul").empty();
        
        // for (let i = 0; i < result.data.data.sidebar.length; i++) {
        //   $("#assignFilter ul").append(
        //     "<li><button class='' type='button' onclick='FilterSelection(" +'"' +result.data.data.sidebar[i].id +'"' +
        //       ")' id='filter_id_" +
        //       result.data.data.sidebar[i].id +
        //       "'><span class='name'>" +
        //       result.data.data.sidebar[i].name +
        //       "</span><span class='uk-icon'><svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='16' height='16' viewBox='0 0 32 32' aria-hidden='true'><path d='M13 24L4 15 5.414 13.586 13 21.171 26.586 7.586 28 9 13 24z'></path></svg></span></button></li>"
        //   );
        // }
      }
    });
  };

  handleFilter = () => {
    let result = "";
    let index = 0;
    result = this.state.FilterList.list.map((response, index) => (
      <li><button class="" type="button" id={"filter_id_"+response.id} onClick={(e)=>this.FilterSelection(response.id)}><span class="name">{response.name}</span><span class="uk-icon"><svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true"><path d="M13 24L4 15 5.414 13.586 13 21.171 26.586 7.586 28 9 13 24z"></path></svg></span></button></li>
    ));
    return result;
  };

  // getAgents = () => {
  //   let classRef = this;
  //   let company_id = localStorage.getItem("company_id");
  //   axios({
  //     method: "POST",
  //     url: Constant.url + "get-agents-for-chat",
  //     data: { company_id: company_id },
  //   }).then(function (result) {
  //     if (result.data.status === true) {
  //       classRef.setState({
  //         agentList: {
  //           isLoaded: true,
  //           msg: "Loading...",
  //           list: result.data.data,
  //         },
  //       });
  //     }
  //   });
  // };



  DownloadProfile = () => {
    // $("#customerOptions").hide();
    $("#customerOptions").removeClass("uk-open");
    let customer_id = localStorage.getItem("customer_id");
    let company_id = localStorage.getItem("company_id");
    window.location.href =
      Constant.url +
      "download-profile?company_id=" +
      company_id +
      "&contact_id=" +
      customer_id +
      "";
  };

  DeleteProfile = () => {
    let customer_id = localStorage.getItem("customer_id");
    let company_id = localStorage.getItem("company_id");
    let conversation_id = localStorage.getItem("conversation_id");
    // $("#customerOptions").hide();
    $("#customerOptions").removeClass("uk-open");
    $("#li" + conversation_id).remove();
    axios({
      method: "POST",
      url: Constant.url + "delete-profile",
      data: { company_id: company_id, contact_id: customer_id },
    }).then(function (result) {
      if (result.data.status === true) {
        $("#selectDiv").removeClass("uk-hidden");
        $("#chat_box").addClass("uk-hidden");
        $("#profile_details").addClass("uk-hidden");
        $("#selectOptions").addClass("uk-hidden");
        $("#chatImage").removeClass("uk-hidden");
        $("#DeleteContact").removeClass("uk-flex uk-open");
      }
    });
  };

  SubfilterSelection = (filter_Id) =>{
    $(".newMsg").remove();
    let classRef = this;
    localStorage.setItem("sub_filter",filter_Id)
    UIkit.dropdown("#assignFilter1").hide();
    $("#selectDiv").addClass("uk-hidden");
    $('#chat_box').addClass("uk-hidden");
    $('#profile_details').addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let filterName = filter_Id;
    var elems = document.querySelectorAll("#assignFilter1 ul li .active");
    [].forEach.call(elems, function(el) {
        el.classList.remove("active");
    });
    (filter_Id == "open") ? $('#filterTitle1').text(filterName+'ed') : (filter_Id == "all") ? $('#filterTitle1').text(filterName) : $('#filterTitle1').text(filterName+'d');
    
    let mTimerClass = "";
    let mExpiredClass = "";
    let page = 1;
    let company_id = localStorage.getItem('company_id');
    let user_from = localStorage.getItem('user_id');
    let threadParams = new FormData();
    // threadParams.append( 'conversation_id', company_id );
    threadParams.append( 'user_id', user_from );
    threadParams.append( 'company_id', company_id );
    threadParams.append( 'sub_filter', filter_Id );
    threadParams.append( 'filter_name', ($('#filterTitle').text().toLowerCase() == 'all') ? "" : $('#filterTitle').text().toLowerCase());
    threadParams.append("page", page);

    let to_date_search =localStorage.getItem("to_date_search");
    let from_date_search =localStorage.getItem("from_date_search");
    let filtercountry_search =localStorage.getItem("filtercountry_search");
    let filtercity_search =localStorage.getItem("filtercity_search");
    let filtergender_search =localStorage.getItem("filtergender_search");
    let filteragents_search =localStorage.getItem("filteragents_search");
    let txtName_search =localStorage.getItem("txtName_search");
    let txtPhone_search =localStorage.getItem("txtPhone_search");

    if(to_date_search != null){
      threadParams.append("dateto", to_date_search);
    }
    if(from_date_search != null){
      threadParams.append("datefrom", from_date_search);
    }
    if(filtercountry_search != null){
      threadParams.append("country", filtercountry_search);
    }
    if(filtercity_search != null){
      threadParams.append("city", filtercity_search);
    }
    if(filtergender_search != null){
      threadParams.append("gender", filtergender_search);
    }
    if(filteragents_search != null){
      threadParams.append("agent", filteragents_search);
    }
    if(txtName_search != null){
      threadParams.append("name", txtName_search);
    }
    if(txtPhone_search != null){
      threadParams.append("phone", txtPhone_search);
    }


    axios({
      method: "POST",
      url: Constant.url + "live-chat/" + company_id,
      data: threadParams,
    }).then(function (result) {
      if (result.data.code === 200) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#chat_box").addClass("uk-hidden");
        $("#profile_details").addClass("uk-hidden");
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          pageNumber: 1,

          hasMore: result.data.hasMore,
        });
        $("#chatImage").removeClass("uk-hidden");
        $("#selectOptions").addClass("uk-hidden");
        $("#data-error").addClass("uk-hidden");
      } else {
        classRef.setState({ noEntry: 1, hasMore: false, chatList:{
          isLoaded: false,
          msg: "No Data Found",
          list: [],
        } });
        $(".spinner").addClass("uk-hidden");
        $('#chatImage').addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#data-error").removeClass("uk-hidden");
      }
    });
  }

  FilterSelection = (filter_Id) =>{
    $(".newMsg").remove();
    let classRef = this;
    UIkit.dropdown("#assignFilter").hide();
    $("#selectDiv").addClass("uk-hidden");
    $('#chat_box').addClass("uk-hidden");
    $('#profile_details').addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let filterName = $('#filter_id_'+filter_Id).text();
    var elems = document.querySelectorAll("#assignFilter ul li .active");
    [].forEach.call(elems, function(el) {
        el.classList.remove("active");
    });
    $('#filter_id_'+filter_Id).addClass('active');
    localStorage.setItem("filter_name",filter_Id)
    $('#filterTitle').text(filterName);
    let page = 1;
    let mTimerClass = "";
    let mExpiredClass = "";
    let company_id = localStorage.getItem('company_id');
    let user_from = localStorage.getItem('user_id');

    //Fill Thread List
    let threadParams = new FormData();
    // threadParams.append( 'conversation_id', company_id );
    threadParams.append( 'user_id', user_from );
    threadParams.append( 'company_id', company_id );
    let to_date_search =localStorage.getItem("to_date_search");
    let from_date_search =localStorage.getItem("from_date_search");
    let filtercountry_search =localStorage.getItem("filtercountry_search");
    let filtercity_search =localStorage.getItem("filtercity_search");
    let filtergender_search =localStorage.getItem("filtergender_search");
    let filteragents_search =localStorage.getItem("filteragents_search");
    let txtName_search =localStorage.getItem("txtName_search");
    let txtPhone_search =localStorage.getItem("txtPhone_search");

    if(to_date_search != null){
      threadParams.append("dateto", to_date_search);
    }
    if(from_date_search != null){
      threadParams.append("datefrom", from_date_search);
    }
    if(filtercountry_search != null){
      threadParams.append("country", filtercountry_search);
    }
    if(filtercity_search != null){
      threadParams.append("city", filtercity_search);
    }
    if(filtergender_search != null){
      threadParams.append("gender", filtergender_search);
    }
    if(filteragents_search != null){
      threadParams.append("agent", filteragents_search);
    }
    if(txtName_search != null){
      threadParams.append("name", txtName_search);
    }
    if(txtPhone_search != null){
      threadParams.append("phone", txtPhone_search);
    }
  

    threadParams.append( 'filter_name', filter_Id );
    threadParams.append( 'sub_filter', ($('#filterTitle1').text().toLowerCase() == 'all' || $('#filterTitle1').text().toLowerCase() == 'Filter by') ? "" : $('#filterTitle1').text().toLowerCase());
    // ($('#filterTitle1').text().toLowerCase() != "filter by" || $('#filterTitle1').text().toLowerCase() != "all") ? threadParams.append( 'sub_filter', ($('#filterTitle1').text().toLowerCase() == 'opened') ? 'open' : 'expire' ) :"";
    axios({
      method: "POST",
      url: Constant.url + "live-chat/" + company_id,
      data: threadParams,
    }).then(function (result) {
      if (result.data.code === 200) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#chat_box").addClass("uk-hidden");
        $("#profile_details").addClass("uk-hidden");
        if(localStorage.getItem('filter_name')=='all'){
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          pageNumber: 2,

          hasMore: result.data.hasMore,
        });
      }
      else{
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          pageNumber: 1,

          hasMore: result.data.hasMore,
        });
      }
        $("#chatImage").removeClass("uk-hidden");
        $("#selectOptions").addClass("uk-hidden");
        $("#data-error").addClass("uk-hidden");
      } else {
        classRef.setState({ noEntry: 1, hasMore: false, chatList:{
          isLoaded: false,
          msg: "No Data Found",
          list: [],
        } });
        $(".spinner").addClass("uk-hidden");
        $('#chatImage').addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#data-error").removeClass("uk-hidden");
      }
    });
  }

  charUserApi = () => {
    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");
    $("#selectDiv").addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let user_from = localStorage.getItem("user_id");
    let page = this.state.pageNumber;
    let charParams = new FormData();
    let sub_filter = localStorage.getItem("sub_filter");
    let filter_name = localStorage.getItem("filter_name");
    // console.log("sub_filter",sub_filter);
    // console.log("filter_name",filter_name);

    charParams.append("user_id", user_from);
    charParams.append("company_id", company_id);
    charParams.append("page", page);
    if(sub_filter !== null){
      charParams.append("sub_filter", sub_filter)
    }
    if(filter_name !== null){
      charParams.append("filter_name", filter_name)
    }
    axios({
      method: "POST",
      url: Constant.url + "live-chat/" + company_id,
      data: charParams,
    }).then(function (result) {
      if (result.data.status === true) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#chat_box").addClass("uk-hidden");
        $("#profile_details").addClass("uk-hidden");
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: [...classRef.state.chatList.list, ...result.data.data],
          },
          pageNumber: classRef.state.pageNumber + 1,

          hasMore: result.data.hasMore,
        });
      } else {
        classRef.setState({ noEntry: 1, hasMore: false });
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
      }
    });
  };

  loadUserApi = () => {
    // $("#chat_box").addClass("uk-hidden");
    // $("#profile_details").addClass("uk-hidden");
    // $("#selectDiv").addClass("uk-hidden");
    // $(".spinner").removeClass("uk-hidden");
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let user_from = localStorage.getItem("user_id");
    let page = this.state.pageNumber;
    let charParams = new FormData();
    let sub_filter = localStorage.getItem("sub_filter");
    let filter_name = localStorage.getItem("filter_name");

    let to_date_search =localStorage.getItem("to_date_search");
    let from_date_search =localStorage.getItem("from_date_search");
    let filtercountry_search =localStorage.getItem("filtercountry_search");
    let filtercity_search =localStorage.getItem("filtercity_search");
    let filtergender_search =localStorage.getItem("filtergender_search");
    let filteragents_search =localStorage.getItem("filteragents_search");
    let txtName_search =localStorage.getItem("txtName_search");
    let txtPhone_search =localStorage.getItem("txtPhone_search");

    if(to_date_search != null){
      charParams.append("dateto", to_date_search);
    }
    if(from_date_search != null){
      charParams.append("datefrom", from_date_search);
    }
    if(filtercountry_search != null){
      charParams.append("country", filtercountry_search);
    }
    if(filtercity_search != null){
      charParams.append("city", filtercity_search);
    }
    if(filtergender_search != null){
      charParams.append("gender", filtergender_search);
    }
    if(filteragents_search != null){
      charParams.append("agent", filteragents_search);
    }
    if(txtName_search != null){
      charParams.append("name", txtName_search);
    }
    if(txtPhone_search != null){
      charParams.append("phone", txtPhone_search);
    }


    // console.log("sub_filter",sub_filter);
    // console.log("filter_name",filter_name);

    charParams.append("user_id", user_from);
    charParams.append("company_id", company_id);
    charParams.append("page", page);
    if(sub_filter !== null){
      charParams.append("sub_filter", sub_filter)
    }
    if(filter_name !== null){
      charParams.append("filter_name", filter_name)
    }
      axios({
        method: "POST",
        url: Constant.url + "live-chat/" + company_id,
        data: charParams,
      }).then(function (result) {
        if (result.data.status === true) {
          // $(".spinner").addClass("uk-hidden");
          // $("#selectDiv").removeClass("uk-hidden");
          // $("#chat_box").addClass("uk-hidden");
          // $("#profile_details").addClass("uk-hidden");
            classRef.setState({
            chatList: {
              isLoaded: true,
              msg: "Loading...",
              list: [...classRef.state.chatList.list, ...result.data.data],              
            },
            pageNumber: classRef.state.pageNumber + 1,

            hasMore: result.data.hasMore,
          });
        } else {
          classRef.setState({ noEntry: 1, hasMore: false });
          // $(".spinner").addClass("uk-hidden");
          // $("#selectDiv").removeClass("uk-hidden");
        }
      });
  };

  charClearApi = () => {
    // console.log("call")
    localStorage.setItem("to_date_search",'');
    localStorage.setItem("from_date_search",'');
    localStorage.setItem("filtercountry_search",'');
    localStorage.setItem("filtercity_search",'');
    localStorage.setItem("filtergender_search",'');
    localStorage.setItem("filteragents_search",'');
    localStorage.setItem("txtName_search",'');
    localStorage.setItem("txtPhone_search",'');
    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");
    $("#selectDiv").addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let user_from = localStorage.getItem("user_id");
    let charParams = new FormData();
    this.setState({ pageNumber: 2 });
    let sub_filter = localStorage.getItem("sub_filter");
    let filter_name = localStorage.getItem("filter_name");
    
    // console.log("sub_filter",sub_filter);
    // console.log("filter_name",filter_name);

    if(sub_filter !== null){
      charParams.append("sub_filter", sub_filter)
    }
    if(filter_name !== null){
      charParams.append("filter_name", filter_name)
    }

    charParams.append("user_id", user_from);
    charParams.append("company_id", company_id);
    charParams.append("page", 1);
    axios({
      method: "POST",
      url: Constant.url + "live-chat/" + company_id,
      data: charParams,
    }).then(function (result) {
      if (result.data.status === true) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#chat_box").addClass("uk-hidden");
        $("#profile_details").addClass("uk-hidden");
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          pageNumber: 2,
        });
        // classRef.setState({
        //   chatList: {
        //     isLoaded: true,
        //     msg: 'Loading...',
        //     list: [result.data.data]
        //   },
        //   pageNumber: classRef.state.pageNumber + 1,

        //   hasMore: result.data.hasMore
        // });
      } else {
        classRef.setState({ noEntry: 1, hasMore: false });
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
      }
    });
  };

  charsearchApi = () => {
    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");
    $("#selectDiv").addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let user_from = localStorage.getItem("user_id");
    let charParams = new FormData();
    charParams.append("user_id", user_from);
    charParams.append("company_id", company_id);
    axios({
      method: "POST",
      url: Constant.url + "live-chat/" + company_id,
      data: charParams,
    }).then(function (result) {
      if (result.data.status === true) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        $("#chat_box").addClass("uk-hidden");
        $("#profile_details").addClass("uk-hidden");
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: [...classRef.state.chatList.list, ...result.data.data],
          },
          pageNumber: classRef.state.pageNumber + 1,

          hasMore: result.data.hasMore,
        });
      } else {
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: [result.data.data],
          },
          pageNumber: classRef.state.pageNumber + 1,

          hasMore: result.data.hasMore,
        });
      }
    });
  };

  getTags = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    axios({
      method: "POST",
      url: Constant.url + "tags",
      data: { company_id: company_id },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          tagList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  hideEmoji = () => {
    $(".attacments").hide();
    $("#suggested_msgs").hide();
    $("#sendMessage").text("Save Notes");
  };

  showEmoji = () => {
    $(".attacments").show();
    $("#suggested_msgs").show();
    $("#sendMessage").text("Send WhatsApp");
  };

  attachTag = () => {
    $(".ModalStyleBtns #addAgent").text("Processing");
    $(".ModalStyleBtns #addAgent").prop("disabled", true);

    let classRef = this;
    var tags = "";
    let company_id = localStorage.getItem("company_id");
    var contact_id = localStorage.getItem("customer_id");
    tags = Object.keys(classRef.state.tags)
      .map(function (k) {
        return classRef.state.tags[k].name;
      })
      .join(",");
    var data = {};

    data = {
      contact_id: contact_id,
      company_id: company_id,
      tag_name: tags,
    };
    axios({
      method: "POST",
      url: Constant.url + "attach-contact-tags",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          // this.multiselectRefTracker.current.resetSelectedValues()

          $(".ModalStyleBtns #addAgent").text("Save");
          $(".ModalStyleBtns #addAgent").prop("disabled", false);

          var conversation_id = localStorage.getItem("conversation_id");
          let company_id = localStorage.getItem("company_id");
          axios({
            method: "POST",
            url: Constant.url + "conversation/" + conversation_id,
            data: { company_id: company_id },
          }).then(function (result) {
            if (result.data.status === true) {
              $("#tags").empty();
              let tagCount = 0;
              for (let k = 0; k < result.data.data.customer.tags.length; k++) {
                tagCount += 1;
                if (tagCount < 4) {
                  $("#tags").append(
                    "<button id='tag_" +
                      result.data.data.customer.tags[k].id +
                      "'> " +
                      result.data.data.customer.tags[k].name +
                      " <a onclick='removeCustomerTags(" +
                      result.data.data.customer.tags[k].id +
                      ")'><span><svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='16' height='16' viewBox='0 0 32 32' aria-hidden='true'><path d='M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z'></path></svg></span></a></button>"
                  );
                }
              }
              tagCount = tagCount - 3;
              if (tagCount > 0) {
                $("#tags").append(
                  "<button class='time' onclick='showTagList()'><span> +" +
                    tagCount +
                    " </span>others</button>"
                );
              }
            }
          });

          setTimeout(function () {
            // $("#msg-dialog").hide();

            $("#AddTagsModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog").close();
            // $("#saj").children('div').children('span').hide();
          }, 1000);

          return;
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $(".AddTeamBtn").text("Save");
          $(".AddTeamBtn").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  attachConversationTag = () => {
    let classRef = this;
    $("#conversationButton #addAgent").text("Processing");
    $("#conversationButton #addAgent").prop("disabled", true);
    var tags = "";
    let company_id = localStorage.getItem("company_id");
    var conversation_id = localStorage.getItem("conversation_id");
    tags = Object.keys(classRef.state.conversationtags)
      .map(function (k) {
        return classRef.state.conversationtags[k].name;
      })
      .join(",");
    var data = {};

    data = {
      conversation_id: conversation_id,
      company_id: company_id,
      tag_name: tags,
    };
    axios({
      method: "POST",
      url: Constant.url + "attach-conversation-tags",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          // this.multiselectRefTracker.current.resetSelectedValues()

          $("#conversationButton #addAgent").text("Save");
          $("#conversationButton #addAgent").prop("disabled", false);
          let company_id = localStorage.getItem("company_id");
          var conversation_id = localStorage.getItem("conversation_id");
          axios({
            method: "POST",
            url: Constant.url + "conversation/" + conversation_id,
            data: { company_id: company_id },
          }).then(function (result) {
            if (result.data.status === true) {
              $("#conversation_tags").empty();
              let tagCount = 0;
              for (
                let k = 0;
                k < result.data.data.conversation.tags.length;
                k++
              ) {
                tagCount += 1;
                if (tagCount < 4) {
                  $("#conversation_tags").append(
                    "<button id='tagConversation_" +
                      result.data.data.conversation.tags[k].id +
                      "'> " +
                      result.data.data.conversation.tags[k].name +
                      " <a onclick='removeConversationTags(" +
                      result.data.data.conversation.tags[k].id +
                      ")'><span><svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='16' height='16' viewBox='0 0 32 32' aria-hidden='true'><path d='M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z'></path></svg></span></a></button>"
                  );
                }
              }
              tagCount = tagCount - 3;
              if (tagCount > 0) {
                $("#conversation_tags").append(
                  "<button class='time' onclick='showConversationTagList()'><span> +" +
                    tagCount +
                    " </span>others</button>"
                );
              }
            }
          });

          setTimeout(function () {
            // $("#msg-dialog").hide();

            $("#AddConversationTagsModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog").close();
            // $("#saj").children('div').children('span').hide();
          }, 1000);

          return;
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $(".AddTeamBtn").text("Save");
          $(".AddTeamBtn").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  onMessageSelction = (event) => {
    let classRef = this;
    let selectedmessage = event.target.value;
    const filteredData = this.state.messagesList.filter((val) => {
      return val.name == selectedmessage;
    });

    const no_of_param = filteredData[0].no_params;

    //for mdeia exist
    var typeExist = filteredData[0].type;
    classRef.setState({ noofParams: no_of_param });
    if (typeExist === "media") {
      classRef.setState({ typeExist: typeExist });
    } else {
      classRef.setState({ typeExist: "" });
    }

    if (filteredData.length > 0) {
      this.setState({
        previewHeaderForQuickMessage: filteredData[0].header,
        previewFooterForQuickMessage: filteredData[0].footer,
        previewBodyForQuickMessage: filteredData[0].body,
      });
    }

    classRef.setState({ filteredDataForQuickMessageTemplate: filteredData });

    // for error validation
    var paramError = [];
    for (var a = 0; a < filteredData[0].no_params; a++) {
      paramError.push("");
    }
    classRef.setState({
      paramErrorArrayForQuickMessage: paramError,
    });

    var paramArray = [];
    for (var a = 0; a < filteredData[0].no_params; a++) {
      paramArray.push("{{" + (a + 1) + "}}");
    }
    this.setState({ templateCustomParametersForQuickMessage: paramArray });
    var finalArray = [];
    for (var a = 0; a < filteredData[0].message.length; a++) {
      if (filteredData[0].no_params == 0) {
        finalArray.push(filteredData[0].message[a]);
      } else {
        if (a < filteredData[0].no_params) {
          finalArray.push(
            filteredData[0].message[a] +
              // +`${this.state.templateCustomParametersForQuickMessage[a]}`)
              "{{" +
              `${a + 1}` +
              "}}"
          );
        } else {
          finalArray.push(filteredData[0].message[a]);
        }
      }
    }

    classRef.setState({ messageArrayForQuickMessage: finalArray });
    var paramValues = [];
    if (no_of_param > 0) {
      for (let i = 0; i < filteredData[0].no_params; i++) {
        paramValues.push({
          parmamVal: false,
          value: "",
        });
      }
      classRef.setState({ paramArrayForQuickMessage: paramValues });
    } else {
      classRef.setState({ paramArrayForQuickMessage: [] });
    }
    classRef.setState({ noofParamsForNewBroadcast: no_of_param });
  };

  handleFile = (event) => {
    if (event.target.files[0] != undefined) {
      this.setState({ media: event.target.files[0] });
    }
  };

  handleParamChangeForQuickMessage = (e, index) => {
    let classRef = this;

    //for error validation params
    let paramsErrorArray = [...this.state.paramErrorArrayForQuickMessage];
    if (e.target.value === "") {
      paramsErrorArray[index] = "";
    } else {
      paramsErrorArray[index] = e.target.value;
    }
    classRef.setState({ paramErrorArrayForQuickMessage: paramsErrorArray });

    let paramArrayForQuickMessage = [...this.state.paramArrayForQuickMessage];
    paramArrayForQuickMessage[index][e.target.name] = e.target.value;
    paramArrayForQuickMessage[index]["value"] = e.target.value;
    if (paramArrayForQuickMessage[index][e.target.name] == "") {
      paramArrayForQuickMessage[index]["parmamVal"] = false;
    } else {
      paramArrayForQuickMessage[index]["parmamVal"] = true;
    }

    this.setState({ paramArrayForQuickMessage });

    let tempArray = [...this.state.templateCustomParametersForQuickMessage];
    tempArray[index] = e.target.value;
    classRef.setState({ templateCustomParametersForQuickMessage: tempArray });

    const filteredData = this.state.filteredDataForQuickMessageTemplate;
    var finalArray = [];
    for (var a = 0; a < filteredData[0].message.length; a++) {
      if (filteredData[0].no_params == 0) {
        finalArray.push(filteredData[0].message[a]);
      } else {
        if (a < filteredData[0].no_params) {
          finalArray.push(filteredData[0].message[a] + tempArray[a]);
        } else {
          finalArray.push(filteredData[0].message[a]);
        }
      }
    }
    classRef.setState({ messageArrayForQuickMessage: finalArray });
  };

  callCustomerDetail = () => {
    let conversation_id = localStorage.getItem("conversation_id");
    let company_id = localStorage.getItem("company_id");
    let user_id = localStorage.getItem("user_id");
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "conversation/" + conversation_id,
      data: { company_id: company_id, user_id: user_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var customerdata = result.data.data.customer;

        var text = customerdata.phone;
        var front = text.slice(0, 2);
        var part = text.slice(2);
        var pho = "+" + front + " " + part;

        classRef.setState({
          first_name: customerdata.first_name,
          last_name: customerdata.last_name,
          gender: customerdata.gender,
          address: customerdata.address,
          email: customerdata.email,
          phone: customerdata.channel_id,
          phoneintlnumber: pho,
        });
      }
    });
  };

  handleInternationalnumber = (data) => {
    let classRef = this;
    var searchword = $("#phon").val();
    this.setState({ phoneintlnumber: searchword });
  };

  saveCustomerDetail = () => {
    $(".AddTeamBtn").text("Processing");
    $(".AddTeamBtn").prop("disabled", true);
    let customer_id = localStorage.getItem("customer_id");
    let conversation_id = localStorage.getItem("conversation_id");
    let first_name = $("#first_name").val();
    let address = $("#address").val();
    let last_name = $("#last_name").val();
    let gender = $("#customer_gender").val();
    let email = $("#email").val();
    axios({
      method: "POST",
      url: Constant.url + "edit-contact/" + customer_id,
      data: {
        first_name: first_name,
        last_name: last_name,
        gender: gender,
        address: address,
        email: email,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        $("#customer_" + conversation_id).text(first_name + " " + last_name);
        $(".profile_name h4").text(first_name + " " + last_name);
        $("#gender").text(gender);
        $("#successAlert2").append(
          '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Customer Edit Successfuuly.</p></div>'
        );
        $(".AddTeamBtn").text("Save");
        $(".AddTeamBtn").prop("disabled", false);
        setTimeout(function () {
          $("#AddNewContact").removeClass("uk-flex uk-open");
          UIkit.alert("#msg-dialog").close();
        }, 1000);
      }
    });
  };

  emoji = () => {
    let messageTabs = $("#messageTabs li.uk-active").attr("data-id");
    if (messageTabs == "reply") {
      if ($("#emoji").hasClass("uk-hidden") == false) {
        $("#emoji").addClass("uk-hidden");
      } else {
        $("#emoji").removeClass("uk-hidden");
        $("#emojidiv").addClass("uk-hidden");
        $("#emojiCross").removeClass("uk-hidden");
      }
    }
  };

  hideEmojiCross = () => {
    $("#emojidiv").removeClass("uk-hidden");
    $("#emojiCross").addClass("uk-hidden");
    $("#emoji").addClass("uk-hidden");
  };

  sendWhatsApp = () => {
    window.sendMessage();
  };

  selectCheckbox = () => {
    let classRef = this;
    let threadCount = 0;
    $("#select_all").on("click", function () {
      if (this.checked) {
        $(".checkbox").each(function () {
          this.checked = true;
          threadCount = $(
            '.contacts_list input[type="checkbox"]:checked'
          ).length;
        });
      } else {
        $(".checkbox").each(function () {
          this.checked = false;
          threadCount = $(
            '.contacts_list input[type="checkbox"]:checked'
          ).length;
        });
      }
      $("#chat_box").addClass("uk-hidden");
      $("#profile_details").addClass("uk-hidden");
      classRef.setState({ threadCount: threadCount });
      window.selectAllCheckbox(threadCount);
    });
  };

  unAssignClick = () => {
    window.unAssign();
  };

  onClickCheckbox = () => {
    let classRef = this;
    let threadCount = 0;
    // $('.checkbox').on('click',function(){
    if ($(".checkbox:checked").length == $(".checkbox").length) {
      $("#select_all").prop("checked", true);

      threadCount = $('.contacts_list input[type="checkbox"]:checked').length;
    } else {
      $("#select_all").prop("checked", false);
      threadCount = $('.contacts_list input[type="checkbox"]:checked').length;
    }

    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");
    classRef.setState({ threadCount: threadCount });
    window.selectAllCheckbox(threadCount);
    $("#threadCount_reopen_span").text(threadCount);
    // console.log(threadCount);
    // });
  };

  fetchUserDetail = (user_id) => {
    // console.log("fetchUserChat",user_id)
    let classRef = this;
    // classRef.setState({ conversations_id : localStorage.getItem('conversation_id') });
    // window.fetchUserChat(user_id);
    let Mtags = window.fetchUserChat(user_id);

    // apiUrl+"conversation/"+conversation_id,
    // axios({
    //   method: "POST",
    //   url: Constant.url + "conversation/" + user_id,
    //   data: { company_id: localStorage.getItem("company_id") },
    // }).then(function (result) {
    //   // console.log("conversation call", result);
    //   if (result.data.status === true) {
    //     console.log("conversation call", result.data.data.conversation.id);
    //     classRef.setState({
    //       conversation_selected_id: result.data.data.conversation.id,
    //       // conversation_selected_id: "",
    //     });
    //   }
    // });
    setTimeout(() => {
      classRef.setState({
        tags: Mtags[0],
        conversationtags: Mtags[1],
      });
    }, 2000);
  };

  addTag = () => {
    $("#AddTagsModal").addClass("uk-flex uk-open");
  };

  addConversationTag = () => {
    $("#AddConversationTagsModal").addClass("uk-flex uk-open");
  };

  confirmResolveError = () => {
    $("#markResolveError").removeClass("uk-flex uk-open");
  };

  confirmReinitaite = () => {
    let classRef = this;

    let template_id = $("#suggested_msgs_pop")
      .find("option:selected")
      .attr("id");
    template_id = template_id.split("_")[3];
    // console.log("template_id", template_id);
    let fileName = $("#fileName").val();

    var paramError = this.state.paramErrorArrayForQuickMessage;
    const containEmptyString = (element) => element === "";
    let customTemplateParamErrros = paramError.filter(containEmptyString);
    let template_message = this.state.messageArrayForQuickMessage.join(" ");
    let media = this.state.media;
    let company_id = localStorage.getItem("company_id");
    let company_msisdn = localStorage.getItem("company_msisdn");
    let user_id = localStorage.getItem("user_id");
    // let company_id = localStorage.getItem("company_id");
    let conversation_id = localStorage.getItem("conversation_id");
    let reInitiateParams = new FormData();

    if (template_id == undefined) {
      $("#errorAlertQuick").append(
        '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please select message first!</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 2000);
      return;
    }
    if (customTemplateParamErrros.length > 0) {
      $("#errorAlertQuick").append(
        '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please fill all params</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 2000);
      return;
    }
    if (this.state.typeExist !== "") {
      if (media === "") {
        $("#errorAlertQuick").append(
          '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Media is required</p></div>'
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 2000);
        return;
      }
      if (fileName === "") {
        $("#errorAlertQuick").append(
          '<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>FileName is Required</p></div>'
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 2000);
        return;
      }
    }

    // if (template_id > 0) {

    reInitiateParams.append("conversation_id", conversation_id);
    // reInitiateParams.append("company_id", company_id);
    reInitiateParams.append("template_id", template_id);
    reInitiateParams.append("user_id", user_id);
    reInitiateParams.append("status", 1);
    reInitiateParams.append("business_number", company_msisdn);
    reInitiateParams.append("template_message", template_message);
    reInitiateParams.append("company_id", company_id);
    reInitiateParams.append("template_id", template_id);
    // reInitiateParams.append("msisdn", phone_no);
    if (this.state.typeExist != "") {
      reInitiateParams.append("file", media);
      reInitiateParams.append("filename", fileName);
    }
    if (paramError.length > 0) {
      paramError.map((val, index) => {
        reInitiateParams.append(`param${index + 1}`, val);
      });
    }
    axios({
      method: "POST",
      url: Constant.url + "re-initiate-chat",
      data: reInitiateParams,
    }).then(function (result) {
      // console.log("result", result);
      if (result.data.status === true) {
        let date = new Date();
        let agentPhoto = localStorage.getItem("agent-photo");

        $("#successAlertQuick").append(
          '<div id="msg-dialog" class="uk-alert-success uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Message sent successfully.</p></div>'
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#reinitiate").removeClass("uk-flex uk-open");
          $("#AddTeamBtn").text("Processing");
          $("#AddTeamBtn").prop("disabled", true);
          $("#AddTeamBtn").text("Send");
          $("#AddTeamBtn").prop("disabled", false);
          // $("#phone_no").val("");
          $("#fileName").val("");

          classRef.setState({
            messageArrayForQuickMessage: [],
            paramArrayForQuickMessage: [],
            paramErrorArrayForQuickMessage: [],
            previewHeaderForQuickMessage: "",
            previewFooterForQuickMessage: "",
            previewBodyForQuickMessage: "",
            filteredDataForQuickMessageTemplate: [],
            templateCustomParametersForQuickMessage: [],
            typeExist: "",
          });
        }, 2000);

        $(".message_box_list").append(
          "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
            agentPhoto +
            "'></div><div class='text'><span>" +
            result.data.data.msg +
            "</span><div class='dateSpan'>" +
            date.getHours() +
            ":" +
            date.getMinutes() +
            "</div></div></div></li>"
        );
        $(".messages_box").scrollTop($(".messages_box")[0].scrollHeight);
        // $("#reinitiate").removeClass("uk-flex uk-open");
      }
    });
  };

  isComplaintExist = () => {
    $("#resolveBtn").prop("disabled", false);
    $(`#complaintNotExistError`).empty();
    let classRef = this;

    var conversation_id = localStorage.getItem("conversation_id");
    axios({
      method: "POST",
      url: Constant.url + "has-complaint",
      data: {
        conversation_id,
      },
    }).then(function (result) {
      $("#complaintNotExistError").empty();
      if (result.data.status === true) {
        classRef.setState({
          hasComplaintExist: true,
        });
      } else {
        $(`#complaintNotExistError`).append(
          '<div id="msg-dialog" class="uk-alert-danger uk-margin-top" uk-alert><a class="uk-alert-close" uk-close></a> <p>Please fill Live Ops Form then resolve the chat</p></div>'
        );
        $("#resolveBtn").prop("disabled", true);
        // setTimeout(function () {
        //   UIkit.alert("#msg-dialog").close();
        // }, 2000);
        classRef.setState({
          hasComplaintExist: false,
        });
        // localStorage.setItem("submit_complaint_form", 0);
      }
    });
  };
  markResolved = () => {
    // let tagCount = document.querySelectorAll("#tags button");
    // if (tagCount.length > 0) {

    if (localStorage.getItem("submit_complaint_form") == 1) {
      this.isComplaintExist();
    }
    this.MarkResolved();
    // } else {
    //   $("#markResolveError").addClass("uk-flex uk-open");
    // }
  };

  markConfirmClick = () => {
    if ($("#resolve_comment").val() != "") {
      window.markResolved();
      if ($("#pauseAutomation").text().trim() == "Resume Automation") {
        this.setState({ automationText: "pause" });
        $("#pauseAutomation").html(
          "<span class='awsome'></span>  Pause Automation"
        );
      } else {
        this.setState({ automationText: "resume" });
        $("#pauseAutomation").html(
          "<span class='awsome'></span>  Resume Automation"
        );
      }
    } else {
      $("#errorAlertresolved").append(
        '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Please fill comment before reoslve this conversation.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    }
    
    // this.markCancelClick();
  };

  reopenChat = () => {
    let reopenConversations = "";
    $('input[name="threadSelect"]:checked').each(function () {
      reopenConversations += $(this).attr("id").split("select")[1] + ",";
    });
    reopenConversations = reopenConversations.slice(0, -1);

    let user_id = localStorage.getItem("user_id");
    let company_id = localStorage.getItem("company_id");
    let reopenParams = new FormData();
    reopenParams.append("conversation_id", reopenConversations);
    reopenParams.append("user_id", user_id);
    reopenParams.append("status", 1);
    reopenParams.append("company_id", company_id);
    axios({
      method: "POST",
      url: Constant.url + "change-conversation-status",
      data: reopenParams,
    }).then(function (result) {
      if (result.data.status === true) {
        $(".checkbox").each(function () {
          this.checked = false;
        });
        let FilterName = $("#assignFilter li .active")
          .attr("id")
          .split("id_")[1];
        this.FilterSelection(FilterName);
        $("#select_all").prop("checked", false);
        $("#selectOptions").addClass("uk-hidden");
        $("#chatImage").removeClass("uk-hidden");
        $("#ReopenChat").removeClass("uk-flex uk-open");
      }
    });
  };

  bulkMarkResolved = () => {
    let resolvedConversations = "";
    $('input[name="threadSelect"]:checked').each(function () {
      resolvedConversations += $(this).attr("id").split("select")[1] + ",";
    });
    resolvedConversations = resolvedConversations.slice(0, -1);

    let user_id = localStorage.getItem("user_id");
    let company_id = localStorage.getItem("company_id");
    let resolvedParams = new FormData();
    resolvedParams.append("conversation_id", resolvedConversations);
    resolvedParams.append("user_id", user_id);
    resolvedParams.append("status", 3);
    resolvedParams.append("company_id", company_id);
    axios({
      method: "POST",
      url: Constant.url + "change-conversation-status",
      data: resolvedParams,
    }).then(function (result) {
      if (result.data.status === true) {
        $(".checkbox").each(function () {
          this.checked = false;
        });
        $("#successAlertAddResolved").append(
          '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Conversation resolved successfully.</p></div>'
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
          $("#select_all").prop("checked", false);
          $("#selectOptions").addClass("uk-hidden");
          $("#chatImage").removeClass("uk-hidden");
          $("#AddResolved").removeClass("uk-flex uk-open");
        }, 2000);
      } else {
        $("#errorAlertAddResolved").append(
          '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 2000);
      }
    });
  };

  bulkAssignClick = () => {
    window.bulkAssign();
  };

  getcurrentDate = () => {
    let today = new Date();

    let date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);

    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    return date + "T" + time;
  };

  handleUserList = () => {
    let result = "";
    let index = 0;

    result = this.state.chatList.list.map((response, index) => (
      <li id={"li" + response.conversation_id} data-id={response.status}>
        <div className="chat_tab uk-grid uk-grid-small" uk-grid="">
          <div className="uk-width-auto uk-flex-first">
            <div className="chat_select">
              {/* <Checkbox isEmphasized></Checkbox> */}
              <input
                className="uk-checkbox checkbox"
                id={"select" + response.conversation_id}
                data-id={response.status}
                onClick={this.onClickCheckbox}
                type="checkbox"
                name="threadSelect"
              ></input>
            </div>
          </div>
          <a
            className="user_list uk-width-expand"
            id={"convesation_iteation_" + response.conversation_id}
            onClick={(e) => this.fetchUserDetail(response.conversation_id)}
          >
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-auto uk-flex-first">
                <div className="pp">
                  <img src={response.avatar} />
                  <div className="whatsappProfilelogo">
                    <img src={"/images/" + response.channel_image} uk-svg="" />
                  </div>
                </div>
              </div>
              <div className="uk-width-expand">
                <p id={"customer_" + response.conversation_id}>
                  {response.name}
                </p>

                <p id={"msg_" + response.conversation_id}>
                  

                  {/* {response.preview.includes("https") !== false ? (
                    <>
                      <Attachment /> Attachment{" "}
                    </>
                  ) : (
                    response.preview
                  )} */}


                  {response.preview.includes("https") !== false ? (
                    <>
                      <Attachment /> Attachment{" "}
                    </>
                  ) : (response.msg_type == "location") ? (
                    <>
                      <Location /> Location{" "}
                    </>
                  ) : (
                    response.preview
                  )}
                </p>
              </div>

              <div className="uk-width-auto uk-flex-last">
                <div className="timingBox">
                  {response.status == 3 ? (
                    <div className="assignedunassigned">Resolved</div>
                  ) : response.user_id == null ? (
                    <div className="assignedunassigned">Unassigned</div>
                  ) : (
                    <div className="assignedunassigned">Assigned</div>
                  )}
                  {/* <div className="assignedunassigned">Assigned</div> */}
                  <div className="time timeLeft">
                    {/* {console.log((response.timer.replace("PK", "") > this.getcurrentDate()) ? ""  : "countdown uk-hidden") } */}
                    <div
                      id="customDate"
                      className={
                        response.timer.replace("PK", "") > this.getcurrentDate()
                          ? "countdown"
                          : "countdown uk-hidden"
                      }
                      uk-countdown={
                        "date: " + response.timer.replace("PK", "") + "+05:00"
                      }
                    >
                      <div className=" uk-countdown-hours"></div>
                    </div>
                    <p
                      className={
                        response.timer.replace("PK", "") > this.getcurrentDate()
                          ? "blue"
                          : "blue uk-hidden"
                      }
                    >
                      h<span className="left">left</span>
                    </p>
                    <p
                      className={
                        response.timer.replace("PK", "") < this.getcurrentDate()
                          ? "red"
                          : "red uk-hidden"
                      }
                    >
                      <span className="left">expired</span>
                    </p>
                  </div>
                  <div className="time newTime">
                    <p>{response.last_reply_at.slice(0, -3)}</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div className="agentList">
            <div className="uk-width-auto uk-flex-first">
              <div className="agentIcon">
                <UserMultiple />
              </div>
            </div>
            <div className="uk-width-expand">
              <p>{response.agent_name}</p>
              {/* <p id={"msg_"+response.conversation_id}>{response.preview}</p> */}
            </div>
            <div className="uk-width-auto uk-flex-last uk-float-right">
              <div className="count">
                <span
                  class="uk-badge"
                  id="unreadMsgs"
                  style={{ display: "none" }}
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>
      </li>
    ));

    return result;
  };

  searchrecord(event) {
    // console.log("event",event)
    this.setState({ advancesearch: event.target.advancesearch });
    this.setState({ pageNumber: 2 });
    // this.charUserApi();
  }

  // searchrecord = () =>{

  // }

  addNewContact = () => {
    $("#AddNewContact").addClass("uk-flex uk-open");
    // $("#customerOptions").hide();
    $("#customerOptions").removeClass("uk-open");
    this.callCustomerDetail();
  };
  closeContactModal = () => {
    $("#AddNewContact").removeClass("uk-flex uk-open");
  };

  // for form1
  addNewFormModal = () => {
    $("#formModal").addClass("uk-flex uk-open");
    //  axios({
    //   method: "POST",
    //   url: Constant.url + "conversation/" + user_id,
    //   data: { company_id: localStorage.getItem("company_id") },
    // }).then(function (result) {
    //   // console.log("conversation call", result);
    //   if (result.data.status === true) {
    //     console.log("conversation call", result.data.data.conversation.id);
    //     classRef.setState({
    //       conversation_selected_id: result.data.data.conversation.id,
    //       // conversation_selected_id: "",
    //     });
    //   }
    // });
  };
  closeNewFormModal = () => {
    $("#formModal").removeClass("uk-flex uk-open");
  };

  // for ticket form
  addTicketFormModal = () => {
    $("#ticketFormModal").addClass("uk-flex uk-open");
  };
  closeTicketFormModal = () => {
    $("#ticketFormModal").removeClass("uk-flex uk-open");
  };

  // for loader form
  addLoaderModal = () => {
    $("#LoaderModal").addClass("uk-flex uk-open");
  };
  closeLoaderModal = () => {
    $("#LoaderModal").removeClass("uk-flex uk-open");
  };

  advSearchClear = () => {
    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");
    $("#closeBtn").hide();
    localStorage.setItem("to_date_search","");
    localStorage.setItem("from_date_search","");
    localStorage.setItem("filtercountry_search","");
    localStorage.setItem("filtercity_search","");
    localStorage.setItem("filtergender_search","");
    localStorage.setItem("filteragents_search","");
    localStorage.setItem("txtName_search","");
    localStorage.setItem("txtPhone_search","");
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    // classRef.charClearApi();
    classRef.charUserApi();
    $("#chatImage").removeClass("uk-hidden");
    $("#data-error").addClass("uk-hidden");
  };

  handleButton = () => {
    //   if (e.key === 'Enter') {
    //     console.log('do validate');
    //   }
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // console.log("do validate");
    }
  };
  render() {
    const options = [
      {
        value: 1,
        label: (
          <div className="chip">
            <img src="/images/avatar.jpg" alt="Image" />
            <span>Usman Ramnani</span>
          </div>
        ),
      },
      {
        value: 2,
        label: (
          <div className="chip">
            <img src="/images/avatar.jpg" alt="Image" />
            <span>Usman Ramnani</span>
          </div>
        ),
      },
    ];
    return (
      <Content>
        <div className="chat_container" uk-height-match=".ht_match">
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Live chat</h3>
              </div>
              <div className="uk-width-expand">
                <div className="search_field">
                  <form action="JavaScript:void(0);">
                    <input
                      className="uk-input"
                      type="text"
                      onChange={this.searchrecord}
                      id="search_box"
                      onKeyUp={this.handleKeyPress}
                      value={this.state.advancesearch}
                      placeholder="Search through live chat conversations"
                    />
                  </form>
                  <div className="CampaignReports Filtermain">
                    <div className="uk-width-auto">
                      <button
                        style={{ right: "30px" }}
                        id="closeBtn"
                        onClick={this.advSearchClear}
                      >
                        <Close />
                      </button>
                      <button onClick={this.addSearchModal}>
                        <img src="/images/newfilter.svg" uk-svg />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-width-1-5">
                {this.state.allowbot == true ? (
                  <div className="NotificationToggleNew NotificationToggle">
                    <label className="CustomNoti">Bot</label>
                    <label className="switch">
                      {/* { localStorage.getItem('chat-msg-notification') == "true" ? checked : "" } */}

                      <input type="checkbox" class="abc" id="chkLive" />
                      <Link to="/bot-chat" className="slider round"></Link>
                    </label>
                    <label className="CustomNoti">Live Chat</label>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="uk-width-1-5">
                <div className="AvailableToggle NotificationToggle">
                  <label className="switch">
                    <input
                      type="checkbox"
                      class="Availablechk"
                      id="Available"
                    />
                    <span
                      className="slider round"
                      onClick={this.changeAgentStatus}
                    />
                  </label>
                  <label className="CustomNoti">Available</label>
                </div>
              </div>

              <div className="uk-width-1-5">
                <div className="NotificationToggle">
                  <label className="switch">
                    {/* { localStorage.getItem('chat-msg-notification') == "true" ? checked : "" } */}

                    <input type="checkbox" class="abc" id="chkTone" />
                    <span
                      className="slider round"
                      onClick={this.handleToggleChange}
                    ></span>
                  </label>
                  <label className="CustomNoti">Notification tune</label>
                </div>
              </div>

              {/* <div class="Filtermain">
                        <div className="uk-width-auto">
                            <button class="" type="button"><Filter /><span>Filter</span></button>
                            <div uk-dropdown="mode: click;pos">
                                <div class="uk-margin-small">
                                    <div class="uk-inline">
                                        <span class="uk-form-icon" uk-icon="icon: user"></span>
                                        <input class="uk-input" type="text" placeholder="Search"/>
                                    </div>
                                </div>
                                <ul className="uk-nav uk-dropdown-nav">
                                    <li class="">
                                        <button class="uk-button" onClick={this.nameFilter} type="button"><span>Name</span></button>
                                    </li>
                                    <li>
                                        <button class="uk-button" onClick={this.emailFilter} type="button"><span>Email</span></button>
                                    </li>
                                    <li ><button class="uk-button"><span>Last seen</span></button></li>
                                    
                                    <li ><button class="uk-button"><span>First seen</span></button></li>
                                    <li ><button class="uk-button"><span>Date</span></button></li>
                                    <li ><button class="uk-button"><span>City</span></button></li>
                                    <li ><button class="uk-button"><span>Country</span></button></li>
                                    <li ><button class="uk-button"><span>Region</span></button></li>
                                    <li ><button class="uk-button"><span>Language</span></button></li>
                                    <li ><button class="uk-button"><span>URL Referrer</span></button></li>
                                    <li ><button class="uk-button"><span>IP</span></button></li>
                                    <li ><button class="uk-button"><span>Timezone</span></button></li>
                                    <li ><button class="uk-button"><span>Integration</span></button></li>
                                    <li ><button class="uk-button"><span>URL</span></button></li>
                                    <li ><button class="uk-button"><span>Gender</span></button></li>
                                    <li ><button class="uk-button"><span>Username</span></button></li>
                                    <li ><button class="uk-button"><span>Username</span></button></li>
                                </ul>
                            </div>


                            <div id="emailDrop" uk-dropdown="mode: click;">
                              <p><b>Email</b></p>
                              <ul className="uk-nav uk-dropdown-nav">
                                  <li>
                                      <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                      <input type="text" />
                                  </li>
                              </ul>
                            </div>

                            <div id="nameDrop" uk-dropdown="mode: click;">
                              <p><b>Name</b></p>
                              <ul className="uk-nav uk-dropdown-nav">
                                  <li>
                                      <label><input class="uk-radio" type="radio" name="sameradio"/>Is</label>
                                      <input type="text" />
                                  </li>
                              </ul>
                            </div>

                        </div>
                    </div> */}
              {/* <div className="uk-width-auto">
                        <Link to="javascript:;"><Tag /><span>Segments</span></Link>
                    </div>
                    <div className="uk-width-auto">
                        <Link to="javascript:;"><Tag /><span>Tags</span></Link>
                    </div> */}
              <div className="uk-width-expand" id="add-agent">
                <button
                  className="newFlowBtn uk-float-right"
                  uk-toggle="target: #AddBroadcastModal"
                >
                  <Add /> <span>Add Agent</span>
                </button>
              </div>
            </div>
            <div
              id="SearchTeamModal"
              className="AddTeamModal ModalStyle uk-flex-top"
              uk-modal=""
            >
              <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button className="uk-modal-close-default" type="button">
                  {" "}
                  <Close />
                </button>
                <h3>Advance Search</h3>
                <form className="uk-form-stacked" action="javascript:void(0);">
                  <div className="uk-margin">
                    <div className="Campaigndate">
                      <label className="uk-form-label" htmlFor="FromDate">
                        From Date
                      </label>
                      <div class="uk-form-controls">
                        <input
                          type="date"
                          label="Select Broadcast"
                          className="to_date"
                          id="from_date"
                        />
                      </div>
                    </div>

                    <div className="Campaigndate">
                      <label className="uk-form-label" htmlFor="ToDate">
                        To Date
                      </label>
                      <div class="uk-form-controls">
                        <input
                          type="date"
                          label="Select Broadcast"
                          className="from_date"
                          id="to_date"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="uk-margin">
                    <div className="Campaigndate">
                      <label className="uk-form-label" htmlFor="teamName">
                        Country
                      </label>
                      <div className="uk-form-controls">
                        <select
                          className="uk-select to_date"
                          id="filtercountry"
                          onChange={this.filtercountryChange}
                        >
                          <option value="" selected>
                            Select Country
                          </option>
                          {this.state.country.isLoaded
                            ? this.handleCountry()
                            : ""}
                        </select>
                      </div>
                    </div>

                    <div className="Campaigndate">
                      <label className="uk-form-label" htmlFor="teamName">
                        City
                      </label>
                      <div className="uk-form-controls">
                        <select className="uk-select from_date" id="filtercity">
                          <option value="" selected>
                            Select City
                          </option>
                          {this.state.city.isLoaded ? this.handleCity() : ""}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="uk-margin">
                    <div className="Campaigndate">
                      <label className="uk-form-label" htmlFor="teamName">
                        Gender
                      </label>
                      <div className="uk-form-controls">
                        <select className="uk-select to_date" id="filtergender">
                          <option value="" selected>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>

                    <div className="Campaigndate">
                      <label className="uk-form-label" htmlFor="teamName">
                        Agents
                      </label>
                      <div className="uk-form-controls">
                        <select
                          className="uk-select from_date"
                          id="filteragents"
                        >
                          <option value="" selected>
                            Select Agent
                          </option>
                          {this.state.agentList.isLoaded
                            ? this.handleAgent()
                            : ""}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="uk-margin">
                    <div className="Campaigndate">
                      <label className="uk-form-label" htmlFor="teamName">
                        Name
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input to_date"
                          id="txtName"
                          name="txtName"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>

                    <div className="Campaigndate">
                      <label className="uk-form-label" htmlFor="teamName">
                        Phone
                      </label>
                      <div className="uk-form-controls">
                        <input
                          className="uk-input"
                          id="txtPhone"
                          name="txtPhone"
                          type="text"
                          placeholder="Enter Phone"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uk-margin">
                    <div className="ModalStyleBtns ClearFiltersBtn">
                      <button
                        className="AddTeamBtn"
                        onClick={this.clearFilters}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>

                  <div className="uk-margin">
                    <div className="ModalStyleBtns CampaignBtn ClearBtns">
                      <button
                        className="CnclBtn"
                        onClick={this.closeSearchModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="AddTeamBtn"
                        id="addteam"
                        onClick={this.advanceSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              id="AddAgentsModal"
              className="AddTeamModal ModalStyle uk-flex-top"
              uk-modal=""
            >
              <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button className="uk-modal-close-default" type="button">
                  {" "}
                  <Close />
                </button>
                <h3>Add agent</h3>

                <div id="errorAlert"></div>
                <div id="successAlert"></div>

                <form className="uk-form-stacked" action="javascript:void(0);">
                  <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="teamName">
                      Role
                    </label>
                    <div className="uk-form-controls">
                      <select className="uk-select" id="role">
                        {this.state.role.isLoaded ? this.handleRoles() : ""}
                      </select>
                    </div>
                  </div>
                  <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="teamName">
                      Full name
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="full_name"
                        name="full_name"
                        type="text"
                        placeholder="Full legal name"
                      />
                    </div>
                  </div>
                  <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="teamName">
                      Phone
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="phone"
                        name="phone"
                        type="text"
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                  <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="teamName">
                      Email address
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="email"
                        name="email"
                        type="text"
                        placeholder="name@example.com"
                      />
                    </div>
                  </div>
                  <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="teamName">
                      Password
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Minimum 8 characters"
                      />
                    </div>
                  </div>
                  <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="teamName">
                      Confirm password
                    </label>
                    <div className="uk-form-controls">
                      <input
                        className="uk-input"
                        id="password_confirmation"
                        name="password_confirmation"
                        type="password"
                        placeholder="Minimum 8 characters"
                      />
                    </div>
                  </div>
                  <div className="uk-margin">
                    <label>
                      <input
                        className="uk-checkbox"
                        id="invitation"
                        name="invitation"
                        type="checkbox"
                        checked
                      />{" "}
                      Send an email invite
                    </label>
                    <p className="notice">
                      An invite can be sent later if you're not ready
                    </p>
                  </div>
                  <div className="uk-margin">
                    <div className="ModalStyleBtns">
                      <button
                        className="CnclBtn"
                        onClick={this.closeAgentModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="AddTeamBtn"
                        id="addAgent"
                        onClick={this.addAgent}
                      >
                        Add agent
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <PageActionBar />    */}
          {/* audio tone */}

          <div className="uk-grid uk-grid-collapse" uk-grid>
            <div className="chat_heads_list ht_match uk-width-1-4">
              <div className="chat_box_head">
                <div className="mdl">
                  <div className="mdl_inner">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand">
                        <div className="user_selection">
                          <div className="uk-inline">
                            <div className="select_all">
                              <input
                                onClick={this.onClickCheckbox}
                                className="uk-checkbox"
                                id="select_all"
                                type="checkbox"
                                name=""
                              />
                            </div>
                            <button
                              className=""
                              type="button"
                              id="selectionBtn"
                            >
                              
                              <span id="filterTitle">{(localStorage.getItem('filter_name') !== null) ? (localStorage.getItem('filter_name') == "closed") ? "Resolved" : localStorage.getItem('filter_name') : "All"}</span>
                              <span uk-icon="chevron-down"></span>
                            </button>
                            <div uk-dropdown="mode: click " id="assignFilter">
                              <ul>{this.handleFilter()}</ul>
                            </div>
                          </div>
                          <div className="uk-inline uk-float-right">
                            {/* <div className="select_all"><input onClick={this.onClickCheckbox} className="uk-checkbox" id="select_all" type="checkbox" name="" /></div> */}
                            <button
                              className=""
                              type="button"
                              id="selectionBtn1"
                            >
                              <span id="filterTitle1">{(localStorage.getItem('sub_filter') !== null) ? (localStorage.getItem('sub_filter') !== "all") ? (localStorage.getItem('sub_filter') !== "expire") ? localStorage.getItem('sub_filter')+'ed' : localStorage.getItem('sub_filter')+'d' : localStorage.getItem('sub_filter') : "Filter by"}</span>
                              <span uk-icon="chevron-down"></span>
                            </button>
                            <div uk-dropdown="mode: click" id="assignFilter1">
                              <ul>
                                <li>
                                  <button
                                    class=""
                                    type="button"
                                    onClick={(e) =>
                                      this.SubfilterSelection("all")
                                    }
                                  >
                                    <span class="name">All</span>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    class=""
                                    type="button"
                                    onClick={(e) =>
                                      this.SubfilterSelection("open")
                                    }
                                  >
                                    <span class="name">Opened</span>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    class=""
                                    type="button"
                                    onClick={(e) =>
                                      this.SubfilterSelection("expire")
                                    }
                                  >
                                    <span class="name">Expired</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contacts_list" id="threadList">
                <ul>
                  {/* {(this.state.noEntry && this.state.chatList.isLoaded === false) ? <center>No Record Found</center> :(this.state.chatList.isLoaded && this.state.noEntry === 0) ? this.handleUserList() : <div className="mdl"><div className="mdl_inner"><div className="uk-text-center"><div uk-spinner=""></div></div></div></div>} */}
                  <InfiniteScroll
                    style={{ overflow: "none" }}
                    dataLength={this.state.chatList.list.length} //This is important field to render the next data
                    next={this.loadUserApi}
                    pullDownToRefreshThreshold={50}
                    hasMore={this.state.hasMore}
                    scrollableTarget="threadList"
                    className="conversaionsList"
                    endMessage={<center id="record_not_found"></center>}
                  >
                    {this.state.chatList.isLoaded === true &&
                    this.state.chatList.list.length > 0
                      ? this.handleUserList()
                      : ""}
                  </InfiniteScroll>
                </ul>
              </div>
            </div>
            <div className="chat_box ht_match uk-width-expand spinner">
              <div className="uk-text-center uk-position-center ">
                <span uk-spinner="ratio: 2" />
              </div>
            </div>
            <div
              className="chat_box ht_match uk-width-expand uk-hidden"
              id="chat_box"
            >
              <div className="chat_box_head">
                <div className="uk-grid" uk-grid="">
                  <div className="uk-width-auto">
                    <div className="user_selection">
                      <div className="uk-inline uk-hidden" id="btnAgent">
                        <button className="" type="button">
                          <div className="icon">
                            <img src="" id="profile_pic" />
                          </div>
                          <span id="agentTitle">Select Agent</span>
                          <span uk-icon="chevron-down"></span>
                        </button>
                        <div uk-dropdown="mode: click" id="agentList">
                          <ul></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-expand uk-text-center">
                    <div className="timeExpireBox">
                      <Time />{" "}
                      <div
                        id="liveTimer"
                        uk-countdown="date: 2022-12-30T08:36:57+00:00"
                      >
                        <span className="uk-countdown-hours" />h{" "}
                        <span className="uk-countdown-minutes" />m{" "}
                        <span className="uk-countdown-seconds" />s
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-auto">
                    <div className="RefreshMsg">
                      <button onClick={this.refreshMsg}>
                        <span>
                          <Renew />
                        </span>
                        Refresh
                      </button>
                    </div>
                    <div className="seen_status">
                      <button onClick={this.markResolved}>
                        {" "}
                        <span>
                          <img src="/images/done_all_black_24dp.svg" />
                        </span>{" "}
                        Mark as resolved
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat_date">{/* <p>Today, 13:38</p> */}</div>

              <div className="messages_box">
                <ul className="message_box_list" id="message_box_list">
                  {/* audio start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <audio
                          id="player"
                          src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3"
                          type="audio/mp3"
                          controls
                        ></audio>
                      </div>
                    </div>
                  </li>

                  <div className="dateSpan">8:30 PM</div>
                  {/* audio end */}

                  {/* video start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <video width="320" height="240" controls>
                          <source
                            src="images/ForBiggerFun.mp4"
                            type="video/mp4"
                          />
                          {/* <source src="movie.ogg" type="video/ogg" />  */}
                        </video>
                      </div>
                    </div>
                  </li>
                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <video width="320" height="240" controls>
                          <source
                            src="images/ForBiggerFun.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  </li>
                  {/* video end */}

                  {/* img start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <img src="images/avatar.jpg" width="200" height="200" />
                      </div>
                    </div>
                  </li>

                  {/* img end */}

                  {/* pdf start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text customPdfBtn">
                        <a
                          href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                          download=""
                        >
                          <img src="images/box.svg" uk-svg="" /> Download{" "}
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text customPdfBtn">
                        <a
                          href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                          download
                        >
                          <img src="images/box.svg" uk-svg="" /> Download{" "}
                        </a>
                      </div>
                    </div>
                  </li>

                  {/* pdf end */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <span>👋</span>
                        <span>Get started</span>
                      </div>
                    </div>
                  </li>
                  <li className="prompt_msg">
                    <p className="uk-text-center">
                      Opted-in through widget: 👋 Get Started
                    </p>
                  </li>
                  <li className="support">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <span>
                          We are very sorry, that we could not resolve your
                          issue. Unfortunately, we haveconstantly been looking
                          for solutions and have found none so far.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="prompt_msg">
                    <p className="uk-text-center">
                      Usman Khan attached a note to WhatsApp conversation:
                    </p>
                  </li>
                </ul>
              </div>
              <div class="ModalStyleBtns ii uk-text-center" id="Reinitiate">
                <button
                  class="AddTeamBtn uk-float-none"
                  onClick={this.showReinitiate}
                >
                  Re-Initiate Chat
                </button>
              </div>
              <div className="suggested_msgs" id="suggested_msgs">
                <ul></ul>
              </div>

              <div className="type_box">
                <ul
                  className="uk-subnav "
                  uk-switcher="animation: uk-animation-fade"
                  id="messageTabs"
                >
                  <li data-id="reply" onClick={this.showEmoji}>
                    <a href="#">Reply</a>
                  </li>
                  <li data-id="notes" onClick={this.hideEmoji}>
                    <a href="#">Notes</a>
                  </li>
                </ul>

                <ul className="uk-switcher">
                  <li>
                    <div className="placeholder">
                      <textarea
                        id="txtReply"
                        placeholder="Reply here"
                        onKeyDown={(e) => {
                        if (!e.ctrlKey && e.keyCode == 13){
                          this.sendWhatsApp();
                          e.preventDefault();
                          e.stopPropagation();
                          $('#txtReply').prop("disabled", true); 
                          setTimeout(()=>{
                            $('#txtReply').prop("disabled", false); 
                          },2000) 
                          return false;
                        } else if(e.ctrlKey && e.keyCode == 13){
                          $("#txtReply").val($("#txtReply").val() + "\n");
                        }
                        }}
                      ></textarea>
                    </div>
                  </li>
                  <li>
                    <div className="placeholder ii">
                      <textarea
                        id="txtNotes"
                        placeholder="Leave a note for your team members or reminder for yourself"
                        onKeyDown={(e) => {
                          if (!e.ctrlKey && e.keyCode == 13){
                            this.sendWhatsApp();
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                          } else if(e.ctrlKey && e.keyCode == 13){
                            $("#txtNotes").val($("#txtNotes").val() + "\n");
                          }
                          }}
                      ></textarea>
                    </div>
                  </li>
                </ul>

                <div className="submit_bar">
                  <div className="uk-grid uk-grid-collapse">
                    <div className="uk-width-1-2">
                      <div className="attacments">
                        <ul>
                          <li id="emojiCross" className="uk-hidden">
                            <button className="emojiCross">
                              <Close onClick={this.hideEmojiCross} />
                            </button>
                          </li>
                          <li id="emoji" className="uk-hidden">
                            <Emoji />
                          </li>
                          <li id="emojidiv">
                            <button>
                              <FaceSatisfied onClick={this.emoji} />
                            </button>
                          </li>
                          <li>
                            <div className="js-upload" uk-form-custom="">
                              <input
                                type="file"
                                id="file_image"
                                title="Photos & Videos"
                                onChange={this.onFileChange}
                              />
                              <button type="button" tabIndex="-1">
                                <Image />
                              </button>
                            </div>
                          </li>
                          <li>
                            <div className="js-upload" uk-form-custom="">
                              <input
                                type="file"
                                title="Document"
                                id="file_attach"
                                onChange={this.onFileChange}
                              />
                              <button type="button" tabIndex="-1">
                                <Attachment />
                              </button>
                            </div>
                          </li>
                          {/* <li>
                            <button>
                              <Microphone onClick={this.AddAudioplayer} />
                            </button>
                          </li> */}
                          {/* <li>
                            <button>
                              <Location />
                            </button>
                          </li>
                          <li>
                            <button>
                              <DecisionTree />
                            </button>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className=" action_btns customaction_btns uk-grid uk-grid-small uk-float-right uk-child-width-auto">
                        {/* <div>
                          <button>Send & Close</button>
                        </div> */}
                        <div>
                          <button
                            className="fill"
                            id="sendMessage"
                            onClick={this.sendWhatsApp}
                          >
                            {" "}
                            Send WhatsApp
                          </button>

                          <button
                            className="fill"
                            id="quickReply"
                            onClick={this.showQuickreply}
                          >
                            {" "}
                            Quick Reply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="profile_details ht_match uk-width-1-4  uk-hidden"
              id="profile_details"
            >
              <div className="chat_box_head">
                <div className="mdl">
                  <div className="mdl_inner">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand">
                        <div className="profile_name">
                          <h4>Naveen Asim</h4>
                        </div>
                      </div>

                      <div className="uk-width-auto">
                        <div className="profile_action">
                          <div className="uk-inline">
                            <button className="" type="button">
                              <span uk-icon="more"></span>
                            </button>
                            <div uk-dropdown="mode: click" id="customerOptions">
                              <ul className="action_menu">
                                <li>
                                  <button onClick={this.addNewContact}>
                                    <span className="icon">
                                      <Edit />
                                    </span>{" "}
                                    <span>Edit</span>
                                  </button>
                                </li>
                                {/* <li>
                                  <button>
                                    <span className="icon">
                                      <StopOutline />
                                    </span>
                                    <span>Unsubscribe</span>
                                  </button>
                                </li> */}
                                <li>
                                  <button onClick={this.DownloadProfile}>
                                    <span className="icon">
                                      <Download />
                                    </span>
                                    <span>Download Data</span>
                                  </button>
                                </li>
                              </ul>
                              <hr></hr>
                              <ul className="action_menu">
                                <li className="delete">
                                  <button onClick={this.DeleteContactShow}>
                                    <span className="icon">
                                      <TrashCan />
                                    </span>{" "}
                                    <span>Delete</span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_content">
                <div className="profile_pic">
                  <img src="/images/" alt="" id="customer_profile_pic" />
                </div>
                <div className="contact_details">
                  <ul>
                    <li>
                      <button>
                        {" "}
                        <span>
                          <MobileCheck />
                        </span>{" "}
                        <span id="mobile_no">033565472945</span>
                      </button>
                    </li>
                    {/* <li><button onClick={this.AddUnsubscribe}> <span><CheckmarkOutline /></span> <span>Subscribed</span></button></li> */}
                    <li id="genderLi">
                      {" "}
                      <span>
                        <GenderFemale />
                      </span>{" "}
                      <span id="gender">Female</span>
                    </li>
                    <li>
                      {" "}
                      <span>
                        <Time />
                      </span>{" "}
                      <span id="time">Contact Time: 16:07 (UTC+05)</span>
                    </li>
                    {/* <li class="uk-hidden"> <span><Credentials /></span> <span>5947368681956251</span></li> */}
                  </ul>
                </div>
                {localStorage.getItem("company_id") == "16" || localStorage.getItem("company_id") == "17"? (
                  <div
                    class="uk-button uk-form-select dropdown-container"
                    uk-grid=""
                    data-uk-form-select
                  >
                    <select
                      className="uk-width-1 dropdown-button"
                      onChange={this.onChangeForm}
                      id="my_select"
                    >
                      <option value="" selected disabled>
                        Select Form
                      </option>
                      <option value="Complaint Form">
                        <a onClick={this.addNewFormModal}>Live Ops</a>
                      </option>
                      <option value="Ticket Form">CS Form</option>
                    </select>
                  </div>
                ) : (
                  ""
                )}

                <div className="pause_automation">
                  {/* <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-2-3">
                      <h4>Pause Automation</h4>
                    </div>
                    <div className="uk-width-1-3">
                      <button className="time uk-float-right"><span><Add /></span>30 min</button>
                    </div>
                  </div> */}
                  <div className="button">
                    <button onClick={this.showpause} id="pauseAutomation">
                      <span className="awsome"></span> Resume Automation
                    </button>
                  </div>
                </div>

                <div className="pause_automation not notes-div">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-1-1">
                      <h4>Notes</h4>
                    </div>
                  </div>
                  <div className="notes" id="notes">
                    <button>
                      {" "}
                      <p className="title">Issue can not be resolved</p>{" "}
                      <span className="date">Aug 17, 2022, 17:02</span>
                    </button>
                  </div>
                  {/* <div className="showall">
                    <button id="showAll" onClick={this.showAll}>Show all  <span uk-icon="chevron-down"></span></button>
                    <button id="showLess" className="uk-hidden" onClick={this.showLess}>Show less  <span uk-icon="chevron-up"></span></button>
                  </div> */}
                </div>

                <div className="pause_automation ">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-2-3">
                      <h4>Contact Tags</h4>
                    </div>
                    <div className="uk-width-1-3">
                      <button
                        className="time uk-float-right"
                        onClick={this.addTag}
                      >
                        <span>
                          <Add />
                        </span>
                        Add Tag
                      </button>
                    </div>
                  </div>
                  <div className="tags" id="tags"></div>
                </div>

                <div className="pause_automation ">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-2-3">
                      <h4>Conversation Tags</h4>
                    </div>
                    <div className="uk-width-1-3">
                      <button
                        className="time uk-float-right"
                        onClick={this.addConversationTag}
                      >
                        <span>
                          <Add />
                        </span>
                        Add Tag
                      </button>
                    </div>
                  </div>
                  <div className="tags" id="conversation_tags"></div>
                </div>
                <div
                  id="AddTagsModal"
                  className="AddTeamModal ModalStyle uk-flex-top"
                  uk-modal="esc-close: false; bg-close: false"
                >
                  <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button">
                      {" "}
                      <Close />
                    </button>
                    <h3>Add tag</h3>
                    <div id="errorAlert"></div>
                    <div id="successAlert"></div>
                    <form
                      id="tagform"
                      className="uk-form-stacked"
                      action="javascript:void(0);"
                    >
                      <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="teamName">
                          Select tags
                        </label>

                        {/* <div className="uk-form-controls">
                                  <Select
                                      defaultValue={[options[0], options[1]]}
                                      name="tags"
                                      options={options}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                </div> */}

                        <div className="chipBox">
                          <div className="chipBox CustomChipBox">
                            <Multiselect
                              id="saj"
                              options={this.state.tagList.list}
                              selectedValues={this.state.tags}
                              onSelect={this.onSelectTags}
                              onRemove={this.onRemoveTags}
                              displayValue="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <div className="ModalStyleBtns">
                          <button
                            className="AddTeamBtn"
                            id="addAgent"
                            onClick={this.attachTag}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  id="AddConversationTagsModal"
                  className="AddTeamModal ModalStyle uk-flex-top"
                  uk-modal="esc-close: false; bg-close: false"
                >
                  <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button">
                      {" "}
                      <Close />
                    </button>
                    <h3>Add tag</h3>
                    <div id="errorAlert"></div>
                    <div id="successAlert"></div>
                    <form
                      id="tagform"
                      className="uk-form-stacked"
                      action="javascript:void(0);"
                    >
                      <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="teamName">
                          Select tags
                        </label>

                        {/* <div className="uk-form-controls">
                                  <Select
                                      defaultValue={[options[0], options[1]]}
                                      name="tags"
                                      options={options}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                </div> */}

                        <div className="chipBox">
                          <div className="chipBox CustomChipBox">
                            <Multiselect
                              id="saj"
                              options={this.state.tagList.list}
                              selectedValues={this.state.conversationtags}
                              onSelect={this.onSelectConversationTags}
                              onRemove={this.onRemoveConversationTags}
                              displayValue="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <div className="ModalStyleBtns" id="conversationButton">
                          <button
                            className="AddTeamBtn"
                            id="addAgent"
                            onClick={this.attachConversationTag}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="pause_automation ">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-1-1">
                      <h4>Opted in through</h4>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="tags uk-margin-remove">
                        <button> 👋 Get Started</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="multi_chat_select_prompt ht_match uk-width-expand "
              id="selectDiv"
            >
              <div className="mdl">
                <div className="mdl_inner">
                  <div className="assignment_actions">
                    <img id="chatImage" src="images/chaticon.svg" />
                    <p id="data-error" className="uk-text-center uk-hidden">
                      No Data Found!
                    </p>
                    <ul className="uk-hidden" id="selectOptions">
                      <li>
                        <h5 className="uk-text-center">
                          {" "}
                          <span id="threadCount_span">
                            {this.state.threadCount}
                          </span>{" "}
                          conversations selected
                        </h5>
                      </li>
                      <li>
                        <button
                          className=""
                          id="reopen_conversaton"
                          onClick={this.reopenShow}
                        >
                          <span>
                            <img
                              src="/images/done_all_black_24dp.svg"
                              uk-svg=""
                            />
                          </span>{" "}
                          Reopen Conversations
                        </button>
                      </li>
                      <li>
                        <button
                          className=""
                          id="mark_resolved"
                          onClick={this.addMarkAsRead}
                        >
                          <span>
                            <img
                              src="/images/done_all_black_24dp.svg"
                              uk-svg=""
                            />
                          </span>{" "}
                          Mark as resolved
                        </button>
                      </li>
                      <li>
                        <div className="user_selection" id="user_selection">
                          <div className="uk-inline uk-width-1-1">
                            <button className="" type="button">
                              <span>
                                <UserRole />
                              </span>
                              Assign Conversations
                            </button>
                            <div uk-dropdown="mode: click" id="agentsList">
                              <ul>
                                <li>
                                  <button className="active" type="button">
                                    <div className="icon"></div>
                                    <span className="name">Usman Ramnani</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                                <li>
                                  <button className="" type="button">
                                    <div className="icon"></div>
                                    <span className="name">Yumna Zaidi</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                                <li>
                                  <button className="" type="button">
                                    <div className="icon"></div>
                                    <span className="name">Usman Khan</span>
                                    <span className="uk-icon">
                                      <Checkmark />
                                    </span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button
                          className=""
                          id="user_unassign"
                          onClick={this.unassignShow}
                        >
                          <span>
                            <img src="/images/user--role.svg" uk-svg="" />
                          </span>{" "}
                          Unassign Conversations
                        </button>
                      </li>
                    </ul>
                    <div
                      className="clear_selection uk-text-center uk-hidden"
                      id="selectOptions"
                    >
                      <Link to="#">Clear Selection</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* bulk mark as resolved  */}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Mark as resolved </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>
                  <span id="threadCount_mark_resolved_span">
                    {this.state.threadCount}
                  </span>{" "}
                  conversations will be marked as resolved!
                </b>
              </p>
              <p className="small">
                This action will affect{" "}
                <span id="threadCount_mark_bottom_span">
                  {this.state.threadCount}
                </span>{" "}
                conversations. Are you sure?
              </p>
              <div id="errorAlertAddResolved"></div>
              <div id="successAlertAddResolved"></div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.cancelBulkResolved}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    onClick={this.bulkMarkResolved}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* bulk mark as resolved  */}
        <div
          id="ReopenChat"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>
                  <span id="threadCount_reopen_span">
                    {this.state.threadCount}
                  </span>{" "}
                  conversations will be reopened!
                </b>
              </p>
              <p className="small">
                This action will affect{" "}
                <span id="threadCount_reopen_span">
                  {this.state.threadCount}
                </span>{" "}
                conversations. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.cancelReopen}>
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick={this.reopenChat}>
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Bulk assign popup */}

        <div
          id="bulkAssign"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Assign Conversations</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>
                  <span id="conversation_top_count"></span> conversations will
                  be assigned to <span id="agentsName"></span>
                </b>
              </p>
              <p className="small">
                This action will affect{" "}
                <span id="conversation_bottom_count"></span> conversation. Are
                you sure?
              </p>
              <p className="uk-hidden" id="agents_id"></p>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.cancelBulkAssign}>
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick={this.bulkAssignClick}>
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* mark as resolved popup */}
        <div
          id="MarkResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Mark as resolved </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>This conversations will be marked as resolved!</b>
              </p>
              <p className="small">
                This action will affect 1 conversation. Are you sure?
              </p>
              <input
                className="uk-input"
                placeholder="Enter Comment"
                id="resolve_comment"
                name="resolve_comment"
              />
              <div id="errorAlertresolved"></div>
              <div id="successAlertresolved"></div>
              <div id="complaintNotExistError"></div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.markCancelClick}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="resolveBtn"
                    onClick={this.markConfirmClick}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* pause automation popup */}
        <div
          id="AumationPopup"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>This conversation will be {this.state.automationText} !</b>
              </p>
              <p className="small">
                This action will affect 1 conversation. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.pauseCancelClick}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    onClick={this.pauseConfirmClick}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* unassign popup */}
        <div
          id="unAssign"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Unassign Conversations </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>
                  <span id="threadCount_unassign_span">
                    {this.state.threadCount}
                  </span>{" "}
                  conversations will be unassigned
                </b>
              </p>
              <p className="small">
                This action will affect{" "}
                <span id="threadCount_unassign_bottom_span">
                  {this.state.threadCount}
                </span>{" "}
                conversation. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.unassignHide}>
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick={this.unAssignClick}>
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* markResolveError popup */}
        <div
          id="markResolveError"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Error </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p style={{ color: "red" }}>
                <b>
                  Please add customer tag, before resolved this conversation.
                </b>
              </p>
              {/* <p className="small">This action will affect <span id="threadCount_unassign_bottom_span">{ this.state.threadCount }</span> conversation. Are you sure?</p> */}

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                  <button
                    className="AddTeamBtn"
                    onClick={this.confirmResolveError}
                  >
                    Ok
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* reinitiate popup */}
        <div
          id="reinitiate"
          // className="AlertPOPUP ModalStyle uk-flex-top"
          className="quickModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Re Initiate Chat</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div id="errorAlert2"></div>
            <div className="uk-grid" uk-grid="">
              <div className="uk-width-1-2@m uk-width-1-1@s">
                <div className="resolved_content">
                  <select
                    class="uk-select"
                    id="suggested_msgs_pop"
                    name="suggested_msgs_pop"
                    onChange={this.onMessageSelction}
                  >
                    <option>Select Message</option>
                  </select>
                  {this.state.typeExist !== "" ? (
                    <>
                      <div class="js-upload uk-placeholder uk-text-center uk-margin-remove-bottom">
                        <span uk-icon="icon: cloud-upload"></span>
                        <div uk-form-custom>
                          <input
                            type="file"
                            name="media"
                            id="myfile"
                            onChange={this.handleFile}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        class="uk-input uk-margin-top"
                        placeholder="Enter File Name"
                        id="fileName"
                        name="fileName"
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.paramArrayForQuickMessage.length == 0
                    ? ""
                    : this.state?.paramArrayForQuickMessage?.map(
                        (val, index) => {
                          return (
                            <div className="uk-margin" key={index}>
                              <label
                                className="uk-form-label"
                                htmlFor="template"
                              >
                                Param {index + 1}
                              </label>
                              <input
                                type="text"
                                class="uk-input"
                                placeholder={`Enter Param ${index + 1}`}
                                id={`param${index}business`}
                                name={`param${index}`}
                                onChange={(e) =>
                                  this.handleParamChangeForQuickMessage(
                                    e,
                                    index
                                  )
                                }
                              />
                            </div>
                          );
                        }
                      )}

                  <div id="paramFields"></div>
                  <div id="errorAlertQuick"></div>
                  <div id="successAlertQuick"></div>

                  <form
                    className="uk-form-stacked uk-margin-medium-top"
                    action="javascript:void(0);"
                  >
                    <div className="ModalStyleBtns uk-float-right">
                      {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                      <button
                        className="AddTeamBtn"
                        id="AddTeamBtn"
                        onClick={this.confirmReinitaite}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="uk-width-1-2@m uk-width-1-1@s">
                <div className="msg_preview">
                  <h2>Preview</h2>
                  <div className="msg_box">
                    <h6>{this.state.previewHeader}</h6>
                    <p>{this.state.messageArrayForQuickMessage.join(" ")}</p>
                    <p>{this.state.previewFooter}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ShowAllTag popup */}
        <div
          id="ShowAllTag"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Tag List</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="spinner uk-hidden" id="tagSpinner">
              <div className="uk-text-center uk-position-center ">
                <span uk-spinner="ratio: 1" />
              </div>
            </div>
            <div id="errorAlert2"></div>
            <div className="resolved_content">
              <div className="tags" id="tagsList"></div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                  <button className="AddTeamBtn" onClick={this.hideTagList}>
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* ShowAllConversationTag popup */}
        <div
          id="ShowAllConversationTag"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Tag List</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="spinner uk-hidden" id="tagSpinner">
              <div className="uk-text-center uk-position-center ">
                <span uk-spinner="ratio: 1" />
              </div>
            </div>
            <div id="errorAlert2"></div>
            <div className="resolved_content">
              <div className="tags" id="conversationtagsList"></div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                  <button
                    className="AddTeamBtn"
                    onClick={this.hideConversationTagList}
                  >
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Delete Contact */}
        <div
          id="DeleteContact"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Delete Profile </span>
              <span className="uk-inline uk-float-right">
                <TrashCan />
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>This profile will be deleted</b>
              </p>
              <p className="small">
                This action will affect 1 profile. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.DeleteContactHide}>
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick={this.DeleteProfile}>
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          id="AddUnassigned"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Unassign Conversations </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/warning--alt--filled.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>2 conversations will be marked as resolved!</b>
              </p>
              <p className="small">
                This action will affect 2 conversations. Are you sure?
              </p>
              <div className="uk-margin">
                <label className="uk-form-label">Agents </label>
                <div className="chipBox">
                  <Select
                    defaultValue={[options[0], options[1]]}
                    isMulti
                    name="agents"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick="">
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick="">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* contact edit start */}

        <div
          id="AddNewContact"
          className="AddNewContact  ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit Contact</h3>
            <p>It is mandatory to fill the Phone number or Email</p>
            <div id="errorAlert2"></div>
            <div id="successAlert2"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-grid uk-child-width-1-2 uk-grid-small">
                <div className="">
                  <label className="uk-form-label" htmlFor="">
                    First Name
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      placeholder="Enter first name"
                      id="first_name"
                      value={this.state.first_name}
                      onChange={this.handleFirstNameChange}
                    />
                  </div>
                </div>
                <div className="">
                  <label className="uk-form-label" htmlFor="">
                    Last Name
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      placeholder="Enter last name"
                      id="last_name"
                      value={this.state.last_name}
                      onChange={this.handleLastNameChange}
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Phone Number
                </label>
                <div className="uk-form-controls">
                  <PhoneInput
                    international
                    defaultCountry="PK"
                    id="phon"
                    className="uk-input"
                    placeholder="Enter phone number"
                    value={this.state.phoneintlnumber}
                    onChange={this.handleInternationalnumber}
                  />
                </div>
              </div>

              {/* <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-4">
                  <label className="uk-form-label " htmlFor="">
                    Phone Number
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select">
                      <option>+92</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-expand">
                  <label
                    className="uk-form-label uk-margin-top"
                    htmlFor=""
                  ></label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type="number"
                      disabled
                      placeholder="Enter phone number"
                      value={this.state.phone}
                    />
                  </div>
                </div>
              </div> */}

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Addess
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="address"
                    name="address"
                    type="text"
                    placeholder="Address"
                    value={this.state.address}
                    onChange={this.handleAddressChange}
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="email"
                    name="Email"
                    type="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Gender
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="customer_gender"
                    value={this.state.gender}
                    onChange={this.handleGenderChange}
                  >
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <label>
                  I confirm that we have obtained consent to send message, email
                  from contact being created with regulations and Telenor{" "}
                  <Link to="javascript"> Terms of Service</Link>
                </label>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeContactModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="Create"
                    onClick={this.saveCustomerDetail}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* contact edit end */}

        <div
          id="QuickReply"
          className="disconectAcount ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Quick Replies</h3>
            <div id="quickReplyMsg">
              <ul className="uk-list scrollList">{this.handleQuickReply()}</ul>
            </div>
            {/* <ul className="uk-list scrollList">
                            <li><button>1</button></li>
                            <li><button>2</button></li>
                            <li><button>3</button></li>
                            <li><button>4</button></li>
                            <li><button>5</button></li>
                            <li><button>6</button></li> 
                          </ul>
                      */}
            <div className="uk-margin">
              <div className="ModalStyleBtns">
                <button
                  className="CnclBtn nomargin"
                  onClick={this.closeQuickreply}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* audio player start */}
        <div
          id="audioplayer"
          className="disconectAcount ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-animation-slide-bottom uk-text-center">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>

            <main class="container">
              <h1 style={{ margin: "0px" }} id="output">
                00:00
              </h1>
            </main>

            <p style={{ margin: "0px" }}>
              <strong className="uk-hidden blink" id="recordingText">
                Recording....
              </strong>
            </p>
            <ol id="recordingsList"></ol>

            <section class="controls">
              <div id="controls">
                <button
                  class="btn btn-default"
                  onClick={this.startRecorder}
                  id="recordButton"
                >
                  Start
                </button>
                <button
                  class="btn btn-default"
                  onClick={this.pauseRecorder}
                  id="pauseButton"
                >
                  Pause
                </button>
                <button
                  class="btn btn-default"
                  onClick={this.stopRecorder}
                  id="stopButton"
                >
                  Send
                </button>
              </div>

              <div id="errorAlertAudioPlayer"></div>
              {/* <button class="controls__button" id="resetButton"><i class="fa-solid fa-fw fa-arrow-rotate-right"></i></button>
                        <button class="controls__button" id="startButton"><i class="fa-solid fa-fw fa-play"></i></button>
                        <button class="controls__button" id="lapButton"><i class="fa-solid fa-fw fa-flag"></i></button> */}
            </section>
            <div className="spinner-recording uk-hidden">
              <div className="mainspiner">
                <span uk-spinner="ratio: 1" />
              </div>
            </div>
          </div>
        </div>
        {/* audio player end */}

        {/* Unsubscribe popup start */}
        <div
          id="Unsubscribe"
          className="disconectAcount ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              Unsubscribe from Account{" "}
              <span className="uk-inline uk-float-right">
                <img src="/images/warning--alt--filled.png" alt="" />
              </span>
            </h3>
            <p className="">
              <b>Are you sure to unsubscribe 2,222 contacts.</b>
            </p>
            <p>
              <span>You will not be able to subscribe them back.</span>
            </p>
            <p>
              <span>
                These users will not be able to receive messages until they type
                "start" again or click the button with the "Subscribe To Bot"
                action specified!
              </span>
            </p>
            <div id="errorAlert"></div>
            <div id="successAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id=""
                    name=""
                    type="text"
                    placeholder="Confirm by typing unsubscribe here"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeUnsubscribe}>
                    Cancel
                  </button>
                  <button className="confirm">Unsubscribe</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Unsubscribe popup end */}

        {/* form modal */}
        <div
          id="formModal"
          className="ModalStyle uk-flex-top uk-modal-container"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default"
              type="button"
              onClick={this.handleFormClose}
            >
              <Close />
            </button>
            <h3>Live Ops Form</h3>
            <div id="errorAlertComplaint"></div>
            <div id="errorTitle"></div>
            <div id="errorOrder"></div>
            <div id="errorDescription"></div>

            {/* {this.state.conversation_selected_id == "" ? (
              this.handleFormLiveOps()
            ) : ( */}
              <form
                id="complaintForm"
                action="javascript:void(0);"
                // onSubmit={this.handleSubmit}
              >
                {this.state?.formItems?.map((val, index) => {
                  return (
                    <div uk-grid="" key={index}>
                      <input name='conversationid' id="conversation_input_field" value={localStorage.getItem('conversation_id')}/>
                      <div className="uk-width-1-4">
                        <label
                          className="uk-form-label"
                          for="whatsapp_escalated"
                        >
                          WhatsApp Escalated{" "}
                          <span className="form-required-field-sign">*</span>
                        </label>
                        <div class=" uk-form-select" data-uk-form-select>
                          <select
                            name="whatsapp_escalated"
                            className="select-box"
                            id="whatsapp_escalated"
                            onChange={(event) =>
                              this.handleFormChange(
                                index,
                                event,
                                "whatsapp_escalated"
                              )
                            }
                          >
                            <option value="" selected>
                              Select WhatsApp Esclated
                            </option>
                            <option value="Escalated">Escalated</option>
                            <option value="Not Escalated">Not Escalated</option>
                          </select>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <label className="uk-form-label" for="order_status">
                          Order Status{" "}
                          <span className="form-required-field-sign">*</span>
                        </label>
                        <div class="uk-form-select" data-uk-form-select>
                          <select
                            name="order_status"
                            id="order_status"
                            className="select-box"
                            onChange={(event) =>
                              this.handleFormChange(
                                index,
                                event,
                                "order_status"
                              )
                            }
                          >
                            <option value="" selected>
                              Select Order Status
                            </option>
                            <option value="Delivery">Delivery</option>
                            <option value="Cancellation">Cancellation</option>
                            <option value="On-Hold">onHold</option>
                            <option value="Partial Delivery">
                              Partial delivery
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <label className="uk-form-label" for="owner">
                          Owner{" "}
                          <span className="form-required-field-sign">*</span>
                        </label>
                        <div class=" uk-form-select" data-uk-form-select>
                          <select
                            name="owner"
                            // id="owner"
                            id={`owner${index}`}
                            className='select-box'
                            onChange={(event) =>
                              this.handleFormChange(index, event, "owner")
                            }
                          >
                            <option value="" selected>
                              Select Owner
                            </option>
                            
                          </select>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <label className="uk-form-label">Title </label>
                        <div>
                          <input
                            type="text"
                            className="dynamic-form-input"
                            name="title"
                            // required
                            placeholder="Enter Title"
                            onChange={(event) =>
                              this.handleFormChange(index, event, "title")
                            }
                          />
                        </div>
                      </div>

                      <div className="uk-width-1-4">
                        <label className="uk-form-label" for="duplicate_conv">
                          Duplicate Conversation{" "}
                          <span className="form-required-field-sign">*</span>
                        </label>
                        <div class=" uk-form-select" data-uk-form-select>
                          <select
                            name="duplicate_conv"
                            id="duplicate_conv"
                            className="select-box"
                            onChange={(event) =>
                              this.handleFormChange(
                                index,
                                event,
                                "duplicate_conv"
                              )
                            }
                          >
                            <option value="" selected>
                              Select Duplicate Conversation
                            </option>

                            <option value="yes">yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <label className="uk-form-label">
                          Order Id{" "}
                          <span className="form-required-field-sign">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            name="order_id"
                            className="dynamic-form-input"
                            required
                            placeholder="Enter Order Id"
                            onChange={(event) =>
                              this.handleFormChange(index, event, "order_id")
                            }
                          />
                          <div id={`order_id${index}`}></div>
                        </div>
                      </div>
                      <div className="uk-width-1-4">
                        <label className="uk-form-label">
                          Reason{" "}
                          <span className="form-required-field-sign">*</span>
                        </label>
                        <div>
                          <select
                            name="description"
                            id={`description${index}`}
                            className='select-box'
                            onChange={(event) =>
                              this.handleFormChange(index, event, "description")
                            }
                          >
                            <option value="" selected>
                              Select Reason
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="uk-margin-medium-small uk-text-right">
                  <button className="add-btn" onClick={this.addFormFields}>
                    <span className="uk-padding-small-right">
                      <Add />
                    </span>
                    Add
                  </button>
                  {this.state.formItems.length !== 1 && (
                    <button
                      className="remove-btn"
                      onClick={this.removeFormFields}
                    >
                      <span className="uk-padding-small-right">
                        <SubtractAlt />
                      </span>
                      Remove
                    </button>
                  )}
                </div>
                <div class="uk-margin">
                  <div class="ModalStyleBtns">
                    <button
                      class="AddTeamBtn"
                      id="complaintSaveBtn"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            {/* )} */}
          </div>
        </div>
        {/* end form modal  */}

        {/* ticket form modal  */}
        <div
          id="ticketFormModal"
          className="ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default"
              type="button"
              onClick={this.handleFormClose}
            >
              <Close />
            </button>
            <h3>CS Form</h3>

            <form
              className="uk-form-stacked"
              id="ticket-form"
              onSubmit={this.handleTicketForm}
            >
              <div className="uk-margin">
                <label className="uk-form-label" for="title">
                  Title
                </label>
                <div>
                  <input
                    type="text"
                    id="title"
                    className="ticket-form-input"
                    name="title"
                    placeholder="Enter Title"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" for="name">
                  Customer Name{" "}
                  <span className="form-required-field-sign">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    id="name"
                    name="customer_name"
                    className="ticket-form-input"
                    placeholder="Enter Customer Name"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" for="status">
                  Status <span className="form-required-field-sign">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    id="status"
                    name="status"
                    className="ticket-form-input"
                    placeholder="Enter Status"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" for="order">
                  Order ID <span className="form-required-field-sign">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    id="order"
                    name="order_id"
                    className="ticket-form-input"
                    placeholder="Enter Order Id"
                    onChange={(e) => this.handleOrderId(e)}
                  />
                  <div id="order_id"></div>
                </div>
              </div>
              <div className="uk-margin">
                <label
                  className="uk-form-label"
                  for="description"
                  name="description"
                >
                  Description{" "}
                  <span className="form-required-field-sign">*</span>
                </label>
                <div>
                  <textarea
                    type="text"
                    id="description"
                    className="ticket-form-input"
                    name="description"
                    placeholder="Enter Description"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" for="email">
                  Email <span className="form-required-field-sign">*</span>
                </label>
                <div>
                  <input
                    type="email"
                    id="email"
                    className="ticket-form-input"
                    name="email"
                    placeholder="Enter Email"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" for="phone">
                  Phone Number{" "}
                  <span className="form-required-field-sign">*</span>
                </label>
                <div>
                  <input
                    type="number"
                    id="phone"
                    className="ticket-form-input"
                    name="phone"
                    placeholder="Enter Phone"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" for="phone">
                  Media <span className="form-required-field-sign">*</span>
                </label>
                <div class="js-upload uk-placeholder uk-text-center">
                  <span uk-icon="icon: cloud-upload"></span>
                  <div uk-form-custom>
                    <input
                      type="file"
                      name="media"
                      id="myfile"
                      onChange={this.handleFile}
                    />
                  </div>
                </div>
              </div>
              <div id="errorAlertTicket"></div>
              <div class="uk-margin">
                <div class="ModalStyleBtns">
                  <button class="AddTeamBtn" id="ticketSaveBtn">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* end ticket form modal  */}

        {/* loader form modal  */}
        <div id="LoaderModal" className="ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default"
              type="button"
              onClick={this.closeLoaderModal}
            >
              <Close />
            </button>
            <div class="container">
              <p>Processing...</p>
              <span class="uk-margin-small-right" uk-spinner="ratio: 3"></span>
              {/* <div class="loader"></div> */}
            </div>
          </div>
        </div>
        {/* end loader form modal  */}

        {/* Error uploading Document start */}
        <div id="errorDocument" className="ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default"
              type="button"
              onClick={this.closeErrorDocument}
            >
              <Close />
            </button>
            <div class="container">
              <p style={{color : 'red'}}>File size exceeds the maximum limit is 10MB</p>
            </div>
          </div>
        </div>
        {/* Error uploading Document end */}


        {/* location uploading Document start */}
        <div id="location" className="ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default"
              type="button"
              onClick={this.closeLocation}
            >
              <Close />
            </button>
            <div class="container" id="locationIframe">
              {/* <iframe width="560" height="315" src="https://www.google.com/maps?q=33.711900,73.057800"></iframe> */}
            </div>
          </div>
        </div>
        {/* Error uploading Document end */}




      </Content>
    );
  }
}

export default LiveChat;
