import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import axios from 'axios';
import Constant from "../../App/Constant";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  let dummyData = {

    labels: [] ,
    datasets: [
      // {
      //   label: 'All Chats',
      //   data: [],
      //   backgroundColor: 'rgb(220, 220, 220)',
      // },
      {
        label: 'Open Chats',
        data:[],
        backgroundColor: 'rgb(184, 203,252)',
      },
      {
        label: 'Resolved Chats',
        data: [],
        backgroundColor: 'rgb(75, 103, 238)',
      },
    ],
  
  }

  export const MonthTickets = ({stateTo,stateFrom}) => {

    
    const [graphData, setGraphData] = useState(dummyData);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

      formValidation();
  },  [stateTo,stateFrom]);

  const formValidation = async () => {
        
    axios({
        method: 'POST',
        url: Constant.url+"month-wise_ticket-statuses",
        data: {company_id:localStorage.getItem('company_id'),stateTo:stateTo,stateFrom:stateFrom,channel_type:localStorage.getItem("channeltype")}  
    }).then(result =>{



      setIsLoaded(true)
      var stats = result.data.data;
      setGraphData({
        labels : stats.month,
        datasets: [
          // {
          //   label: 'All Chats',
          //   data: stats.open_ticket,
          //   backgroundColor: 'rgb(220, 220, 220)',
          // },
          {
            label: 'Open Chats',
            data: stats.closed_by_bot,
            backgroundColor: '#527AAD',
          },
          {
            label: 'Resolved Chats',
            data: stats.closed_by_agent,
            backgroundColor: '#D1E8E0',
          },
        ],

      })

      // setTimeout(() => {formValidation()}, 3000);
          
    }).catch(err => {

      // console.log(err)
            
    });


}

    const options = {
      plugins: {
        legend: {
          position: 'bottom'
        },
        title: {
          display: false,
          // text: 'Chart.js Bar Chart - Stacked',
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };
// {console.log(graphData,'graphData')}
    return <Bar options={options} data={graphData} />;

  }







