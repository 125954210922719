import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import {Content} from '@adobe/react-spectrum';
import {Picker, Item, Section} from '@adobe/react-spectrum';
import PageActionBar from "../../Sections/PageActionBar";
import { CaretDown, Image, CloudUpload, Add, Edit, Document,DocumentImport,TrashCan, Information, OverflowMenuVertical, InProgress, CheckmarkOutline, ViewFilled,FaceSatisfied, ReplyAll, Send, NotSent,Close, Checkmark, NonCertified, Download } from '@carbon/icons-react';
import Constant from "../../../App/Constant";
import axios from 'axios';
import {DatePicker, TimeField} from '@adobe/react-spectrum'
import ReactPaginate from 'react-paginate';
import $ from 'jquery';


class BroadcastingDetail extends React.Component {    
    constructor(){
        super();   
        this.getTemplate = this.getTemplate.bind(this);
        this.handleTemplate = this.handleTemplate.bind(this);
        this.handleSegment = this.handleSegment.bind(this);
        this.callApi = this.callApi.bind(this);
        this.callScheduledApi = this.callScheduledApi.bind(this);
        this.callHistoryApi = this.callHistoryApi.bind(this);
        this.handleTable = this.handleTable.bind(this);
        this.handleHistoryTable = this.handleHistoryTable.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.importTempList = this.importTempList.bind(this);
        this.handleImportTemplate = this.handleImportTemplate.bind(this);    
        this.callPackageApi = this.callPackageApi.bind(this);
        this.callTemplatesApi = this.callTemplatesApi.bind(this);
        this.onEditTemplateChange = this.onEditTemplateChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleBroadcastClick = this.handleBroadcastClick.bind(this);
        this.handleLogsClick = this.handleLogsClick.bind(this);
        this.handleScheduleClick = this.handleScheduleClick.bind(this);
        this.PendinghandlePageClick = this.PendinghandlePageClick.bind(this);
        this.callPendingTemplatesApi = this.callPendingTemplatesApi.bind(this);


        this.callLogsApi = this.callLogsApi.bind(this);
        this.ApihandlePageClick = this.ApihandlePageClick.bind(this);

    
        this.state = {  
            uploadfiles : "" ,
            dataList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            }, 
            historyDataList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            templatesDataList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },             
            pendingtemplatesDataList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },  
            apiLogsDataList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },   
            SceduledDataList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },        
            templateList :{
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            segmentList :{
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            categoryList :{
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            languagesList :{
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },            
            importTempList :{
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            headerContent : "Header",
            bodyContent : "body",
            footerContent : "footer",
            buttonOneContent : "",
            buttonTwoContent : "",
            buttonThreeContent : "",  
            buttonFourContent : "",
            stats:{
                sent:0,
                delivered:0,
                read:0,
                replied:0,
                sending:0
            },
            previewHeader : "",
            previewFooter : "",
            previewBody : "",   
            previewButtonOne : "",
            previewButtonTwo : "",       
            previewButtonThree : "",
            previewImage:"",
            previewVideo:"",
            broadcastEditID:"",
            checkAll : false,
            default:"no",
            is_expired: 0,
            rowsCountTemplate : 0,
            rowsCountBroadcast : 0,
            rowsCountLogs : 0,
            rowsCountSchedule : 0,
            tempDeleteId : 0,
            tempAppId: 0,
            rowsCountPendingTemplate : 0,
            rowsCountapiLogsDataList: 0,
            broadcastID : 0,
        }  
    }

    componentDidMount(){    
        var classRef = this;    
        var type="all"
        var pageCount = 1
        
        this.getTemplate();
        this.getSegment();
        this.callApi(1);
        this.getCategory();
        this.getLanguages();
        this.callScheduledApi();
        this.callHistoryApi(pageCount,type);
        this.importTempList();
        $('#logs').removeClass('uk-active');
        $('#logsData').removeClass('uk-active');
        this.callTemplatesApi(1);
        this.callPendingTemplatesApi(1);
        this.callLogsApi(1);


        // setInterval(function(){
        //     if(classRef.state.default == "no"){      
        //         classRef.callPackageApi();
        //     }
        //   },3000)


           if(localStorage.getItem('loginStatus')== 1){
            let screens = localStorage.getItem('rolesceens').split(',');
            var checking = screens.includes("5");
             if(checking == true){
    
                }else{
                    window.location.href = "/"; 
                }
            }
            else{
                window.location.href = "/"; 
            }
     
    }    

    confirmReinitaite = () =>{
        let template_id  = $("#suggested_msgs_pop").find('option:selected').attr('id');
        template_id = template_id.split("_")[3];
        let phone_no = $("#phone_no").val();
        let company_id = localStorage.getItem('company_id');
        let company_msisdn = localStorage.getItem('company_msisdn');
        if(phone_no == ""){
            $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please enter number first!</p></div>');
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},2000);
            return;
        }
        if(template_id == undefined) {
            $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Please select message first!</p></div>');
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},2000);
            return ;
        }

        let reInitiateParams = new FormData();
        reInitiateParams.append( 'business_number', company_msisdn );
        reInitiateParams.append( 'company_id', company_id );
        reInitiateParams.append( 'template_id', template_id );
        reInitiateParams.append( 'msisdn', phone_no );
        axios({
        method: 'POST',
        url: Constant.url + "quick-message",
        data : reInitiateParams
        }).then(function (result) {
        if (result.data.status === true) {
            $("#successAlertQuick").append('<div id="msg-dialog" class="uk-alert-success uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>Message sent successfully.</p></div>');
            setTimeout(function(){
            UIkit.alert('#msg-dialog').close();
            $("#reinitiate").removeClass('uk-flex uk-open');
            },2000);
        } else {
            $("#errorAlertQuick").append('<div id="msg-dialog" class="uk-alert-danger uk-margin" uk-alert><a class="uk-alert-close" uk-close></a><p>An error occurred!</p></div>');
            setTimeout(function(){
            UIkit.alert('#msg-dialog').close();
            },2000);
        }
        })
      }
        
    showReinitiate = () =>{
        let company_id = localStorage.getItem('company_id');
        let messageParams = new FormData();
        messageParams.append( 'company_id', company_id );
        
        axios({
        method: 'POST',
        url: Constant.url + "templates",
        data: messageParams,
        }).then(function (response) {
        if (response.data.status === true) {
            $('#suggested_msgs_pop').empty();
            $("#suggested_msgs_pop").append("<option id='0'>Select Message</option>");
            for(let i = 0; i < response.data.data.length; i++){
              // $("#suggested_msgs_pop ul").append("<li id='suggest_msg_"+response.data.data[i].id+"' onclick='sendMessage("+response.data.data[i].id+")'><button id='suggest_msg_"+response.data.data[i].id+"'>"+response.data.data[i].name+"</button></li>");
              $("#suggested_msgs_pop").append("<option id='suggest_msg_pop_"+response.data.data[i].id+"'>"+response.data.data[i].name+"</option>");
            }
        }
        })
        $("#reinitiate").addClass('uk-flex uk-open');
    }

    confirmCancelBroadcast = () => {
        let classRef = this;
        let company_id = localStorage.getItem('company_id');
        let cancelParams = new FormData();
        cancelParams.append( 'company_id', company_id );
        cancelParams.append( 'broadcast_id', classRef.state.broadcastID );
        axios({
        method: 'POST',
        url: Constant.url + "cancel-broadcast",
        data: cancelParams,
        }).then(function (response) {
            if (response.data.status === true) {
                $("#broadcast_"+classRef.state.broadcastID+" td button:eq(0)").text("Cancelled");
                $("#cancelBroadcastPopup").removeClass('uk-flex uk-open');
            } else {
                $("#cancelBroadcastPopup").removeClass('uk-flex uk-open');
            }
        });
    }

    callPackageApi = () => {

        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "package-stats",
            data: { company_id: localStorage.getItem('company_id') }
        }).then(function (result) {
            if (result.data.status === true) {
                var package_stats = result.data.data;
                classRef.setState({
                    is_expired: package_stats.is_expired,
                })
    
            }
        })
      
      }

    callApi = (pageCount) => {
        let classRef = this;
        let urlname = window.location.pathname.split("/")[2];
        axios({
            method: 'POST',
            url: Constant.url + "broadcast-details",
            data: { company_id: localStorage.getItem('company_id'),broadcast_id: urlname }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    dataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountBroadcast: result.data.rowscount,
                    
                })
            }else{
                classRef.setState({
                    dataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountBroadcast: result.data.rowscount,
                })
            }
        })
    }

    BroadcastSorting = (pageCount) => {
        let classRef = this;
        let urlname = window.location.pathname.split("/")[2];
        let sortingValue = $("#BroadcastSortingBtn").val();
        sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
        axios({
            method: 'POST',
            url: Constant.url + "broadcast-details",
            data: { company_id: localStorage.getItem('company_id'),page: pageCount, limit : sortingValue,broadcast_id: urlname }
        }).then(function (result) {
            if (result.data.status === true) {

                classRef.setState({
                    dataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountBroadcast: result.data.rowscount,
                    
                })

              
            }else{

                classRef.setState({
                    dataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountBroadcast: result.data.rowscount,
                })
              
            }
        })
    }

    callScheduledApi = () => {
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "broadcasts",
            data: { company_id: localStorage.getItem('company_id') ,status : 1}
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    SceduledDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data.list
                    },
                    rowsCountSchedule : result.data.rowscount
                })
            }else{
                classRef.setState({
                    SceduledDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountSchedule : result.data.rowscount
                })
            
            }
        })
    }  
    
    ScheduleSorting = () => {
        let classRef = this;
        let sortingValue = $("#ScheduleSortingBtn").val();
        sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
        axios({
            method: 'POST',
            url: Constant.url + "broadcasts",
            data: { company_id: localStorage.getItem('company_id') ,status : 1, page: 1, limit : sortingValue}
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    SceduledDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data.list
                    },
                    rowsCountSchedule : result.data.rowscount
                })
            }else{
                classRef.setState({
                    SceduledDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountSchedule : result.data.rowscount
                })
            
            }
        })
    }  
    TemplateSorting = () =>{
        let classRef = this;
        let sortingValue = $("#TemplateSortingBtn").val();
        axios({
            method: 'POST',
            url: Constant.url + "templates",
            data: { 
                company_id: localStorage.getItem('company_id'),
                page: 1,
                limit : sortingValue
            }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    templatesDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountTemplate : result.data.rowscount
                })
            }else{
                classRef.setState({
                    templatesDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountTemplate : result.data.rowscount
                })
            
            }
        })
    }


    PendingTemplateSorting = () =>{
        let classRef = this;
        let sortingValue = $("#PendingTemplateSortingBtn").val();
        axios({
            method: 'POST',
            url: Constant.url + "pending-templates",
            data: { 
                company_id: localStorage.getItem('company_id'),
                page: 1,
                limit : sortingValue
            }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    pendingtemplatesDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountPendingTemplate : result.data.rowscount
                })
            }else{
                classRef.setState({
                    pendingtemplatesDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountPendingTemplate : result.data.rowscount
                })
            
            }
        })
    }


    ApiLogsSorting = () =>{
        let classRef = this;
        let sortingValue = $("#ApiSortingBtn").val();
        axios({
            method: 'POST',
            url: Constant.url + "broadcast_api_list",
            data: { 
                company_id: localStorage.getItem('company_id'),
                page: 1,
                limit : sortingValue
            }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    apiLogsDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountapiLogsDataList : result.data.rowscount
                })
            }else{
                classRef.setState({
                    apiLogsDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountapiLogsDataList : result.data.rowscount
                })
            
            }
        })
    }

    callHistoryApi = (pageCount,type) => {
        let classRef = this;
        var page = pageCount;
        var type = type;


        axios({
            method: 'POST',
            url: Constant.url + "broadcast-history",
            data: { company_id: localStorage.getItem('company_id'), page: pageCount,type: type}
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    historyDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountLogs : result.data.rowscount
                })
            }else{
                classRef.setState({
                    historyDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountLogs : result.data.rowscount
                })
            
            }
        })
    }
    
    LogsSorting = (pageCount) => {
        let classRef = this;
        let sortingValue = $("#LogsSortingBtn").val();
        sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
        axios({
            method: 'POST',
            url: Constant.url + "broadcast-history",
            data: { company_id: localStorage.getItem('company_id'), page: pageCount, limit : sortingValue}
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    historyDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountLogs : result.data.rowscount
                })
            }else{
                classRef.setState({
                    historyDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountLogs : result.data.rowscount
                })
            
            }
        })
    }
    callTemplatesApi = (pageCount) => {
        let classRef = this;
        let sortingValue = $("#TemplateSortingBtn").val();
        sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
        axios({
            method: 'POST',
            url: Constant.url + "templates",
            data: { 
                company_id: localStorage.getItem('company_id'),
                page: pageCount,
                limit: sortingValue
            }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    templatesDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountTemplate : result.data.rowscount
                })
            }else{
                classRef.setState({
                    templatesDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountTemplate : result.data.rowscount
                })
            
            }
        })
    }       
    
    
    callPendingTemplatesApi = (pageCount) => {
        let classRef = this;
        let sortingValue = $("#PendingTemplateSortingBtn").val();
        sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
        axios({
            method: 'POST',
            url: Constant.url + "pending-templates",
            data: { 
                company_id: localStorage.getItem('company_id'),
                page: pageCount,
                limit: sortingValue
            }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    pendingtemplatesDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountPendingTemplate : result.data.rowscount
                })
            }else{
                classRef.setState({
                    pendingtemplatesDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountPendingTemplate : result.data.rowscount
                })
            
            }
        })
    }    


        
    callLogsApi = (pageCount) => {
        let classRef = this;
        let sortingValue = $("#ApiSortingBtn").val();
        sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
        axios({
            method: 'POST',
            url: Constant.url + "broadcast_api_list",
            data: { 
                company_id: localStorage.getItem('company_id'),
                page: pageCount,
                limit: sortingValue
            }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    apiLogsDataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountapiLogsDataList : result.data.rowscount
                })
            }else{
                classRef.setState({
                    apiLogsDataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountapiLogsDataList : result.data.rowscount
                })
            
            }
        })
    }    




    importTempList = () => {
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "popular-templates",
            data: { company_id: localStorage.getItem('company_id')}
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    importTempList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    } 
                })
            }else{
                classRef.setState({
                    importTempList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  }
                })
            
            }
        })
    }         
    
    importTemplate = (id) => {
        $("#import_"+id).html('Processing...');
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url + "import-template",
            data: { company_id: localStorage.getItem('company_id'),template_id:id}
        }).then(function (result) {
            if (result.data.status === true) {                
                $("#main_div_"+id).hide();
            }
        })
    }         
        
    handleButtonAction = () =>{
        var selectedAction = $("#button_action").children("option:selected").val();
        if(selectedAction == "call_to_action"){
            $("#call_to_action").show();
            $("#quick_reply").hide();
        }else{
            $("#quick_reply").show();
            $("#call_to_action").hide();
        }        
    }


    handleImportTemplate = () =>{
        let classRef = this;
        let result = "";
        let index = 0;
        result = this.state.importTempList.list.map((response, index) => (
        (response.is_imported == 0)?
        <div className="uk-width-1-4@m uk-width-1-1@s" id={"main_div_"+response.id}>
        <div className="customCard">
            <div className="uk-card uk-card-default">
                <div className="uk-card-header">
                    <div className="uk-flex-middle">
                        <div className="uk-width-auto">
                            <img className="" src="/images/b1.svg" />
                            {/* <img className="" src={response.image} /> */}
                        </div>
                        <div className="uk-width-expand">
                            <h4 className=""><b>{response.name}</b></h4>
                            <p className="uk-text-meta uk-margin-remove-top">{response.body}</p>
                        </div>
                    </div>
                </div>
                {/* <div className="uk-card-body">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                </div> */}
                <div className="uk-card-footer">
                    <a className="uk-button uk-button-text" id={"import_"+response.id} onClick={(e)=>this.importTemplate(response.id)}>Import</a>
                </div>
            </div>
        </div>
    </div>:''
    ));
    return result;
    }

    handleTable = () => {
        let classRef = this;
        let result = "";
        let index = 0;
        result = this.state.dataList.list.map((response, index) => (
            <tr id={"broadcast_"+response.id}>
            <td>
                <span>{response.contact_number}</span>
            </td>
            <td>{response.msg}</td>
            <td><button className="ctmBtn infoBtn">{(response.is_sent == 1) ? 'Sent' : 'Not Sent'}</button></td>
            <td>{response.created_date}</td>

            {/* <td><button className="iconcircleBtn success" uk-tooltip="title: Download Data" onClick={(e) => classRef.downloadFile(response.id)}><Download /></button></td> */}



            {/* <td>
                <div className="uk-inline">
                    <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                    <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li><a onClick = {(e) => classRef.downloadFile(response.id)}><Download />Download</a></li>
                        </ul>
                    </div>
                </div>
            </td> */}
        </tr>
        ));
        return result;
    }

    downloadFile = () =>{


        $(".uk-dropdown").removeClass("uk-open")
        let urlname = window.location.pathname.split("/")[2];
        
        window.location.href =  Constant.url + "broadcast-detail-download?company_id="+localStorage.getItem('company_id')+"&broadcast_id="+urlname+"";
    }

    CancelBroadcastModal = (id) =>{
        this.setState({
            broadcastID: id,
        })
        $("#cancelBroadcastPopup").addClass('uk-flex uk-open');
    }

    handleSchduledTable = () => {
        let result = "";
        let index = 0;
        let classRef = this;
        result = this.state.SceduledDataList.list.map((response, index) => (
            <tr>
            <td>
                <label><input className="uk-checkbox autoSelectSchedule" type="checkbox" /></label>
                {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
            </td>
            <td>
                <span>{response.name}</span>
            </td>         
            <td><button className="ctmBtn infoBtn">{response.status}</button></td>            
            <td>{response.scheduled}</td>
            <td>
                <div className="uk-inline">
                    <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                    <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                        <li><a onClick = {(e) => classRef.EditBroadcastModal(response.id)}><Edit />Edit</a></li>
                        <li className="hintAlert"><a onClick = {(e) => classRef.deleteBroadcast(response.id)}><TrashCan />Delete</a></li>                         
                        </ul>
                    </div>
                </div>
            </td>
        </tr>
        ));
        return result;
    }

    
    handleHistoryTable = () => {
        let result = "";
        let index = 0;
        // console.log(this.state.historyDataList,'historylogs')
        result = this.state.historyDataList.list.map((response, index) => (
            <tr>
            {/* <td> */}
                {/* <label><input className="uk-checkbox" type="checkbox" /></label> */}
                {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
            {/* </td> */}
            <td>{response.contact_number}</td>
            <td>{response.msg}</td>
            <td>{response.status}</td>
            <td>{response.created_date}</td>
        </tr>
        ));
        return result;
    }

    showBroadcastingPopup = (id)  =>{
        // console.log(id,'umair')
        let classRef = this;
        $("#AddBroadcast").click();
        $("#template").val(id);
        classRef.onTemplateChange(id);
    }

    // showPendingBroadcastingPopup = (id)  =>{

    //     console.log(id,'hhhhhhADII')
    //     let classRef = this;
    //     $("#PendingAddBroadcast").click();
    //     $("#Pendingtemplate").val(id);
    //     classRef.onPendingTemplateChange();
    // }

    showTemplateDelete = (id) =>{
        $("#TemplateDeleteDrop").removeClass('uk-open');
        let classRef = this;
        classRef.setState({ tempDeleteId : id })
        $("#TemplateDelPop").addClass('uk-flex uk-open');
    
    }

    showTemplateApproved = (id) =>{
        $("#TemplateDeleteDrop").removeClass('uk-open');
        let classRef = this;
        classRef.setState({ tempAppId : id })
        $("#TemplateAppPop").addClass('uk-flex uk-open');
    
    }

    DeleteTemplate = () =>{
        $('#TemplateDelete').text('Processing')
        $('#TemplateDelete').prop('disabled', true);
        let classRef = this;
        let tempId = classRef.state.tempDeleteId;
        axios({
            method: 'POST',
            url: Constant.url + "delete-bulk-template",
            data: { company_id: localStorage.getItem('company_id'),template_ids:tempId}
        }).then(function (response) {
            if (response.data.code == 200) {                
                $('#TemplateDelete').text('Save')
                $('#TemplateDelete').prop('disabled', false);
                $("#successAlertTemplate2").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
                setTimeout(function(){
                    UIkit.alert('#msg-dialog').close();
                    $("#TemplateDelPop").removeClass('uk-flex uk-open');
                },1000)
                return;
            } else {
                $('#TemplateDelete').text('Save')
                $('#TemplateDelete').prop('disabled', false);
                $("#errorAlertTemplate2").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
                setTimeout(function(){
                    UIkit.alert('#msg-dialog').close();
                    $("#TemplateDelPop").removeClass('uk-flex uk-open');
                },1000)
                return;
            }
        })
    }


    ApprovedTemplate = () =>{
        $('#TemplateDelete').text('Processing')
        $('#TemplateDelete').prop('disabled', true);
        let classRef = this;
        let tempId = classRef.state.tempAppId;
        axios({
            method: 'POST',
            url: Constant.url + "approve-template",
            data: { company_id: localStorage.getItem('company_id'),template_id:tempId}
        }).then(function (response) {
            if (response.data.code == 200) {                
                $('#TemplateDelete').text('Save')
                $('#TemplateDelete').prop('disabled', false);
                $("#successAlertTemplate2").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
                setTimeout(function(){
                    UIkit.alert('#msg-dialog').close();
                    $("#TemplateAppPop").removeClass('uk-flex uk-open');
                },1000)
                   window.location.reload();    
                return;
            } else {
                $('#TemplateDelete').text('Save')
                $('#TemplateDelete').prop('disabled', false);
                $("#errorAlertTemplate2").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
                setTimeout(function(){
                    UIkit.alert('#msg-dialog').close();
                    $("#TemplateAppPop").removeClass('uk-flex uk-open');
                },1000)
                window.location.reload();    
                return;
            }
        })
    }

    handleTemplatesTable = () => {
        let classRef = this;
        let result = "";
        let index = 0;
        // console.log(this.state.templatesDataList,'historylogs')
        result = this.state.templatesDataList.list.map((response, index) => (
            <tr>
                <td>{response.name}</td>
                <td><a href="javascript:;" onClick={(e)=>this.showBroadcastingPopup(response.id)}>Click to Preview</a></td>
                <td>{response.created_date}</td>
                <td>
                <div className="uk-inline">
                    <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                    <div uk-dropdown="mode: click" id="TemplateDeleteDrop">
                        <ul className="uk-nav uk-dropdown-nav">
                        <li className="hintAlert"><a onClick = {(e) => classRef.showTemplateDelete(response.id)}><TrashCan />Delete</a></li>                         
                        </ul>
                    </div>
                </div>
            </td>
            </tr>
        ));
        return result;
    }

    handlePageClick = (data) =>{
        let currentPage = data.selected + 1;
        this.callTemplatesApi(currentPage);
    }



    PendinghandleTemplatesTable = () => {
        let classRef = this;
        let result = "";
        let index = 0;
        // console.log(this.state.templatesDataList,'historylogs')
        result = this.state.pendingtemplatesDataList.list.map((response, index) => (
            <tr>
                <td>{response.name}</td>
                <td><a href="javascript:;" onClick={(e)=>this.showBroadcastingPopup(response.id)}>Click to Preview</a></td>
                {/* <td><button className="ctmBtn infoBtn">{response.status}</button></td>  */}
                <td><button className={(response.status == '1' ? 'ctmBtn succesBtn' : "ctmBtn dangreBtn")}>{(response.status == '1') ? 'Active' : 'Pending'}</button></td>
                <td>{response.created_date}</td>
                <td>
                <div className="uk-inline">
                    <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                    <div uk-dropdown="mode: click" id="TemplateDeleteDrop">
                        <ul className="uk-nav uk-dropdown-nav">
                        <li className="hintAlert"><a onClick = {(e) => classRef.showTemplateApproved(response.id)}><Checkmark />Approved</a></li>   
                        <li className="hintAlert"><a onClick = {(e) => classRef.showTemplateDelete(response.id)}><TrashCan />Delete</a></li>                       
                        </ul>
                    </div>

                  
        



                </div>
            </td>
            </tr>
        ));
        return result;
    }

    PendinghandlePageClick = (data) =>{
        let currentPage = data.selected + 1;
        this.callPendingTemplatesApi(currentPage);
    }


    ApiLogsTable = () => {
        let classRef = this;
        let result = "";
        let index = 0;
        // console.log(this.state.templatesDataList,'historylogs')
        result = this.state.pendingtemplatesDataList.list.map((response, index) => (
            <tr>
                <td>{response.name}</td>
                <td><a href="javascript:;" onClick={(e)=>this.showBroadcastingPopup(response.id)}>Click to Preview</a></td>
                {/* <td><button className="ctmBtn infoBtn">{response.status}</button></td>  */}
                <td><button className={(response.status == '1' ? 'ctmBtn succesBtn' : "ctmBtn dangreBtn")}>{(response.status == '1') ? 'Active' : 'Pending'}</button></td>
                <td>{response.created_date}</td>
                <td>
                <div className="uk-inline">
                    <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                    <div uk-dropdown="mode: click" id="TemplateDeleteDrop">
                        <ul className="uk-nav uk-dropdown-nav">
                        <li className="hintAlert"><a onClick = {(e) => classRef.showTemplateApproved(response.id)}><Checkmark />Approved</a></li>   
                        <li className="hintAlert"><a onClick = {(e) => classRef.showTemplateDelete(response.id)}><TrashCan />Delete</a></li>                       
                        </ul>
                    </div>

                  
        



                </div>
            </td>
            </tr>
        ));
        return result;
    }


    
    ApihandlePageClick = (data) =>{
        let currentPage = data.selected + 1;
        this.callLogsApi(currentPage);
    }











    handleBroadcastClick = (data) =>{
        let currentPage = data.selected + 1;
        this.BroadcastSorting(currentPage);
    }

    handleLogsClick = (data) =>{
        let currentPage = data.selected + 1;
        this.LogsSorting(currentPage);
    }

    handleScheduleClick = (data) =>{
        let currentPage = data.selected + 1;
        this.ScheduleSorting(currentPage);
    }

    getTemplate = () =>{
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"templates",
            data: {company_id:localStorage.getItem('company_id')}            
        }).then(function (result) {
            if(result.data.status === true){                          
                classRef.setState({
                    templateList :{
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },    
                    previewHeader : result.data.data[0].header,
                    previewFooter : result.data.data[0].footer,
                    previewBody : result.data.data[0].body,   
                    previewButtonOne : result.data.data[0].button_1,
                    previewButtonTwo : result.data.data[0].button_2,       
                    previewButtonThree : result.data.data[0].button_3                      
                })                               
            }
        })
    }  
    
    getSegment = () =>{
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"segments",
            data: {company_id:localStorage.getItem('company_id')}            
        }).then(function (result) {
            if(result.data.status === true){                          
                classRef.setState({
                    segmentList :{
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },                    
                })                               
            }
        })
    }      

    uploadFile = () =>{
        $('#uploadButton').text('Processing')
        $('#uploadButton').prop('disabled', true); 
        let classRef = this;
        let company_id = localStorage.getItem('company_id');
        let segment_name = $('#segment_name').val();
        let importParams = new FormData();
        importParams.append( 'company_id', company_id );
        importParams.append( 'segment_name', segment_name );
        importParams.append( 'file', classRef.state.uploadfiles );
        if(segment_name != ""){
            if(classRef.state.uploadfiles != ""){
                axios({
                    method: 'POST',
                    url: Constant.url+"import-contacts",
                    data: importParams            
                }).then(function (result) {
                    if(result.data.status === true){                          
                        classRef.getSegment();    
                        $("#ImportcontactModal").removeClass('uk-flex uk-open');                        
                    }
                })
            } else {
                $("#errorAlertSegment").append('<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please upload file.</p></div>');
                $('#uploadButton').text('Continue')
                $('#uploadButton').prop('disabled', false); 
                setTimeout(function(){UIkit.alert('#msg-dialog1').close();},1000);
            }

        } else {
            $("#errorAlertSegment").append('<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please Enter Segment Name.</p></div>');
            $('#uploadButton').text('Continue')
            $('#uploadButton').prop('disabled', false); 
            setTimeout(function(){UIkit.alert('#msg-dialog1').close();},1000);
        }

    }
    
    onFileUpload = (event) =>{
        let classRef = this;
        if(event.target.files[0] != undefined){
            $("#uploadedFile").empty();
            $("#uploadedFile").append("Uploaded File : "+event.target.files[0].name);
            classRef.setState({ uploadfiles: event.target.files[0] });
        }
    }

    handleSegment = () => {
        let result = "";
        let index = 0;
        result = this.state.segmentList.list.map((response, index) => (   
          <option value={response.id}>{response.name}</option>
        ));
        return result;
    }

    handleTemplate = () => {
        let result = "";
        let index = 0;
        result = this.state.templateList.list.map((response, index) => (   
          <option value={response.id}>{response.name}</option>
        ));
        return result;
    }

    getCategory = () =>{
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"categories"                       
        }).then(function (result) {
            if(result.data.status === true){                          
                classRef.setState({
                    categoryList :{
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },                    
                })                               
            }
        })
    }      

    handleCategory = () => {
        let result = "";
        let index = 0;
        result = this.state.categoryList.list.map((response, index) => (   
          <option value={response.id}>{response.name}</option>
        ));
        return result;
    } 

    getLanguages = () =>{
        let classRef = this;
        axios({
            method: 'POST',
            url: Constant.url+"languages"                       
        }).then(function (result) {
            if(result.data.status === true){                          
                classRef.setState({
                    languagesList :{
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },                    
                })                               
            }
        })
    }      

    handleLanguages = () => {
        let result = "";
        let index = 0;
        result = this.state.languagesList.list.map((response, index) => (   
          <option value={response.id}>{response.name}</option>
        ));
        return result;
    }        

    deleteBroadcast = (id) => {    
        $("#broadcast_"+id).hide();  
      let classRef = this;
      axios({
          method: 'POST',
          url: Constant.url+"edit-broadcast",
          data :{
            company_id :localStorage.getItem('company_id'),
            broadcast_id :id,
            is_deleted:1
          }                       
      }).then(function (result) {
          if(result.data.status === true){                          
                                        
          }
      })      
    }

    EditBroadcastModal = (id) => {
        // console.log(id,"EditBroadcastModal")
        $("#EditBroadcastModal").addClass('uk-flex uk-open');        
        this.broadCastByID(id);
    }

    CloseEditBroadcastModal = () => {
        $("#EditBroadcastModal").removeClass('uk-flex uk-open');        
    }

    
    CreateTemplateModal = () => {
        $("#CreateTemplateModal").addClass('uk-flex uk-open');        
    }

    CloseTemplateModal = () => {
        $("#CreateTemplateModal").removeClass('uk-flex uk-open');        
    }

    hideTemplateDeletePop = () => {
        $("#TemplateDelPop").removeClass('uk-flex uk-open');  
    }

    hideTemplateAppPop = () => {
        $("#TemplateAppPop").removeClass('uk-flex uk-open');  
    }
    

    AddBroadcastModal2 = () => {


        var teamName = $("#teamName").val();
        var template_id = $("#template").val();
        var start_date = $("#broadcast_date").val();
        var start_time =  $("#broadcast_time").val();

        if(teamName ==""){

          $("#logerror").show();
          $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Title Name is required</p></div>');
          setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
          return;
    

        }
        if(template_id ==""){

            $("#logerror").show();
            $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Template is required</p></div>');
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
            return;
          }

        if(start_date ==""){

            $("#logerror").show();
            $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Date is required</p></div>');
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
            return;
          }
        if(start_time == ""){

            $("#logerror").show();
            $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Time is required</p></div>');
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
            return;
          }

        if(teamName !== "" && template_id !== "" && start_date !== "" && start_time !== "")
          {
            $("#AddBroadcastModal2").addClass('uk-flex uk-open');
            $("#AddBroadcastModal").removeClass('uk-flex uk-open');
            
          }

        // $("#AddBroadcastModal2").addClass('uk-flex uk-open');
        // $("#AddBroadcastModal").removeClass('uk-flex uk-open');

    }

    CloseBroadcastModal2 = () => {
        $("#AddBroadcastModal2").removeClass('uk-flex uk-open');
        $("#AddBroadcastModal").removeClass('uk-flex uk-open');
    }
    
    AddBroadcastModal = () => {

        $("#template").val($("#template option:first").val());
        if(this.state.is_expired == '1')
        {
             $("#errorAlertcheck").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Your Subscription has been expired.Please resubscribe your package</p></div>');
             setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)

        }else{

            $("#AddBroadcastModal").addClass('uk-flex uk-open');


        }


    }

    CloseBroadcastModal2 = () => {
        $("#AddBroadcastModal").removeClass('uk-flex uk-open');
    }


    AddImportcontactModal = () => {
        $("#ImportcontactModal").addClass('uk-flex uk-open');
    }

    hideImportcontactModal = () => {
        $("#ImportcontactModal").removeClass('uk-flex uk-open');
    }

    CloseImportcontactModal = () => {
        $("#ImportcontactModal").removeClass('uk-flex uk-open');
    }


    AddImportcontactModalUpload = () => {
        $("#ImportcontactModalUpload").addClass('uk-flex uk-open');
    }

    CloseImportcontactModalUpload = () => {
        $("#ImportcontactModalUpload").removeClass('uk-flex uk-open');
    }

    editBroadcast = () =>{     
        $('.AddTeamBtn').text('Processing')
        $('.AddTeamBtn').prop('disabled', true);

        let classRef = this;
        var data = {};
        data = {
          name: $("#teamNameEdit").val(), 
          template_id: $("#templateEdit").val(),                    
          start_date:$("#broadcast_date_edit").val(),
          start_time:$("#broadcast_date_time").val(),
          is_deleted:0,
          broadcast_id:classRef.state.broadcastEditID,
          company_id:localStorage.getItem('company_id')
        }
        axios({
          method: 'POST',
          url: Constant.url + "edit-broadcast",
          data: data
        }).then(function (response) {          
          if (response.data.code === 200) {
            $("#successAlertEdit").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');            
            window.location.reload()
          }
     
          $("#errorAlertsEdit").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
          $('.AddTeamBtn').text('Next')
          $('.AddTeamBtn').prop('disabled', false);
        //   setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
        //   return;
        
    
        
        }).then(function (response) {
    
        });
    }    

    createBroacast = () => {

        $('.AddTeamBtn').text('Processing')
        $('.AddTeamBtn').prop('disabled', true);

        let classRef = this;
        var data = {};
        data = {
          name: $("#teamName").val(), 
          template_id: $("#template").val(),
          segment_id: $("#segment").val(),
          company_id:localStorage.getItem('company_id'),
          start_date:$("#broadcast_date").val(),
          start_time:$("#broadcast_time").val()
        }
        axios({
          method: 'POST',
          url: Constant.url + "add-broadcast",
          data: data
        }).then(function (response) {          
          if (response.data.code === 200) {
            $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');

            window.location.href = "/broadcasting";
          }else{

            $("#errorAlerts").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
          $('.AddTeamBtn').text('Next')
          $('.AddTeamBtn').prop('disabled', false);
          setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
          return;

          }
     
          
    
        
        }).then(function (response) {
    
        });
    }

    createTemplate = () => {
        let classRef = this;
        var data = {};
        $('#saveTemplate').text('Processing')
        $('#saveTemplate').prop('disabled', true);
        data = {
            button_action: $("#button_action").val(),
            button_1: $("#button_1").val(),
            button_2: $("#button_2").val(),
            button_3: $("#button_3").val(),
            button_web_phone: $("#button_web_phone").val(),
            btn_text: $("#btn_text").val(),
            link_number: $("#link_number").val(),
            tempname: $("#tempname").val(),
            category: $("#category").val(),
            language: $("#language").val(),
            header_type: $("#header_type").val(),
            header_text: $("#header_text").val(),
            body: $("#body").val(),
            footer: $("#footer").val(),
            content_variable: $("#content_variable").val(),
            status : 1,
            company_id : localStorage.getItem('company_id')           
        }
        axios({
          method: 'POST',
          url: Constant.url + "add-template",
          data: data
        }).then(function (response) {          
          if (response.data.code === 200) {
            $("#successAlertTemplate").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
            $('#saveTemplate').text('Save and Submit')
            $('#saveTemplate').prop('disabled', false);
            setTimeout(function(){
                UIkit.alert('#msg-dialog').close();
                $("#ImportcontactModal").removeClass('uk-flex uk-open');
                window.location.reload();
            },1000)
            
          } else {
            $("#errorAlertTemplate").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
            $('#saveTemplate').text('Save and Submit')
            $('#saveTemplate').prop('disabled', false);
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
          }
        }).then(function (response) {
    
        });
    }

    createTemplateDraft = () =>{
        let classRef = this;
        var data = {};
        $('#saveTemplateDraft').text('Processing')
        $('#saveTemplateDraft').prop('disabled', true);
        data = {
            button_action: $("#button_action").val(),
            button_1: $("#button_1").val(),
            button_2: $("#button_2").val(),
            button_3: $("#button_3").val(),
            button_web_phone: $("#button_web_phone").val(),
            btn_text: $("#btn_text").val(),
            link_number: $("#link_number").val(),
            tempname: $("#tempname").val(),
            category: $("#category").val(),
            language: $("#language").val(),
            header_type: $("#header_type").val(),
            header_text: $("#header_text").val(),
            body: $("#body").val(),
            footer: $("#footer").val(),
            content_variable: $("#content_variable").val(),
            status : 1,
            company_id : 1            
        }
        axios({
          method: 'POST',
          url: Constant.url + "add-template",
          data: data
        }).then(function (response) {          
          if (response.data.code === 200) {
            $("#successAlertTemplate").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
            $('#saveTemplateDraft').text('Save and Submit')
            $('#saveTemplateDraft').prop('disabled', false);
            setTimeout(function(){
                UIkit.alert('#msg-dialog').close();
                $("#ImportcontactModal").removeClass('uk-flex uk-open');
            },1000)
          } else {
            $("#errorAlertTemplate").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
            $('#saveTemplateDraft').text('Save and Submit')
            $('#saveTemplateDraft').prop('disabled', false);
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},1000)
          }
        }).then(function (response) {
    
        });
    }

    headerContentType = () =>{
        if($("#header_type").val() == "media"){
            $("#media_section").show();
            $("#text_header_text").hide();
            $("#media_preview").show();
            $("#text_preview").hide();
            $("#footer_sec").hide();
            $("#button_sec").hide();      
            $('#header_text_media').attr('placeholder', 'Url');                  
        }else{
            $("#media_section").hide();
            $("#text_preview").show();
            $("#text_header_text").show();
            $("#media_preview").hide();
            $("#footer_sec").show();
            $("#button_sec").show();  
            $('#header_text_media').attr('placeholder', 'Please Enter Header Text');      
        }        
    }

    handlePreview = (preview) =>{
        
        if(preview == "header_text"){
            this.setState({headerContent : $("#header_text").val()})
        }else if(preview == "header_text_image"){
            this.setState({headerContent : $("#header_text_image").val()})
        }else if(preview == "body"){
            this.setState({bodyContent : $("#body").val()})
        }else if(preview == "footer"){
            this.setState({footerContent : $("#footer").val()})
        }else if(preview == "button_1"){
            this.setState({buttonOneContent : $("#button_1").val()})
        }else if(preview == "button_2"){
            this.setState({buttonTwoContent : $("#button_2").val()})
        }else if(preview == "button_3"){
            this.setState({buttonThreeContent : $("#button_3").val()})
        }else if(preview == "button_4"){
            this.setState({buttonFourContent : $("#button_4").val()})
        }else if(preview == "header_text_media"){
            this.setState({previewImage : $("#header_text_media").val()})
        }  
         
    }

    deleteButton = (button) =>{
        // console.log("asdasd");
        if(button == "button_delete_2"){
            $("#button_delete_2").hide();
            this.setState({buttonTwoContent : ""})
            $("#add_button").show();   
        }else if(button == "button_delete_3"){
            $("#button_delete_3").hide();
            this.setState({buttonThreeContent : ""})
            $("#add_button").show();   
        }
    }

    addButton = () =>{
        if($("#button_delete_2").css('display') == "none"){
            $("#button_delete_2").show();
        }else if($("#button_delete_3").css('display') == "none"){
            $("#button_delete_3").show();
            $("#add_button").hide();            
        }

    }

    button_web_phone = () =>{
        var selectedAction = $("#button_web_phone").children("option:selected").val();
    }

    onTemplateChange = (id) =>{
        let classRef = this;
        // let id = $("#template").val();

    // console.log('owaiss',id)



        axios({
            method: 'POST',
            url: Constant.url+"single-template",
            data: {company_id:localStorage.getItem('company_id'),template_id:id}            
        }).then(function (result) {
            if(result.data.status === true){    
                // console.log(result.data.data,'templateee')                      
                classRef.setState({
                    previewHeader : result.data.data.header,
                    previewFooter : result.data.data.footer,
                    previewBody : result.data.data.body,   
                    previewButtonOne : result.data.data.button_1,
                    previewButtonTwo : result.data.data.button_2,       
                    previewButtonThree : result.data.data.button_3             
                })                               
            }
        })
    }

    templateLoad = (id) =>{
        let classRef = this;
        
        axios({
            method: 'POST',
            url: Constant.url+"single-template",
            data: {company_id:localStorage.getItem('company_id'),template_id:id}            
        }).then(function (result) {
            if(result.data.status === true){    
                // console.log(result.data.data,'templateee')                      
                classRef.setState({
                    previewHeader : result.data.data.header,
                    previewFooter : result.data.data.footer,
                    previewBody : result.data.data.body,   
                    previewButtonOne : result.data.data.button_1,
                    previewButtonTwo : result.data.data.button_2,       
                    previewButtonThree : result.data.data.button_3             
                })                               
            }
        })
    }
    
    onEditTemplateChange = () =>{
        let classRef = this;
        let id = $("#templateEdit").val();
        axios({
            method: 'POST',
            url: Constant.url+"single-template",
            data: {company_id:localStorage.getItem('company_id'),template_id:id}            
        }).then(function (result) {
            if(result.data.status === true){               
                classRef.setState({
                    previewHeader : result.data.data.header,
                    previewFooter : result.data.data.footer,
                    previewBody : result.data.data.body,   
                    previewButtonOne : result.data.data.button_1,
                    previewButtonTwo : result.data.data.button_2,       
                    previewButtonThree : result.data.data.button_3             
                })                               
            }
        })
    } 

    broadCastByID = (id) =>{
        let classRef = this;        
        axios({
            method: 'POST',
            url: Constant.url+"single-broadcast",
            data: {company_id:localStorage.getItem('company_id'),broadcast_id:id}            
        }).then(function (result) {
            if(result.data.status === true){                                     
              classRef.setState({broadcastEditID : result.data.data.name})
              $("#teamNameEdit").val(result.data.data.name);
              $("#templateEdit").val(result.data.data.template_id);
              $("#broadcast_date_edit").val(result.data.data.start_date);
              $("#broadcast_time_edit").val(result.data.data.start_time);      
              classRef.templateLoad(result.data.data.template_id);      
            }
        })
    }     
    

    mediaHandler = () => {
        if($('input[name="radio2"]:checked').val() == "image"){
            $("#image_div").show();
            $("#video_div").hide();
        }else if($('input[name="radio2"]:checked').val() == "video"){
            $("#image_div").hide();
            $("#video_div").show();
        }
    }

    selectCheckbox = () =>{
        if(!this.state.checkAll){
            this.setState({checkAll:true},function(){
                $('#select_all').prop('checked',true);  
                $('.autoSelect').prop('checked',true);  
            })
        }else{
            this.setState({checkAll:false},function(){
                $('#select_all').prop('checked',false);  
                $('.autoSelect').prop('checked',false);  
            })
        }
    }    

    autoSelectSchedule = () =>{
        if(!this.state.checkAll){
            this.setState({checkAll:true},function(){
                $('#select_all_schedule').prop('checked',true);  
                $('.autoSelectSchedule').prop('checked',true);  
            })
        }else{
            this.setState({checkAll:false},function(){
                $('#select_all_schedule').prop('checked',false);  
                $('.autoSelectSchedule').prop('checked',false);  
            })
        }
    }    
    
    allChats = event => {

        var id = event.currentTarget.id;
        var pageCount=1;
        // console.log(id,'karacjiiiii')

        if(id=='sent'){
            var type="is_sent";
            this.callHistoryApi(pageCount,type);
            $("#Broadcasting").removeClass('uk-active');
            $("#BroadcastingData").removeClass('uk-active');
            $('#logs').addClass('uk-active');
            $('#logsData').addClass('uk-active');
          }
        if(id=='delivered'){
        var type="is_delivered";
        this.callHistoryApi(pageCount,type);
        $("#Broadcasting").removeClass('uk-active');
        $("#BroadcastingData").removeClass('uk-active');
        $('#logs').addClass('uk-active');
        $('#logsData').addClass('uk-active');
        }
        if(id=='read'){
        var type="is_read";
        this.callHistoryApi(pageCount,type);
        $("#Broadcasting").removeClass('uk-active');
        $("#BroadcastingData").removeClass('uk-active');
        $('#logs').addClass('uk-active');
        $('#logsData').addClass('uk-active');
        }
        if(id=='replied'){
        var type="is_replied";
        this.callHistoryApi(pageCount,type);
        $("#Broadcasting").removeClass('uk-active');
        $("#BroadcastingData").removeClass('uk-active');
        $('#logs').addClass('uk-active');
        $('#logsData').addClass('uk-active');
        }
        if(id=='failed'){
        var type="failed";
        this.callHistoryApi(pageCount,type);
        $("#Broadcasting").removeClass('uk-active');
        $("#BroadcastingData").removeClass('uk-active');
        $('#logs').addClass('uk-active');
        $('#logsData').addClass('uk-active');
        }
        if(id=='sending'){
        var type="sending";
        this.callHistoryApi(pageCount,type);
        $("#Broadcasting").removeClass('uk-active');
        $("#BroadcastingData").removeClass('uk-active');
        $('#logs').addClass('uk-active');
        $('#logsData').addClass('uk-active');
        }

        
      

    }

    
    handleTableContent = () =>{
        $("#Broadcasting").removeClass('uk-active');
        $("#BroadcastingData").removeClass('uk-active');
        $('#logs').addClass('uk-active');
        $('#logsData').addClass('uk-active');
        // alert('111')
        // UIkit.tab('.TabSec').show(1);


    }

    downloadbroadcast= (id) => {

        let classRef = this;
        var team_id = id;
        window.open(Constant.url +"download-broadcasts?company_id=" + localStorage.getItem('company_id'), "_blank")
      
      }

      downloadbroadcasthistory= () => {

        let classRef = this;

        window.open(Constant.url +"download-broadcast-history?company_id=" + localStorage.getItem('company_id'), "_blank")
      
      }


      downloadschedulebroadcast= () => {
        let classRef = this;
        window.open(Constant.url +"download-scheduled-broadcast?company_id=" + localStorage.getItem('company_id'), "_blank")
      
      }

      downloadTemplate= () => {
        let classRef = this;
        window.open(Constant.url +"templates-download?company_id=" + localStorage.getItem('company_id'), "_blank")
      }

      downloadPendingTemplate= () => {
        let classRef = this;
        window.open(Constant.url +"templates-pending-download?company_id=" + localStorage.getItem('company_id'), "_blank")
      
      }

      downloadApilogs= () => {
        let classRef = this;
        window.open(Constant.url +"download_broadcast_api?company_id=" + localStorage.getItem('company_id'), "_blank")
      
      }

      



      emoji = () =>{
    

        $("#emoji").removeClass('uk-hidden');
        $("#emojidiv").addClass("uk-hidden");
        $("#emojiCross").removeClass("uk-hidden");

      }


      
      hideEmojiCross = () =>{
        $("#emojidiv").removeClass("uk-hidden");
        $("#emojiCross").addClass("uk-hidden");
        $("#emoji").addClass('uk-hidden');
      }
    

      

    render() {
        return (
            <Content>
                
                <div className="BroadcastingContainer" uk-height-viewport="" uk-height-match=".h_match">


                    <div id="errorAlertcheck"></div>

                    <div className="uk-grid">  
                    <div className="uk-width-auto uk-margin-small-top headingBroadcastHead"><h3>Broadcast Details</h3></div>
                        <div className="uk-width-expand"></div>
                    <div className="uk-width-auto">
                        <button className="newFlowBtn uk-margin-small-top" id="AddBroadcast" onClick={this.downloadFile} ><Add /> <span>Export Broadcast Detail</span></button>
                    </div>  
                        

                        <div className="numberSorting uk-float-right broadcastPagSort">
                        <div class="uk-inline">
                            <label>Show
                            <select onChange={(e)=>this.BroadcastSorting()} id="BroadcastSortingBtn">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>500</option>
                            </select>
                            entries</label>
                        </div>
                    </div> 
                    </div> 


                    <div className="tableSec">
                                <table className="uk-table uk-table-small uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>CONTACT NUMBER</th>
                                            <th>MESSAGE</th>
                                            <th>STATUS</th>
                                            <th>DATE</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.dataList.isLoaded) ? this.handleTable() : (this.state.dataList.msg == 'not') ? <tr><td colSpan="15"><div className="uk-text-center uk-height-large">Data not Found</div></td></tr>: <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr> }
                                    </tbody>
                                </table>
                                {(this.state.rowsCountBroadcast > 1) ? 
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'....'}
                                        pageCount={this.state.rowsCountBroadcast}
                                        onPageChange={this.handleBroadcastClick}
                                        containerClassName={'uk-pagination uk-float-right'}
                                        activeClassName={'uk-active'}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={6}
                                    /> : ""
                                }
                            </div>
                </div>
            </Content>
        )
    }
}
export default BroadcastingDetail;