import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import $ from "jquery";
import Constant from "../../../App/Constant";
import IntegrationsSidebar from "../../Common/IntegrationsSidebar";
import axios from "axios";
import { AddAlt, Close, Information } from "@carbon/icons-react";
class EmailIntegration extends React.Component {
  addEmailAccount = () => {
    let classRef = this;
    var data = {};
    var url = window.location.pathname;
    var id = url.split("/");
    data = {
      company_id: localStorage.getItem("company_id"),
      type: "email",
      name: $("#name").val(),
      email: $("#username").val(),
      out_server: $("#smpt").val(),
      out_username: $("#username").val(),
      out_password: $("#Password").val(),
      out_port: $("#port").val(),
      out_encryption: $("#encryption").val(),
      in_server: $("#server").val(),
      in_port: $("#inport").val(),
      in_username: $("#inusername").val(),
      in_password: $("#inPassword").val(),
      in_encryption: $("#inencryption").val(),
    };

    axios({
      method: "POST",
      url: Constant.url + "add-company-channel-email",
      data: data,
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        // window.location.href = "/integrations/email-integration";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );
        $("#AddTeamBtn").text("Save");
        $("#AddTeamBtn").prop("disabled", false);
        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);
        return;
      }
    });

    // console.log(data, "hafeeez");
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>
                  Integrations <span> {">"} Email</span>
                </h3>
              </div>
            </div>
          </div>
          <IntegrationsSidebar />
          <div className="integration inner">
            {/* <h4>Popular integrating</h4> */}
            <div className="uk-flex-top uk-grid">
              <div className="uk-width-auto@m uk-flex-first">
                <img
                  className="Fimg"
                  src="/images/Integrations-email.png"
                  alt=""
                />
              </div>
              <div className="uk-width-3-4">
                <h3>Email</h3>
                <p>
                  Create powerful Email marketing campaigns to re-engage your
                  customers. Reach out to your leads, increase engagement, and
                  learn how to win your customers back.
                </p>

                {/**Sending Mail start */}
                <div className="addnum_box uk-margin-medium-top">
                  <h4>Sending Email</h4>
                </div>
                <div className="CustomBgAdmin removePad">
                  <div className="integration inner">
                    <form
                      className="uk-form-stacked"
                      action="javascript:void(0);"
                    >
                      <div id="errorAlert"></div>
                      <div id="successAlert"></div>

                      <div className="MainFormsection emailForm">
                        <div className="uk-grid  uk-grid-small" uk-grid="">
                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">SMTP Server</label>
                            <input
                              class="uk-input"
                              id="smpt"
                              name="smpt"
                              placeholder="Enter SMTP server address"
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Port</label>
                            <input
                              class="uk-input"
                              id="port"
                              name="port"
                              placeholder="Enter Port"
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">User Name</label>
                            <input
                              class="uk-input"
                              id="username"
                              name="username"
                              type="email"
                              placeholder="Enter user name"
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Password</label>
                            <input
                              class="uk-input"
                              id="Password"
                              name="Password"
                              type="password"
                              placeholder="Password"
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Encription</label>
                            <select
                              className="uk-select"
                              id="encryption"
                              name="encryption"
                            >
                              <option>Choose an encryption method</option>
                              <option value="TLS">TLS</option>
                              <option value="SSL">SSL</option>
                            </select>
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Name</label>
                            <input
                              class="uk-input"
                              id="name"
                              name="name"
                              placeholder="Password"
                            />
                          </div>

                          {/* <div className="uk-width-1-1 uk-margin-medium-top">
                                            <div className="ModalStyleBtns dayFormBtn email">
                                                <button className="AddTeamBtn">Save Settings</button>
                                            </div>
                                        </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/**Sending Mail end */}

                {/**Fetching Emails start */}

                <div className="addnum_box uk-margin-medium-top">
                  <h4>Fetching Emails</h4>
                </div>
                <div className=" CustomBgAdmin removePad emailBg">
                  <div className="integration inner">
                    <form
                      className="uk-form-stacked"
                      action="javascript:void(0);"
                    >
                      <div className="MainFormsection emailForm">
                        <div className="uk-grid  uk-grid-small" uk-grid="">
                          {/* <div className="uk-width-1-2@m">
                                            <label className="uk-form-label">Fetch From</label>
                                            <input class="uk-input"  id="fetch"  name="fetch"  placeholder="Enter address to fetch emails from"  />
                                        </div> */}

                          {/* <div className="uk-width-1-2@m">
                                            <label className="uk-form-label">Protocol</label>
                                            <select className="uk-select" id="protocol" name="protocol">
                                                <option>Choose Protocol</option>
                                            </select>
                                        </div> */}

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Server</label>
                            <input
                              class="uk-input"
                              id="server"
                              name="server"
                              placeholder="Enter email server address"
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Port</label>
                            <input
                              class="uk-input"
                              id="inport"
                              name="inport"
                              placeholder="Enter Port"
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">User Name</label>
                            <input
                              class="uk-input"
                              id="inusername"
                              type="email"
                              name="inusername"
                              placeholder="Enter user name"
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Password</label>
                            <input
                              class="uk-input"
                              id="inPassword"
                              type="password"
                              name="inPassword"
                              placeholder="Password"
                            />
                          </div>

                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">Encription</label>
                            <select
                              className="uk-select"
                              id="inencryption"
                              name="inencryption"
                            >
                              <option>Choose an encryption method</option>
                              <option value="TLS">TLS</option>
                              <option value="SSL">SSL</option>
                            </select>
                          </div>

                          {/* <div className="uk-width-1-1@m label">
                                            <label className="uk-form-label">Validate Certificate</label>
                                        </div>
                                        <div className="uk-width-2-3@m">

                                        <label class="switch">
                                            <input type="checkbox" class="abc" id="chkLive" />
                                                <a  id="tog_button" class="slider round"></a></label>
                                                <span className="uk-margin-left">Enabled  <span className="info" uk-tooltip="dummp text"><Information /></span> </span>
                                        </div> */}
                          <div className="uk-width-1 uk-margin-medium-top">
                            <h4>Validate Certificate</h4>
                          </div>
                          <div className="uk-width-1">
                            <div className="AvailableToggle">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  class="abc"
                                  id="chkLive"
                                  onClick={this.handleToggleChange}
                                />
                                <span className="slider round" />
                              </label>

                              <label className="CustomNoti">Enabled</label>
                            </div>
                          </div>
                          <div className="uk-width-1-1 uk-margin-medium-top">
                            <div class="ModalStyleBtns">
                              <button
                                class="AddTeamBtn"
                                onClick={this.addEmailAccount}
                              >
                                Save Settings
                              </button>
                              <button class="CnclBtn">Check Connection</button>
                            </div>
                          </div>
                          <div className="uk-width-1-2@m">
                            <label className="uk-form-label">
                              Save settings before checking connection.
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/**Fetching Emails end */}
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default EmailIntegration;
