import * as React from "react";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { DateRange } from "../../Common/DateRange";
import $ from "jquery";

  
class LiveChatTags extends React.Component {
    constructor() {
        super();
        this.state = {
        from: "",
        to: "",
        }

    }


    MultiSelectFilter =()=>{
        $(".UltabsBtns").toggleClass("showMenu");
        $(".UltabsBtns > li label").click(function(){
            $(".MultiSelectFilter > p span").text($(this).text());
        });
    }
    

    componentDidMount() {
        var classRef = this;

        var to = "2022-11-01";
        var from = "2022-09-20";
        classRef.setState({ from: from });
        classRef.setState({ to: to });
                }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Tags overview</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>
                
                {/* Utilization per agent */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                        <h5 className="uk-heading-divider">Performance by Tag
                        <div className="uk-float-right ">
                                <div className="AgentDropDown">
                                    <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                                        <p><span>Export</span> <ChevronDown /></p>
                                        
                                    </div>
                                </div>
                            </div>    
                        </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand">TAG</th>
                                    <th className="uk-table-auto uk-text-nowrap">CLOSED</th>
                                    <th className="uk-table-expand uk-text-nowrap">WAIT TIME</th>
                                    <th className="uk-table-shrink uk-text-nowrap">HANDLING TIME</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a>Important</a></td>
                                    <td className="uk-text-nowrap"><span>13,830</span> </td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                    <td className="uk-text-nowrap"><span>00:02:48</span></td>
                                </tr>

                                <tr>
                                    <td><a>Important</a></td>
                                    <td className="uk-text-nowrap"><span>13,830</span> </td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                    <td className="uk-text-nowrap"><span>00:02:48</span></td>
                                </tr>

                                <tr>
                                    <td><a>Important</a></td>
                                    <td className="uk-text-nowrap"><span>13,830</span> </td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                    <td className="uk-text-nowrap"><span>00:02:48</span></td>
                                </tr>

                                <tr>
                                    <td><a>Important</a></td>
                                    <td className="uk-text-nowrap"><span>13,830</span> </td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                    <td className="uk-text-nowrap"><span>00:02:48</span></td>
                                </tr>

                                <tr>
                                    <td><a>Important</a></td>
                                    <td className="uk-text-nowrap"><span>13,830</span> </td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                    <td className="uk-text-nowrap"><span>00:02:48</span></td>
                                </tr>

                                <tr>
                                    <td><a>Important</a></td>
                                    <td className="uk-text-nowrap"><span>13,830</span> </td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                    <td className="uk-text-nowrap"><span>00:02:48</span></td>
                                </tr>
                                <tr>
                                    <td><a>Important</a></td>
                                    <td className="uk-text-nowrap"><span>13,830</span> </td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                    <td className="uk-text-nowrap"><span>00:02:48</span></td>
                                </tr>

                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
         </>
    );
}
}
export default LiveChatTags;