import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from '@adobe/react-spectrum';
import PageActionBar from "../../Sections/PageActionBar";
import { CaretDown, Checkmark, OverflowMenuVertical } from '@carbon/icons-react';
import {TextField,Button,Form,Checkbox} from '@adobe/react-spectrum';
import Constant, { url } from "../../../App/Constant";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import axios from 'axios';
import $ from 'jquery';

class SignIn extends React.Component {

    constructor(){
        super();
    }

    componentDidMount(){
      // if(localStorage.getItem('loginStatus')){
      //   window.location.href = "/dashboard";
      // }
      
    }


    callApi = (email) => {

      let { history } = this.props;
        let classRef = this;
        var data = {};
        if(email.type == "press"){

            data =  {
                email:  $("#email").val(),
                password: $("#password").val(),
                channel: "email"
            }
        }else{

            data =  {
                email:  email,
                channel: "facebook"
            }
        }




         if(data.channel == "email" && data.email == "" ){

          $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Email is required</p></div>');
          $("#signin").text('Continue')
          $("#signin").prop('disabled', false);
          setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)

          return;

         }

         if(data.channel == "email" && data.password == "" ){

          $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Password is required</p></div>');
          $("#signin").text('Continue')
          $("#signin").prop('disabled', false);
          setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
          return;

         }

        //  if(data.channel == "email" && data.password !== "" ){

        //   $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>Password is required</p></div>');
        //   $("#signin").text('Continue')
        //   $("#signin").prop('disabled', false);
        //   return;

        //  }

        $("#signin").text('Processing')
        $("#signin").prop('disabled', true);

       
        axios({
            method: 'POST',
            url: Constant.url+"login",
            data: data            
        }).then(function (response) {
            // let response = {};
            // response["data"] = JSON.parse(LocalStorageService.decryptAes(result.data.eData));

            // var url = window.location.host;
            // var channelimageurl = '/images/whatsapp.svg';
            
            if(response.data.code === 200){
              localStorage.setItem('agentStatus', true);
              localStorage.setItem('loginStatus', 1);
              localStorage.setItem('role', response.data.data.role);              
              localStorage.setItem('roleName', response.data.data.roles['role']);
              localStorage.setItem('company_id', response.data.data.company_id);
              localStorage.setItem('user_id', response.data.data.id);    
              localStorage.setItem('agent-photo', response.data.data.first_name + ' ' + response.data.data.last_name);   

        // if super admin is logged in not save this data inti localstorage      
        if(response.data.data.is_vendor !== 1){
          localStorage.setItem('company_msisdn',response.data.data.company.whatsapp_number);
          localStorage.setItem('company_name',response.data.data.company.company_name);
          localStorage.setItem('channelnumber', response.data.data.company.whatsapp_number);

         }
         if(response.data.data.is_vendor == 1){
          localStorage.setItem('is_vendor',response.data.data.is_vendor);
         }
              // localStorage.setItem('company_msisdn',response.data.data.company.whatsapp_number);
              // localStorage.setItem('company_name',response.data.data.company.company_name);
              localStorage.setItem('login_photo_url', response.data.data.photo_url);    
              localStorage.setItem("chat-msg-notification", true);
              localStorage.setItem('access_token', response.data.data.access_token);  

              var channelimageurl = '/images/whatsapp.svg';
              
              localStorage.setItem('channelimage', channelimageurl);
              localStorage.setItem('channeltype', 'whatsapp');
              // localStorage.setItem('channelnumber', response.data.data.company.whatsapp_number);


              localStorage.setItem('rolesceens', response.data.data.roles.screens); 
              localStorage.setItem('can_chat', response.data.data.roles.can_chat); 
              

              $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');


          
              // if(response.data.data.role == 3){             
              if(response.data.data.is_vendor == 1){             
                window.location.href = "/admin-dashboard";
              }
              else{             
                window.location.href = "/dashboard";
              }
            }else{

              // classRef.showDangerToast(response.data.data);
              $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
              setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
              $("#signin").text('Continue')
              $("#signin").prop('disabled', false);
   
            }


        }).then(function (response) {
            
        });

    };

    responseFacebook = (response) => {
        if(response.status == 'unknown'){return;}
        this.callApi(response.email);                     
    }

    responseGoogle = (response) => {
      if(response.error){return;}
        this.callApi(response.profileObj.email);        
    }

    // resetToastPosition = function() {
    //   $('.jq-toast-wrap').removeClass('bottom-left bottom-right top-left top-right mid-center'); // to remove previous position class
    //   $(".jq-toast-wrap").css({
    //     "top": "",
    //     "left": "",
    //     "bottom": "",
    //     "right": ""
    //   }); //to remove previous position style
    // }

    // showDangerToast = function(sajdain) {
    //   'use strict';
    //   this.resetToastPosition();
    //   window.$.toast({
    //     heading: 'Danger',
    //     text: sajdain,
    //     showHideTransition: 'slide',
    //     icon: 'error',
    //     loaderBg: '#f2a654',
    //     position: 'top-center'
    //   })
    // };


    render() {
        return (
            <section className="login_page">
            <div className="mdl">
              <div className="mdl_inner">
                <div className="uk-container uk-container-expand uk-padding-remove">
                  
                <div className="login_page_inner" >
                  <div className="login_side2 h_match desktop BgImg">
                    <img src="/images/berrytalkslogo.svg"/>
                  </div>
                  <div className="login_side1 h_match">
                    <div className="login_side1_inner mobile">
                      <img className="moblogo" src="/images/berrytalkslogodark.svg" />
                      <h1>Login</h1>
                      {/* <p>Don't have an account? <Link to="/signup">Sign up </Link>for a free trial.</p> */}
                      <Form maxWidth="size-3600" isQuiet aria-label="Quiet example" >
                      <div className="fields">
                      <TextField width="100%" maxWidth="100%" marginTop	="size-200"  label="Email address"  scale="medium" id="email" type="email" />
                      </div>
                      <div className="fields">
                      <TextField width="100%" marginTop	="size-200" maxWidth="100%"  label="Password" type="password"  scale="medium"  id="password"  />
                      </div>

                      {/* <div className="fields">
                      <input name="tags" className="form-control tags_fld" id="keywords" placeholder="Enter Episode Keywords" required />
                      </div> */}

                      <div className="fields">
                        <div className="uk-grid uk-grid-collapse uk-child-width-1-1">
                          {/* <div className="">
                            <Checkbox isEmphasized>I agree to the Terms & conditions and Privacy policy</Checkbox>
                          </div> */}

                          <div id="errorAlert"></div>
                          <div id="successAlert"></div>

                          {/* <div className=""> */}
                          <div className="forgot_password">
                            <a href="/forget-password">Forgot password? </a>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="fields spStyle">
                      <Button className="signinBtn"  id="signin" position="relative" marginTop	="size-200" right="size-0" maxWidth="size-1200" variant="cta" onPress={this.callApi}>Continue</Button>
                      </div>
                      </Form>
    
                      {/* <hr></hr> */}
                      
                      {/* <div className="social_login_btns uk-text-center">
                          <div className="uk-width-1-1">
                          <GoogleLogin
                              clientId="96096407429-e9gh28ou39mhh1fm27qm8bne0cm2nvnl.apps.googleusercontent.com"
                              render={renderProps => (
                                <button className="google" onClick={renderProps.onClick}> <span><img src="/images/google-logo.png" alt="" /></span> Continue with Google</button>
                                // <a className="continuewith" onClick={renderProps.onClick} disabled={renderProps.disabled}><img src="/images/sml-google-logo.png" /></a>
                              )}
                              buttonText="Login"
                              onSuccess={this.responseGoogle}
                              onFailure={this.responseGoogle}
                              cookiePolicy={'single_host_origin'}
                            />
                            
                          </div>
                          <div className="uk-width-1-1">
                          <FacebookLogin
                              appId="1471066116276908"
                              callback={this.responseFacebook}
                              fields="name,email,picture"
                              render={renderProps => (
                                // <a className="continuewith" onClick={renderProps.onClick}><img src="/images/fb-logo.png" /></a>
                                <button onClick={renderProps.onClick}><span><img src="/images/facebook icon.png" alt="" /></span> Continue with Facebook</button>
                              )}
                            />                            
                          </div>
                      </div> */}
    
                    </div>
                    <div className=" copyright uk-position-bottom">
                      <p>© 2023 Convex Interactive. All rights reserved.</p>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            
          </section>
        )
    }
}
export default SignIn;