import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { BarChart } from "../../Sections/BarChart";
import { AreaChart } from "../../Sections/AreaChart";
import { StackChart } from "../../Sections/StackChart";
import { MonthTickets } from "../../Sections/MonthTickets";
import { MonthTicketsAgents } from "../../Sections/MonthTicketsAgents";
import { MonthMessages } from "../../Sections/MonthMessages";
import { AgentsMonthMessages } from "../../Sections/AgentsMonthMessages";
import { BotMonthMessages } from "../../Sections/BotMonthMessages";
import { MessagesRates } from "../../Sections/MessagesRates";
import { AgentsMessagesRates } from "../../Sections/AgentsMessagesRates";
import { BotMessagesRates } from "../../Sections/BotMessagesRates";
import { DaySolvedTickets } from "../../Sections/DaySolvedTickets";
import { AgentDaySolvedTickets } from "../../Sections/AgentDaySolvedTickets";
import { BotDaySolvedTickets } from "../../Sections/BotDaySolvedTickets";
import { DayMessages } from "../../Sections/DayMessages";
import { AgentsDayMessages } from "../../Sections/AgentsDayMessages";
import { BotDayMessages } from "../../Sections/BotDayMessages";
import WaveChart from "../../Sections/WaveChart";

import {
  Time,
  InProgress,
  Hourglass,
  CheckmarkOutline,
  ChatBot,
  UserAdmin,
  Error,
  ChevronDown,
  Chat,
  ReplyAll,
  Events,
  Information,
  User,
  UserMultiple,
} from "@carbon/icons-react";
import $ from "jquery";
import axios from "axios";
import Constant from "../../../App/Constant";
export default class FacebookWorkspace extends React.Component {
  constructor() {
    super();
    this.state = {
      all_ticket: 0,
      new_ticket: 0,
      open_ticket: 0,
      pending: 0,
      closed: 0,
      closed_by_bot: 0,
      // closed_by_agent: '',
      all_chats_bot: 0,
      expired: 0,
      total_customers: 0,
      isLoaded: false,

      //agent

      all_chats_agents: 0,
      assigned_chats_agents: 0,
      unassigned_chats_agents: 0,
      closed_by_agent: 0,
      total_agents: 0,
      replies_sent_by_agents: 0,

      isAgentLoaded: false,

      //bot

      all_chats_bot: 0,
      new_chats_bot: 0,
      open_chats_bot: 0,
      total_customers_bot: 0,
      total_incomming_outgoing_msgs_bot: 0,
      total_incomming_msgs_bot: 0,
      total_outgoing_msgs_bot: 0,

      isBotLoaded: false,

      from: "",
      to: "",
      default: "no",

      total_agents_allowed: 0,
      total_agents_rightnow: 0,
      total_conversations_allowed: 0,
      total_conversations_rightnow: 0,
      total_mau_allowed: 0,
      total_mau_rightnow: 0,
      expiry_date: 0,
      activation_date: 0,
      is_expired: 0,
      packageStats: false,
    };
  }

  componentDidMount() {
    var classRef = this;
    var fromdate = $("#fromdate").val();
    var todate = $("#todate").val();

    if (localStorage.getItem("loginStatus") == 1) {
      let screens = localStorage.getItem("rolesceens").split(",");
      var checking = screens.includes("1");
      if (checking == true) {
        $("#mainDataSec").show();
        $("#LimitedSec").hide();

        // $("#LimitedSec").show();
        // $("#mainDataSec").hide();
      }
    } else {
      window.location.href = "/";
    }

    // console.log(localStorage.getItem("role"), "user_data");
    classRef.setState({ from: fromdate, to: todate });
    this.callApi();
    this.callAgentApi();
    this.callBotApi();
    this.populate();
    this.packageApi();

    $("#contypes").text("Over All");
    $("#all").show();
    $("#agent").hide();
    $("#bot").hide();

    setInterval(function () {
      if (classRef.state.default == "no") {
        classRef.callApi();
        classRef.callAgentApi();
        classRef.callBotApi();
        classRef.packageApi();
      }
    }, 10000);
  }

  packageApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "package-stats",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        var package_stats = result.data.data;
        classRef.setState({
          total_agents_allowed: package_stats.total_agents_allowed,
          total_agents_rightnow: package_stats.total_agents_rightnow,
          total_conversations_allowed:
            package_stats.total_conversations_allowed,
          total_conversations_rightnow:
            package_stats.total_conversations_rightnow,
          expiry_date: package_stats.expiry_date,
          activation_date: package_stats.activation_date,
          is_expired: package_stats.is_expired,
          total_mau_allowed: package_stats.total_mau_allowed,
          total_mau_rightnow: package_stats.total_mau_rightnow,
          packageStats: true,
        });
      }
    });
  };

  callApi = (from = "", todate = "") => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "overview",
      data: {
        company_id: localStorage.getItem("company_id"),
        stateFrom: from,
        stateTo: todate,
        channel_type: localStorage.getItem("channeltype"),
      },
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          isLoaded: false,
          new_ticket: stats.new_ticket,
          open_ticket: stats.open_ticket,
          pending: stats.pending,
          closed: stats.closed,
          closed_by_bot: stats.closed_by_bot,
          closed_by_agent: stats.closed_by_agent,
          expired: stats.expired,
          all_ticket: stats.all_ticket,
          total_customers: stats.total_customers,
          all_chats_bot: stats.all_chats_bot
        });
      }
    });
  };

  callAgentApi = (from = "", todate = "") => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "agents-overview",
      data: {
        company_id: localStorage.getItem("company_id"),
        stateFrom: from,
        stateTo: todate,
        channel_type: localStorage.getItem("channeltype"),
      },
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          isAgentLoaded: true,
          all_chats_agents: stats.all_chats_agents,
          assigned_chats_agents: stats.assigned_chats_agents,
          unassigned_chats_agents: stats.unassigned_chats_agents,
          closed_by_agent: stats.closed_by_agent,
          total_agents: stats.total_agents,
          replies_sent_by_agents: stats.replies_sent_by_agents,
        });
      }
    });
  };

  callBotApi = (from = "", todate = "") => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "bot-overview",
      data: {
        company_id: localStorage.getItem("company_id"),
        stateFrom: from,
        stateTo: todate,
        channel_type: localStorage.getItem("channeltype"),
      },
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          isBotLoaded: true,
          all_chats_bot: stats.all_chats_bot,
          new_chats_bot: stats.new_chats_bot,
          open_chats_bot: stats.open_chats_bot,
          total_customers_bot: stats.total_customers_bot,
          total_incomming_outgoing_msgs_bot:
            stats.total_incomming_outgoing_msgs_bot,
          total_incomming_msgs_bot: stats.total_incomming_msgs_bot,
          total_outgoing_msgs_bot: stats.total_outgoing_msgs_bot,
        });
      }
      $("#searchButton").html("Search");
    });
  };

  handleFromChange(event) {
    this.setState({ from: event.target.from });
  }

  searchApi = () => {
    $("#searchButton").html("Searching...");
    let classRef = this;
    var fromdate = $("#fromdate").val();
    var todate = $("#todate").val();
    classRef.setState({ from: fromdate, to: todate, default: "yes" });
    this.callApi(fromdate, todate);
    this.callAgentApi(fromdate, todate);
    this.callBotApi(fromdate, todate);
  };

  populate = () => {
    var classRef = this;
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var to = yyyy + "-" + mm + "-" + dd;
    var from = "2021-01-01";
    classRef.setState({ from: from });
    classRef.setState({ to: to });

    // classRef.setState({from: from,to: to},()=>{
    // classRef.setState({from: from,to: to},()=>{
    //   console.log(this.state.to,'sajdain')
    // });
    // classRef.setState({});

    // console.log("javed", to);
    // console.log("hassam", from);
  };

  PopulateChoiceall = () => {
    $("#contypes").text("Over All");
    $(".tabsStyle li").removeClass("active");
    $(".overAllBtn").addClass("active");
    $("#all").show();
    $("#agent").hide();
    $("#bot").hide();

    // $("#all").addClass('uk-flex uk-open');
    // $('.uk-dropdown').removeClass('uk-open');

    // if(choice=="Bot"){

    //   $("#bot").show();
    //   $("#all").hide();
    //   $("#agent").hide();

    // }
  };

  PopulateChoiceagents = () => {
    $("#contypes").text("Agent");
    $(".tabsStyle li").removeClass("active");
    $(".AgentBtn").addClass("active");
    $("#agent").show();
    $("#all").hide();
    $("#bot").hide();
  };
  PopulateChoicebot = () => {
    $("#contypes").text("Bot");
    $(".tabsStyle li").removeClass("active");
    $(".BotBtn").addClass("active");
    $("#bot").show();
    $("#all").hide();
    $("#agent").hide();
  };

  allChats = (event) => {
    var id = event.currentTarget.id;

    if (id == "all-all-chats") {
      localStorage.setItem("stats_type", "all");
      localStorage.setItem("stats_card", "all_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "all-new-chats") {
      localStorage.setItem("stats_type", "all");
      localStorage.setItem("stats_card", "new_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "all-open-chats") {
      localStorage.setItem("stats_type", "all");
      localStorage.setItem("stats_card", "open_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "all-pending-chats") {
      localStorage.setItem("stats_type", "all");
      localStorage.setItem("stats_card", "pending_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "all-resolved-chats") {
      localStorage.setItem("stats_type", "all");
      localStorage.setItem("stats_card", "resolved_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "all-total-customers") {
      window.location.href = "/audience";
    }

    if (id == "agent-all-chats") {
      localStorage.setItem("stats_type", "agent");
      localStorage.setItem("stats_card", "all-chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "agent-assigned-chats") {
      localStorage.setItem("stats_type", "agent");
      localStorage.setItem("stats_card", "assigned_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "agent-unassigned-chats") {
      localStorage.setItem("stats_type", "agent");
      localStorage.setItem("stats_card", "unassigned_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "agent-closed-by-agent") {
      localStorage.setItem("stats_type", "agent");
      localStorage.setItem("stats_card", "closed_by_agent");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "agent-all-agent") {
      window.location.href = "/teams";
    }

    if (id == "bot-all-chats") {
      // window.location.href = "/bot-chat";
      localStorage.setItem("stats_type", "bot");
      localStorage.setItem("stats_card", "all_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "bot-new-chats") {
      localStorage.setItem("stats_type", "bot");
      localStorage.setItem("stats_card", "new_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
      // window.location.href = "/bot-chat";
    }

    if (id == "bot-open-chats") {
      localStorage.setItem("stats_type", "bot");
      localStorage.setItem("stats_card", "open_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
      // window.location.href = "/bot-chat";
    }

    if (id == "bot-customer-bots") {
      localStorage.setItem("stats_type", "bot");
      localStorage.setItem("stats_card", "customer_bots");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
      // window.location.href = "/bot-chat";
    }

    if (id == "bot-total-all-chats") {
      // window.location.href = "/bot-chat";
      localStorage.setItem("stats_type", "bot");
      localStorage.setItem("stats_card", "all_chats");
      localStorage.setItem("stats_dateform", $("#fromdate").val());
      localStorage.setItem("stats_dateto", $("#todate").val());
      window.location.href = "/stats_detail";
    }

    if (id == "bot-inout-messages") {
      window.location.href = "/bot-chat";
    }

    // console.log("daniyal", id);
  };

  render() {
    return (
      <Content>
        <div id="mainDataSec" style={{ display: "none" }}>
          <div className="topbarFixed">
            <ul className="tabsStyle">
              <li className="active overAllBtn">
                <a href="javascript:;" onClick={this.PopulateChoiceall}>
                  Over All
                </a>
              </li>
              <li className="AgentBtn">
                <a href="javascript:;" onClick={this.PopulateChoiceagents}>
                  Agent
                </a>
              </li>
              <li className="BotBtn">
                <a href="javascript:;" onClick={this.PopulateChoicebot}>
                  Bot
                </a>
              </li>
            </ul>
            <div className="uk-float-right">
              <form
                className="uk-grid-small"
                uk-grid=""
                action="javascript:void(0);"
                id="chatform"
                method="post"
                enctype="multipart/form-data"
              >
                <div className="uk-width-auto@m uk-width-1-2@s">
                  <label className="lineht">From Date</label>
                </div>
                <div className="uk-width-auto">
                  <input
                    type="date"
                    id="fromdate"
                    min="2021-07-01"
                    name="fromdate"
                    onChange={this.handleFromChange}
                    className="uk-select uk-border-rounded uk-form-width-medium"
                  />
                </div>
                <div className="uk-width-auto">
                  <label className="lineht">To Date</label>
                </div>
                <div className="uk-width-auto">
                  <input
                    type="date"
                    id="todate"
                    min="2021-07-01"
                    name="todate"
                    className="uk-select uk-border-rounded uk-form-width-medium"
                  />
                </div>
                <div className="uk-width-auto">
                  <button
                    style={{ color: "white", background: "#007AD7" }}
                    onClick={this.searchApi}
                    id="searchButton"
                    type="submit"
                    className="uk-button uk-button-defaul uk-border-rounded"
                  >
                    {" "}
                    Search{" "}
                  </button>
                </div>
                <div className="uk-width-auto">
                  <button
                    style={{ color: "white", background: "#007AD7" }}
                    type="submit"
                    onClick={() => window.location.reload(false)}
                    className="uk-button uk-button-defaul uk-border-rounded"
                  >
                    {" "}
                    Clear{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="DateFilterIcon">
            {/* <a href="#menuIcon2" uk-toggle=""></a> */}
            <a href="#mobiledateFilter" uk-toggle="">
              Filter by Date
              {/* <img src="/images/dateFilter.svg" uk-svg="" /> */}
            </a>
          </div>

          <div id="mobiledateFilter" className="sidebar mobile" uk-offcanvas="">
            <div className="uk-offcanvas-bar">
              <button
                className="uk-offcanvas-close"
                type="button"
                uk-close=""
              ></button>
              <div
                className="mobiledateFilter uk-grid-small uk-flex-center"
                uk-height-match=".uk-card"
                uk-grid=""
              >
                <div className="uk-width-1-1">
                  <div className="uk-float-right">
                    <form
                      className="uk-grid-small"
                      uk-grid=""
                      action="javascript:void(0);"
                      id="chatform"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <div className="uk-width-auto@m uk-width-1-1@s">
                        <label className="lineht">From Date</label>
                      </div>
                      <div className="uk-width-auto@m uk-width-1-1@s">
                        <input
                          type="date"
                          id="fromdate"
                          min="2021-07-01"
                          name="fromdate"
                          onChange={this.handleFromChange}
                          className="uk-select fromdate uk-border-pill uk-form-width-medium"
                        />
                      </div>
                      <div className="uk-width-auto@m uk-width-1-1@s">
                        <label className="lineht">To Date</label>
                      </div>
                      <div className="uk-width-auto@m uk-width-1-1@s">
                        <input
                          type="date"
                          id="todate"
                          min="2021-07-01"
                          name="todate"
                          className="todate uk-select uk-border-pill uk-form-width-medium"
                        />
                      </div>
                      <div className="uk-width-auto@m uk-width-1-1@s">
                        <button
                          style={{ color: "white", background: "#007AD7" }}
                          onClick={this.searchApi}
                          id="searchButton"
                          type="submit"
                          className="DataFormBtn uk-button uk-button-defaul uk-border-pill"
                        >
                          {" "}
                          Search{" "}
                        </button>
                      </div>
                      <div className="uk-width-auto@m uk-width-1-1@s">
                        <button
                          style={{ color: "white", background: "#007AD7" }}
                          type="submit"
                          onClick={() => window.location.reload(false)}
                          className="DataFormBtn uk-button uk-button-defaul uk-border-pill"
                        >
                          {" "}
                          Clear{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-container ContainerCustom">
            <div id="all" className="all">
              <div className="agenList">
                {/* <ul>
              <li><a><User /> <p>Agents: <span>14/15</span></p></a></li>
              <li><a><Chat /> <p>Conversations: <span>67/5000</span></p></a></li>
              <li><a><UserMultiple /> <p>MAU: <span>4/4500</span></p></a></li>
              <li><a><p>Expiry Date:  <span>20 Nov 2022</span></p></a></li>
            </ul> */}

                {this.state.packageStats == true ? (
                  <ul>
                  <li>
                  <Link className="" to="/users">
                      <User />{" "}
                      <p>
                        Agents:{" "}
                        <span>
                          {this.state.total_agents_rightnow}/
                          {this.state.total_agents_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                  <Link className="" to="/conversation-detail">
                      <Chat />{" "}
                      <p>
                        Conversations:{" "}
                        <span>
                          {this.state.total_conversations_rightnow}/
                          {this.state.total_conversations_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                  <Link className="" to="/monthly-active-users">
                      <UserMultiple />{" "}
                      <p>
                        MAU:{" "}
                        <span>
                          {this.state.total_mau_rightnow}/
                          {this.state.total_mau_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                  <a>
                        <p>
                          Activation Date: <span>{this.state.activation_date}</span>
                        </p>
                      </a>
                    <a>
                      <p>
                        Expiry Date: <span>{this.state.expiry_date}</span>
                      </p>
                    </a>
                  </li>
                </ul>
                ) : (
                  ""
                )}
              </div>
              <div className="stats_container">
                <div
                  className="uk-child-width-expand@s uk-grid uk-grid-collapse"
                  uk-grid=""
                >
                  <div>
                    <div
                      id="all-all-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.all_ticket}</h6>
                          <span>All Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <Time />
                        <div
                          className="info"
                          uk-tooltip="Number of conversations"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="all-new-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.new_ticket}</h6>
                          <span>New Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <InProgress />

                        <div
                          className="info"
                          uk-tooltip="Number of conversations today"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="all-open-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.open_ticket}</h6>
                          <span>Open Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <Chat />
                        <div
                          className="info"
                          uk-tooltip="Number of conversations that are unserolved"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="all-pending-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.pending}</h6>
                          <span>Pending</span>
                        </div>
                      </div>
                      <div className="icon">
                        <Hourglass />

                        <div
                          className="info"
                          uk-tooltip="Number of conversations that are not replied by agents"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      id="all-resolved-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.closed}</h6>
                          <span>Resolved</span>
                        </div>
                      </div>
                      <div className="icon">
                        <CheckmarkOutline />

                        <div
                          className="info"
                          uk-tooltip="Number of conversations that are not replied by agents"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="bot-total-all-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.all_chats_bot}</h6>
                          <span>Total Bot Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <Events />

                        <div
                          className="info"
                          uk-tooltip="Number of customers approached"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>
            <div className="box">
              <div className="text">
                <h6>{this.state.expired}</h6>
                <span>Expired</span>
                {(this.state.isLoaded) ? 
                <div className="card_txt"><h6>{this.state.expired}</h6><span>Expired</span></div>:
                <div class="uk-position"><div uk-spinner=""></div></div>}
              </div>
              <div className="icon">
                <Error />
              </div>
            </div>
          </div> */}
                </div>
              </div>
              <div className="graphs_container">
                <div className="graphs_section">
                  <div
                    className="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s"
                    uk-grid=""
                    uk-height-match
                  >
                    <div className="">
                      <div className="head">
                        <h5>Month wise Chat Status</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        <MonthTickets
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                        {/* <StackChart /> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="head">
                        <h5>Month wise Total message</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        <MonthMessages
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                        {/* <BarChart /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="graphs_section uk-margin-medium-top">
                  <div
                    className="uk-grid uk-child-width-1-3@m uk-child-width-1-1@s "
                    uk-grid=""
                  >
                    <div>
                      <div className="head">
                        <h5>Message Delivery Rate</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        {/* <AreaChart /> */}
                        <MessagesRates
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="head">
                        <h5>Resolved Chats</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        {/* <BarChart /> */}
                        <DaySolvedTickets
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="head">
                        <h5>Messages per Day Average</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        {/* <BarChart /> */}
                        <DayMessages
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="agent" className="agent">
              <div className="agenList">
                {/* <ul>
              <li><a><User /> <p>Agents: <span>14/15</span></p></a></li>
              <li><a><Chat /> <p>Conversations: <span>67/5000</span></p></a></li>
              <li><a><UserMultiple /> <p>MAU: <span>4/4500</span></p></a></li>
              <li><a><p>Expiry Date:  <span>20 Nov 2022</span></p></a></li>
            </ul> */}
                {this.state.packageStats == true ? (
                  <ul>
                  <li>
                  <Link className="" to="/users">
                      <User />{" "}
                      <p>
                        Agents:{" "}
                        <span>
                          {this.state.total_agents_rightnow}/
                          {this.state.total_agents_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                  <Link className="" to="/conversation-detail">
                      <Chat />{" "}
                      <p>
                        Conversations:{" "}
                        <span>
                          {this.state.total_conversations_rightnow}/
                          {this.state.total_conversations_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                  <Link className="" to="/monthly-active-users">
                      <UserMultiple />{" "}
                      <p>
                        MAU:{" "}
                        <span>
                          {this.state.total_mau_rightnow}/
                          {this.state.total_mau_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                  <a>
                        <p>
                          Activation Date: <span>{this.state.activation_date}</span>
                        </p>
                      </a>
                    <a>
                      <p>
                        Expiry Date: <span>{this.state.expiry_date}</span>
                      </p>
                    </a>
                  </li>
                </ul>
                ) : (
                  ""
                )}
              </div>
              <div className="stats_container">
                <div
                  className="uk-child-width-expand@s uk-grid uk-grid-collapse"
                  uk-grid=""
                >
                  <div>
                    <div
                      id="agent-all-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.all_chats_agents}</h6>
                          <span>All Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <Time />
                        <div
                          className="info"
                          uk-tooltip="Number of conversations"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="agent-assigned-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.assigned_chats_agents}</h6>
                          <span>Assign Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <InProgress />
                        <div
                          className="info"
                          uk-tooltip="Number of assign conversations"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="agent-unassigned-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.unassigned_chats_agents}</h6>
                          <span>UnAssigned Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <CheckmarkOutline />
                        <div
                          className="info"
                          uk-tooltip="Number of unassign conversations"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="agent-closed-by-agent"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.closed_by_agent}</h6>
                          <span>Solved by Agent</span>
                        </div>
                      </div>
                      <div className="icon">
                        <UserAdmin />
                        <div
                          className="info"
                          uk-tooltip="Number of conversations solved by agents"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="agent-all-agent"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.total_agents}</h6>
                          <span>Total Agents</span>
                        </div>
                      </div>
                      <div className="icon">
                        <ChatBot />
                        <div
                          className="info"
                          uk-tooltip="Number of total agents"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="box" uk-height-match="">
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.replies_sent_by_agents}</h6>
                          <span>Total Replies Sent</span>
                        </div>
                      </div>
                      <div className="icon">
                        <ReplyAll />
                        <div
                          className="info"
                          uk-tooltip="Number of agent replies"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>
    <div className="box">
      <div className="text">
        <h6>{this.state.expired}</h6>
        <span>Expired</span>
        {(this.state.isLoaded) ? 
        <div className="card_txt"><h6>{this.state.expired}</h6><span>Expired</span></div>:
        <div class="uk-position"><div uk-spinner=""></div></div>}
      </div>
      <div className="icon">
        <Error />
      </div>
    </div>
  </div> */}
                </div>
              </div>
              <div className="graphs_container">
                <div className="graphs_section">
                  <div
                    className="uk-grid uk-child-width-1-2"
                    uk-grid=""
                    uk-height-match
                  >
                    <div className="">
                      <div className="head">
                        <h5>Month wise Chat Status</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        <MonthTicketsAgents
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                        {/* <StackChart /> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="head">
                        <h5>Month wise Total message</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        <AgentsMonthMessages
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                        {/* <BarChart /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="graphs_section uk-margin-medium-top">
                  <div
                    className="uk-grid uk-child-width-1-3@m uk-child-width-1-1@s"
                    uk-grid=""
                  >
                    <div>
                      <div className="head">
                        <h5>Message Delivery Rate</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        {/* <AreaChart /> */}
                        <AgentsMessagesRates
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="head">
                        <h5>Resolved Chats</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        {/* <BarChart /> */}
                        <AgentDaySolvedTickets
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="head">
                        <h5>Messages per Day Average</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        {/* <BarChart /> */}
                        <AgentsDayMessages
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bot" className="bot">
              <div className="agenList">
                {/* <ul>
              <li><a><User /> <p>Agents: <span>14/15</span></p></a></li>
              <li><a><Chat /> <p>Conversations: <span>67/5000</span></p></a></li>
              <li><a><UserMultiple /> <p>MAU: <span>4/4500</span></p></a></li>
              <li><a><p>Expiry Date:  <span>20 Nov 2022</span></p></a></li>
            </ul> */}
                {this.state.packageStats == true ? (
                  <ul>
                  <li>
                  <Link className="" to="/users">
                      <User />{" "}
                      <p>
                        Agents:{" "}
                        <span>
                          {this.state.total_agents_rightnow}/
                          {this.state.total_agents_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                  <Link className="" to="/conversation-detail">
                      <Chat />{" "}
                      <p>
                        Conversations:{" "}
                        <span>
                          {this.state.total_conversations_rightnow}/
                          {this.state.total_conversations_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                  <Link className="" to="/monthly-active-users">
                      <UserMultiple />{" "}
                      <p>
                        MAU:{" "}
                        <span>
                          {this.state.total_mau_rightnow}/
                          {this.state.total_mau_allowed}
                        </span>
                      </p>
                      </Link>
                  </li>
                  <li>
                    <a>
                        <p>
                          Activation Date: <span>{this.state.activation_date}</span>
                        </p>
                      </a>
                    <a>
                      <p>
                        Expiry Date: <span>{this.state.expiry_date}</span>
                      </p>
                    </a>
                  </li>
                </ul>
                ) : (
                  ""
                )}
              </div>
              <div className="stats_container">
                <div
                  className="uk-child-width-expand@s uk-grid uk-grid-collapse"
                  uk-grid=""
                >
                  <div>
                    <div
                      id="bot-all-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.all_chats_bot}</h6>
                          <span>All Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <Time />
                        <div
                          className="info"
                          uk-tooltip="Number Of Conversations"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="bot-new-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.new_chats_bot}</h6>
                          <span>New Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <InProgress />
                        <div
                          className="info"
                          uk-tooltip="Number of conversations today"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="bot-open-chats"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.open_chats_bot}</h6>
                          <span>Open Chats</span>
                        </div>
                      </div>
                      <div className="icon">
                        <Chat />
                        <div
                          className="info"
                          uk-tooltip="Number of conversations that are unresolved"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      id="bot-customer-bots"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.total_customers_bot}</h6>
                          <span>Total Customer Bot</span>
                        </div>
                      </div>
                      <div className="icon">
                        <Hourglass />
                        <div
                          className="info"
                          uk-tooltip="Number of conversations by bot"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      id="bot-inout-messages"
                      onClick={this.allChats}
                      className="box"
                      uk-height-match=""
                    >
                      <div className="text">
                        <div className="card_txt">
                          <h6>
                            {this.state.total_incomming_outgoing_msgs_bot}
                          </h6>
                          <span>Total In/Out Messages</span>
                        </div>
                      </div>
                      <div className="icon">
                        <CheckmarkOutline />
                        <div
                          className="info"
                          uk-tooltip="Number of incoming and outgoing messages"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="box" uk-height-match="">
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.total_incomming_msgs_bot}</h6>
                          <span>Total Incoming Messages</span>
                        </div>
                      </div>
                      <div className="icon">
                        <UserAdmin />
                        <div
                          className="info"
                          uk-tooltip="Number of incoming messages"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="box" uk-height-match="">
                      <div className="text">
                        <div className="card_txt">
                          <h6>{this.state.total_outgoing_msgs_bot}</h6>
                          <span>Total Outgoing Messages</span>
                        </div>
                      </div>
                      <div className="icon">
                        <ReplyAll />
                        <div
                          className="info"
                          uk-tooltip="Number of outgoing messages"
                        >
                          <Information />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>
    <div className="box">
      <div className="text">
        <h6>{this.state.expired}</h6>
        <span>Expired</span>
        {(this.state.isLoaded) ? 
        <div className="card_txt"><h6>{this.state.expired}</h6><span>Expired</span></div>:
        <div class="uk-position"><div uk-spinner=""></div></div>}
      </div>
      <div className="icon">
        <Error />
      </div>
    </div>
  </div> */}
                </div>
              </div>
              <div className="graphs_container">
                <div className="graphs_section">
                  <div
                    className="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s"
                    uk-grid=""
                    uk-height-match
                  >
                    <div className="">
                      <div className="head">
                        <h5>Month wise Total message</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        <BotMonthMessages
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                        {/* <BarChart /> */}
                      </div>
                    </div>

                    <div className="">
                      <div className="head">
                        <h5>Message Delivery Rate</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        {/* <AreaChart /> */}
                        <BotMessagesRates
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="graphs_section uk-margin-medium-top">
                  <div
                    className="uk-grid uk-child-width-1-2@m uk-child-width-1-1@s"
                    uk-grid=""
                    uk-height-match
                  >
                    <div className="">
                      <div className="head">
                        <h5>Resolved Chats</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        <BotDaySolvedTickets
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>

                    <div className="">
                      <div className="head">
                        <h5>Messages per Day Average</h5>
                      </div>
                      <div className="uk-card uk-card-default uk-padding-small">
                        {/* <AreaChart /> */}
                        <BotDayMessages
                          stateTo={this.state.to}
                          stateFrom={this.state.from}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="LimitedSec">
            <div className="SupportMain">
              <h2>
                <b>
                  Hi, {localStorage.getItem("agent-photo")} welcome to
                  BerryTalk?
                </b>
              </h2>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
