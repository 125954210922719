import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import $ from "jquery";
import Constant from "../../../App/Constant";
import IntegrationsSidebar from "../../Common/IntegrationsSidebar";
import axios from "axios";
import { AddAlt, Close, Unlink, TextLink, Settings } from "@carbon/icons-react";
import FacebookLogin from 'react-facebook-login';



class FB_Integrate extends React.Component {
  showDisconnect = (mobile, name, id) => {
    $("#txtConfirmDisconnect").val("");
    this.setState({ companyMobile: mobile, companyName: name, channel_id: id });
    $("#disconnectAcountModal").addClass("uk-flex uk-open");
  };

  hideDisconnect = () => {
    this.setState({ companyMobile: "", companyName: "", channel_id: 0 });
    $("#disconnectAcountModal").removeClass("uk-flex uk-open");
  };

  showConnect = (mobile, name, id) => {
    $("#txtConfirmConnect").val("");
    this.setState({ companyMobile: mobile, companyName: name, channel_id: id });
    $("#ConnectAcountModal").addClass("uk-flex uk-open");
  };

  hideConnect = () => {
    this.setState({ companyMobile: "", companyName: "", channel_id: 0 });
    $("#ConnectAcountModal").removeClass("uk-flex uk-open");
  };

  addAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    if ($("input#termsPolicy").is(":checked")) {
      let c_code = $("#country_code").val();
      let number = $("#whatsapp_number").val();
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("c_code", c_code);
      compParams.append("number", number);
      compParams.append("type", "whatsapp");
      compParams.append("name", "Convex");
      axios({
        method: "POST",
        url: Constant.url + "add-company-channel",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
          $("#termsPolicy").prop("checked", false);
          $("#whatsapp_number").val("");
          classRef.companyChannelsApi();
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 1000);
        }
      });
    } else {
      $("#errorAlert").append(
        '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please agree Terms & conditions before send request.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog").close();
      }, 1000);
    }
  };

  constructor() {
    super();
    this.companyChannelsApi = this.companyChannelsApi.bind(this);
    this.handleChannels = this.handleChannels.bind(this);
    this.state = {
      accountList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      companyMobile: "",
      companyName: "",
      companyId: 0,
    };
  }

  companyChannelsApi = () => {
    let classRef = this;

    var url = window.location.pathname;
    var id = url.split('/');

    if (id[2] == 'facebook-messenger') {
      var type = 'facebook'
    }

    let company_id = localStorage.getItem("company_id");
    let compParams = new FormData();
    compParams.append("company_id", company_id);
    compParams.append('type', type);
    axios({
      method: "POST",
      url: Constant.url + "company-channels",
      data: compParams,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          accountList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  componentDidMount() {
    this.companyChannelsApi();
  }

  connectAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let txtConfirm = $("#txtConfirmConnect").val();
    let channel_id = classRef.state.channel_id;
    if (txtConfirm != "" && txtConfirm == "connect") {
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("channel_id", channel_id);
      compParams.append("is_active", "yes");
      axios({
        method: "POST",
        url: Constant.url + "change-channel-status",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successConnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
          );
          setTimeout(function () {
            $("#ConnectAcountModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
          classRef.companyChannelsApi();
        } else {
          $("#errorConnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
        }
      });
    } else {
      $("#errorConnectAlert").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please type connect in input field.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  disconnectAccount = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let txtConfirm = $("#txtConfirmDisconnect").val();
    let channel_id = classRef.state.channel_id;
    if (txtConfirm != "" && txtConfirm == "disconnect") {
      let compParams = new FormData();
      compParams.append("company_id", company_id);
      compParams.append("channel_id", channel_id);
      compParams.append("is_active", "no");



      axios({
        method: "POST",
        url: Constant.url + "change-channel-status",
        data: compParams,
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successDisconnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
          );
          setTimeout(function () {
            $("#disconnectAcountModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
          classRef.companyChannelsApi();
        } else {
          $("#errorDisconnectAlert").append(
            '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
          );
          setTimeout(function () {
            UIkit.alert("#msg-dialog1").close();
          }, 1000);
        }
      });
    } else {
      $("#errorDisconnectAlert").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please type disconnect in input field.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  handleChannels = () => {
    let result = "";
    let index = 0;

    result = this.state.accountList.list.map((response, index) => (
      <tr>
        <td width={90}>
          <img className="uk-border-circle" src={response.icon} alt="" />
        </td>
        <td>
          {/* <h5>{response.name}</h5> */}
          <h5>{response.name}</h5>
          {/* <p>{response.c_code + response.number}</p> */}
          <p>{response.number}</p>
        </td>
        <td width={100}>
          <span className="status">{response.status}</span>
        </td>
        <td width={100}>
          <Link to={"/integrations/facebook-messenger/settings/" + response.id}> <Settings /> Settings</Link>
        </td>
        <td width={150}>
          {response.is_active == "Disconnected" ? (
            <button
              onClick={(e) =>
                this.showConnect(
                  response.c_code + response.number,
                  response.name,
                  response.id
                )
              }
              className="disconnect success"
            >
              <TextLink />
              Disconnected
            </button>
          ) : (
            <button
              onClick={(e) =>
                this.showDisconnect(
                  response.number,
                  response.name,
                  response.id
                )
              }
              className="disconnect danger"
            >
              <Unlink />
              Connected
            </button>
          )}
        </td>
      </tr>
    ));
    return result;
  };


  //   responseFacebook = (response) => {
  //     console.log(response)
  // }


  responseFacebook = (response) => {
    // console.log(response, 'responsefb')
    let company_id = localStorage.getItem('company_id');
    let classRef = this;
    window.FB.api('/' + response.userID + '/accounts?fields=name,access_token&access_token=' + response.accessToken, function (res) {
      // console.log(res, 'resres');
      // var lastIndex = res.data.length - 1;
      var lastIndex = 0;
      axios({
        method: 'POST',
        url: Constant.url + "add-company-channel-facebook",
        data: {
          company_id: company_id,
          type: "facebook",
          name: res.data[lastIndex].name,
          facebook_api_url: res.data[lastIndex].name,
          page_access_token: res.data[lastIndex].access_token,
          page_id: res.data[lastIndex].id
        }
      }).then(function (result) {
        if (result.data.status === true) {
          window.location.reload();
        }
      })

    });


  }

  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>
                  Integrations <span> {">"} Facebook Messenger</span>
                </h3>
              </div>
            </div>
          </div>
          <IntegrationsSidebar />
          <div className="integration inner h_match">
            <div className="uk-flex-top uk-grid">
              <div className="uk-width-auto@m uk-flex-first">
                <img className="Fimg" src="/images/facebook.png" alt="" />
              </div>
              <div className="uk-width-3-4">
                <h3>Facebook Messenger</h3>
                <p>Sell products, book appointments, nurture leads, capture contact info, and build relationships all through Messenger with Berry Talks multi-agent platform.</p>



                {this.state.accountList.isLoaded ? "" : <div className="addnum_box uk-margin-medium-top">
                  <div className="fields spStyle">
                    <FacebookLogin
                      appId="235839840615867"
                      cookie="true"
                      callback={this.responseFacebook}
                      fields="email"
                      scope="public_profile,email,pages_messaging,pages_manage_metadata,pages_show_list,pages_manage_engagement"
                      render={renderProps => (
                        <button className="addRowBtn"> <span> <AddAlt /></span> Add another</button>
                        // <a className="continuewith" onClick={renderProps.onClick}><img src="images/fb_icon.svg" /><span>Continue with Facebook</span></a>
                      )}
                    />
                  </div>
                </div>}

                {/**ADD NUM */}
                {/* <div className="addnum_box uk-margin-medium-top">
                  <div className="fields spStyle">
                    <FacebookLogin
                          appId="235839840615867"
                          callback={this.responseFacebook}
                          fields="public_profile,email,pages_messaging,pages_manage_metadata"
                          render={renderProps => (
                              <button className="addRowBtn"> <span> <AddAlt /></span> Add another</button>
                              // <a className="continuewith" onClick={renderProps.onClick}><img src="images/fb_icon.svg" /><span>Continue with Facebook</span></a>
                          )}
                    />
                  </div>
                </div> */}


                <p>Click on above button to login and select Facebook page which you want to connect with Berry Talks UCaaS platform</p>
                {/**ADD NUM */}

                {/**Connected */}
                <div className="connected" id="connected">
                  <h4>Connected Facebook Pages</h4>
                  <div className="AccountCampain">
                    <table class="uk-table uk-table-middle">
                      {this.state.accountList.isLoaded
                        ? this.handleChannels()
                        : ""}
                    </table>
                  </div>

                  {/* <div className="uk-width-1-1 uk-margin-medium-top">
                    <button className="addRowBtn">
                      {" "}
                      <span>
                        {" "}
                        <AddAlt />
                      </span>{" "}
                      Add another
                    </button>
                  </div> */}
                </div>
                <div>
                  <p>You need to disconnect this page if you want to connect another Facebook page to Berry Talks.</p>
                </div>
                {/**Connected */}
              </div>
            </div>
          </div>
        </div>

        {/* Disconnected Popup */}
        <div
          id="disconnectAcountModal"
          className="disconectAcount ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              Disconnect Facebook Account{" "}
              <span className="uk-inline uk-float-right">
                <img src="/images/warning--alt--filled.png" alt="" />
              </span>
            </h3>
            <p className="">
              <b>
                Are you sure you want to disconnect this Facebook Account from Berry Talks
              </b>
            </p>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-flex-middle uk-grid uk-grid-small ">
                <div className="uk-width-auto uk-flex-first">
                  <img className="uk-border-circle" src="/images/facebook.png" alt="" />
                </div>
                <div className="uk-width-expand">
                  <h5>{this.state.companyName}</h5>
                  <p>{this.state.companyMobile}</p>
                </div>
              </div>
              <div className="uk-margin">
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="txtConfirmDisconnect"
                    name="txtConfirmDisconnect"
                    type="text"
                    placeholder="Confirm by typing disconnect here"
                  />
                </div>
              </div>
              <div id="errorDisconnectAlert"></div>
              <div id="successDisconnectAlert"></div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.hideDisconnect}>
                    Cancel
                  </button>
                  <button className="confirm" onClick={this.disconnectAccount}>
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Connected Popup */}
        <div
          id="ConnectAcountModal"
          className="disconectAcount ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              Connect Facebook Account{" "}
              <span className="uk-inline uk-float-right">
                <img src="/images/warning--alt--filled.png" alt="" />
              </span>
            </h3>
            <p className="">
              <b>
                Are you sure you want to connect this Facebook Account from
                telenor WABA
              </b>
            </p>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-flex-middle uk-grid uk-grid-small ">
                <div className="uk-width-auto uk-flex-first">
                  <img className="uk-border-circle" src="/images/whatsapp.png" alt="" />
                </div>
                <div className="uk-width-expand">
                  <h5>{this.state.companyName}</h5>
                  <p>{this.state.companyMobile}</p>
                </div>
              </div>
              <div className="uk-margin">
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="txtConfirmConnect"
                    name="txtConfirmConnect"
                    type="text"
                    placeholder="Confirm by typing connect here"
                  />
                </div>
              </div>
              <div id="errorConnectAlert"></div>
              <div id="successConnectAlert"></div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.hideConnect}>
                    Cancel
                  </button>
                  <button className="confirm" onClick={this.connectAccount}>
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}
export default FB_Integrate;
