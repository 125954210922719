import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, Checkbox } from "@adobe/react-spectrum";
import {
  Checkmark,
  FaceSatisfied,
  Image,
  Attachment,
  Location,
  DecisionTree,
  Edit,
  StopOutline,
  TrashCan,
  Download,
  MobileCheck, CheckmarkOutline, GenderFemale, Time, Credentials, Add, Close
} from "@carbon/icons-react";
import PageActionBar from "../../Sections/PageActionBar";
import Constant from "../../../App/Constant";
import axios from 'axios';
import $ from 'jquery';
class EditBuilder extends React.Component {

  constructor() {
    super();    

  }

  componentDidMount() {    
    
  }

 
  render() {
    return (
      <Content>
       <iframe className="IframeEmbd" src={"https://yolo.convexinteractive.com:10085/flowbuilder_convex/visual_flow_builder/edit_builder_data/"+window.location.href.substring(window.location.href.lastIndexOf('/') + 1)+"/1/fb?msisdn="+localStorage.getItem('company_msisdn')+"&company_id="+localStorage.getItem('company_id')} width={"1700px"} height={'1000px'} title="description"></iframe>
      

       <div id="Builder" className="AlertPOPUP ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3 >
              <span className="uk-inline"> Warning</span> 
              <span className="uk-inline uk-float-right"> 
                  <img src="/images/done_all_black_24dp.svg" />{" "} 
              </span>
            </h3>
            <div className="resolved_content">
              <p><b>Are you sure you want to delete Segment?</b></p>
              <p className="small">You won't be able to revert this!</p>

              <form className="uk-form-stacked uk-margin-medium-top" action="javascript:void(0);">
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>Cancel</button>
                  <button className="AddTeamBtn" id="addconsent" onClick={this.deleteSegment}>Confirm</button>
                </div>
            </form>
            </div>
          </div>
        </div>
      
      </Content>
    );
  }
}

export default EditBuilder;
