import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown,
    Timer,
    Chat,
    Forum,
    ChatLaunch,
  } from "@carbon/icons-react";

import { ChannelsOverviewChart } from "../../Sections/ChannelsOverviewChart";
import { DateRange } from "../../Common/DateRange";
import { ChatbotTotalSession } from "../../Sections/ChatbotTotalSession";
import { ChatBotMessageChart } from "../../Sections/ChatBotMessageChart";
import { ChatBotUserChart } from "../../Sections/ChatBotUserChart";
// import DateRangePicker from 'rsuite/DateRangePicker';
// // import { DateRangePicker, Stack } from 'rsuite';
// import subDays from 'date-fns/subDays';
// import startOfWeek from 'date-fns/startOfWeek';
// import endOfWeek from 'date-fns/endOfWeek';
// import addDays from 'date-fns/addDays';
// import startOfMonth from 'date-fns/startOfMonth';
// import endOfMonth from 'date-fns/endOfMonth';
// import addMonths from 'date-fns/addMonths';
  
class InsightChatboxOverview extends React.Component {

    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2023-01-30";
        var from = "2021-01-01";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }


MultiSelectFilter =()=>{
    $(".UltabsBtns").toggleClass("showMenu");
    $(".UltabsBtns > li label").click(function(){
        $(".MultiSelectFilter > p span").text($(this).text());
    });
}


  
  

    render() {
        return (
         <>
            <div uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Chatbot Overview</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">

                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>All Channels</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>




                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Summary</h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Chat  className="iconBara" /> Total Chats</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>29,384</h3>
                                <p>Total Chats</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>10</h3>
                                <p>Avg. chats per user</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>203,820</h3>
                                <p>Total users</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Forum  className="iconBara" /> Engagement
                                            {/* <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                                <div uk-dropdown="mode: click">
                                                    <h5>All response time</h5>
                                                    <p>The metric that displays agent's responsiveness.</p>
                                                </div>
                                            </div> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3> 19,084 </h3>
                                <p>Total engaged Chats</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>6</h3>
                                <p>Avg. engaged chats per user</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>12,451</h3>
                                <p>Total engaged users
                                    <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                        <div uk-dropdown="mode: click">
                                            <h5>Total engaged users</h5>
                                            <p>User that sent at least 2 messages.</p>
                                        </div>
                                    </div>
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                


                {/* * Total sessions* */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <ul className="tabsBtns desktop uk-heading-divider" uk-switcher="connect: .multiTabNewLiveAgent">
                                <li className="uk-inline infoTool"><a>Total sessions</a></li>
                                <li className="uk-inline infoTool"><a>Average session per user</a></li>
                                <li className="uk-inline infoTool"><a>Total users</a></li>
                            </ul>
                        </div>
                        <ul className="uk-switcher multiTabNewLiveAgent">
                        <li>
                            <div className="uk-width-1-1">
                                <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                    <div className="head"><h5>All Channels Overview</h5></div>
                                        {/* Chart */}
                                        <div className="ChannelsOverviewGraph">
                                            <ChatbotTotalSession
                                            stateTo={this.state.to}
                                            stateFrom={this.state.from} />
                                        </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            111
                        </li>
                        <li>
                            222
                        </li>
                        </ul>
                    </div>
                </div>



                {/* * Messages* */}
                <div className="uk-width-1-2">
                    <div className="uk-card uk-card-custom uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Messages</h5>
                        </div>
                        <div className="uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <ChatBotMessageChart/>
                        </div>
                    </div>
                </div>


                {/* * Users* */}
                <div className="uk-width-1-2">
                    <div className="uk-card  uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Users</h5>
                        </div>
                        <div className="uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <ChatBotUserChart/>
                        </div>
                    </div>
                </div>




                {/* Sessions and messages per chatbot */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Utilization per agent
                            <div className="uk-float-right ">
                                <div className="AgentDropDown">
                                    <a  className="MultiSelectFilter">
                                        <p><span>Export</span> <ChevronDown /></p>
                                        {/* <ul className="UltabsBtns">
                                            <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                                        </ul> */}
                                    </a>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                <th className="uk-table-expand">AGENT</th>
                                <th className="uk-table-shrink uk-text-nowrap">TOTAL SESSIONS</th>
                                <th className="uk-table-shrink uk-text-nowrap">TOTAL USERS</th>
                                <th className="uk-table-shrink uk-text-nowrap">TOTAL MESSAGES</th>
                                <th className="uk-table-shrink uk-text-nowrap">INBOUND</th>
                                <th className="uk-table-shrink uk-text-nowrap">OUTBOUND</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>3,345</span> </td>
                                    <td><span>374</span></td>
                                    <td className="uk-text-nowrap"><span>13,830</span></td>
                                    <td className="uk-text-nowrap"><span>1,726</span></td>
                                    <td className="uk-text-nowrap"><span>9,836</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>1,627</span></td>
                                    <td className="uk-text-nowrap"><span>14,652</span></td>
                                    <td className="uk-text-nowrap"><span>2,628</span></td>
                                    <td className="uk-text-nowrap"><span>3,793</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>1,722</span></td>
                                    <td className="uk-text-nowrap"><span>2,726</span></td>
                                    <td className="uk-text-nowrap"><span>12,802</span></td>
                                    <td className="uk-text-nowrap"><span>28,261</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>1,726</span></td>
                                    <td className="uk-text-nowrap"><span>2,937</span></td>
                                    <td className="uk-text-nowrap"><span>17,262</span></td>
                                    <td className="uk-text-nowrap"><span>12,083</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>17,382</span></td>
                                   <td className="uk-text-nowrap"><span>2,937</span></td>
                                    <td className="uk-text-nowrap"><span>17,262</span></td>
                                    <td className="uk-text-nowrap"><span>12,083</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>374</span></td>
                                   <td className="uk-text-nowrap"><span>2,937</span></td>
                                    <td className="uk-text-nowrap"><span>17,262</span></td>
                                    <td className="uk-text-nowrap"><span>12,083</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>1,627</span></td>
                                   <td className="uk-text-nowrap"><span>2,937</span></td>
                                    <td className="uk-text-nowrap"><span>17,262</span></td>
                                    <td className="uk-text-nowrap"><span>12,083</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>1,722</span></td>
                                   <td className="uk-text-nowrap"><span>2,937</span></td>
                                    <td className="uk-text-nowrap"><span>17,262</span></td>
                                    <td className="uk-text-nowrap"><span>12,083</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Test1</span></td>
                                    <td><span>29:18:18</span> </td>
                                    <td><span>1,726</span></td>
                                   <td className="uk-text-nowrap"><span>2,937</span></td>
                                    <td className="uk-text-nowrap"><span>17,262</span></td>
                                    <td className="uk-text-nowrap"><span>12,083</span></td>
                                </tr>
                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
         </>
    );
}
}
export default InsightChatboxOverview;