import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown
  } from "@carbon/icons-react";
import { ChannelsOverviewChart } from "../../Sections/ChannelsOverviewChart";
import { DateRange } from "../../Common/DateRange";
import { WhatsappBusinessMessStatus } from "../../Sections/WhatsappBusinessMessStatus";
import { BroadCastDeliveryRateChart } from "../../Sections/BroadCastDeliveryRateChart";
import { BroadcastCompRateChart } from "../../Sections/BroadcastCompRateChart";
// import DateRangePicker from 'rsuite/DateRangePicker';
// // import { DateRangePicker, Stack } from 'rsuite';
// import subDays from 'date-fns/subDays';
// import startOfWeek from 'date-fns/startOfWeek';
// import endOfWeek from 'date-fns/endOfWeek';
// import addDays from 'date-fns/addDays';
// import startOfMonth from 'date-fns/startOfMonth';
// import endOfMonth from 'date-fns/endOfMonth';
// import addMonths from 'date-fns/addMonths';
  
class BroadCastComplaints extends React.Component {

    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2023-01-30";
        var from = "2021-01-01";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }


MultiSelectFilter =()=>{
    $(".UltabsBtns").toggleClass("showMenu");
    $(".UltabsBtns > li label").click(function(){
        $(".MultiSelectFilter > p span").text($(this).text());
    });
}


  
  

    render() {
        return (
         <>
            <div uk-grid="" uk-height-match="target: > div > .uk-card">
                
              

             
                 {/* * Completion rate* */}
                 <div className="uk-width-1-2">
                    <div className="uk-card uk-card-custom uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Spam complaints rate</h5>
                        </div>
                        <div className="mychartSession uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <BroadcastCompRateChart/>
                        </div>
                    </div>
                </div>

                 {/* * Completion rate* */}
                 <div className="uk-width-1-2">
                    <div className="uk-card uk-card-custom uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Unsubscribes rate</h5>
                        </div>
                        <div className="mychartSession uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <BroadcastCompRateChart/>
                        </div>
                    </div>
                </div>
            </div>
         </>
    );
}
}
export default BroadCastComplaints;