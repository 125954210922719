import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { AgentStatusChart } from "../../Sections/AgentStatusChart";
import { AssignLiveChat } from "../../Sections/AssignLiveChat";
import { LiveChatPerQueueChart } from "../../Sections/LiveChatPerQueueChart";

import LiveChatBubbleChart from "../../Sections/LiveChatBubbleChart";
import ZoomableBubbleChart from "../../Sections/ZoomableBubbleChart";
  
class InsightsLiveChatAnalytics extends React.Component {
    MultiSelectFilter =()=>{
        $(".UltabsBtns").toggleClass("showMenu");
        $(".UltabsBtns > li label").click(function(){
            $(".MultiSelectFilter > p span").text($(this).text());
        });
    }
    
    render() {
        return (
         <>
            <div className="uk-grid-small" uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Right now</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">
                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>All Channels</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Live chats highlights */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Live chats highlights 
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Highlights</h5>
                                        <p>Current active conversations status distribution and the performance of all queues in the selected period.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1m 48s</h3>
                                <p>Avg. first response time</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1d 19h</h3>
                                <p>Avg. handling time</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>5</h3>
                                <p>SLA breach count</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Live chat breakdown by conversations */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Live chat breakdown by conversations 
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Breakdown by conversations</h5>
                                        <p>Distribution of conversations per current conversation status.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>4,560</h3>
                                <p>Total</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>24</h3>
                                <p><Timer /> Open</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>4</h3>
                                <p><Hourglass /> Waiting</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>4,532</h3>
                                <p className="solvedTxt"><img src="/images/doublecheckmark.svg" uk-svg="" /> Solved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Live chat waiting time 
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>Waiting time</h5>
                                        <p>These metrics shows how long customers are waiting for agents to respond. These results can be filtered by selecting a specific channel from the <b>Channels list</b> above.</p>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Timer className="iconBara" /> First response time
                                            <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                                <div uk-dropdown="mode: click">
                                                    <h5>First response time</h5>
                                                    <p>How long are customers waiting for agent's first response.</p>
                                                </div>
                                            </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>4s</h3>
                                <p>min</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>34m 23s</h3>
                                <p>max</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1m 48s</h3>
                                <p>avg</p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Timer className="iconBara" /> All response time
                                            <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                                <div uk-dropdown="mode: click">
                                                    <h5>All response time</h5>
                                                    <p>How long are customers waiting for agent's first response.</p>
                                                </div>
                                            </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>4s</h3>
                                <p>min</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>34m 23s</h3>
                                <p>max</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>1m 48s</h3>
                                <p>avg</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Agent's staus */}
                <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head">
                            <h5 className="uk-heading-divider">Agent's staus </h5>
                        </div>
                        <AgentStatusChart />
                    </div>
                </div>


                {/* Assigned live chats */}
                <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head">
                            <h5 className="uk-heading-divider">Assigned live chats</h5>
                        </div>
                        <AssignLiveChat/>
                    </div>
                </div>

                 {/*Live chats per queue */}
                 <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head">
                            <h5 className="uk-heading-divider">Live chats per queue</h5>
                        </div>
                        <LiveChatPerQueueChart/>
                    </div>
                </div>


                 {/*Live chats per queue */}
                 <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head">
                            <h5 className="uk-heading-divider">Top most used tags in live chats</h5>
                        </div>
                        {/* <LiveChatBubbleChart/> */}
                        {/* <LiveChatTagChart/> */}
                        {/* <ZoomableBubbleChart/> */}
                    </div>
                </div>



                 {/* Live chat performance per channel */}
                 <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                        <h5 className="uk-heading-divider">Live chat performance per channel
                            <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                <div uk-dropdown="mode: click">
                                    <h5>Performance per channel</h5>
                                    <p>Distribution of the currently active conversations per status for each channel.</p>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand">RANKING</th>
                                    <th className="uk-table-expand ">TOTAL</th>
                                    <th className="uk-table-auto uk-text-nowrap">OPEN</th>
                                    <th className="uk-table-expand uk-text-nowrap">WAITING</th>
                                    <th className="uk-table-shrink uk-text-nowrap">SOLVED</th>
                                    <th className="uk-table-shrink uk-text-nowrap">WAITING TIME</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
         </>
    );
}
}
export default InsightsLiveChatAnalytics;