import * as React from "react";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import { Content } from "@adobe/react-spectrum";
import axios from "axios";
import $ from "jquery";
import Constant from "../../../App/Constant";
import moment from "moment";

class PackagesConsumptionMonthly extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      STARTDATE: moment().startOf("month").format("YYYY-MM-DD"),

      ENDDATE: moment().endOf("month").format("YYYY-MM-DD"),
      companyId: "1",
    };
  }

  componentDidMount() {
    $("#field_wrapper").on("click", ".deleteBtn", function () {
      $(this).parent().parent().parent().remove();
    });

    this.callApi();

    if (localStorage.getItem("loginStatus") == 1) {
      if (localStorage.getItem("is_vendor") == 1) {
      } else {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }

    $("#cross").hide();
  }

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "package-consumption-monthly-stats",
      data: {
        vendor_id: this.state.companyId,
        from: this.state.STARTDATE,
        to: this.state.ENDDATE,
      },
    }).then(function (result) {
      // console.log("resukl", result);
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr>
        <td>
          <span>{response.company}</span>
        </td>
        <td>
          <span>{response.agent}</span>
        </td>
        <td>
          <span>{response.consumed_maus}</span>
        </td>
        <td>
          <span>{response.consumed_conv}</span>
        </td>
        <td>
          <span>{response.bussiness_initiated}</span>
        </td>
        <td>
          <span>{response.user_initiated}</span>
        </td>
        <td>
          <span>{response.broadcast_campaigns}</span>
        </td>
        <td>
          <span>{response.subscription_expiry}</span>
        </td>
      </tr>
    ));

    return result;
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer BroadcastingContainer "
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Package Consumption</h3>
              </div>
            </div>
          </div>
          <div className="tableSec">
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Agent</th>
                  <th>Consumed MAUs</th>
                  <th>Consumed Conversation</th>
                  <th>Business Initiated</th>
                  <th>User Initiated</th>
                  <th>Broadcast Campagins</th>
                  <th>Subscription Expiry</th>

                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.state.dataList.isLoaded ? (
                  this.handleTable()
                ) : this.state.dataList.msg == "not" ? (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        Data not Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        <div className="mdl">
                          <div className="mdl_inner">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Content>
    );
  }
}
export default PackagesConsumptionMonthly;
