import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { Multiselect } from "multiselect-react-dropdown";
import {
  Add,
  CaretDown,
  Edit,
  TrashCan,
  Chat,
  OverflowMenuVertical,
  Download,
  Close,
  Checkmark,
} from "@carbon/icons-react";
import Constant from "../../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
import ReactPaginate from "react-paginate";

class BusinessQueriesReport extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      rowsCountStats: 0,
      advancesearch: "",
    };
  }

  componentDidMount() {
    var pageCount = 1;
    this.callApi(pageCount);
    if (!localStorage.getItem("role") == "3") {
      window.location.href = "/";
    }

    $("#cross").hide();
  }

  EditTeamModal = (id) => {
   
    localStorage.setItem("stats_conversation_id", id);

    window.location.href = "/chat";
  };

  EditTeamBotModal = (id) => {


    localStorage.setItem("bot_stats_conversation_id", id);

    window.location.href = "/bot-chat";


  };

callApi = (pageCount) => {
    let classRef = this;
    // var company_id = localStorage.getItem("company_id");
    var company_id = 8;
    axios({
      method: "POST",
      url: Constant.url + "hico-business-queries-show",
      data: {
        company_id: company_id,
        page: pageCount,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountStats: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountStats: result.data.rowscount,
        });
      }
    });
  };



  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    this.setState({ advancesearch: searchword });

    if (searchword !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "hico-business-queries-show",
      data: {
        // company_id: localStorage.getItem("company_id"),
        company_id: 8,
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountStats: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountStats: result.data.rowscount,
        });
      }

    });
  };

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callApi();
    $("#to_date").val('')
    $("#from_date").val('')
    $("#filter_name").val(null)
    $("#cross").hide();
  };

  EditTeamBotModal = (id) => {
    // console.log("id", id)
    localStorage.setItem("bot_stats_conversation_id", id);

    window.location.href = "/bot-chat";
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response) => (
      <tr>
        <td>{response.query}</td>
        <td>
          <span>{response.name}</span>
        </td>
        <td>{response.city}</td>
        <td>{response.number}</td>
        <td>{response.business_name}</td>
        <td>{response.message}</td>
        <td>{response.contact_time}</td>
        <td>
            <a
              className="iconcircleBtn"
              uk-tooltip="title: Chat History"
              onClick={(e) => this.EditTeamBotModal(response.msisdn)}
              // onClick={(e) => this.EditTeamBotModal(response.conversation_id)}
            >
              <Chat />
            </a>
          </td>      </tr>
    ));
    return result;
  };

  downloadStats = () => {
    let classRef = this;
    // var company_id = localStorage.getItem("company_id");
    var company_id = 8;
    var dateto = $("#to_date").val();
    var datefrom = $("#from_date").val();
    // var search = this.state.advancesearch;
    var search = $("#filter_name").val();
    if(search == null){
      search = ''
    }
    else{
      search = $("#filter_name").val()
    }

    window.open(
      Constant.url +
        "hico-business-queries-export?company_id=" +
        company_id+
        "&dateto="+
        dateto+
        "&filter_name="+
        search+
        "&datefrom="+
        datefrom,
      "_blank"
    );
  };

  handleQuickClick = (data) => {
    let currentPage = data.selected + 1;
    this.QuickSorting(currentPage);
  };

  QuickSorting = (pageCount) => {
  
    let classRef = this;
    let sortingValue = $("#QuickSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;

    // var company_id = localStorage.getItem("company_id");
    var company_id = 8;
    var dateto = $("#to_date").val();
    var datefrom = $("#from_date").val();
   

    axios({
      method: "POST",
      url: Constant.url + "hico-business-queries-show",
      data: {
        company_id: company_id,
        page: pageCount,
        limit: sortingValue,
        dateto: dateto,
        datefrom: datefrom,
        page: pageCount,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountStats: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountStats: result.data.rowscount,
        });
      }
    });
  };

  addAutoSearchModal = () => {
    $("#SearchAutoTeamModal").addClass("uk-flex uk-open");
  };


  AutoadvanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      filter_name:$("#filter_name").val(),
      // company_id: localStorage.getItem("company_id"),
      company_id: 8,
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }

    if (data.filter_name) {
      var key = key + " Query: " + $("#filter_name").val();
    }

    if (key !== "") {
      $("#cross").show();
    }

    classRef.setState({ advancesearch: key });

    axios({
      method: "POST",
      url: Constant.url + "hico-business-queries-show",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountStats: result.data.rowscount,
        });
        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      } else {

     

        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountStats: result.data.rowscount,
        });


        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");


      }
    });
  };

  clearFilters = () => {
    $("#from_date").val("");
    $("#to_date").val("");
    $("#filter_name").val("");
    $("#searchword").val("");
  };


  render() {
    return (
      <Content>
        <div
          className="TeamContainer contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Business Queries </h3>
              </div>

              <div className="uk-width-1-3@m">
              <div className="tagInput">
                  <div className="search_field">
                    <form action="javascript:void(0);">
                      <input
                        className="uk-input"
                        type="text"
                        onChange={this.searchrecord}
                        name="searchword"
                        id="searchword"
                        value={this.state.advancesearch}
                        placeholder="Search through msisdn"
                      />
                    </form>
                    <div class="CampaignReports Filtermain">
                      <div className="uk-width-auto">
                        <button
                          id="cross"
                          style={{ right: "30px" }}
                          onClick={this.advSearchClear}
                        >
                          <Close />
                        </button>
                        <button
                            className="popadvanceFilter"
                            onClick={this.addAutoSearchModal}
                          >
                            <img src="/images/newfilter.svg" uk-svg="" />
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>


              <div className="uk-width-expand"></div>
                <div className="uk-width-auto">
                <button
                    className="newFlowBtn uk-margin-small-top"
                    id="AddBroadcast"
                    onClick={this.downloadStats}>
                    <Add /> <span>Export Stats</span>
                </button>
                </div>


            </div>
          </div>
          <div className="uk-grid">
            <div className="uk-width-expand"></div>

            <div className="numberSorting uk-float-right">
              <div class="uk-inline">
                <label>
                  Show
                  <select
                    onChange={(e) => this.QuickSorting()}
                    id="QuickSortingBtn"
                  >
                    {/* <option disabled selected>Sorting</option> */}
                    <option>10</option>
                    <option>20</option>
                    <option>50</option>
                    <option>500</option>
                  </select>
                  entries
                </label>
              </div>
            </div>
          </div>

          <div className="tableSec">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-small uk-table-divider">
                <thead>
                  <tr>
                    <th>Queries</th>
                    <th>Contact Persone Name</th>
                    <th>City</th>
                    <th>Number</th>
                    <th>Business Name</th>
                    <th>Message</th>
                    <th>Chat Raised</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.dataList.isLoaded ? (
                    this.handleTable()
                  ) : this.state.dataList.msg == "not" ? (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          Data not Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          <div className="mdl">
                            <div className="mdl_inner">
                              <div uk-spinner=""></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {this.state.rowsCountStats > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"...."}
                  pageCount={this.state.rowsCountStats}
                  onPageChange={this.handleQuickClick}
                  containerClassName={"uk-pagination uk-float-right"}
                  activeClassName={"uk-active"}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={6}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div
          id="SearchAutoTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Query
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="filter_name">
                    <option value="" disabled selected>
                      Select Query
                    </option>
                    <option value="I already have Hico products in my store">I already have Hico products in my store</option>
                    <option value="I want Hico products for my business">I want Hico products for my business</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="uk-modal-close CnclBtn"
                    
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.AutoadvanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>


      </Content>
    );
  }
}
export default BusinessQueriesReport;
