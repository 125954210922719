import * as React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {Content} from '@adobe/react-spectrum';



class DayForm extends React.Component {
    
    

    render() {

        return (
            <Content>
                <form className="uk-form-stacked" action="javascript:void(0);">

                    <div className="MainFormsection">    
                        <div className="uk-grid  uk-grid-small" uk-grid="">
                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Monday">Monday</label>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>9:00</option>
                                </select>
                            </div>
                                
                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>18:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Tuesday">Tuesday</label>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>9:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>18:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Wednesday">Wednesday</label>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>9:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>18:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Thrusday">Thrusday</label>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>9:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>18:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Friday">Friday</label>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>9:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>18:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Saturday">Saturday</label>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>9:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>18:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Sunday">Sunday</label>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>9:00</option>
                                </select>
                            </div>

                            <div className="uk-width-1-3">
                                <select className="uk-select" id="country_code" name="country_code">
                                    <option>18:00</option>
                                </select>
                            </div>


                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Sunday">Off Day Message</label>
                            </div>

                            <div className="uk-width-2-3">
                                <textarea class="uk-textarea" rows="5" placeholder="Textarea"></textarea>
                            </div>

                            <div className="uk-width-1-3 label">
                                <label className="uk-form-label" htmlFor="Sunday">Off Hour Message</label>
                            </div>

                            <div className="uk-width-2-3">
                                <textarea class="uk-textarea" rows="5" placeholder="Textarea"></textarea>
                            </div>
               
                            <div className="uk-width-1-1 uk-margin">
                                <div className="ModalStyleBtns dayFormBtn">
                                    <button className="AddTeamBtn">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                      
            </Content>
        )
    }
}
export default DayForm;