import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../App/Constant";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);


let dummyData = {

  labels: [],
  datasets: [
    {
      fill: false,
      label: 'WhatsApp',
      data:  [],
      borderColor: '#1DB954',
      
    },
  ],


}

export const UserAnalyticTotalUser = (stateTo,stateFrom) => {

  const [graphData, setGraphData] = useState(dummyData);

  useEffect(() => {

    formValidation();

}, [stateTo,stateFrom]);


const formValidation = async () => {

  axios({
      method: 'POST',
      url: Constant.url+"day-wise-avg-messages",
      data: {company_id:localStorage.getItem('company_id'),stateTo:stateTo.stateTo,stateFrom:stateTo.stateFrom,channel_type:localStorage.getItem("channeltype")}   
  }).then(result =>{

    var stats = result.data.data;
    // console.log(stats.values,'statscheck')
    setGraphData({

    labels: stats.labels,
    datasets: [
      {
        fill: false,
        label: 'Total users',
        data:  [5, 15, 25, 5, 30, 0, 20, 25, 5, 20, 30, 5, 0, 10, 25, 28, 0, 5 ],
        borderColor: '#84EB7F',
        
      },
      {
        fill: false,
        label: 'Returning users',
        data:  [0, 3, 8, 6, 0, 3, 0, 18, 20, 0, 8, 7, 0, 18, 20, 0, 6, 10,0, 5 ],
        borderColor: '#007FF9',
        
      },
      {
        fill: false,
        label: 'New users',
        data:  [5, 10, 8, 6, 0, 3, 0, 18, 20, 0, 8, 7, 0, 18, 20, 0, 6, 10, 20, 0 ],
        borderColor: '#FF9F0A',
        
      },
    ],

    })

    
  }).catch(err => {

    // console.log(err)
          
  });


}


        const options = {
          responsive: true,
          plugins: {
          legend: {
          position: 'right',
          },
          title: {
          display: false,
          // text: 'Chart.js Line Chart',
          },
          },
          }
        
        return <Line options={options} data={graphData}/>;
      }
