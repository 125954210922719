import * as React from "react";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { DateRange } from "../../Common/DateRange";
import $ from "jquery";

  
class LiveChatChanel extends React.Component {
    constructor() {
        super();
        this.state = {
        from: "",
        to: "",
        }

    }


    MultiSelectFilter =()=>{
        $(".UltabsBtns").toggleClass("showMenu");
        $(".UltabsBtns > li label").click(function(){
            $(".MultiSelectFilter > p span").text($(this).text());
        });
    }
    

    componentDidMount() {
        var classRef = this;

        var to = "2022-11-01";
        var from = "2022-09-20";
        classRef.setState({ from: from });
        classRef.setState({ to: to });
                }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="">
                <div className="uk-width-1-2">
                    <h1 className="uk-h2 uk-text-light">Channels overview</h1>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>
                
                {/* Utilization per agent */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                        <h5 className="uk-heading-divider">Performance per channel
                            <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                <div uk-dropdown="mode: click">
                                    <h5>Performance per channel</h5>
                                    <p>Distribution of the currently active conversations per status for each channel.</p>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand">RANKING</th>
                                    <th className="uk-table-expand ">TOTAL</th>
                                    <th className="uk-table-auto uk-text-nowrap">OPEN</th>
                                    <th className="uk-table-expand uk-text-nowrap">WAITING</th>
                                    <th className="uk-table-shrink uk-text-nowrap">SOLVED</th>
                                    <th className="uk-table-shrink uk-text-nowrap">WAITING TIME</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Calls</span></td>
                                    <td className="uk-text-nowrap"><span>3,432</span> </td>
                                    <td className="uk-text-nowrap"><span>6</span></td>
                                    <td className="uk-text-nowrap"><span>1</span></td>
                                    <td className="uk-text-nowrap"><span>3425</span></td>
                                    <td className="uk-text-nowrap"><span>00:05:48</span></td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
         </>
    );
}
}
export default LiveChatChanel;