import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import { ErrorFilled } from "@carbon/icons-react";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import Select from "react-select";
import axios from "axios";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Constant from "../../../App/Constant";

import {
  CaretDown,
  Checkmark,
  Download,
  MessageQueue,
  InProgress,
  Information,
  ViewFilled,
  ReplyAll,
  Send,
  NotSent,
  Chat,
  Search,
  TrashCan,
  Add,
  AddAlt,
  Edit,
  ArrowRight,
  OverflowMenuVertical,
  Close,
  Filter,
  Tag,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  Time,
} from "@carbon/icons-react";

class PackagesRequest extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },

      request_id: "",
      company_name: "",
      company_type: "",
      whatsapp_name: "",
      code: "",
      whatsapp_number: "",
      display_picture: "",
      partner_name: "",
      your_name: "",
      email_address: "",
      region: "",
      company_facebook_business_manager_id: "",
      company_facebook_url: "",
      company_website: "",
      brand_name: "",
      description: "",
      status: "",
      package_name: "",
      request_at: "",

      number_of_requests: "",
      pending: "",
      approved: "",
      rejected: "",
      number_of_companies: "",
      number_of_packages_active_packages: "",
      rowsCountpackages: 0,
    };
  }

  componentDidMount() {
    var pageCount = 1;
    this.callApi(pageCount);
    this.companyStats();
    $("#cross").hide();
    if(localStorage.getItem('loginStatus')== 1){
      let screens = localStorage.getItem('rolesceens').split(',');
      var checking = screens.includes("16");
       if(checking == true){

          }else{
              window.location.href = "/"; 
          }
      }
      else{
          window.location.href = "/"; 
      }
  }

  QuickSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#QuickSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "account-request-details",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountpackages: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountpackages: result.data.rowscount,
        });
      }
    });
  };

  handleQuickClick = (data) => {
    let currentPage = data.selected + 1;
    this.QuickSorting(currentPage);
  };

  companyStats = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "account-request-overview",
    }).then(function (result) {
      if (result.data.status === true) {
        var stats = result.data.data;
        classRef.setState({
          number_of_requests: stats.number_of_requests,
          pending: stats.pending,
          approved: stats.approved,
          rejected: stats.rejected,
          number_of_companies: stats.number_of_companies,
          number_of_packages_active_packages:
            stats.number_of_packages_active_packages,
        });
      }
    });
  };

  callApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "account-request-details",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountpackages: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountpackages: result.data.rowscount,
        });
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr>
        <td>
          <div className="avatarIcon">
            <img src={response.display_picture} alt="Image" />
          </div>
        </td>
        <td>
          <span>{response.company_name}</span>
        </td>
        <td>
          <span>{response.whatsapp_name}</span>
        </td>
        <td>
          <span>{response.whatsapp_number}</span>
        </td>
        <td>
          <span>{response.package_name}</span>
        </td>
        <td>
          <td>
            <button
              className={
                response.status == "Approved"
                  ? "ctmBtn succesBtn"
                  : "ctmBtn dangreBtn"
              }
            >
              {response.status}
            </button>
          </td>
        </td>
        <td>{response.request_at}</td>
        <td><button className="iconcircleBtn" uk-tooltip="title: View Details"onClick={(e) => this.ViewDetailModal(response.id)}><Edit /></button></td>
        <td>
                  {response.status == "Approved" ? (
                    <button className="iconcircleBtn danger" uk-tooltip="title: Suspend" onClick={(e) => this.suspendconsent(response.id)}><ErrorFilled /></button>
                  ) : (
                    ""
                  )}
               
                  {response.status == "Pending" ? (
                    <button className="iconcircleBtn success" uk-tooltip="title: Approve" onClick={(e) => this.approveconsent(response.id)}><ErrorFilled /></button>
                  ) : (
                    ""
                  )}
               
                
                  {response.status == "Pending" ? (
                    <button className="iconcircleBtn danger" uk-tooltip="title: Rejected" onClick={(e) => this.addrejectconsent(response.id)}><ErrorFilled /></button>
                  ) : (
                    ""
                  )}

                  {response.company_id ? (
                    ""
                  ) : (
                    <Link className="iconcircleBtn" uk-tooltip="title:  Add Company" to={"/create-company/" + response.id}><Add /></Link>
                  )}
              </td>
      </tr>
    ));

    return result;
  };

  ViewDetailModal = (id) => {
    $("#ContactProfile").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findrequest(id);
  };

  findrequest = (id) => {
    let classRef = this;
    let request_id = id;
    axios({
      method: "POST",
      url: Constant.url + "get-single-request",
      data: { request_id: request_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var pack = result.data.data;
        classRef.setState({
          request_id: pack.id,
          company_name: pack.company_name,
          company_type: pack.company_type,
          whatsapp_name: pack.whatsapp_name,
          code: pack.code,
          whatsapp_number: pack.whatsapp_number,
          display_picture: pack.display_picture,
          partner_name: pack.partner_name,
          your_name: pack.your_name,
          email_address: pack.email_address,
          region: pack.region,
          company_facebook_business_manager_id:
            pack.company_facebook_business_manager_id,
          company_facebook_url: pack.company_facebook_url,
          company_website: pack.company_website,
          brand_name: pack.brand_name,
          description: pack.description,
          status: pack.status,
          package_name: pack.package_name,
          request_at: pack.request_at,
        });
      }
    });
  };

  suspendconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#suspendconsent").addClass("uk-flex uk-open");
    $("#suspendconsent").val(id);
  };

  closesuspendconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#suspendconsent").removeClass("uk-flex uk-open");
  };

  approveconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#approveconsent").addClass("uk-flex uk-open");
    $("#addappconsent").val(id);
  };

  closeapproveconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#approveconsent").removeClass("uk-flex uk-open");
  };

  addrejectconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#rejectconsent").addClass("uk-flex uk-open");
    $("#rejectconsent").val(id);
  };

  closerejectconsent = (id) => {
    $(".uk-dropdown").removeClass("uk-open");
    $("#rejectconsent").removeClass("uk-flex uk-open");
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  approverequest = () => {
    var id = $("#addappconsent").val();
    let classRef = this;
    var request_id = id;
    axios({
      method: "POST",
      url: Constant.url + "change-status",
      data: { request_id: request_id, status: 1 },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/packages-requests";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/packages-requests";
      }
    });
  };

  rejectrequest = () => {
    var id = $("#rejectconsent").val();
    let classRef = this;
    var request_id = id;
    axios({
      method: "POST",
      url: Constant.url + "change-status",
      data: { request_id: request_id, status: 2 },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/packages-requests";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/packages-requests";
      }
    });
  };

  suspendrequest = () => {
    var id = $("#suspendconsent").val();
    let classRef = this;
    var request_id = id;
    axios({
      method: "POST",
      url: Constant.url + "change-status",
      data: { request_id: request_id, status: 3 },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/packages-requests";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/packages-requests";
      }
    });
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    this.setState({ advancesearch: searchword });

    if (searchword !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "account-request-details",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  advanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      status: $("#status").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.status) {
      var key = key + " Status: " + $("#status :selected").text();
    }

    classRef.setState({ advancesearch: key });
    if (key !== "") {
      $("#cross").show();
    }
    axios({
      method: "POST",
      url: Constant.url + "account-request-details",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.callApi();
    $("#cross").hide();
  };

  clearFilters = () => {
    $("#from_date").val("");
    $("#to_date").val("");
    $("#status").val("");
  };

  downloadRequest = () => {
    let classRef = this;

    window.open(
      Constant.url +
        "account-request-details-download?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer BroadcastingContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div id="errorAlert"></div>
            <div id="successAlert"></div>

            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto@m">
                <h3>Packages Requests</h3>
              </div>
              <div className="uk-width-1-3@m">
                <div className="search_field">
                  <form action="javascript:void(0)">
                    <input
                      className="uk-input"
                      type="text"
                      onChange={this.searchrecord}
                      name="searchword"
                      id="searchword"
                      value={this.state.advancesearch}
                      placeholder="Search packages through name"
                    />
                  </form>
                  <div class="CampaignReports Filtermain">
                    <div className="uk-width-auto">
                      <button
                        id="cross"
                        style={{ right: "30px" }}
                        onClick={this.advSearchClear}
                      >
                        <Close />
                      </button>
                      <button onClick={this.addSearchModal}>
                        <img src="/images/newfilter.svg" uk-svg />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-expand@m">
                <button
                  class="newFlowBtn uk-float-left"
                  onClick={this.downloadRequest}
                >
                  <Add /> <span>Export Package Request</span>
                </button>
              </div>
            </div>
          </div>

          <div className="stats_container">
            <div
              className="uk-child-width-expand@s uk-grid uk-grid-collapse"
              uk-grid=""
              uk-height-match="target: .box"
            >
              {/* <div>
                                        <div className="box" uk-height-match="">
                                            <div className="text">
                                                <h6>{this.state.number_of_companies}</h6>
                                                <span>All Companies</span>
                                            </div>
                                          <div className="icon">
                                          <MessageQueue />
                                            <div className="info" uk-tooltip="Number of Messages"><Information /></div>
                                          </div>

                                           </div>
                                    </div> */}
              <div>
                <div className="box" uk-height-match="">
                  <div className="text">
                    <h6>{this.state.number_of_requests}</h6>
                    <span>Number Of Requests</span>
                  </div>
                  <div className="icon">
                    <Chat />
                    <div className="info" uk-tooltip="Number of Requests">
                      <Information />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="box" uk-height-match="">
                  <div className="text">
                    <h6>{this.state.pending}</h6>
                    <span>Number Of Pending Requests</span>
                  </div>
                  <div className="icon">
                    <ViewFilled />
                    <div
                      className="info"
                      uk-tooltip="Number of Pending Requests"
                    >
                      <Information />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box" uk-height-match="">
                  <div className="text">
                    <h6>{this.state.approved}</h6>
                    <span>Number Of Approved Requests</span>
                  </div>
                  <div className="icon">
                    <ReplyAll />
                    <div
                      className="info"
                      uk-tooltip="Number Of Approved Requests"
                    >
                      <Information />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box" uk-height-match="">
                  <div className="text">
                    <h6>{this.state.rejected}</h6>
                    <span>Number Of Rejected Requests</span>
                  </div>
                  <div className="icon">
                    <Send />
                    <div
                      className="info"
                      uk-tooltip="Number Of Rejected Requests"
                    >
                      <Information />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box" uk-height-match="">
                  <div className="text">
                    <h6>{this.state.number_of_packages_active_packages}</h6>
                    <span>Number Of Packages Active</span>
                  </div>
                  <div className="icon">
                    <NotSent />
                    <div
                      className="info"
                      uk-tooltip="Number Of Packages Active"
                    >
                      <Information />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="numberSorting uk-float-right">
            <div class="uk-inline">
              <label>
                Show
                <select
                  onChange={(e) => this.QuickSorting()}
                  id="QuickSortingBtn"
                >
                  {/* <option disabled selected>Sorting</option> */}
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>500</option>
                </select>
                entries
              </label>
            </div>
          </div>

          <div className="tableSec">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-small uk-table-divider">
                <thead>
                  <tr>
                    <th>Avatar</th>
                    <th>Company Name</th>
                    <th>Whatsapp Name</th>
                    <th>Whatsapp Number</th>
                    <th>Package Name</th>
                    <th>STATUS</th>
                    <th>CREATED</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.dataList.isLoaded ? (
                    this.handleTable()
                  ) : this.state.dataList.msg == "not" ? (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          Data not Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          <div className="mdl">
                            <div className="mdl_inner">
                              <div uk-spinner=""></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {this.state.rowsCountpackages > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"...."}
                  pageCount={this.state.rowsCountpackages}
                  onPageChange={this.handleQuickClick}
                  containerClassName={"uk-pagination uk-float-right"}
                  activeClassName={"uk-active"}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={6}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div
          id="EditTagsModal"
          className=" ModalStyle uk-flex-top"
          uk-modal="esc-close: false; bg-close: false"
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Package Details</h3>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Webhook name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="webhook_id"
                    name="webhook_id"
                    value={this.state.webhook_id}
                    type="hidden"
                  />
                  <input
                    type="text"
                    id="new_webhookName"
                    name="new_webhookName"
                    value={this.state.webhook_name}
                    onChange={this.handleNameChange}
                    className="uk-input"
                    placeholder="Your webhook name (required)"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Webhook url
                </label>
                <div className="uk-form-controls">
                  <input
                    type="text"
                    id="new_url"
                    name="new_url"
                    className="uk-input"
                    value={this.state.url}
                    onChange={this.handleUrlChange}
                    placeholder="Your Webhook url (required)"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Verification token
                </label>
                <div className="uk-form-controls">
                  <input
                    type="text"
                    id="new_verificationToken"
                    name="new_verificationToken"
                    value={this.state.verification_token}
                    onChange={this.handleTokenChange}
                    className="uk-input"
                    placeholder="Verification token"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns uk-margin-top">
                  <div className="uk-float-right">
                    <button
                      className="CnclBtn"
                      onClick={this.closeEditWebhookModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="AddTeamBtn"
                      id="editwebhook"
                      onClick={this.EditWebhooks}
                    >
                      Save <ArrowRight />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form
              id="advsearchform"
              className="uk-form-stacked"
              action="javascript:void(0);"
            >
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date"
                    />
                  </div>
                </div>
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Status
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="status">
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="1">Active</option>
                    <option value="0">In Active</option>
                  </select>
                </div>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn ">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="ContactProfile"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>{this.state.company_name}</h3>
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-1-2">
                <div className="ProfileLeft">
                  <div className="profile_pic">
                    <img
                      src={this.state.display_picture}
                      alt=""
                      id="profile_pic"
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1">
                <div className="_contact_details">
                  <table class="uk-table uk-table-small uk-table-divider packagesTable">
                    <tbody>
                      <tr>
                        <td>Request Number : </td>
                        <td>
                          <span>{this.state.request_id}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Company Name :</td>
                        <td>
                          <span>{this.state.company_name}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Company Type : </td>
                        <td>
                          <span>{this.state.company_type}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Whatsapp Name :</td>
                        <td>
                          <span>{this.state.whatsapp_name}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Whatsapp Number :</td>
                        <td>
                          <span>{this.state.whatsapp_number}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Code :</td>
                        <td>
                          <span>{this.state.code}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Region :</td>
                        <td>
                          <span>{this.state.region}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Brand Name :</td>
                        <td>
                          <span>{this.state.brand_name}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Email Address :</td>
                        <td>
                          <span>{this.state.email_address}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Package Name :</td>
                        <td>
                          <span>{this.state.package_name}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Company Website :</td>
                        <td>
                          <span>{this.state.company_website}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Status :</td>
                        <td>
                          <span>{this.state.status}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Company Facebook Business Manager Id:</td>
                        <td>
                          <span>
                            {this.state.company_facebook_business_manager_id}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Company Facebook Url:</td>
                        <td>
                          <span>{this.state.company_facebook_url}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Request at :</td>
                        <td>
                          <span>{this.state.request_at}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Description :</td>
                        <td>
                          <span>{this.state.description}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <ul>
                        <li><span >Request Number : </span><span>{this.state.request_id}</span></li>
                        <li><span >Company Name : </span><span>{this.state.company_name}</span></li>
                        <li><span >Company Type : </span><span>{this.state.company_type}</span></li>
                        <li><span >Whatsapp Name : </span><span>{this.state.whatsapp_name}</span></li>
                        <li><span >Whatsapp Number : </span><span>{this.state.whatsapp_number}</span></li>
                        <li><span >Code : </span><span>{this.state.code}</span></li>
                        <li><span >Region : </span><span>{this.state.region}</span></li>
                        <li><span >Brand Name : </span><span>{this.state.brand_name}</span></li>
                        <li><span >Email Address : </span><span>{this.state.email_address}</span></li>
                        <li><span >Package Name : </span><span>{this.state.package_name}</span></li>
                        <li><span >Company Website : </span><span>{this.state.company_website}</span></li>
                        <li><span >Status : </span><span>{this.state.status}</span></li>
                        <li><span >Company Facebook Business Manager Id: </span><span>{this.state.company_facebook_business_manager_id}</span></li>
                        <li><span >Company Facebook Url: </span><span>{this.state.company_facebook_url}</span></li>
                        <li><span >Request at : </span><span>{this.state.request_at}</span></li>
                        <li><span >Description : </span><span>{this.state.description}</span></li>
                    </ul> */}
                </div>
              </div>
            </div>
            {/* <div className="uk-width-1-2">
            <div className="pause_automation ">
                <div className="head">
                    <div className="">
                        <h4>Description</h4>
                    </div>
                </div>
                <hr/>
            </div>
        </div> */}
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="approveconsent"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to approve request?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button
                    className="CnclBtn"
                    onClick={this.closeapproveconsent}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addappconsent"
                    onClick={this.approverequest}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="rejectconsent"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to reject request?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closerejectconsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="rejectconsent"
                    onClick={this.rejectrequest}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="suspendconsent"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to suspend request?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button
                    className="CnclBtn"
                    onClick={this.closesuspendconsent}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="suspendconsent"
                    onClick={this.suspendrequest}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default PackagesRequest;
