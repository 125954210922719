import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content,TextField,Checkbox,Button} from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from 'axios';
import $ from 'jquery';
import {
  AddAlt,
  Close
} from "@carbon/icons-react";
class CreateNewCompany extends React.Component {


    constructor() {
        super();

    }

    componentDidMount() {
      if(localStorage.getItem('loginStatus')== 1){
        let screens = localStorage.getItem('rolesceens').split(',');
        var checking = screens.includes("15");
         if(checking == true){

            }else{
                window.location.href = "/"; 
            }
        }
        else{
            window.location.href = "/"; 
        }
    
      }
  


    addconsent = (id) =>{
        // console.log(id,"sajdain");
        $("#AddResolved").addClass('uk-flex uk-open');
        $( "#addconsent" ).val(id)
    
      }


      deleteCompany = () => {

        var id = $("#addconsent").val()
        let classRef = this;
        var company_id = id;
        // var company_id = localStorage.getItem('company_id');
        // var is_deleted = 1;
    
              axios({
                method: 'POST',
                url: Constant.url + "account-deletess",
                data: { company_id: company_id}
            }).then(function (result) {
            
                if (result.data.code === 200) {
            
                    $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
        
                    localStorage.clear();
                    window.location.href = "/";
            
                }else{
            
                    $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
            
                    setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
        
                    window.location.href = "/agents";      
            
                }
                   
            })
      
      }
    

      closeConsent = () =>{
        $("#AddResolved").removeClass('uk-flex uk-open');
      }


      editCompany = () => {    

        $('#editcompany').text('Processing')
        $('#editcompany').prop('disabled', true);  
        let classRef = this;
        var url = window.location.pathname;
        var id = url.split("/");
        var request_id = id[3];
        

        var data = {};
        // data = {
        //   company_name: $("#Companyname").val(), 
        //   first_name: $("#Firstname").val(),
        //   last_name: $("#Lastname").val(),
        //   email: $("#Email").val(),
        //   password: $("#Password").val(),
        //   c_code: $("#Countrycode").val(),
        //   whatsapp_number: $("#Phone").val(),
        //   phone_number: $("#Contactphone").val(),
        //   contact_code: $("#Contactcode").val(),
        //   company_industry: $("#Companyindustry").val(),
        // }
        data = {
          vendor_id:1,
          first_name: $("#Firstname").val(),
          last_name: $("#Lastname").val(),
          email: $("#Email").val(),
          whatsapp_number: $("#Phone").val(),
          password: $("#Password").val(),
          company_name: $("#Companyname").val(),
          country_code: $("#Countrycode").val(),
          industry: $("#Companyindustry").val(),
          phone_number: $("#Contactphone").val()
        }
    
        axios({
          method: 'POST',
          url: Constant.url + "create-company-account-direct",
          data: data
        }).then(function (response) {
    
          if (response.data.code === 200) {
    
            $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');
    
            // window.location.reload()
            window.location.href = "/company";
      
          }else{
    
            $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
            $('#editcompany').text('Update')
            $('#editcompany').prop('disabled', false);
            setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
            return;
        
    
          }
        }).then(function (response) {
    
        });
    
    
      };

   

  render() {
    return (
      <Content>
        <div
          className="contactContainer adminContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
                <div className=" uk-grid " uk-grid="">
                    <div className="uk-width-auto"><h3>Add Company</h3></div>
                </div>
            </div>
          <div className=" CustomBgAdmin">
            <div className="integration inner">
            <h3><b>Add Company</b></h3>
                    <div id="errorAlert"></div>
                    <div id="successAlert"></div>
            <form action="javascript:void(0);">
                <div className="uk-grid uk-grid-medium" uk-grid>
                  
                    <div className="uk-width-1-3">
                        <div className="uk-margin">
                            <label className="uk-form-label" for="Companyname"><b>Company name</b></label>
                            <div className="uk-form-controls">
                                <input className="uk-input"  id="Companyname" name="Companyname" placeholder="Company Name"/>
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-3">
                        <div className="uk-margin">
                            <label className="uk-form-label" for="Companyname"><b>Company industry</b></label>
                            <div className="uk-form-controls">
                                <input className="uk-input"  id="Companyindustry" name="Companyindustry" placeholder="Company Industry"/>
                            </div>
                        </div>
                    </div>

                    <div class="uk-width-auto">
                        <label class="uk-form-label " for=""><b>Code</b></label>
                        <div class="uk-form-controls">
                            <select class="uk-select" id="Countrycode" name="Countrycode">
                                <option>+92</option>
                            </select>
                        </div>
                    </div>  

                    <div class="uk-width-1-4">
                        <label class="uk-form-label " for=""><b>Whatsapp Number</b></label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="Phone" name="Phone" type="number" placeholder="Enter Phone Number"/>
                            </div>
                    </div>   


                    <div className="uk-width-1-3">
                        <div className="uk-margin">
                            <label className="uk-form-label" for=""><b>First Name</b></label>
                            <div className="uk-form-controls">
                                <input className="uk-input"  id="Firstname" name="Firstname" placeholder="First Name"/>
                            </div>
                        </div>
                    </div>


                    <div className="uk-width-1-3">
                        <div className="uk-margin">
                            <label className="uk-form-label" for=""><b>Last Name</b></label>
                            <div className="uk-form-controls">
                                <input className="uk-input"  id="Lastname" name="Lastname" placeholder="Last Name"/>
                            </div>
                        </div>
                    </div>

                    <div class="uk-width-auto">
                        <label class="uk-form-label " for=""><b>Code</b></label>
                        <div class="uk-form-controls">
                            <select class="uk-select" id="Contactcode" name="Contactcode">
                                <option>+92</option>
                            </select>
                        </div>
                    </div>  

                    <div class="uk-width-1-4">
                        <label class="uk-form-label " for=""><b>Contact Number</b></label>
                            <div class="uk-form-controls">
                                <input class="uk-input" id="Contactphone" name="Contactphone" type="number" placeholder="Enter phone number"/>
                            </div>
                    </div>


                    <div className="uk-width-1-3">
                        <div className="uk-margin">
                            <label className="uk-form-label" for=""><b>Email</b></label>
                            <div className="uk-form-controls">
                                <input className="uk-input"  id="Email" name="Email" placeholder="Company Email"/>
                            </div>
                        </div>
                    </div>


                    <div className="uk-width-1-3">
                        <div className="uk-margin">
                            <label className="uk-form-label" for=""><b>Password</b></label>
                            <div className="uk-form-controls">
                                <input className="uk-input" type="password"  id="Password" name="Password" placeholder="Enter Password"/>
                            </div>
                        </div>
                    </div>
                        
                    <div className="uk-width-1-1">
                        <div className="FormDivider">
                            <hr/>
                        </div>
                    </div>

                    <div className="uk-width-1-1">
                        <div className="uk-margin">
                            <button id="editcompany" onClick= {(e)=>this.editCompany()} >Add Company</button>
                        </div>
                    </div>

                </div>

            </form>

            
            </div>
            

          </div>
        </div>


                {/*----MODAL---*/}
                <div id="AddResolved" className="AlertPOPUP ModalStyle uk-flex-top" uk-modal="">
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button"> <Close /></button>
            <h3 >
              <span className="uk-inline"> Warning</span> 
              <span className="uk-inline uk-float-right"> 
                  <img src="/images/done_all_black_24dp.svg" />{" "} 
              </span>
            </h3>
            <div className="resolved_content">
              <p><b>Are you sure you want to delete Company?</b></p>
              <p className="small">You won't be able to revert this!</p>

              <form className="uk-form-stacked uk-margin-medium-top" action="javascript:void(0);">
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>Cancel</button>
                  <button className="AddTeamBtn" id="addconsent" onClick={this.deleteCompany}>Confirm</button>
                </div>
            </form>
            </div>
          </div>
        </div>


        
      </Content>
    );
  }
}
export default CreateNewCompany;
