import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, TextField, Checkbox, Button } from "@adobe/react-spectrum";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import { AddAlt, Close } from "@carbon/icons-react";
class Admin extends React.Component {
  constructor() {
    super();
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleWhatsappChange = this.handleWhatsappChange.bind(this);
    this.state = {
      company_name: "",
      industry: "",
      email: "",
      code: "",
      whatsapp_number: "",
      company_id: "",
    };
  }

  handleNameChange(event) {
    this.setState({ company_name: event.target.company_name });
  }

  handleIndustryChange(event) {
    this.setState({ industry: event.target.industry });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.email });
  }

  handleCodeChange(event) {
    this.setState({ code: event.target.code });
  }

  handleWhatsappChange(event) {
    this.setState({ whatsapp_number: event.target.whatsapp_number });
  }

  addconsent = (id) => {
    // console.log(id, "sajdain");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  deleteCompany = () => {
    var id = $("#addconsent").val();
    let classRef = this;
    var company_id = id;
    // var company_id = localStorage.getItem('company_id');
    // var is_deleted = 1;

    axios({
      method: "POST",
      url: Constant.url + "account-deletess",
      data: { company_id: company_id },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        localStorage.clear();
        window.location.href = "/";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/agents";
      }
    });
  };

  componentDidMount() {
    this.callApi();

    if (localStorage.getItem("loginStatus") == 1) {
      let screens = localStorage.getItem("rolesceens").split(",");
      var checking = screens.includes("10");
      if (checking == true) {
      } else {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  }

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "account-overview",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        var company = result.data.data;
        classRef.setState({
          company_name: company.company_name,
          industry: company.industry,
          email: company.email,
          code: company.code,
          whatsapp_number: company.whatsapp_number,
          company_id: company.id,
        });
      }
    });
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  editCompany = (id) => {
    $("#editcompany").text("Processing");
    $("#editcompany").prop("disabled", true);
    let classRef = this;
    var company_id = id;

    var data = {};
    data = {
      company_name: $("#Companyname").val(),
      industry: $("#Companyindustry").val(),
      company_id: company_id,
    };

    axios({
      method: "POST",
      url: Constant.url + "account-edit",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          window.location.href = "/admin";
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#editcompany").text("Update");
          $("#editcompany").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer adminContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Admin</h3>
              </div>
            </div>
          </div>
          <div className="adminContainerinner">
            <div className="CustomBgAdmin addSpace">
              <div className="integration inner">
                <h3>
                  <b>Account Overview</b>
                </h3>
                <div id="errorAlert"></div>
                <div id="successAlert"></div>
                <form action="javascript:void(0);">
                  <div className="uk-grid uk-grid-medium" uk-grid>
                    <div className="uk-width-1-3@m">
                      <div className="uk-margin">
                        <label className="uk-form-label" for="Companyname">
                          <b>Company name</b>
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            value={this.state.company_name}
                            onChange={this.handleNameChange}
                            id="Companyname"
                            name="Companyname"
                            placeholder="Company name"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="uk-width-1-3@m">
                      <div className="uk-margin">
                        <label className="uk-form-label" for="Companyindustry">
                          <b>Company industry</b>
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            value={this.state.industry}
                            id="Companyindustry"
                            onChange={this.handleIndustryChange}
                            name="Companyindustry"
                            placeholder="Company industry"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="uk-width-1-3@m">
                      <div className="uk-margin">
                        <label className="uk-form-label" for="">
                          <b>Company email</b>
                        </label>
                        <div className="uk-form-controls">
                          <input
                            className="uk-input"
                            value={this.state.email}
                            readOnly
                            onChange={this.handleEmailChange}
                            id="Companyemail"
                            name="Companyemail"
                            placeholder="Company email"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="uk-width-auto selctPhoneMobile">
                      <label class="uk-form-label " for="">
                        <b>Code</b>
                      </label>

                      <div class="uk-form-controls ">
                        <div class="uk-form-controls customMargin ">
                          <select
                            class="uk-select"
                            id="country_code"
                            value={this.state.code}
                            onChange={this.handleCodeChange}
                            readOnly
                            name="country_code"
                          >
                            <option>{this.state.code}</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="uk-width-1-5@m phoneMobile">
                      <label class="uk-form-label " for="">
                        <b>Company Number</b>
                      </label>
                      <div class="uk-form-controls customMargin ">
                        <input
                          class="uk-input"
                          id="phone"
                          value={this.state.whatsapp_number}
                          readOnly
                          onChange={this.handleWhatsappChange}
                          name="phone"
                          type="number"
                          placeholder="Enter phone number"
                        />
                      </div>
                    </div>

                    {/* <div className="uk-width-1-3@m">
                        <div className="uk-margin">
                            <label className="uk-form-label" for=""><b>WhatsApp short links</b></label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="firstName" name="firstName" placeholder="Enter first name"/>
                            </div>
                        </div>
                    </div>     */}

                    {/* <div className="uk-width-1-1">
                        <div className="FormDivider">
                            <hr/>
                        </div>
                    </div>

                    <div className="uk-width-1-3@m">
                        <div className="uk-margin">
                            <label className="uk-form-label" for=""><b>Account Time Zone</b></label>
                            <p>All the data in Telenor Engage will be displayed and exported according to this time-zone.</p>
                            <div class="uk-form-controls">
                            <select class="uk-select" id="country_code" name="country_code">
                                <option>(UTC+05:00) Pakistan Standard Time</option>
                            </select>
                        </div>
                        </div>
                    </div>


                     <div className="uk-width-1-3@m">
                        <div className="uk-margin">
                            <label className="uk-form-label" for=""><b>Contact Reports</b></label>
                            <p>ARegular updates on how your contact base is doing</p>
                            <div class="uk-form-controls">
                            <select class="uk-select" id="" name="">
                                <option>Weekly</option>
                            </select>
                        </div>
                        </div>
                    </div>      */}

                    <div className="uk-width-1-1@m">
                      <div className="FormDivider">
                        <hr />
                      </div>
                    </div>

                    <div className="uk-width-1-1@m">
                      <div className="uk-margin">
                        {/* <h4><b>Leave Account</b></h4>
                            <p>Transfer your ownership to another team member if you want to leave this account</p> */}
                        <button
                          id="editcompany"
                          onClick={(e) =>
                            this.editCompany(this.state.company_id)
                          }
                        >
                          Update
                        </button>
                      </div>
                    </div>

                    {/* <div className="uk-width-1-1@m">
                        <div className="uk-margin">
                            <h4><b>Delete Account</b></h4>
                            <p>Continue to account deletion</p>
                            <button className="" onClick= {(e)=>this.addconsent(this.state.company_id)}>Delete</button>
                        </div>
                    </div>  */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Company?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsent"
                    onClick={this.deleteCompany}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default Admin;
