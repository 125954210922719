import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown
  } from "@carbon/icons-react";
import { ChannelsOverviewChart } from "../../Sections/ChannelsOverviewChart";
import { DateRange } from "../../Common/DateRange";
import { WhatsappBusinessMessStatus } from "../../Sections/WhatsappBusinessMessStatus";
import { BroadCastDeliveryRateChart } from "../../Sections/BroadCastDeliveryRateChart";
import { BroadcastCompRateChart } from "../../Sections/BroadcastCompRateChart";
// import DateRangePicker from 'rsuite/DateRangePicker';
// // import { DateRangePicker, Stack } from 'rsuite';
// import subDays from 'date-fns/subDays';
// import startOfWeek from 'date-fns/startOfWeek';
// import endOfWeek from 'date-fns/endOfWeek';
// import addDays from 'date-fns/addDays';
// import startOfMonth from 'date-fns/startOfMonth';
// import endOfMonth from 'date-fns/endOfMonth';
// import addMonths from 'date-fns/addMonths';
  
class BroadCastAnalytics extends React.Component {

    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2023-01-30";
        var from = "2021-01-01";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }


MultiSelectFilter =()=>{
    $(".UltabsBtns").toggleClass("showMenu");
    $(".UltabsBtns > li label").click(function(){
        $(".MultiSelectFilter > p span").text($(this).text());
    });
}


  
  

    render() {
        return (
         <>
            <div uk-grid="" uk-height-match="target: > div > .uk-card">
                
                
                {/* All Channels Performance */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head">
                            <h5 className="uk-heading-divider"><div class="uk-inline infoTool"><a  href="#">Broadcast Highlights <Information /></a>
                                <div uk-dropdown="mode: click">
                                    <h5>Delivery Rate</h5>
                                    <h6>Delivery Rate = Delivered / (Delivered + Pending + Undelivered + Expired)</h6> 
                                    <p>Message can be rejected because of <b>Do Not Contact</b> enabled, so messages with status <b>"Rejected"</b> are not included in formula.</p>
                                </div>
                                </div>
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>41</h3>
                                <p>Trafic</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>29</h3>
                                <p>Valid recipients</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>$0.002</h3>
                                <p>Cost</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Service-level agreemant*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Broadcast Delivery rate
                            </h5>
                        </div>
                        
                            <div uk-grid="">
                                <div className="uk-width-1-3">
                                   <BroadCastDeliveryRateChart/>
                                </div>

                                <div className="uk-width-2-3">
                                    <div className="labelResoltuionTimeMain">
                                        <ul className="uk-list heading">
                                            
                                            <li><b>Messages</b></li>
                                            <li><b>Percent age</b></li>
                                        </ul>
                                        <ul className="labelResoltuionTime whatsappmessage" >
                                            <li>
                                                <span className="Label">No error</span>
                                                <progress id="js-progressbar" class="uk-progress delivered" value="40" max="100"></progress>
                                                <span className="Percent">96.67%</span>
                                                <span className="Percent">29</span>
                                            </li>

                                            <li>
                                                <span className="Label">Bounced</span>
                                                <progress id="js-progressbar" class="uk-progress pending" value="15" max="100"></progress>
                                                <span className="Percent">3.33%</span>
                                                <span className="Percent">10</span>
                                            </li>

                                            <li>
                                                <span className="Label">Dropped</span>
                                                <progress id="js-progressbar" class="uk-progress expired" value="11" max="100"></progress>
                                                <span className="Percent">0.1%</span>
                                                <span className="Percent">1</span>
                                            </li>

                                            <li>
                                                <span className="Label">System error</span>
                                                <progress id="js-progressbar" class="uk-progress rejected" value="9" max="100"></progress>
                                                <span className="Percent">0.1%</span>
                                                <span className="Percent">1</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>
                {/* Broadcast Summary */}
                <div className="uk-width-1-2">
                    <div className="uk-card uk-card-custom uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Broadcast Summary</h5>
                        </div>
                        <div className="BroadcastSummaryList">
                            <h6>Advanced options</h6>
                            <ul>
                                <li>
                                    <span>Validity period</span>
                                    <span><b>24 hours</b></span>
                                </li>
                                <li>
                                    <span>Delivery speed</span>
                                    <span><b>5 per Minute</b></span>
                                </li>
                            </ul>
                            <h6>Scheduling options</h6>
                            <ul>
                                <li>
                                    <span>Started at</span>
                                    <span><b>January 3, 2023 18:40</b></span>
                                </li>
                                <li>
                                    <span>Time zone</span>
                                    <span><b>Pakistan / Islamabad (PKT)</b></span>
                                </li>
                                <li>
                                    <span>Delivery time window</span>
                                    <span><b>Everyday 00 - 24</b></span>
                                </li>
                            </ul>
                            <h6>Advanced options</h6>
                            <ul>
                                <li>
                                    <span>Sending domain</span>
                                    <span><b>selfserviceib.com</b></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                 {/* * Completion rate* */}
                 <div className="uk-width-1-2">
                    <div className="uk-card uk-card-custom uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Completion rate</h5>
                        </div>
                        <div className="mychartSession uk-padding uk-padding-remove-top uk-padding-remove-bottom">
                            <BroadcastCompRateChart/>
                        </div>
                    </div>
                </div>
                {/* Utilization per agent */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Recipients
                            <div className="uk-float-right ">
                                <div className="AgentDropDown">
                                    <a onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                                        <p><span>Export</span> <ChevronDown /></p>
                                        {/* <ul className="UltabsBtns">
                                            <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                            <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                                        </ul> */}
                                    </a>
                                </div>
                            </div>
                            </h5>
                        </div>
                        

                        <div className="uk-overflow-auto AgentTable">
                            <table className="uk-table uk-table-striped">
                            <thead>
                                <tr>
                                <th className="uk-table-expand">QUEUES</th>
                                <th className="uk-width-small uk-text-nowrap">LIVE CHATS</th>
                                <th className="uk-inline uk-width-small uk-text-nowrap">
                                        <div className="statusBtn"><span>STATUS <ChevronDown /></span></div>
                                        <div className="dropdown-status" uk-dropdown="mode: click">
                                            <ul class="uk-nav uk-dropdown-nav status-dropdown-nav">
                                                <li class="uk-active">
                                                    <a href="#">
                                                        <span className="statusLabel">All</span> 
                                                        <span className="tick"  uk-icon="check"></span>
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="#">
                                                        <span className="statusLabel">Sent</span> 
                                                        <span className="tick" uk-icon="check"></span>
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="#">
                                                        <span className="statusLabel">Bounced</span> 
                                                        <span className="tick" uk-icon="check"></span>
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="#">
                                                        <span className="statusLabel">Dropped</span> 
                                                        <span className="tick" uk-icon="check"></span>
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="#">
                                                        <span className="statusLabel">System Error</span> 
                                                        <span className="tick" uk-icon="check"></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </th>
                                <th className="uk-width-small uk-text-nowrap">SENT AT</th>
                                
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="sent">Sent</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="sent">Sent</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="sent">Sent</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="sent">Sent</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="bounced">Bounced</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="bounced">Bounced</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="bounced">Bounced</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="canceled">System Error</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Naveen Asim</span></td>
                                    <td><span>naveenasim45@gmail.com</span> </td>
                                    <td><span class="progress">In Progress</span></td>
                                    <td className="uk-text-nowrap"><span>31/10/2022 18:21</span></td>
                                </tr>
                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
         </>
    );
}
}
export default BroadCastAnalytics;