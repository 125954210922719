import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, Checkbox } from "@adobe/react-spectrum";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {
  Checkmark,
  FaceSatisfied,
  UserRole,
  Image,
  Attachment,
  Location,
  DecisionTree,
  Edit,
  StopOutline,
  TrashCan,
  Download,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  Time,
  Credentials,
  Add,
  Close,
  Renew,
} from "@carbon/icons-react";
import PageActionBar from "../../Sections/PageActionBar";
import Constant from "../../../App/Constant";
import axios from "axios";
import $, { cssNumber, data } from "jquery";
import { Multiselect } from "multiselect-react-dropdown";
import Emoji from "../../Common/Emoji";

class CampaignChat extends React.Component {
  constructor() {
    super();
    this.charUserApi = this.charUserApi.bind(this);
    this.handleUserList = this.handleUserList.bind(this);
    this.getTags = this.getTags.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    // this.handleEmailChange = this.AddUnsubscribe.bind(this);
    this.onSelectTags = this.onSelectTags.bind(this);
    this.onRemoveTags = this.onRemoveTags.bind(this);
    this.state = {
      chatList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      tagList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      first_name: "",
      last_name: "",
      gender: "",
      email: "",
      phone: "",
      files: null,
      threadCount: 0,
      oldContainerId: 0,
      conversations_id: 0,
      tags: [],
    };
  }

  onSelectTags(selectedList, selectedItem) {
    this.setState({ tags: selectedList });
  }
  onRemoveTags(selectedList, removedItem) {
    this.setState({ tags: selectedList });
  }

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  addSaveUserSegment = () => {
    $("#SaveUserSegment").addClass("uk-flex uk-open");
  };

  onFileChange = (event) => {
    if (event.target.files[0] != undefined) {
      let agentPhoto = localStorage.getItem("agent-photo");
      let conversation_id = localStorage.getItem("conversation_id");
      let company_id = localStorage.getItem("company_id");
      let user_id = localStorage.getItem("user_id");
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("conversation_id", conversation_id);
      formData.append("company_id", company_id);
      formData.append("user_id", user_id);
      formData.append("status", 1);
      formData.append("action", "send_reply");

      axios({
        method: "POST",
        url: Constant.url + "send-reply",
        contentType: "multipart/form-data",
        processData: false,
        data: formData,
      }).then(function (result) {
        if (result.status == 200) {
          if (event.target.files[0].type.includes("text")) {
            $(".message_box_list").append(
              "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                agentPhoto +
                "'></div><div class='text'><span>" +
                result.data.data.filename +
                "</span></div></div></li>"
            );
          } else if (event.target.files[0].type.includes("image")) {
            $(".message_box_list").append(
              "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                agentPhoto +
                "' /></div><div class='text'><img src='" +
                result.data.data.filename +
                "'  width='200' height='200' /></div></div></li>"
            );
          } else if (event.target.files[0].type.includes("video")) {
            $(".message_box_list").append(
              "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                agentPhoto +
                "' /></div><div class='text'><video width='320' height='240' controls><source src='" +
                result.data.data.filename +
                "' type='video/mp4' /></video></div></div></li>"
            );
          } else if (event.target.files[0].type.includes("audio")) {
            $(".message_box_list").append(
              "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                agentPhoto +
                "' /></div><div class='text'><audio id='player' src='" +
                result.data.data.filename +
                "'  type='audio/mp3' controls></audio></div></div></li>"
            );
          } else if (event.target.files[0].type.includes("application")) {
            $(".message_box_list").append(
              "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
                agentPhoto +
                "' /></div><div class='text customPdfBtn'><a href='" +
                result.data.data.filename +
                "' download><img src='images/box.svg' uk-svg='' /> Document.pdf </a></div></div></li>"
            );
          }
          $(".messages_box").scrollTop($(".messages_box")[0].scrollHeight);
        }
      });
    }
  };

  refreshMsg = () => {
    window.refreshMsg();
  };

  handleFirstNameChange(event) {
    this.setState({ first_name: event.target.first_name });
  }

  handleLastNameChange(event) {
    this.setState({ last_name: event.target.last_name });
  }

  handleGenderChange(event) {
    this.setState({ gender: event.target.gender });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.email });
  }

  componentDidMount() {
    this.charUserApi();
    this.loadFilter();
    this.getTags();
    $("#add-agent").hide();
    this.selectCheckbox();
    this.loadAgents();
    $("#selectDiv").addClass("uk-hidden");
    this.loadSuggestedMsgs();
    $("#cross").hide();
  }

  loadAgents = () => {
    let company_id = localStorage.getItem("company_id");
    let agentsParams = new FormData();
    agentsParams.append("company_id", company_id);

    axios({
      method: "POST",
      url: Constant.url + "get-agents",
      data: agentsParams,
    }).then(function (response) {
      if (response.data.status === true) {
        $("#agentsList ul").empty();
        for (let i = 0; i < response.data.data.length; i++) {
          $("#agentsList ul").append(
            "<li id='agents_" +
              response.data.data[i].id +
              "'><button class='' type='button' onclick='agentsSelection(" +
              response.data.data[i].id +
              ")' id='agents_id_" +
              response.data.data[i].id +
              "'><div class='icon'><img src='" +
              response.data.data[i].photo_url +
              "'/></div><span class='name'>" +
              response.data.data[i].name +
              "</span><span class='uk-icon'><svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='16' height='16' viewBox='0 0 32 32' aria-hidden='true'><path d='M13 24L4 15 5.414 13.586 13 21.171 26.586 7.586 28 9 13 24z'></path></svg></span></button></li>"
          );
        }
      }
    });
  };

  loadSuggestedMsgs = () => {
    let company_id = localStorage.getItem("company_id");
    let messageParams = new FormData();
    messageParams.append("company_id", company_id);

    axios({
      method: "POST",
      url: Constant.url + "chat-templates",
      data: messageParams,
    }).then(function (response) {
      if (response.data.status === true) {
        $("#suggested_msgs ul").empty();
        for (let i = 0; i < response.data.data.length; i++) {
          $("#suggested_msgs ul").append(
            "<li id='suggest_msg_" +
              response.data.data[i].id +
              "' onclick='sendMessage(" +
              response.data.data[i].id +
              ")'><button id='suggest_msg_" +
              response.data.data[i].id +
              "'>" +
              response.data.data[i].name +
              "</button></li>"
          );
        }
      }
    });
  };

  loadFilter = () => {
    axios({
      method: "POST",
      url: Constant.url + "filters",
    }).then(function (result) {
      if (result.data.status === true) {
        $("#assignFilter ul").empty();
        for (let i = 0; i < result.data.data.sidebar.length; i++) {
          $("#assignFilter ul").append(
            "<li><button class='' type='button' onclick='FilterSelection(" +
              '"' +
              result.data.data.sidebar[i].id +
              '"' +
              ")' id='filter_id_" +
              result.data.data.sidebar[i].id +
              "'><span class='name'>" +
              result.data.data.sidebar[i].name +
              "</span><span class='uk-icon'><svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='16' height='16' viewBox='0 0 32 32' aria-hidden='true'><path d='M13 24L4 15 5.414 13.586 13 21.171 26.586 7.586 28 9 13 24z'></path></svg></span></button></li>"
          );
        }
      }
    });
  };

  DownloadProfile = () => {
    $("#customerOptions").hide();
    let customer_id = localStorage.getItem("customer_id");
    let company_id = localStorage.getItem("company_id");
    window.location.href =
      Constant.url +
      "download-profile?company_id=" +
      company_id +
      "&contact_id=" +
      customer_id +
      "";
  };

  DeleteProfile = () => {
    let customer_id = localStorage.getItem("customer_id");
    let company_id = localStorage.getItem("company_id");
    let conversation_id = localStorage.getItem("conversation_id");
    $("#customerOptions").hide();
    $("#li" + conversation_id).remove();
    axios({
      method: "POST",
      url: Constant.url + "delete-profile",
      data: { company_id: company_id, contact_id: customer_id },
    }).then(function (result) {
      if (result.data.status === true) {
        $("#selectDiv").removeClass("uk-hidden");
        $("#chat_box").addClass("uk-hidden");
        $("#profile_details").addClass("uk-hidden");
        $("#selectOptions").addClass("uk-hidden");
        $("#chatImage").removeClass("uk-hidden");
        $("#DeleteContact").removeClass("uk-flex uk-open");
      }
    });
  };

  charUserApi = () => {
    $(".spinner").removeClass("uk-hidden");
    let classRef = this;
    var url = window.location.pathname;
    var id = url.split("/");
    var campaign_id = id[3];
    let company_id = localStorage.getItem("company_id");
    let user_from = localStorage.getItem("user_id");
    let charParams = new FormData();
    charParams.append("campaign_id", campaign_id);
    charParams.append("company_id", company_id);

    axios({
      method: "POST",
      url: Constant.url + "bot-chat-msisdns",
      data: charParams,
    }).then(function (result) {
      if (result.data.status === true) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          chatList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });

        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
      }
    });
  };

  getTags = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    axios({
      method: "POST",
      url: Constant.url + "tags",
      data: { company_id: company_id },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          tagList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  attachTag = () => {
    $(".ModalStyleBtns #addAgent").text("Processing");
    $(".ModalStyleBtns #addAgent").prop("disabled", true);

    let classRef = this;
    var tags = "";
    let company_id = localStorage.getItem("company_id");
    var contact_id = localStorage.getItem("customer_id");
    tags = Object.keys(classRef.state.tags)
      .map(function (k) {
        return classRef.state.tags[k].name;
      })
      .join(",");
    var data = {};

    data = {
      contact_id: contact_id,
      company_id: company_id,
      tag_name: tags,
    };
    axios({
      method: "POST",
      url: Constant.url + "attach-contact-tags",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );

          // this.multiselectRefTracker.current.resetSelectedValues()

          $(".ModalStyleBtns #addAgent").text("Save");
          $(".ModalStyleBtns #addAgent").prop("disabled", false);

          var conversation_id = localStorage.getItem("conversation_id");
          axios({
            method: "POST",
            url: Constant.url + "conversation/" + conversation_id,
          }).then(function (result) {
            if (result.data.status === true) {
              $("#tags").empty();
              let tagCount = 0;
              for (let k = 0; k < result.data.data.customer.tags.length; k++) {
                tagCount += 1;
                if (tagCount < 4) {
                  $("#tags").append(
                    "<button id='tag_" +
                      result.data.data.customer.tags[k].id +
                      "'> " +
                      result.data.data.customer.tags[k].name +
                      " <a onclick='removeCustomerTags(" +
                      result.data.data.customer.tags[k].id +
                      ")'><span><svg focusable='false' preserveAspectRatio='xMidYMid meet' xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='16' height='16' viewBox='0 0 32 32' aria-hidden='true'><path d='M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z'></path></svg></span></a></button>"
                  );
                }
              }
              tagCount = tagCount - 3;
              if (tagCount > 0) {
                $("#tags").append(
                  "<button class='time' onclick='showTagList()'><span> +" +
                    tagCount +
                    " </span>others</button>"
                );
              }
            }
          });

          setTimeout(function () {
            // $("#msg-dialog").hide();

            $("#AddTagsModal").removeClass("uk-flex uk-open");
            UIkit.alert("#msg-dialog").close();
            // $("#saj").children('div').children('span').hide();
          }, 1000);

          return;
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $(".AddTeamBtn").text("Save");
          $(".AddTeamBtn").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  callCustomerDetail = () => {
    let conversation_id = localStorage.getItem("conversation_id");
    let company_id = localStorage.getItem("company_id");
    let user_id = localStorage.getItem("user_id");
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "conversation/" + conversation_id,
      data: { company_id: company_id, user_id: user_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var customerdata = result.data.data.customer;
        classRef.setState({
          first_name: customerdata.first_name,
          last_name: customerdata.last_name,
          gender: customerdata.gender,
          email: customerdata.email,
          phone: customerdata.channel_id,
        });
      }
    });
  };

  saveCustomerDetail = () => {
    $(".AddTeamBtn").text("Processing");
    $(".AddTeamBtn").prop("disabled", true);
    let customer_id = localStorage.getItem("customer_id");
    let conversation_id = localStorage.getItem("conversation_id");
    let first_name = $("#first_name").val();
    let last_name = $("#last_name").val();
    let gender = $("#customer_gender").val();
    let email = $("#email").val();
    axios({
      method: "POST",
      url: Constant.url + "edit-contact/" + customer_id,
      data: {
        first_name: first_name,
        last_name: last_name,
        gender: gender,
        email: email,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        $("#customer_" + conversation_id).text(first_name + " " + last_name);
        $(".profile_name h4").text(first_name + " " + last_name);
        $("#gender").text(gender);
        $("#successAlert2").append(
          '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Customer Edit Successfuuly.</p></div>'
        );
        $(".AddTeamBtn").text("Save");
        $(".AddTeamBtn").prop("disabled", false);
        setTimeout(function () {
          $("#AddNewContact").removeClass("uk-flex uk-open");
          UIkit.alert("#msg-dialog").close();
        }, 1000);
      }
    });
  };

  emoji = () => {
    let messageTabs = $("#messageTabs li.uk-active").attr("data-id");
    if (messageTabs == "reply") {
      if ($("#emoji").hasClass("uk-hidden") == false) {
        $("#emoji").addClass("uk-hidden");
      } else {
        $("#emoji").removeClass("uk-hidden");
      }
    }
  };

  sendWhatsApp = () => {
    window.sendMessage();
  };

  selectCheckbox = () => {
    let classRef = this;
    let threadCount = 0;
    $("#select_all").on("click", function () {
      if (this.checked) {
        $(".checkbox").each(function () {
          this.checked = true;
          threadCount = $(
            '.contacts_list input[type="checkbox"]:checked'
          ).length;
        });
      } else {
        $(".checkbox").each(function () {
          this.checked = false;
          threadCount = $(
            '.contacts_list input[type="checkbox"]:checked'
          ).length;
        });
      }
      $("#chat_box").addClass("uk-hidden");
      $("#profile_details").addClass("uk-hidden");
      classRef.setState({ threadCount: threadCount });
      window.selectAllCheckbox(threadCount);
    });
  };

  unAssignClick = () => {
    window.unAssign();
  };

  onClickCheckbox = () => {
    let classRef = this;
    let threadCount = 0;
    // $('.checkbox').on('click',function(){
    if ($(".checkbox:checked").length == $(".checkbox").length) {
      $("#select_all").prop("checked", true);
      threadCount = $('.contacts_list input[type="checkbox"]:checked').length;
    } else {
      $("#select_all").prop("checked", false);
      threadCount = $('.contacts_list input[type="checkbox"]:checked').length;
    }
    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");
    classRef.setState({ threadCount: threadCount });
    window.selectAllCheckbox(threadCount);
    // });
  };

  fetchUserDetail = (user_id) => {
    // let classRef = this;
    // classRef.setState({ conversations_id : localStorage.getItem('conversation_id') });
    window.fetchBotChat(user_id);
  };

  addTag = () => {
    $("#AddTagsModal").addClass("uk-flex uk-open");
  };

  confirmResolveError = () => {
    $("#markResolveError").removeClass("uk-flex uk-open");
  };

  confirmReinitaite = () => {
    let template_id = $("#suggested_msgs_pop")
      .find("option:selected")
      .attr("id");
    template_id = template_id.split("_")[3];
    if (template_id > 0) {
      let user_id = localStorage.getItem("user_id");
      let company_id = localStorage.getItem("company_id");
      let conversation_id = localStorage.getItem("conversation_id");
      let reInitiateParams = new FormData();
      reInitiateParams.append("conversation_id", conversation_id);
      reInitiateParams.append("company_id", company_id);
      reInitiateParams.append("template_id", template_id);
      reInitiateParams.append("user_id", user_id);
      reInitiateParams.append("status", 1);
      axios({
        method: "POST",
        url: Constant.url + "re-initiate-chat",
        data: reInitiateParams,
      }).then(function (result) {
        if (result.data.status === true) {
          let date = new Date();
          let agentPhoto = localStorage.getItem("agent-photo");
          $(".message_box_list").append(
            "<li class='support'><div class='message'><div class='picture'><img src='https://ui-avatars.com/api/?name=" +
              agentPhoto +
              "'></div><div class='text'><span>" +
              result.data.data.msg +
              "</span><div class='dateSpan'>" +
              date.getHours() +
              ":" +
              date.getMinutes() +
              "</div></div></div></li>"
          );
          $(".messages_box").scrollTop($(".messages_box")[0].scrollHeight);
          $("#reinitiate").removeClass("uk-flex uk-open");
        }
      });
    } else {
    }
  };

  markResolved = () => {
    let tagCount = document.querySelectorAll("#tags button");
    if (tagCount.length > 0) {
      this.MarkResolved();
    } else {
      $("#markResolveError").addClass("uk-flex uk-open");
    }
  };

  markConfirmClick = () => {
    window.markResolved();
    this.markCancelClick();
    $(".seen_status").hide();
  };

  bulkMarkResolved = () => {
    let resolvedConversations = "";
    $('input[name="threadSelect"]:checked').each(function () {
      resolvedConversations += $(this).attr("id").split("select")[1] + ",";
    });
    resolvedConversations = resolvedConversations.slice(0, -1);

    let user_id = localStorage.getItem("user_id");
    let company_id = localStorage.getItem("company_id");
    let resolvedParams = new FormData();
    resolvedParams.append("conversation_id", resolvedConversations);
    resolvedParams.append("user_id", user_id);
    resolvedParams.append("status", 3);
    resolvedParams.append("company_id", company_id);
    axios({
      method: "POST",
      url: Constant.url + "change-conversation-status",
      data: resolvedParams,
    }).then(function (result) {
      if (result.data.status === true) {
        $(".checkbox").each(function () {
          this.checked = false;
        });
        $("#select_all").prop("checked", false);
        $("#selectOptions").addClass("uk-hidden");
        $("#chatImage").removeClass("uk-hidden");
        $("#AddResolved").removeClass("uk-flex uk-open");
      }
    });
  };

  bulkAssignClick = () => {
    window.bulkAssign();
  };

  getcurrentDate = () => {
    let today = new Date();

    let date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);

    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    return date + "T" + time;
  };

  handleUserList = () => {
    let result = "";
    let index = 0;

    result = this.state.chatList.list.map((response, index) => (
      <li id={"li" + response.conversation_id}>
        <div className="chat_tab uk-grid uk-grid-small" uk-grid="">
          <div className="uk-width-auto uk-flex-first">
            {/* <div className="chat_select"> */}
            {/* <Checkbox isEmphasized></Checkbox> */}
            {/* <input className="uk-checkbox checkbox"  id={"select"+response.conversation_id} onClick={this.onClickCheckbox} type="checkbox" name="threadSelect"></input> */}
            {/* </div> */}
          </div>
          <a
            className="user_list uk-width-expand"
            id={"convesation_iteation_" + response.conversation_id}
            onClick={(e) => this.fetchUserDetail(response.conversation_id)}
          >
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-auto uk-flex-first">
                <div className="pp">
                  <img src={response.avatar} />
                  <div className="whatsappProfilelogo">
                    <img src="/images/chatprofilewhatsApp.svg" uk-svg="" />
                  </div>
                </div>
              </div>
              <div className="uk-width-expand">
                <p id={"customer_" + response.conversation_id}>
                  {response.name}
                </p>
                <p id={"msg_" + response.conversation_id}>{response.preview}</p>
              </div>

              <div className="uk-width-auto uk-flex-last">
                <div className="timingBox">
                  {/* {response.user_id == null ? <div className="assignedunassigned">Unassigned</div> : <div className="assignedunassigned">Assigned</div> } */}
                  <div className="time newTime">
                    <p>{response.last_reply_at.slice(0, -3)}</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </li>
    ));

    return result;
  };

  addNewContact = () => {
    $("#AddNewContact").addClass("uk-flex uk-open");
    $("#customerOptions").hide();
    this.callCustomerDetail();
  };
  closeContactModal = () => {
    $("#AddNewContact").removeClass("uk-flex uk-open");
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    this.setState({ advancesearch: searchword });

    var url = window.location.pathname;
    var id = url.split("/");
    var campaign_id = id[3];

    if (searchword !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "bot-chat-msisdns",
      data: {
        company_id: localStorage.getItem("company_id"),
        campaign_id: campaign_id,
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          chatList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  advanceSearch = () => {
    var url = window.location.pathname;
    var id = url.split("/");
    var campaign_id = id[3];

    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      company_id: localStorage.getItem("company_id"),
      campaign_id: campaign_id,
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }

    classRef.setState({ advancesearch: key });

    if (key !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "bot-chat-msisdns",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          chatList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  advSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.charUserApi();

    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");

    $("#cross").hide();
  };

  render() {
    const options = [
      {
        value: 1,
        label: (
          <div className="chip">
            <img src="/images/avatar.jpg" alt="Image" />
            <span>Usman Ramnani</span>
          </div>
        ),
      },
      {
        value: 2,
        label: (
          <div className="chip">
            <img src="/images/avatar.jpg" alt="Image" />
            <span>Usman Ramnani</span>
          </div>
        ),
      },
    ];
    return (
      <Content>
        <div className="chat_container" uk-height-match=".ht_match">
          {/* <PageActionBar />    */}

          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-1-4">
                <h3>Chat History</h3>
              </div>
              <div className="uk-width-expand uk-text-center">
                <div className="search_field">
                  <form>
                    <input
                      className="uk-input"
                      type="text"
                      onChange={this.searchrecord}
                      name="searchword"
                      id="searchword"
                      value={this.state.advancesearch}
                      placeholder="Search through msisdn"
                    />
                  </form>
                  <div class="CampaignReports Filtermain">
                    <div className="uk-width-auto">
                      <button
                        id="cross"
                        style={{ right: "30px" }}
                        onClick={this.advSearchClear}
                      >
                        <Close />
                      </button>
                      <button onClick={this.addSearchModal}>
                        <img src="/images/newfilter.svg" uk-svg />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-4">
                <div id="add-agent">
                <button
                  className="newFlowBtn uk-float-right"
                  uk-toggle="target: #AddBroadcastModal"
                >
                  <Add /> <span>Add Agent</span>
                </button>
                </div>
              </div>
            </div>
          </div>

          {/* audio tone */}

          <audio
            id="buzzer"
            src="/audio/message_tone.mp3"
            type="audio/mp3"
          ></audio>
          <div className="uk-grid uk-grid-collapse" uk-grid>
            <div className="chat_heads_list ht_match uk-width-1-4">
              <div className="chat_box_head">
                <div className="mdl">
                  <div className="mdl_inner">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand">
                        <div className="user_selection">
                          <div className="uk-inline">
                            <div className="select_all">
                              {/* <input onClick={this.onClickCheckbox} className="uk-checkbox" id="select_all" type="checkbox" name="" /> */}
                            </div>
                            {/* <img src="/images/toplogo.svg"/> */}
                            {/* <button className="" type="button">
                              <span id="filterTitle">Bot</span>
                              <span uk-icon="chevron-down"></span>
                            </button> */}
                            {/* <div uk-dropdown="mode: click" id="assignFilter">
                              <ul></ul>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="contacts_list contacts_list_loader"
                id="threadList"
              >
                <ul>
                  {/* {(this.state.chatList.isLoaded) ? this.handleUserList() : ""} */}

                  {this.state.chatList.isLoaded ? (
                    this.handleUserList()
                  ) : this.state.chatList.msg == "not" ? (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center loaderData uk-height-large">
                          Data not Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          <div className="mdl">
                            <div className="mdl_inner">
                              <div uk-spinner=""></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </ul>
              </div>
            </div>
            <div className="chat_box ht_match uk-width-expand spinner">
              <div className="uk-text-center uk-position-center ">
                <span uk-spinner="ratio: 2" />
              </div>
            </div>
            <div
              className="chat_box ht_match uk-width-expand uk-hidden"
              id="chat_box"
            >
              <div className="chat_box_head">
                <div className="uk-grid" uk-grid="">
                  <div className="uk-width-expand">
                    {/* <div className="user_selection">
                      <div className="uk-inline uk-hidden" id="btnAgent">
                        <button className="" type="button">
                          <div className="icon"><img src="" id="profile_pic"/></div>
                          <span id="agentTitle">Select Agent</span>
                          <span uk-icon="chevron-down"></span>
                        </button>
                        <div uk-dropdown="mode: click" id="agentList">
                          <ul></ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="uk-width-auto">
                    <div className="RefreshMsg">
                      {/* <button onClick={this.refreshMsg}>
                        <span>
                          <Renew />
                        </span>
                        Refresh
                      </button> */}
                    </div>
                    <div className="seen_status">
                      {/* <button onClick={this.markResolved}>
                        {" "}
                        <span>
                          <img src="/images/done_all_black_24dp.svg" />
                        </span>{" "}
                        Mark as resolved
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat_date">{/* <p>Today, 13:38</p> */}</div>

              <div className="messages_box CampaignChatmessages_box">
                <ul className="message_box_list" id="message_box_list">
                  {/* audio start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <audio
                          id="player"
                          src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3"
                          type="audio/mp3"
                          controls
                        ></audio>
                      </div>
                    </div>
                  </li>

                  <div className="dateSpan">8:30 PM</div>
                  {/* audio end */}

                  {/* video start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <video width="320" height="240" controls>
                          <source
                            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"
                            type="video/mp4"
                          />
                          {/* <source src="movie.ogg" type="video/ogg" />  */}
                        </video>
                      </div>
                    </div>
                  </li>
                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <video width="320" height="240" controls>
                          <source
                            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  </li>
                  {/* video end */}

                  {/* img start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <img src="images/avatar.jpg" width="200" height="200" />
                      </div>
                    </div>
                  </li>

                  {/* img end */}

                  {/* pdf start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text customPdfBtn">
                        <a
                          href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                          download=""
                        >
                          <img src="images/box.svg" uk-svg="" /> Document.pdf{" "}
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text customPdfBtn">
                        <a
                          href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                          download
                        >
                          <img src="images/box.svg" uk-svg="" /> Document.pdf{" "}
                        </a>
                      </div>
                    </div>
                  </li>

                  {/* pdf end */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <span>👋</span>
                        <span>Get started</span>
                      </div>
                    </div>
                  </li>
                  <li className="prompt_msg">
                    <p className="uk-text-center">
                      Opted-in through widget: 👋 Get Started
                    </p>
                  </li>
                  <li className="support">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <span>
                          We are very sorry, that we could not resolve your
                          issue. Unfortunately, we have constantly been looking
                          for solutions and have found none so far.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="prompt_msg">
                    <p className="uk-text-center">
                      Usman Khan attached a note to WhatsApp conversation:
                    </p>
                  </li>
                </ul>
              </div>

              <hr />
              {/* <div class="ModalStyleBtns ii uk-text-center" id="Reinitiate">
                <button class="AddTeamBtn uk-float-none" onClick={this.showReinitiate}>Re-Initiate Chat</button>
              </div> */}
              {/* <div className="suggested_msgs" id="suggested_msgs">
                <ul>
                </ul>
              </div> */}

              {/* <div className="type_box">
                <ul
                  className="uk-subnav "
                  uk-switcher="animation: uk-animation-fade"
                  id="messageTabs"
                >
                  <li data-id="reply">
                    <a href="#">Reply</a>
                  </li>
                  <li data-id="notes">
                    <a href="#">Notes</a>
                  </li>
                </ul> */}

              {/* <ul className="uk-switcher">
                  <li>
                    <div className="placeholder">
                      <textarea id="txtReply" placeholder="Reply here"></textarea>
                    </div>
                  </li>
                  <li>
                    <div className="placeholder ii">
                      <textarea id="txtNotes" placeholder="Leave a note for your team members or reminder for yourself"></textarea>
                    </div>
                  </li>
                </ul> */}

              {/* <div className="submit_bar">
                  <div className="uk-grid uk-grid-collapse">
                    <div className="uk-width-1-2">
                      <div className="attacments">
                        <ul>
                          <li id="emoji" className="uk-hidden">
                              <Emoji/>
                          </li>
                          <li>
                            <button>
                              <FaceSatisfied onClick={this.emoji}/>
                            </button>
                          </li>
                          <li>
                            <div className="js-upload" uk-form-custom="">
                              <input type="file" id="file_image" onChange={this.onFileChange}/>
                              <button type="button" tabIndex="-1">
                                <Image />
                              </button>
                            </div>
                          </li>
                          <li>
                            <div className="js-upload" uk-form-custom="">
                              <input type="file" id="file_attach" onChange={this.onFileChange}/>
                              <button type="button" tabIndex="-1">
                                <Attachment />
                              </button>
                            </div>
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                    <div className="uk-width-1-2">
                      <div className=" action_btns customaction_btns uk-grid uk-grid-small uk-float-right uk-child-width-auto">
                        
                      <div>
                          <button className="fill" id="sendMessage" onClick={this.sendWhatsApp} > Send WhatsApp</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div
              className="profile_details ht_match uk-width-1-4  uk-hidden"
              id="profile_details"
            >
              <div className="chat_box_head">
                <div className="mdl">
                  <div className="mdl_inner">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand">
                        <div className="profile_name">
                          <h4>Naveen Asim</h4>
                        </div>
                      </div>
                      <div className="uk-width-auto">
                        <div className="profile_action">
                          <div className="uk-inline">
                            <button className="" type="button">
                              <span uk-icon="more"></span>
                            </button>
                            <div uk-dropdown="mode: click" id="customerOptions">
                              <ul className="action_menu">
                                {/* <li>
                                  <button  onClick={this.addNewContact}>
                                    <span className="icon">
                                      <Edit />
                                    </span>{" "}
                                    <span>Edit</span>
                                  </button>
                                </li> */}
                                {/* <li>
                                  <button>
                                    <span className="icon">
                                      <StopOutline />
                                    </span>
                                    <span>Unsubscribe</span>
                                  </button>
                                </li> */}
                                <li>
                                  <button onClick={this.DownloadProfile}>
                                    <span className="icon">
                                      <Download />
                                    </span>
                                    <span>Download Data</span>
                                  </button>
                                </li>
                              </ul>
                              {/* <hr></hr> */}
                              {/* <ul className="action_menu">
                                <li className="delete">
                                  <button onClick={this.DeleteContactShow}>
                                    <span className="icon">
                                      <TrashCan />
                                    </span>{" "}
                                    <span>Delete</span>
                                  </button>
                                </li>
                              </ul> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_content">
                <div className="profile_pic">
                  <img src="/images/" alt="" id="customer_profile_pic" />
                </div>
                <div className="contact_details">
                  <ul>
                    <li>
                      <button>
                        {" "}
                        <span>
                          <MobileCheck />
                        </span>{" "}
                        <span id="mobile_no">033565472945</span>
                      </button>
                    </li>
                    {/* <li><button onClick={this.AddUnsubscribe}> <span><CheckmarkOutline /></span> <span>Subscribed</span></button></li> */}
                    {/* <li> <span><GenderFemale /></span> <span id="gender">Female</span></li> */}
                    <li>
                      {" "}
                      <span>
                        <Time />
                      </span>{" "}
                      <span id="time">Contact Time: 16:07 (UTC+05)</span>
                    </li>
                    {/* <li class="uk-hidden"> <span><Credentials /></span> <span>5947368681956251</span></li> */}
                  </ul>
                </div>
                <div className="pause_automation">
                  {/* <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-2-3">
                      <h4>Pause Automation</h4>
                    </div>
                    <div className="uk-width-1-3">
                      <button className="time uk-float-right"><span><Add /></span>30 min</button>
                    </div>
                  </div> */}
                  {/* <div className="button">
                    <button onClick={this.showpause}> <span className="awsome"></span> Pause Automation</button>
                  </div> */}
                </div>

                {/* <div className="pause_automation not ">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-1-1">
                      <h4>Notes</h4>
                    </div>
                  </div>
                  <div className="notes"  id="notes">
                    <button> <p className="title">Issue can not be resolved</p> <span className="date">Aug 17, 2022, 17:02</span></button>
                  </div>
                  <div className="showall">
                    <button id="showAll" onClick={this.showAll}>Show all  <span uk-icon="chevron-down"></span></button>
                    <button id="showLess" className="uk-hidden" onClick={this.showLess}>Show less  <span uk-icon="chevron-up"></span></button>
                  </div>
                </div> */}

                {/* <div className="pause_automation ">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-2-3">
                      <h4>Contact Tags</h4>
                    </div>
                    <div className="uk-width-1-3">
                      <button className="time uk-float-right" onClick={this.addTag}><span><Add /></span>Add Tag</button>
                    </div>
                  </div>
                  <div className="tags"  id="tags">
                    <button>  New-customer <span><Close /></span></button>
                    <button>  opt-in-for-SMS <span><Close /></span></button>
                    <button>  user-Registered <span><Close /></span></button>
                    <button>  Loyalty <span><Close /></span></button>
                    <button>  Retail <span><Close /></span></button>
                    <button>  preferred_Email <span><Close /></span></button>
                    <button>  preferred_Phone <span><Close /></span></button>
                  </div>
                </div> */}
                <div
                  id="AddTagsModal"
                  className="AddTeamModal ModalStyle uk-flex-top"
                  uk-modal="esc-close: false; bg-close: false"
                >
                  <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button">
                      {" "}
                      <Close />
                    </button>
                    <h3>Add tag</h3>
                    <div id="errorAlert"></div>
                    <div id="successAlert"></div>
                    <form
                      id="tagform"
                      className="uk-form-stacked"
                      action="javascript:void(0);"
                    >
                      <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="teamName">
                          Select tags
                        </label>

                        {/* <div className="uk-form-controls">
                                  <Select
                                      defaultValue={[options[0], options[1]]}
                                      name="tags"
                                      options={options}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                </div> */}

                        <div className="chipBox">
                          <div className="chipBox CustomChipBox">
                            <Multiselect
                              id="saj"
                              options={this.state.tagList.list}
                              selectedValues={this.state.tags}
                              onSelect={this.onSelectTags}
                              onRemove={this.onRemoveTags}
                              displayValue="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <div className="ModalStyleBtns">
                          <button
                            className="AddTeamBtn"
                            id="addAgent"
                            onClick={this.attachTag}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="pause_automation ">
                  <div className="head uk-padding-remove uk-grid uk-grid-collapse">
                    <div className="uk-width-1-1">
                      <h4>Opted in through</h4>
                    </div>
                    <div className="uk-width-1-1">
                      <div className="tags uk-margin-remove">
                        <button>  👋 Get Started</button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className="multi_chat_select_prompt ht_match uk-width-expand "
              id="selectDiv"
            >
              <div className="mdl">
                <div className="mdl_inner">
                  <div className="assignment_actions">
                    <img id="chatImage" src="/images/chaticon.svg" />
                    <p id="data-error" className="uk-text-center uk-hidden">
                      No Data Found!
                    </p>
                    <ul className="uk-hidden" id="selectOptions">
                      <li>
                        <h5 className="uk-text-center">
                          {" "}
                          <span id="threadCount_span">
                            {this.state.threadCount}
                          </span>{" "}
                          conversations selected
                        </h5>
                      </li>
                      <li>
                        <button className="" id="" onClick={this.addMarkAsRead}>
                          <span>
                            <img
                              src="/images/done_all_black_24dp.svg"
                              uk-svg=""
                            />
                          </span>{" "}
                          Mark as resolved
                        </button>
                      </li>
                      <li>
                        <div className="user_selection">
                          <div className="uk-inline uk-width-1-1">
                            {/* <button className="" type="button">
                                <span><UserRole /></span>
                                  Assign Conversations
                                </button> */}
                            {/* <div uk-dropdown="mode: click" id="agentsList">
                                  <ul>
                                    <li>
                                      <button className="active" type="button">
                                        <div className="icon"></div>
                                        <span className="name">Usman Ramnani</span>
                                        <span className="uk-icon">
                                          <Checkmark />
                                        </span>
                                      </button>
                                    </li>
                                    <li>
                                      <button className="" type="button">
                                        <div className="icon"></div>
                                        <span className="name">Yumna Zaidi</span>
                                        <span className="uk-icon">
                                          <Checkmark />
                                        </span>
                                      </button>
                                    </li>
                                    <li>
                                      <button className="" type="button">
                                        <div className="icon"></div>
                                        <span className="name">Usman Khan</span>
                                        <span className="uk-icon">
                                          <Checkmark />
                                        </span>
                                      </button>
                                    </li>
                                  </ul>
                                </div> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <button className="" id="" onClick={this.unassignShow}>
                          <span>
                            <img src="/images/user--role.svg" uk-svg="" />
                          </span>{" "}
                          Unassign Conversations
                        </button>
                      </li>
                    </ul>
                    <div
                      className="clear_selection uk-text-center uk-hidden"
                      id="selectOptions"
                    >
                      <Link to="#">Clear Selection</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* bulk mark as resolved  */}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Mark as resolved </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>
                  <span id="threadCount_mark_resolved_span">
                    {this.state.threadCount}
                  </span>{" "}
                  conversations will be marked as resolved!
                </b>
              </p>
              <p className="small">
                This action will affect{" "}
                <span id="threadCount_mark_bottom_span">
                  {this.state.threadCount}
                </span>{" "}
                conversations. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.cancelBulkResolved}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    onClick={this.bulkMarkResolved}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Bulk assign popup */}

        <div
          id="bulkAssign"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Assign Conversations</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>
                  <span id="conversation_top_count"></span> conversations will
                  be assigned to <span id="agentsName"></span>
                </b>
              </p>
              <p className="small">
                This action will affect{" "}
                <span id="conversation_bottom_count"></span> conversation. Are
                you sure?
              </p>
              <p className="uk-hidden" id="agents_id"></p>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.cancelBulkAssign}>
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick={this.bulkAssignClick}>
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* mark as resolved popup */}
        <div
          id="MarkResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Mark as resolved </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>This conversations will be marked as resolved!</b>
              </p>
              <p className="small">
                This action will affect 1 conversation. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.markCancelClick}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    onClick={this.markConfirmClick}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* pause automation popup */}
        <div
          id="AumationPopup"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Pause Automation </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>This conversation will be paused !</b>
              </p>
              <p className="small">
                This action will affect 1 conversation. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.pauseCancelClick}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    onClick={this.pauseConfirmClick}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* unassign popup */}
        <div
          id="unAssign"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Unassign Conversations </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>
                  <span id="threadCount_unassign_span">
                    {this.state.threadCount}
                  </span>{" "}
                  conversations will be unassigned
                </b>
              </p>
              <p className="small">
                This action will affect{" "}
                <span id="threadCount_unassign_bottom_span">
                  {this.state.threadCount}
                </span>{" "}
                conversation. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.unassignHide}>
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick={this.unAssignClick}>
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* markResolveError popup */}
        <div
          id="markResolveError"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Error </span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>
                  Please add customer tag, before resolved this conversation.
                </b>
              </p>
              {/* <p className="small">This action will affect <span id="threadCount_unassign_bottom_span">{ this.state.threadCount }</span> conversation. Are you sure?</p> */}

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                  <button
                    className="AddTeamBtn"
                    onClick={this.confirmResolveError}
                  >
                    Ok
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* reinitiate popup */}
        <div
          id="reinitiate"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Re Initiate Chat</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div id="errorAlert2"></div>
            <div className="resolved_content">
              <select
                class="uk-select"
                id="suggested_msgs_pop"
                name="suggested_msgs_pop"
              >
                <option>Select Message</option>
              </select>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                  <button
                    className="AddTeamBtn"
                    onClick={this.confirmReinitaite}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* ShowAllTag popup */}
        <div
          id="ShowAllTag"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Tag List</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div id="errorAlert2"></div>
            <div className="resolved_content">
              <div className="tags" id="tagsList"></div>
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                  <button className="AddTeamBtn" onClick={this.hideTagList}>
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Delete Contact */}
        <div
          id="DeleteContact"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Delete Profile </span>
              <span className="uk-inline uk-float-right">
                <TrashCan />
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>This profile will be deleted</b>
              </p>
              <p className="small">
                This action will affect 1 profile. Are you sure?
              </p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.DeleteContactHide}>
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick={this.DeleteProfile}>
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="to_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="from_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}

export default CampaignChat;
