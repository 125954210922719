import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from '@adobe/react-spectrum';
import { Multiselect } from 'multiselect-react-dropdown';
import { Add, CaretDown, Edit, TrashCan, Chat, OverflowMenuVertical, Download,Close, Checkmark } from '@carbon/icons-react';
import Constant from "../../../App/Constant";
import axios from 'axios';
import $ from 'jquery';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';

class TeamDetail extends React.Component {

    constructor() {
        super();
        this.callApi = this.callApi.bind(this);
        this.handleTable = this.handleTable.bind(this);
        this.state = {
            dataList: {
                isLoaded: false,
                msg: 'Loading...',
                list: []
            },
            rowsCountStats : 0,
            advancesearch:"",
        }
    }


    componentDidMount() {
        var pageCount = 1;
        this.callApi(pageCount);
        $("#cross").hide()


       
    }


    advSearchClear = () => {
        let classRef = this;     
            classRef.setState({ advancesearch:""})
            classRef.callApi()
            $("#cross").hide()
            
      
      }

    EditTeamModal = (id) =>{
        

        localStorage.setItem('stats_conversation_id', id);

        window.location.href = "/chat";
  
      }


      EditTeamBotModal = (id) =>{
        

        localStorage.setItem('bot_stats_conversation_id', id);

        window.location.href = "/bot-chat";
  
      }
    
    



    callApi = (pageCount) => {


        let classRef = this;
        var url = window.location.pathname;
        var id = url.split('/');
        let sortingValue = $("#QuickSortingBtn").val();
        sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
        var company_id = localStorage.getItem('company_id');

        axios({
            method: 'POST',
            url: Constant.url + "team-details",
            data: { company_id: company_id,team_id:id[2],limit : sortingValue,page: pageCount}
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    dataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountStats : result.data.rowscount
                })
            }else{

                classRef.setState({
                  dataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountStats : result.data.rowscount
              })
              }
        })

    }


    searchrecord = () => {
        let classRef = this;
        var searchword = $("#searchword").val();
        this.setState({ advancesearch: searchword });
    
        if (searchword !== "") {
          $("#cross").show();
        }
    
        axios({
          method: "POST",
          url: Constant.url + "chats",
          data: {
            company_id: localStorage.getItem("company_id"),
            type: localStorage.getItem("stats_type"),
            card:localStorage.getItem("stats_card"),
            dateto:localStorage.getItem("stats_dateform"),
            datefrom:localStorage.getItem("stats_dateto"),
            channel_type:localStorage.getItem("channeltype"),
            search: searchword,
          },
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              dataList: {
                isLoaded: true,
                msg: "Loading...",
                list: result.data.data,
              },
              rowsCountStats: result.data.rowscount,
            });
          } else {
            classRef.setState({
              dataList: {
                isLoaded: false,
                msg: "not",
                list: [],
              },
              rowsCountStats: result.data.rowscount,
            });
          }
    
        });
      };

    handleTable = () => {
        let result = "";
        let index = 0;
        result = this.state.dataList.list.map((response) => (
            <tr>
                <td><span>{response.first_name} {response.last_name}</span></td>
                <td>{response.email}</td>
                <td>{response.phone}</td>
            </tr>
        ));
        return result;
    }



    downloadStats= () => {

        let classRef = this;
        var url = window.location.pathname;
        var id = url.split('/');
    
        var team_id = id[2];

        window.open(Constant.url +"team-download?company_id=" + localStorage.getItem('company_id') + "&team_id=" + team_id, "_blank")

       
      }


      handleQuickClick = (data) =>{
        let currentPage = data.selected + 1;
        this.QuickSorting(currentPage);
      }


      QuickSorting = (pageCount) => {

        let classRef = this;
        var url = window.location.pathname;
        var id = url.split('/');
        let sortingValue = $("#QuickSortingBtn").val();
        sortingValue = (sortingValue != "Sorting") ? sortingValue : 10;
        var company_id = localStorage.getItem('company_id');
        
        axios({
            method: 'POST',
            url: Constant.url + "team-details",
            data: { company_id: company_id,team_id:id[2],page: pageCount,limit : sortingValue }
        }).then(function (result) {
            if (result.data.status === true) {
                classRef.setState({
                    dataList: {
                        isLoaded: true,
                        msg: 'Loading...',
                        list: result.data.data
                    },
                    rowsCountStats : result.data.rowscount
                })
            }else{

                classRef.setState({
                  dataList: {
                      isLoaded: false,
                      msg: 'not',
                      list: []
                  },
                  rowsCountStats : result.data.rowscount
              })
              }
        })

      
      }

      searchrecord = () => {
        let classRef = this;
        var searchword = $("#searchword").val();
        this.setState({ advancesearch: searchword });
    
        if (searchword !== "") {
          $("#cross").show();
        }
    
        axios({
          method: "POST",
          url: Constant.url + "conversation_stats",
          data: {
            company_id: localStorage.getItem("company_id"),
            channel_type: localStorage.getItem("channeltype"),
            search: searchword,
          },
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              dataList: {
                isLoaded: true,
                msg: "Loading...",
                list: result.data.data,
              },
              rowsCountStats: result.data.rowscount,
            });
          } else {
            classRef.setState({
              dataList: {
                isLoaded: false,
                msg: "not",
                list: [],
              },
              rowsCountStats: result.data.rowscount,
            });
          }
        });
      };


      
    


render() {

    return (
        <Content>
            <div className="TeamContainer" uk-height-viewport="" uk-height-match=".h_match">
                
                    <div className="head">
                    <div className=" uk-grid" uk-grid="">
                        <div className="uk-width-auto headingBroadcastHead"><h3>Team Details</h3></div>
                        <div className="uk-width-expand"></div>

                        {/* <div className="uk-width-expand">
                        <div className="tagInput">
                          <div className="search_field">
                              <form action="javascript:void(0);">
                                  <input className="uk-input" type="text" onChange={this.searchrecord} name="searchword" id="searchword" value={this.state.advancesearch}  placeholder="Search through msisdn"/>
                              </form>
                              <div class="CampaignReports Filtermain">
                                          <div className="uk-width-auto">
                                          <button id='cross' style={{right: "10px"}} onClick = {this.advSearchClear}><Close/></button>
                                          </div>
                              </div> 
                          </div>
                          
                    </div>
                    </div> */}

                    <div className="uk-width-auto">
                            <button className="newFlowBtn uk-margin-small-top uk-margin-small-right " id="AddBroadcast" onClick={this.downloadStats}><Add /> <span>Export Team Detail</span></button>
                    </div>
                    {/* <div className="uk-width-auto">
                            <button className="newFlowBtn uk-margin-small-top" id="AddBroadcast" onClick={this.downloadStats}><Add /> <span>Export Stats Detail</span></button>
                       </div> */}
                    </div>
                </div>
                <div className="uk-grid">  
                <div className="uk-width-expand"></div>
                      

                <div className="numberSorting uk-float-right broadcastPagSort">
                <div class="uk-inline">
                    <label>Show
                    <select onChange={(e)=>this.QuickSorting()} id="QuickSortingBtn">
                        {/* <option disabled selected>Sorting</option> */}
                        <option>10</option>
                        <option>20</option>
                        <option>50</option>
                        <option>500</option>
                    </select>
                    entries</label>
                </div>
            </div> 
            </div> 

                <div className="tableSec">
                    <div className="uk-overflow-auto">
                    <table className="uk-table uk-table-small uk-table-divider">
                        <thead>
                            <tr>
                                <th>Name</th>                           
                                <th>Email</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : (this.state.dataList.msg == 'not') ? <tr><td colSpan="15"><div className="uk-text-center uk-height-large">Data not Found</div></td></tr>: <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr> }
                        </tbody>
                    </table>
                    {(this.state.rowsCountStats > 1) ? 
                  <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'....'}
                      pageCount={this.state.rowsCountStats}
                      onPageChange={this.handleQuickClick}
                      containerClassName={'uk-pagination uk-float-right'}
                      activeClassName={'uk-active'}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={6}
                  /> : ""
              }
                    </div>
                </div>
            </div>
        </Content>
    )
}
}
export default TeamDetail;