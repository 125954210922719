import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Constant, { url } from "../../../App/Constant";

const PasswordRecovery = () => {
  const params = useParams();

  const recoveryEmail = () => {
    axios({
      method: "POST",
      url: Constant.url + "recovery-link-verification",
      data: { verification_code: params.verification_code },
    }).then(function (response) {
      if (response.data.code == 200 && response.data.message == "verified") {
        localStorage.setItem("user_id", response.data.data.id);
        setTimeout(() => {
          window.location.href = "/confirm-password";
        }, 500);
      } else {
        window.location.href = "/forget-password";
      }
    });
  };
  useEffect(() => {
    recoveryEmail();
  }, []);
  return (
    <div className="loader-recovery">
      <div uk-spinner=""></div>
    </div>
  );
};
export default PasswordRecovery;
