import * as React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
  Home,
  Chat,
  User,
  WatsonHealth3DCurveAutoColon,
  Rocket,
  UserMultiple,
  Flash,
  Settings,
  Help,
  CaretSortDown,
  Events,
  TagGroup,
  Credentials,
  TaskTools,
  Analytics,
  Logout,
  NotificationNew,
} from "@carbon/icons-react";
import axios from "axios";
import $ from "jquery";
import Constant from "../../App/Constant";


export class SidebarNotifications extends React.Component {

    constructor() {
        super();
        this.state = {
        default: "no",
        dataList: {
            isLoaded: false,
            msg: "Loading...",
            list: [],
        },
        newnotification: "",
        }
    
    }

    componentDidMount() {
        var classRef = this;
        classRef.newNotificationApi();
        var url = window.location.pathname;
    
        // console.log(url,'hhhhhhhhhh')
    
        setInterval(function () {
          if (classRef.state.default == "no") {
            classRef.newNotificationApi();
          }
        }, 10000);
      }


      handleTable = () => {
        let result = "";
        let index = 0;
        result = this.state.dataList.list.map((response, index) => (
        //   <li>
        //     <div className="notification_profile_text">
        //       <h6 className="title">{response.title}</h6>
        //       <span className="content">{response.text}</span>
        //       <span className="DateTime">{response.created_date}</span>
        //     </div>
        //   </li>

            <li>
            <div className="notificationBox">
                <div className="notificationImg">
                    <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                    <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                </div>
                <div className="notificationTxt">
                    <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                    <span>20 hours ago</span>
                </div>
            </div>
            </li>



         
        ));
        return result;
      };

      newNotificationApi = () => {
        // console.log("newNotification");
        let classRef = this;
        axios({
          method: "POST",
          url: Constant.url + "has-notification",
          data: { user_id: localStorage.getItem("user_id") },
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              newnotification: result.data.data,
            });
          }
        });
      };


      seenNotification = () => {
        let classRef = this;
        var data = {};
        data = {
          user_id: localStorage.getItem("user_id"),
        };
    
        axios({
          method: "POST",
          url: Constant.url + "has-seen",
          data: data,
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.getNotification();
            classRef.setState({ newnotification: "" });
          }
        });
      };
    
      getNotification = () => {
        let classRef = this;
        var data = {};
        data = {
          user_id: localStorage.getItem("user_id"),
        };
    
        axios({
          method: "POST",
          url: Constant.url + "notifications",
          data: data,
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              dataList: {
                isLoaded: true,
                msg: "Loading...",
                list: result.data.data,
              },
            });
          } else {
            classRef.setState({
              dataList: {
                isLoaded: false,
                msg: "not",
                list: [],
              },
            });
          }
        });
      };


  render() {
    return (
      <div
        id="SidebarNotification"
        className="SidebarNotification"
        uk-offcanvas="overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <button className="uk-offcanvas-close" type="button" uk-close="" />
          <h3>Notifications</h3>
          <h6>High Priority</h6>
          <ul>

          {this.state.dataList.isLoaded ? (this.handleTable()) : this.state.dataList.msg == "not" ? (<li><div className="uk-text-center uk-height-large">Data not Found</div></li>): (<li><div className="uk-text-center uk-height-large">Data not Foundd</div></li>)}

            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>


            {/* <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li>
            <li>
                <div className="notificationBox">
                    <div className="notificationImg">
                        <img className="uk-border-circle pp" src="/images/pic.jpg" alt="" />
                        <img className="uk-border-circle bp" src="/images/whatsapp.png" alt="" />
                    </div>
                    <div className="notificationTxt">
                        <p><b>Rashid Khan</b> shared <b>document.pdf</b> for clearance.</p>
                        <span>20 hours ago</span>
                    </div>
                </div>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}
