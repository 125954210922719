import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import {
  CaretDown,
  Checkmark,
  OverflowMenuVertical,
  Add,
  Close,
  Edit,
  StopOutline,
  Download,
  TrashCan,
} from "@carbon/icons-react";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import {
  Filter,
  Tag,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  Time,
  Credentials,
  Search,
} from "@carbon/icons-react";
import { Multiselect } from "multiselect-react-dropdown";

class Contacts extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.getTags = this.getTags.bind(this);
    this.onSelectTags = this.onSelectTags.bind(this);
    this.onRemoveTags = this.onRemoveTags.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      country: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      city: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      filterType: "is",
      popUpName: "",
      popUpNumber: "",
      popUpGender: "",
      popUpTag: "",
      popUpSunscribe: "",
      popUpContactTime: "",
      tagList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      tags: [],
      old_tags: [],
    };
  }

  addContactProfile = (
    contact_name,
    phone,
    gender,
    tags,
    subscribed,
    contact_time,
    id
  ) => {
    this.setState({
      popUpName: contact_name,
      popUpNumber: phone,
      popUpGender: gender,
      popUpTag: tags,
      popUpSunscribe: subscribed,
      popUpContactTime: contact_time,
      popUpId: id,
      checkAll: false,
    });

    this.contactTags(tags, id);

    $("#ContactProfile").addClass("uk-flex uk-open");
    // $("#conid").value(id)
  };

  contactTags = (tags, id) => {
    var cid = id;
    var ctags = [];
    var ctags = tags ? tags.split(",") : "";
    for (let k = 0; k < ctags.length; k++) {
      $("#tags").append(
        "<div class='button' id='tags_" +
          ctags[k] +
          "'> " +
          ctags[k] +
          " <button data-id='" +
          cid +
          "' value='" +
          ctags[k] +
          "' id='" +
          k +
          "' onclick='removeContactTags(this.value)'><span  uk-icon='close'></span></button></div>"
      );
    }
  };

  closeContactProfile = () => {
    $("#ContactProfile").removeClass("uk-flex uk-open");
  };

  componentDidMount() {
    this.callApi();
    this.getTags();
    this.getCountry();
    this.getAgents();
    // this.getCity();
  }

  addContactProfile = (
    contact_name,
    phone,
    gender,
    tags,
    subscribed,
    contact_time,
    id
  ) => {
    this.setState({
      popUpName: contact_name,
      popUpNumber: phone,
      popUpGender: gender,
      popUpTag: tags,
      popUpSunscribe: subscribed,
      popUpContactTime: contact_time,
      popUpId: id,
      checkAll: false,
    });

    this.contactTags(tags, id);

    $("#ContactProfile").addClass("uk-flex uk-open");
    // $("#conid").value(id)
  };

  contactTags = (tags, id) => {
    var cid = id;
    var ctags = [];
    var ctags = tags ? tags.split(",") : "";
    for (let k = 0; k < ctags.length; k++) {
      $("#tags").append(
        "<div class='button' id='tags_" +
          ctags[k] +
          "'> " +
          ctags[k] +
          " <button data-id='" +
          cid +
          "' value='" +
          ctags[k] +
          "' id='" +
          k +
          "' onclick='removeContactTags(this.value)'><span  uk-icon='close'></span></button></div>"
      );
    }
  };

  closeContactProfile = () => {
    $("#ContactProfile").removeClass("uk-flex uk-open");
  };

  getAgents = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "get-agents",
      data: { company_id: localStorage.getItem('company_id') },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleAgent = () => {
    let result = "";
    let index = 0;
    result = this.state.agentList.list.map((response, index) => (
      <option value={response.id}>{response.name}</option>
    ));
    return result;
  };

  getCountry = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "countries",
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          country: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCountry = () => {
    let result = "";
    let index = 0;
    result = this.state.country.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  countryChange = () => {
    this.getCity();
  };

  filtercountryChange = () => {
    this.getfilterCity();
  };

  getfilterCity = () => {
    let classRef = this;
    let country = $("#filtercountry").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  getCity = () => {
    let classRef = this;
    let country = $("#country").val();
    axios({
      method: "POST",
      url: Constant.url + "cities",
      data: { country_name: country },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          city: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleCity = () => {
    let result = "";
    let index = 0;
    result = this.state.city.list.map((response, index) => (
      <option value={response.name}>{response.name}</option>
    ));
    return result;
  };

  getTags = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "tags",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          tagList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  onSelectTags(selectedList, selectedItem) {
    this.setState({ tags: selectedList });
  }
  onRemoveTags(selectedList, removedItem) {
    this.setState({ tags: selectedList });
  }

  attachTag = () => {
    // var user_id = $("#user_id").val()

    $("#addtagss").text("Processing");
    $("#addtagss").prop("disabled", false);

    let classRef = this;
    var tags = "";
    var contact_id = $("#user_id").val();
    tags = Object.keys(classRef.state.tags)
      .map(function (k) {
        return classRef.state.tags[k].name;
      })
      .join(",");
    var data = {};

    data = {
      contact_id: contact_id,
      company_id: localStorage.getItem("company_id"),
      tag_name: tags,
    };
    axios({
      method: "POST",
      url: Constant.url + "attach-contact-tags",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlerttag").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.reload();
          $("#addtagss").text("Save");
          $("#addtagss").prop("disabled", false);
        } else {
          $("#errorAlerttag").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $(".AddTeamBtn").text("Save");
          $(".AddTeamBtn").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  addTag = () => {
    $("#AddTagsModal").addClass("uk-flex uk-open");
  };

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "contacts",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    let tagCount = "";
    let tags = "";
    result = this.state.dataList.list.map(
      (response, index) => (
        (tagCount = response.tags.split(",")),
        (tagCount = tagCount.length - 2),
        (tags =
          response.tags.split(",")[0] + "," + response.tags.split(",")[1]),
        (
          <tr id={"contact_" + response.id}>
            <td>
              <label>
                <input
                  className="uk-checkbox autoSelect"
                  name="multi_check[]"
                  value={response.id}
                  type="checkbox"
                />
              </label>
              {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
            </td>
            <td>
              <div className="avatarIcon">
                <img src={response.avatar} alt="Image" />
              </div>
            </td>

            {/* <Link to="/dashboard"><Home /><span>Home</span> */}

            <td>
              <button
                className="dataTable"
                onClick={(e) =>
                  this.addContactProfile(
                    response.contact_name,
                    response.phone,
                    response.gender,
                    response.tags,
                    response.subscribed,
                    response.contact_time,
                    response.id
                  )
                }
              >
                <span>{response.contact_name}</span>
              </button>
            </td>

            <td>{response.phone}</td>
            <td>{response.gender}</td>
            <td>
              {response.tags == "" ? (
                "None"
              ) : (
                <div>
                  {tags}
                  <button
                    className="dataTable"
                    onClick={(e) =>
                      this.addContactProfile(
                        response.contact_name,
                        response.phone,
                        response.gender,
                        response.tags,
                        response.subscribed,
                        response.contact_time,
                        response.id
                      )
                    }
                  >
                    {tagCount > 0 ? "+" + tagCount + " others" : ""}
                  </button>
                </div>
              )}{" "}
            </td>
            <td>{response.assigned}</td>
            <td>{response.subscribed}</td>
            <td>{response.contact_time}</td>
            <td> <a className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.editNewContact( response.id, response.first_name, response.last_name, response.phone, response.email, response.gender, response.country, response.city, response.tags_2)}><Edit /></a></td>
            <td><a className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.deleteContact(response.id)}><TrashCan /></a></td>
          </tr>
        )
      )
    );
    return result;
  };

  editNewContact = (
    id,
    first_name,
    last_name,
    phone,
    email,
    gender,
    country,
    city,
    tags
  ) => {
    $(".editOptions").hide();
    $("#EditNewContact").addClass("uk-flex uk-open");
    $("#firstNameEdit").val(first_name);
    $("#lastNameEdit").val(last_name);
    $("#phoneEdit").val(phone);
    $("#emailEdit").val(email);
    $("#genderEdit").val(gender);
    $("#countryEdit").val(country);
    $("#cityEdit").val(city);
    $("#contact_id").val(id);
    tags = tags.filter(function (element) {
      return element.name !== "";
    });
    this.setState({
      old_tags: tags,
    });
  };
  closeEditContactModal = () => {
    $("#EditNewContact").removeClass("uk-flex uk-open");
  };
  addNewContact = () => {
    $("#AddNewContact").addClass("uk-flex uk-open");
  };
  closeContactModal = () => {
    $("#AddNewContact").removeClass("uk-flex uk-open");
  };

  closeContactProfileModal = () => {
    $("#tags").empty();
    $("#ContactProfile").removeClass("uk-flex uk-open");
  };

  createContact = () => {
    $(".AddTeamBtn").text("Processing");
    $(".AddTeamBtn").prop("disabled", true);
    let phoneCount = $("#phone").val();
    if (phoneCount.length == 10) {
      $("#createContact").text("Processing");
      $("#createContact").prop("disabled", true);
      let classRef = this;
      var data = {};
      var tags = "";
      tags = Object.keys(classRef.state.tags)
        .map(function (k) {
          return classRef.state.tags[k].name;
        })
        .join(",");
      data = {
        first_name: $("#firstName").val(),
        last_name: $("#lastName").val(),
        code: $("#country_code").val(),
        phone: $("#phone").val(),
        email: $("#email").val(),
        gender: $("#gender").val(),
        country: $("#country").val(),
        city: $("#city").val(),
        tags: tags,
        company_id: localStorage.getItem('company_id'),
      };
      axios({
        method: "POST",
        url: Constant.url + "add-contact",
        data: data,
      })
        .then(function (response) {
          if (response.data.code === 200) {
            $("#successAlert").append(
              '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
                response.data.message +
                "</p></div>"
            );
            window.location.href = "/contacts";
          } else {
            $("#errorAlert").append(
              '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
                response.data.data +
                "</p></div>"
            );
            $("#createContact").text("Create");
            $("#createContact").prop("disabled", false);
            setTimeout(function () {
              UIkit.alert("#msg-dialog").close();
            }, 500);
            return;
          }
        })
        .then(function (response) {});
      $("#createContact").text("Processing");
      $("#createContact").prop("disabled", true);
    } else {
      $("#errorAlert").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Phone no must be 10 digits.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }

    document.getElementById("AddNewContactField").reset();
  };

  editContact = () => {
    $(".EditTeamBtn").text("Processing");
    $(".EditTeamBtn").prop("disabled", true);

    let classRef = this;
    var data = {};
    var id = $("#contact_id").val();
    var tags = "";
    tags = Object.keys(classRef.state.tags)
      .map(function (k) {
        return classRef.state.tags[k].name;
      })
      .join(",");
    data = {
      full_name: $("#firstNameEdit").val(),
      last_name: $("#lastNameEdit").val(),
      code: $("#country_codeEdit").val(),
      email: $("#emailEdit").val(),
      gender: $("#genderEdit").val(),
      company_id: localStorage.getItem("company_id"),
      country: $("#countryEdit").val(),
      city: $("#cityEdit").val(),
      tags: tags,
    };
    axios({
      method: "POST",
      url: Constant.url + "edit-contact/" + id,
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlertEdit").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.href = "/contacts";
        } else {
          $("#errorAlertEdit").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $(".EditTeamBtn").text("Edit");
          $(".EditTeamBtn").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  searchnum = () => {
    this.hideAllFilter();
    let classRef = this;
    var name = $("#filterName").val();
    var email = $("#filterEmail").val();
    var date = $("#filterDate").val();
    var city = $("#filterCity").val();
    var country = $("#filterCountry").val();
    var region = $("#filterRegion").val();
    var gender = $("#filterGender").val();
    var username = $("#filterUsername").val();
    var searchword = $("#searchword").val();
    classRef.setState({
      dataList: {
        isLoaded: false,
      },
    });
    axios({
      method: "POST",
      url: Constant.url + "contacts",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
        name: name,
        email: email,
        date: date,
        city: city,
        country: country,
        region: region,
        gender: gender,
        username: username,
      },
    }).then(function (result) {
      // console.log("checkinh");

      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  openFilter = (seletorID) => {
    this.hideAllFilter();
    $("#" + seletorID).show();
    $("#" + seletorID).addClass("uk-dropdown uk-open");
  };

  hideFilter = (seletorID) => {
    $("#" + seletorID).removeClass("uk-dropdown uk-open");
    $("#" + seletorID).hide();
  };

  hideAllFilter = () => {
    $("#nameDrop").hide();
    $("#emailDrop").hide();
    $("#dateDrop").hide();
    $("#cityDrop").hide();
    $("#countryDrop").hide();
    $("#regionDrop").hide();
    $("#genderDrop").hide();
    $("#usernameDrop").hide();
    $("#nameDrop").removeClass("uk-dropdown uk-open");
    $("#emailDrop").removeClass("uk-dropdown uk-open");
    $("#dateDrop").removeClass("uk-dropdown uk-open");
    $("#cityDrop").removeClass("uk-dropdown uk-open");
    $("#countryDrop").removeClass("uk-dropdown uk-open");
    $("#regionDrop").removeClass("uk-dropdown uk-open");
    $("#genderDrop").removeClass("uk-dropdown uk-open");
    $("#usernameDrop").removeClass("uk-dropdown uk-open");
  };

  deleteContact = (id) => {
    $("#editOptions").hide();
    $("#contact_" + id).hide();
    let classRef = this;
    id = [id];
    axios({
      method: "POST",
      url: Constant.url + "delete-profile",
      data: {
        company_id: localStorage.getItem('company_id'),
        contact_id: id,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        //  window.location.reload();
      }
    });
  };

  deleteMultiContact = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#contact_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;

    axios({
      method: "POST",
      url: Constant.url + "delete-profile",
      data: {
        company_id: localStorage.getItem('company_id'),
        contact_id: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        //  window.location.reload();
      }
    });
  };

  showSegmentPopup = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        ids[i] = $(this).val();
      }
    });
    ids = ids.filter(function (element) {
      return element !== "on";
    });
    if (ids.length > 0) {
      $("#select_dropdown").hide();
      $("#AddSegmentPopup").addClass("uk-flex uk-open");
    } else {
      $("#errorAlertmain").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please select contact first.</p></div>'
      );
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  addSegmentContact = () => {
    $("#Addsegments").text("Processing");
    $("#Addsegments").prop("disabled", true);
    if ($("#segment_title").val() != "") {
      var ids = [];
      $("input[type=checkbox]").each(function (i) {
        if ($(this).is(":checked")) {
          ids[i] = $(this).val();
        }
      });
      ids = ids.filter(function (element) {
        return element !== "on";
      });
      let classRef = this;
      let company_id = localStorage.getItem("company_id");
      let segment_name = $("#segment_title").val();
      axios({
        method: "POST",
        url: Constant.url + "create-segment-by-select-contacts",
        data: {
          company_id: company_id,
          contact_id: ids,
          segment_name: segment_name,
        },
      }).then(function (result) {
        if (result.data.status === true) {
          $("#successAlertSegment").append(
            '<div id="msg-dialog1" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              result.data.message +
              "</p></div>"
          );
          $("#Addsegments").text("Save");
          $("#Addsegments").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog1").close();
            $("#AddSegmentPopup").removeClass("uk-flex uk-open");
          }, 1000);
        }
      });
    } else {
      $("#errorAlertSegment").append(
        '<div id="msg-dialog1" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>Please enter segment title.</p></div>'
      );
      $("#Addsegments").text("Save");
      $("#Addsegments").prop("disabled", false);
      setTimeout(function () {
        UIkit.alert("#msg-dialog1").close();
      }, 1000);
    }
  };

  selectCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  advanceSearch = () => {
    let classRef = this;
    var data = {};
    var tags = "";
    tags = Object.keys(classRef.state.tags)
      .map(function (k) {
        return classRef.state.tags[k].name;
      })
      .join(",");
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      country: $("#filtercountry").val(),
      city: $("#filtercity").val(),
      gender: $("#filtergender").val(),
      agent: $("#filteragents").val(),
      tags: tags,
      company_id: localStorage.getItem("company_id"),
    };

    axios({
      method: "POST",
      url: Constant.url + "contacts",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  render() {
    return (
      <Content>
        <div
          className="contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className="uk-grid" uk-grid="">
              <div className="uk-width-auto">
                <h3>Contacts</h3>
              </div>

              {/* <div className="chipBox CustomChipBox">
                            <Multiselect
                            options={objectArray}
                            singleSelect
                            displayValue="key"
                            />
                            </div> */}

              <div className="uk-width-1-3">
                <div className="search_field">
                  <form>
                    <input
                      className="uk-input"
                      onChange={this.searchnum}
                      name="searchword"
                      id="searchword"
                      type="text"
                      placeholder="Search contacts through phone"
                    />
                  </form>
                  <div class="CampaignReports Filtermain">
                    <div className="uk-width-auto">
                      <button onClick={this.addSearchModal}>
                        <img src="/images/newfilter.svg" uk-svg />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* filter start */}
              <div class="Filtermain">
                <div className="uk-width-auto">
                  <button>
                    <Search onClick={this.searchnum} />
                  </button>
                  <button class="" onClick={this.hideAllFilter} type="button">
                    <Filter />
                    <span>Filter</span>
                  </button>

                  <div id="mainModal" uk-dropdown="mode: click;">
                    <div class="uk-margin-small">
                      <div class="uk-inline">
                        {/* <span class="uk-form-icon" uk-icon="icon: user"></span> */}
                        <input
                          class="uk-input"
                          type="text"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                    <ul className="uk-nav uk-dropdown-nav">
                      <li class="">
                        <button
                          class="uk-button"
                          type="button"
                          onClick={(e) => {
                            this.openFilter("nameDrop");
                          }}
                        >
                          <span>Name</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="uk-button"
                          onClick={(e) => {
                            this.openFilter("emailDrop");
                          }}
                          type="button"
                        >
                          <span>Email</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="uk-button"
                          onClick={(e) => {
                            this.openFilter("dateDrop");
                          }}
                        >
                          <span>Date</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="uk-button"
                          onClick={(e) => {
                            this.openFilter("cityDrop");
                          }}
                        >
                          <span>City</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="uk-button"
                          onClick={(e) => {
                            this.openFilter("countryDrop");
                          }}
                        >
                          <span>Country</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="uk-button"
                          onClick={(e) => {
                            this.openFilter("regionDrop");
                          }}
                        >
                          <span>Region</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="uk-button"
                          onClick={(e) => {
                            this.openFilter("genderDrop");
                          }}
                        >
                          <span>Gender</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="uk-button"
                          onClick={(e) => {
                            this.openFilter("usernameDrop");
                          }}
                        >
                          <span>Username</span>
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div id="nameDrop" style={{ display: "none" }}>
                    <p>
                      <b>Name</b>
                    </p>
                    <ul className="uk-nav uk-dropdown-nav customNavDrop">
                      <li>
                        <label>
                          <input
                            class="uk-radio"
                            type="radio"
                            name="sameradio"
                          />
                          Is
                        </label>
                        <input type="text" id="filterName" />
                      </li>
                    </ul>
                  </div>

                  <div id="emailDrop" style={{ display: "none" }}>
                    <p>
                      <b>Email</b>
                    </p>
                    <ul className="uk-nav uk-dropdown-nav customNavDrop ">
                      <li>
                        <label>
                          <input
                            class="uk-radio"
                            type="radio"
                            name="sameradio"
                          />
                          Is
                        </label>
                        <input type="email" id="filterEmail" />
                      </li>
                    </ul>
                  </div>

                  <div id="dateDrop" style={{ display: "none" }}>
                    <p>
                      <b>Date</b>
                    </p>
                    <ul className="uk-nav uk-dropdown-nav customNavDrop">
                      <li>
                        <label>
                          <input
                            class="uk-radio"
                            type="radio"
                            name="sameradio"
                          />
                          Is
                        </label>
                        <input type="date" id="filterDate" />
                      </li>
                    </ul>
                  </div>

                  <div id="cityDrop" style={{ display: "none" }}>
                    <p>
                      <b>City</b>
                    </p>
                    <ul className="uk-nav uk-dropdown-nav customNavDrop">
                      <li>
                        <label>
                          <input
                            class="uk-radio"
                            type="radio"
                            name="sameradio"
                          />
                          Is
                        </label>
                        <input type="text" id="filterCity" />
                      </li>
                    </ul>
                  </div>

                  <div id="countryDrop" style={{ display: "none" }}>
                    <p>
                      <b>Country</b>
                    </p>
                    <ul className="uk-nav uk-dropdown-nav customNavDrop">
                      <li>
                        <label>
                          <input
                            class="uk-radio"
                            type="radio"
                            name="sameradio"
                          />
                          Is
                        </label>
                        <input type="text" id="filterCountry" />
                      </li>
                    </ul>
                  </div>

                  <div id="regionDrop" style={{ display: "none" }}>
                    <p>
                      <b>Region</b>
                    </p>
                    <ul className="uk-nav uk-dropdown-nav customNavDrop">
                      <li>
                        <label>
                          <input
                            class="uk-radio"
                            type="radio"
                            name="sameradio"
                          />
                          Is
                        </label>
                        <input type="text" id="filterRegion" />
                      </li>
                    </ul>
                  </div>

                  <div id="genderDrop" style={{ display: "none" }}>
                    <p>
                      <b>Gender</b>
                    </p>
                    <ul className="uk-nav uk-dropdown-nav customNavDrop">
                      <li>
                        <label>
                          <input
                            class="uk-radio"
                            type="radio"
                            name="sameradio"
                          />
                          Is
                        </label>
                        {/* <input type="text" id="filterGender"/> */}
                        <select>
                          <option value="male">male</option>
                          <option value="female">female</option>
                        </select>
                      </li>
                    </ul>
                  </div>

                  <div id="usernameDrop" style={{ display: "none" }}>
                    <p>
                      <b>Username</b>
                    </p>
                    <ul className="uk-nav uk-dropdown-nav customNavDrop">
                      <li>
                        <label>
                          <input
                            class="uk-radio"
                            type="radio"
                            name="sameradio"
                          />
                          Is
                        </label>
                        <input type="text" id="filterUsername" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="uk-width-auto">
                                <Link to="javascript:;"><Tag /><span>Segments</span></Link>
                            </div>
                            <div className="uk-width-auto">
                                <Link to="javascript:;"><Tag /><span>Tags</span></Link>
                            </div> */}

              <div className="uk-width-expand">
                <button
                  class="newFlowBtn uk-float-right"
                  onClick={this.addNewContact}
                >
                  <Add /> <span>Add New Contact</span>
                </button>
              </div>
            </div>
          </div>
          <div id="errorAlertmain"></div>
          <div className="tableSec">
            <div className="uk-overflow-auto">
              <table className="uk-table uk-table-small uk-table-divider ContactCustomTableWidth uk-table-middle">
                <thead>
                  <tr>
                    <th>
                      <label>
                        <input
                          className="uk-checkbox "
                          type="checkbox"
                          id="select_all"
                          onClick={this.selectCheckbox}
                        />
                      </label>
                      <div className="uk-inline drpIconBox">
                        <button className="iconTble" type="button">
                          <CaretDown />
                        </button>
                        <div uk-dropdown="mode: click" id="select_dropdown">
                          <ul className="uk-nav uk-dropdown-nav">
                            <li>
                              <a onClick={this.showSegmentPopup}>
                                Add Segments
                              </a>
                            </li>
                            <li>
                              <a onClick={this.deleteMultiContact}>
                                Delete Selected Rows
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </th>
                    <th>AVATAR</th>
                    <th>CONTACT NAME</th>
                    <th>PHONE</th>
                    <th>GENDER</th>
                    <th>TAGS</th>
                    <th>ASSIGNED AGENT</th>

                    {/* <th>
                                            <div className="uk-inline drpDownBtnBox">
                                                <a href="#">ASSIGNED <CaretDown /></a>
                                                <div uk-dropdown="mode: click">
                                                    <ul className="uk-nav uk-dropdown-nav">
                                                        <li className="active">
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button>
                                                                <div className="drpDwnList">
                                                                    <img src="/images/avatar.jpg" alt="Image" />
                                                                    <span>Usman Ramnani</span>
                                                                    <Checkmark />
                                                                </div>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="uk-inline drpIconBox">
                                                <a href="#">STATUS <CaretDown /></a>
                                                <div uk-dropdown="mode: click">
                                                    <ul className="uk-nav uk-dropdown-nav">
                                                        <li><a href="#">All</a></li>
                                                        <li><a href="#">Open</a></li>
                                                        <li><a href="#">In Progress</a></li>
                                                        <li><a href="#">Need Details</a></li>
                                                        <li><a href="#">Researching</a></li>
                                                        <li><a href="#">Resolved</a></li>
                                                        <li><a href="#">Expired</a></li>
                                                        <li><a href="#">Closed</a></li>
                                                        <li><a href="#">Canceled</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </th> */}
                    <th>SUBSCRIBED</th>
                    <th>CONTACT TIME</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* {(this.state.dataList.isLoaded) ? this.handleTable() : ""} */}
                  {this.state.dataList.isLoaded ? (
                    this.handleTable()
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          <div className="mdl">
                            <div className="mdl_inner">
                              <div uk-spinner=""></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          id="AddNewContact"
          className="AddNewContact  ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Create New Contact</h3>

            <div id="errorAlert"></div>
            <div id="successAlert"></div>

            <p>It is mandatory to fill the Phone number or Email</p>
            <form
              className="uk-form-stacked"
              action="javascript:void(0);"
              id="AddNewContactField"
            >
              <div className="uk-grid uk-child-width-1-2 uk-grid-small">
                <div className="">
                  <label className="uk-form-label" htmlFor="">
                    First Name
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="firstName"
                      name="firstName"
                      placeholder="Enter first name"
                    />
                  </div>
                </div>
                <div className="">
                  <label className="uk-form-label" htmlFor="">
                    Last Name
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="lastName"
                      name="lastName"
                      placeholder="Enter last name"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-4">
                  <label className="uk-form-label " htmlFor="">
                    Phone Number
                  </label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      id="country_code"
                      name="country_code"
                    >
                      <option>+92</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-expand">
                  <label
                    className="uk-form-label uk-margin-top"
                    htmlFor=""
                  ></label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="phone"
                      name="phone"
                      type="number"
                      placeholder="Enter phone number"
                    />
                  </div>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Gender
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="gender" name="gender">
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Country
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="country"
                    name="country"
                    onChange={this.countryChange}
                  >
                    <option value="">Select Country</option>
                    {this.state.country.isLoaded ? this.handleCountry() : ""}
                    {/* <option value="Pakistan">Pakistan</option> */}
                  </select>
                  {/* <input className="uk-input" id="country" name="country" type="country" placeholder="Country" /> */}
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  City
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="city" name="city">
                    <option value="">Select City</option>
                    {this.state.city.isLoaded ? this.handleCity() : ""}
                    {/* <option value="Pakistan">Pakistan</option> */}
                  </select>
                  {/* <input className="uk-input" id="city" name="city" type="city" placeholder="City" /> */}
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Tags
                </label>
                <div className="uk-form-controls">
                  <div className="chipBox">
                    <div className="chipBox CustomChipBox">
                      <Multiselect
                        id="saj"
                        options={this.state.tagList.list}
                        selectedValues={this.state.tags}
                        onSelect={this.onSelectTags}
                        onRemove={this.onRemoveTags}
                        displayValue="name"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="uk-margin">
                                <label>
                                <input className="uk-checkbox" id="invitation" name="invitation" type="checkbox" checked /> I confirm that we have obtained consent to send message, email from contact being created with regulations and Telenor <Link to="javascript"> Terms of Service</Link>
                                </label>
                                
                            </div> */}
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeContactModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="createContact"
                    onClick={this.createContact}
                  >
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="EditNewContact"
          className="EditNewContact  ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit Contact</h3>

            <div id="errorAlertEdit"></div>
            <div id="successAlertEdit"></div>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-grid uk-child-width-1-2 uk-grid-small">
                <div className="">
                  <label className="uk-form-label" htmlFor="">
                    First Name
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="firstNameEdit"
                      name="firstName"
                      placeholder="Enter first name"
                    />
                    <input
                      type="hidden"
                      className="uk-input"
                      id="contact_id"
                      name="contact_id"
                    />
                  </div>
                </div>
                <div className="">
                  <label className="uk-form-label" htmlFor="">
                    Last Name
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="lastNameEdit"
                      name="lastName"
                      placeholder="Enter last name"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-4" style={{ display: "none" }}>
                  <label className="uk-form-label " htmlFor="">
                    Phone Number
                  </label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select"
                      id="country_codeEdit"
                      name="country_code"
                    >
                      <option>+92</option>
                    </select>
                  </div>
                </div>
                <div className="uk-width-expand">
                  <label
                    className="uk-form-label uk-margin-top"
                    htmlFor=""
                  ></label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      id="phoneEdit"
                      name="phone"
                      type="number"
                      placeholder="Enter phone number"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Email
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="emailEdit"
                    name="email"
                    type="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Gender
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="genderEdit" name="gender">
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </div>

              {/* <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="">Country</label>
                                <div className="uk-form-controls">
                                <input className="uk-input" id="countryEdit" name="countryEdit" type="countryEdit" placeholder="Country" />
                                </div>
                            </div>
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="">City</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="cityEdit" name="cityEdit" type="cityEdit" placeholder="City" />
                                </div>
                            </div> */}
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Country
                </label>
                <div className="uk-form-controls">
                  <select
                    className="uk-select"
                    id="countryEdit"
                    name="countryEdit"
                    onChange={this.countryChange}
                  >
                    <option value="">Select Country</option>
                    {this.state.country.isLoaded ? this.handleCountry() : ""}
                    {/* <option value="Pakistan">Pakistan</option> */}
                  </select>
                  {/* <input className="uk-input" id="country" name="country" type="country" placeholder="Country" /> */}
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  City
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="cityEdit" name="cityEdit">
                    <option value="">Select City</option>
                    {this.state.city.isLoaded ? this.handleCity() : ""}
                    {/* <option value="Pakistan">Pakistan</option> */}
                  </select>
                  {/* <input className="uk-input" id="city" name="city" type="city" placeholder="City" /> */}
                </div>
              </div>
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="">
                  Tags
                </label>
                <div className="uk-form-controls">
                  <div className="chipBox">
                    <div className="chipBox CustomChipBox">
                      <Multiselect
                        id="saj"
                        options={this.state.tagList.list}
                        selectedValues={this.state.old_tags}
                        onSelect={this.onSelectTags}
                        onRemove={this.onRemoveTags}
                        displayValue="name"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="uk-margin">
                                <label>
                                <input className="uk-checkbox" id="invitationEdit" name="invitation" type="checkbox" checked /> I confirm that we have obtained consent to send message, email from contact being created with regulations and Telenor <Link to="javascript"> Terms of Service</Link>
                                </label>
                                
                            </div> */}
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeEditContactModal}
                  >
                    Cancel
                  </button>
                  <button className="AddTeamBtn" onClick={this.editContact}>
                    Edit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* popup contact start */}

        <div
          id="ContactProfile"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
              className="uk-modal-close-default"
              onClick={this.closeContactProfileModal}
              type="button"
            >
              {" "}
              <Close />
            </button>
            <h3>
              {this.state.popUpName}
              {/* <div className="uk-inline SegmentAction uk-float-right">
                            <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                            <div uk-dropdown="mode: click;pos:top-right">
                                <ul className="uk-nav uk-dropdown-nav">
                                    <li><a href="#"><Edit />Edit</a></li>
                                    <li><a href="#"><StopOutline />Unsubscribe</a></li>
                                    <li><a href="#"><Download />Download Data</a></li>
                                    <hr/>
                                    <li class="hintAlert"><a href="#"><TrashCan />Delete</a></li>
                                </ul>
                            </div>
                        </div> */}
            </h3>
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-1-2">
                <div className="ProfileLeft">
                  <div className="profile_pic">
                    <img
                      src="/images/placeholder.jpg"
                      alt=""
                      id="profile_pic"
                    />
                  </div>
                  <div className="contact_details">
                    <ul>
                      <li>
                        <button>
                          {" "}
                          <span>
                            <MobileCheck />
                          </span>{" "}
                          <span id="mobile_no">{this.state.popUpNumber}</span>
                        </button>
                      </li>
                      <li>
                        <button>
                          {" "}
                          <span>
                            <CheckmarkOutline />
                          </span>{" "}
                          <span>{this.state.popUpSunscribe}</span>
                        </button>
                      </li>
                      <li>
                        {" "}
                        <span>
                          <GenderFemale />
                        </span>{" "}
                        <span id="gender">{this.state.popUpGender}</span>
                      </li>
                      <li>
                        {" "}
                        <span>
                          <Time />
                        </span>{" "}
                        <span id="time">{this.state.popUpContactTime}</span>
                      </li>
                      <li className="uk-hidden">
                        <button
                          id="user_id"
                          value={this.state.popUpId}
                        ></button>
                        {this.state.popUpId}
                      </li>
                      {/* <li> <span><Credentials /></span> <span>{this.state.popUpContactTime}</span></li> */}
                    </ul>
                    {/* <button class="newFlowBtn"><span>Start Chat</span></button> */}
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2">
                <div className="pause_automation ">
                  <div className="head">
                    <div className="">
                      <h4>
                        Contact Tags{" "}
                        <button
                          className="time uk-float-right"
                          onClick={this.addTag}
                        >
                          <span>
                            <Add />
                          </span>
                          Add Tag
                        </button>
                      </h4>
                    </div>
                  </div>

                  <div className="tags" id="tags">
                    {/* <button>  New-customer <span><Close /></span></button>
                                        <button>  opt-in-for-SMS <span><Close /></span></button>
                                        <button>  user-Registered <span><Close /></span></button>
                                        <button>  Loyalty <span><Close /></span></button>
                                        <button>  Retail <span><Close /></span></button>
                                        <button>  preferred_Email <span><Close /></span></button>
                                        <button>  preferred_Phone <span><Close /></span></button> */}
                  </div>

                  <hr />
                  <div className="CustomHead">
                    <div className="head">
                      <div className="">
                        <h4>Opted in through</h4>
                      </div>
                      <div className="">
                        <div className="tags uk-margin-remove">
                          <button> 👋 Get Started</button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {/* <div className="head">
                                            <div className="">
                                                <h4>Contact Tags <button className="time uk-float-right"><span></span>Manage</button></h4>
                                                <div className="CustomProfileBtn">
                                                    <button>email_address: naveenasim@gamil.com</button>
                                                    <button>phone_number: 033565472945</button>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                  <div
                    id="AddTagsModal"
                    className="AddTeamModal ModalStyle uk-flex-top"
                    uk-modal="esc-close: false; bg-close: false"
                  >
                    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                      <button className="uk-modal-close-default" type="button">
                        {" "}
                        <Close />
                      </button>
                      <h3>Add tag</h3>
                      <div id="errorAlerttag"></div>
                      <div id="successAlerttag"></div>
                      <form
                        id="tagform"
                        className="uk-form-stacked"
                        action="javascript:void(0);"
                      >
                        <div className="uk-margin">
                          <label className="uk-form-label" htmlFor="teamName">
                            Select tags
                          </label>

                          {/* <div className="uk-form-controls">
                                  <Select
                                      defaultValue={[options[0], options[1]]}
                                      name="tags"
                                      options={options}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                </div> */}

                          <div className="chipBox">
                            <div className="chipBox CustomChipBox">
                              <Multiselect
                                options={this.state.tagList.list}
                                selectedValues={this.state.tags}
                                onSelect={this.onSelectTags}
                                onRemove={this.onRemoveTags}
                                displayValue="name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="uk-margin">
                          <div className="ModalStyleBtns">
                            <button
                              className="AddTeamBtn"
                              id="addtagss"
                              onClick={this.attachTag}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* popup  contact end */}

        {/* AddSegmentPopup popup */}
        <div
          id="AddSegmentPopup"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Add Segments</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div id="errorAlertSegment"></div>
            <div id="successAlertSegment"></div>
            <div className="resolved_content">
              <label for="segmenttitle"></label>
              <input
                type="text"
                name="segment_title"
                id="segment_title"
                className="uk-input"
                placeholder="Segment Title"
              />
              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  {/* <button className="CnclBtn" onClick={this.unassignHide}>Cancel</button> */}
                  <button
                    className="AddTeamBtn"
                    id="Addsegments"
                    onClick={this.addSegmentContact}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="to_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="from_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="teamName">
                    Country
                  </label>
                  <div className="uk-form-controls">
                    <select
                      className="uk-select to_date"
                      id="filtercountry"
                      onChange={this.filtercountryChange}
                    >
                      <option value="" disabled selected>
                        Select Country
                      </option>
                      {this.state.country.isLoaded ? this.handleCountry() : ""}
                    </select>
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="teamName">
                    City
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select from_date" id="filtercity">
                      <option value="" disabled selected>
                        Select City
                      </option>
                      {this.state.city.isLoaded ? this.handleCity() : ""}
                    </select>
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="teamName">
                    Gender
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select to_date" id="filtergender">
                      <option value="" disabled selected>
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="teamName">
                    Agents
                  </label>
                  <div className="uk-form-controls">
                    <select className="uk-select from_date" id="filteragents">
                      <option value="" disabled selected>
                        Select Agent
                      </option>
                      {this.state.agentList.isLoaded ? this.handleAgent() : ""}
                    </select>
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Tag
                </label>
                <div className="uk-form-controls">
                  <div className="chipBox">
                    <div className="chipBox CustomChipBox">
                      <Multiselect
                        id="saj"
                        options={this.state.tagList.list}
                        selectedValues={this.state.tags}
                        onSelect={this.onSelectTags}
                        onRemove={this.onRemoveTags}
                        displayValue="name"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}
export default Contacts;
