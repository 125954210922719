import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown
  } from "@carbon/icons-react";
import InsightsLiveChatAnalytics from "./InsightsLiveChatAnalytics";
import LiveChatOverview from "./LiveChatOverview";
import LiveChatAgent from "./LiveChatAgent";
import LiveChatQueries from "./LiveChatQueries";
import LiveChatVOC from "./LiveChatVOC";
import LiveChatChanel from "./LiveChatChanel";
import LiveChatTags from "./LiveChatTags";
import WhatsappChanelAnalytics from "./WhatsappChanelAnalytics";
import WhatsappBroadcast from "./WhatsappBroadcast";
import SmsAnalytics from "./SmsAnalytics";
import SmsBroadcast from "./SmsBroadcast";


  
class SmsMain extends React.Component {

    render() {
        return (
         <>
            <div uk-grid="">
                <div className="uk-width-1-1">
                    <div className="tabsInner">
                        <ul className="tabsBtns" uk-switcher="connect: .InnerMultiTabsWhatsapp">
                            <li>
                                <a href="#">Analytics</a>
                            </li>
                            <li>
                                <a href="#">Broadcast</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="uk-width-1-1">
                    <ul className="uk-switcher InnerMultiTabsWhatsapp">
                        <li><SmsAnalytics/></li>
                        <li><SmsBroadcast/></li>
                    </ul>
                </div>
            </div>
         </>
    );
}
}
export default SmsMain;