import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import {Content} from '@adobe/react-spectrum';
import Constant from "../../../App/Constant";
import axios from 'axios';
import $ from 'jquery';

class OfficeHours extends React.Component {
    constructor() {
        super();
        this.callHourApi = this.callHourApi.bind(this);
        this.handleTable = this.handleTable.bind(this);
        this.callApi = this.callApi.bind(this);
        this.handlemondayfrom = this.handlemondayfrom.bind(this);
        this.handlemondayto = this.handlemondayto.bind(this);
        this.handletuesdayfrom = this.handletuesdayfrom.bind(this);
        this.handletuesdayto = this.handletuesdayto.bind(this);
        this.handlewednesdayfrom = this.handlewednesdayfrom.bind(this);
        this.handlewednesdayto = this.handlewednesdayto.bind(this);
        this.handlethrusdayfrom = this.handlethrusdayfrom.bind(this);
        this.handlethrusdayto = this.handlethrusdayto.bind(this);
        this.handlefridayfrom = this.handlefridayfrom.bind(this);
        this.handlefridayto = this.handlefridayto.bind(this);
        this.handlesaturdayfrom = this.handlesaturdayfrom.bind(this);
        this.handlesaturdayto = this.handlesaturdayto.bind(this);
        this.handlesundayfrom = this.handlesundayfrom.bind(this);
        this.handlesundayto = this.handlesundayto.bind(this);
        this.handleoffday = this.handleoffday.bind(this);
        this.handleoffhour = this.handleoffhour.bind(this);

        this.state = {
          dataList: {
            isLoaded: false,
            msg: 'Loading...',
            list: []
          },
            mondayfrom:"",
            mondayto:"",
            tuesdayfrom:"",
            tuesdayto:"",
            wednesdayfrom:"",
            wednesdayto:"",
            thrusdayfrom:"",
            thrusdayto:"",
            fridayfrom:"",
            fridayto:"",
            saturdayfrom:"",
            saturdayto:"",
            sundayfrom:"",
            sundayto:"",
            offday:"",
            offhour:"",
          
        }
      }


      componentDidMount() {
        this.callHourApi();
        this.callApi();
      }


      callHourApi = () => {
        let classRef = this;
        axios({
          method: 'GET',
          url: Constant.url + "hours",
        }).then(function (result) {
          if (result.data.status === true) {
            classRef.setState({
              dataList: {
                isLoaded: true,
                msg: 'Loading...',
                list: result.data.data
              },
            })
          }
        })
      }


      handleTable = () => {
        let result = "";
        let index = 0;
        result = this.state.dataList.list.map((response, index) => (   
          <option value={response.value}>{response.title}</option>
        ));
        return result;
      }


      callApi = () => {
        let classRef = this;
        axios({
          method: 'POST',
          url: Constant.url + "officehours",
          data: { company_id: localStorage.getItem('company_id')}
        }).then(function (result) {

          if (result.data.status === true) {
            var office = result.data.data
            classRef.setState({
                mondayfrom: office.monday_from,
                mondayto:office.monday_to,
                tuesdayfrom:office.tuesday_from,
                tuesdayto:office.tuesday_to,
                wednesdayfrom:office.wednesday_from,
                wednesdayto:office.wednesday_to,
                thrusdayfrom:office.thursday_from,
                thrusdayto:office.thursday_to,
                fridayfrom:office.friday_from,
                fridayto:office.friday_to,
                saturdayfrom:office.saturday_from,
                saturdayto:office.saturday_to,
                sundayfrom:office.sunday_from,
                sundayto:office.sunday_to,
                offday:office.off_day_msg,
                offhour:office.closed_hours_msg
              })

            

          }
        })
      }



      addOfficeHours = () =>{  
        let classRef = this;
        var data = {};
        data = {
        monday : {from: $("#mondayfrom").val(),to: $("#mondayto").val()},
        tuesday : {from: $("#tuesdayfrom").val(),to: $("#tuesdayto").val()},
        wednesday : {from: $("#wednesdayfrom").val(),to: $("#wednesdayto").val()},
        thursday : {from: $("#thrusdayfrom").val(),to: $("#thrusdayto").val()},
        friday : {from: $("#fridayfrom").val(),to: $("#fridayto").val()},
        saturday : {from: $("#saturdayfrom").val(),to: $("#saturdayto").val()},
        sunday : {from: $("#sundayfrom").val(),to: $("#sundayto").val()},
        off_day_msg: $("#offday").val(),
        closed_hours_msg: $("#offhour").val(),
        company_id: localStorage.getItem('company_id') 
        }

        axios({
            method: 'POST',
            url: Constant.url + "set-officehours",
            data: data
          }).then(function (result) {

            if (result.data.code === 200) {
    
                $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.message+'</p></div>');
        
                window.location.href = "/settings";
              }else{
        
                $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+result.data.data+'</p></div>');
                $('#editcompany').text('Save')
                $('#editcompany').prop('disabled', false);
                setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
                return;
            
        
              }
          
        });

        // console.log(data,'hafeeez')


      }


      
handlemondayfrom(event){
    this.setState({ mondayfrom: event.target.mondayfrom });
}

handlemondayto(event){
    this.setState({ mondayto: event.target.mondayto });
}

handletuesdayfrom(event){
    this.setState({ tuesdayfrom: event.target.tuesdayfrom });
}

handletuesdayto(event){
    this.setState({ tuesdayto: event.target.tuesdayto });
}

handlewednesdayfrom(event){
    this.setState({ wednesdayfrom: event.target.wednesdayfrom });
}

handlewednesdayto(event){
    this.setState({ wednesdayto: event.target.wednesdayto });
}

handlethrusdayfrom(event){
    this.setState({ thrusdayfrom: event.target.thrusdayfrom });
}

handlethrusdayto(event){
    this.setState({ thrusdayto: event.target.thrusdayto });
}

handlefridayfrom(event){
    this.setState({ fridayfrom: event.target.fridayfrom });
}

handlefridayto(event){
    this.setState({ fridayto: event.target.fridayto });
}


handlesaturdayfrom(event){
    this.setState({ saturdayfrom: event.target.saturdayfrom });
}

handlesaturdayto(event){
    this.setState({ saturdayto: event.target.saturdayto });
}


handlesundayfrom(event){
    this.setState({ sundayfrom: event.target.sundayfrom });
}

handlesundayto(event){
    this.setState({ sundayto: event.target.sundayto });
}

handleoffday(event){
    this.setState({ offday: event.target.offday });
}

handleoffhour(event){
    this.setState({ offhour: event.target.offhour });
}




    
    

    render() {

        return (
            
            <Content>
                <div
          className="contactContainer adminContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
                <div className=" uk-grid " uk-grid="">
                    <div className="uk-width-auto"><h3>Office Working Hours</h3></div>
                </div>
            </div>
          <div className=" CustomBgAdmin">
            <div className="integration inner">
            <form className="uk-form-stacked" action="javascript:void(0);">

                <div id="errorAlert"></div>
                <div id="successAlert"></div>

                <div className="MainFormsection">    
                    <div className="uk-grid  uk-grid-small" uk-grid="">
                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Monday">Monday</label>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="mondayfrom" value={this.state.mondayfrom} onChange={this.handlemondayfrom}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>
                            
                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="mondayto" value={this.state.mondayto} onChange={this.handlemondayto}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Tuesday">Tuesday</label>
                        </div>

                        <div className="uk-width-1-3@m">
                        <select className="uk-select" id="tuesdayfrom" value={this.state.tuesdayfrom} onChange={this.handletuesdayfrom}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="tuesdayto" value={this.state.tuesdayto} onChange={this.handletuesdayto}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Wednesday">Wednesday</label>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="wednesdayfrom" value={this.state.wednesdayfrom} onChange={this.handlewednesdayfrom}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="wednesdayto" value={this.state.wednesdayto} onChange={this.handlewednesdayto}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Thrusday">Thrusday</label>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="thrusdayfrom" value={this.state.thrusdayfrom} onChange={this.handlethrusdayfrom}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="thrusdayto" value={this.state.thrusdayto} onChange={this.handlethrusdayto}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Friday">Friday</label>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="fridayfrom"  value={this.state.fridayfrom} onChange={this.handlefridayfrom}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="fridayto"  value={this.state.fridayto} onChange={this.handlefridayto}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Saturday">Saturday</label>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="saturdayfrom" value={this.state.saturdayfrom} onChange={this.handlesaturdayfrom}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="saturdayto" value={this.state.saturdayto} onChange={this.handlesaturdayto}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Sunday">Sunday</label>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="sundayfrom" value={this.state.sundayfrom}  onChange={this.handlesundayfrom}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>

                        <div className="uk-width-1-3@m">
                            <select className="uk-select" id="sundayto" value={this.state.sundayto} onChange={this.handlesundayto}>
                            {(this.state.dataList.isLoaded) ? this.handleTable() : ""}
                            </select>
                        </div>


                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Sunday" >Off Day Message</label>
                        </div>

                        <div className="uk-width-2-3@m">
                            <textarea class="uk-textarea" rows="5" placeholder="Textarea" id="offday" value={this.state.offday} onChange={this.handleoffday}></textarea>
                        </div>

                        <div className="uk-width-1-3@m label">
                            <label className="uk-form-label" htmlFor="Sunday">Off Hour Message</label>
                        </div>

                        <div className="uk-width-2-3@m">
                            <textarea class="uk-textarea" rows="5" placeholder="Textarea" id="offhour" value={this.state.offhour} onChange={this.handleoffhour}></textarea>
                        </div>

                        <div className="uk-width-1-1 uk-margin">
                            <div className="ModalStyleBtns dayFormBtn">
                                <button className="AddTeamBtn" id="editcompany" onClick={this.addOfficeHours} >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
            
            </div>
            </div>
                
                      
            </Content>
        )
    }
}
export default OfficeHours;