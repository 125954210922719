import * as React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../Assets/css/style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
  legend: {
  position: 'top',
  },
  title: {
  display: true,
  text: 'Chart.js Line Chart',
  },
  },
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

      export const data = {
        labels,
        datasets: [
          {
            fill: true,
            label: 'Dataset 2',
            data:  ['45', '35', '6', '46', '55', '60', '85'],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };



      export const AreaChart = () => {
        return <Line options={options} data={data}/>;
      }




// export const Barchart = () => {

  

    
    // <div>
    //         <Bar
    // data={{    
    //         labels: ["january", "february", "march", "april", "may", "june", "july"],
    //         datasets: [
    //             {
    //             label: 'Dataset 1',
    //             data: ["-45", "-35", "6", "46", "-55", "-60", "-85"],
    //             borderColor: 'rgba(0, 82, 204, 1)',
    //             backgroundColor:  'rgba(0, 82, 204, .5)',
    //             },
    //             {
    //             label: 'Dataset 2',
    //             data: ["45", "-28", "8", "40", "-35", "-75", "-39"],
    //             borderColor: 'rgba(70, 255, 90, .5)',
    //             backgroundColor: 'rgba(70, 255, 90, 1)',
    //             }
    //             ]
    // }}
    // height={400}
    // width={600}
    // options={options = {
    //     indexAxis: 'y' as const,
    //     elements: {
    //       bar: {
    //         borderWidth: 2,
    //       },
    //     },
    //     responsive: true,
    //     plugins: {
    //       legend: {
    //         position: 'right' as const,
    //       },
    //       title: {
    //         display: true,
    //         text: 'Chart.js Horizontal Bar Chart',
    //       },
    //     },
    //   }}

    // />

    // </div>





    
  
// }


