import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { BorderBottom } from '@carbon/icons-react';

ChartJS.register(ArcElement, Tooltip, Legend);

export const WhatsappMonthlyActiveChart = () => {

     const data = {
        labels: ['Used', 'Tire limit'],
        datasets: [
          {
            label: 'All Live Chats',
            data: [70, 20],
            backgroundColor: [
                'rgb(31, 115, 183)',
                'rgb(150, 204, 249)',
              
              
            ],
            borderColor: [
                'rgb(31, 115, 183)',
                'rgb(150, 204, 249)',
            ],
            borderWidth: 1,
            cutout:'85%',
            circumference:180,
            rotation:270,
          }
        ]
        
      };
      const options = {
        aspectRatio: 1.5,
        plugins : {
        legend: {
          display: true,
          position: 'bottom',
          labels:{
            // boxWidth: 10,
            boxHeight: 4,
            usePointStyle:true,
            pointStyle: 'circle',
            pointStyleWidth	:5,
            pointStyleHeight: 5,
            
          }
        },
        tooltip:{
            enabled: false
        }
        }
      };

      const textMeter = [{
        id: 'textMeter',
        beforeDraw: function(chart) {
            var width = chart.width,
            height = chart.height,
            left = chart.left,
            ctx = chart.ctx;
            ctx.restore();
            // var fontSize = 25;
            // ctx.font = fontSize + "px sans-serif";
            
            var text = "11/38";
            var text1 = "Open Live Chats",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2.5;
            ctx.font = '36px san-serif';
            ctx.textBaseline = "top";
            ctx.fillStyle = 'rgb(75, 75, 75)';
            ctx.fillText('26,251', Math.round((width - ctx.measureText(text).width) / 2)-10, textY+35);
        }
      }]
  return (
    <div>
         <Doughnut data={data} options={options}  plugins={textMeter} /> 
    </div>
  )
}
