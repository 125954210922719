import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import {
  Checkmark,
  CaretDown,
  Add,
  OverflowMenuVertical,
} from "@carbon/icons-react";
import Constant from "../../../App/Constant";
import Teams from "../Team/Teams";
import Automation from "../Automation/Automation";
import Broadcasting from "../Broadcasting/Broadcasting";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
class Reports extends React.Component {
  constructor() {
    super();
    this.automationcallApi = this.automationcallApi.bind(this);
    this.agentCallApi = this.agentCallApi.bind(this);
    this.handleAutomationTable = this.handleAutomationTable.bind(this);
    this.handleAgentTable = this.handleAgentTable.bind(this);
    this.broadcastCallApi = this.broadcastCallApi.bind(this);
    this.handleBroadcastTable = this.handleBroadcastTable.bind(this);
    this.state = {
      automationList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      broadcastList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
    };
  }

  broadcastCallApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "broadcasts",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          broadcastList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data.list,
          },
        });
      }
    });
  };

  agentCallApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "agents",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  activateflow = (id) => {
    let classRef = this;
    var flow_id = id;
    axios({
      method: "POST",
      url: Constant.url + "activate-deactive-flow",
      data: {
        company_id: localStorage.getItem("company_id"),
        status: "active",
        flow_id: flow_id,
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/automation";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/automation";

        // window.location.href = "/team";
      }
    });
  };

  componentDidMount() {
    this.automationcallApi();
    this.agentCallApi();
    this.broadcastCallApi();
    if(localStorage.getItem('loginStatus')== 1){
      let screens = localStorage.getItem('rolesceens').split(',');
      var checking = screens.includes("8");
       if(checking == true){

          }else{
              window.location.href = "/"; 
          }
      }
      else{
          window.location.href = "/"; 
      }
  }

  automationcallApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "automation-flow",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          automationList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  handleAgentTable = () => {
    let result = "";
    let index = 0;
    result = this.state.agentList.list.map((response, index) => (
      <tr>
        <td>
          {/* <label>
                <input className="uk-checkbox" type="checkbox" />
              </label> */}
          {/* <input
                className="star"
                type="checkbox"
                title="bookmark page"
                defaultChecked
              /> */}
          <input
            className="star"
            type="checkbox"
            title="bookmark page"
            checked={response.is_star == 1 ? true : false}
          />
        </td>
        <td>
          <div className="avatarIcon">
            <img src={response.avatar} alt="Image" />
          </div>
        </td>
        <td>
          <span>{response.agent_name}</span>
        </td>
        <td>{response.phone}</td>
        <td>
          <Link to="">{response.email}</Link>
        </td>

        <td>{response.role}</td>
        <td>{response.team}</td>
        <td>
          <button className="ctmBtn infoBtn">{response.status}</button>
        </td>
        <td>{response.contact_time}</td>
      </tr>
    ));
    return result;
  };

  handleBroadcastTable = () => {
    let result = "";
    let index = 0;
    result = this.state.broadcastList.list.map((response, index) => (
      <tr>
        {/* <td>
                <label><input className="uk-checkbox" type="checkbox" /></label>
                <input className="star" type="checkbox" title="bookmark page" defaultChecked /> 
            </td> */}
        <td>
          <span>{response.name}</span>
        </td>
        <td>{response.success_rate}</td>
        <td>{response.read}</td>
        <td>{response.replied}</td>
        <td>{response.failed}</td>
        <td>
          <button className="ctmBtn infoBtn">{response.status}</button>
        </td>

        <td>{response.scheduled}</td>
        {/* <td>
                <div className="uk-inline">
                    <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                    <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                            <li><Link to="">Edit</Link></li>
                        </ul>
                    </div>
                </div>
            </td> */}
      </tr>
    ));
    return result;
  };

  handleAutomationTable = () => {
    let result = "";
    let index = 0;
    result = this.state.automationList.list.map((response, index) => (
      <tr>
        {/* <td>
                <label><input className="uk-checkbox" type="checkbox" /></label>
                <input className="star" type="checkbox" title="bookmark page" defaultChecked /> 
            </td> */}
        <td>
          <p>
            <b>{response.bot_name}</b>
          </p>
          <div className="whatsAppTxt">
            <img src="/images/whatsappicon.png" alt="" />
            Message is start, subscribe
          </div>
        </td>
        <td>
          <p>
            <b>3245</b>
          </p>
          <p>1234</p>
        </td>
        <td>
          <p>
            <b>3245</b>
          </p>
          <p>1234</p>
        </td>
        <td>
          <p>
            <b>3245</b>
          </p>
          <p>1234</p>
        </td>
        <td>
          <p>
            <b>53.2%</b>
          </p>
          <p>73.2%</p>
        </td>
        <td>
          <button
            className={
              response.status == "Active"
                ? "ctmBtn succesBtn"
                : "ctmBtn dangreBtn"
            }
          >
            {response.status}
          </button>
        </td>

        <td>{response.modified}</td>
        <td>
          <div className="uk-inline">
            <button className="iconTble" type="button">
              <OverflowMenuVertical />
            </button>

            <div uk-dropdown="mode: click">
              <ul className="uk-nav uk-dropdown-nav mainauto">
                <li>
                  <Link to={"/edit-flow-builder/" + response.ref_id}>Edit</Link>
                </li>
                <li>
                  <Link to={"/automation/campaign-report/" + response.id}>
                    Campaign Report
                  </Link>
                </li>
                <li>
                  <Link to={"/automation/campaign-chat/" + response.id}>
                    Chat History
                  </Link>
                </li>
                {response.status == "inactive" ? (
                  <li>
                    <button onClick={(e) => this.activateflow(response.id)}>
                      Active
                    </button>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </td>
      </tr>
    ));
    return result;
  };

  render() {
    return (
      <Content>
        <div
          className="AutomationContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div id="errorAlert"></div>
          <div id="successAlert"></div>
          {/* Automation Tab Start */}
          <div className="TabsHeadBox desktop">
            <ul className="tabsBtns" uk-switcher="connect: .TabSec">
              <li>
                <a href="#">Agents</a>
              </li>
              <li>
                <a href="#">Broadcast</a>
              </li>
              <li>
                <a href="#">Automation</a>
              </li>
            </ul>
            {/* <ul className="tabsBtns desktop" uk-switcher="connect: .TabSec">
                            <li><a href="#">Agents</a></li>
                            <li><a href="#">Broadcast</a></li>
                            <li><a href="#">Automation</a></li>
                            
                        </ul> */}

            <div class="mobTabsBtn">
              <p class="AnimFade uk-scrollspy-inview uk-animation-fade">
                Agents
              </p>

              {/* <ul className="tabsBtns" uk-switcher="connect: .TabSec">
                                <li><a href="#">Agents</a></li>
                                <li><a href="#">Broadcast</a></li>
                                <li><a href="#">Broadcast</a></li>
                            </ul> */}
            </div>
          </div>

          <ul className="uk-switcher uk-margin-small TabSec">
            <li>
              <Teams />
            </li>
            <li>
              <Broadcasting />
            </li>
            <li>
              <Automation />
            </li>
            {/* <li> 
                          <div className="tableSec">
                            <div className="uk-overflow-auto">
                                <table className="uk-table uk-table-small uk-table-divider CustomTableWidth">
                                <thead>
                                    <tr>
                                    <th></th>
                                    <th>AVATAR</th>
                                    <th>CONTACT NAME</th>
                                    <th>PHONE</th>
                                    <th>EMAIL</th>
                                    <th>ROLE </th>
                                    <th>DEPARTMENT </th>
                                    <th>STATUS </th>
                                    <th>LAST LOGIN</th>
                                    </tr>
                                </thead>
                                <tbody>                
                                    {(this.state.agentList.isLoaded) ? this.handleAgentTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>}
                                </tbody>
                                </table>
                            </div>
                        </div>
                        
                        </li>
                        <li>
                        <div className="tableSec">
                            <div className="uk-overflow-auto">
                            <table className="uk-table uk-table-small uk-table-divider">
                                    <thead>
                                        <tr>
                                            
                                            <th>BROADCAST NAME</th>
                                            <th>SUCCESSFUL</th>
                                            <th>READ</th>
                                            <th>REPLIED</th>
                                            <th>FAILED</th>
                                            <th>STATUS</th>
                                            <th>SCHEDULED</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(this.state.broadcastList.isLoaded) ? this.handleBroadcastTable() : ""}
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </li>
                        <li>
                            <div className="tableSec">
                                <div className="uk-overflow-auto">
                                <table className="uk-table uk-table-small uk-table-divider">
                                    <thead>
                                        <tr>
                                            
                                            <th>NAME</th>
                                            <th>TRIGGERED</th>
                                            <th>STEPS FINISHED</th>
                                            <th>FINISHED</th>
                                            <th>CTR</th>
                                            <th>STATUS</th>
                                            <th>MODIFIED ON</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(this.state.automationList.isLoaded) ? this.handleAutomationTable() : ""}
                                    </tbody>
                                </table>
                                </div>
                                
                            </div>
                        </li> */}
          </ul>
        </div>
      </Content>
    );
  }
}
export default Reports;
