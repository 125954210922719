import React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import style from "../../../Assets/css/style.css";
//import InputFeild from "../../../UI_Components/InputFeild"
import { TextField, Button, Form, Checkbox } from "@adobe/react-spectrum";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import $ from 'jquery';
import axios from 'axios';
import Constant from "../../../App/Constant";
UIkit.use(Icons);

class SignUp extends React.Component {

  constructor() {
    super();
    this.state = {
      socialEmail : "",
      socialName : "",
      socialPhone : "",
      socialLogin : false,
      socialSource : "",
    }
  }

  componentDidMount() {
    if(localStorage.getItem('loginStatus')){
      window.location.href = "/dashboard";
    }
  }

  responseFacebook = (response) => {
    if(response.status == 'unknown'){return;}
    // console.log(response)
    this.setState({socialEmail : response.email,socialName : response.name,socialSource : "facebook"})      
    $("#step1").hide()
    $("#step2").show()
    
  }

  responseGoogle = (response) => {
    // response.error
    if(response.error){return;}
    $("#step1").hide()
    $("#step2").show()    
    this.setState({socialEmail : response.profileObj.email,socialName : response.profileObj.name,socialSource : "gmail"})
    // email: response.profileObj.email, firstname: response.profileObj.name, image: response.profileObj.imageUrl
  }

  phoneNext = () => {
    $("#step1").hide()
    $("#step2").hide()
    $("#step3").show()
    this.setState({socialPhone : $("#msisdn").val(),socialLogin:true})
  }

  emailLogin = () => {
    this.setState({socialLogin : false})
    $("#step1").hide()
    $("#step2").hide()
    $("#step3").show()
  }

  signUp = () => {
    
    $("#signupcomplete").text('Processing')
    $("#signupcomplete").prop('disabled', true);  
    
    let classRef = this;    
    var data = {};
    // console.log(this.state.socialLogin,'socialLogin')
   
    if(this.state.socialLogin == true){
      data = {
        channel:classRef.state.socialSource,
        first_name:classRef.state.socialName,
        last_name:classRef.state.socialName,
        email:classRef.state.socialEmail,
        whatsapp_number:$("#msisdn").val(),
        country_code:$("#country_code").val(),
        company_name: $("#company_name").val(),
        industry: $("#company_industry").val(),
        how_did_u_here: $("#source").val(),
        most_exited: $("#most_exited").val()        
            
      }
    }else{
      data = {
        channel:'email',
        first_name:$("#first_name").val(),
        last_name:$("#last_name").val(),
        email:$("#email").val(),
        whatsapp_number:$("#whatsapp_number").val(),
        country_code:$("#email_country_code").val(),
        company_name: $("#company_name").val(),
        industry: $("#company_industry").val(),
        how_did_u_here: $("#source").val(),
        most_exited: $("#most_exited").val(),
        password: $("#password").val(),
        password_confirmation: $("#password").val()                    
      }            
    }   


 
    // if(data.how_did_u_here == ""){

    //   $("#logerror").show();
    //   $("#error_msg").html("Where did you hear about us? is missing.");return;
      
    // }

    // if(data.most_exited == ""){

    //   $("#logerror").show();
    //   $("#error_msg").html("What are you most excited about using WABA?​ is missing.");return;
      
    // }
    
    

    axios({
        method: 'POST',
        url: Constant.url + "signup",
        data: data        
    }).then(function (response){   
      // console.log(response.data.data);             
        if (response.data.code === 200 && response.data.status === true) {          
          // classRef.props.history.push("/dashboard");
          localStorage.setItem('loginStatus', 1);
          localStorage.setItem('role', response.data.data.role);


          $("#successAlert").append('<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.message+'</p></div>');


          if(response.data.data.role == 1){
            window.location.href = "/chat";
          }else{
            window.location.href = "/dashboard";
          }
          localStorage.setItem('company_id', response.data.data.company_id);
          localStorage.setItem('user_id', response.data.data.id);
          window.location.href = "/dashboard";

        }else{

         
          $("#errorAlert").append('<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>'+response.data.data+'</p></div>');
          setTimeout(function(){UIkit.alert('#msg-dialog').close();},500)
          $("#signupcomplete").text('Complete')
          $("#signupcomplete").prop('disabled', false);  
          return



        }
    })
  };  



  render() {
    return (
      <div>
        <section id="step1" className="login_page signup">
          <div className="uk-container uk-container-expand">
            <div className="signup_logo">
              <Link to="#"><img src="/images/berrytalkslogodark.svg" alt="" /></Link>
            </div>
          </div>
          <div className="mdl">
            <div className="mdl_inner">
              <div className="uk-container uk-container-expand uk-padding-remove">
                <div className="login_page_inner">
                  <div className="login_side1">
                    <div className="login_side1_inner">
                      {/* <img className="login_from_logo" src="/images/logo.png" alt="" /> */}
                      <h1>Create an account</h1>
                      <div className="social_signup">
                        <p>Sign up with social</p>
                        <ul>
                          <li>
                            <GoogleLogin
                              clientId="96096407429-e9gh28ou39mhh1fm27qm8bne0cm2nvnl.apps.googleusercontent.com"
                              render={renderProps => (
                                <a className="continuewith" onClick={renderProps.onClick} disabled={renderProps.disabled}><img src="/images/sml-google-logo.png" /></a>
                              )}
                              buttonText="Login"
                              onSuccess={this.responseGoogle}
                              onFailure={this.responseGoogle}
                              cookiePolicy={'single_host_origin'}
                            />
                          </li>
                          {" "}
                          <li>
                            {" "}
                            <FacebookLogin
                              appId="1471066116276908"
                              callback={this.responseFacebook}
                              fields="name,email,picture"
                              render={renderProps => (
                                <a className="continuewith" onClick={renderProps.onClick}><img src="/images/fb-logo.png" /></a>
                              )}
                            />
                          </li>
                        </ul>
                      </div>
                      <hr></hr>

                      <div className="social_signup">
                        <p>Sign up with email</p>
                        <Form
                          maxWidth="size-3600"
                          isQuiet
                          aria-label="Quiet example"
                        >
                          <div className="fields">
                            <TextField
                              width="100%"
                              maxWidth="100%"
                              marginTop="size-200"
                              label="Email address"
                              scale="medium"
                              type="email"
                              id="email"
                              isRequired necessityIndicator="icon"
                            />
                          </div>

                          <div className="uk-grid uk-child-width-1-2">
                            <div>
                              <div className="fields">
                                <TextField
                                  width="100%"
                                  maxWidth="100%"
                                  marginTop="size-200"
                                  label="First name"
                                  scale="medium"
                                  type="text"
                                  id="first_name"
                                  isRequired necessityIndicator="icon"
                                />
                              </div>
                            </div>
                            <div>
                              <div className="fields">
                                <TextField
                                  width="100%"
                                  maxWidth="100%"
                                  marginTop="size-200"
                                  label="Last name"
                                  scale="medium"
                                  type="text"
                                  id="last_name"
                                  isRequired necessityIndicator="icon"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="fields">
                            <TextField
                              width="100%"
                              marginTop="size-200"
                              maxWidth="100%"
                              label="Password"
                              type="password"
                              id="password"
                              scale="medium"
                              isRequired necessityIndicator="icon"
                            />
                          </div>

                          <div className="uk-grid uk-grid-collapse">
                            <div className="uk-width-auto">
                              <div className="fields select_box">
                                <label className=" uk-form-label" >Code</label>
                                <select className="uk-select" id="email_country_code">
                                  <option>+92</option>
                                  <option>+92</option>
                                </select>
                              </div>
                            </div>
                            <div className="uk-width-expand">
                              <div className="fields">
                                <TextField
                                  width="100%"
                                  marginTop="size-200"
                                  maxWidth="100%"
                                  label="WhatsApp number"
                                  id="whatsapp_number"
                                  type="text"
                                  scale="medium"
                                  isRequired necessityIndicator="icon"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="fields uk-margin-top">
                            <div className="uk-grid uk-grid-collapse uk-child-width-1-1">
                              <div className="">
                                <Checkbox isEmphasized>I agree to the Terms & conditions and Privacy policy</Checkbox>
                              </div>
                            </div>
                          </div>

                          <div className=" form_action_btn uk-grid-small uk-grid uk-grid-collapse uk-child-width-1-2">
                            <div>
                              <p >  Existing user? <Link to="/"> Sign in</Link> now</p>
                            </div>
                            <div>
                              <div className="fields spStyle">
                                <Button
                                  id="signin"
                                  position="relative"
                                  marginTop="size-200"
                                  right="size-0"
                                  maxWidth="size-1200"
                                  variant="cta"
                                  onPress={this.emailLogin}
                                >
                                  Next
                                </Button>
                             
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" copyright uk-position-bottom  ">
            <div className="uk-grid uk-grid-collapse uk-child-width-1-2">
              <div>
                <ul>
                  <li><Link to="#">Terms of Use</Link></li>
                  <li><Link to="#">Privacy Policy</Link></li>
                </ul>
              </div>
              <div>
                <p>© 2022 Convex Interactive. All rights reserved.</p>
              </div>
            </div>

          </div>
        </section>


        <section id="step2" className="login_page signup" style={{ display: "none" }}>
          <div className="uk-container uk-container-expand">
            <div className="signup_logo">
              <Link to="#"><img src="/images/signup-head-logo.png" alt="" /></Link>
            </div>
          </div>
          <div className="mdl">
            <div className="mdl_inner">
              <div className="uk-container uk-container-expand uk-padding-remove">
                <div className="login_page_inner">
                  <div className="login_side1">
                    <div className="login_side1_inner">
                      {/* <img className="login_from_logo" src="/images/logo.png" alt="" /> */}
                      <h1>{"Welcome, "+this.state.socialName+"."}</h1>
                      <p>Now set your WhatsApp number.</p>

                      <div className="social_signup">
                        <Form
                          maxWidth="size-3600"
                          isQuiet
                          aria-label="Quiet example"
                        >
                          <div className="uk-grid uk-grid-collapse">
                            <div className="uk-width-auto">
                              <div className="fields select_box">
                                <label className=" uk-form-label" >Code</label>
                                <select id="country_code" className="uk-select">
                                  <option value={+92}>+92</option>
                                  <option value={+92}>+92</option>
                                </select>
                              </div>
                            </div>
                            <div className="uk-width-expand">
                              <div className="fields">
                                <TextField
                                  width="100%"
                                  marginTop="size-200"
                                  maxWidth="100%"
                                  label="WhatsApp number"
                                  type="number"
                                  scale="medium"
                                  id="msisdn"
                                  isRequired necessityIndicator="icon"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="fields uk-margin-top">
                            <div className="uk-grid uk-grid-collapse uk-child-width-1-1">
                              <div className="">
                                <Checkbox isEmphasized>I agree to the Terms & conditions and Privacy policy</Checkbox>
                              </div>
                            </div>
                          </div>

                          <div className=" form_action_btn uk-grid-small uk-grid uk-grid-collapse uk-child-width-1-2">
                            <div>
                              {/* <p >  Existing user? <Link to="#"> Sign in</Link> now</p> */}
                            </div>
                            <div>
                              <div className="fields">
                                <Button
                                  id="signin"
                                  position="relative"
                                  marginTop="size-200"
                                  right="size-0"
                                  maxWidth="size-1200"
                                  variant="cta"
                                  onPress = {this.phoneNext}
                                >
                                  Next
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" copyright uk-position-bottom  ">
            <div className="uk-grid uk-grid-collapse uk-child-width-1-2">
              <div>
                <ul>
                  <li><Link to="#">Terms of Use</Link></li>
                  <li><Link to="#">Privacy Policy</Link></li>
                </ul>
              </div>
              <div>
                <p>© 2022 Convex Interactive. All rights reserved.</p>
              </div>
            </div>

          </div>
        </section>


        <section id="step3" className="login_page signup" style={{ display: "none" }}>
          <div className="uk-container uk-container-expand">
            <div className="signup_logo">
              <Link to="#"><img src="/images/toplogo.svg" alt="" /></Link>
            </div>
          </div>
          <div className="mdl">
            <div className="mdl_inner">
              <div className="uk-container uk-container-expand uk-padding-remove">
                <div className="login_page_inner">
                  <div className="login_side1">
                    <div className="login_side1_inner">
                      {/* <img className="login_from_logo" src="/images/logo.png" alt="" /> */}
                      <h1>You're almost there!</h1>
                      <p>Just answer a few questions and you're done!</p>
                      <div className="social_signup">
                        <Form
                          maxWidth="size-3600"
                          isQuiet
                          aria-label="Quiet example"
                        >
                          <div className="uk-grid uk-grid-collapse">
                            <div className="uk-width-1-1">
                              <div className="fields">
                                <TextField
                                  width="100%"
                                  id="company_name"
                                  marginTop="size-200"
                                  maxWidth="100%"
                                  label="Company Name"
                                  type="text"
                                  scale="medium"
                                  isRequired necessityIndicator="icon"
                                />
                              </div>
                            </div>
                            <div className="uk-width-1-1">
                              <div className="fields select_box ii">
                                <label className=" uk-form-label uk-width-1-1 uk-display-block" >Company industry</label>
                                <select className="uk-select uk-width-1-1 " id="company_industry">
                                  <option value={'IT'}>IT</option>
                                  <option value={'Digital Agency'}>Digital Agency</option>
                                </select>
                              </div>
                            </div>
                            <div className="uk-width-1-1">
                              <div className="fields select_box ii">
                                <label className=" uk-form-label uk-width-1-1 uk-display-block" >Where did you hear about us?</label>
                                <select className="uk-select uk-width-1-1 " id="source">
                                  <option value={'Facebook'}>Facebook</option>
                                  <option value={'Instagram'}>Instagram</option>
                                  <option value={'Friend'}>Friend</option>
                                </select>
                              </div>
                            </div>
                            <div className="uk-width-1-1">
                              <div className="fields">
                                {/* <label className=" uk-form-label uk-width-1-1 uk-display-block" >What are you most excited about using WABA?</label> */}
                                <TextField
                                  width="100%"
                                  id="most_exited"
                                  marginTop="size-200"
                                  maxWidth="100%"
                                  label="What are you most excited about using WABA?"
                                  type="text"
                                  scale="medium"
                                  isRequired necessityIndicator="icon"
                                />
                              </div>
                            </div>
                          </div>

                          <div className=" form_action_btn uk-grid-small uk-grid uk-grid-collapse uk-child-width-1-2">
                            <div>
                              {/* <p >  Existing user? <Link to="#"> Sign in</Link> now</p> */}
                            </div>
                            <div>
                              <div className="fields">
                                <Button
                                  id="signupcomplete"
                                  position="relative"
                                  marginTop="size-200"
                                  right="size-0"
                                  maxWidth="size-1200"
                                  variant="cta"
                                  onPress={this.signUp}
                                >
                                  Complete
                                </Button>
                              </div>
                            </div>
                          </div>

                          <div id="errorAlert"></div>
                          <div id="successAlert"></div>

                        </Form>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" copyright uk-position-bottom  ">
            <div className="uk-grid uk-grid-collapse uk-child-width-1-2">
              <div>
                <ul>
                  <li><Link to="#">Terms of Use</Link></li>
                  <li><Link to="#">Privacy Policy</Link></li>
                </ul>
              </div>
              <div>
                <p>© 2022 Convex Interactive. All rights reserved.</p>
              </div>
            </div>

          </div>
        </section>


      </div>
    );
  }
}

export default SignUp;
