import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import PageActionBar from "../../Sections/PageActionBar";
import {
  CaretDown,
  Add,
  OverflowMenuVertical,
  Close,
  Information,
  TrashCan,
  Edit,
  ReportData,
  Chat,
} from "@carbon/icons-react";
import Constant from "../../../App/Constant";
import axios from "axios";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Select from "react-select";
import moment from "moment";
class Automation extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.callPackageApi = this.callPackageApi.bind(this);
    this.getKeywordValue = this.getKeywordValue.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      keywordDataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      keywordReportList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      checkAll: false,
      default: "no",
      is_expired: 0,
      rowsCountAutomation: 0,
      rowsCountKeywords: 0,
      keywordDetail_Dateto: moment(new Date()).format("YYYY-MM-DD"),
      keywordDetail_DateFrom: moment(new Date())
        .subtract(7, "d")
        .format("YYYY-MM-DD"),
    };
  }

  activateflow = (id, status) => {
    let classRef = this;
    var flow_id = id;
    axios({
      method: "POST",
      url: Constant.url + "activate-deactive-flow",
      data: {
        company_id: localStorage.getItem("company_id"),
        status: status,
        flow_id: flow_id,
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        window.location.href = "/automation";
      } else {
        window.location.href = "/automation";
      }
    });
  };

  resub = () => {
    window.location.href = "/packages";
  };

  componentDidMount() {
    var classRef = this;
    var pageCount = 1;
    this.callApi(pageCount);
    this.callKeywordsApi(pageCount);
    this.getKeywordReport(pageCount);
    classRef.callPackageApi();
    // setInterval(function(){
    //     if(classRef.state.default == "no"){
    //         classRef.callPackageApi();
    //     }
    //   },3000)

    if (localStorage.getItem("loginStatus") == 1) {
      let screens = localStorage.getItem("rolesceens").split(",");
      var checking = screens.includes("3");
      if (checking == true) {
      } else {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }

    $("#cross").hide();
    $("#across").hide();
    $("#crossForKewordReport").hide();
  }

  callPackageApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "package-stats",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        var package_stats = result.data.data;
        classRef.setState({
          is_expired: package_stats.is_expired,
        });
      }
    });
  };

  callApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "automation-flow",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountAutomation: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountAutomation: result.data.rowscount,
        });
      }
    });
  };

  AutomationSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#AutomationSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "automation-flow",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountAutomation: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountAutomation: result.data.rowscount,
        });
      }
    });
  };

  handleAutomationClick = (data) => {
    let currentPage = data.selected + 1;
    this.AutomationSorting(currentPage);
  };

  handleKeywordsClick = (data) => {
    let currentPage = data.selected + 1;
    this.KeywordsSorting(currentPage);
  };

  callKeywordsApi = (pageCount) => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "keywords",
      data: { company_id: localStorage.getItem("company_id"), page: pageCount },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          keywordDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountKeywords: result.data.rowscount,
        });
      } else {
        classRef.setState({
          keywordDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountKeywords: result.data.rowscount,
        });
      }
    });
  };
  getKeywordReport = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "keyword-reports",
      // data: { dateto: "2023-05-17", datefrom: "2023-05-01" },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          keywordReportList: {
            isLoaded: true,
            msg: "Loading...",
            list: result?.data?.data,
          },
        });
      } else {
        classRef.setState({
          keywordReportList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  KeywordsSorting = (pageCount) => {
    let classRef = this;
    let sortingValue = $("#KeywordsSortingBtn").val();
    sortingValue = sortingValue != "Sorting" ? sortingValue : 10;
    axios({
      method: "POST",
      url: Constant.url + "keywords",
      data: {
        company_id: localStorage.getItem("company_id"),
        page: pageCount,
        limit: sortingValue,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          keywordDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountKeywords: result.data.rowscount,
        });
      } else {
        classRef.setState({
          keywordDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountKeywords: result.data.rowscount,
        });
      }
    });
  };

  getKeywordValue = () => {
    // console.log("working");
  };

  handleKeywordTable = () => {
    let result = "";
    let index = 0;
    result = this.state.keywordDataList.list.map((response, index) => (
      <tr id={"keyword_" + response.id}>
        {/* <td>
                    <label><input className="uk-checkbox" type="checkbox" /></label>
                </td> */}

        <td>
          {/* <p><b>Send</b></p> */}
          <div className="whatsAppTxt FlowTxt">
            <p>
              <a>{response.bot_name}</a>
            </p>
          </div>
        </td>

        <td>
          <p>
            <b>Message contains</b>
          </p>
          <div className="whatsAppTxt AutoTxt">
            <ul>
              {response.keywords.map(function (keyword, index) {
                // return <li key={ index }>{keyword}</li>;
                return (
                  <li key={index}>
                    <Link
                      to={
                        "/automation/keyword-report/" +
                        response.campaign_id +
                        "/" +
                        keyword
                      }
                    >
                      {keyword}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </td>

        {/* <td>
                    <div className="whatsAppTxt FlowTxt">
                        <p><a>{response.bot_name}</a></p>
                    </div>
                </td> */}

        <td>
          <p>{response.modified}</p>
        </td>
        {/* <td>
                    <div className="uk-inline">
                        <button className="iconTble" type="button"><OverflowMenuVertical /></button>
                        <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                                <li><a href="#">Delete</a></li>
                                <li><a href="#">Edit</a></li>
                            </ul>
                        </div>
                    </div>
                </td> */}
      </tr>
    ));
    return result;
  };
  handleKeywordReportData = () => {
    let result = "";
    let index = 0;
    result = this.state.keywordReportList.list.map((response, index) => (
      <>
        <tr>
          <td>
            <Link
              to={
                "/automation/keywordReportDetails/details/" +
                "product_information" +
                "/" +
                this.state.keywordDetail_Dateto +
                "/" +
                this.state.keywordDetail_DateFrom +
                "/Product Information"
              }
            >
              Product Information
            </Link>
          </td>
          <td>
            <p>{response.product_information}</p>
          </td>
        </tr>
        <tr>
          <td>
            <Link
              to={
                "/automation/keywordReportDetails/details/" +
                "consumer_feedback" +
                "/" +
                this.state.keywordDetail_Dateto +
                "/" +
                this.state.keywordDetail_DateFrom +
                "/Consumer Feedback"
              }
            >
              Consumer Feedback
            </Link>
          </td>
          <td>
            <p>{response.consumer_feedback}</p>
          </td>
        </tr>
        <tr>
          <td>
            <Link
              to={
                "/automation/keywordReportDetails/details/" +
                "product_availability" +
                "/" +
                this.state.keywordDetail_Dateto +
                "/" +
                this.state.keywordDetail_DateFrom +
                "/Product Availiblity"
              }
            >
              Product Availiblity
            </Link>
          </td>
          <td>
            <p>{response.product_availability}</p>
          </td>
        </tr>
        <tr>
          <td>
            <Link
              to={
                "/automation/keywordReportDetails/details/" +
                "business_inquiry" +
                "/" +
                this.state.keywordDetail_Dateto +
                "/" +
                this.state.keywordDetail_DateFrom +
                "/Business Inquiry"
              }
            >
              Business Inquiry
            </Link>
          </td>
          <td>
            <p>{response.business_inquiry}</p>
          </td>
        </tr>
        <tr>
          <td>
            <Link
              to={
                "/automation/keywordReportDetails/details/" +
                "event_coverage" +
                "/" +
                this.state.keywordDetail_Dateto +
                "/" +
                this.state.keywordDetail_DateFrom +
                "/Event Coverage"
              }
            >
              Event Coverage
            </Link>
          </td>
          <td>
            <p>{response.event_coverage}</p>
          </td>
        </tr>
        <tr>
          <td>
            <Link
              to={
                "/automation/keywordReportDetails/details/" +
                "delivery_partners" +
                "/" +
                this.state.keywordDetail_Dateto +
                "/" +
                this.state.keywordDetail_DateFrom +
                "/Delivery Partner"
              }
            >
              Delivery Partner
            </Link>
          </td>
          <td>
            <p>{response.delivery_partners}</p>
          </td>
        </tr>
        <tr>
          <td>
            <Link
              to={
                "/automation/keywordReportDetails/details/" +
                "others_customer" +
                "/" +
                this.state.keywordDetail_Dateto +
                "/" +
                this.state.keywordDetail_DateFrom +
                "/Other LiveChat"
              }
            >
              Other LiveChat
            </Link>
          </td>
          <td>
            <p>{response.others_customer}</p>
          </td>
        </tr>
      </>
    ));
    return result;
  };

  deleteMultiFlow = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#flow_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "delete-flow",
      data: {
        company_id: localStorage.getItem("company_id"),
        flow_id: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        //  window.location.reload();
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((response, index) => (
      <tr id={"flow_" + response.id}>
        <td>
          <label>
            <input
              className="uk-checkbox autoSelect"
              value={response.id}
              type="checkbox"
            />
          </label>
          {/* <input className="star" type="checkbox" title="bookmark page" defaultChecked />  */}
        </td>
        <td>
          <p>
            <b>{response.bot_name}</b>
          </p>
          <div className="whatsAppTxt">
            <img src="/images/whatsappicon.png" alt="" />
            Message is start, subscribe
          </div>
        </td>
        <td>
          <p className="info" uk-tooltip="Number contacts hits the bot">
            <b>{response.triggered}</b>
          </p>
          <p
            className="info"
            uk-tooltip="Number of unique contacts hits the bot"
          >
            {response.unique_triggered}
          </p>
        </td>
        <td>
          <p>
            <b>{response.step_finished}</b>
          </p>
          <p>{response.unique_step_finished}</p>
        </td>
        <td>
          <p>
            <b>{response.finished}</b>
          </p>
          <p>{response.unique_finished}</p>
        </td>
        {/* <td>
                    <p><b>{Math.floor(Math.random() * 100)+"%"}</b></p>
                    <p>{Math.floor(Math.random() * 100)+"%"}</p>
                </td> */}

        <td>
          <button
            onClick={
              response.status == "active"
                ? (e) => this.activateflow(response.id, "inactive")
                : (e) => this.activateflow(response.id, "active")
            }
            className={
              response.status == "active"
                ? "ctmBtn succesBtn"
                : "ctmBtn dangreBtn"
            }
          >
            {response.status == "active" ? "Active" : "Inactive"}
          </button>
        </td>
        {/* <td><button onClick={(response.status == 'active') ? (e) => this.resub() : ""} className={(response.status == 'active' ? 'ctmBtn succesBtn' : "ctmBtn dangreBtn")}>{(response.status == 'active') ? 'Expired' : 'Deactivated'}</button></td> */}
        {/* <td><button onClick={(response.status == 'active') ? (e) => this.activateflow(response.id, 'inactive') : (e) => this.activateflow(response.id, 'active')} className={(response.status == 'active' ? 'ctmBtn succesBtn' : "ctmBtn dangreBtn")}>{(response.status == 'active') ? 'Active' : 'Inactive'}</button></td> */}

        <td>{response.modified}</td>
        <td>
          <Link
            className="iconcircleBtn success"
            uk-tooltip="title: Campaign Report"
            to={"/automation/campaign-report/" + response.id}
          >
            <ReportData />
          </Link>
        </td>
        <td>
          <Link
            className="iconcircleBtn"
            uk-tooltip="title: Chat History"
            to={"/automation/campaign-chat/" + response.id}
          >
            <Chat />
          </Link>
        </td>
        {/* <td><Link className="iconcircleBtn" uk-tooltip="title: Edit" to={"/edit-flow-builder/" + response.ref_id}><Edit /></Link></td> */}

        <td>
          {response.is_custom == "no" ? (
            <Link
              className="iconcircleBtn"
              uk-tooltip="title: Edit"
              to={"/edit-flow-builder/" + response.ref_id}
            >
              <Edit />
            </Link>
          ) : (
            <Link
              className="iconcircleBtn disabled-link"
              uk-tooltip="title: Edit"
              to={"/edit-flow-builder/" + response.ref_id}
            >
              <Edit />
            </Link>
          )}
        </td>

        <td>
          <a
            className="iconcircleBtn danger"
            uk-tooltip="title: Delete"
            onClick={(e) => this.addconsent(response.id)}
          >
            <TrashCan />
          </a>
        </td>
      </tr>
    ));
    return result;
  };

  selectCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    // console.log(searchword);

    if (searchword !== "") {
      $("#across").show();
    }

    this.setState({ advanceautosearch: searchword });

    axios({
      method: "POST",
      url: Constant.url + "automation-flow",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountAutomation: result.data.rowscount,
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountAutomation: result.data.rowscount,
        });
      }
    });
  };

  advAutoSearchClear = () => {
    let classRef = this;
    classRef.setState({ advanceautosearch: "" });
    classRef.callApi();
    $("#across").hide();
  };

  searchrecordkey = () => {
    let classRef = this;
    var searchword = $("#searchwords").val();
    // console.log(searchword);

    this.setState({ advancekeysearch: searchword });

    if (searchword !== "") {
      $("#cross").show();
    }

    axios({
      method: "POST",
      url: Constant.url + "keywords",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          keywordDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountKeywords: result.data.rowscount,
        });
      } else {
        classRef.setState({
          keywordDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountKeywords: result.data.rowscount,
        });
      }
    });
  };

  closeAutoSearchModal = () => {
    $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
  };

  addAutoSearchModal = () => {
    $("#SearchAutoTeamModal").addClass("uk-flex uk-open");
  };

  closeKeySearchModal = () => {
    $("#SearchKeyTeamModal").removeClass("uk-flex uk-open");
  };

  addkeySearchModal = () => {
    $("#SearchKeyTeamModal").addClass("uk-flex uk-open");
  };

  closeshowKeywordReportFilterModal = () => {
    $("#SearchKeywordReportModal").removeClass("uk-flex uk-open");
  };

  showKeywordReportFilterModal = () => {
    $("#SearchKeywordReportModal").addClass("uk-flex uk-open");
  };

  advkeySearchClear = () => {
    let classRef = this;
    classRef.setState({ advancekeysearch: "" });
    classRef.callKeywordsApi();
    $("#cross").hide();
  };

  advanceSearchkeywordSearchClear = () => {
    let classRef = this;
    classRef.setState({ advancekeywordResportsearch: "" });
    classRef.getKeywordReport();
    $("#crossForKewordReport").hide();
  };

  KeyadvanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#keyto_date").val(),
      datefrom: $("#keyfrom_date").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#keyto_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#keyfrom_date").val();
    }

    if (key !== "") {
      $("#cross").show();
    }

    classRef.setState({ advancekeysearch: key });

    axios({
      method: "POST",
      url: Constant.url + "keywords",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          keywordDataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountKeywords: result.data.rowscount,
        });
        $("#SearchKeyTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          keywordDataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountKeywords: result.data.rowscount,
        });
        $("#SearchKeyTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };
  KeywordReportadvanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#keywordReportto_date").val(),
      datefrom: $("#keywordReportfrom_date").val(),
    };
    classRef.setState({
      keywordDetail_Dateto: data.dateto,
      keywordDetail_DateFrom: data.datefrom,
    });
    if (data.dateto) {
      var key = "Date To: " + $("#keywordReportto_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#keywordReportfrom_date").val();
    }

    if (key !== "") {
      $("#crossForKewordReport").show();
    }

    classRef.setState({ advancekeywordResportsearch: key });

    axios({
      method: "POST",
      url: Constant.url + "keyword-reports",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          keywordReportList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchKeywordReportModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          keywordReportList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchKeywordReportModal").removeClass("uk-flex uk-open");
      }
    });
  };

  AutoadvanceSearch = () => {
    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      keyword: $("#keyword").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }
    if (data.keyword) {
      var key = key + " Status: " + $("#keyword :selected").text();
    }

    if (key !== "") {
      $("#across").show();
    }

    classRef.setState({ advanceautosearch: key });

    axios({
      method: "POST",
      url: Constant.url + "automation-flow",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          rowsCountAutomation: result.data.rowscount,
        });
        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          rowsCountAutomation: result.data.rowscount,
        });
        $("#SearchAutoTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  clearFilters = () => {
    $("#to_date").val("");
    $("#from_date").val("");
    $("#keyword").val("");
  };

  clearkeyFilters = () => {
    $("#keywordReportfrom_date").val("");
    $("#keywordReportto_date").val("");
  };
  clearkeywordReportFilters = () => {
    let classRef = this;
    $("#keywordfrom_date").val("");
    $("#keyto_date").val("");
    classRef.setState({
      keywordDetail_Dateto: moment(new Date()).format("YYYY-MM-DD"),
      keywordDetail_DateFrom: moment(new Date())
        .subtract(7, "d")
        .format("YYYY-MM-DD"),
    });
  };

  addconsent = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  //   deleteFlow = (id) => {
  //     $("#flow_" + id).hide();
  //     let classRef = this;
  //     axios({
  //         method: 'POST',
  //         url: Constant.url + "delete-flow",
  //         data: {
  //             company_id: localStorage.getItem('company_id'),
  //             flow_id: [id]
  //         }
  //     }).then(function (result) {
  //         if (result.data.status === true) {
  //             window.location.reload();
  //         }
  //     })
  // }

  deleteFlow = () => {
    let classRef = this;
    var id = $("#addconsent").val();

    axios({
      method: "POST",
      url: Constant.url + "delete-flows",
      data: {
        company_id: localStorage.getItem("company_id"),
        flow_id: [id],
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#flow_" + id).hide();

        window.location.reload();
      } else {
        window.location.href = "/audience";
      }
    });
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  downloadAutomation = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "download-automation-flow?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };

  downloadkeyword = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "download-keywords?company_id=" +
        localStorage.getItem("company_id"),
      "_blank"
    );
  };
  downloadkeywordReport = () => {
    let classRef = this;
    var dateto = $("#keywordReportto_date").val();
    var datefrom = $("#keywordReportfrom_date").val();
    window.open(
      Constant.url +
        "keyword-report-export?=dateto" +
        dateto +
        "&datefrom=" +
        datefrom,
      "_blank"
    );
  };

  render() {
    return (
      <Content>
        <div
          className="AutomationContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div id="errorAlert"></div>
          <div id="successAlert"></div>
          {/* Automation Tab Start */}
          <div className="TabsHeadBox">
            <ul
              className="tabsBtns desktop"
              uk-switcher="connect: .multiTabNew2"
            >
              <li>
                <a href="#">Automation Flows</a>
              </li>
              {localStorage.getItem("company_id") != 8 && (

              <li>
                <a href="#">Keywords</a>
              </li>
              )}
              {localStorage.getItem("company_id") == 8 && (
                <li>
                  <a href="#">Keyword Report</a>
                </li>
              )}
            </ul>
            <div class="mobTabsBtn">
              <p class="AnimFade uk-scrollspy-inview uk-animation-fade">
                Automation Flows
              </p>
              <ul className="tabsBtns" uk-switcher="connect: .multiTabNew2">
                <li>
                  <a href="#">Automation Flows</a>
                </li>
                {localStorage.getItem("company_id") != 8 && (
                <li>
                  <a href="#">Keywords</a>
                </li>
                )}
                {localStorage.getItem("company_id") == 8 && (
                  <li>
                    <a href="#">Keyword Report</a>
                  </li>
                )}
              </ul>
            </div>
            <ul className="uk-switcher multiTabNew2">
              <li>
                <div className="searchnFlowBtn mobile">
                  <form className="uk-search" action="javascript:void(0)">
                    <div className="uk-width-auto">
                      <input
                        className="uk-search-input"
                        type="search"
                        onChange={this.searchrecord}
                        value={this.state.advanceautosearch}
                        name="searchword"
                        id="searchword"
                        placeholder="Search through name"
                      />
                    </div>
                    <div class="Filtermain automaationFiltermain">
                      <div className="icon">
                        <div className="uk-width-auto">
                          <button
                            id="across"
                            style={{ right: "50px" }}
                            onClick={this.advAutoSearchClear}
                          >
                            <Close />
                          </button>
                          <button
                            className="popadvanceFilter"
                            onClick={this.addAutoSearchModal}
                          >
                            <img src="/images/newfilter.svg" uk-svg="" />
                            {/* <Search onClick = {this.addSearchModal}/> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                  <Link
                    style={{ padding: "7px 15px 4px 15px" }}
                    className="newFlowBtn uk-float-right"
                    to="/flow-builder"
                  >
                    <Add /> <span>New Flow</span>
                  </Link>
                  <button
                    className="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.downloadAutomation}
                  >
                    <Add /> <span>Export Automation</span>
                  </button>
                </div>
              </li>
              {localStorage.getItem("company_id") != 8 && (
              <li>
                <div className="searchnFlowBtn mobile">
                  <form className="uk-search" action="javascript:void(0)">
                    <div className="uk-width-auto">
                      <input
                        className="uk-search-input"
                        type="search"
                        onChange={this.searchrecordkey}
                        value={this.state.advancekeysearch}
                        name="searchwords"
                        id="searchwords"
                        placeholder="Search through keyword"
                      />
                    </div>
                    <div class="Filtermain automaationFiltermainkeyword">
                      <div className="uk-width-auto">
                        <button
                          id="cross"
                          style={{ right: "30px" }}
                          onClick={this.advkeySearchClear}
                        >
                          <Close />
                        </button>

                        <button
                          className="popadvanceFilter"
                          onClick={this.addkeySearchModal}
                        >
                          <img src="/images/newfilter.svg" uk-svg />
                          {/* <Search onClick = {this.addSearchModal}/> */}
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* <Link className="newFlowBtn" to="/flow-builder"><Add /> <span>New Flow2</span></Link> */}
                  <button
                    class="newFlowBtn uk-float-right uk-margin-right"
                    onClick={this.downloadkeyword}
                  >
                    <Add /> <span>Export Keyword</span>
                  </button>
                </div>
              </li>
              )}
              {localStorage.getItem("company_id") == 8 && (
                <li>
                  <div className="searchnFlowBtn mobile">
                    <form className="uk-search" action="javascript:void(0)">
                      <div className="uk-width-auto">
                        <input
                          className="uk-search-input"
                          type="search"
                          // onChange={this.searchrecordkey}
                          value={this.state.advancekeywordResportsearch}
                          name="searchwords"
                          id="searchwords"
                          disabled
                        />
                      </div>
                      <div class="Filtermain automaationFiltermainkeyword">
                        <div className="uk-width-auto">
                          <button
                            id="crossForKewordReport"
                            style={{ right: "30px" }}
                            onClick={this.advanceSearchkeywordSearchClear}
                          >
                            <Close />
                          </button>

                          <button
                            className="popadvanceFilter"
                            // onClick={this.addkeySearchModal}
                            onClick={this.showKeywordReportFilterModal}
                          >
                            <img src="/images/newfilter.svg" uk-svg />
                          </button>
                        </div>
                      </div>
                    </form>
                    <button
                      class="newFlowBtn uk-float-right uk-margin-right"
                      onClick={this.downloadkeywordReport}
                    >
                      <Add /> <span>Export Keyword Report</span>
                    </button>
                  </div>
                </li>
              )}
            </ul>
          </div>

          <ul className="uk-switcher multiTabNew2">
            <li>
              <div className="numberSorting uk-float-right">
                <div class="uk-inline">
                  <label>
                    Show
                    <select
                      onChange={(e) => this.AutomationSorting()}
                      id="AutomationSortingBtn"
                    >
                      {/* <option disabled selected>Sorting</option> */}
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="tableSec">
                <table className="uk-table uk-table-small uk-table-divider uk-table-middle">
                  <thead>
                    <tr>
                      <th>
                        <label>
                          <input
                            className="uk-checkbox"
                            id="select_all"
                            onClick={this.selectCheckbox}
                            type="checkbox"
                          />
                        </label>
                        <div className="uk-inline drpIconBox">
                          <button className="iconTble" type="button">
                            <CaretDown />
                          </button>
                          <div uk-dropdown="mode: click">
                            <ul className="uk-nav uk-dropdown-nav">
                              <li className="hintAlert">
                                <a onClick={this.deleteMultiFlow}>
                                  <TrashCan />
                                  Delete Select Rows
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </th>
                      <th>NAME</th>
                      <th
                        className="info"
                        uk-tooltip="Number contacts hits the bot"
                      >
                        TRIGGERED
                      </th>
                      <th>STEPS FINISHED</th>
                      <th>FINISHED</th>
                      {/* <th>CTR</th> */}
                      <th>STATUS</th>
                      {/* <th>
                                                <div className="uk-inline drpIconBox">
                                                    <a href="#">STATUS <CaretDown /></a>
                                                    <div uk-dropdown="mode: click">
                                                        <ul className="uk-nav uk-dropdown-nav">
                                                            <li><a href="#">All</a></li>
                                                            <li><a href="#">Active</a></li>
                                                            <li><a href="#">Inactive</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </th> */}
                      <th>MODIFIED ON</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {(this.state.dataList.isLoaded) ? this.handleTable() : ""} */}
                    {this.state.dataList.isLoaded ? (
                      this.handleTable()
                    ) : this.state.dataList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountAutomation > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountAutomation}
                    onPageChange={this.handleAutomationClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li>
            {localStorage.getItem("company_id") != 8 && (
            <li>
              <div className="numberSorting uk-float-right">
                <div class="uk-inline">
                  <label>
                    Show
                    <select
                      onChange={(e) => this.KeywordsSorting()}
                      id="KeywordsSortingBtn"
                    >
                      {/* <option disabled selected>Sorting</option> */}
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>500</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="tableSec">
                <table className="uk-table uk-table-small uk-table-divider uk-table-middle">
                  <thead>
                    <tr>
                      {/* <th>
                                                <label><input className="uk-checkbox" type="checkbox" /></label>
                                                <div className="uk-inline drpIconBox">
                                                    <button className="iconTble" type="button"><CaretDown /></button>
                                                    <div uk-dropdown="mode: click">
                                                        <ul className="uk-nav uk-dropdown-nav">
                                                            <li><a href="#">Delete All Selected Rows</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </th> */}
                      {/* <th>ACTIVE</th> */}

                      <th>FLOW TITLE</th>
                      <th>KEYWORD</th>
                      <th>MODIFIED ON</th>
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody className="AutomationTabelHover">
                    {this.state.keywordDataList.isLoaded ? (
                      this.handleKeywordTable()
                    ) : this.state.keywordDataList.msg == "not" ? (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            Data not Found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="15">
                          <div className="uk-text-center uk-height-large">
                            <div className="mdl">
                              <div className="mdl_inner">
                                <div uk-spinner=""></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {this.state.rowsCountKeywords > 1 ? (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"...."}
                    pageCount={this.state.rowsCountKeywords}
                    onPageChange={this.handleKeywordsClick}
                    containerClassName={"uk-pagination uk-float-right"}
                    activeClassName={"uk-active"}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={6}
                  />
                ) : (
                  ""
                )}
              </div>
            </li>
            )}
            {localStorage.getItem("company_id") == 8 && (
              <li>
                <div className="tableSec">
                  <table className="uk-table uk-table-small uk-table-divider uk-table-middle">
                    <thead>
                      <tr>
                        <th>KEYWORD</th>
                        <th>NO OF TIMES TO BE PRESSED</th>
                      </tr>
                    </thead>
                    <tbody className="AutomationTabelHover">
                      {this.state.keywordReportList.isLoaded ? (
                        this.handleKeywordReportData()
                      ) : this.state.keywordReportList.msg == "not" ? (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              Data not Found
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="15">
                            <div className="uk-text-center uk-height-large">
                              <div className="mdl">
                                <div className="mdl_inner">
                                  <div uk-spinner=""></div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </li>
            )}
          </ul>
        </div>

        <div
          id="SearchAutoTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date"
                    />
                  </div>
                </div>

                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Status
                </label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="keyword">
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn">
                  <button className="CnclBtn" onClick={this.clearFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeAutoSearchModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.AutoadvanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="SearchKeyTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="keyfrom_date"
                    />
                  </div>
                </div>
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="keyto_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn">
                  <button className="CnclBtn" onClick={this.clearkeyFilters}>
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeKeySearchModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.KeyadvanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          id="SearchKeywordReportModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      className="to_date"
                      id="keywordReportfrom_date"
                    />
                  </div>
                </div>
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      className="from_date"
                      id="keywordReportto_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns ClearFiltersBtn">
                  <button
                    className="CnclBtn"
                    onClick={this.clearkeywordReportFilters}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn ClearBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.closeshowKeywordReportFilterModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.KeywordReportadvanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Flow?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsent"
                    onClick={this.deleteFlow}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default Automation;
