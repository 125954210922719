import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";

import {
    Timer,
    Information,
    Hourglass,
    ChevronDown
  } from "@carbon/icons-react";
import { AgentStatusChart } from "../../Sections/AgentStatusChart";
import { AssignLiveChat } from "../../Sections/AssignLiveChat";
import { DateRange } from "../../Common/DateRange";
import { LiveChatOverviewResolutionTIme } from "../../Sections/LiveChatOverviewResolutionTIme";
import { WhatsappBusinessMessStatus } from "../../Sections/WhatsappBusinessMessStatus";
import { WhatsappFrequencyChart } from "../../Sections/WhatsappFrequencyChart";
import { WhatsappSmsOverview } from "../../Sections/WhatsappSmsOverview";
import { WhatsappdeliveryTime } from "../../Sections/WhatsappdeliveryTime";
import { WhatsappMonthlyActiveChart } from "../../Sections/WhatsappMonthlyActiveChart";
import { WhatsappMonthlyActiveUserChart } from "../../Sections/WhatsappMonthlyActiveUserChart";


  
class WhatsappChanelAnalytics extends React.Component {
    MultiSelectFilter =()=>{
        $(".UltabsBtns").toggleClass("showMenu");
        $(".UltabsBtns > li label").click(function(){
            $(".MultiSelectFilter > p span").text($(this).text());
        });
    }
    
    render() {
        return (
         <>
            <div className="uk-grid-medium" uk-grid="" uk-height-match="target: > div > .uk-card">
                <div className="uk-width-1-2">
                    <div className="ChannelDropDown">
                        <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                            <p><span>WhatsApp</span> <ChevronDown /></p>
                            <ul className="UltabsBtns">
                                <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                                <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2 uk-flex uk-flex-right">

                    
                    <div className="CustomCalender">
                    <DateRange />
                    </div>
                </div>
                
                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head"><h5>SMS Overview</h5></div>
                            {/* Chart */}
                            <div className="ChannelsOverviewGraph">
                            <WhatsappSmsOverview  />
                            </div>
                    </div>
                </div>
                {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">User Initiated and Business Initiated Messages Performance
                            </h5>
                        </div>
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Timer className="iconBara" /> User Initiated Messages
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>203,820</h3>
                                <p>Traffic
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>First response time</h5>
                                        <p>How long are customers waiting for agent's first response.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>186,457</h3>
                                <p>Messages
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>First response time</h5>
                                        <p>How long are customers waiting for agent's first response.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>17,363</h3>
                                <p>Cost</p>
                                </div>
                            </div>

                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>17,363</h3>
                                <p>Delivery Rate
                                <div class="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>First response time</h5>
                                        <p>How long are customers waiting for agent's first response.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div uk-grid="">
                            <div className="uk-width-expand">
                                <div className="mdl">
                                    <div className="mdl_inner">
                                        <div className="overCardTxt">
                                            <p className="solvedTxt"><Timer className="iconBara" /> Business Initiated Messages
                                            
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3> 17,623</h3>
                                <p>Traffic
                                <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>All response time</h5>
                                        <p>The metric that displays agent's responsiveness.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>11,261</h3>
                                <p>Messages
                                <div className="uk-inline infoTool"><a href="#"><Information /></a>
                                    <div uk-dropdown="mode: click">
                                        <h5>All response time</h5>
                                        <p>The metric that displays agent's responsiveness.</p>
                                    </div>
                                </div>
                                </p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3>18,628</h3>
                                <p>Cost</p>
                                </div>
                            </div>
                            <div className="uk-width-expand">
                                <div className="overCardTxt">
                                <h3></h3>
                                <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Service-level agreemant*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Business Initiated Messages Status Breakdown
                            </h5>
                        </div>
                        
                            <div uk-grid="">
                                <div className="uk-width-1-3">
                                   <WhatsappBusinessMessStatus/>
                                </div>

                                <div className="uk-width-2-3">
                                    <div className="labelResoltuionTimeMain">
                                        <ul className="uk-list heading">
                                            
                                            <li><b>Messages</b></li>
                                            <li><b>Percent age</b></li>
                                        </ul>
                                        <ul className="labelResoltuionTime whatsappmessage" >
                                            <li>
                                                <span className="Label">Delivered</span>
                                                <progress id="js-progressbar" class="uk-progress delivered" value="40" max="100"></progress>
                                                <span className="Percent">45.86%</span>
                                                <span className="Percent">72</span>
                                            </li>

                                            <li>
                                                <span className="Label">Pending</span>
                                                <progress id="js-progressbar" class="uk-progress pending" value="15" max="100"></progress>
                                                <span className="Percent">6.02%</span>
                                                <span className="Percent">14</span>
                                            </li>

                                            <li>
                                                <span className="Label">Expired</span>
                                                <progress id="js-progressbar" class="uk-progress expired" value="11" max="100"></progress>
                                                <span className="Percent">18.7%</span>
                                                <span className="Percent">23</span>
                                            </li>

                                            <li>
                                                <span className="Label">Rejected</span>
                                                <progress id="js-progressbar" class="uk-progress rejected" value="9" max="100"></progress>
                                                <span className="Percent">22.78%</span>
                                                <span className="Percent">28</span>
                                            </li>

                                            <li>
                                                <span className="Label">Undeliverable</span>
                                                <progress id="js-progressbar" class="uk-progress undeliverable" value="0" max="100"></progress>
                                                <span className="Percent">0</span>
                                                <span className="Percent">0</span>
                                            </li>

                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>

                {/* Delivery rate */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">WhatsApp Delivery Rate for Business Initiated Messages per Top Countries</h5>
                        </div>  
                        <div className="labelResoltuionTimeMainDelivery">
                        <table class="uk-table">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand">Country</th>
                                    <th className="uk-table-shrink uk-text-nowrap">Messages</th>
                                    <th className="uk-table-shrink uk-text-nowrap"></th>
                                    <th className="uk-table-shrink uk-text-nowrap">Delivery rate</th>
                                    <th className="uk-table-shrink uk-text-nowrap">Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img className="uk-preserve-width" src="../images/country/1.png" width={20} height={20}  alt="" /><span> Pakistan </span></td>
                                    <td>47</td>
                                    <td><progress id="js-progressbar" class="uk-progress" value="40" max="100"></progress></td>
                                    <td>12.5%</td>
                                    <td>$0.00</td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width" src="../images/country/2.png" width={20} height={20}  alt="" /><span> Jordan </span></td>
                                    <td>33</td>
                                    <td><progress id="js-progressbar" class="uk-progress" value="10" max="100"></progress></td>
                                    <td>12.5%</td>
                                    <td>$0.00</td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width" src="../images/country/3.png" width={20} height={20}  alt="" /><span> Qatar </span></td>
                                    <td>31</td>
                                    <td><progress id="js-progressbar" class="uk-progress" value="30" max="100"></progress></td>
                                    <td>12.5%</td>
                                    <td>$0.00</td>
                                </tr>

                                <tr>
                                    <td><img className="uk-preserve-width" src="../images/country/4.png" width={20} height={20}  alt="" /><span> Dubai </span></td>
                                    <td>0</td>
                                    <td><progress id="js-progressbar" class="uk-progress" value="50" max="100"></progress></td>
                                    <td>12.5%</td>
                                    <td>$0.00</td>
                                </tr>
                                <tr>
                                    <td><img className="uk-preserve-width" src="../images/country/5.png" width={20} height={20}  alt="" /><span> UAE </span></td>
                                    <td>4</td>
                                    <td><progress id="js-progressbar" class="uk-progress" value="20" max="100"></progress></td>
                                    <td>12.5%</td>
                                    <td>$0.00</td>
                                </tr>
                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                 {/* Live chat waiting time */}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <div className="head customheader">
                            <h5 className="uk-heading-divider">WhatsApp Delivery Time by Country</h5>
                        </div>  
                        {/* <div className="head"><h5>All Channels Overview</h5></div> */}
                            {/* Chart */}
                            <div className="ChannelsOverviewGraph">
                            <WhatsappdeliveryTime/>
                            </div>
                    </div>
                </div>


                {/* Live chat waiting time */}
                <div className="uk-width-1-3">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Monthly Active User (this month)
                            </h5>
                        </div>
                            {/* Chart */}
                            <div className="uk-padding">
                                <WhatsappMonthlyActiveChart/>
                            </div>
                    </div>
                </div>

                {/* Live chat waiting time */}
                <div className="uk-width-2-3">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                    <div className="head customheader">
                            <h5 className="uk-heading-divider">Monthly Active User (in past 6 months)</h5>
                        </div>
                            {/* Chart */}
                            <div className="">
                            <WhatsappMonthlyActiveUserChart/>
                            </div>
                    </div>
                </div>
                {/* Frequency of Categories Inquired*/}
                <div className="uk-width-1-1">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                        <div className="head customheader">
                            <h5 className="uk-heading-divider">Frequency of Categories Inquired
                            </h5>
                        </div>
                        
                            <div uk-grid="">
                                <div className="uk-width-1-3">
                                    <div className="uk-padding">
                                        <WhatsappFrequencyChart/>
                                    </div>
                                </div>

                                <div className="uk-width-2-3">
                                    <div className="labelResoltuionTimeMain">
                                        <ul className="labelResoltuionTime whatsappCategories" >
                                            <li>
                                                <span className="Label">Menu</span>
                                                <progress id="js-progressbar" class="uk-progress" value="70" max="100"></progress>
                                                <span className="Percent">45.86%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Menu: Sales</span>
                                                <progress id="js-progressbar" class="uk-progress" value="15" max="100"></progress>
                                                <span className="Percent">6.02%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Menu: Services</span>
                                                <progress id="js-progressbar" class="uk-progress" value="11" max="100"></progress>
                                                <span className="Percent">3.01%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Menu: Talk with Agent</span>
                                                <progress id="js-progressbar" class="uk-progress" value="9" max="100"></progress>
                                                <span className="Percent">3.01%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Menu: Book an appointment</span>
                                                <progress id="js-progressbar" class="uk-progress " value="0" max="100"></progress>
                                                <span className="Percent"> &lt; 1%</span>
                                            </li>

                                            <li>
                                                <span className="Label">Menu: Deals and Discount</span>
                                                <progress id="js-progressbar" class="uk-progress" value="0" max="100"></progress>
                                                <span className="Percent">0</span>
                                            </li>

                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>
                

            </div>
         </>
    );
}
}
export default WhatsappChanelAnalytics;