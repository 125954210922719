import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import {
    Error,
    Information,
    Checkmark,
    ChevronDown
  } from "@carbon/icons-react";
import { ChannelsOverviewChart } from "../../Sections/ChannelsOverviewChart";
import { DateRange } from "../../Common/DateRange";
// import DateRangePicker from 'rsuite/DateRangePicker';
// // import { DateRangePicker, Stack } from 'rsuite';
// import subDays from 'date-fns/subDays';
// import startOfWeek from 'date-fns/startOfWeek';
// import endOfWeek from 'date-fns/endOfWeek';
// import addDays from 'date-fns/addDays';
// import startOfMonth from 'date-fns/startOfMonth';
// import endOfMonth from 'date-fns/endOfMonth';
// import addMonths from 'date-fns/addMonths';
  
class SmsBroadcast extends React.Component {

    constructor() {
        super();
        this.state = {
        from: "",
        to: "",


        }

    }



    componentDidMount() {
        var classRef = this;

        var to = "2023-01-30";
        var from = "2021-01-01";
        classRef.setState({ from: from });
        classRef.setState({ to: to });


                }


MultiSelectFilter =()=>{
    $(".UltabsBtns").toggleClass("showMenu");
    $(".UltabsBtns > li label").click(function(){
        $(".MultiSelectFilter > p span").text($(this).text());
    });
}


  
  

    render() {
        return (
         <>
            <div uk-grid="">
                <div className="uk-width-1-1">
                    <div onClick={this.MultiSelectFilter} className="MultiSelectFilter">
                        <p><span>WhatsApp</span> <ChevronDown /></p>
                        <ul className="UltabsBtns">
                            <li><label htmlFor="WACheck"><img src="/images/Integrations-whatsapp.jpg" /> WhatsApp <input id="WACheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="FBCheck"><img src="/images/facebook.png" /> Facebook <input id="FBCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="InstCheck"><img src="/images/instagram.png" /> Instagram <input id="InstCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="TWCheck"><img src="/images/twitter.png" /> Twitter <input id="TWCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="SMSCheck"><img src="/images/Integrations-sms.jpg" /> SMS <input id="SMSCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="EmlCheck"><img src="/images/Integrations-email.jpg" /> Email <input id="EmlCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="CLCheck"><img src="/images/Integrations-callcenter.jpg" /> Calls <input id="CLCheck" className="uk-checkbox" type="checkbox" /></label></li>
                            <li><label htmlFor="ACCheck"> All Channels <input id="ACCheck" className="uk-checkbox" type="checkbox" defaultChecked /></label></li>
                        </ul>
                    </div>
                    <div className="CustomSearch">
                        <form class="uk-search uk-search-default">
                            <span uk-search-icon=""></span>
                            <input class="uk-search-input" type="search" placeholder="Search by communication name..." aria-label="Search" />
                        </form>
                    </div>
                </div>


                {/* BROADCAST NAME */}
                <div className="uk-width-1-1">
                    <div >
                        <div className="uk-overflow-auto BroadCastTable">
                            <table className="uk-table">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand">BROADCAST NAME</th>
                                    <th className="uk-table-shrink uk-text-nowrap">TRAFIC</th>
                                    <th className="uk-table-shrink uk-text-nowrap">DELIVERY RATE</th>
                                    <th className="uk-table-shrink uk-text-nowrap">
                                    <div className="uk-inline">
                                        <a className="statusBtn"><span>STATUS <ChevronDown /></span></a>
                                        <div className="dropdown-status" uk-dropdown="mode: click">
                                            <ul class="uk-nav uk-dropdown-nav status-dropdown-nav">
                                                <li class="uk-active">
                                                    <a href="#">
                                                        <span className="statusLabel">All</span> 
                                                        <span className="tick"  uk-icon="check"></span>
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="#">
                                                        <span className="statusLabel">Sent</span> 
                                                        <span className="tick" uk-icon="check"></span>
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="#">
                                                        <span className="statusLabel">Delivered</span> 
                                                        <span className="tick" uk-icon="check"></span>
                                                    </a>
                                                </li>

                                                <li class="">
                                                    <a href="#">
                                                        <span className="statusLabel">Read</span> 
                                                        <span className="tick" uk-icon="check"></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    </th>
                                    <th className="uk-table-shrink uk-text-nowrap">COST</th>
                                    <th className="uk-table-shrink uk-text-nowrap">CREATED AT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Lahore Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>200,343</span></td>
                                    <td className="uk-text-nowrap"><span>100% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="progress">In Progress</span></td>
                                    <td className="uk-text-nowrap"><span>$45</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/>
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Karachi Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>1,343</span></td>
                                    <td className="uk-text-nowrap"><span>52.1% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="complete">Completed</span></td>
                                    <td className="uk-text-nowrap"><span>$5</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/> 
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Karachi Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>1,343</span></td>
                                    <td className="uk-text-nowrap"><span>52.1% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="complete">Completed</span></td>
                                    <td className="uk-text-nowrap"><span>$5</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/>    
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Karachi Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>1,343</span></td>
                                    <td className="uk-text-nowrap"><span>52.1% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="complete">Completed</span></td>
                                    <td className="uk-text-nowrap"><span>$5</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/>    
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Karachi Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>1,343</span></td>
                                    <td className="uk-text-nowrap"><span>52.1% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="complete">Completed</span></td>
                                    <td className="uk-text-nowrap"><span>$5</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/>    
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Karachi Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>1,343</span></td>
                                    <td className="uk-text-nowrap"><span>52.1% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="complete">Completed</span></td>
                                    <td className="uk-text-nowrap"><span>$5</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/>    
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Karachi Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>1,343</span></td>
                                    <td className="uk-text-nowrap"><span>52.1% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="complete">Completed</span></td>
                                    <td className="uk-text-nowrap"><span>$5</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/>    
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Karachi Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>1,343</span></td>
                                    <td className="uk-text-nowrap"><span>52.1% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="complete">Completed</span></td>
                                    <td className="uk-text-nowrap"><span>$5</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/>    
                                <tr>
                                    <td><a><img className="uk-preserve-width uk-border-circle" src="images/avatar.jpg" width={40} height={40} alt="" /><span>Karachi Offer</span></a></td>
                                    <td className="uk-text-nowrap"><span>1,343</span></td>
                                    <td className="uk-text-nowrap"><span>52.1% Delivery</span></td>
                                    <td className="uk-text-nowrap"><span className="complete">Completed</span></td>
                                    <td className="uk-text-nowrap"><span>$5</span></td>
                                    <td className="uk-text-nowrap"><span>5 May , 17:44 (UTC+05)</span></td>
                                </tr>
                                <br/>       
                               
                                
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
               
            </div>
         </>
    );
}
}
export default SmsBroadcast;