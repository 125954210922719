import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import axios from 'axios';
import Constant from "../../../App/Constant";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  let dummyData = {

    labels: [] ,
    datasets: [
      {
        label: 'Total Chats',
        data: [],
        backgroundColor: 'rgb(220, 220, 220)',
      },
      {
        label: 'Resolved By Agent',
        data: [],
        backgroundColor: 'rgb(75, 103, 238)',
      },
    ],
  
  }

  export const MonthTicketsAgents = ({stateCompany,stateTo,stateFrom}) => {
    
    const [graphData, setGraphData] = useState(dummyData);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
     formValidation();

  },  [stateCompany,stateTo,stateFrom]);

  const formValidation = async () => {
        
    axios({
        method: 'POST',
        url: Constant.url+"admin-agents-month-wise_ticket-statuses",
        data: {company_id:stateCompany,stateTo:stateTo,stateFrom:stateFrom}  
    }).then(result =>{

      setIsLoaded(true)
      var stats = result.data.data;
      setGraphData({
        labels : stats.month_agent,
        datasets: [
          {
            label: 'Total Chats',
            data: stats.total_chats_agent,
            backgroundColor: 'rgb(220, 220, 220)',
          },
          {
            label: 'Resolved By Agent',
            data: stats.resolved_by_agent,
            backgroundColor: 'rgb(75, 103, 238)',
          },
        ],

      })

      
    }).catch(err => {

      // console.log(err)
            
    });


}

    const options = {
      plugins: {
        legend: {
          position: 'bottom'
        },
        title: {
          display: false,
          // text: 'Chart.js Bar Chart - Stacked',
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    return <Bar options={options} data={graphData} />;

  }







