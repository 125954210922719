import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Constant from "../../../App/Constant";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  let dummyData = {

      labels: [] ,
      datasets: [
        {
          label: 'SMS Rates',
          data: [],
          borderColor: 'rgb(255, 197, 122)',
          backgroundColor: 'rgb(255, 197, 122)',
        }
      ],


  }


  export const BotMessagesRates = (stateCompany,stateTo,stateFrom) => {

    const [graphData, setGraphData] = useState(dummyData);

    useEffect(() => {

      formValidation();

  }, [stateCompany,stateTo,stateFrom]);

  const formValidation = async () => {

    axios({
        method: 'POST',
        url: Constant.url+"admin-bot-rates",
        data: {company_id:stateCompany.stateCompany,stateTo:stateCompany.stateTo,stateFrom:stateCompany.stateFrom} 
    }).then(result =>{

      var stats = result.data.data;
      setGraphData({

      labels: stats.status_bot,
      datasets: [
        {
          label: 'Message Delivery Rate',
          data: stats.values_bot,
          borderColor: 'rgb(255, 197, 122)',
          backgroundColor: 'rgb(255, 197, 122)',
        },
        

      ],

      })

      
    }).catch(err => {

      // console.log(err)
            
    });


}

    
   const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom'
        },
        title: {
          display: false,
          // text: 'Chart.js Horizontal Bar Chart',
        },
      },
    };

    return <Bar options={options} data={graphData} />;


  }







