import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content, Checkbox } from "@adobe/react-spectrum";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Checkmark,
  FaceSatisfied,
  UserRole,
  Image,
  Attachment,
  Location,
  DecisionTree,
  Edit,
  StopOutline,
  TrashCan,
  Download,
  MobileCheck,
  CheckmarkOutline,
  GenderFemale,
  Time,
  Credentials,
  Add,
  Close,
  Renew,
} from "@carbon/icons-react";
import PageActionBar from "../../Sections/PageActionBar";
import Constant from "../../../App/Constant";
import axios from "axios";
import $, { cssNumber, data } from "jquery";
import { Multiselect } from "multiselect-react-dropdown";
import Emoji from "../../Common/Emoji";

class BotChat extends React.Component {
  constructor() {
    super();
    this.charUserApi = this.charUserApi.bind(this);
    this.handleUserList = this.handleUserList.bind(this);
    this.state = {
      chatList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      tagList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      first_name: "",
      last_name: "",
      gender: "",
      email: "",
      phone: "",
      files: null,
      threadCount: 0,
      oldContainerId: 0,
      conversations_id: 0,
      tags: [],
      pageNumber: 1,
      hasMore: true,

    };
  }

  addSearchModal = () => {
    $("#SearchTeamModal").addClass("uk-flex uk-open");
  };

  closeSearchModal = () => {
    $("#SearchTeamModal").removeClass("uk-flex uk-open");
  };

  addSaveUserSegment = () => {
    $("#SaveUserSegment").addClass("uk-flex uk-open");
  };

  refreshMsg = () => {
    window.refreshMsg();
  };
  


  componentDidMount() {
    this.charUserApi();
    $("#add-agent").hide();

    $("#selectDiv").addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    // if (localStorage.getItem("role") == "3") {
    //   window.location.href = "/";
    // }
  }

  charUserApi = () => {
    $("#selectDiv").addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let classRef = this;
    // var campaign_id = id[3];
    let company_id = localStorage.getItem("company_id");
    let user_from = localStorage.getItem("user_id");
    let charParams = new FormData();
    let page = this.state.pageNumber;
    if (localStorage.getItem("bot_stats_conversation_id") != "") {
      var searchword = localStorage.getItem("bot_stats_conversation_id");
      this.setState({ advancesearch: searchword });
      charParams.append("search", searchword);
    }
    charParams.append("company_id", company_id);
    charParams.append("page", page);
    axios({
      method: "POST",
      url: Constant.url + "bot-chat-msisdns",
      data: charParams,
    }).then(function (result) {
      if (result.data.status === true) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
          pageNumber: classRef.state.pageNumber + 1,
          hasMore: result.data.hasMore,

        });
        localStorage.setItem("bot_stats_conversation_id", "");
      } else {
        classRef.setState({
          chatList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
          noEntry: 1, hasMore: false
        });
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
      }
    });
  };

  fetchUserDetail = (user_id,mask) => {
    // let classRef = this;
    // classRef.setState({ conversations_id : localStorage.getItem('conversation_id') });
    localStorage.setItem('masking',mask);
    window.fetchBotChat(user_id);
  };

  getcurrentDate = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return date + "T" + time;
  };

  handleUserList = () => {
    let result = "";
    let index = 0;

    result = this.state.chatList.list.map((response, index) => (
      <li id={"li" + response.conversation_id} data-bot={""+response.is_bot}>
        <div className="chat_tab uk-grid uk-grid-small" uk-grid="">
          <div className="uk-width-auto uk-flex-first"></div>
          <a
            className="user_list uk-width-expand"
            id={"convesation_iteation_" + response.conversation_id}
            onClick={(e) => this.fetchUserDetail(response.conversation_id,response.masking)}
          >
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-auto uk-flex-first">
                <div className="pp">
                  <img src={response.avatar} />
                  <div className="whatsappProfilelogo">
                    <img src={'/images/'+response.channel_image} uk-svg="" />
                  </div>
                </div>
              </div>
              <div className="uk-width-expand">
                <p id={"customer_" + response.conversation_id}>
                  {response.name}
                </p>
                <p id={"msg_" + response.conversation_id}>{response.preview}</p>
              </div>

              <div className="uk-width-auto uk-flex-last">
                <div className="timingBox">
                  <div class="time timeLeft">
                   {(response.is_bot == 'no') ? <p class="blue" id={"ra"+response.conversation_id}>Routed</p> : <p class="blue uk-hidden" id={"ra"+response.conversation_id}>Routed</p>}
                  </div>
                  {/* {response.user_id == null ? <div className="assignedunassigned">Unassigned</div> : <div className="assignedunassigned">Assigned</div> } */}
                  <div className="time newTime">
                    <p>{response.last_reply_at.slice(0, -3)}</p>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </li>
    ));

    return result;
  };

  routedClick = () =>{
    let classRef = this;  
    axios({
        method: 'GET',
        url: Constant.url + "create-or-update-contact?company_id="+localStorage.getItem('company_id')+"&mobile="+localStorage.getItem('bot_customer_id')+"&mask="+localStorage.getItem('masking')+"&user_id="+localStorage.getItem('user_id')+"",
    }).then(function (result) {
    })
    $("#btnRouted").hide();
    $("#ra"+localStorage.getItem('bot_customer_id')).removeClass("uk-hidden");
    $("#li"+localStorage.getItem('bot_customer_id')).attr("data-bot", "no");
  }

  loadUserApi = () => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let page = this.state.pageNumber;
    let charParams = new FormData();
      charParams.append("company_id", company_id);
    charParams.append("page", page);
  
    axios({
      method: "POST",
      url: Constant.url + "bot-chat-msisdns",
      data: charParams,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: [...classRef.state.chatList.list, ...result.data.data],
          },
          pageNumber: classRef.state.pageNumber + 1,

          hasMore: result.data.hasMore,
        });
      } else {
        classRef.setState({ noEntry: 1, hasMore: false });
        // $(".spinner").addClass("uk-hidden");
        // $("#selectDiv").removeClass("uk-hidden");
      }
    });
  };

  searchrecord = () => {
    $("#selectDiv").addClass("uk-hidden");
    $(".spinner").removeClass("uk-hidden");
    let classRef = this;
    var searchword = $("#searchword").val();
    this.setState({ advancesearch: searchword });

    axios({
      method: "POST",
      url: Constant.url + "bot-chat-msisdns",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        $(".spinner").addClass("uk-hidden");
        $("#selectDiv").removeClass("uk-hidden");
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          chatList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  advanceSearch = () => {
    var url = window.location.pathname;
    var id = url.split("/");
    var campaign_id = id[3];

    let classRef = this;
    var data = {};
    data = {
      dateto: $("#to_date").val(),
      datefrom: $("#from_date").val(),
      company_id: localStorage.getItem("company_id"),
    };

    var key = "";

    if (data.dateto) {
      var key = "Date To: " + $("#to_date").val();
    }
    if (data.datefrom) {
      var key = key + " Date From: " + $("#from_date").val();
    }

    classRef.setState({ advancesearch: key });

    axios({
      method: "POST",
      url: Constant.url + "bot-chat-msisdns",
      data: data,
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          chatList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      } else {
        classRef.setState({
          chatList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
        $("#SearchTeamModal").removeClass("uk-flex uk-open");
      }
    });
  };

  advSearchClear = () => {
    $("#chat_box").addClass("uk-hidden");
    $("#profile_details").addClass("uk-hidden");
    $(".spinner").addClass("uk-hidden");
    $("#selectDiv").removeClass("uk-hidden");

    let classRef = this;
    classRef.setState({ advancesearch: "" });
    classRef.charUserApi();
  };

  render() {
    return (
      <Content>
        <div className="chat_container" uk-height-match=".ht_match">
          {/* <PageActionBar />    */}

          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Chat History</h3>
              </div>
              <div className="uk-width-expand">
                <div className="search_field">
                  <form action="JavaScript:void(0);">
                    <input
                      className="uk-input"
                      type="text"
                      onChange={this.searchrecord}
                      name="searchword"
                      id="searchword"
                      value={this.state.advancesearch}
                      placeholder="Search through msisdn"
                    />
                  </form>
                  <div class="CampaignReports Filtermain">
                    <div className="uk-width-auto">
                      <button
                        style={{ right: "30px" }}
                        onClick={this.advSearchClear}
                      >
                        <Close />
                      </button>
                      <button onClick={this.addSearchModal}>
                        <img src="/images/newfilter.svg" uk-svg />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="NotificationToggleNew">
                  <label className="CustomNoti">Bot</label>
                  <label className="switch">
                    {/* { localStorage.getItem('chat-msg-notification') == "true" ? checked : "" } */}

                    <input type="checkbox" class="abc" id="chkLiveChat" />
                    <Link to="/chat" className="slider round">
                      {" "}
                      <span></span>
                    </Link>
                  </label>
                  <label className="CustomNoti">Live Chat</label>
                </div>
              </div>
              <div className="uk-width-1-4">
                <div id="add-agent">
                  <button
                    className="newFlowBtn uk-float-right"
                    uk-toggle="target: #AddBroadcastModal"
                  >
                    <Add /> <span>Add Agent</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="uk-grid uk-grid-collapse" uk-grid>
            <div className="chat_heads_list ht_match uk-width-1-4">
              <div className="chat_box_head">
                <div className="mdl">
                  <div className="mdl_inner">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand">
                        <div className="user_selection">
                          <div className="uk-inline">
                            {/* <img src="/images/toplogo.svg"/> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="contacts_list contacts_list_loader"
                id="threadList"
              >
                <ul>
                  {/* {this.state.chatList.isLoaded ? (
                    this.handleUserList()
                  ) : this.state.chatList.msg == "not" ? (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center loaderData uk-height-large">
                          Data not Found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div className="uk-text-center uk-height-large">
                          <div className="mdl">
                            <div className="mdl_inner">
                              <div uk-spinner="" className="botLoader"></div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )} */}

<InfiniteScroll
                    style={{ overflow: "none" }}
                    dataLength={this.state.chatList.list.length} //This is important field to render the next data
                    next={this.loadUserApi}
                    pullDownToRefreshThreshold={50}
                    hasMore={this.state.hasMore}
                    scrollableTarget="threadList"
                    className="conversaionsList"
                    endMessage={<center id="record_not_found"></center>}
                  >
                    {this.state.chatList.isLoaded === true &&
                    this.state.chatList.list.length > 0
                      ? this.handleUserList()
                      : ""}
                  </InfiniteScroll>
                </ul>
              </div>
            </div>
            <div className="chat_box ht_match uk-width-expand spinner">
              <div className="uk-text-center uk-position-center ">
                <span uk-spinner="ratio: 2" />
              </div>
            </div>
            <div
              className="chat_box ht_match uk-width-expand uk-hidden"
              id="chat_box"
            >
              <div className="chat_box_head">
                <div className="uk-grid uk-flex uk-flex-right " uk-grid="">
                  <div className="uk-width-auto">
                    <div className="RefreshMsg">
                      {/* <button onClick={this.refreshMsg}>
                        <span>
                          <Renew />
                        </span>
                        Refresh
                      </button> */}
                    </div>
                    <div className="seen_status" >
                      <button className="fill" id="btnRouted" onClick={(e)=>this.routedClick()}> <span><img src="/images/done_all_black_24dp.svg" /></span>Route to agent</button>
                    </div>
                    {/* <div className="seen_status"> */}
                      {/* <button onClick={this.markResolved}>
                        {" "}
                        <span>
                          <img src="/images/done_all_black_24dp.svg" />
                        </span>{" "}
                        Mark as resolved
                      </button> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="chat_date">{/* <p>Today, 13:38</p> */}</div>

              <div className="messages_box CampaignChatmessages_box">
                <ul className="message_box_list" id="message_box_list">
                  {/* audio start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <audio
                          id="player"
                          src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3"
                          type="audio/mp3"
                          controls
                        ></audio>
                      </div>
                    </div>
                  </li>

                  <div className="dateSpan">8:30 PM</div>
                  {/* audio end */}

                  {/* video start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <video width="320" height="240" controls>
                          <source
                            src='images/ForBiggerFun.mp4' 
                            type="video/mp4"
                          />
                          {/* <source src="movie.ogg" type="video/ogg" />  */}
                        </video>
                      </div>
                    </div>
                  </li>
                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <video width="320" height="240" controls>
                          <source
                            src='images/ForBiggerFun.mp4' 
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  </li>
                  {/* video end */}

                  {/* img start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <img src="images/avatar.jpg" width="200" height="200" />
                      </div>
                    </div>
                  </li>

                  {/* img end */}

                  {/* pdf start */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text customPdfBtn">
                        <a
                          href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                          download=""
                        >
                          <img src="images/box.svg" uk-svg="" /> Document.pdf{" "}
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text customPdfBtn">
                        <a
                          href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                          download
                        >
                          <img src="images/box.svg" uk-svg="" /> Document.pdf{" "}
                        </a>
                      </div>
                    </div>
                  </li>

                  {/* pdf end */}

                  <li className="customer">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <span>👋</span>
                        <span>Get started</span>
                      </div>
                    </div>
                  </li>
                  <li className="prompt_msg">
                    <p className="uk-text-center">
                      Opted-in through widget: 👋 Get Started
                    </p>
                  </li>
                  <li className="support">
                    <div className="message">
                      <div className="picture">
                        <img src="/images/" />
                      </div>
                      <div className="text">
                        <span>
                          We are very sorry, that we could not resolve your
                          issue. Unfortunately, we have constantly been looking
                          for solutions and have found none so far.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="prompt_msg">
                    <p className="uk-text-center">
                      Usman Khan attached a note to WhatsApp conversation:
                    </p>
                  </li>
                </ul>
              </div>
              <hr />
            </div>
            <div
              className="profile_details ht_match uk-width-1-4  uk-hidden"
              id="profile_details"
            >
              <div className="chat_box_head">
                <div className="mdl">
                  <div className="mdl_inner">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-expand">
                        <div className="profile_name">
                          <h4>Naveen Asim</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_content">
                <div className="profile_pic">
                  <img src="/images/" alt="" id="customer_profile_pic" />
                </div>
                <div className="contact_details">
                  <ul>
                    <li>
                      <button>
                        {" "}
                        <span>
                          <MobileCheck />
                        </span>{" "}
                        <span id="mobile_no">033565472945</span>
                      </button>
                    </li>
                    <li>
                      {" "}
                      <span>
                        <Time />
                      </span>{" "}
                      <span id="time">Contact Time: 16:07 (UTC+05)</span>
                    </li>
                  </ul>
                </div>
                <div className="pause_automation"></div>
                <div
                  id="AddTagsModal"
                  className="AddTeamModal ModalStyle uk-flex-top"
                  uk-modal="esc-close: false; bg-close: false"
                >
                  <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <button className="uk-modal-close-default" type="button">
                      {" "}
                      <Close />
                    </button>
                    <h3>Add tag</h3>
                    <div id="errorAlert"></div>
                    <div id="successAlert"></div>
                    <form
                      id="tagform"
                      className="uk-form-stacked"
                      action="javascript:void(0);"
                    >
                      <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="teamName">
                          Select tags
                        </label>
                        <div className="chipBox">
                          <div className="chipBox CustomChipBox">
                            <Multiselect
                              id="saj"
                              options={this.state.tagList.list}
                              selectedValues={this.state.tags}
                              onSelect={this.onSelectTags}
                              onRemove={this.onRemoveTags}
                              displayValue="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="uk-margin">
                        <div className="ModalStyleBtns">
                          <button
                            className="AddTeamBtn"
                            id="addAgent"
                            onClick={this.attachTag}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="multi_chat_select_prompt ht_match uk-width-expand "
              id="selectDiv"
            >
              <div className="mdl">
                <div className="mdl_inner">
                  <div className="assignment_actions">
                    <img id="chatImage" src="/images/chaticon.svg" />
                    <p id="data-error" className="uk-text-center uk-hidden">
                      No Data Found!
                    </p>
                    {/* <ul className="uk-hidden" id="selectOptions">
                          <li>
                            <h5 className="uk-text-center"> <span id="threadCount_span">{ this.state.threadCount }</span> conversations selected</h5>
                          </li>
                          <li>
                            <button className="" id="" onClick={this.addMarkAsRead}>
                            <span>
                            <img src="/images/done_all_black_24dp.svg" uk-svg=""/>
                            </span>{" "}Mark as resolved</button>
                          </li>
                          <li>
                            <div className="user_selection">
                              <div className="uk-inline uk-width-1-1">
                              </div>
                            </div>
                          </li>
                          <li>
                            <button className="" id="" onClick={this.unassignShow}>
                            <span ><img src="/images/user--role.svg" uk-svg=""/></span>{" "}Unassign Conversations</button>
                          </li>
                        </ul> */}
                    <div
                      className="clear_selection uk-text-center uk-hidden"
                      id="selectOptions"
                    >
                      <Link to="#">Clear Selection</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="SearchTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Advance Search</h3>
            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="FromDate">
                    From Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="to_date"
                      id="from_date"
                    />
                  </div>
                </div>
                <div className="Campaigndate">
                  <label className="uk-form-label" htmlFor="ToDate">
                    To Date
                  </label>
                  <div class="uk-form-controls">
                    <input
                      type="date"
                      label="Select Broadcast"
                      className="from_date"
                      id="to_date"
                    />
                  </div>
                </div>
              </div>

              <div className="uk-margin">
                <div className="ModalStyleBtns CampaignBtn">
                  <button className="CnclBtn" onClick={this.closeSearchModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.advanceSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
  }
}

export default BotChat;
