import * as React from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/uikit/dist/css/uikit.css";
import UIkit from "uikit";
import style from "../../../Assets/css/style.css";
import { Content } from "@adobe/react-spectrum";
import { Multiselect } from "multiselect-react-dropdown";
import {
  Add,
  CaretDown,
  Edit,
  TrashCan,
  OverflowMenuVertical,
  Download,
  Close,
  Checkmark,
} from "@carbon/icons-react";
import Constant from "../../../App/Constant";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";

class Team extends React.Component {
  constructor() {
    super();
    this.callApi = this.callApi.bind(this);
    this.handleTable = this.handleTable.bind(this);
    this.getAgents = this.getAgents.bind(this);
    this.onSelectAgents = this.onSelectAgents.bind(this);
    this.onRemoveAgents = this.onRemoveAgents.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onSelectNewAgents = this.onSelectNewAgents.bind(this);
    this.onNewRemoveAgents = this.onNewRemoveAgents.bind(this);
    this.state = {
      dataList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      agentList: {
        isLoaded: false,
        msg: "Loading...",
        list: [],
      },
      selectOption: [],
      agents: [],
      new_name: "",
      users: "",
      team: "",
      old_agents: [],
    };
  }

  download = (id) => {
    let classRef = this;
    var team_id = id;
    window.open(
      Constant.url +
        "team-download?company_id=" +
        localStorage.getItem("company_id") +
        "&team_id=" +
        team_id,
      "_blank"
    );
  };

  closeConsent = () => {
    $("#AddResolved").removeClass("uk-flex uk-open");
  };

  addconsent = (id) => {
    // console.log(id, "sajdain");
    $(".uk-dropdown").removeClass("uk-open");
    $("#AddResolved").addClass("uk-flex uk-open");
    $("#addconsent").val(id);
  };

  handleNameChange(event) {
    this.setState({ new_name: event.target.new_name });
  }

  onSelectAgents(selectedList, selectedItem) {
    this.setState({ agents: selectedList });
  }
  onRemoveAgents(selectedList, removedItem) {
    this.setState({ agents: selectedList });
  }

  onSelectNewAgents(selectedList, selectedItem) {
    this.setState({ old_agents: selectedList });
  }
  onNewRemoveAgents(selectedList, removedItem) {
    this.setState({ old_agents: selectedList });
  }

  componentDidMount() {
    this.callApi();
    this.getAgents();
  }

  deleteTeam = (id) => {
    let classRef = this;
    var id = $("#addconsent").val();
    var team = id;
    var company_id = localStorage.getItem("company_id");
    var is_deleted = 1;
    var user_id = localStorage.getItem("user_id");

    axios({
      method: "POST",
      url: Constant.url + "edit-team/" + team,
      data: {
        company_id: company_id,
        is_deleted: is_deleted,
        user_id: user_id,
      },
    }).then(function (result) {
      if (result.data.code === 200) {
        $("#successAlert").append(
          '<div id="msg-dialog" class="uk-alert-success custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.message +
            "</p></div>"
        );

        window.location.href = "/team";
      } else {
        $("#errorAlert").append(
          '<div id="msg-dialog" class="uk-alert-danger custom-uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
            result.data.data +
            "</p></div>"
        );

        setTimeout(function () {
          UIkit.alert("#msg-dialog").close();
        }, 500);

        window.location.href = "/team";
      }
    });
  };

  callApi = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "teams",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  handleTable = () => {
    let result = "";
    let index = 0;
    result = this.state.dataList.list.map((user) => (
      <tr id={"bteam_" + user.id}>
        <td>
          <label>
            <input
              className="uk-checkbox autoSelect"
              name="multi_check[]"
              value={user.id}
              type="checkbox"
            />
          </label>
          <input
            className="star"
            checked={user.is_star == 1 ? true : false}
            type="checkbox"
            title="bookmark page"
          />
        </td>
        <td>
          <span>{user.name}</span>
        </td>
        <td>{user.counter}</td>
        <td><button className="iconcircleBtn success" uk-tooltip="title: Download Data" onClick={(e) => this.download(user.id)}><Download /></button></td>
        <td><button className="iconcircleBtn" uk-tooltip="title: Edit" onClick={(e) => this.EditTeamModal(user.id)}><Edit /></button></td>
        <td><button className="iconcircleBtn danger" uk-tooltip="title: Delete" onClick={(e) => this.addconsent(user.id)}><TrashCan /></button></td>
      </tr>
    ));
    return result;
  };

  getAgents = () => {
    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "get-agents",
      data: { company_id: localStorage.getItem("company_id") },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          agentList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      }
    });
  };

  searchrecord = () => {
    let classRef = this;
    var searchword = $("#searchword").val();
    // console.log(searchword);
    axios({
      method: "POST",
      url: Constant.url + "teams",
      data: {
        company_id: localStorage.getItem("company_id"),
        search: searchword,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        classRef.setState({
          dataList: {
            isLoaded: true,
            msg: "Loading...",
            list: result.data.data,
          },
        });
      } else {
        classRef.setState({
          dataList: {
            isLoaded: false,
            msg: "not",
            list: [],
          },
        });
      }
    });
  };

  createTeam = () => {
    $("#addteam").text("Processing");
    $("#addteam").prop("disabled", true);

    let classRef = this;
    var agents = "";
    agents = Object.keys(classRef.state.agents)
      .map(function (k) {
        return classRef.state.agents[k].id;
      })
      .join(",");
    var nameArr = agents.split(",");

    var data = {};
    data = {
      name: $("#teamName").val(),
      company_id: localStorage.getItem("company_id"),
      user_id: 1,
      members: nameArr,
    };
    axios({
      method: "POST",
      url: Constant.url + "add-team",
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#successAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.href = "/team";
        } else {
          $("#errorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#addteam").text("Add Team");
          $("#addteam").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  EditTeamModal = (id) => {
    $("#EditTeamModal").addClass("uk-flex uk-open");
    $(".uk-dropdown").removeClass("uk-open");
    this.findteam(id);
  };

  findteam = (id) => {
    let classRef = this;
    let company_id = localStorage.getItem("company_id");
    let team_id = id;
    axios({
      method: "POST",
      url: Constant.url + "single-team",
      data: { company_id: company_id, team_id: team_id },
    }).then(function (result) {
      if (result.data.status === true) {
        var team = result.data.data;
        var child = team.users;
        classRef.setState({
          old_agents: child,
          new_name: team.name,
          users: team.users,
          team_id: team.id,
        });
      }
    });
  };

  addTeamModal = () => {
    $("#AddTeamModal").addClass("uk-flex uk-open");
  };

  closeAgentModal = () => {
    $("#AddTeamModal").removeClass("uk-flex uk-open");
  };

  editTeamModal = () => {
    $("#EditTeamModal").addClass("uk-flex uk-open");
  };

  editcloseAgentModal = () => {
    $("#EditTeamModal").removeClass("uk-flex uk-open");
  };

  editTeam = () => {
    $("#editteam").text("Processing");
    $("#editteam").prop("disabled", true);

    let classRef = this;
    var agents = "";
    var team_id = $("#team_id").val();
    agents = Object.keys(classRef.state.old_agents)
      .map(function (k) {
        return classRef.state.old_agents[k].id;
      })
      .join(",");
    var nameArr = agents.split(",");

    var data = {};
    data = {
      name: $("#new_teamName").val(),
      company_id: localStorage.getItem("company_id"),
      user_id: localStorage.getItem("user_id"),
      members: nameArr,
    };

    axios({
      method: "POST",
      url: Constant.url + "edit-team/" + team_id,
      data: data,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          $("#editsuccessAlert").append(
            '<div id="msg-dialog" class="uk-alert-success" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.message +
              "</p></div>"
          );
          window.location.href = "/team";
        } else {
          $("#editerrorAlert").append(
            '<div id="msg-dialog" class="uk-alert-danger" uk-alert><a class="uk-alert-close" uk-close></a><p>' +
              response.data.data +
              "</p></div>"
          );
          $("#editteam").text("Edit Team");
          $("#editteam").prop("disabled", false);
          setTimeout(function () {
            UIkit.alert("#msg-dialog").close();
          }, 500);
          return;
        }
      })
      .then(function (response) {});
  };

  handleSelectBox = () => {
    let result = {};
    let options = [];
    let classRef = this;
    let index = 0;
    result = this.state.agentList.list.map((response, index) => ({
      value: "ShariqKhan",
      label: (
        <div className="chip">
          <img src="/images/avatar.jpg" alt="Image" />
          <span>Usman Ramnani</span>
        </div>
      ),
    }));
    return options.push(result);
  };

  deleteMultiSegment = () => {
    var ids = [];
    $("input[type=checkbox]").each(function (i) {
      if ($(this).is(":checked")) {
        $("#bteam_" + $(this).val()).hide();
        ids[i] = $(this).val();
      }
    });

    let classRef = this;
    axios({
      method: "POST",
      url: Constant.url + "bulk-delete-teams",
      data: {
        company_id: localStorage.getItem("company_id"),
        webhook_ids: ids,
      },
    }).then(function (result) {
      if (result.data.status === true) {
        //  window.location.reload();
      }
    });
  };

  selectCheckbox = () => {
    if (!this.state.checkAll) {
      this.setState({ checkAll: true }, function () {
        $("#select_all").prop("checked", true);
        $(".autoSelect").prop("checked", true);
      });
    } else {
      this.setState({ checkAll: false }, function () {
        $("#select_all").prop("checked", false);
        $(".autoSelect").prop("checked", false);
      });
    }
  };

  render() {
    const options = [
      {
        value: 1,
        label: (
          <div className="chip">
            <img src="/images/avatar.jpg" alt="Image" />
            <span>Usman Ramnani</span>
          </div>
        ),
      },
      {
        value: 2,
        label: (
          <div className="chip">
            <img src="/images/avatar.jpg" alt="Image" />
            <span>Usman Ramnani</span>
          </div>
        ),
      },
    ];

    return (
      <Content>
        <div
          className="TeamContainer contactContainer"
          uk-height-viewport=""
          uk-height-match=".h_match"
        >
          <div className="head">
            <div className=" uk-grid " uk-grid="">
              <div className="uk-width-auto">
                <h3>Team</h3>
              </div>
              <div className="uk-width-1-3">
                <div className="search_field">
                  <form action="javascript:void(0)">
                    <input
                      className="uk-input"
                      onChange={this.searchrecord}
                      name="searchword"
                      id="searchword"
                      type="text"
                      placeholder="Search team through name"
                    />
                  </form>
                </div>
              </div>
              <div className="uk-width-expand">
                <div className="searchnFlowBtn">
                  <button className="newFlowBtn" onClick={this.addTeamModal}>
                    <Add /> <span>Add Team</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="tableSec">
            <table className="uk-table uk-table-small uk-table-divider">
              <thead>
                <tr>
                  <th>
                    <label>
                      <input
                        className="uk-checkbox"
                        type="checkbox"
                        id="select_all"
                        onClick={this.selectCheckbox}
                      />
                    </label>
                    <div className="uk-inline drpIconBox">
                      <button className="iconTble" type="button">
                        <CaretDown />
                      </button>
                      <div uk-dropdown="mode: click">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li>
                            <a onClick={this.deleteMultiSegment}>
                              Delete Selected Rows
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </th>
                  <th>TEAM NAME</th>
                  <th>TEAM SIZE</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* {(this.state.dataList.isLoaded) ? this.handleTable() : ""} */}
                {/* {(this.state.dataList.isLoaded) ? this.handleTable() : <tr><td colSpan="15"><div className="uk-text-center uk-height-large"><div className="mdl"><div className="mdl_inner"><div uk-spinner=""></div></div></div></div></td></tr>} */}
                {this.state.dataList.isLoaded ? (
                  this.handleTable()
                ) : this.state.dataList.msg == "not" ? (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        Data not Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="15">
                      <div className="uk-text-center uk-height-large">
                        <div className="mdl">
                          <div className="mdl_inner">
                            <div uk-spinner=""></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          id="AddTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Add team</h3>

            <div id="errorAlert"></div>
            <div id="successAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Team name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="teamName"
                    name="teamName"
                    type="text"
                    placeholder="Team 1"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Add agents </label>

                <div className="chipBox">
                  <div className="chipBox CustomChipBox">
                    <Multiselect
                      options={this.state.agentList.list}
                      selectedValues={this.state.agents}
                      onSelect={this.onSelectAgents}
                      onRemove={this.onRemoveAgents}
                      displayValue="name"
                    />
                  </div>
                </div>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button className="CnclBtn" onClick={this.closeAgentModal}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addteam"
                    onClick={this.createTeam}
                  >
                    Add team
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          id="EditTeamModal"
          className="AddTeamModal ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>Edit team</h3>

            <div id="editerrorAlert"></div>
            <div id="editsuccessAlert"></div>

            <form className="uk-form-stacked" action="javascript:void(0);">
              <div className="uk-margin">
                <label className="uk-form-label" htmlFor="teamName">
                  Team name
                </label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input"
                    id="team_id"
                    value={this.state.team_id}
                    name="team_id"
                    type="hidden"
                  />
                  <input
                    className="uk-input"
                    id="new_teamName"
                    value={this.state.new_name}
                    onChange={this.handleNameChange}
                    type="text"
                  />
                </div>
              </div>

              <div className="uk-margin">
                <label className="uk-form-label">Add agents </label>
                <div className="chipBox">
                  <div className="chipBox CustomChipBox">
                    <Multiselect
                      options={this.state.agentList.list}
                      selectedValues={this.state.old_agents}
                      onSelect={this.onSelectNewAgents}
                      onRemove={this.onRemoveNewAgents}
                      displayValue="name"
                    />
                  </div>
                </div>
              </div>
              <div className="uk-margin">
                <div className="ModalStyleBtns">
                  <button
                    className="CnclBtn"
                    onClick={this.editcloseAgentModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="editteam"
                    onClick={this.editTeam}
                  >
                    Edit team
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/*----MODAL---*/}
        <div
          id="AddResolved"
          className="AlertPOPUP ModalStyle uk-flex-top"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button className="uk-modal-close-default" type="button">
              {" "}
              <Close />
            </button>
            <h3>
              <span className="uk-inline"> Warning</span>
              <span className="uk-inline uk-float-right">
                <img src="/images/done_all_black_24dp.svg" />{" "}
              </span>
            </h3>
            <div className="resolved_content">
              <p>
                <b>Are you sure you want to delete Team?</b>
              </p>
              <p className="small">You won't be able to revert this!</p>

              <form
                className="uk-form-stacked uk-margin-medium-top"
                action="javascript:void(0);"
              >
                <div className="ModalStyleBtns uk-float-right">
                  <button className="CnclBtn" onClick={this.closeConsent}>
                    Cancel
                  </button>
                  <button
                    className="AddTeamBtn"
                    id="addconsent"
                    onClick={this.deleteTeam}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}
export default Team;
